<mat-card
  [matBadge]="selection?.length"
  matBadgeColor="accent"
  [matBadgeHidden]="selection?.length < 2"
>
  <mat-select
    #selector
    [placeholder]="globalConfig.domainTitlePlural | uppercase | translate | titlecase"
    (selectionChange)="domainSelectionChanged($event.value, $event.source.options)"
    [(ngModel)]="selection"
    multiple
  >
    <mat-select-trigger *ngIf="selection?.length">
      {{selection[0].name}}
    </mat-select-trigger>
    <mat-option
      *ngFor="let domain of domains$ | async"
      [value]="domain"
      class="custom-option"
    >
      <dkm-core-ava-viewer
        *ngIf="selector.panelOpen"
        [editDisabled]="true"
        [fileLink]="domain.thumbprintUrl"
        [cacheEnabled]="true"
        class="mr-8"
      >{{ domain.name | ekonInitials }}</dkm-core-ava-viewer>
      <span>{{domain.name}}</span>
    </mat-option>
    <mat-option style="display: none"></mat-option>
    <div
      class="open-dialog"
      (click)="openDialog(); selector.close();"
    >{{'ADD MORE' | translate }}
    </div>
  </mat-select>
</mat-card>
