import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import {
  ekIconCompressAlt,
  ekIconExpandArrowsAlt, ekIconTimes,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { EkonDialogConfirmComponent } from './components/ekon-dialog-confirm/ekon-dialog-confirm.component';
import { EkonDialogWrapperComponent } from './components/ekon-dialog-wrapper/ekon-dialog-wrapper.component';

@NgModule({
  declarations: [
    EkonDialogWrapperComponent,
    EkonDialogConfirmComponent
  ],
  exports: [
    EkonDialogWrapperComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    FlexModule,
    EkonIconsModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/common/ekon-dialogs/',
      multi: true
    }
  ]
})
export class EkonDialogsModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconExpandArrowsAlt,
      ekIconTimes,
      ekIconCompressAlt
    );
  }
}
