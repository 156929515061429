import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DkmDomain } from '@ekon-client/dkm-api';

@Component({
  selector: 'dkm-domain-card',
  templateUrl: './domain-card.component.html',
  styleUrls: ['./domain-card.component.scss'],
})
export class DomainCardComponent {
  @Input()
  public domain: DkmDomain;

  @Input()
  public disableAllActions: boolean;

  @Output()
  onDelete = new EventEmitter<DkmDomain>();

  @Output()
  onEdit = new EventEmitter<DkmDomain>();

  @Output()
  onView = new EventEmitter<DkmDomain>();

  @Output()
  select = new EventEmitter<DkmDomain>();

  delete(): void {
    this.onDelete.emit(this.domain);
  }

  editDomain(): void {
    this.onEdit.emit(this.domain);
  }

  viewDomain(): void {
    this.onView.emit(this.domain);
  }

  selectEntity(): void {
    this.select.emit(this.domain);
  }
}
