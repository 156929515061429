/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *   Comment  Post  Blog  Page  File
 */
export type BlockedContentType = 'Comment' | 'Post' | 'Blog' | 'Page' | 'File';

export const BlockedContentType = {
    Comment: 'Comment' as BlockedContentType,
    Post: 'Post' as BlockedContentType,
    Blog: 'Blog' as BlockedContentType,
    Page: 'Page' as BlockedContentType,
    File: 'File' as BlockedContentType
};

