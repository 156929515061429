import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import { Observable } from 'rxjs';

import { FileDataSource} from '../extras';
import { FileViewDialogComponent } from '../file-view/components/file-view-dialog/file-view-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class FileViewService {
  constructor(private dialog: MatDialog) {}

  viewFile(
    initialFileIndex?: number,
    selectable?: boolean,
    fileType?: string | string[],
    specificDomain?: string,
    userFilesOnly?: boolean
  ): Observable<FileDataSource> {
    const dialogConfig = getModalDefaultConfig();
    dialogConfig.maxWidth = '65vw';
    // dialogConfig.maxHeight = '90vh';
    dialogConfig.data = { initialFileIndex, selectable, fileType, specificDomain, userFilesOnly };

    return this.dialog.open(FileViewDialogComponent, dialogConfig).afterClosed();
  }
}
