import { Pipe, PipeTransform } from '@angular/core';
import { UserProfileHeader } from '@ekon-client/dkm-api';

import { getUserName } from '../utils';

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {

  transform(user: UserProfileHeader): string {
    return getUserName(user);
  }

}
