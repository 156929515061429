import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration, PagePaginationModel,
  RightToErsureModel,
  UpdateShareConsent, UserHeaderModelPagedResult,
  UserProfile,
  UserProfileHeader,
  UserProfileService,
  UserProfileServiceInterface, ViewUserProfile
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserProfileEventsServiceInterface } from './UserProfileEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class UserProfileEventsService
  implements UserProfileServiceInterface, UserProfileEventsServiceInterface {
  get configuration(): Configuration {
    return this.userProfileService.configuration;
  }

  set configuration(val: Configuration) {
    this.userProfileService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.userProfileService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.userProfileService.defaultHeaders = val;
  }

  /**
   * The mechanism for monitoring Create action
   */
  private userProfileCreatedSubject: Subject<UserProfile> = new Subject();

  get userProfileCreated(): Observable<UserProfile> {
    return this.userProfileCreatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Delete action
   */
  private userProfileDeletedSubject: Subject<string> = new Subject();

  get userProfileDeleted(): Observable<string> {
    return this.userProfileDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Update action
   */
  private userProfileUpdatedSubject: Subject<string> = new Subject();

  get userProfileUpdated(): Observable<string> {
    return this.userProfileUpdatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring TOU acceptance action
   */
  private touAcceptedSubject: Subject<string> = new Subject();

  get touAccepted(): Observable<string> {
    return this.touAcceptedSubject.asObservable();
  }


  constructor(
    private userProfileService: UserProfileService,
    private progressBar: EkonProgressBarService,
  ) {
  }

  getUserProfile(): Observable<UserProfile> {
    return this.progressBar.apply(
      this.userProfileService.getUserProfile()
    );
  }

  migrateUsersToIdentity(): Observable<unknown> {
    throw new Error('Method not implemented.');
  }

  activateUserProfile(email: string): Observable<unknown> {
    return this.progressBar.apply(
      this.userProfileService.activateUserProfile(email)
    );
  }


  updateUserProfile(userProfile: UserProfile): Observable<unknown> {
    return this.progressBar
      .apply(this.userProfileService.updateUserProfile(userProfile))
      .pipe(tap(() => this.userProfileUpdatedSubject.next(userProfile.id)));
  }

  listUsers(requestBody?: Array<string>): Observable<Array<UserProfileHeader>> {
    return this.progressBar.apply(
      this.userProfileService.listUsers(requestBody)
    );
  }

  updateUserAcceptTouPp(profileId: string): Observable<unknown> {
    return this.progressBar
      .apply(this.userProfileService.updateUserAcceptTouPp(profileId))
      .pipe(
        tap(() => this.touAcceptedSubject.next(profileId))
      );
  }

  updateUserShareConset(profileId: string, shareConsent: UpdateShareConsent): Observable<unknown> {
    return this.progressBar.apply(this.userProfileService.updateUserShareConset(profileId, shareConsent));
  }

  updateUserSubscription(subscriptionPlanName: string): Observable<unknown> {
    return this.progressBar.apply(this.userProfileService.updateUserSubscription(subscriptionPlanName));
  }

  userSubscriptionPlanRequest(body: string): Observable<unknown> {
    return this.progressBar.apply(this.userProfileService.userSubscriptionPlanRequest(body));
  }

  updateUserWalletId(body: string): Observable<unknown> {
    return this.progressBar.apply(this.userProfileService.updateUserWalletId(body));
  }

  rightToErasureReasons(): Observable<Array<string>> {
    return this.progressBar.apply(this.userProfileService.rightToErasureReasons());
  }

  rightToErsureRequestForm(rightToErsureModel?: RightToErsureModel): Observable<unknown> {
    return this.progressBar.apply(this.userProfileService.rightToErsureRequestForm(rightToErsureModel));
  }

  getOneTimeVerificationCode(profileId: string): Observable<unknown> {
    return this.progressBar.apply(this.userProfileService.getOneTimeVerificationCode(profileId));
  }

  findProtectedUserProfileById(userId: string): Observable<ViewUserProfile> {
    return this.progressBar.apply(this.userProfileService.findProtectedUserProfileById(userId));
  }

  findPublicUserProfileById(userId: string): Observable<ViewUserProfile> {
    return this.progressBar.apply(this.userProfileService.findPublicUserProfileById(userId));
  }

  listUserProfileHeaders(pagePaginationModel?: PagePaginationModel): Observable<UserHeaderModelPagedResult> {
    return this.progressBar.apply(this.userProfileService.listUserProfileHeaders(pagePaginationModel));
  }
}
