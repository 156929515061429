import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { EkonAuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EkonRootScreenGuard implements CanLoad, CanActivate {
  constructor(
    private authService: EkonAuthService,
    private router: Router
  ) {
  }

  canLoad(
    route: Route,
    _segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isLoggedIn$.pipe(
      take(1),
      // tap(auth => !auth && this.router.navigate(['landing']))
      tap({
        next: auth => {
          console.log("EkonRootScreenGuard canLoad auth: " + auth + ", path: " + route.path)
          if (route.path === 'landing' && auth) {
            console.log("EkonRootScreenGuard canLoad navigate => dashboard", auth, route.path)
            this.router.createUrlTree(['dashboard']);
          }
          if (route.path !== 'landing' && !auth) {
            console.log("EkonRootScreenGuard canLoad navigate => landing", auth, route.path)
            this.router.createUrlTree(['landing']);
          }
        }
      }),
      map(auth => route.path === 'landing' ? !auth : auth),
      tap(r => console.log("EkonRootScreenGuard canLoad map result: " + r))
    );
    // return this.handleRedirect();
  }

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    state.url
    return this.handleRedirect(state.url);
  }

  handleRedirect(path: string): Observable<boolean | UrlTree> | boolean | UrlTree {
    const nonAuthNavFailed: string = sessionStorage.getItem('ek-nonAuthNavFailed');

    return this.authService.isLoggedIn$.pipe(
      take(1),
      map(auth => {
        // let url = nonAuthNavFailed;
        console.log("EkonRootScreenGuard handleRedirect IsLogedIn: " + auth + ", path: " + path)
        if (!auth) {
          if (!path.startsWith('/landing') && !path.startsWith('/dashboard')) {
            // sessionStorage.setItem('ek-nonAuthNavFailed', path);
          }
          //url = '/landing';
          return this.router.parseUrl('/landing');
        } else if (auth == true && nonAuthNavFailed) {
          sessionStorage.removeItem('ek-nonAuthNavFailed');
          return this.router.parseUrl(nonAuthNavFailed);;
        } else {
          return this.router.parseUrl('/dashboard');
        }
      })
    );
  }
}
