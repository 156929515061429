import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  CreateGeoLayer,
  GeoLayerFeatureModel,
  GeoLayerModel,
  GeoLayerService,
  GeoLayerServiceInterface,
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { GeoEventsServiceInterface } from './GeoEventsServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class GeoEventsService
  implements GeoLayerServiceInterface, GeoEventsServiceInterface {
  get configuration(): Configuration {
    return this.geoService.configuration;
  }

  set configuration(val: Configuration) {
    this.geoService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.geoService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.geoService.defaultHeaders = val;
  }

  private layerCreatedSubject: Subject<GeoLayerModel> = new Subject();
  get layerCreated(): Observable<GeoLayerModel> {
    return this.layerCreatedSubject.asObservable();
  }

  private layerDeletedSubject: Subject<string> = new Subject();
  get layerDeleted(): Observable<string> {
    return this.layerDeletedSubject.asObservable();
  }

  private layerUpdatedSubject: Subject<string> = new Subject();
  get layerUpdated(): Observable<string> {
    return this.layerUpdatedSubject.asObservable();
  }

  private featureDeletedSubject: Subject<string> = new Subject();
  get featureDeleted(): Observable<string> {
    return this.featureDeletedSubject.asObservable();
  }

  private featureUpdatedSubject: Subject<string> = new Subject();
  get featureUpdated(): Observable<string> {
    return this.featureUpdatedSubject.asObservable();
  }

  constructor(
    private geoService: GeoLayerService,
    private progressBar: EkonProgressBarService
  ) { }

  createGeoLayer(
    createGeoLayerRequest: CreateGeoLayer
  ): Observable<GeoLayerModel> {
    return this.progressBar
      .apply(this.geoService.createGeoLayer(createGeoLayerRequest))
      .pipe(
        tap({
          next: (r: GeoLayerModel) => this.layerCreatedSubject.next(r),
        })
      );
  }

  deleteGeoLayer(id: string): Observable<unknown> {
    return this.progressBar.apply(this.geoService.deleteGeoLayer(id)).pipe(
      tap({
        next: () => this.layerDeletedSubject.next(id),
      })
    );
  }

  findGeoLayerById(id: string): Observable<GeoLayerModel> {
    return this.progressBar.apply(this.geoService.findGeoLayerById(id));
  }

  listGeoLayersInDomain(): Observable<Array<GeoLayerModel>> {
    return this.progressBar.apply(this.geoService.listGeoLayersInDomain());
  }

  updateGeoLayer(id: string, geoLayerModel: GeoLayerModel): Observable<unknown> {
    return this.progressBar
      .apply(this.geoService.updateGeoLayer(id, geoLayerModel))
      .pipe(
        tap({
          next: () => this.layerUpdatedSubject.next(id),
        })
      );
  }

  updateGeoLayerFeature(
    id: string,
    geoLayerFeatureModel: GeoLayerFeatureModel
  ): Observable<unknown> {
    return this.progressBar
      .apply(this.geoService.updateGeoLayerFeature(id, geoLayerFeatureModel))
      .pipe(
        tap({
          next: () => this.featureUpdatedSubject.next(id),
        })
      );
  }
}
