import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';
import { EkonPaginationModule } from '@ekon-client/shared/common/ekon-pagination';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { UserChipsAutocompleteComponent } from './components/user-chips-autocomplete/user-chips-autocomplete.component';
import { UserNameComponent } from './components/user-name/user-name.component';
import { UserNamePipe } from './pipes/user-name.pipe';

@NgModule({
  declarations: [
    UserNameComponent,
    UserNamePipe,
    UserChipsAutocompleteComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    EkonPaginationModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    EkonIconsModule,
    MatFormFieldModule,
    MatChipsModule
  ],
  exports: [
    UserNameComponent,
    UserNamePipe,
    UserChipsAutocompleteComponent
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-user-profile/',
      multi: true
    }
  ]
})
export class DkmUserProfileModule {
}
