import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  CreateNotificationTemplateModel,
  NotificationTemplateModel,
  NotificationTemplateService,
  NotificationTemplateServiceInterface
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NotificationTemplateEventsServiceInterface } from './NotificationTemplateEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class NotificationTemplateEventsService
  implements NotificationTemplateServiceInterface, NotificationTemplateEventsServiceInterface {
  get configuration(): Configuration {
    return this.notificationTemplateService.configuration;
  }

  set configuration(val: Configuration) {
    this.notificationTemplateService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.notificationTemplateService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.notificationTemplateService.defaultHeaders = val;
  }

  /**
   * The mechanism for monitoring Create action
   */
  private noteCreatedSubject: Subject<NotificationTemplateModel> = new Subject();

  get notificationCreated(): Observable<NotificationTemplateModel> {
    return this.noteCreatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Delete action
   */
  private noteDeletedSubject: Subject<string> = new Subject();

  get notificationDeleted(): Observable<string> {
    return this.noteDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Update action
   */
  private noteUpdatedSubject: Subject<string> = new Subject();

  get notificationUpdated(): Observable<string> {
    return this.noteUpdatedSubject.asObservable();
  }

  constructor(
    private notificationTemplateService: NotificationTemplateService,
    private progressBar: EkonProgressBarService
  ) {
  }

  createEmailTemplate(
    createNotificationTemplateModel?: CreateNotificationTemplateModel,
    xSelectedDomain?: string
  ): Observable<CreateNotificationTemplateModel> {
    return this.progressBar.apply(this.notificationTemplateService.createEmailTemplate(
        createNotificationTemplateModel,
        xSelectedDomain
      ))
      .pipe(
        tap((notificationTemplateModel: NotificationTemplateModel) =>
          this.noteCreatedSubject.next(notificationTemplateModel)
        )
      );
  }

  deleteEmailTemplate(id: string): Observable<unknown> {
    return this.progressBar.apply(
      this.notificationTemplateService.deleteEmailTemplate(id)
    ).pipe(tap(() => this.noteDeletedSubject.next(id)));
  }

  findEmailTemplateById(id: string): Observable<NotificationTemplateModel> {
    return this.progressBar.apply(this.notificationTemplateService.findEmailTemplateById(id));
  }

  listEmailTemplates(xSelectedDomain?: string): Observable<Array<NotificationTemplateModel>> {
    return this.progressBar.apply(this.notificationTemplateService.listEmailTemplates(xSelectedDomain));
  }

  updateEmailTemplate(
    id: string,
    notificationTemplateModel?: NotificationTemplateModel,
    xSelectedDomain?: string
  ): Observable<unknown> {
    return this.progressBar.apply(
      this.notificationTemplateService.updateEmailTemplate(id, notificationTemplateModel, xSelectedDomain)
    ).pipe(tap(() => this.noteUpdatedSubject.next(id)));
  }
}
