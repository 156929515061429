import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter, HostBinding,
  Input,
  Output
} from '@angular/core';
import { CalendarItem } from '@ekon-client/dkm-api';
import { trackById } from '@ekon-client/shared/common/ekon-utils';

import { UpdateProperty } from '../calendar-item-list-item/calendar-item-list-item.component';

@Component({
  selector: 'dkm-calendar-item-list',
  templateUrl: './calendar-item-list.component.html',
  styleUrls: ['./calendar-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarItemListComponent {
  trackByFn = trackById;

  @Input() tasks: CalendarItem[];
  @Input() viewOnly: boolean;
  @Input() minimized: boolean;
  @Input() horizontal: boolean;
  @Input() selectMode: boolean;

  @Output() view: EventEmitter<string> = new EventEmitter<string>();
  @Output() select: EventEmitter<CalendarItem> = new EventEmitter<CalendarItem>();
  @Output() edit: EventEmitter<string> = new EventEmitter<string>();
  @Output() delete: EventEmitter<CalendarItem> = new EventEmitter<CalendarItem>();
  @Output() updateProperty: EventEmitter<UpdateProperty> = new EventEmitter<
    UpdateProperty
  >();

  @HostBinding('class.horizontal') get isHorizontal(): boolean { return this.horizontal }

  editTask(id: string): void {
    this.edit.emit(id);
  }

  viewTask(id: string): void {
    this.view.emit(id);
  }

  selectTask(item: CalendarItem): void {
    this.select.emit(item);
  }

  deleteTask(task: CalendarItem): void {
    this.delete.emit(task);
  }

  updateTaskProperty(data: UpdateProperty): void {
    this.updateProperty.emit(data);
  }
}
