import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { FileDataSource, FileMediaTypes } from '../extras';
import { FileSelectDialogComponent } from '../file-select/components/file-select-dialog/file-select-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class FileSharedService {
  constructor(private dialog: MatDialog) {
  }

  selectFiles(
    fileType?: FileMediaTypes,
    filesPersonalMode?: boolean,
    domainFilter?: string[],
    aspectRatio?: number
  ): Observable<FileDataSource> {
    return this.dialog
      .open(FileSelectDialogComponent, {
        ...getModalDefaultConfig(),
        maxWidth: '650px',
        data: {
          fileType,
          filesPersonalMode,
          domainFilter: domainFilter ? domainFilter.join(',') : undefined,
          aspectRatio
        }
      })
      .afterClosed()
      .pipe(
        filter((r) => !!r)
      );
  }
}
