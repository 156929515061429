import { EkonIconDefinition } from '@ekon-client/shared/common/ekon-icons';
import { Selection, SimulationLinkDatum, SimulationNodeDatum } from 'd3';
import { Observable } from 'rxjs';

export type ForceGraphNodeKind = string | number;

export interface ForceGraphNodeSimulated<Kind extends ForceGraphNodeKind>
  extends ForceGraphNode<Kind>, SimulationNodeDatum {}

// export interface ForceGraphEdgeSimulated<Kind extends ForceGraphNodeKind>
//   extends ForceGraphEdge<Kind>, SimulationLinkDatum<ForceGraphNodeSimulated<Kind>> {}

export interface ForceGraphNode<Kind extends ForceGraphNodeKind, Data = any> {
  id: string;
  label: string;
  kind: Kind;
  isRoot?: boolean;
  data?: Data,
  children?: string[],
}

// export interface ForceGraphEdge<Kind extends ForceGraphNodeKind> {
export interface ForceGraphEdge<Data = string> {
  source: Data;
  target: Data;
}

export interface ForceGraphData<Kind extends ForceGraphNodeKind> {
  nodes: ForceGraphNodeSimulated<Kind>[];
  links: Array<ForceGraphEdge>;
}

export interface ForceGraphNodeKindConfig {
  icon: EkonIconDefinition,
  color: string,
  label: string
}

export interface ForceGraphConfig<Kind extends ForceGraphNodeKind> {
  kinds: {
    [key: ForceGraphNodeKind]: ForceGraphNodeKindConfig
  },

  /**
   * Handlers
   */
  linkTargetPredicate: (source: ForceGraphNode<Kind>, target: ForceGraphNode<Kind>) => boolean,
  linkSourcePredicate: (source: ForceGraphNode<Kind>) => boolean,
  handleLink: (source: ForceGraphNode<Kind>, target: ForceGraphNode<Kind>) => Observable<boolean>,
  handleLoadChildren: (parent: ForceGraphNode<Kind>) => Observable<ForceGraphData<Kind>>,
  handlePreview?: (node: ForceGraphNode<Kind>) => void
}

export type ForceSelectionSVGG = Selection<SVGGElement, ForceGraphNodeSimulated<ForceGraphNodeKind>, SVGGElement, undefined>;
export type ForceToolBtn = Selection<Element, ForceGraphNodeSimulated<ForceGraphNodeKind>, SVGGElement, undefined>;
