import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  CreateRssModel,
  RssDetails,
  RssService,
  RssServiceInterface,
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, of as observableOf, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { RssEventsServiceInterface } from './RssEventsServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class RssEventsService
  implements RssServiceInterface, RssEventsServiceInterface {
  get configuration(): Configuration {
    return this.rssService.configuration;
  }

  set configuration(val: Configuration) {
    this.rssService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.rssService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.rssService.defaultHeaders = val;
  }

  constructor(
    private rssService: RssService,
    private progressBar: EkonProgressBarService
  ) {}

  /**
   * The mechanism for monitoring Create action
   */
  private rssCreatedSubject: Subject<RssDetails> = new Subject();
  get rssCreated(): Observable<RssDetails> {
    return this.rssCreatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Deleted action
   */
  private rssDeletedSubject: Subject<string> = new Subject();
  get rssDeleted(): Observable<string> {
    return this.rssDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Updated action
   */
  private rssUpdatedSubject: Subject<string> = new Subject();
  get rssUpdated(): Observable<string> {
    return this.rssUpdatedSubject.asObservable();
  }

  /**
   * Creating new RSS item
   *
   * @param createRss
   * @param xSelectedDomain
   */
  createRss(createRss?: CreateRssModel, xSelectedDomain?: string): Observable<RssDetails> {
    return this.progressBar
      .apply(this.rssService.createRss(createRss, xSelectedDomain))
      .pipe(tap((res) => this.rssCreatedSubject.next(res)));
  }

  /**
   * Deleting RSS item
   *
   * @param id
   */
  deleteRss(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.rssService.deleteRss(id))
      .pipe(tap(() => this.rssDeletedSubject.next(id)));
  }

  /**
   * Getting RSS by ID
   *
   * @param id
   */
  findRssById(id: string): Observable<RssDetails> {
    return this.progressBar.apply(this.rssService.findRssById(id));
  }

  /**
   * Getting a list of RSSes
   *
   */
  listRsses(xSelectedDomain?: string): Observable<Array<RssDetails>> {
    return this.progressBar
      .apply(this.rssService.listRsses(xSelectedDomain))
      .pipe(
        catchError((error: HttpErrorResponse) =>
          error.status === 404 ? observableOf([]) : throwError(error)
        )
      );
  }

  /**
   * Updating RSS item
   *
   * @param id
   * @param rssDetails
   * @param xSelectedDomain
   */
  updateRss(id: string, rssDetails: RssDetails, xSelectedDomain?: string): Observable<unknown> {
    return this.progressBar
      .apply(this.rssService.updateRss(id, rssDetails, xSelectedDomain))
      .pipe(tap(() => this.rssUpdatedSubject.next(id)));
  }
}
