import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BlockContentService,
  BlockContentServiceInterface, BlockedContentType,
  Configuration, HiddenContentDTO
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject} from 'rxjs';
import { tap } from 'rxjs/operators';

import { BlockContentEventsServiceInterface } from './BlockContentEventsServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class BlockContentEventsService
  implements BlockContentEventsServiceInterface, BlockContentServiceInterface {
  get defaultHeaders(): HttpHeaders {
    return this.tenderService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.tenderService.defaultHeaders = val;
  }

  get configuration(): Configuration {
    return this.tenderService.configuration;
  }

  set configuration(val: Configuration) {
    this.tenderService.configuration = val;
  }

  private itemBlockedSubject = new Subject<string>();
  get itemBlocked(): Observable<string> {
    return this.itemBlockedSubject.asObservable();
  }

  private itemUnblockedSubject = new Subject<string>();
  get itemUnblocked(): Observable<string> {
    return this.itemUnblockedSubject.asObservable();
  }

  constructor(
    private tenderService: BlockContentService,
    private progressBar: EkonProgressBarService
  ) { }

  hideContent(
    contentId: string,
    contentType: BlockedContentType
  ): Observable<unknown> {
    return this.progressBar.apply(
      this.tenderService.hideContent(contentId, contentType)
    ).pipe(
      tap({
        next: () => this.itemBlockedSubject.next(contentId)
      })
    );
  }

  unblockContent(
    blockId: string,
    reason: string
  ): Observable<unknown> {
    return this.progressBar.apply(
      this.tenderService.unblockContent(blockId, reason)
    ).pipe(
      tap({
        next: () => this.itemUnblockedSubject.next(blockId)
      })
    );
  }

  hidenContent(extraHttpRequestParams?: any): Observable<Array<HiddenContentDTO>> {
    return this.progressBar.apply(
      this.tenderService.hidenContent()
    );
  }
}
