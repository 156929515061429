/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *   asc  desc  text
 */
export type SortDirection = 'asc' | 'desc' | 'text';

export const SortDirection = {
    Asc: 'asc' as SortDirection,
    Desc: 'desc' as SortDirection,
    Text: 'text' as SortDirection
};

