import { InjectionToken } from '@angular/core';
import { FileMetadata } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const FILE_ACTIONS = new InjectionToken('File model actions');
export const FILE_EVENTS = new InjectionToken('File model events');

export interface FileEventsServiceInterface {
  fileUploaded: Observable<FileMetadata>;
  fileDeleted: Observable<Blob>;
  fileMetaUpdated: Observable<string>;
}
