import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { EkonMessageService, EkonMessageTypes } from '@ekon-client/shared/common/ekon-utils';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PwaUpdateService {
  isStandalone: boolean;
  supportsInstall = false;
  platform: 'ios' | 'other' = 'other';
  browser: 'chrome' | 'safari' | 'other' = 'other';

  deferredPrompt: unknown;

  appIsStable$: Observable<boolean>;

  private _updatesReady$: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);

  public updatesReady$: Observable<boolean> = this._updatesReady$.asObservable();

  private _installHelperHidden$: BehaviorSubject<boolean> = new BehaviorSubject(
    JSON.parse(localStorage.getItem('install-message-hidden') as string) ?? false
  );

  public installHelperHidden$: Observable<boolean> = this._installHelperHidden$.asObservable();

  private pbKey = '';

  constructor(
    appRef: ApplicationRef,
    private updates: SwUpdate,
    private message: EkonMessageService,
    private progress: EkonProgressBarService,
  ) {
    window.addEventListener('beforeinstallprompt', (e: Event) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });

    const uAgent = navigator.userAgent.toLowerCase();

    if (uAgent.includes('iphone')) {
      this.platform = 'ios';
    }

    // if (uAgent.includes('android')) {
    //   this.platform = 'android';
    // }

    if (uAgent.includes('safari')) {
      this.browser = 'safari';
    }

    if (uAgent.includes('chrome')) {
      this.browser = 'chrome';
    }

    if (
      (this.platform === 'ios' && this.browser === 'safari')
      || (this.platform === 'other' && this.browser === 'chrome')
    ) {
      this.supportsInstall = true;
    }

    this.isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    this.appIsStable$ = appRef.isStable.pipe(
      tap(stable => console.log('stable', stable)),
      first(isStable => isStable)
    );


    // Service Worker
    // this.appIsStable$.pipe(
    //   switchMap(() => updates.versionUpdates),
    // )
    updates.versionUpdates
      .subscribe(evt => {
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
            this._updatesReady$.next(true);
            this.progress.hide(this.pbKey);
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            this.progress.hide(this.pbKey);
            break;
        }
      });

    this.appIsStable$.pipe(
      switchMap(() => updates.unrecoverable),
    ).subscribe(event => {
      console.error(
        'An error occurred that we cannot recover from:\n' +
        event.reason +
        '\n\nPlease reload the page.'
      );
    });


    // Web Worker
    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker(new URL('./test.worker', import.meta.url));
      worker.onmessage = ({ data }) => {
        console.log(`page got message: ${data}`);
      };
      worker.postMessage('hello');
    } else {
      // Web workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }

    this.updates.checkForUpdate();
  }

  activateUpdate(): Observable<boolean> {
    return from(this.updates.activateUpdate());
  }

  checkUpdates(): void {
    this.pbKey = this.progress.show();
    this.updates.checkForUpdate().then(updates => {
      if(!updates) {
        this.message.show('No new version released', EkonMessageTypes.INFO);
        this.progress.hide(this.pbKey);
      }
    });
  }

  showMessage(): void {
    this._installHelperHidden$.next(false);
    localStorage.removeItem('install-message-hidden');
  }

  hideMessage(): void {
    this._installHelperHidden$.next(true);
    localStorage.setItem('install-message-hidden', JSON.stringify(true));
  }

  installOrShowHelper(): void {
    if (this.supportsInstall && this.platform !== 'ios') {
      this.addToHomeScreen();
    } else {
      this.showMessage();
    }
  }

  addToHomeScreen(): void {
    this.hideMessage();

    const defPrompt =  this.deferredPrompt as {
      prompt: () => void;
      userChoice: Promise<{ outcome: string }>
    };
    // Show the prompt
    defPrompt.prompt();
    // Wait for the user to respond to the prompt
    defPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
          this.showMessage();
        }
        this.deferredPrompt = null;
      });
  }

}
