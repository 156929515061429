/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserPostPageModel } from './userPostPageModel';
import { UserPostMeetupModel } from './userPostMeetupModel';
import { UserPostProductModel } from './userPostProductModel';
import { UserPostBlogModel } from './userPostBlogModel';
import { UserPostDomainModel } from './userPostDomainModel';
import { UserPostExternalLinkModel } from './userPostExternalLinkModel';
import { UserPostLegalEntityModel } from './userPostLegalEntityModel';
import { UserPostReshareModel } from './userPostReshareModel';
import { UserPostFilesModel } from './userPostFilesModel';
import { UserPostModel } from './userPostModel';
import { UserPostPlainModel } from './userPostPlainModel';


export interface UserPostModelPagedResult { 
    items?: Array<UserPostModel | UserPostPlainModel | UserPostPageModel | UserPostProductModel | UserPostBlogModel | UserPostDomainModel | UserPostLegalEntityModel | UserPostMeetupModel | UserPostFilesModel | UserPostExternalLinkModel | UserPostReshareModel> | null;
    pageSize?: number;
    pageNumber?: number;
    totalItems?: number;
}

