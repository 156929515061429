/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EditabilityTypes } from './editabilityTypes';
import { Category } from './category';
import { ProductDetails } from './productDetails';
import { UserHeaderModel } from './userHeaderModel';
import { VisibilityTypes } from './visibilityTypes';
import { DkmDomainHeaderModel } from './dkmDomainHeaderModel';
import { LayoutDetails } from './layoutDetails';


export interface PageDetails { 
    kind: string;
    id: string;
    name: string;
    shortDescription: string;
    description?: string | null;
    isTemplate?: boolean | null;
    thumbprintUrl?: string | null;
    imageId?: string | null;
    /**
     * AllowComments: page is configured to allow users to add comments
     */
    allowComments?: boolean | null;
    /**
     * AllowFeedback: page is configured to allow users to send feedback to User owner / Site manager / Domain User
     */
    allowFeedback?: boolean | null;
    /**
     * List of emails to send page feedback to...  Could also be groups...  TODO
     */
    feedbackEmails?: Array<string> | null;
    /**
     * RequestReadAndSign  Pages that are marked as requested to be read and sign  TODO: Currently for everyone that is can see the page.  TBD: Make it for a specific group, if will comeup as business requirement.
     */
    requestReadAndSign?: boolean | null;
    /**
     * Rating - 1-5 stars  This is calculated results arrived from external analytics system, if applicable, or DKM\'s Analytics.  One use case: WeldGalaxy, calculated and arrived from UPM analytics
     */
    externalRatingResult?: number;
    /**
     * An Id from external populations.
     */
    externalId_fromPopulation?: string | null;
    tags?: Array<string> | null;
    /**
     * The Average rate of this page
     */
    averageRate?: number;
    layout?: LayoutDetails;
    lastUpdateAt?: Date;
    publishedAt?: Date | null;
    publishedBy?: string | null;
    publisher?: UserHeaderModel;
    createdBy?: string | null;
    createdAt?: Date;
    creator?: UserHeaderModel;
    /**
     * UI Only. Display page\'s categories
     */
    categories?: Array<Category> | null;
    /**
     * UI Only. Display page\'s domain/space header.
     */
    domains?: Array<DkmDomainHeaderModel> | null;
    create?: boolean;
    read?: boolean;
    update?: boolean;
    remove?: boolean;
    visibleTo?: VisibilityTypes;
    editableBy?: EditabilityTypes;
}

