import { Category } from '@ekon-client/dkm-api';

/**
 * @param categories
 * @param category
 */
export function getParentCategory(
  categories: Category[],
  category: Category
): Category {
  /**
   * @param subs
   */
  function findInSubs(subs: Category[]): Category | undefined {
    for (let i = 0; i < subs.length; i++) {
      let res = subs[i].subCategories.find(
        (c: Category) => c.id === category.id
      );
      if (res) {
        return subs[i];
      } else {
        res = findInSubs(subs[i].subCategories);
        if (res) {
          return res;
        }
      }
    }

    return undefined;
  }

  return findInSubs(categories);
}

/**
 * @param categories
 * @param current
 */
export function excludeSelfCategory(
  categories: Category[],
  current: Category
): Category[] {
  /**
   * @param subs
   */
  function excludeSelf(subs: Category[]): Category[] | boolean {
    const indexFound = subs.find((c: Category) => c.id === current.id);
    if (indexFound) {
      return subs.filter((c: Category) => c.id !== current.id);
    }

    for (let i = 0; i < subs.length; i++) {
      // todo check&fix
      // let _excl;
      const res =
        subs[i].subCategories && subs[i].subCategories.length
          ? (/*_excl = */excludeSelf(subs[i].subCategories))
          : false;
      if (res && res !== true) {
        subs[i].subCategories = res;
      }
      if (res) {
        return true;
      }
    }

    return false;
  }

  const resCats = excludeSelf(categories);
  return resCats === true ? categories : (resCats as Category[]);
}

/**
 * @param categories
 * @param predicate
 */
export function getAllCategoriesByCondition(
  categories: Category[],
  predicate: (i: Category) => boolean
): Category[] {
  /**
   * @param subs
   */
  function findInSubs(subs: Category[]): Category[] {
    let res: Category[] = [];
    for (let i = 0; i < subs.length; i++) {
      if (predicate(subs[i])) {
        res.push(subs[i]);
      }
      res = [...res, ...findInSubs(subs[i].subCategories)];
    }

    return res;
  }

  return findInSubs(categories);
}
/**
 * @param categories
 * @param predicate
 */
export function findFirstOccurance(
  categories: Category[],
  predicate: (i: Category) => boolean
): Category {
  /**
   * @param subs
   */
  function findInSubs(subs: Category[]): Category {
    for (let i = 0; i < subs.length; i++) {
      if (predicate(subs[i])) {
        return subs[i];
      } else {
        if (subs[i].subCategories.length) {
          const res = findInSubs(subs[i].subCategories);
          if (res) {
            return res;
          }
        }
      }
    }

    return undefined;
  }

  return findInSubs(categories);
}
