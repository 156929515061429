/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EditabilityTypes } from './editabilityTypes';
import { UserHeaderModel } from './userHeaderModel';
import { VisibilityTypes } from './visibilityTypes';


export interface DkmDomain { 
    id: string;
    name: string;
    shortDescription: string;
    description?: string | null;
    publishedAt?: Date | null;
    publishedBy?: string | null;
    isPublished?: boolean | null;
    legalEntityId?: string | null;
    createdBy?: string;
    createdAt?: Date | null;
    updatedBy?: string | null;
    lastUpdateAt?: Date | null;
    thumbprintUrl?: string | null;
    imageUrl?: string | null;
    create?: boolean;
    read?: boolean;
    update?: boolean;
    remove?: boolean;
    creator?: UserHeaderModel;
    visibleTo?: VisibilityTypes;
    editableBy?: EditabilityTypes;
}

