import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CalendarServiceInterface } from '@ekon-client/dkm-api';
import { BreadcrumbTitleResolver } from '@ekon-client/shared/common/ekon-breadcrumbs';
import { map } from 'rxjs/operators';

import { CALENDAR_ACTIONS } from '../CalendarEventsServiceInterface';


export const CalendarBreadcrumbResolver: BreadcrumbTitleResolver = (route: ActivatedRouteSnapshot, inj: Injector) =>
  (inj.get(CALENDAR_ACTIONS) as CalendarServiceInterface).findCalendarItemById(route.params.id)
    .pipe(
      map(p => p.name)
    );
