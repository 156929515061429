import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { EkonDatatableFlexDirective } from './directives/ekon-datatable-flex.directive';
import { EkonInitialsPipe } from './pipes/ekon-initials.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  exports: [
    EkonInitialsPipe,
    EkonDatatableFlexDirective,
    TranslateModule
  ],
  declarations: [EkonInitialsPipe, EkonDatatableFlexDirective],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/common/ekon-utils/',
      multi: true
    }
  ]
})
export class EkonUtilsModule {}
