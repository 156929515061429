import { ChangeDetectionStrategy, Component, Inject, QueryList } from '@angular/core';
import { MatListOption } from '@angular/material/list/selection-list';
import { DkmDomain, DkmDomainPagedResult,DomainServiceInterface } from '@ekon-client/dkm-api';
import { DOMAIN_ACTIONS } from '@ekon-client/dkm-events';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { PaginationModel, PaginatorConfig } from '@ekon-client/shared/common/ekon-pagination';
import { DomainSelectorService } from '@ekon-client/shared/features/dkm-domains-services';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';


@Component({
  selector: 'dkm-domain-selector-dialog',
  templateUrl: './domain-selector-dialog.component.html',
  styleUrls: ['./domain-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainSelectorDialogComponent {
  trackById = (index: number, item: DkmDomain): string => item.id;
  paginatorConfig: PaginatorConfig = {
    loader: (pagination: PaginationModel): Observable<DkmDomainPagedResult> =>
      this.domainActions.listUserAvailableDomains(pagination)
  };

  constructor(
    @Inject(DOMAIN_ACTIONS) private domainActions: DomainServiceInterface,
    private domainSelectorService: DomainSelectorService,
    public globalConfig: GlobalConfigurationService
  ) {
  }

  domainSelectionChanged(selectedOptions: MatListOption[], options: QueryList<MatListOption>): void {
    const visibleSelection = selectedOptions.map((option: MatListOption) => option.value);
    const unselected = options
      .filter((o: MatListOption) => !o.selected)
      .map((o: MatListOption) => o.value);

    this.domainSelectorService.domainsSelected$
      .pipe(
        take(1),
        map((selection: DkmDomain[]) =>
          selection.filter(d => !unselected.some(u => u.id === d.id) && !visibleSelection.some(v => v.id === d.id))
        )
      )
      .subscribe({
        next: (restSelection: DkmDomain[]) => this.domainSelectorService.setDomains([
          ...visibleSelection,
          ...restSelection
        ])
      });
  }

  checkInitialSelection(domainId: string): Observable<boolean> {
    return this.domainSelectorService.domainsSelected$.pipe(
      take(1),
      map((domains: DkmDomain[]) =>
        Boolean(domains && domains.find(d => d.id === domainId))
      )
    );
  }
}
