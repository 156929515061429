/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for Feedback a  Excellent  Great  Good  Fair  Poor  ExtremelyLikely  Likely  NeitherLikelyNorUnlikely  Unlikely  ExtremelyUnlikely  DonnotKnow  VeryEasy  Easy  Neither  Difficult  VeryDifficult  Other
 */
export type FeedbackAnswers = 'Excellent' | 'Great' | 'Good' | 'Fair' | 'Poor' | 'ExtremelyLikely' | 'Likely' | 'NeitherLikelyNorUnlikely' | 'Unlikely' | 'ExtremelyUnlikely' | 'DonnotKnow' | 'VeryEasy' | 'Easy' | 'Neither' | 'Difficult' | 'VeryDifficult' | 'Other';

export const FeedbackAnswers = {
    Excellent: 'Excellent' as FeedbackAnswers,
    Great: 'Great' as FeedbackAnswers,
    Good: 'Good' as FeedbackAnswers,
    Fair: 'Fair' as FeedbackAnswers,
    Poor: 'Poor' as FeedbackAnswers,
    ExtremelyLikely: 'ExtremelyLikely' as FeedbackAnswers,
    Likely: 'Likely' as FeedbackAnswers,
    NeitherLikelyNorUnlikely: 'NeitherLikelyNorUnlikely' as FeedbackAnswers,
    Unlikely: 'Unlikely' as FeedbackAnswers,
    ExtremelyUnlikely: 'ExtremelyUnlikely' as FeedbackAnswers,
    DonnotKnow: 'DonnotKnow' as FeedbackAnswers,
    VeryEasy: 'VeryEasy' as FeedbackAnswers,
    Easy: 'Easy' as FeedbackAnswers,
    Neither: 'Neither' as FeedbackAnswers,
    Difficult: 'Difficult' as FeedbackAnswers,
    VeryDifficult: 'VeryDifficult' as FeedbackAnswers,
    Other: 'Other' as FeedbackAnswers
};

