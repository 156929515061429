import { Injectable } from '@angular/core';
import { uniqBy as _uniqBy } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export interface EkonNavigationHistoryItem {
  path: string;
  title: string;
  kind: string;
}

@Injectable({
  providedIn: 'root'
})
export class EkonNavigationHistoryService {

  private _history$: BehaviorSubject<EkonNavigationHistoryItem[]>;
  public history$: Observable<EkonNavigationHistoryItem[]>;

  constructor() {
    this._history$ = new BehaviorSubject(JSON.parse(localStorage.getItem('ek-navigation-history')) ?? []);
    this.history$ = this._history$.asObservable();
  }

  addRecord(path: string, title: string, kind: string): void {
    this._history$.pipe(take(1)).subscribe({
      next: history => {
        history.unshift({ path, title, kind });
        history = _uniqBy(history, i => i.path.split('?')[0].split('/').pop()).slice(0, 15);

        this._history$.next(history);
        localStorage.setItem('ek-navigation-history', JSON.stringify(history))
      }
    })
  }
}
