import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";

import { EkonAuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class EkonAuthRedirectGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private authService: EkonAuthService, private router: Router) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.checkAuthorization(state.url);
  }

  canLoad(_route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    const url = segments.map(segment => segment.path).join('/');
    return this.checkAuthorization(`/${url}`);
  }

  canActivateChild(_childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.checkAuthorization(state.url);
  }

  private checkAuthorization(fullUrl: string): Observable<boolean | UrlTree> {
    console.log("EkonAuthRedirectGuard checkAuthorization path: " + fullUrl);

    return this.authService.isLoggedIn$.pipe(
      take(1),
      switchMap(isLoggedIn => {
        // Check for OAuth2 redirect (state, session_state, code in URL)
        const isAuthRedirect = fullUrl.includes('state=') && fullUrl.includes('session_state=') && fullUrl.includes('code=');

        if (isAuthRedirect) {
          console.log("EkonAuthRedirectGuard isAuthRedirect: true, trying to login");
          return this.authService.loadDocAndTryLogin().pipe(
            take(1),
            switchMap(() =>
              // Redirect based on login status after trying login
              this.authService.isLoggedIn$.pipe(
                take(1),
                map(loggedIn => loggedIn ? this.router.createUrlTree(['/dashboard']) : this.router.createUrlTree(['/landing']))
              )
            ));
        }
        else if (isLoggedIn && (fullUrl === '/landing' || fullUrl === '/')) {
          console.log("EkonAuthRedirectGuard isLoggedIn: true, redirecting to dashboard");
          return of(this.router.createUrlTree(['/dashboard']));  // User is logged-in redirect to dashboard
        }
        else if (!isLoggedIn && fullUrl === '/') {
          console.log("EkonAuthRedirectGuard isLoggedIn: false, redirecting to landing");
          return of(this.router.createUrlTree(['/landing']));;  // User proceeds to landing, allow navigation
        }
        else if (isLoggedIn || (!isLoggedIn && fullUrl === '/landing')) {
          console.log("EkonAuthRedirectGuard isLoggedIn, continue to: " + fullUrl, isLoggedIn);
          return of(true);  // User is logged-in or proceeds to landing, allow navigation
        }

        // No user and no OAuth2 redirect params, start login process
        const redirectUri = window.location.origin + fullUrl;
        console.log("EkonAuthRedirectGuard trying to login and return to: " + redirectUri);
        this.authService.login(redirectUri);
        return of(false); // Block navigation until login is completed
      })
    );
  }

}
