import {
  Component,
  ElementRef,
  Inject,
  InjectionToken,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from 'geojson';
import {
  get as _get,
  isArray as _isArray,
  map as _map,
  omit as _omit,
  pick as _pick
} from 'lodash-es';
import { GeoJSONFeature } from 'ol/format/GeoJSON';
import { Observable, Subject } from 'rxjs';

export interface MapObjectData {
  id?: string;
  name?: string;
  pages?: string[];
  tags?: string[];
  properties?: Array<{
    name: string;
    value: string;
  }>;
}

export interface MapMarkerDialogResult {
  action: MAP_DIALOG_ACTIONS;
  data?: GeoJSONFeature;
}

export enum MAP_DIALOG_ACTIONS {
  DELETE = 'Delete',
  SAVE = 'Save',
}

export const MAP_FEATURE_FORM_DATA = new InjectionToken('MapFeatureFormData');

export type SimpleShape =
  | Point
  | MultiPoint
  | LineString
  | MultiLineString
  | Polygon
  | MultiPolygon;

@Component({
  selector: 'dkm-map-feature-form',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss']
})
export class MapDialogComponent {
  form: FormGroup;
  isArray = _isArray;

  private _onCloseDialog: Subject<MapMarkerDialogResult> = new Subject();
  get onCloseDialog(): Observable<MapMarkerDialogResult> {
    return this._onCloseDialog.asObservable();
  }

  get propertiesControls(): AbstractControl[] {
    return (this.form.get('properties') as FormArray).controls;
  }

  get location(): unknown {
    return (this.feature.geometry as SimpleShape).coordinates;
  }

  constructor(
    private fb: FormBuilder,
    /*@Inject(MAP_FEATURE_FORM_DATA) public feature: GeoJSONFeature,*/
    @Inject(MAT_DIALOG_DATA) public feature: GeoJSONFeature,
    private dialogRef: MatDialogRef<MapDialogComponent>,
    public elRef: ElementRef
  ) {
    console.log(feature);
    this.form = fb.group({
      id: [_get(feature, 'id')],
      name: [_get(feature, 'properties.name')],
      pages: [_get(feature, 'properties.pages')],
      tags: [_get(feature, 'properties.tags')],
      properties: fb.array([
        ..._map(_omit(feature.properties, ['name', 'tags', 'pages']), (v, k) =>
          fb.group({
            name: k,
            value: v,
          })
        ),
      ]),
    });
  }

  addProperty(): void {
    (this.form.get('properties') as FormArray).push(
      this.fb.group({
        name: null,
        value: null,
      })
    );
  }

  applyChanges(): void {
    const formData: MapObjectData = this.form.getRawValue();
    this.closeDialog({
      action: MAP_DIALOG_ACTIONS.SAVE,
      data: {
        ...this.feature,
        properties: {
          ..._pick(formData, ['name', 'pages', 'tags']),
          ...formData.properties.reduce((acc, val) => {
            acc[val.name] = val.value;
            return acc;
          }, {}),
        },
      },
    });
  }

  deleteMarker(): void {
    this.closeDialog({ action: MAP_DIALOG_ACTIONS.DELETE });
  }

  cancelChanges(): void {
    this.closeDialog();
  }

  private closeDialog(result?: MapMarkerDialogResult): void {
    this.dialogRef.close(result);
    /*this._onCloseDialog.next(result);
    this._onCloseDialog.complete();*/
  }
}
