import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PageServiceInterface } from '@ekon-client/dkm-api';
import { BreadcrumbTitleResolver } from '@ekon-client/shared/common/ekon-breadcrumbs';
import { map } from 'rxjs/operators';

import { PRODUCT_ACTIONS } from '../ProductEventsServiceInterface';

export const PageBreadcrumbResolver: BreadcrumbTitleResolver = (route: ActivatedRouteSnapshot, inj: Injector) =>
  (inj.get(PRODUCT_ACTIONS) as PageServiceInterface).findPageById(route.params.id)
    .pipe(
      map(p => p.name)
    );
