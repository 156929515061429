/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TextSection } from './textSection';
import { SubscriptionPlan } from './subscriptionPlan';
import { Article } from './article';
import { Link } from './link';


/**
 * LandingPage data in contract
 */
export interface LandingPage { 
    id: string;
    sections?: Array<TextSection> | null;
    links?: Array<Link> | null;
    articles?: Array<Article> | null;
    subscriptionPlans?: Array<SubscriptionPlan> | null;
    /**
     * ToU = Term Of Use
     */
    touVersion?: string | null;
    touDocument?: string | null;
    /**
     * Pp = Privacy Policy
     */
    ppVersion?: string | null;
    ppDocument?: string | null;
    /**
     * Community Quide Lines
     */
    guideLines?: string | null;
    guideLinesVersion?: string | null;
}

