import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileMetadata, UpdateFileMetadata } from '@ekon-client/dkm-api';
import { get as _get, isEqual as _isEqual } from 'lodash-es';

@Component({
  selector: 'dkm-file-edit-dialog',
  templateUrl: './file-edit-dialog.component.html',
  styleUrls: ['./file-edit-dialog.component.scss'],
})
export class FileEditDialogComponent {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<FileEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FileMetadata
  ) {
    this.form = this.fb.group({
      id: [_get(this.data, 'id'), Validators.required],
      name: [_get(this.data, 'name'), Validators.required],
      shortDescription: _get(this.data, 'shortDescription'),
      tags: [_get(this.data, 'tags')],
    });
  }

  validateAndSave(): void {
    if (this.form.valid) {
      const formData = this.form.getRawValue() as UpdateFileMetadata;
      formData.nameModified = formData.name !== this.data.name;
      formData.shortDescriptionModified =
        formData.shortDescription !== this.data.shortDescription;
      formData.descriptionModified =
        formData.description !== this.data.description;
      formData.tagsModified = !_isEqual(formData.tags, this.data.tags);

      this.dialogRef.close(formData);
    }
  }
}
