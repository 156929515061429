/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CreateProductPageModel } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { PageDetails } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';
import { ProductDetails } from '../model/models';
import { ProductHeaderPagedProductResult } from '../model/models';
import { ProductHeaderPagedResult } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    ProductServiceInterface
} from './product.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class ProductService implements ProductServiceInterface {

    protected basePath = 'http://dkm-pl.ekonm.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param createProductPageModel 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProduct(createProductPageModel?: CreateProductPageModel, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<ProductDetails>;
    public createProduct(createProductPageModel?: CreateProductPageModel, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<ProductDetails>>;
    public createProduct(createProductPageModel?: CreateProductPageModel, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<ProductDetails>>;
    public createProduct(createProductPageModel?: CreateProductPageModel, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProductDetails>(`${this.configuration.basePath}/api/protected/v1/product`,
            createProductPageModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create from population batch.
     * @param pageDetailsProductDetails 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProductFromPopulation(pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageDetails | ProductDetails>;
    public createProductFromPopulation(pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageDetails | ProductDetails>>;
    public createProductFromPopulation(pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageDetails | ProductDetails>>;
    public createProductFromPopulation(pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageDetails | ProductDetails>(`${this.configuration.basePath}/api/protected/v1/product/create/procuct/from/population`,
            pageDetailsProductDetails,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param kbeId 
     * @param filterByDomains 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listProductPagesByKbe(kbeId: string, filterByDomains: boolean, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<ProductHeaderPagedProductResult>;
    public listProductPagesByKbe(kbeId: string, filterByDomains: boolean, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<ProductHeaderPagedProductResult>>;
    public listProductPagesByKbe(kbeId: string, filterByDomains: boolean, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<ProductHeaderPagedProductResult>>;
    public listProductPagesByKbe(kbeId: string, filterByDomains: boolean, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (kbeId === null || kbeId === undefined) {
            throw new Error('Required parameter kbeId was null or undefined when calling listProductPagesByKbe.');
        }
        if (filterByDomains === null || filterByDomains === undefined) {
            throw new Error('Required parameter filterByDomains was null or undefined when calling listProductPagesByKbe.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProductHeaderPagedProductResult>(`${this.configuration.basePath}/api/protected/v1/product/kbe/${encodeURIComponent(String(kbeId))}/filter/${encodeURIComponent(String(filterByDomains))}`,
            pagePaginationModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param legalEntityId 
     * @param filterByDomains 
     * @param isTemplate 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listProductPagesByLegalEntity(legalEntityId: string, filterByDomains: boolean, isTemplate: boolean, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<ProductHeaderPagedResult>;
    public listProductPagesByLegalEntity(legalEntityId: string, filterByDomains: boolean, isTemplate: boolean, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<ProductHeaderPagedResult>>;
    public listProductPagesByLegalEntity(legalEntityId: string, filterByDomains: boolean, isTemplate: boolean, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<ProductHeaderPagedResult>>;
    public listProductPagesByLegalEntity(legalEntityId: string, filterByDomains: boolean, isTemplate: boolean, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (legalEntityId === null || legalEntityId === undefined) {
            throw new Error('Required parameter legalEntityId was null or undefined when calling listProductPagesByLegalEntity.');
        }
        if (filterByDomains === null || filterByDomains === undefined) {
            throw new Error('Required parameter filterByDomains was null or undefined when calling listProductPagesByLegalEntity.');
        }
        if (isTemplate === null || isTemplate === undefined) {
            throw new Error('Required parameter isTemplate was null or undefined when calling listProductPagesByLegalEntity.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ProductHeaderPagedResult>(`${this.configuration.basePath}/api/protected/v1/product/legalentity/legalentity/${encodeURIComponent(String(legalEntityId))}/filter/${encodeURIComponent(String(filterByDomains))}/template/${encodeURIComponent(String(isTemplate))}`,
            pagePaginationModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
