import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { SharingOptionsDialogComponent } from './components/sharing-options-dialog/sharing-options-dialog.component';



@NgModule({
  declarations: [
    SharingOptionsDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    TranslateModule,
    MatTabsModule,
    MatRadioModule
  ],
  exports: [
    SharingOptionsDialogComponent
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-partials/src/lib/dialogs/',
      multi: true
    }
  ]
})
export class EkonPartialsDialogsModule { }
