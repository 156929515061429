import { Direction } from '@angular/cdk/bidi';
import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { EkonLayoutConfigService } from '@ekon-layout-utils/services';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutsConfigService {
  defaultLang: string;

  private langDirection$: BehaviorSubject<Direction>;
  langDirection: Observable<Direction>;

  constructor(
    private _translateService: TranslateService,
    private ekonLayoutConfigService: EkonLayoutConfigService,
    private globalConfig: GlobalConfigurationService,
    private meta: Meta
  ) {
    this.syncLocalSettings();

    this.langDirection$ = new BehaviorSubject(
      this.getLangDir(this.defaultLang)
    );
    this.langDirection = this.langDirection$.asObservable();
    _translateService.onLangChange
      .pipe(
        distinctUntilChanged((x: LangChangeEvent, y: LangChangeEvent) => x.lang === y.lang)
      ).subscribe((evt: LangChangeEvent) => {
      this.langDirection$.next(this.getLangDir(evt.lang));
    });
  }

  private getLangDir(lang: string): Direction {
    let dir;
    if (this.globalConfig.langAvailable) {
      dir = this.globalConfig.langAvailable[lang.toUpperCase()].direction;
    }
    return dir || 'ltr';
  }

  private syncLocalSettings(): void {
    this.defaultLang =
      localStorage.getItem('locale') || this.globalConfig.langDefault;

    let colorTheme = localStorage.getItem('colorTheme');
    if (!colorTheme || !this.globalConfig.colorThemes.some(theme => theme === colorTheme)) {
      colorTheme = this.globalConfig.colorThemes[0];
      localStorage.setItem('colorTheme', colorTheme);
    }

    this.ekonLayoutConfigService.setConfig({
      colorTheme,
      layout: {
        style:
          localStorage.getItem('layoutStyle') ||
          this.ekonLayoutConfigService.defaultConfig.layout.style
      }
    });

    this.meta.updateTag({ content: colorTheme.toLowerCase().includes('dark', colorTheme.length - 6) ? '#424242' : 'whitesmoke'}, 'name=theme-color');

  }
}
