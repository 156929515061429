/* 🤖 this file was generated by svg-to-ts*/

export type EkonIconType =
  | '3-rd'
  | 'add-square'
  | 'add-cart'
  | 'add-to-basket'
  | 'admin-panel'
  | 'analytics'
  | 'angle-left'
  | 'angle-right'
  | 'arc'
  | 'arrow-double-down'
  | 'arrow-double-left'
  | 'arrow-double-right'
  | 'arrow-double-up'
  | 'arrows-alt'
  | 'arw'
  | 'attachment'
  | 'attendee'
  | 'bars'
  | 'bell'
  | 'bid'
  | 'blogs'
  | 'book'
  | 'boolean'
  | 'box'
  | 'briefcase'
  | 'build'
  | 'calendar-day'
  | 'calendar-event'
  | 'calendar-plus'
  | 'calendar-week'
  | 'calendar-alt'
  | 'calendar'
  | 'cart'
  | 'catalog'
  | 'chat'
  | 'check-square'
  | 'chevron-down'
  | 'chevron-up'
  | 'clock'
  | 'clone'
  | 'cog'
  | 'color-picker'
  | 'comment-reply'
  | 'comments'
  | 'completion'
  | 'compress-alt'
  | 'connect'
  | 'cool'
  | 'cropper'
  | 'cs'
  | 'css'
  | 'csv'
  | 'curious'
  | 'currency'
  | 'date-time'
  | 'diagram'
  | 'direct-message'
  | 'doc'
  | 'docx'
  | 'download'
  | 'dropdown'
  | 'edit'
  | 'element-type'
  | 'ellipsis-v'
  | 'event'
  | 'exclamation-triangle'
  | 'exclamation'
  | 'expand-arrows-alt'
  | 'external-link-alt'
  | 'file-view'
  | 'file'
  | 'filter'
  | 'flac'
  | 'flag-de'
  | 'flag-fr'
  | 'flag-hu'
  | 'flag-il'
  | 'flag-ru'
  | 'flag-ua'
  | 'flag-uk'
  | 'flag-us'
  | 'folder-open'
  | 'folder'
  | 'gif'
  | 'glasses'
  | 'grid-list'
  | 'grid-view'
  | 'hand-holding-heart'
  | 'hand-sparkles'
  | 'heart-outline'
  | 'heart'
  | 'home'
  | 'hourglass'
  | 'house-user'
  | 'html'
  | 'id-card'
  | 'iframe-value'
  | 'image'
  | 'infinite-list'
  | 'info'
  | 'input'
  | 'insightful'
  | 'ios-share'
  | 'jpeg'
  | 'jpg'
  | 'js'
  | 'json'
  | 'laptop'
  | 'layer-group'
  | 'lightbulb-on'
  | 'like'
  | 'link'
  | 'location-outline'
  | 'lock'
  | 'logout'
  | 'love'
  | 'mail'
  | 'map-marked-alt'
  | 'media'
  | 'meh'
  | 'minus'
  | 'mov'
  | 'move'
  | 'mp-3'
  | 'mp-4'
  | 'my-space'
  | 'newspaper'
  | 'notes'
  | 'numeric'
  | 'ontology'
  | 'open-in-new'
  | 'pages'
  | 'palette-outline'
  | 'palette'
  | 'pdf'
  | 'pencil-ruler'
  | 'phone-outline'
  | 'plus'
  | 'png'
  | 'posts'
  | 'priority'
  | 'project-diagram'
  | 'question'
  | 'raw'
  | 'redo'
  | 'related-posts'
  | 'report'
  | 'rich-text'
  | 'scss'
  | 'search'
  | 'sentiment'
  | 'share-outline'
  | 'share-square'
  | 'shipping-fast'
  | 'shopping-bag'
  | 'shopping-cart'
  | 'simulation'
  | 'sorting-asc'
  | 'sorting-desc'
  | 'sorting'
  | 'square'
  | 'star-empty'
  | 'star-full'
  | 'star-half'
  | 'sticky-note'
  | 'stores'
  | 'subscription'
  | 'support'
  | 'tags'
  | 'task-outlined'
  | 'task'
  | 'template'
  | 'text-area'
  | 'text-input'
  | 'thumbs-up'
  | 'times-circle'
  | 'times'
  | 'trash-alt'
  | 'trash-full'
  | 'ts'
  | 'unlock'
  | 'upload'
  | 'user-plus'
  | 'user'
  | 'value'
  | 'vector-square'
  | 'work-outline'
  | 'youtube';
export interface EkonIconDefinition {
  name: EkonIconType;
  data: string;
}
