import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockedContentType, DomainServiceInterface } from '@ekon-client/dkm-api';
import { DOMAIN_ACTIONS } from '@ekon-client/dkm-events';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { BlockNReportService } from '@ekon-client/shared/features/ekon-block-n-report';
import { Observable } from 'rxjs';

@Component({
  selector: 'ekon-sharing-actions-menu',
  templateUrl: './sharing-actions-menu.component.html',
  styles: [`
    :host {
      display: flex;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})



export class SharingActionsMenuComponent {

  @Input() enableBlock?: boolean;
  @Input() blockContentId?: string;
  @Input() blockContentType?: BlockedContentType;
  @Input() useAsReason?: string;
  @Input() showRelationsGraph?: boolean;



  constructor(
    public globalConfig: GlobalConfigurationService,
    private blockNReport: BlockNReportService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOMAIN_ACTIONS) private domainActions: DomainServiceInterface,
  ) { }



  report(): void {
    if(this.enableBlock && this.blockContentId && this.blockContentType) {
      this.blockNReport.report(this.blockContentId, this.blockContentType).subscribe({
        next: () => this.router.navigate(['../../'], { relativeTo: this.route })
      });
    }
  }

  block() {
    if(this.enableBlock && this.blockContentId && this.blockContentType) {
      this.blockNReport.block(this.blockContentId, this.blockContentType, this.useAsReason).subscribe({
        next: () => this.router.navigate(['../../'], { relativeTo: this.route })
      });
    }
  }
}
