/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CreateDomainModel } from '../model/models';
import { CreateFeedback } from '../model/models';
import { DkmDomain } from '../model/models';
import { DkmDomainPagedResult } from '../model/models';
import { DomainUserJoinWithdrawModel } from '../model/models';
import { FeedbackDetails } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { LegalEntityJoinWithdrawModel } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';
import { UserDomainRelationType } from '../model/models';
import { UserDomainRelations } from '../model/models';
import { UserProfileHeader } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface DomainServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param createDomainModel 
     */
    createDomain(createDomainModel?: CreateDomainModel, extraHttpRequestParams?: any): Observable<DkmDomain>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     * @param createFeedback 
     */
    createDomainFeedback(id: string, createFeedback?: CreateFeedback, extraHttpRequestParams?: any): Observable<FeedbackDetails>;

    /**
     * Temporary removed.
     * 
     * @param id 
     */
    deleteDomain(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     */
    findDomainById(id: string, extraHttpRequestParams?: any): Observable<DkmDomain>;

    /**
     * Join or redraw a Legal Entity from a Domain
     * 
     * @param id 
     * @param legalEntityJoinWithdrawModel 
     */
    legalEntityJoinWithrawDomain(id: string, legalEntityJoinWithdrawModel?: LegalEntityJoinWithdrawModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param types 
     * @param legalEntityId 
     */
    listByCreteria(pagePaginationModel?: PagePaginationModel, types?: Array<UserDomainRelationType>, legalEntityId?: string, extraHttpRequestParams?: any): Observable<DkmDomainPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param legalEntityId 
     * @param pagePaginationModel 
     */
    listByLegalEntity(legalEntityId: string, pagePaginationModel?: PagePaginationModel, extraHttpRequestParams?: any): Observable<DkmDomainPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     */
    listDomains(pagePaginationModel?: PagePaginationModel, extraHttpRequestParams?: any): Observable<DkmDomainPagedResult>;

    /**
     * Load domain details for requested domain id&#x60;s
     * 
     * @param requestBody array of domain id
     */
    listDomainsByIds(requestBody?: Array<string>, extraHttpRequestParams?: any): Observable<Array<DkmDomain>>;

    /**
     * Get list of users related to domain
     * 
     * @param id array of domain id
     */
    listDomainsRelatedUsers(id: string, extraHttpRequestParams?: any): Observable<Array<UserProfileHeader>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     */
    listProtectedDomains(pagePaginationModel?: PagePaginationModel, extraHttpRequestParams?: any): Observable<DkmDomainPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     */
    listUserAvailableDomains(pagePaginationModel?: PagePaginationModel, extraHttpRequestParams?: any): Observable<DkmDomainPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     */
    listUserRelationDomains(extraHttpRequestParams?: any): Observable<Array<UserDomainRelations>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     * @param dkmDomain 
     */
    updateDomain(id: string, dkmDomain?: DkmDomain, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param domainId 
     * @param newLegalEntityId 
     */
    updateLegalEntityInDomain(domainId: string, newLegalEntityId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Join or redraw a user from a Domain
     * 
     * @param id 
     * @param domainUserJoinWithdrawModel 
     */
    userJoinWithrawDomain(id: string, domainUserJoinWithdrawModel?: DomainUserJoinWithdrawModel, extraHttpRequestParams?: any): Observable<{}>;

}
