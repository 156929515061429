import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter, Input,
  Output
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DkmDomainHeaderModel } from '@ekon-client/dkm-api';
import { EkonDialogsService } from '@ekon-client/shared/common/ekon-dialogs';
import { isString as _isString } from 'lodash-es';
import { filter } from 'rxjs/operators';

import { AvaViewerComponent } from '../ava-viewer/ava-viewer.component';
import { FileDataSource } from '../extras/FileDataSource';
import { FileMediaTypes } from '../extras/FileTypesWeCanView';
import { FileService } from '../services/file.service';
import { FileCacheService } from '../services/file-cache.service';
import { FileSharedService } from '../services/file-shared.service';

@Component({
  selector: 'dkm-core-ava-setter',
  templateUrl: './ava-setter.component.html',
  styleUrls: ['./ava-setter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// todo: make form control
export class AvaSetterComponent extends AvaViewerComponent {
  @Input() aspectRatio?: number;
  @Input() filesPersonalMode: boolean;

  @Input() domainFilter: string[] | DkmDomainHeaderModel[];

  @Output() imageChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    protected filesService: FileSharedService,
    protected fileService: FileService,
    protected dialogsService: EkonDialogsService,
    sanitizer: DomSanitizer,
    protected fileCache: FileCacheService,
    protected elRef: ElementRef
  ) {
    super(
      fileService,
      dialogsService,
      sanitizer,
      fileCache,
      false,
      elRef
    );
  }

  changeImage(): void {
    let domainFilter: string[];
    if (this.domainFilter) {
      domainFilter = _isString(this.domainFilter[0])
        ? (this.domainFilter as string[])
        : (this.domainFilter as DkmDomainHeaderModel[]).map(d => d.id)
    }
    this.filesService.selectFiles(
      FileMediaTypes.IMAGE,
      this.filesPersonalMode,
      domainFilter,
      this.aspectRatio
    )
      // .pipe(
      //   switchMap((fds: FileDataSource) => this.fileService.loadFileBase64(fds.file.id)),
      //   switchMap(dataLink => this.fileService.limitImageSize(dataLink, 256))
      // )
      .subscribe({
        next: (fds: FileDataSource) => this.imageChange.emit(fds.file.id)
        // next: (dataLink: string) => this.imageChange.emit(dataLink)
      });
  }

  clearImage(): void {
    this.dialogsService
      .confirm('You are about to delete image', 'Delete image?')
      .pipe(filter((r) => Boolean(r)))
      .subscribe({
        next: () => this.imageChange.emit(null)
      });
  }
}
