import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input, OnDestroy,
  OnInit
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import {
  dkmFadeInOut,
  ekonFade,
  expandCollapseHorizontalExtended,
  slideInOut
} from '@ekon-client/shared/common/ekon-utils';
import { Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { SidebarMeta, SidebarService } from '../../../services/sidebar.service';

@Component({
  selector: 'ekon-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    expandCollapseHorizontalExtended,
    dkmFadeInOut,
    slideInOut,
    ekonFade,

    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ background: 'red', width: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ background: 'green', width: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ width: 300, opacity: 1 }),
            animate('1s ease-in',
              style({ width: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() key: string;
  @Input() position: 'left' | 'right' = 'left';
  @Input() visible: boolean;
  @Input() fixed: boolean;

  @HostBinding('class.fixed-position') get fixedPosition(): boolean {
    return this.fixed;
  }

  @HostBinding('class.left-position') get leftPosition(): boolean {
    return this.position === 'left';
  }

  @HostBinding('class.right-position') get rightPosition(): boolean {
    return this.position === 'right';
  }

  @HostBinding('style.display') get display(): string {
    return this.isAnimationProcess ? null : 'none';
  }

  @HostBinding('@expandCollapseHorizontalExtended')
    // @HostBinding('@ekonFade')
  animationState: '*' | 'void' = 'void';

  meta: Observable<SidebarMeta>;

  isAnimationProcess = 'none';

  unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private sidebarService: SidebarService,
    private mediaObserver: MediaObserver
  ) {
  }

  ngOnInit(): void {
    this.meta = this.sidebarService.register({
      key: this.key,
      visible: this.mediaObserver.isActive('gt-xs') && this.visible,
      initialVisibility: this.visible
    })
      .pipe(
        tap((meta: SidebarMeta) => {
          this.animationState = meta.visible ? '*' : 'void';
        }),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    this.mediaObserver.asObservable()
      .pipe(
        withLatestFrom(this.meta),
        // tap(() => console.warn('mediaObserver', this.mediaObserver.isActive('lt-md')))
        takeUntil(this.unsubscribeAll)
      )
      .subscribe({
        next: ([, meta]: [unknown, SidebarMeta]) => {
          if (this.mediaObserver.isActive('lt-md')) {
            meta.visible && this.sidebarService.hide(this.key)
          } else {
            meta.initialVisibility && !meta.visible && this.sidebarService.show(this.key)
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();

    this.sidebarService.unregister(this.key);
  }

  hide(): void {
    this.sidebarService.hide(this.key);
  }
}
