import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent, MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';

@Directive()
export abstract class BaseChipsAutocomplete<T, R> implements ControlValueAccessor {
  @Input() label: string;
  @Input() hint: string;
  @Input() placeholder: string;
  @Input() outlineStyle = true;

  @Output() selectionChanged: EventEmitter<R[]> = new EventEmitter();

  items: T[] = [];
  disabled: boolean;

  inputControl = new FormControl();
  filtered: Observable<T[]>;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('itemInput') itemsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  onChange?: (x?: unknown) => void;
  onTouched?: (x?: unknown) => void;

  protected constructor(
    protected _cdr: ChangeDetectorRef,
    private enableNew?: boolean
  ) {}

  abstract prepareFormValue(items: T[]): R[];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createNewItem(value: string): T {
    return {} as T;
  }

  /* ControlValueAccessor implementation */

  registerOnChange(fn?: (x: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn?: (x: unknown) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
    this._cdr.detectChanges();
  }

  abstract writeValue(obj: R[]): void;

  /* Control logic */

  applyChanges(items: T[]): void {
    this.matAutocomplete.isOpen && this.autocompleteTrigger.closePanel();

    this.inputControl.setValue(null);

    const res = items.length ? this.prepareFormValue(items) : null;

    if (this.onChange) {
      this.onChange(res);
    }

    this.selectionChanged.emit(res);
  }

  addItem(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = (event.value || '').trim();

    if (value) {
      this.items.push(this.createNewItem(value));
    }

    if (input) {
      input.value = '';
    }


    this.applyChanges(this.items);
  }

  removeItem(item: T): void {
    const index = this.items.indexOf(item);

    this.items.splice(index, 1);

    this.applyChanges(this.items);
  }

  autoCompleteItemSelected(event: MatAutocompleteSelectedEvent): void {
    this.items.push(event.option.value);
    this.itemsInput.nativeElement.value = '';
    this.applyChanges(this.items);
  }

  clear(): void {
    this.items = [];
    this.itemsInput.nativeElement.value = '';
    // TODO: fix double-emitting
    this.applyChanges(this.items);
  }
}
