import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  BasketDetails, BasketHeader,
  BasketServiceInterface,
  CreateBasket, ProductsGoodsDetails
} from '@ekon-client/dkm-api';
import {
  BASKET_ACTIONS,
  BASKET_EVENTS,
  BasketEventsServiceInterface
} from '@ekon-client/dkm-events';
import { EkonDialogsService, getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import {
  EkonMessageService,
  EkonMessageTypes
} from '@ekon-client/shared/common/ekon-utils';
import {
  concat, Observable,
  throwError
} from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { BasketAddEditDialogComponent } from '../components/basket-add-edit-dialog/basket-add-edit-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  constructor(
    @Inject(BASKET_ACTIONS) private basketActions: BasketServiceInterface,
    @Inject(BASKET_EVENTS) private basketEvents: BasketEventsServiceInterface,
    private message: EkonMessageService,
    private dialog: MatDialog,
    private dialogsService: EkonDialogsService,
    private router: Router,
  ) { }

  // PUBLIC

  createBasket(): Observable<BasketDetails> {
    return this.dialog
      .open(BasketAddEditDialogComponent, getModalDefaultConfig())
      .afterClosed()
      .pipe(
        filter((c) => !!c),
        switchMap((createBasket: CreateBasket) =>
          this.basketActions.createBasket(createBasket)
        ),
        take(1),
        tap({
          next: (basketCreated: BasketDetails) =>
            this.message.show(
              `'${basketCreated.name}' basket created`,
              EkonMessageTypes.SUCCESS
            ),
          error: () =>
            this.message.show(`Error creating basket`, EkonMessageTypes.ERROR)
        })
      );
  }

  deleteBasket(basket: BasketDetails): Observable<unknown> {
    return this.dialogsService
      .confirm(
        `You are about to delete basket '${basket.name}'`,
        'Are You sure?'
      )
      .pipe(
        filter((r) => !!r),
        switchMap(() => this.basketActions.deleteBasket(basket.id)),
        tap({
          next: () => this.message.show(`Basket deleted`, EkonMessageTypes.SUCCESS),
          error: () =>
            this.message.show(`Error deleting basket`, EkonMessageTypes.ERROR)
        })
      );
  }

  editBasketMeta(basket: BasketDetails): void {
    this.dialog
      .open(BasketAddEditDialogComponent, {
        ...getModalDefaultConfig(),
        data: basket
      })
      .afterClosed()
      .pipe(
        filter((c) => !!c),
        map(
          (basketChanged: CreateBasket) =>
          ({
            ...basket,
            ...basketChanged
          } as BasketDetails)
        ),
        switchMap((basketDetails: BasketDetails) =>
          this.basketActions.updateBasket(basketDetails.id, basketDetails)
        )
      )
      .subscribe({
        next: () => this.message.show(`Basket saved`, EkonMessageTypes.SUCCESS),
        error: (error: unknown) => {
          this.message.show(`Error saving basket`, EkonMessageTypes.ERROR);
          return throwError(error);
        }
      });
  }

  addBasketItem(
    basket: BasketDetails | BasketHeader,
    items: ProductsGoodsDetails[]
  ): Observable<string> {
    return concat(
      ...items.map((item) =>
        this.basketActions
          .addProductToBasket(basket.id, item.productPageId, {})
          .pipe(map(() => item.productName))
      )
    ).pipe(
      tap({
        next: (itemName: string) =>
          this.message.show(
            `'${itemName}' added to cart '${basket.name}'`,
            EkonMessageTypes.SUCCESS
          ),
        error: () =>
          this.message.show(
            `Error adding product to cart '${basket.name}'`,
            EkonMessageTypes.ERROR
          )
      })
    );
  }

  deleteBasketItem(
    basketId: string,
    item: ProductsGoodsDetails
  ): Observable<unknown> {
    return this.dialogsService
      .confirm(
        `You are about to delete item '${item.productName}'`,
        'Are You sure?'
      )
      .pipe(
        filter((r) => !!r),
        switchMap(() => this.basketActions.findBasketById(basketId)),
        map((basketRaw: BasketDetails) => ({
          ...basketRaw,
          items: basketRaw.items.filter(
            (i) => i.productPageId !== item.productPageId
          )
        } as BasketDetails)),
        switchMap((b: BasketDetails) => this.basketActions.updateBasket(b.id, b))
      );
  }

  closeBasket(basket: BasketDetails): Observable<unknown> {
    return this.dialogsService
      .confirm(`You are about to close '${basket.name}'`, 'Are You sure?')
      .pipe(
        filter((r) => Boolean(r)),
        switchMap(() => this.basketActions.submitAndCloseBasket(basket.id)),
        tap({
          next: () =>
            this.message.show(`'${basket.name}' closed`, EkonMessageTypes.SUCCESS),
          error: () =>
            this.message.show(
              `Error closing '${basket.name}'`,
              EkonMessageTypes.ERROR
            )
        })
      );
  }

  duplicateBasket(basket: BasketDetails): Observable<unknown> {
    return this.dialogsService
      .confirm(`You are about to clone '${basket.name}'`, 'Are You sure?')
      .pipe(
        filter((r) => Boolean(r)),
        switchMap(() => this.basketActions.duplicateBasket(basket.id)),
        tap({
          next: (clonedBasket: BasketDetails) => {
            this.router.navigate(['baskets', clonedBasket.id]);
            this.message.show(`'${basket.name}' cloned`, EkonMessageTypes.SUCCESS);
          },
          error: () =>
            this.message.show(
              `Error cloning '${basket.name}'`,
              EkonMessageTypes.ERROR
            )
        })
      );
  }
}
