<div [formGroup]="form" fxLayout="column">
  <div fxLayout="row" fxLayoutGap="16px">
    <mat-form-field appearance="outline" fxFlex="1 1 50%">
      <mat-label>Family Name</mat-label>
      <input matInput formControlName="familyName"/>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="1 1 50%">
      <mat-label>First Name</mat-label>
      <input matInput formControlName="firstName"/>
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline" fxFlex="1 1 50%">
    <mat-label>Title</mat-label>
    <input matInput formControlName="title"/>
  </mat-form-field>

  <mat-form-field appearance="outline" fxFlex="1 1 50%">
    <mat-label>Department</mat-label>
    <input matInput formControlName="department"/>
  </mat-form-field>

  <div fxLayout="row" fxLayoutGap="16px">
    <mat-form-field appearance="outline" fxFlex="1 1 50%">
      <mat-label>Position</mat-label>
      <input
        matInput
        formControlName="positionInOrganisation"
        matTooltip="position in organization, ex: VP, CEO, Director, etc."
      />
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="1 1 50%">
      <mat-label>Role</mat-label>
      <input
        matInput
        formControlName="roleInOrganisation"
        matTooltip="role could be accountant, legal representative etc."
      />
    </mat-form-field>
  </div>

  <div>Address</div>
  <dkm-core-address-form formControlName="address"></dkm-core-address-form>

  <dkm-core-controls-set title="Phones" formControlName="phones" class="mt-16">
    <ng-template let-ctrl>
      <dkm-partials-controls-pair-form
        [formControl]="ctrl"
        control1Name="phoneTitle"
        control2Name="phoneNumber"
        control1Label="Phone Title"
        control2Label="Phone Number"
      ></dkm-partials-controls-pair-form>
    </ng-template>
  </dkm-core-controls-set>

</div>
