import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CalendarItem, CalendarServiceInterface,
  CreateNote,
  NoteDetails,
  NoteServiceInterface
} from '@ekon-client/dkm-api';
import { CALENDAR_ACTIONS, NOTE_ACTIONS } from '@ekon-client/dkm-events';
import { EkonDialogsService, getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import { EkonMessageService, EkonMessageTypes } from '@ekon-client/shared/common/ekon-utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { NoteAddEditDialogComponent } from '../components/note-add-edit-dialog/note-add-edit-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  constructor(
    @Inject(NOTE_ACTIONS) private noteActions: NoteServiceInterface,
    @Inject(CALENDAR_ACTIONS) private calendarActions: CalendarServiceInterface,
    private dialog: MatDialog,
    private messageService: EkonMessageService,
    private dialogsService: EkonDialogsService,
    private translate: TranslateService
  ) {
  }

  addNote(): Observable<NoteDetails> {
    const dialogConfig = getModalDefaultConfig();
    dialogConfig.maxWidth = '800px';

    return this.dialog
      .open(NoteAddEditDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        filter((res) => !!res),
        switchMap((note: CreateNote) => this.noteActions.createNote(note)),
        tap({
          next: () =>
            this.messageService.show(
              this.translate.instant('NOTE_ADDED_SUCCESSFULLY'),
              EkonMessageTypes.SUCCESS
            ),
          error: () =>
            this.messageService.show(
              this.translate.instant('ERROR_OCCURRED_WHILE_ADDING_NOTE'),
              EkonMessageTypes.ERROR
            )
        })
      );
  }

  editNote(noteDetails: NoteDetails): Observable<unknown> {
    const dialogConfig = getModalDefaultConfig();
    dialogConfig.maxWidth = '800px';
    dialogConfig.data = noteDetails;

    return this.dialog
      .open(NoteAddEditDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        filter((res) => !!res),
        switchMap((note: NoteDetails) =>
          this.noteActions.updateNote(note.id, note)
        ),
        tap({
          next: () =>
            this.messageService.show(
              this.translate.instant('NOTE_UPDATED_SUCCESSFULLY'),
              EkonMessageTypes.SUCCESS
            ),
          error: () =>
            this.messageService.show(
              this.translate.instant('ERROR_OCCURRED_WHILE_UPDATING_NOTE'),
              EkonMessageTypes.ERROR
            )
        })
      );
  }

  deleteNote(noteDetails: NoteDetails): Observable<unknown> {
    const dialogConfig = getModalDefaultConfig();
    dialogConfig.data = noteDetails;

    return this.dialogsService.confirm(
      null,
      this.translate.instant('DELETE_NOTE'),
      this.translate.instant(`ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_NOTE`)
    )
      .pipe(
        filter((res: boolean) => res),
        switchMap(() => this.noteActions.deleteNote(noteDetails.id)),
        tap({
          next: () => this.messageService.show(
            this.translate.instant('NOTE_DELETED_SUCCESSFULLY'),
            EkonMessageTypes.SUCCESS
          ),
          error: () => this.messageService.show(
            this.translate.instant('ERROR_OCCURRED_WHILE_DELETING_NOTE'),
            EkonMessageTypes.ERROR
          )
        })
      );
  }

  createCalendarItem(noteDetails: NoteDetails): Observable<CalendarItem> {
    return this.dialogsService.confirm(
      null,
      this.translate.instant('CREATE_TASK'),
      this.translate.instant(`ARE_YOU_SURE_YOU_WANT_TO_CREATE_TASK_FROM_THIS_NOTE`)
    )
      .pipe(
        filter((res: boolean) => res),
        switchMap(() => this.calendarActions.copyNoteToTask(noteDetails.id)),
        tap({
          next: () => this.messageService.show(
            this.translate.instant('TASK_CREATED_SUCCESSFULLY'),
            EkonMessageTypes.SUCCESS
          ),
          error: () => this.messageService.show(
            this.translate.instant('ERROR_OCCURRED_WHILE_CREATING_TASK'),
            EkonMessageTypes.ERROR
          )
        })
      );
  }
}
