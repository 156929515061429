import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RateDetails, RefType } from '@ekon-client/dkm-api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RatingService } from '../../services/rating.service';

@Component({
  selector: 'dkm-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  public destroy$ = this._destroy$.asObservable();

  @Input() refEntityMeta: {
    id: string;
    type: RefType
  };

  rate: RateDetails;
  averageRate: number;

  constructor(private ratingService: RatingService) { }

  ngOnInit(): void {
    this.ratingService
      .getRate(this.refEntityMeta.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((rateDetails: RateDetails) => {
        this.rate = rateDetails;
      });

    this.getAverageRate();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  setRate(rate: number): void {
    this.ratingService
      .setRate(rate, this.refEntityMeta.id, this.refEntityMeta.type)
      .subscribe(() => {
        this.getAverageRate();
      });
  }

  getAverageRate(): void {
    this.ratingService
      .getAverageRate(this.refEntityMeta.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((rate: number) => {
        this.averageRate = rate;
      });
  }
}
