/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserHeaderModel } from './userHeaderModel';
import { UserPostModel } from './userPostModel';
import { CalendarItemHeader } from './calendarItemHeader';
import { UserPostReaction } from './userPostReaction';


export interface UserPostMeetupModel extends UserPostModel { 
    attachement?: CalendarItemHeader;
}

