/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *   NoGraph  BarHorizontal  BarVertical  Pie  Line  BarWithLine  ScatterPlot
 */
export type GraphType = 'NoGraph' | 'BarHorizontal' | 'BarVertical' | 'Pie' | 'Line' | 'BarWithLine' | 'ScatterPlot';

export const GraphType = {
    NoGraph: 'NoGraph' as GraphType,
    BarHorizontal: 'BarHorizontal' as GraphType,
    BarVertical: 'BarVertical' as GraphType,
    Pie: 'Pie' as GraphType,
    Line: 'Line' as GraphType,
    BarWithLine: 'BarWithLine' as GraphType,
    ScatterPlot: 'ScatterPlot' as GraphType
};

