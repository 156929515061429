import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ekonInitials'
})
export class EkonInitialsPipe implements PipeTransform {

  transform(value: string, limit?: number): unknown {
    return value.toUpperCase()
      .split(' ')
      .map((word: string) => word[0])
      .slice(0, limit ? limit : 3)
      .join('');
  }
}
