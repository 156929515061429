import {
  ComponentFactoryResolver,
  Inject,
  Injectable,
  Injector,
  ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GeoLayerServiceInterface } from '@ekon-client/dkm-api';
import { GEO_ACTIONS } from '@ekon-client/dkm-events';
import { EkonDialogsService, getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import centroid from '@turf/centroid';
import { Feature as TurfFeature } from '@turf/helpers/dist/js/lib/geojson';
// import { isDraggedWithinPeriod } from 'angular-calendar/modules/common/util';
import {
  isEqual as _isEqual,
  noop as _noop
} from 'lodash-es';
import { defaults as defaultControls } from 'ol/control';
import { Coordinate } from 'ol/coordinate';
import { click } from 'ol/events/condition';
import Feature from 'ol/Feature';
import GeoJSON, { GeoJSONFeature } from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import { DragPan } from 'ol/interaction';
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import { DrawEvent } from 'ol/interaction/Draw';
import Interaction from 'ol/interaction/Interaction';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import Select, { SelectEvent } from 'ol/interaction/Select';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import OverlayPositioning from 'ol/OverlayPositioning';
import { useGeographic } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import View from 'ol/View';
import { Observable } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';

import {
  MAP_DIALOG_ACTIONS,
  MAP_FEATURE_FORM_DATA,
  MapDialogComponent,
  MapMarkerDialogResult
} from '../components/map-dialog/map-dialog.component';
import { ShapesToolbarControl } from '../utilities/ShapesToolbarControl';

// import enableImageDrag = DomUtil.enableImageDrag;

export interface DKMMapOptions {
  mouseWheelDisabled: boolean;
  controlsDisabled: boolean;
  interactionsDisabled: boolean;
}

@Injectable()
export class MapService {
  map: Map;

  vectorSource = new VectorSource({
    wrapX: false,
    features: new GeoJSON().readFeatures({
      type: 'FeatureCollection',
      features: this.getFeaturesFromLocalStorage()
    })
  });

  vectorLayer = new VectorLayer({ source: this.vectorSource });
  private mouseWheelInteraction: Interaction = new MouseWheelZoom();

  public constructor(
    private cfr: ComponentFactoryResolver,
    private injector: Injector,
    public vcr: ViewContainerRef,
    private dialog: MatDialog,
    private dialogsService: EkonDialogsService,
    @Inject(GEO_ACTIONS) private geoActions: GeoLayerServiceInterface
  ) {
    useGeographic();
    // this.initMap();
  }

  initMap(options: DKMMapOptions): Map {
    const selectInteraction = new Select({
      layers: [this.vectorLayer],
      condition: click
    });
    selectInteraction.on(
      'select',
      (e: SelectEvent) =>
        e.selected.length &&
        this.featureOnClick(
          selectInteraction.getFeatures().item(0),
          e.mapBrowserEvent.coordinate
        )
          .pipe(finalize(() => selectInteraction.getFeatures().clear()))
          .subscribe(_noop)
    );

    this.map = new Map({
      controls: !options.controlsDisabled ? defaultControls().extend([
        new ShapesToolbarControl({
          source: this.vectorSource,
          drawEndCallback: (e: DrawEvent) => {
            this.featureOnClick(
              e.feature,
              centroid(new GeoJSON().writeFeatureObject(e.feature) as TurfFeature).geometry.coordinates
            ).subscribe(_noop);
          }
        })
      ]) : [],
      // interactions: defaultInteractions().extend(

      interactions: options.interactionsDisabled ? [] : [
        new DoubleClickZoom(),
        selectInteraction,
        new DragPan(),
        ...(options.mouseWheelDisabled ? [] : [this.mouseWheelInteraction])
      ],

      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://tile.osmand.net/hd/{z}/{x}/{y}.png',
            tilePixelRatio: 2,
            wrapX: true
          })
        }),
        this.vectorLayer
      ],
      view: new View({
        center: [15, 50],
        zoom: 3
      })
    });

    return this.map;
  }

  setMapContainer(target: HTMLElement | string): void {
    setTimeout(() => {
      this.map.setTarget(target);
    });
  }

  featureOnClick(
    feature: Feature<Geometry>,
    overlayCoords: Coordinate
  ): Observable<MapMarkerDialogResult> {
    const geoJSONFormatter = new GeoJSON();
    const featureGeoJSON = geoJSONFormatter.writeFeatureObject(feature);
    let objects: GeoJSONFeature[] = this.getFeaturesFromLocalStorage();
    const objectData = objects.find(
      (f: GeoJSONFeature) => f.id === feature.getId()
    );

    return this.openFeatureFormDialog(featureGeoJSON, overlayCoords).pipe(
      tap({
        next: (r) =>
          ((!r && !objectData) ||
            (r && r.action === MAP_DIALOG_ACTIONS.DELETE)) &&
          this.vectorSource.removeFeature(feature)
      }),
      filter((r) => Boolean(r)),
      tap({
        next: (result: MapMarkerDialogResult) => {
          if (result.action === MAP_DIALOG_ACTIONS.DELETE && objectData) {
            this.saveFeatures(
              objects.filter((md: GeoJSONFeature) => !_isEqual(md, objectData))
            );
          } else {
            if (objectData) {
              objects = objects.filter(
                (md: GeoJSONFeature) => md.id !== result.data.id
              );
            } else {
              const featureId = Date.now();
              feature.setId(featureId);
              result.data.id = featureId;
            }
            feature.setGeometry(
              geoJSONFormatter.readGeometry(result.data.geometry)
            );
            feature.setProperties(result.data.properties);
            objects.push(result.data);
            this.saveFeatures(objects);
          }
        }
      })
    );
  }

  openFeatureFormDialog(
    data: GeoJSONFeature,
    overlayCoords: Coordinate
  ): Observable<MapMarkerDialogResult> {
    const dialogRef = this.dialog.open(MapDialogComponent,
      { ...getModalDefaultConfig(), data: data}
    );
    return dialogRef.afterClosed().pipe(
      tap((e)=>{console.log(e)})
    );
    /*const formFactory = this.cfr.resolveComponentFactory(MapDialogComponent);*/
    /*const formInjector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: MAP_FEATURE_FORM_DATA,
          useValue: data
        }
      ]
    });
    const formRef = formFactory.create(formInjector);
    this.vcr.insert(formRef.hostView);

    const ovr = new Overlay({
      element: formRef.instance.elRef.nativeElement,
      position: overlayCoords,
      autoPan: true,
      positioning: OverlayPositioning.BOTTOM_CENTER,
      offset: [0, -48]
    });

    setTimeout(() => this.map.addOverlay(ovr), 0);
*/
    /*return formRef.instance.onCloseDialog.pipe(
      tap({
        next: () => this.map.removeOverlay(ovr)
      })
    );*/
  }

  getFeaturesFromLocalStorage(): GeoJSONFeature[] {
    return JSON.parse(localStorage.getItem('geoJSONFeatures')) || [];
  }

  saveFeatures(markers: GeoJSONFeature[]): void {
    localStorage.setItem('geoJSONFeatures', JSON.stringify(markers));

    console.log(
      new GeoJSON().writeFeaturesObject(this.vectorSource.getFeatures())
    );
    // this.geoActions
    //   .createGeoLayer({
    //     geoLayer: {
    //       geoLayer: new GeoJSON().writeFeaturesObject(
    //         this.vectorSource.getFeatures()
    //       ) as FeatureCollection
    //     }
    //   })
    //   .subscribe(console.warn);
  }

  setOptions(options: DKMMapOptions): void {
    !options.mouseWheelDisabled &&
    this.map.addInteraction(this.mouseWheelInteraction);
    // this.map.addControl()
  }
}
