<mat-card fxLayout="row">

  <dkm-core-ava-viewer
    fxFlex="0 0 auto"
    [editDisabled]="true"
    [fileLink]="domain.thumbprintUrl"
    (imageClick)="disableAllActions ? selectEntity() : viewDomain()"
    [cacheEnabled]="true"
  >{{ domain.name | ekonInitials }}</dkm-core-ava-viewer>

  <div
    class="ml-16"
  >
    <div class="h2 mb-8"><a (click)="disableAllActions ? selectEntity() : viewDomain()">{{domain.name}}</a></div>

    <div class="fadeout mb-0">{{domain.shortDescription}}</div>
  </div>
</mat-card>
