/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *   Page  BlogPost  Meeting  Task
 */
export type RefType = 'Page' | 'BlogPost' | 'Meeting' | 'Task';

export const RefType = {
    Page: 'Page' as RefType,
    BlogPost: 'BlogPost' as RefType,
    Meeting: 'Meeting' as RefType,
    Task: 'Task' as RefType
};

