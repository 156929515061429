import {
  AfterViewInit,
  ChangeDetectionStrategy,
ChangeDetectorRef,
  Component,
  forwardRef,   Inject,
  Input,
  ViewChild} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field/form-field';
import { DkmDomain, DkmDomainPagedResult,DomainServiceInterface } from '@ekon-client/dkm-api';
import { DOMAIN_ACTIONS } from '@ekon-client/dkm-events';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { PaginationModel, PaginatorComponent, PaginatorConfig } from '@ekon-client/shared/common/ekon-pagination';
import { DomainSelectorService } from '@ekon-client/shared/features/dkm-domains-services';
import {
  includes as _includes,
  isArray as _isArray,
  isString as _isString
} from 'lodash-es';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'dkm-domain-selector-control',
  templateUrl: './domain-selector-control.component.html',
  styleUrls: ['./domain-selector-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DomainSelectorControlComponent),
    multi: true
  }]
})
export class DomainSelectorControlComponent implements ControlValueAccessor, AfterViewInit {

  @Input() dataAsString: boolean;
  @Input() appearance: MatFormFieldAppearance;
  @Input() multiple: boolean;

  onChange: (value: string | string[]) => void;
  onTouched: () => void;

  trackById = (index: number, item: DkmDomain): string => item.id;
  paginatorConfig: PaginatorConfig = {
    loader: (pagination: PaginationModel): Observable<DkmDomainPagedResult> =>
      this.domainActions.listByCreteria(pagination, ['IsOwnerOf', 'IsManagerOf']),
    initialOptions: {
      pageSize: 5
    }
  };


  @ViewChild('paginator') paginator: PaginatorComponent;


  selected: string[] = [];
  selectedCur: string[] = [];

  disabled = false;

  constructor(
    @Inject(DOMAIN_ACTIONS) private domainActions: DomainServiceInterface,
    private domainSelectorService: DomainSelectorService,
    public globalConfig: GlobalConfigurationService,
    private _cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit(): void {
    this.paginator.items$.subscribe({
      next: (items: DkmDomain[]) => this.updateCurrentPortionSelection(items)
    });
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(data: string | string[]): void {
    if (_isArray(data)) {
      this.selected = data;
    } else if (_isString(data)) {
      this.selected = data.split(',');
    } else {
      this.selected = [];
    }

    this.paginator && this.paginator.items$.pipe(take(1)).subscribe({
      next: (items: DkmDomain[]) => {
        this.updateCurrentPortionSelection(items);
        this._cdr.detectChanges();
      }
    });

  }

  changeValue(): void {
    this.onChange(this.dataAsString
      ? (this.selected.length ? this.selected.join(','): null)
      : (this.selected.length ? [...this.selected] : null)
    );
  }

  updateCurrentPortionSelection(items: DkmDomain[]): void {
    this.selectedCur = items.map(i => i.id).filter(id => _includes(this.selected, id));
  }

  checkItemSelection(value: string): boolean {
    return this.selected.some((item => item === value));
  }

  setItemSelection($event: MatOptionSelectionChange): void {
    if ($event.isUserInput) {
      if ($event.source.selected) {
        this.selected.push($event.source.value);
      } else {
        this.selected = this.selected.filter(item => item !== $event.source.value);
      }

      this.changeValue();
    }
  }

  clearSelection(): void {
    this.selected = [];
    this.updateCurrentPortionSelection([]);
    this._cdr.detectChanges();
    this.changeValue();
  }
}
