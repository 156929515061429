/* eslint-disable rxjs/no-ignored-subscription */
// *************** Not authentication service (can not be declered inside auth)
// Auth service should not depened on any other dependency (except configuration)
// Auth lib is resolving data based on user authentication token only!!!
import { Location } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LandingPage, LandingPageServiceInterface, UserProfile } from '@ekon-client/dkm-api';
import { LANDING_PAGE_ACTIONS, USERPROFILE_EVENTS, UserProfileEventsServiceInterface } from '@ekon-client/dkm-events';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TermsOfUseService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  isFirstTime: boolean;

  tou$: Observable<LandingPage>;

  private _termsOfUseAccepted$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  get termsOfUseAccepted$(): Observable<boolean> {
    return this._termsOfUseAccepted$.asObservable()
      .pipe(filter(touAccepted => touAccepted !== null));
  }

  constructor(
    private router: Router,
    private location: Location,
    @Inject(LANDING_PAGE_ACTIONS) private lpActions: LandingPageServiceInterface,
    @Inject(USERPROFILE_EVENTS) private userProfileEvents: UserProfileEventsServiceInterface
  ) {

    this.tou$ = this.lpActions.findDefaultLandingPage().pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this.destroy$)
    );

    this.userProfileEvents.touAccepted.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.setTOUAccepted(true));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkTermsOfUse(userProfile: UserProfile): Observable<boolean> {
    return this.tou$.pipe(
      switchMap((/*lp: LandingPage*/) => {
        if (userProfile.touAccepted /* && !this.isFirstTime*/) {
          this.setTOUAccepted(true);
        } else {
          this.isFirstTime = true;
          this.router.navigate(['terms-of-use'], {
            state: {
              userProfile,
              prev: this.location.path()
            }
          });
        }

        return this.termsOfUseAccepted$;
      })
    );
  }

  setTOUAccepted(accept: boolean): void {
    this._termsOfUseAccepted$.next(accept);
    // this._termsOfUseAccepted$.complete();
  }
}
