<ng-container
  *ngIf="(items$ | async)?.length; else emptyHandler"
>
  <ng-content></ng-content>
</ng-container>
<button
  *ngIf="!viewAllBtnAlwaysHidden && !(paginator.error$ | async) && (viewAllBtnAlwaysDisplay || (paginator.totalItems$ | async) > (items$ | async)?.length)"
  mat-button
  (click)="emitViewAll()"
  color="primary"
  class="view-all-btn"
>{{'VIEW_ALL' | translate}}</button>
<ng-template #emptyHandler>
  <div class="empty-handler">
    <div
      *ngIf="(items$ | async)?.length === 0"
      class="no-items"
    >{{ config.noItemsLabel ? config.noItemsLabel : 'No items' }}</div>
    <ng-container *ngIf="paginator.error$ | async as error">
      <!--<div [ngClass]="error.type + '-message'">{{error?.message}}</div>-->
      <div
        class="reload-btn"
        matRipple
        *ngIf="error.type === 'error'"
        (click)="forceReload()"
      >
        <ekon-icon icon="redo" size="3x"></ekon-icon>
      </div>
    </ng-container>
  </div>
</ng-template>
