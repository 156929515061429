<div class="message-container" *ngIf="(pwa.installHelperHidden$ | async) === false && !pwa.isStandalone">
  <div class="message">
    <ng-container *ngIf="pwa.supportsInstall; else fallback">
      <ng-container *ngIf="pwa.platform === 'ios'; else other">
        <span>To install app tap on </span>
        <ekon-icon [icon]="ekIconIosShare" size="lg" class="mx-8"></ekon-icon>
        <span>and select {{ 'ADD_TO_HOME_SCREEN' | translate}}</span>
        <ekon-icon [icon]="ekIconAddSquare" size="lg" class="mx-8"></ekon-icon>
        <span>then tap on {{ 'ADD' | translate}}</span>
      </ng-container>
      <ng-template #other>
        <button mat-raised-button color="primary" (click)="pwa.addToHomeScreen()">Install App</button>
        <span class="ml-16">To get more features</span>
      </ng-template>
    </ng-container>
    <ng-template #fallback>
      Your browser doesn't support app installation. Kindly, use

      <ng-container *ngIf="pwa.platform === 'ios'; else otherFallback">
        <a href="https://www.apple.com/safari/" target="_blank">Safari</a>
      </ng-container>
      <ng-template #otherFallback>
        <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>
      </ng-template>
    </ng-template>
  </div>

  <button mat-icon-button class="close-message" (click)="pwa.hideMessage()">
    <ekon-icon [icon]="ekIconTimes" size="lg"></ekon-icon>
  </button>
</div>
