import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import {
  ekIconAdminPanel,
  ekIconLogout,
ekIconQuestion,
  ekIconSubscription,
  ekIconUser,   EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { AvaViewerModule } from '@ekon-client/shared/features/dkm-files';
import { TranslateModule } from '@ngx-translate/core';

import { ThemeSetterModule } from '../theme-setter/theme-setter.module';
import { ProfileMenuComponent } from './profile-menu.component';


@NgModule({
  declarations: [
    ProfileMenuComponent
  ],
    imports: [
        CommonModule,
        AvaViewerModule,
        MatButtonModule,
        RouterModule,
        FlexLayoutModule,
        MatMenuModule,
        MatRippleModule,
        EkonIconsModule,
        TranslateModule,
        ThemeSetterModule
    ],
  exports: [
    ProfileMenuComponent
  ]
})
export class ProfileMenuModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconUser,
      ekIconSubscription,
      ekIconAdminPanel,
      ekIconLogout,
      ekIconQuestion
    );
  }
}
