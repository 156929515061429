<ekon-dialog-wrapper>
  <div mat-dialog-title class="h2 mb-24" fxLayoutAlign="start center">Select File</div>
  <mat-dialog-content>
    <dkm-file-upload-control
      (fileUploaded)="onFileUploaded($event)"
      class="mb-16"
      [filesPersonalMode]="data.filesPersonalMode"
      [specificDomain]="data.domainFilter"
      [aspectRatio]="data.aspectRatio"
    ></dkm-file-upload-control>
    <ekon-paginator
      [config]="filesPaginatorConfig"
      [customEmptyHandling]="true"
      #paginator
    >
      <dkm-file-list
        [mode]="FileListModes.VIEW"
        [data]="{ files: paginator.items$ | async, users: authors$ | async }"
        [typeColHidden]="!!data.fileType"
        (fileSelect)="fileSelected($event, true)"
        (fileView)="viewFile($event.fileIndex, paginator.paginator.pageNumber$, paginator.paginator.pageSize$)"
        (mimeTypesSelected)="!data.fileType && paginator.pushFilteringState({contentType: $event})"
      >
      </dkm-file-list>
    </ekon-paginator>
  </mat-dialog-content>
</ekon-dialog-wrapper>
