/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *   Unspecified  NeedsAction  Completed  InProcess  Cancelled
 */
export type TaskStatus = 'Unspecified' | 'NeedsAction' | 'Completed' | 'InProcess' | 'Cancelled';

export const TaskStatus = {
    Unspecified: 'Unspecified' as TaskStatus,
    NeedsAction: 'NeedsAction' as TaskStatus,
    Completed: 'Completed' as TaskStatus,
    InProcess: 'InProcess' as TaskStatus,
    Cancelled: 'Cancelled' as TaskStatus
};

