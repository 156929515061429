import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  createNgModuleRef, Inject,
  Injector,
  Input, OnInit, Type, ViewChild, ViewContainerRef
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl, NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { GlobalConfigurationService, WysiwygType } from '@ekon-client/shared/cloud-config';
import {
  EkonWysiwygAdapterBase,
  WysiwygMode
} from '@ekon-client/shared/common/ekon-wysiwyg-adapter';
import { noop as _noop } from 'lodash-es';
import { EMPTY, from } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { WYSIWYG_MODE } from '../../extras/ModeInjector';


@Component({
  selector: 'ekon-wysiwyg-editor',
  template: `
    <div #editor [ngClass]="{'form-control-error': wysiwygControl.invalid}"></div>
  `,
  styles: [
    `
      :host {
        display: block;
        margin-bottom: 24px;
      }

      .form-control-error {
        border: 2px solid indigo;
        border-radius: 4px;
        overflow: hidden;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush, providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EkonWysiwygEditorComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: EkonWysiwygEditorComponent
    }
  ]
})
export class EkonWysiwygEditorComponent implements ControlValueAccessor, Validator, OnInit {

  @Input() type: WysiwygType = 'quill';
  @Input() mode?: WysiwygMode;
  @Input() placeholder: string | undefined;

  @ViewChild('editor', { read: ViewContainerRef, static: true }) editorRef?: ViewContainerRef;

  wysiwygControl: FormControl = new FormControl(null);

  // ControlValueAccessor props

  onChange?: (value: unknown) => void;

  onTouched?: () => void;

  // Validator props

  validatorChange?: () => void;

  constructor(
    public globalConfig: GlobalConfigurationService,
    private injector: Injector,
    private cdr: ChangeDetectorRef,
    @Inject(WYSIWYG_MODE) private modeInj: WysiwygMode
  ) {
    this.wysiwygControl.valueChanges.subscribe({
      next: value => this.onChange && this.onChange(value)
    });
  }

  ngOnInit(): void {
    this.loadEditorControl();
  }

  loadEditorControl(): void {
    this.globalConfig.WYSIWYG$.pipe(
      take(1),
      switchMap((type: WysiwygType) => {
        switch(type) {
          case 'quill':
            return from(import('@ekon-client/shared/common/ekon-quill-adapter')
              .then(m => this.createAdapter(
                m.EkonQuillAdapterModule,
                m.EkonQuillAdapterComponent
              )));

          case 'ckeditor':
            return from(import('@ekon-client/shared/common/ekon-ckeditor-adapter')
              .then(m => this.createAdapter(
                m.EkonCkeditorAdapterModule,
                m.EkonCkeditorAdapterComponent
              )));
          default:
            return EMPTY;
        }
      })
    ).subscribe(_noop);
  }

  createAdapter(module: Type<unknown>, editor: Type<unknown>): void {
    const moduleRef = createNgModuleRef(module, this.injector);
    const component = this.editorRef?.createComponent(editor, {
      ngModuleRef: moduleRef
    }).instance as EkonWysiwygAdapterBase;

    component.wysiwygControl = this.wysiwygControl;
    (this.mode || this.modeInj) && (component.mode = this.mode || this.modeInj);
    component.onFocus = () => this.markAsTouched();
    this.cdr.detectChanges();
  }

  // ControlValueAccessor methods

  writeValue(value: number): void {
    this.wysiwygControl.setValue(value);
    this.wysiwygControl.updateValueAndValidity();
  }

  registerOnChange(onChange: () => unknown): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => unknown): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    disabled ? this.wysiwygControl.disable() : this.wysiwygControl.enable();
  }

  markAsTouched(): void {
    if(this.onTouched) {
      this.onTouched();
    }
  }

  // Validator methods

  validate(/*control: FormControl*/): ValidationErrors | null {
    return this.wysiwygControl.errors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.validatorChange = fn;
  };
}
