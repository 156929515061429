import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BlogPostDetails,
  BlogPostHeaderPagedResult,
  BlogService,
  BlogServiceInterface,
  Comment,
  Configuration,
  CreateBlogPostModel,
  CreateComment,   CreateFeedback, FeedbackDetails,
PagePaginationModel} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BlogEventsServiceInterface } from './BlogEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class BlogEventsService
  implements BlogServiceInterface, BlogEventsServiceInterface {
  get configuration(): Configuration {
    return this.blogService.configuration;
  }

  set configuration(val: Configuration) {
    this.blogService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.blogService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.blogService.defaultHeaders = val;
  }

  /**
   * The mechanism for monitoring Create action
   */
  private blogPostCreatedSubject: Subject<BlogPostDetails> = new Subject();

  get blogPostCreated(): Observable<BlogPostDetails> {
    return this.blogPostCreatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Delete action
   */
  private blogPostDeletedSubject: Subject<string> = new Subject();

  get blogPostDeleted(): Observable<string> {
    return this.blogPostDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Update action
   */
  private blogPostUpdatedSubject: Subject<string> = new Subject();

  get blogPostUpdated(): Observable<string> {
    return this.blogPostUpdatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Comment Create action
   */
  private commentCreatedSubject: Subject<Comment> = new Subject();

  get commentCreated(): Observable<Comment> {
    return this.commentCreatedSubject.asObservable();
  }

  constructor(
    private blogService: BlogService,
    private progressBar: EkonProgressBarService
  ) {
  }

  createBlogPost(
    createBlogPost?: CreateBlogPostModel,
    xSelectedDomain?: string
  ): Observable<BlogPostDetails> {
    return this.progressBar
      .apply(this.blogService.createBlogPost(createBlogPost, xSelectedDomain))
      .pipe(
        tap((blogPost: BlogPostDetails) =>
          this.blogPostCreatedSubject.next(blogPost)
        )
      );
  }

  deleteBlogPost(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.blogService.deleteBlogPost(id))
      .pipe(tap(() => this.blogPostDeletedSubject.next(id)));
  }

  updateBlogPost(
    id: string,
    blogPostDetails: BlogPostDetails,
    xSelectedDomain?: string
  ): Observable<unknown> {
    return this.progressBar
      .apply(this.blogService.updateBlogPost(id, blogPostDetails, xSelectedDomain))
      .pipe(tap(() => this.blogPostUpdatedSubject.next(id)));
  }

  findBlogPostById(id: string): Observable<BlogPostDetails> {
    return this.progressBar.apply(this.blogService.findBlogPostById(id));
  }

  listBlogPosts(
    pagination?: PagePaginationModel,
    xSelectedDomain?: string
  ): Observable<BlogPostHeaderPagedResult> {
    return this.progressBar.apply(
      this.blogService.listBlogPosts(pagination, xSelectedDomain)
    );
  }

  createBlogComment(
    id: string,
    createComment: CreateComment,
  ): Observable<Comment> {
    return this.progressBar
      .apply(this.blogService.createBlogComment(id, createComment))
      .pipe(
        tap((comment: Comment) => this.commentCreatedSubject.next(comment))
      );
  }

  listUserBlogPosts(
    pagination?: PagePaginationModel,
    xSelectedDomain?: string
  ): Observable<BlogPostHeaderPagedResult> {
    return this.progressBar.apply(
      this.blogService.listUserBlogPosts(pagination, xSelectedDomain)
    );
  }

  createBlogFeedback(id: string, createFeedback: CreateFeedback): Observable<FeedbackDetails> {
    return this.progressBar.apply(
      this.blogService.createBlogFeedback(id, createFeedback)
    );
  }

}
