<!--<mat-spinner *ngIf="loaderVisible"
  color="primary"
  mode="indeterminate"
  diameter="50"
  strokeWidth="3px">
</mat-spinner>-->
<ng-container *ngIf="fileData">
  <dkm-file-view-image
    *ngIf="FileTypesWeCanView.canViewFileExt((fileData.file)?.contentType) && FileTypesWeCanView.isImage((fileData.file)?.contentType)"
    [fileMeta]="fileData.file"
    [fileAuthor]="fileData.user"
    [hideCredentials]="hideCredentials"
  ></dkm-file-view-image>
  <dkm-file-view-audio
    *ngIf="FileTypesWeCanView.canViewFileExt((fileData.file)?.contentType) && FileTypesWeCanView.isAudio((fileData.file)?.contentType)"
    [fileMeta]="fileData.file"
    [fileAuthor]="fileData.user"
    [hideCredentials]="hideCredentials"
  ></dkm-file-view-audio>
  <dkm-file-view-video
    *ngIf="FileTypesWeCanView.canViewFileExt((fileData.file)?.contentType) && FileTypesWeCanView.isVideo((fileData.file)?.contentType)"
    [fileMeta]="fileData.file"
    [fileAuthor]="fileData.user"
    [hideCredentials]="hideCredentials"
  ></dkm-file-view-video>
  <dkm-file-view-document
    *ngIf="FileTypesWeCanView.canViewFileExt((fileData.file)?.contentType) && FileTypesWeCanView.isDocument((fileData.file)?.contentType)"
    [fileMeta]="fileData.file"
    [fileAuthor]="fileData.user"
    [hideCredentials]="hideCredentials"
  ></dkm-file-view-document>
  <dkm-file-view-unviewable
    *ngIf="!FileTypesWeCanView.canViewFileExt((fileData.file)?.contentType)"
    [fileMeta]="fileData.file"
    [fileAuthor]="fileData.user"
    [hideCredentials]="hideCredentials"
  ></dkm-file-view-unviewable>
</ng-container>
