<div
  class="backdrop"
  *ngIf="(meta | async).visible"
  @dkmFadeInOut
  (click)="hide()"
></div>
<div
  *ngIf="(meta | async).visible"
  [@inOutAnimation]
  class="sidebar-content"
>
  <ng-content></ng-content>
</div>
