import { Inject, Injectable } from '@angular/core';
import {
  Category,
  CategoryServiceInterface,
  CreateCategoryContent,
  UpdateCategoryContent,
} from '@ekon-client/dkm-api';
import { CATEGORY_ACTIONS } from '@ekon-client/dkm-events';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(
    @Inject(CATEGORY_ACTIONS) private categoryActions: CategoryServiceInterface
  ) { }

  listCategories(): Observable<Category[]> {
    return this.categoryActions.listCategories();
  }

  findCategory(id: string): Observable<Category> {
    return this.categoryActions.findCategoryById(id);
  }

  createCategory(
    createCategoryContent: CreateCategoryContent,
    domains?: string
  ): Observable<Category> {
    return this.categoryActions.createCategory(createCategoryContent, domains);
  }

  updateCategory(
    updateCategoryContent: UpdateCategoryContent,
    id: string,
    domains?: string
  ): Observable<unknown> {
    return this.categoryActions.updateCategory(id, updateCategoryContent, domains);
  }

  deleteCategory(id: string): Observable<unknown> {
    return this.categoryActions.deleteCategory(id);
  }
}
