<ekon-dialog-wrapper [isFullscreen]="true">
  <div mat-dialog-title>{{'BLOG_POST_SELECTION' | translate: { default: 'Blog post selection' } }}</div>
  <mat-dialog-content>
    <ekon-paginator [config]="paginatorConfig" #paginator="ekonPaginator">
      <ekon-cards-grid>
        <dkm-blog-card
          *ngFor="let post of paginator.items$ | async; trackBy: trackById"
          [data]="post"
          [disableAllActions]="true"
          (select)="validateAndSave(post)"
        ></dkm-blog-card>
      </ekon-cards-grid>
    </ekon-paginator>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxFlex="0 0 auto">
    <button mat-button [mat-dialog-close]="false">{{'CANCEL' | translate: { default: 'Cancel' } }}</button>
  </mat-dialog-actions>
</ekon-dialog-wrapper>
