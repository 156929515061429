import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { DKM_LAYOUTS_LIST } from '../../../extras';
import { FooterModule } from '../../components/footer/footer.module';
import { ToolbarModule } from '../../components/toolbar/toolbar.module';
import { VerticalLayout2Component } from './layout-2.component';

@NgModule({
    declarations: [VerticalLayout2Component],
    imports: [
        RouterModule,
        FooterModule,
        ToolbarModule,
        CommonModule
    ],
    exports: [VerticalLayout2Component]
})
export class VerticalLayout2Module {
  constructor(@Optional() @SkipSelf() parentModule: VerticalLayout2Module) {
    if (parentModule) {
      throw new Error(
        'VerticalLayout2Module is already loaded. Import it in the AppModule only!'
      );
    }
  }

  static forRoot(): ModuleWithProviders<VerticalLayout2Module> {
    return {
      ngModule: VerticalLayout2Module,
      providers: [
        {
          provide: DKM_LAYOUTS_LIST,
          useValue: {
            key: 'vertical-layout-2',
            name: 'Second Vertical Layout',
            entryComponent: VerticalLayout2Component,
          },
          // https://angular.io/api/core/FactoryProvider
          multi: true,
        },
      ],
    };
  }
}
