import { ChangeDetectionStrategy, Component, Inject,TrackByFunction } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  UserHeaderModel,
  UserHeaderModelPagedResult,
  UserProfileServiceInterface
} from '@ekon-client/dkm-api';
import {
  USERPROFILE_ACTIONS
} from '@ekon-client/dkm-events';
import { PaginationModel, PaginatorConfig } from '@ekon-client/shared/common/ekon-pagination';
import { Observable } from 'rxjs';

@Component({
  selector: 'ekon-client-user-post-add-attachment-page',
  templateUrl: './user-selector-dialog.component.html',
  styleUrls: ['./user-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSelectorDialogComponent {

  paginatorConfig: PaginatorConfig;

  trackById: TrackByFunction<UserHeaderModel> = (index: number, item: UserHeaderModel) => item.id;

  constructor(
    private dialogRef: MatDialogRef<UserSelectorDialogComponent>,
    @Inject(USERPROFILE_ACTIONS) private userProfileActions: UserProfileServiceInterface
  ) {
    this.paginatorConfig = {
      loader: (pagination: PaginationModel): Observable<UserHeaderModelPagedResult> =>
        this.userProfileActions.listUserProfileHeaders(pagination),
      initialOptions: {
        pageSize: 12
      },
      filtersConfig: {
        byCategories: false
      },
    };
  }

  select(user: UserHeaderModel): void {
    this.dialogRef.close(user);
  }
}
