import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ekIconPlus, EkonIconsLibraryService, EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { DkmAutocompletionModule } from '@ekon-client/shared/features/dkm-autocompletion';
import { TranslateModule } from '@ngx-translate/core';

import { AddressFormComponent } from './components/address-form/address-form.component';
import { ControlsPairSelectComponent } from './components/controls-pair/components/controls-pair-select/controls-pair-select.component';
import { ControlsPairFormComponent } from './components/controls-pair/controls-pair-form.component';
import { ControlsSetComponent } from './components/controls-set/controls-set.component';
import { DigitalPresenceFormComponent } from './components/digital-presence-form/digital-presence-form.component';
import { LegalPersonFormComponent } from './components/legal-person-form/legal-person-form.component';
import { TagsMappingComponent } from './components/tags-mapping/tags-mapping.component';



@NgModule({
  declarations: [
    DigitalPresenceFormComponent,
    AddressFormComponent,
    LegalPersonFormComponent,
    ControlsPairFormComponent,
    ControlsSetComponent,
    ControlsPairSelectComponent,
    TagsMappingComponent
  ],
  imports: [
    CommonModule,
    EkonIconsModule,
    ReactiveFormsModule,
    FlexModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    TranslateModule,
    MatSelectModule,
    DkmAutocompletionModule
  ],
  exports: [
    ControlsPairFormComponent,
    AddressFormComponent,
    DigitalPresenceFormComponent,
    LegalPersonFormComponent,
    ControlsSetComponent,
    ControlsPairSelectComponent,
    TagsMappingComponent
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-partials/src/lib/forms/',
      multi: true
    }
  ]
})
export class EkonPartialsFormsModule {
  constructor(ekLib: EkonIconsLibraryService) {
    ekLib.addIcons(
      ekIconPlus,
    )
  }
}
