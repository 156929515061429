import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EkonCardsGridComponent } from './components/ekon-cards-grid/ekon-cards-grid.component';

@NgModule({
  declarations: [EkonCardsGridComponent],
  exports: [EkonCardsGridComponent],
  imports: [CommonModule],
})
export class EkonCardsGridModule {}
