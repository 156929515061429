import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';
import { TranslateModule } from '@ngx-translate/core';

import { ThemeSetterComponent } from './theme-setter.component';

@NgModule({
  declarations: [ThemeSetterComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    EkonIconsModule,
    TranslateModule,
    MatSlideToggleModule,
    MatButtonModule
  ],
  exports: [ThemeSetterComponent],
})
export class ThemeSetterModule {}
