<div
  [formGroup]="form"
>
  <ekon-autocomplete
    *ngIf="autocompleteList; else simpleInput"
    formControlName="tagsProp"
    class="w-60-p"
    [requestApi]="autocompleteList"
    [label]="label"
    [placeholder]="placeholder"
  ></ekon-autocomplete>
  <ng-template #simpleInput>
    <mat-form-field
      appearance="outline"
      fxFlex="1 1 50%"
    >
      <mat-label>{{label}}</mat-label>
      <input matInput formControlName="tagsProp"/>
    </mat-form-field>
  </ng-template>


  <mat-form-field appearance="outline" class="w-20-p pl-8">
    <mat-label>Separator</mat-label>
    <input matInput formControlName="tagsSeparator">
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-20-p pl-8">
    <mat-label>Space symbol</mat-label>
    <input matInput formControlName="tagsSpaceSeparator">
  </mat-form-field>
</div>
