import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import {
  DialogConfirmActionsData,
  DialogConfirmData, DialogConfirmDescriptionConfig,
  EkonDialogConfirmComponent
} from '../components/ekon-dialog-confirm/ekon-dialog-confirm.component';
import { getModalDefaultConfig } from '../utilities';

@Injectable({
  providedIn: 'root',
})
export class EkonDialogsService {
  constructor(private matDialog: MatDialog) {}

  confirm(
    message: string,
    title?: string,
    messageHTML?: string,
    actionLabels?: DialogConfirmActionsData,
    description?: DialogConfirmDescriptionConfig,
    config?: MatDialogConfig
  ): Observable<boolean | string | null | undefined | void> {
    return this.matDialog
      .open(EkonDialogConfirmComponent, {
        ...getModalDefaultConfig(),
        ...config,
        data: {
          title,
          message,
          messageHTML,
          actionLabels,
          description
        } as DialogConfirmData,
      })
      .afterClosed();
  }
}
