import { Component } from '@angular/core';

@Component({
  selector: 'dkm-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss'],
})
export class ChatBotComponent /* implements OnDestroy */ {
  scriptUrl = 'https://wgchatbotstorage.blob.core.windows.net/messenger/aeonx_chat_messenger_weldgalaxyv5.min.js';
  scriptKey = 'chat-box';

  // constructor(private dynamicScriptLoader: DynamicScriptLoaderService) {
  //   dynamicScriptLoader.loadScript(this.scriptKey, this.scriptUrl);
  // }
  //
  // ngOnDestroy(): void {
  //   this.dynamicScriptLoader.deleteScript(this.scriptKey);
  // }
}
