import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Phone } from '@ekon-client/dkm-api';

@Component({
  selector: 'dkm-core-phone-view',
  templateUrl: './phone-view.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneViewComponent {
  @Input() data: Phone;
}
