<ng-container *ngIf="itemList; else topListOrPaginationView">
  <ng-container *ngTemplateOutlet="topList; context: {$implicit: itemList}"></ng-container>
</ng-container>

<ng-template #topListOrPaginationView>
  <ekon-top-list
    [config]="topListConfig"
    (viewAll)="emitViewAll()"
    [viewAllBtnAlwaysDisplay]="viewAllBtnAlwaysDisplay"
    [viewAllBtnAlwaysHidden]="viewAllBtnAlwaysHidden"
    #productList
    *ngIf="topListConfig; else paginatedView"
  >
    <ng-container *ngTemplateOutlet="topList; context: {$implicit: productList.items$ | async}"></ng-container>
  </ekon-top-list>
</ng-template>

<ng-template #paginatedView>
  <ekon-paginator
    [config]="paginatorConfig"
    #paginator="ekonPaginator"
    [topPaginatorHidden]="true"
    [sortingNFilteringHidden]="true"
    [modeSwitcherHidden]="true"
    [noBackground]="true"
  >
    <ng-container *ngTemplateOutlet="topList; context: {$implicit: paginator.items$ | async}"></ng-container>
  </ekon-paginator>
</ng-template>

<ng-template #topList let-items>
  <mat-action-list
    role="list"
    class="list"
    [ngClass]="{'multi-column': multiColumn}"
  >
    <mat-list-item
      role="listitem"
      *ngFor="let page of items"
      class="list-item"
      (click)="goToPage(page)"
    >
      <div class="list-item-content">
        <div>
          <dkm-core-ava-viewer
            [fileLink]="page.thumbprintUrl"
            [editDisabled]="true"
            [noShape]="true"
            class="page-image-avatar"
          ></dkm-core-ava-viewer>
        </div>
        <div class="title">
          <div>{{page.name}}</div>
          <ekon-rating
            *ngIf="displayRating"
            [rate]="page.averageRate"
            [refId]="page.id"
            [displayValue]="true"
            size="small"
          ></ekon-rating>
        </div>
        <dkm-basket-add-item
          [data]="page"
          *ngIf="page.kind === 'ProductHeader'"
          class="action-btn"
        ></dkm-basket-add-item>
        <button
          *ngIf="displayRemoveBtn"
          mat-icon-button
          color="warn"
          (click)="$event.stopPropagation(); removeItem.emit(page)"
          class="action-btn"
        >
          <ekon-icon [icon]="ekIconTimes"></ekon-icon>
        </button>
      </div>
    </mat-list-item>
  </mat-action-list>
</ng-template>
