/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserHeaderModel } from './userHeaderModel';


/**
 * Structure to list detailed user reactions in a UI dialog
 */
export interface UserPostDetailedReactionModel { 
    /**
     * For simple convertion use. Not nice, but works for now :)
     */
    userId?: string;
    userDetails?: UserHeaderModel;
    timeStamp?: Date;
    /**
     * Respone types: Like, Love, Wow, Shapo, more
     */
    responseType?: string | null;
}

