import { Direction } from '@angular/cdk/bidi';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  forwardRef,
  Inject,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationError, Router, UrlSerializer } from '@angular/router';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { EkonLayoutConfig } from '@ekon-layout-utils';
import { EkonLayoutConfigService, EkonSplashScreenService } from '@ekon-layout-utils/services';
import { TranslateService } from '@ngx-translate/core';
import { map as _map, noop as _noop } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import {
  DKM_LAYOUTS_LIST,
  LayoutMeta
} from '../extras';
import { LayoutsConfigService } from '../services/layouts-config.service';

@Component({
  selector: 'ekon-client-layouts-god',
  templateUrl: './layouts-god.component.html',
  styleUrls: ['./layouts-god.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutsGodComponent implements OnDestroy, AfterViewInit {
  currentLayoutStyle: string;

  private _unsubscribeAll: Subject<void> = new Subject();

  langDir: Observable<Direction>;

  @ViewChild('layout', { read: ViewContainerRef, static: true })
  layoutVcr: ViewContainerRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(forwardRef(() => DKM_LAYOUTS_LIST)) private layouts: LayoutMeta[],
    private config: GlobalConfigurationService,
    private ekonLayoutConfigService: EkonLayoutConfigService,
    private _vcr: ViewContainerRef,
    private _cfr: ComponentFactoryResolver,
    private _platform: Platform,
    private ekonSplashScreenService: EkonSplashScreenService,
    private _translateService: TranslateService,
    public layoutsConfig: LayoutsConfigService,
    private renderer: Renderer2,
    private router: Router,
    private titleService: Title
  ) {
    this.setupLangs();

    titleService.setTitle(config.projectDisplayName);

    // Add is-mobile class to the body if the platform is mobile
    if(this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    this.langDir = layoutsConfig.langDirection.pipe(
      tap({
        next: dir => document.dir = dir
      })
    );

    // router.

    const omitScroll = [
      'task',
      'event',
      'kbe-spec/view/',
      'dashboard',
      'feed',
      'edit-element',
      'edit-nested-element'
    ];

    router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd)
    ).subscribe({
      next: (e: NavigationEnd) => {
        !omitScroll.some(s => e.url.includes(s)) && window.scrollTo({ top: 0 });
      }
    });

    router.events.pipe(
      filter((e): e is NavigationError => e instanceof NavigationError)
    ).subscribe({
      next: (e: NavigationError) => {
        console.warn('NavigationError', e);
        router.navigate(['error', 'not-found']);
      }
    });
  }

  private applyLayout(ekonLayoutConfig: EkonLayoutConfig): void {
    if(ekonLayoutConfig.layout.style === this.currentLayoutStyle) {
      return;
    }

    this.currentLayoutStyle = ekonLayoutConfig.layout.style;

    this.layoutVcr.clear();
    const componentType = this.layouts.find(
      (lm) => lm.key === this.currentLayoutStyle
    );
    if(componentType) {
      const component = this.layoutVcr.createComponent(componentType.entryComponent);
      component.changeDetectorRef.detectChanges();
    }
  }

  private applyLayoutsHacks(ekonLayoutConfig: EkonLayoutConfig): void {
    // Boxed
    // TODO: uncomment if needed
    // if ( ekonLayoutConfig.layout.width === 'boxed' )
    // {
    //   this.document.body.classList.add('boxed');
    // }
    // else
    // {
    //   this.document.body.classList.remove('boxed');
    // }

    // Color theme - Use normal for loop for IE11 compatibility
    this.document.body.classList.remove(
      ...Array.from(this.document.body.classList).filter((c: string) =>
        c.startsWith('theme-')
      )
    );

    this.document.body.classList.add(ekonLayoutConfig.colorTheme);
  }

  private setupLangs(): void {
    // Add languages
    this._translateService.addLangs(
      _map(this.config.langAvailable, 'key')
    );

    // Set the default language
    this._translateService.setDefaultLang('en');

    this._translateService.use(this.layoutsConfig.defaultLang).subscribe(_noop);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void {
    this.ekonLayoutConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((ekonLayoutConfig: EkonLayoutConfig) => {
        if(!ekonLayoutConfig) {
          return;
        }

        this.applyLayoutsHacks(ekonLayoutConfig);
        this.applyLayout(ekonLayoutConfig);

      });
  }
}
