import { Component, Input, SimpleChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { FileMetadata, UserProfileHeader } from '@ekon-client/dkm-api';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FileView } from '../../../../../extras';
import { FileService } from '../../../../../services';

@Component({
  selector: 'dkm-file-view-document',
  templateUrl: './file-view-document.component.html',
  styleUrls: ['./file-view-document.component.scss']
})
export class FileViewDocumentComponent implements FileView {
  @Input() fileAuthor: UserProfileHeader;
  @Input() fileMeta: FileMetadata;
  @Input() hideCredentials: boolean;

  private _fileId$: BehaviorSubject<string> = new BehaviorSubject(null);
  fileBodyURI$: Observable<SafeResourceUrl> = this._fileId$.asObservable().pipe(
    switchMap((fileId: string) => fileId ? this.fileService.loadFileUint(fileId) : of(''))
  );

  constructor(
    private fileService: FileService
  ) {
  }

  ngOnDestroy(): void {
    this._fileId$.next(null);
    this._fileId$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.fileMeta
      && changes.fileMeta.currentValue !== changes.fileMeta.previousValue
    ) {
      changes.fileMeta.currentValue
        ? this._fileId$.next(this.fileMeta.id)
        : this._fileId$.next(null);
    }
  }
}
