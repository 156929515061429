<div [formGroup]="form" fxLayout="column">
  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput formControlName="name"/>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Description</mat-label>
    <textarea matInput formControlName="shortDescription"></textarea>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>URL</mat-label>
    <input matInput formControlName="url"/>
  </mat-form-field>
</div>
