import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate, CanLoad, Data, Route, Router, UrlTree
} from '@angular/router';
import { EkonAuthService } from '@ekon-client/auth';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { PermissionsService } from './permissions.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuardService implements CanActivate, CanLoad {
  constructor(
    private permissionsService: PermissionsService,
    private authService: EkonAuthService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> {
    return this.runGuard(route.data);
  }

  canLoad(route: Route): Observable<boolean | UrlTree> {
    return this.runGuard(route.data);
  }

  runGuard(data: Data): Observable<boolean | UrlTree> {
    // return this.authService.isLoggedIn$.pipe(
    //   take(1),
    //   switchMap(authenticated => authenticated ?
    //   this.permissionsService.hasPermission(
    //     data.permissionFeature,
    //     data.permissionAction
    //   ).pipe(take(1)) : of(false)));

    return this.permissionsService.hasPermission(
      data.permissionFeature,
      data.permissionAction
    ).pipe(
      take(1),
      // todo: improve that
      // map((hasPermission: boolean) => hasPermission || this.router.createUrlTree([
      //   '/', 'error', 'no-permission',
      //   { backUrl: this.router.getCurrentNavigation().initialUrl.toString() }
      // ]))
      map((hasPermission: boolean) => hasPermission || this.router.createUrlTree([
        '/', 'subscription',
        { backUrl: this.router.getCurrentNavigation()?.initialUrl.toString() }
      ]))
    );
  }
}
