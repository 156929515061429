import {
  AfterViewChecked, AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef, HostBinding, Input
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Directive({
  selector: '[ekonOverflow]',
  exportAs: 'ekonOverflow'
})
export class EkonOverflowDirective implements AfterViewInit {

  private _fixedHeight = '200px';
  private _disabled?: boolean;

  @Input() set ekonOverflowListenTo(v: unknown) {
    this.setTruncated();
  }

  @Input() set ekonOverflowDisabled(v: boolean) {
    this._disabled = v;
    this.clsTruncated();
    this.setTruncated();
  }

  @Input('ekonOverflow') set fixedHeight(h: string | number | '') {
    if(typeof h === 'string' && h.trim().length) {
      this._fixedHeight = h;
    } else if(typeof h === 'number') {
      this._fixedHeight = `${h}px`;
    }

    this.clsTruncated();
  };

  truncated = false;
  minimized = true;

  constructor(
    private hostElRef: ElementRef<Element>,
    private cdr: ChangeDetectorRef
  ) {
    this.clsTruncated();
  }

  ngAfterViewInit(): void {
    this.setTruncated();
  }

  setTruncated(): void {
    const el: HTMLElement = this.hostElRef.nativeElement as HTMLElement;

    setTimeout(() => {
      this.truncated = el.clientHeight < el.scrollHeight;
      this.cdr.markForCheck();
    }, 100);
  }

  minimize(): void {
    this.minimized = true;
    this.clsTruncated();
  }

  maximize(): void {
    this.minimized = false;
    this.clsTruncated();
  }

  toggle(): void {
    this.minimized = !this.minimized;
    this.clsTruncated();

    this.minimized && this.hostElRef.nativeElement.scrollIntoView(true);
  }

  clsTruncated(): void {
    const host = (this.hostElRef.nativeElement as HTMLElement),
      cls = 'ekon-overflow-truncated';

    this.minimized && !this._disabled ? host.classList.add(cls) : host.classList.remove(cls);
    if(this.minimized && !this._disabled) {
      host.classList.add(cls);
      host.style.maxHeight = this._fixedHeight;
    } else {
      host.classList.remove(cls);
      host.style.maxHeight = '';
    }

    this.cdr.markForCheck();
  }
}
