import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  DLTService, DLTServiceInterface, DltTenderModel, TenderCreatedModel
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject} from 'rxjs';

import { DLTEventsServiceInterface } from './DLTEventsServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class DLTEventsService
  implements DLTEventsServiceInterface, DLTServiceInterface {
  get defaultHeaders(): HttpHeaders {
    return this.tenderService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.tenderService.defaultHeaders = val;
  }

  get configuration(): Configuration {
    return this.tenderService.configuration;
  }

  set configuration(val: Configuration) {
    this.tenderService.configuration = val;
  }

  private itemCreatedSubject = new Subject<DltTenderModel>();
  get itemCreated(): Observable<DltTenderModel> {
    return this.itemCreatedSubject.asObservable();
  }

  private itemDeletedSubject = new Subject<string>();
  get itemDeleted(): Observable<string> {
    return this.itemDeletedSubject.asObservable();
  }

  private itemUpdatedSubject = new Subject<string>();
  get itemUpdated(): Observable<string> {
    return this.itemUpdatedSubject.asObservable();
  }

  constructor(
    private tenderService: DLTService,
    private progressBar: EkonProgressBarService
  ) { }

  findTenderByBasketId(basketId: string): Observable<Array<DltTenderModel>> {
    return this.progressBar
      .apply(this.tenderService.findTenderByBasketId(basketId));
  }

  findTenderByCustomerLegalEntityId(customerLegalEntityId: string): Observable<Array<DltTenderModel>> {
    return this.progressBar
      .apply(this.tenderService.findTenderByCustomerLegalEntityId(customerLegalEntityId));
  }

  findTenderBySupplierLegalEntityId(supplierLegalEntityId: string): Observable<Array<DltTenderModel>> {
    return this.progressBar
      .apply(this.tenderService.findTenderBySupplierLegalEntityId(supplierLegalEntityId));
  }

  findTenderByTenderId(tenderId: string): Observable<Array<DltTenderModel>> {
    return this.progressBar
      .apply(this.tenderService.findTenderByTenderId(tenderId));
  }

  findTenderByUser(): Observable<Array<DltTenderModel>> {
    return this.progressBar
      .apply(this.tenderService.findTenderByUser());
  }

  tenderBidAcceptUpdate(): Observable<Blob> {
    return undefined;
  }

  tenderBidDeclineUpdate(): Observable<Blob> {
    return undefined;
  }

  tenderBidUpdate(): Observable<Blob> {
    return undefined;
  }

  tenderCreated(tenderCreatedModel: TenderCreatedModel): Observable<Blob> {
    return this.progressBar.apply(this.tenderService.tenderCreated(tenderCreatedModel));
  }

}
