/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Element } from './element';


export interface TextAreaInputElement extends Element { 
    /**
     * The autocomplete attribute specifies whether or not an input field should have autocomplete enabled  types: text, search, url, tel, email, password, datepickers, range, and color
     */
    autocomplete?: boolean | null;
    /**
     * Specifies the visible width of a text area  Specifies the width of the text area (in average character width). Default value is 20
     */
    columns?: number | null;
    /**
     * Flag, indicating placeholder defigned
     */
    readonly hasAutocomplete?: boolean;
    /**
     * Flag, indicating element label defigned
     */
    readonly hasLabel?: boolean;
    /**
     * Flag, indicating pattern validation defigned
     */
    readonly hasPatternValidation?: boolean;
    /**
     * Flag, indicating placeholder defigned
     */
    readonly hasPlaceholder?: boolean;
    id: string;
    /**
     * Visible lable of element
     */
    label?: string | null;
    /**
     * The maximum number of characters the element should accept
     */
    maxlength?: number | null;
    /**
     * The minimum number of characters long the element can be and still be considered valid
     */
    minlength?: number | null;
    /**
     * Element name
     */
    name: string;
    /**
     * A regular expression the element contents must match in order to be valid
     */
    pattern?: string | null;
    /**
     * An exemplar value to display in the element whenever it is empty
     */
    placeholder?: string | null;
    /**
     * Specifies whether or not the contents of the element should be read-only
     */
    readonly?: boolean | null;
    /**
     * Specifies that an element must be filled out before save
     */
    required?: boolean | null;
    /**
     * Specifies the height of the text area (in lines). Default value is 2
     */
    rows?: number | null;
    /**
     * Specifies short description of element
     */
    shortDescription: string;
    /**
     * Specifies the width of an element, in characters. Default value is 20
     */
    size?: number | null;
    /**
     * enable client side spell checking for an element
     */
    spellcheck?: boolean | null;
    /**
     * Text value
     */
    value?: string | null;
}

