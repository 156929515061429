/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateCategoryContent { 
    id: string;
    name: string;
    isNameUpdated?: boolean;
    shortDescription?: string | null;
    isShortDescriptionUpdated?: boolean;
    description?: string | null;
    isDescriptionUpdated?: boolean;
    icon?: string | null;
    isIconUpdated?: boolean;
    domains?: Array<string> | null;
    isDomainsUpdated?: boolean;
    parentId?: string | null;
    isParentIdUpdated?: boolean;
}

