import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { ekIconChevronDown } from '@ekon-client/shared/common/ekon-icons';
import { EkonLayoutConfig } from '@ekon-layout-utils';
import { EkonLayoutConfigService } from '@ekon-layout-utils/services';
import { Observable, Subject } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'dkm-layout-theme-setter',
  templateUrl: './theme-setter.component.html',
  styleUrls: ['./theme-setter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeSetterComponent implements OnDestroy {
  ekIconChevronDown = ekIconChevronDown;

  isDarkMode$: Observable<boolean>;
  selected$: Observable<string>;

  handleToggle$: Observable<unknown>;

  themes$: Observable<{
    key: string;
    label: string;
    mode: string; // 'dark' | 'light'
  }[]>;

  private readonly _toggleLight$: Subject<void> = new Subject();

  constructor(
    globalConfig: GlobalConfigurationService,
    titleCase: TitleCasePipe,
    private ekonLayoutConfigService: EkonLayoutConfigService,
    private meta: Meta
  ) {
    this.themes$ = globalConfig.colorThemes$.pipe(
      map(themes => themes.map(key => {
        const parts = key.split('-'),
          label = titleCase.transform(parts.slice(1, -1).join(' ')),
          mode = parts.pop();

        return { key, label, mode };
      }))
    );

    this.selected$ = ekonLayoutConfigService.config.pipe(
      map((config: EkonLayoutConfig) => config.colorTheme)
    );

    this.isDarkMode$ = this.selected$.pipe(
      map((theme: string) => theme.includes('dark', theme.length - 6))
    );

    this.handleToggle$ = this._toggleLight$.pipe(
      withLatestFrom(
        this.isDarkMode$,
        globalConfig.colorThemes$
      ),
      map(([, isDark, themes]: [void, boolean, string[]]) =>
        themes.find(t => t.toLowerCase()
          .includes(isDark ? 'light' : 'dark', t.length - 6)
        )
      ),
      tap({
        next: (theme: string) => this.setTheme(theme)
      })
    );
  }

  toggleLight(): void {
    this._toggleLight$.next();
  }

  setTheme(colorTheme: string): void {
    localStorage.setItem('colorTheme', colorTheme);
    this.ekonLayoutConfigService.setConfig({ colorTheme });
    this.meta.updateTag({
      content: colorTheme.toLowerCase().includes('dark', colorTheme.length - 6)
               ? '#424242'
               : 'whitesmoke'
    }, 'name=theme-color');
  }

  ngOnDestroy(): void {
    this._toggleLight$.complete();
  }
}
