import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const BLOCK_CONTENT_ACTIONS = new InjectionToken('Block Content model actions');
export const BLOCK_CONTENT_EVENTS = new InjectionToken('Block Content model events');

export interface BlockContentEventsServiceInterface {
  itemBlocked: Observable<string>; // entity id
  itemUnblocked: Observable<string>; // entity id
}
