<mat-action-list
  role="list"
  class="list"
  [ngClass]="{
    'multi-column': multiColumn
  }"
>
  <mat-list-item
    role="listitem"
    *ngFor="let domain of domains"
    class="list-item"
    (click)="selectItem.emit(domain)"
  >
    <div class="list-item-content">
      <dkm-core-ava-viewer
        [fileLink]="domain.thumbprintUrl"
        [noShape]="true"
        [editDisabled]="true"
        class="avatar"
      ></dkm-core-ava-viewer>
      <div class="title">{{domain.name}}</div>
      <button
        *ngIf="displayRemoveBtn"
        mat-icon-button
        type="button"
        color="warn"
        (click)="$event.stopPropagation(); removeItem.emit(domain)"
        class="action-btn"
      >
        <ekon-icon [icon]="ekIconTimes"></ekon-icon>
      </button>
    </div>
  </mat-list-item>
</mat-action-list>
