import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EkonAuthService } from '@ekon-client/auth';
import {
  DkmDomain,
  DomainServiceInterface
} from '@ekon-client/dkm-api';
import {
  DOMAIN_ACTIONS,
  DOMAIN_EVENTS,
  DomainEventsServiceInterface
} from '@ekon-client/dkm-events';
// import { OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { BehaviorSubject, merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DomainSelectorService {
  /**
   * Selected domains
   */
  private _domainsSelectionChanged$: Subject<DkmDomain[]> = new Subject();
  get domainsSelectionChanged$(): Observable<DkmDomain[]> {
    return this._domainsSelectionChanged$.asObservable();
  }

  private _filterPanelActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly filterPanelActive$: Observable<boolean> = this._filterPanelActive$.asObservable();

  private _domainsSelected: BehaviorSubject<DkmDomain[]> = new BehaviorSubject(null);
  get domainsSelected$(): Observable<DkmDomain[]> {
    return this._domainsSelected.asObservable()
      .pipe(filter(r => Boolean(r)));
  }

  set domainsSelected(domains: DkmDomain[]) {
    localStorage.setItem(
      'selectedDomains',
      JSON.stringify(domains.map((d) => d.id))
    );
    this._domainsSelected.next(domains);
    this._domainsSelectionChanged$.next(domains);
  }

  constructor(
    private authService: EkonAuthService,
    @Inject(DOMAIN_ACTIONS) private domainActions: DomainServiceInterface,
    @Inject(DOMAIN_EVENTS) private domainEvents: DomainEventsServiceInterface
  ) {

    // const userOidcProfileLoad$ = this.authService.events.pipe(
    //   filter(event => event instanceof OAuthSuccessEvent
    //     && event.type === 'user_profile_loaded'),
    //   take(1)
    // );

    merge(
      domainEvents.domainCreated,
      domainEvents.domainDeleted,
      domainEvents.domainUpdated,
      domainEvents.domainMembershipConfirmed,
      domainEvents.domainMembershipLeft,
      this.authService.isLoggedIn$.pipe(filter(u => !!u))
    )
      .pipe(
        switchMap(() => this.domainActions.listDomainsByIds(
          JSON.parse(localStorage.getItem('selectedDomains')) || []
        ).pipe(catchError((error: HttpErrorResponse) => error.status === 404
          ? of([] as DkmDomain[])
          : throwError(() => error)
        ))
        )
      )
      .subscribe(this._domainsSelected);
  }

  setDomains(domains: DkmDomain[]): void {
    this.domainsSelected = domains;
  }

  togglePanel(): void {
    this.filterPanelActive$.pipe(
      take(1)
    ).subscribe({
      next: (active: boolean) => this._filterPanelActive$.next(!active)
    });
  }

  openPanel(): void {
    this._filterPanelActive$.next(true);
  }

  closePanel(): void {
    this._filterPanelActive$.next(false);
  }
}
