import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  Arc,
  arc,
  interpolateNumber,
  interpolateSpectral,
  pie,
  PieArcDatum,
  quantize,
  ScaleOrdinal,
  scaleOrdinal,
  select
} from 'd3';
import ResizeObserver from 'resize-observer-polyfill';

import { NameValuePair } from '../../extras';
import { SelectionSVG, SelectionSVGG } from '../../extras/SelectionTypes';


@Component({
  selector: 'ekon-pie-graph',
  template: ``,
  styleUrls: ['./pie-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieGraphComponent implements AfterViewInit, OnChanges {
  @Input() data: NameValuePair[];
  @Input() ignoreZeros: boolean;

  private margin = { top: 20, right: 20, bottom: 30, left: 20 };
  private svg: SelectionSVG;
  private container: SelectionSVGG;

  hostEl: Element;

  resizeObserver: ResizeObserver;

  pie = pie<NameValuePair>()
    .sort(null)
    .value((d: NameValuePair) => d.value);

  private tDuration = 500;

  constructor(private hostElRef: ElementRef<Element>) {
    this.hostEl = this.hostElRef.nativeElement;
  }

  arcGen(radius: number): Arc<void, PieArcDatum<NameValuePair>> {
    return arc<PieArcDatum<NameValuePair>>()
      .innerRadius(radius * 0.60)
      .outerRadius(radius * 0.90);
  }

  labelsArcGen(radius: number): Arc<void, PieArcDatum<NameValuePair>> {
    return arc<PieArcDatum<NameValuePair>>()
      .innerRadius(radius)
      .outerRadius(radius);
  }

  colorScale(data: string[]): ScaleOrdinal<string, string> {
    return scaleOrdinal<string>(data)
      .range(
        quantize(
          t => interpolateSpectral(t * 0.8 + 0.1),
          data.length
        ).reverse()
      );
  }

  ngAfterViewInit(): void {
    this.svg = select(this.hostEl)
      .append('svg');

    this.container = this.svg
      .append('g')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle');


    // this.data && this.drawGraph(
    //   this.data,
    //   this.hostEl.clientWidth,
    //   this.hostEl.clientHeight
    // );

    let timeout;

    this.resizeObserver = new ResizeObserver(
      ([{ contentRect: bounds }]: ResizeObserverEntry[]) => {
        timeout && clearTimeout(timeout);

        timeout = setTimeout(() => {
          this.data && this.drawGraph(
            this.data,
            bounds.width,
            bounds.height
          );
        }, 100);
      }
    );

    this.resizeObserver.observe(this.hostEl);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && !changes.data.firstChange && changes.data.currentValue) {
      this.drawGraph(
        changes.data.currentValue,
        this.hostEl.clientWidth,
        this.hostEl.clientHeight
      );
    }
    if (
      changes.ignoreZeros
      && !changes.ignoreZeros.firstChange
      && changes.ignoreZeros.currentValue !== changes.ignoreZeros.previousValue
      && this.data
    ) {
      this.drawGraph(
        this.data,
        this.hostEl.clientWidth,
        this.hostEl.clientHeight
      );
    }
  }

  drawGraph(data: NameValuePair[], pWidth: number, pHeight: number): void {
    if (this.ignoreZeros) {
      data = data.filter(d => d.value);
    }

    const width = pWidth - this.margin.left - this.margin.right;
    const height = pHeight - this.margin.top - this.margin.bottom;
    if (width < 0 || height < 0) {
      return;
    }

    let maxRadius = width / 2;

    this.svg
      .append('g')
      .attr('opacity', '0')
      .call(tempG => tempG
        .selectAll('text')
        .data(data.map(d => d.name))
        .join(
          enter => enter
            .append('text')
            .text(d => d.toLocaleString())
        )
        .call(n => {
          maxRadius = Math.min(
            maxRadius - Math.max(...n.nodes().map((tn: SVGTextContentElement) => tn.getComputedTextLength())),
            height / 2
          );
        })
      )
      .call(tempG => tempG.remove());


    const arc = this.arcGen(maxRadius);
    const labelsArc = this.labelsArcGen(maxRadius);
    const pies = this.pie(data);
    const colors = this.colorScale(data.map(d => d.name));

    this.svg
      .attr('width', pWidth)
      .attr('height', pHeight)
      // .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewBox', `-${pWidth / 2}, -${pHeight / 2}, ${pWidth}, ${pHeight}`);
    // .selectAll('*').interrupt();

    const oldData: PieArcDatum<NameValuePair>[] = this.container.selectAll('g').data() as PieArcDatum<NameValuePair>[];

    this.container
      .selectAll('g')
      .data(pies, (d: NameValuePair) => d.name)
      .join(
        enter => enter.append('g')
          .call(arcG => {
            // arc pies
            arcG
              .append('path');

            // value text
            arcG
              .append('text')
              .attr('class', 'value');

            // label text
            arcG
              .append('text')
              .attr('class', 'label');

            // label lines
            arcG
              .append('polyline');
          }),
        update => update,
        exit => exit
          .call(arcG => arcG
              .remove()
            // .select('path')
            // .transition()
            // .duration(this.tDuration)
            // .attrTween('d', (d: PieArcDatum<NameValuePair>) => {
            //   console.warn('exit called', d);
            //   // const i = interpolateObject(prevState, nextState);
            //   const s = interpolateNumber(d.startAngle, d.startAngle);
            //   const e = interpolateNumber(d.endAngle, d.startAngle);
            //   return (t) => {
            //     d.startAngle = s(t);
            //     d.endAngle = e(t);
            //     console.log(d.data.name, d.startAngle, d.endAngle);
            //     return arc(d);
            //   };
            // })
            // .on('end', () => arcG.remove())
          )
      )
      .call(arcG => {
        // arc pies
        arcG
          .select('path')
          .transition()
          // .delay(this.tDuration)
          .duration(this.tDuration)
          .attr('fill', (...[d,, a]) => a.length > 1 ? colors(d.data.name) : '#69b3a2')
          .attrTween('d', (d: PieArcDatum<NameValuePair>) => {
            const prevState = oldData.find(i => i.data.name === d.data.name);
            const s = interpolateNumber(prevState ? prevState.startAngle : 0.001, d.startAngle);
            const e = interpolateNumber(prevState ? prevState.endAngle : 0.001, d.endAngle);
            return (t) => {
              d.startAngle = s(t);
              d.endAngle = e(t);
              return arc(d);
            };
          });

        // value text
        arcG
          .select('text.value')
          .text(d => d.data.value.toLocaleString())
          // .transition()
          // .duration(this.tDuration)
          // .attr('opacity', 0)
          .attr('x', (d: PieArcDatum<NameValuePair>) => arc.centroid(d)[0])
          .attr('y', (d: PieArcDatum<NameValuePair>) => arc.centroid(d)[1]);

        function midAngle(startAngle: number, endAngle: number) {
          return startAngle + (endAngle - startAngle) / 2;
        }

        // label text
        arcG
          .select('text.label')
          .attr('fill', '#9c9c9c')
          .transition()
          .duration(this.tDuration)
          .attrTween('transform', (d: PieArcDatum<NameValuePair>) => {
            const prevState = oldData.find(i => i.data.name === d.data.name);
            const s = interpolateNumber(prevState ? prevState.startAngle : 0.001, d.startAngle);
            const e = interpolateNumber(prevState ? prevState.endAngle : 0.001, d.endAngle);
            return (t) => {
              d.startAngle = s(t);
              d.endAngle = e(t);
              const pos = labelsArc.centroid(d);
              pos[0] = labelsArc.outerRadius()(d) * 1.05 * (midAngle(d.startAngle, d.endAngle) < Math.PI ? 1 : -1);
              return 'translate(' + pos + ')';
            };
          })
          .styleTween('text-anchor', (d: PieArcDatum<NameValuePair>) => {
            const prevState = oldData.find(i => i.data.name === d.data.name);
            const s = interpolateNumber(prevState ? prevState.startAngle : 0.001, d.startAngle);
            const e = interpolateNumber(prevState ? prevState.endAngle : 0.001, d.endAngle);
            return (t) => {
              return midAngle(s(t), e(t)) < Math.PI ? 'start' : 'end';
            };
          })
          .text(d => d.data.name.toLocaleString());

        arcG
          .select('polyline')
          .attr('opacity', '.3')
          .attr('stroke', '#9c9c9c')
          .attr('stroke-width', '1px')
          .attr('fill', 'none')
          .transition()
          .duration(this.tDuration)
          .attrTween('points', (d: PieArcDatum<NameValuePair>) => {
            const prevState = oldData.find(i => i.data.name === d.data.name);
            const s = interpolateNumber(prevState ? prevState.startAngle : 0.001, d.startAngle);
            const e = interpolateNumber(prevState ? prevState.endAngle : 0.001, d.endAngle);
            return (t) => {
              d.startAngle = s(t);
              d.endAngle = e(t);
              const p1 = arc.centroid(d);
              const p2 = labelsArc.centroid(d);
              const p3 = [
                labelsArc.outerRadius()(d) * (midAngle(d.startAngle, d.endAngle) < Math.PI ? 1 : -1),
                p2[1]
              ];
              return `${p1} ${p2} ${p3}`;
            };
          });

      });
  }

}
