<button
  mat-raised-button
  class="main-action-sub ml-8"
  routerLink="share"
  *ngIf="globalConfig.enableFeed"
>
  <ekon-icon icon="share-outline"></ekon-icon>
  <span class="ml-8">{{'SHARE' | translate}}</span>
</button>
<button
  *ngIf="globalConfig.enableFeed"
  mat-raised-button
  class="menu-trigger-sub"
  [matMenuTriggerFor]="shareActionsMenu"
  matTooltip="More actions"
>
  <ekon-icon icon="ellipsis-v" size="lg"></ekon-icon>
</button>


<mat-menu #shareActionsMenu="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      routerLink="related-posts"
    >
      <ekon-icon icon="related-posts" ></ekon-icon>
      <span class="ml-8">{{'RELATED_USER_POSTS' | translate}}</span>
    </button>

    <button
      mat-menu-item
      routerLink="relations"
      *ngIf="showRelationsGraph"
    >
      <ekon-icon icon="stores"></ekon-icon>
      <span class="ml-8">{{'RELATIONS_GRAPH' | translate}}</span>
    </button>

    <ng-container *ngIf="globalConfig.enableBlockNReportContent$ | async">
      <button
        *ngIf="enableBlock"
        mat-menu-item
        (click)="report()"
      >
        <ekon-icon icon="exclamation-triangle" class="mr-8"></ekon-icon>
        <span>{{'REPORT' | translate: { default: 'Report' } }}</span>
      </button>
      <button
        *ngIf="enableBlock"
        mat-menu-item
        (click)="block()"
      >
        <ekon-icon icon="exclamation-triangle" class="mr-8"></ekon-icon>
        <span>{{'HIDE' | translate }}</span>
      </button>
    </ng-container>

  </ng-template>
</mat-menu>
