import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BlogInvitationModel,
  Configuration, CreateInvitationModel, DomainContributors,
DomainInvitationModel,
  DomainInviteesModel,
  InvitationModel, InvitationResponseAllModel, InvitationResponseModel, InvitationService,
  InvitationServiceInterface, LegalEntityInvitationModel, PageContributors, PageInvitationModel, PageInviteesModel,
  UserInvitationsListModel, UserProfileHeader, } from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { InvitationEventsServiceInterface } from './InvitationEventsServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class InvitationEventsService
  implements InvitationEventsServiceInterface, InvitationServiceInterface {
  get defaultHeaders(): HttpHeaders {
    return this.invitationService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.invitationService.defaultHeaders = val;
  }

  get configuration(): Configuration {
    return this.invitationService.configuration;
  }

  set configuration(val: Configuration) {
    this.invitationService.configuration = val;
  }

  private invitationToDomainCreatedSubject = new Subject<DomainInvitationModel>();

  get invitationToDomainCreated(): Observable<DomainInvitationModel> {
    return this.invitationToDomainCreatedSubject.asObservable();
  }

  private invitationToLegalEntityCreatedSubject = new Subject<LegalEntityInvitationModel>();

  get invitationToLegalEntityCreated(): Observable<LegalEntityInvitationModel> {
    return this.invitationToLegalEntityCreatedSubject.asObservable();
  }

  private invitationToPageCreatedSubject = new Subject<PageInvitationModel>();

  get invitationToPageCreated(): Observable<PageInvitationModel> {
    return this.invitationToPageCreatedSubject.asObservable();
  }

  private invitationToBlogCreatedSubject = new Subject<BlogInvitationModel>();

  get invitationToBlogCreated(): Observable<BlogInvitationModel> {
    return this.invitationToBlogCreatedSubject.asObservable();
  }

  private invitationDeletedSubject = new Subject<string>();

  get invitationDeleted(): Observable<string> {
    return this.invitationDeletedSubject.asObservable();
  }

  private invitationRespondedSubject = new Subject<InvitationResponseModel>();

  get invitationResponded(): Observable<InvitationResponseModel> {
    return this.invitationRespondedSubject.asObservable();
  }

  constructor(
    private invitationService: InvitationService,
    private progressBar: EkonProgressBarService
  ) {
  }

  createDomainInvitation(createInvitationModel: CreateInvitationModel): Observable<DomainInvitationModel> {
    return this.progressBar.apply(
      this.invitationService.createDomainInvitation(createInvitationModel)
        .pipe(
          tap((invitation: DomainInvitationModel) =>
            this.invitationToDomainCreatedSubject.next(invitation))
        )
    );
  }

  createLegalEntityInvitation(createInvitationModel: CreateInvitationModel): Observable<LegalEntityInvitationModel> {
    return this.progressBar.apply(
      this.invitationService.createLegalEntityInvitation(createInvitationModel)
        .pipe(
          tap((invitation: LegalEntityInvitationModel) =>
            this.invitationToLegalEntityCreatedSubject.next(invitation))
        )
    );
  }

  deleteInvitation(id: string): Observable<unknown> {
    return this.progressBar.apply(
      this.invitationService.deleteInvitation(id)
        .pipe(
          tap(() => this.invitationDeletedSubject.next(id))
        )
    );
  }

  findInvitationById(id: string): Observable<InvitationModel> {
    return this.progressBar.apply(
      this.invitationService.findInvitationById(id)
    );
  }

  findUsersInvitations(email: string): Observable<UserInvitationsListModel> {
    return this.progressBar.apply(
      this.invitationService.findUsersInvitations(email)
    );
  }

  invitationRespond(id: string, response: InvitationResponseModel): Observable<InvitationModel | DomainInvitationModel | PageInvitationModel | BlogInvitationModel | LegalEntityInvitationModel> {
    return this.progressBar.apply(
      this.invitationService.invitationRespond(id, response)
        .pipe(
          tap(() => this.invitationRespondedSubject.next(response))
        )
    );
  }

  findPendingInvitations(xSelectedDomain?: string): Observable<UserInvitationsListModel> {
    return this.progressBar.apply(
      this.invitationService.findPendingInvitations(xSelectedDomain)
    );
  }

  findPendingInvitationsByEntityId(id: string, xSelectedDomain?: string): Observable<Array<InvitationModel>> {
    return this.progressBar.apply(
      this.invitationService.findPendingInvitationsByEntityId(id, xSelectedDomain)
    );
  }

  createBlogInvitation(createInvitationModel: CreateInvitationModel): Observable<BlogInvitationModel> {
    return this.progressBar.apply(
      this.invitationService.createBlogInvitation(createInvitationModel)
        .pipe(
          tap((invitation: BlogInvitationModel) =>
            this.invitationToBlogCreatedSubject.next(invitation))
        )
    );
  }

  createPageInvitation(createInvitationModel: CreateInvitationModel): Observable<PageInvitationModel> {
    return this.progressBar.apply(
      this.invitationService.createPageInvitation(createInvitationModel)
        .pipe(
          tap((invitation: PageInvitationModel) =>
            this.invitationToPageCreatedSubject.next(invitation))
        )
    );
  }

  invitationRespondAll(
    response?: InvitationResponseAllModel,
  ): Observable<unknown> {
    return this.progressBar.apply(
      this.invitationService.invitationRespondAll(response)
        .pipe(
          tap(() => this.invitationRespondedSubject.next(response))
        )
    );
  }

  pageInvitees(pageInviteesModel?: PageInviteesModel, xSelectedDomain?: string): Observable<Array<PageContributors>> {
    return this.progressBar.apply(
      this.invitationService.pageInvitees(pageInviteesModel, xSelectedDomain)
    );
  }

  domainInvitees(domainInviteesModel?: DomainInviteesModel, xSelectedDomain?: string): Observable<Array<DomainContributors>> {
    return this.progressBar.apply(
      this.invitationService.domainInvitees(domainInviteesModel, xSelectedDomain)
    );
  }

}
