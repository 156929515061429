/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserHeaderModel } from './userHeaderModel';


/**
 * For editing and details view
 */
export interface Comment { 
    /**
     * Identifier
     */
    id: string;
    /**
     * Id of refering object, such as: Page/Template or other entity...
     */
    referrerId?: string | null;
    /**
     * Comment parent id in case of discussion reply
     */
    parentId?: string | null;
    /**
     * Text content
     */
    value: string;
    lastUpdateAt?: Date;
    publishedAt?: Date | null;
    publishedBy?: string | null;
    publisher?: UserHeaderModel;
    /**
     * Sub comments to comment
     */
    subComments?: Array<Comment> | null;
}

