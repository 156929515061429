/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LegalEntityHeaderModel } from './legalEntityHeaderModel';
import { Category } from './category';
import { PageHeader } from './pageHeader';
import { UserHeaderModel } from './userHeaderModel';
import { DkmDomainHeaderModel } from './dkmDomainHeaderModel';
import { Pricing } from './pricing';


export interface ProductHeader extends PageHeader { 
    pricingDetails?: Pricing;
    legalentityHeader?: LegalEntityHeaderModel;
}

