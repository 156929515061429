/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *   StaticRecipients  User  Admin
 */
export type RecipientTypes = 'StaticRecipients' | 'User' | 'Admin';

export const RecipientTypes = {
    StaticRecipients: 'StaticRecipients' as RecipientTypes,
    User: 'User' as RecipientTypes,
    Admin: 'Admin' as RecipientTypes
};

