import { ChangeDetectionStrategy, Component, Inject,TrackByFunction } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DkmDomainHeaderModel, DkmDomainPagedResult, DomainServiceInterface } from '@ekon-client/dkm-api';
import {
  DOMAIN_ACTIONS, DOMAIN_EVENTS, DomainEventsServiceInterface
} from '@ekon-client/dkm-events';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { PaginationModel, PaginatorConfig } from '@ekon-client/shared/common/ekon-pagination';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './domain-selector-dialog.component.html',
  styleUrls: ['./domain-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainSelectorDialogComponent {

  paginatorConfig: PaginatorConfig;

  trackById: TrackByFunction<DkmDomainHeaderModel> = (index: number, item: DkmDomainHeaderModel) => item.id;

  constructor(
    private dialogRef: MatDialogRef<DomainSelectorDialogComponent>,
    @Inject(DOMAIN_ACTIONS) private domainActions: DomainServiceInterface,
    @Inject(DOMAIN_EVENTS) private domainEvents: DomainEventsServiceInterface,
    public globalConfig: GlobalConfigurationService,
    @Inject(MAT_DIALOG_DATA) public data: { isOwnerMode: boolean }
  ) {
    this.paginatorConfig = {
      loader: (pagination: PaginationModel): Observable<DkmDomainPagedResult> => this.data.isOwnerMode
        ? this.domainActions.listByCreteria(
          pagination,
          ['IsOwnerOf', 'IsManagerOf'])
        : this.domainActions.listDomains(pagination),
      observables: [
        this.domainEvents.domainCreated,
        this.domainEvents.domainUpdated,
        this.domainEvents.domainDeleted,
      ]
    }
  }

  validateAndSave(post: DkmDomainHeaderModel): void {
    this.dialogRef.close(post);
  }
}
