import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Category } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CategoriesService } from '../../services/categories.service';

export enum CATEGORIES_SELECTOR_DIALOG_MODES {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export interface CategoriesSelectorDialogData {
  categories: Category[];
  mode?: CATEGORIES_SELECTOR_DIALOG_MODES;
  preselected?: Category[];
}

export const CATEGORIES_SELECTOR_DIALOG_COMPONENT_STYLES = `
mat-tree.categories-tree {
  background-color: transparent;
}

.categories-tree {

  &-invisible {
    display: none;
  }

  ul,
  li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  .mat-tree-node {
    margin-bottom: 4px;
  }
}

.category-selector {
  cursor: pointer;
  padding: 10px 16px;
}
`;


@Component({
  selector: 'dkm-categories-selector-dialog',
  templateUrl: './categories-selector-dialog.component.html',
  styles: [CATEGORIES_SELECTOR_DIALOG_COMPONENT_STYLES],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesSelectorDialogComponent {
  treeControl = new NestedTreeControl<Category>(
    (node: Category) => node.subCategories
  );

  dataSource$: Observable<Category[]>;

  /** The selection for checklist */
  checklistSelection: SelectionModel<Category>;

  MODES = CATEGORIES_SELECTOR_DIALOG_MODES;
  mode: CATEGORIES_SELECTOR_DIALOG_MODES;

  constructor(
    private categoriesService: CategoriesService,
    private dialogRef: MatDialogRef<CategoriesSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CategoriesSelectorDialogData
  ) {
    this.mode = data && data.mode ? data.mode : CATEGORIES_SELECTOR_DIALOG_MODES.SINGLE;

    this.dataSource$ = this.categoriesService.listCategories().pipe(
      map(categories => {
        this.checklistSelection = new SelectionModel<Category>(
          true,
          data?.preselected?.map(p => categories.find(c => p?.id === c.id)),
          true
        );

        return categories;
      }),
    );
  }

  hasChild(id: number, node: Category): boolean {
    return !!node.subCategories && node.subCategories.length > 0;
  }

  selectCategory(category: Category): void {
    this.dialogRef.close(category);
  }

  toggleCategorySelection(category: Category): void {
    this.checklistSelection.toggle(category);
  }

  itemSelected(category: Category): boolean {
    return this.checklistSelection.isSelected(category);
  }

  descendantsPartiallySelected(category: Category): boolean {
    return (
      this.treeControl
        .getDescendants(category)
        .some((c: Category) => this.checklistSelection.isSelected(c)) &&
      !this.checklistSelection.isSelected(category)
    );
  }

  applySelection(): void {
    this.dialogRef.close(this.checklistSelection.selected);
  }

  clearSelection(): void {
    this.checklistSelection.clear();
  }
}
