<!-- SIDE PANEL -->
<ng-container *ngIf="!ekonLayoutConfig.layout.sidepanel.hidden && ekonLayoutConfig.layout.sidepanel.position === 'left'">

  <!-- PANEL CONTENT -->

</ng-container>
<!-- / SIDE PANEL -->

<h1>Second Vertical Layout</h1>

<div id="main">

  <!-- TOOLBAR: Above fixed -->
  <ng-container *ngIf="ekonLayoutConfig.layout.toolbar.position === 'above-fixed'">
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  </ng-container>
  <!-- / TOOLBAR: Above fixed -->

  <div id="container-1" class="container">

    <!-- TOOLBAR: Above static -->
    <ng-container *ngIf="ekonLayoutConfig.layout.toolbar.position === 'above-static'">
      <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above static -->

    <div id="container-2" class="container">

      <!-- NAVBAR: Left -->
      <ng-container *ngIf="ekonLayoutConfig.layout.navbar.position === 'left'">
        <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
      </ng-container>
      <!-- / NAVBAR: Left -->

      <!-- CONTENT -->
      <router-outlet *ngIf="true"></router-outlet>
      <router-outlet name="chat-bot"></router-outlet>
      <!-- / CONTENT -->

      <!-- NAVBAR: Right -->
      <ng-container *ngIf="ekonLayoutConfig.layout.navbar.position === 'right'">
        <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
      </ng-container>
      <!-- / NAVBAR: Right -->

    </div>

    <!-- FOOTER: Above static -->
    <ng-container *ngIf="ekonLayoutConfig.layout.footer.position === 'above-static'">
      <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- FOOTER: Above static -->

  </div>

  <!-- FOOTER: Above fixed -->
  <ng-container *ngIf="ekonLayoutConfig.layout.footer.position === 'above-fixed'">
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  </ng-container>
  <!-- FOOTER: Above fixed -->

</div>

<!-- SIDE PANEL -->
<ng-container *ngIf="!ekonLayoutConfig.layout.sidepanel.hidden && ekonLayoutConfig.layout.sidepanel.position === 'right'">

  <!-- PANEL CONTENT -->

</ng-container>
<!-- / SIDE PANEL -->


<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
  <toolbar *ngIf="!ekonLayoutConfig.layout.toolbar.hidden"
           [ngClass]="ekonLayoutConfig.layout.toolbar.customBackgroundColor === true ? ekonLayoutConfig.layout.toolbar.position + ' ' + ekonLayoutConfig.layout.toolbar.background : ekonLayoutConfig.layout.toolbar.position">
  </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
  <footer *ngIf="!ekonLayoutConfig.layout.footer.hidden"
          [ngClass]="ekonLayoutConfig.layout.footer.customBackgroundColor === true ? ekonLayoutConfig.layout.footer.position + ' ' + ekonLayoutConfig.layout.footer.background : ekonLayoutConfig.layout.footer.position">
  </footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
</ng-template>
<!-- / RIGHT NAVBAR -->
