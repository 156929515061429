import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';

import { FileDataSource, FileTypesWeCanView} from '../../../../../extras';

@Component({
  selector: 'ekon-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileViewerComponent {

  FileTypesWeCanView = FileTypesWeCanView;

  @Input() hideCredentials: boolean;
  @Input() fileData: FileDataSource;
}
