import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditabilityTypes, VisibilityTypes } from '@ekon-client/dkm-api';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { TranslateService } from '@ngx-translate/core';
import { get as _get } from 'lodash-es';

export interface SharingOptionsDialogData<T> {
  entity: T;
  config?: {
    visibility?: VisibilityTypes[];
    editability?: EditabilityTypes[];
  }
}

export const SHARING_OPTIONS_DIALOG_CONFIG_DEFAULT = {
  visibility: [
    VisibilityTypes.Private,
    VisibilityTypes.Internal,
    VisibilityTypes.Protected
  ],
  editability: [
    EditabilityTypes.Private,
    EditabilityTypes.Internal,
    EditabilityTypes.Protected,
  ]
};


interface OptionLabelWithDescription {
  label: string;
  description: string;
}


@Component({
  templateUrl: './sharing-options-dialog.component.html',
  styles: [`
    label {
      display: block;
      margin: 16px 0;
    }

    mat-radio-group {
      display: flex;
      flex-direction: column;
    }

    mat-radio-button {
      margin-bottom: 16px;
    }
  `],
})
export class SharingOptionsDialogComponent<T> implements OnInit {
  form: FormGroup;

  labels: {
    visibility: Record<VisibilityTypes, OptionLabelWithDescription>;
    editability: Record<EditabilityTypes, OptionLabelWithDescription>;
  };

  VisibilityTypes: VisibilityTypes[];
  EditabilityTypes: EditabilityTypes[];

  constructor(
    protected fb: FormBuilder,
    protected dialogRef: MatDialogRef<SharingOptionsDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: SharingOptionsDialogData<T>,
    private globalConfig: GlobalConfigurationService,
    private translateService: TranslateService
  ) {
    this.VisibilityTypes = _get(data, 'config.visibility', Object.keys(VisibilityTypes) as VisibilityTypes[]);
    this.EditabilityTypes = _get(data, 'config.editability', Object.keys(EditabilityTypes) as EditabilityTypes[]);

    this.labels = {
      visibility: {
        Private: {
          label: 'Private',
          description: 'The content is only visible to you.'
        },
        Internal: {
          label: `Within ${
            this.translateService.instant(this.globalConfig.domainTitleSingular.toUpperCase()).toLowerCase()
          }`,
          description: `The content is only visible within the ${
            this.translateService.instant(this.globalConfig.domainTitleSingular.toUpperCase()).toLowerCase()
          }.`
        },
        Protected: {
          label: 'Protected',
          description: 'Content is visible to all registered users.'
        },
        Public: {
          label: 'Public',
          description: ''
        },
        All: {
          label: 'All',
          description: ''
        },
      },
      editability: {
        Private: {
          label: 'Private',
          description: 'The content can be modified only by you.'
        },
        Internal: {
          label: `Within ${
            this.translateService.instant(this.globalConfig.domainTitleSingular.toUpperCase()).toLowerCase()
          }`,
          description: `The content can be modified by users who have access to the ${
            this.translateService.instant(this.globalConfig.domainTitleSingular.toUpperCase()).toLowerCase()
          }.`
        },
        Protected: {
          label: 'Protected',
          description: 'The content can be modified by all registered users.'
        },
        Public: {
          label: 'Public',
          description: ''
        },
        All: {
          label: 'All',
          description: ''
        },
      }
    };
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      visibleTo: [_get(this.data, 'entity.visibleTo')],
      editableBy: [_get(this.data, 'entity.editableBy')],
    });
  }

  validateAndSave(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.getRawValue() as Partial<T>);
    }
  }
}
