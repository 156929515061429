import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { HammerModule } from '@angular/platform-browser';
import {
  ekIconAngleLeft,
  ekIconAngleRight,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { SliderComponent } from './components/slider/slider.component';

@NgModule({
  imports: [
    CommonModule,
    MatRippleModule,
    EkonIconsModule,
    HammerModule,
    TranslateModule
  ],
  declarations: [SliderComponent],
  exports: [SliderComponent],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/common/ekon-slider/',
      multi: true
    }
  ]
})
export class EkonSliderModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconAngleRight,
      ekIconAngleLeft
    );
  }
}
