<div
  class="menu-trigger"
  [matMenuTriggerFor]="userMenu"
  matRipple
>
  <dkm-core-ava-viewer
    class="profile-image user-button"
    [fileLink]="(userData | async)?.avatarUrl"
    [editDisabled]="true"
  >
    <ekon-icon icon="user"></ekon-icon>
  </dkm-core-ava-viewer>

  <ekon-icon [icon]="ekIconChevronDown" size="xs" class="menu-icon"></ekon-icon>
</div>

<!--<div class="username mr-12" fxHide fxShow.gt-sm>
  <ng-container
    *ngIf="getUserFullName(userProfile) as userFullName; else userName">{{userFullName}}</ng-container>
  <ng-template #userName>{{userProfile.userName}}</ng-template>
</div>
<fa-icon class="mr-8" [icon]="['fas', 'chevron-down']"></fa-icon>-->

<mat-menu #userMenu="matMenu" [overlapTrigger]="false">

  <button
    *ngIf="userData | async as user"
    [routerLink]="['/users', user.id]"
    mat-menu-item
  >
    <ekon-icon icon="user" size="lg" class="mr-8"></ekon-icon>
    <span>{{'MY_PROFILE' | translate}}</span>
  </button>

  <button
    mat-menu-item
    routerLink="/admin"
    *ngIf="permissionsService.hasPermission('SYSTEM', 'config') | async"
  >
    <ekon-icon icon="admin-panel" size="lg" class="mr-8"></ekon-icon>
    <span>{{'ADMIN_PANEL'| translate}}</span>
  </button>
  <button *ngIf="!pwa.isStandalone"  mat-menu-item (click)="installApp()">
    <ekon-icon [icon]="ekIconDownload" size="lg" class="mr-8"></ekon-icon>
    <span>{{'INSTALL_APP'| translate}}</span>
  </button>
  <button mat-menu-item (click)="checkUpdates()">
    <ekon-icon [icon]="ekIconRedo" size="lg" class="mr-8"></ekon-icon>
    <span>{{'CHECK_UPDATES'| translate}}</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <ekon-icon icon="logout" size="lg" class="mr-8"></ekon-icon>
    <span>{{'LOGOUT'| translate}}</span>
  </button>

  <div mat-menu-item (click)="$event.stopPropagation();">
    <dkm-layout-theme-setter
      class="toolbar-item theme-setter"
    ></dkm-layout-theme-setter>
  </div>
</mat-menu>
