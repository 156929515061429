/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BoxplotValue { 
    name?: string | null;
    /**
     * Min value
     */
    min?: number;
    /**
     * First Quartile
     */
    q1?: number;
    /**
     * The Median
     */
    median?: number;
    /**
     * Third Quartile
     */
    q3?: number;
    /**
     * Max value
     */
    max?: number;
    /**
     * Use color picker
     */
    color?: string | null;
    /**
     * Future usage, get json data from external Url source, for single BoxPlots   Json data should be compatible to D3 Graph
     */
    dataSetSourceUrl?: string | null;
}

