import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  forwardRef,
  Provider,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { UserHeaderModel } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

import { AutocompleteService } from '../../services';

const CUSTOM_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AttendeesChipsAutocompleteComponent),
  multi: true,
};

@Component({
  selector: 'dkm-attendees-chips-autocomplete',
  templateUrl: './attendees-chips-autocomplete.component.html',
  styles: [''],
  providers: [CUSTOM_VALUE_ACCESSOR],
})
export class AttendeesChipsAutocompleteComponent
  implements ControlValueAccessor {
  attendees: UserHeaderModel[] = [];
  disabled: boolean;

  attendeeInputControl = new FormControl();
  filteredAttendees: Observable<UserHeaderModel[]>;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('attendeeInput') attendeeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autocmplAttendees') matAutocomplete: MatAutocomplete;

  onChange: (x?: unknown) => void;
  onTouched: (x?: unknown) => void;

  constructor(private autocomleteService: AutocompleteService) {
    this.filteredAttendees = autocomleteService.autocompleteAttendees(
      this.attendeeInputControl
    );
  }

  /* ControlValueAccessor implementation */

  registerOnChange(fn: (x?: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (x?: unknown) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: UserHeaderModel[]): void {
    this.attendees = obj?.filter(a => Boolean(a)) || [];
  }

  /* Control logic */

  addAttendee(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.chipInput.inputElement;

      // TODO: uncomment when ability to add extra emails is ready
      // const value = event.value;
      // if (value.trim().length) {
      //   this.attendees.push({
      //     email: value.trim()
      //   });
      // }

      input.value = '';

      if (this.onChange) {
        this.onChange(this.attendees.length ? this.attendees : []);
      }
    }
  }

  removeAttendee(attendee: UserHeaderModel): void {
    const index = this.attendees.indexOf(attendee);

    this.attendees.splice(index, 1);

    if (this.onChange) {
      this.onChange(this.attendees.length ? this.attendees : []);
    }
  }

  autoCompleteAttendeeSelected($event: MatAutocompleteSelectedEvent): void {
    // TODO: fix this case later
    this.attendees.push({
      ...$event.option.value,
      id: 'be233b2b-446c-4e8b-8e88-845e249aa27e',
    });
    this.attendeeInput.nativeElement.value = '';
  }
}
