import { InjectionToken } from '@angular/core';
import { Element } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const ELEMENT_TEMPLATES_ACTIONS = new InjectionToken(
  'ElementTemplates model actions'
);
export const ELEMENT_TEMPLATES_EVENTS = new InjectionToken(
  'ElementTemplates model events'
);

export interface ElementTemplatesEventsServiceInterface {
  elementTemplateCreated: Observable<Element>;
  elementTemplateDeleted: Observable<string>;
  elementTemplateUpdated: Observable<string>;
}
