import { InjectionToken } from '@angular/core';
import { LegalEntityDetails } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const LEGAL_ENTITY_ACTIONS = new InjectionToken(
  'Legal Entity model actions'
);
export const LEGAL_ENTITY_EVENTS = new InjectionToken(
  'Legal Entity model events'
);

export interface LegalEntityEventsServiceInterface {
  readonly entityCreated: Observable<LegalEntityDetails>;
  readonly entityDeleted: Observable<string>;
  readonly entityUpdated: Observable<string>;
}
