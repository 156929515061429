import { ChangeDetectionStrategy,Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EkonAuthService } from '@ekon-client/auth';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'place4u-client-oidc-redirect',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthRedirectComponent {

  constructor(
    router: Router,
    auth: EkonAuthService,
    route: ActivatedRoute
  ) {
    // todo: just a workaround
    //  get rid of after eis is configured to accept root route
    if (route.snapshot.queryParams['error']) {
      return;
    }

    // todo: just a workaround
    //  get rid of after eis is configured to accept root route
    auth.isLoggedIn$.pipe(
      filter(r => r),
      take(1)
    ).subscribe({
      next: () => router.navigate([''])
    })
  }

}
