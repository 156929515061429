/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { HttpValidationProblemDetails } from '../model/models';
import { PageRateDistributionResult } from '../model/models';
import { ProblemDetails } from '../model/models';
import { TagsWordCloudResults } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface StatisticsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Get page\&#39;s distribution by pages avearge rating.
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    pagesRateDistribution(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageRateDistributionResult>;

    /**
     * Get Tags grouped in WordCloud
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    pagesTagsWordCloud(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<TagsWordCloudResults>;

}
