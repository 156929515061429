<dkm-calendar-item-list-item
  *ngFor="let task of tasks; trackBy: trackByFn"
  [item]="task"
  [viewOnly]="viewOnly"
  [minimized]="minimized"
  [selectMode]="selectMode"
  (edit)="editTask($event)"
  (view)="viewTask($event)"
  (select)="selectTask($event)"
  (delete)="deleteTask($event)"
  (updateProperty)="updateTaskProperty($event)"
></dkm-calendar-item-list-item>
