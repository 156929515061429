<div class="mode">
  <div class="mode--view">
    <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
      <ekon-icon
        [ngClass]="{ 'warn-fg': userViewEnabled, 'accent-fg': !userViewEnabled }"
        [icon]="(rate$ | async) >= star
          ? ekIconStarFull
          : ((rate$ | async) < star && (rate$ | async) > star - 1
            ? ekIconStarHalf
            : ekIconStarEmpty
          )"
        (click)="setRate(star)"
        [size]="size === 'small' ? 'xs' : null"
      ></ekon-icon>
    </ng-container>
  </div>

  <div class="mode--edit" *ngIf="editEnabled">
  <span
    class="cursor-pointer"
    *ngFor="let star of [1, 2, 3, 4, 5]"
    (mouseenter)="setSelection(star)"
    (mouseleave)="clearSelection()"
    [ngClass]="{ 'highlighted': star <= selection }"
  >
    <ekon-icon
      class="warn-fg star-empty"
      [icon]="ekIconStarEmpty"
      (click)="setRate(star)"
      [size]="size === 'small' ? 'xs' : null"
    ></ekon-icon>
    <ekon-icon
      class="warn-fg star-full"
      [icon]="ekIconStarFull"
      (click)="setRate(star)"
      [size]="size === 'small' ? 'xs' : null"
    ></ekon-icon>
  </span>
  </div>
</div>

<span
  class="text-boxed cursor-pointer"
  *ngIf="displayValue"
  matRipple
  (click)="toggleViewMode()"
>{{rate$ | async | number: '1.1-1'}}</span>
