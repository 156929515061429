
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CalendarItem, TaskAction,TaskStatus } from '@ekon-client/dkm-api';
import { isEqual as _isEqual } from 'lodash-es';

export interface UpdateProperty {
  id: string;
  property: Partial<CalendarItem>;
  task: CalendarItem;
}

@Component({
  selector: 'dkm-calendar-item-list-item',
  templateUrl: './calendar-item-list-item.component.html',
  styleUrls: ['./calendar-item-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarItemListItemComponent implements OnChanges {
  TaskStatus = TaskStatus;

  @Input() item: CalendarItem;
  @Input() viewOnly: boolean;
  @Input() minimized: boolean;
  @Input() selectMode: boolean;
  @Input() standalone: boolean;

  @Output() view: EventEmitter<string> = new EventEmitter<string>();
  @Output() select: EventEmitter<CalendarItem> = new EventEmitter<CalendarItem>();
  @Output() edit: EventEmitter<string> = new EventEmitter<string>();
  @Output() delete: EventEmitter<CalendarItem> = new EventEmitter<CalendarItem>();
  @Output() updateProperty: EventEmitter<UpdateProperty> = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  @HostBinding('style.borderInlineStartColor')
  get primaryColor() {
    return this.item.primaryColor;
  }

  @ViewChild('taskContent', { read: MatRipple, static: true })
  ripple: MatRipple;

  constructor(
    public sanitizer: DomSanitizer
  ) {}

  editTask(): void {
    this.edit.emit(this.item.id);
  }

  viewTask(): void {
    this.view.emit(this.item.id);
  }

  selectTask(): void {
    this.select.emit(this.item);
  }

  deleteTask(): void {
    this.delete.emit(this.item);
  }

  updateTaskProperty(task: CalendarItem, property: string, value: unknown): void {
    this.updateProperty.emit({
      id: this.item.id,
      property: {
        [property]: value,
      },
      task
    });
  }

  isCompletedTask() {
      return this.itemAsTask()?.status === TaskStatus.Completed;
  }

  itemAsTask(): TaskAction {
    if (this.item?.kind === "TaskAction") {
      return this.item as TaskAction;
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.item.currentValue &&
      !changes.item.firstChange &&
      !_isEqual(changes.item.currentValue, changes.item.previousValue)
    ) {
      this.ripple.launch({ animation: { exitDuration: 2500 } });
    }
  }
}
