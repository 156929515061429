/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BasketDetails } from '../model/models';
import { BasketHeaderPagedResult } from '../model/models';
import { BasketHeaderWithProductCountPagedResult } from '../model/models';
import { CreateBasket } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';
import { ProductsGoodsDetails } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    BasketServiceInterface
} from './basket.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class BasketService implements BasketServiceInterface {

    protected basePath = 'http://dkm-pl.ekonm.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param productId 
     * @param productsGoodsDetails 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProductToBasket(id: string, productId: string, productsGoodsDetails?: ProductsGoodsDetails, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public addProductToBasket(id: string, productId: string, productsGoodsDetails?: ProductsGoodsDetails, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public addProductToBasket(id: string, productId: string, productsGoodsDetails?: ProductsGoodsDetails, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public addProductToBasket(id: string, productId: string, productsGoodsDetails?: ProductsGoodsDetails, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling addProductToBasket.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling addProductToBasket.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/protected/v1/basket/${encodeURIComponent(String(id))}/add/product/${encodeURIComponent(String(productId))}`,
            productsGoodsDetails,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param createBasket 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBasket(createBasket?: CreateBasket, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<BasketDetails>;
    public createBasket(createBasket?: CreateBasket, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<BasketDetails>>;
    public createBasket(createBasket?: CreateBasket, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<BasketDetails>>;
    public createBasket(createBasket?: CreateBasket, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BasketDetails>(`${this.configuration.basePath}/api/protected/v1/basket`,
            createBasket,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBasket(id: string, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public deleteBasket(id: string, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public deleteBasket(id: string, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public deleteBasket(id: string, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteBasket.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/protected/v1/basket/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Provide human readable Basket document
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downladBasketDocument(id: string, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public downladBasketDocument(id: string, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public downladBasketDocument(id: string, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public downladBasketDocument(id: string, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downladBasketDocument.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/protected/v1/basket/document/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Duplicate a basket.  Set the duplicated basket as open basket.
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public duplicateBasket(id: string, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<BasketDetails>;
    public duplicateBasket(id: string, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<BasketDetails>>;
    public duplicateBasket(id: string, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<BasketDetails>>;
    public duplicateBasket(id: string, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling duplicateBasket.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BasketDetails>(`${this.configuration.basePath}/api/protected/v1/basket/duplicate/${encodeURIComponent(String(id))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findBasketById(id: string, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<BasketDetails>;
    public findBasketById(id: string, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<BasketDetails>>;
    public findBasketById(id: string, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<BasketDetails>>;
    public findBasketById(id: string, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling findBasketById.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BasketDetails>(`${this.configuration.basePath}/api/protected/v1/basket/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param pagePaginationModel 
     * @param isOpen 
     * @param legalEntityId 
     * @param from 
     * @param to 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listBaskets(pagePaginationModel?: PagePaginationModel, isOpen?: boolean, legalEntityId?: string, from?: Date, to?: Date, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<BasketHeaderPagedResult>;
    public listBaskets(pagePaginationModel?: PagePaginationModel, isOpen?: boolean, legalEntityId?: string, from?: Date, to?: Date, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<BasketHeaderPagedResult>>;
    public listBaskets(pagePaginationModel?: PagePaginationModel, isOpen?: boolean, legalEntityId?: string, from?: Date, to?: Date, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<BasketHeaderPagedResult>>;
    public listBaskets(pagePaginationModel?: PagePaginationModel, isOpen?: boolean, legalEntityId?: string, from?: Date, to?: Date, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (isOpen !== undefined && isOpen !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isOpen, 'isOpen');
        }
        if (legalEntityId !== undefined && legalEntityId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>legalEntityId, 'legalEntityId');
        }
        if (from !== undefined && from !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>to, 'to');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BasketHeaderPagedResult>(`${this.configuration.basePath}/api/protected/v1/basket/baskets`,
            pagePaginationModel,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param pagePaginationModel 
     * @param isOpen 
     * @param legalEntityId 
     * @param productPageId 
     * @param from 
     * @param to 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listBasketsWithProductPageCount(pagePaginationModel?: PagePaginationModel, isOpen?: boolean, legalEntityId?: string, productPageId?: string, from?: Date, to?: Date, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<BasketHeaderWithProductCountPagedResult>;
    public listBasketsWithProductPageCount(pagePaginationModel?: PagePaginationModel, isOpen?: boolean, legalEntityId?: string, productPageId?: string, from?: Date, to?: Date, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<BasketHeaderWithProductCountPagedResult>>;
    public listBasketsWithProductPageCount(pagePaginationModel?: PagePaginationModel, isOpen?: boolean, legalEntityId?: string, productPageId?: string, from?: Date, to?: Date, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<BasketHeaderWithProductCountPagedResult>>;
    public listBasketsWithProductPageCount(pagePaginationModel?: PagePaginationModel, isOpen?: boolean, legalEntityId?: string, productPageId?: string, from?: Date, to?: Date, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (isOpen !== undefined && isOpen !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isOpen, 'isOpen');
        }
        if (legalEntityId !== undefined && legalEntityId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>legalEntityId, 'legalEntityId');
        }
        if (productPageId !== undefined && productPageId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>productPageId, 'productPageId');
        }
        if (from !== undefined && from !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>from, 'from');
        }
        if (to !== undefined && to !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>to, 'to');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BasketHeaderWithProductCountPagedResult>(`${this.configuration.basePath}/api/protected/v1/basket/baskets/with/productpage`,
            pagePaginationModel,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Submit and close basket.  Send request notification to suppliers
     * @param id 
     * @param shallSubmit 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public submitAndCloseBasket(id: string, shallSubmit?: boolean, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public submitAndCloseBasket(id: string, shallSubmit?: boolean, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public submitAndCloseBasket(id: string, shallSubmit?: boolean, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public submitAndCloseBasket(id: string, shallSubmit?: boolean, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling submitAndCloseBasket.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (shallSubmit !== undefined && shallSubmit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>shallSubmit, 'shallSubmit');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/protected/v1/basket/submit/${encodeURIComponent(String(id))}`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param basketDetails 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBasket(id: string, basketDetails?: BasketDetails, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public updateBasket(id: string, basketDetails?: BasketDetails, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public updateBasket(id: string, basketDetails?: BasketDetails, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public updateBasket(id: string, basketDetails?: BasketDetails, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateBasket.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/protected/v1/basket/${encodeURIComponent(String(id))}`,
            basketDetails,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param productId 
     * @param quantity 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBasketItemQuantity(id: string, productId: string, quantity: number, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public updateBasketItemQuantity(id: string, productId: string, quantity: number, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public updateBasketItemQuantity(id: string, productId: string, quantity: number, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public updateBasketItemQuantity(id: string, productId: string, quantity: number, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateBasketItemQuantity.');
        }
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling updateBasketItemQuantity.');
        }
        if (quantity === null || quantity === undefined) {
            throw new Error('Required parameter quantity was null or undefined when calling updateBasketItemQuantity.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/protected/v1/basket/${encodeURIComponent(String(id))}/update/product/${encodeURIComponent(String(productId))}/quantity/${encodeURIComponent(String(quantity))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
