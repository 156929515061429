import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import { EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { BlockedItemComponent } from './components/blocked-item/blocked-item.component';

@NgModule({
  imports: [
    CommonModule,
    EkonDialogsModule,
    MatCardModule,
    EkonIconsModule,
    TranslateModule,
    MatButtonModule,
    // PageCardModule
  ],
  declarations: [
    BlockedItemComponent
  ],
  exports: [
    BlockedItemComponent
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/ekon-block-n-report/',
      multi: true
    }
  ]
})
export class EkonBlockNReportModule {}
