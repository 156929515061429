/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RefType } from './refType';


/**
 * Users rating resonse for a given entity
 */
export interface RateDetails { 
    /**
     * Response Id
     */
    id?: string;
    referrerId: string;
    /**
     * User rating - 1-5 stars
     */
    rate?: number;
    usageRefType?: RefType;
    lastUpdateAt?: Date;
    publishedAt?: Date | null;
    publishedBy?: string | null;
}

