import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, InjectionToken, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { GlobalConfiguration } from './classes';
import { AppGlobalConfigurationInitService, loadAppGlobalConfigurationInitService } from './global-configuration/app-global-configuration-init.service';

export const FOR_ROOT_APP_INIT_OPTIONS_TOKEN = new InjectionToken<Partial<GlobalConfiguration>>("forRoot() App Init options configuration.");

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
})
export class CloudConfigModule {
  constructor(@Optional() @SkipSelf() parentModule: CloudConfigModule) {
    if (parentModule) {
      throw new Error(
        'CloudConfigModule is already loaded. Import it in the AppModule only!'
      );
    }
  }

  public static forRoot(environment: unknown | Partial<GlobalConfiguration>
  ): ModuleWithProviders<CloudConfigModule> {
    return {
      ngModule: CloudConfigModule,
      providers: [
        {
          provide: FOR_ROOT_APP_INIT_OPTIONS_TOKEN,
          useValue: environment
        },
        AppGlobalConfigurationInitService,
        {
          provide: APP_INITIALIZER,
          useFactory: loadAppGlobalConfigurationInitService,
          deps: [AppGlobalConfigurationInitService, FOR_ROOT_APP_INIT_OPTIONS_TOKEN],
          multi: true
        }
      ],
    };
  }
}
