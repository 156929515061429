/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserHeaderModel } from './userHeaderModel';
import { FileMetadata } from './fileMetadata';
import { UserPostModel } from './userPostModel';
import { UserPostReaction } from './userPostReaction';


export interface UserPostFilesModel extends UserPostModel { 
    /**
     * Allow users to upload/ref files
     */
    attachement?: Array<FileMetadata> | null;
}

