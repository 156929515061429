import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ekon-tags',
  template: `
    <mat-chip-list aria-label="tags" [ngClass]="{ 'dkm-chips': minimal }">
      <mat-chip
        *ngFor="let tag of tags"
        (click)="searchByTag(tag)"
      >{{tag}}</mat-chip>
    </mat-chip-list>
  `,
  styleUrls: ['./tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsComponent {

  @Input() tags: string[] | undefined;
  @Input() minimal: boolean | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }


  searchByTag(tag: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { 'tags': tag },
      queryParamsHandling: 'merge'
    });
  }
}
