import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum EkonMessageTypes {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root',
})
export class EkonMessageService {
  constructor(private snackBar: MatSnackBar) {}

  show(message: string, type?: EkonMessageTypes): string {
    this.snackBar.open(message, undefined, {
      duration: 4000,
      panelClass: ['message-box', !type ? EkonMessageTypes.INFO : type],
    });

    return message;
  }
}
