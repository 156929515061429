<mat-card fxLayout="column">

  <dkm-core-ava-viewer
    fxFlex="0 0 auto"
    [noShape]="true"
    [fileLink]="page.thumbprintUrl"
    (imageClick)="(disableAllActions && page.read) ? selectPage() : viewPage()"
  >
    <ekon-icon [icon]="page.isTemplate ? 'template' : (isProduct ? ekIconBox : ekIconNewspaper)" size="6x"></ekon-icon>
  </dkm-core-ava-viewer>


  <h3 fxFlex="0 0 auto"><a (click)="(disableAllActions && page.read) ? selectPage() : viewPage()">{{page.name}}</a></h3>

  <div class="fadeout" fxFlex="0 0 auto">{{page.shortDescription}}</div>

  <ekon-tags
    *ngIf="page.tags && page.tags.length"
    [tags]="page.tags"
    [minimal]="true"
    class="card-tags fadeout"
    fxFlex="1 1 100%"
  ></ekon-tags>

  <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="space-between flex-end">
    <div>
      <ekon-rating
        [rate]="page.averageRate"
        [refId]="page.id"
        [displayValue]="true"
      ></ekon-rating>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        mat-icon-button
        aria-label="Derive"
        *ngIf="!disableAllActions && deriveEnabled && page.create"
        (click)="derivePage()"
        matTooltip="Create a page from this template"
      >
        <ekon-icon icon="clone"></ekon-icon>
      </button>

      <button
        mat-icon-button
        *ngIf="!disableAllActions && cloneEnabled && page.create"
        aria-label="Make copy"
        (click)="duplicatePage()"
        matTooltip="Clone"
      >
        <ekon-icon icon="clone"></ekon-icon>
      </button>

      <!--<button
        mat-icon-button
        *ngIf="!disableAllActions && page.read"
        aria-label="Share"
        matTooltip="Share"
      >
        <ekon-icon icon="share-outline"></ekon-icon>
      </button>-->

      <!--<button
        mat-icon-button
        *ngIf="!disableAllActions && page.read"
        aria-label="view"
        (click)="viewPage()"
        matTooltip="View"
      >
        <ekon-icon icon="glasses"></ekon-icon>
      </button>-->

      <button
        mat-icon-button
        *ngIf="!disableAllActions && deleteEnabled && page.remove"
        color="warn"
        aria-label="delete"
        (click)="deletePage()"
        matTooltip="Delete"
      >
        <ekon-icon icon="trash-alt"></ekon-icon>
      </button>
    </div>
  </div>

  <dkm-basket-add-item
    *ngIf="!page.isTemplate && isProduct && globalConfig.useBasket && globalConfig.enableLegalEntity"
    [data]="page"
    [labelEnabled]="true"
    class="mt-8"
  ></dkm-basket-add-item>
</mat-card>
