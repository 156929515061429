import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileCacheService {
  private imageCache: Map<string, Observable<string>> = new Map();

  cacheFile(id: string, value: Observable<string>): Observable<string> {
    const shared = value.pipe(shareReplay({ bufferSize: 1, refCount: true }));
    this.imageCache.set(id, shared);
    return shared;
  }

  getCachedFile(id: string): Observable<string> {
    return this.imageCache.get(id);
  }
}
