import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  KbeRequirements,
  KbeRequirementsModel,
  KbeService,
  KbeServiceInterface,
  //WGVendorInfo,
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { KbeRequirementsEventsServiceInterface } from './KbeSpecificationEventsServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class KbeSpecificationEventsService
  implements KbeServiceInterface, KbeRequirementsEventsServiceInterface {
  get configuration(): Configuration {
    return this.kbeService.configuration;
  }

  set configuration(val: Configuration) {
    this.kbeService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.kbeService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.kbeService.defaultHeaders = val;
  }

  /**
   * The mechanism for monitoring Updated action
   *
   */
  private kbeSpecificationUpdatedSubject: Subject<string> = new Subject();
  get kbeSpecificationUpdated(): Observable<string> {
    return this.kbeSpecificationUpdatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Delete action
   */
  private kbeSpecificationDeletedSubject: Subject<string> = new Subject();
  get kbeSpecificationDeleted(): Observable<string> {
    return this.kbeSpecificationDeletedSubject.asObservable();
  }

  constructor(
    private kbeService: KbeService,
    private progressBar: EkonProgressBarService
  ) { }

  /**
   * Note: Kbe specification is created by KBE-Tool, via server-side api
   *
   * @param kbeSpecification
   */
  createKbeRequirements(
    kbeSpecification?: KbeRequirements
  ): Observable<KbeRequirementsModel> {
    return this.progressBar.apply(
      this.kbeService.createKbeRequirements(kbeSpecification)
    );
  }

  deleteKbeRequirements(id: string): Observable<KbeRequirementsModel> {
    return this.progressBar
      .apply(this.kbeService.deleteKbeRequirements(id))
      .pipe(tap(() => this.kbeSpecificationDeletedSubject.next(id)));
  }

  findKbeRequirementsById(id: string): Observable<KbeRequirementsModel> {
    return this.progressBar.apply(this.kbeService.findKbeRequirementsById(id));
  }

  listKbeRequirements(
  ): Observable<KbeRequirementsModel[]> {
    return this.progressBar.apply(
      this.kbeService.listKbeRequirements()
    );
  }

  updateKbeRequirements(
    id: string,
    kbeSpecificationDetails: KbeRequirementsModel
  ): Observable<unknown> {
    return this.progressBar
      .apply(
        this.kbeService.updateKbeRequirements(id, kbeSpecificationDetails)
      )
      .pipe(tap(() => this.kbeSpecificationUpdatedSubject.next(id)));
  }

  updateTenderInitiation(/*_id: string*/): Observable<unknown> {
    return undefined; /*this.progressBar.apply(this.kbeService.updateTenderInitiation(id));*/
  }

  downladKbeRequirementsDocument(id: string): Observable<unknown> {
    return this.progressBar.apply(
      this.kbeService.downladKbeRequirementsDocument(id)
    );
  }

  getVendorsForSpec(/*_id: string*/): Observable<unknown> {
    return undefined; /*this.progressBar.apply(this.kbeService.getVendorsForSpec(id));*/
  }








}
