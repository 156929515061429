<div
  class="image-wrapper"
  [matTooltip]="
      fileMeta?.fileName + ' by ' + (fileAuthor | userName)
      + (!fileMeta?.shortDescription ? '' : '\n\n' + fileMeta?.shortDescription)
    "
  [matTooltipDisabled]="hideCredentials"
  (click)="emitWrapperClick($event)"
>
  <ng-container *ngIf="fileBodyURI$ | async as fileBodyURI; else errorTpl">
    <img
      [src]="fileBodyURI"
      [ekonImageFullscreen]
      [fileMeta]="fileMeta"
      [fileAuthor]="fileAuthor"
      [ekonImageFullscreenEnabled]="enableChildPreview"
    />
  </ng-container>

  <ng-template #errorTpl>
    <div *ngIf="loadingError$ | async" class="error-case">
      <ekon-icon
        icon="exclamation-triangle"
        size="3x"
        class="error-case-icon"
      ></ekon-icon>
      <div class="error-case-message">Error occurred while loading image</div>
    </div>
  </ng-template>
</div>
