<div *ngIf="pwa.updatesReady$ | async" class="updates-ready ekon-bg">
  <span>A new version is released</span>
  <button
    mat-raised-button
    color="primary"
    class="ml-24"
    (click)="applyUpdates()"
  >Update</button>
</div>

<mat-toolbar
  class="mat-elevation-z1"
>
  <div
    tourAnchor="main.toolbar.logo"
    class="logo"
    (click)="backToLanding()"
    [ngStyle]="{ 'background': 'url(' + globalConfig.logoURL + ') no-repeat center / contain content-box' }"
  ></div>

  <div class="main-area toolbar-item">
    <ng-container *ngIf="!(isMobile$ | async) && !(user | async)">
      <div class="top-nav">
        <a
          class="top-nav-item"
          routerLink="/"
        >
          <ekon-icon icon="home"></ekon-icon>
          <span>{{'HOME' | translate}}</span>
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="!(isMobile$ | async) && user | async">
      <ekon-search-n-filter
        tourAnchor="main.toolbar.spaces_select"
        *ngIf="permissions.hasPermission('DOMAINS', 'read') | async"
        class="search-n-filter"
      ></ekon-search-n-filter>

      <div class="top-nav">
        <a
          tourAnchor="main.toolbar.home"
          *ngIf="permissions.hasPermission('DASHBOARD', 'read') | async"
          class="top-nav-item"
          routerLink="/"
          [routerLinkActive]="['active']"
          matRipple
          (click)="notifier.notify('NAV.HOME')"
        >
          <ekon-icon icon="home"></ekon-icon>
          <span>{{'HOME' | translate}}</span>
        </a>
        <a
          tourAnchor="main.toolbar.feed"
          *ngIf="globalConfig.enableFeed
            && !globalConfig.useAltHomeDashboard
            && permissions.hasPermission('FEED', 'read') | async"
          class="top-nav-item"
          routerLink="/feed"
          [routerLinkActive]="['active']"
          matRipple
          [matRippleDisabled]="true"
        >
          <ekon-icon icon="newspaper"></ekon-icon>
          <span>{{'FEED' | translate}}</span>
        </a>
        <a
          tourAnchor="main.toolbar.domains"
          *ngIf="permissions.hasPermission('DOMAINS', 'read') | async"
          class="top-nav-item"
          routerLink="/domains"
          [routerLinkActive]="['active']"
          matRipple
        >
          <ekon-icon icon="stores"></ekon-icon>
          <span>{{globalConfig.domainTitlePlural | uppercase | translate}}</span>
        </a>
        <a
          *ngIf="(globalConfig.useBasket$ | async)
            && permissions.hasPermission('PRODUCTS', 'read') | async"
          class="top-nav-item"
          routerLink="/products"
          [routerLinkActive]="['active']"
          matRipple
        >
          <ekon-icon icon="catalog"></ekon-icon>
          <span>{{'CATALOG' | translate}}</span>
        </a>
        <a
          tourAnchor="main.toolbar.pages"
          *ngIf="permissions.hasPermission('PAGES', 'read') | async"
          class="top-nav-item"
          routerLink="/pages"
          [routerLinkActive]="['active']"
          matRipple
        >
          <ekon-icon icon="pages"></ekon-icon>
          <span>{{'PAGES_LABEL' | translate}}</span>
        </a>
        <a
          *ngIf="globalConfig.enableBlogs && permissions.hasPermission('BLOGS', 'read') | async"
          class="top-nav-item"
          routerLink="/blogs"
          [routerLinkActive]="['active']"
          matRipple
        >
          <ekon-icon icon="blogs" [directionSensitive]="false"></ekon-icon>
          <span>{{'BLOGS' | translate}}</span>
        </a>
        <a
          *ngIf="(permissions.hasPermission('MY_SPACE', 'read') | async)
            && permissions.hasPermission('DOMAINS', 'read') | async"
          class="top-nav-item"
          routerLink="/my-space"
          [routerLinkActive]="['active']"
          matRipple
          tourAnchor="main.toolbar.my-space"
        >
          <ekon-icon icon="my-space"></ekon-icon>
          <span>{{'MY_SPACE' | translate}}</span>
        </a>
      </div>
    </ng-container>
  </div>

  <!--<mat-slide-toggle
    class="toolbar-item"
    (change)="toggleTheme($event)"
  ></mat-slide-toggle>-->

  <button
    mat-button
    (click)="logout()"
    *ngIf="(authService.isLoggedIn$ | async) && !(user | async)"
  >
    <ekon-icon
      class="mr-8"
      icon="logout"
    ></ekon-icon>
    <span>Logout</span>
  </button>

  <ekon-client-tour-launch *ngIf="user | async"></ekon-client-tour-launch>

  <dkm-profile-menu
    *ngIf="user | async"
    class="toolbar-item profile-menu"
    tourAnchor="main.toolbar.profile-menu"
  ></dkm-profile-menu>

  <ekon-language-setter
    *ngIf="!(isMobile$ | async)"
    class="toolbar-item"
  ></ekon-language-setter>

  <button
    mat-button
    class="toolbar-item baskets"
    *ngIf="(user | async) && useBasket && enableLegalEntity
     && permissions.hasPermission('BASKETS', 'read') | async"
    routerLink="/baskets"
  >
    <ekon-icon icon="shopping-cart"></ekon-icon>
  </button>


  <button
    *ngIf="(isMobile$ | async) && user | async"
    mat-button
    class="toolbar-item"
    (click)="toggleSidebarStatus('mobileNav')"
  >
    <ekon-icon icon="bars" size="lg"></ekon-icon>
  </button>

</mat-toolbar>

<div
  class="proj-colors"
></div>

<!-- PROGRESS BAR -->
<ekon-progress-bar
  tourAnchor="ekon.progress.bar"
  [ngStyle]="{ 'background': globalConfig.dashboardGreetingBackground }"
></ekon-progress-bar>
<!-- / PROGRESS BAR -->
