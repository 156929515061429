<ekon-dialog-wrapper>
  <form [formGroup]="form" (ngSubmit)="validateAndSubmit()" fxLayout="column" class="h-100-p">
    <div mat-dialog-title class="h2 mb-24">Send Report</div>
    <mat-dialog-content fxLayout="column">

      <mat-form-field appearance="outline" fxFlex="0 0 auto">
        <mat-label>Reason</mat-label>
        <mat-select formControlName="title">
          <mat-option
            *ngFor="let reason of reasons"
            [value]="reason.title"
            (onSelectionChange)="setSummaries(reason.summary)"
          >{{reason.title}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="0 0 auto">
        <mat-label>Summary</mat-label>
        <mat-select formControlName="summary">
          <mat-option
            *ngFor="let summary of currentSummaries"
            [value]="summary"
          >{{summary}}</mat-option>
        </mat-select>
      </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button type="submit" [disabled]="!form.pristine && !form.valid">Send</button>
    </mat-dialog-actions>
  </form>
</ekon-dialog-wrapper>
