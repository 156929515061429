import { ChangeDetectionStrategy, Component, EventEmitter,Input, Output } from '@angular/core';
import { VisibilityTypes } from '@ekon-client/dkm-api';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';

@Component({
  selector: 'ekon-client-custom-entity-title',
  templateUrl: './custom-entity-title.component.html',
  styleUrls: ['./custom-entity-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomEntityTitleComponent {
  VisibilityTypes = VisibilityTypes;

  @Input() title: string;
  @Input() isTemplate: boolean;
  @Input() canUpdate: boolean;
  @Input() visibleTo: VisibilityTypes;

  @Output() setSharingOptions: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public globalConfig: GlobalConfigurationService
  ) {}
}
