import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, SimpleChanges } from '@angular/core';
import {
  BaseType,
  ScaleBand,
  scaleBand as d3ScaleBand,
  ScaleLinear,
  scaleLinear as d3ScaleLinear,
  select as d3Select} from 'd3';
import { axisBottom as d3AxisBottom, axisRight as d3AxisRight } from 'd3-axis';
import { isNil as _isNil } from 'lodash-es';
import ResizeObserver from 'resize-observer-polyfill';

import { SelectionSVG, SelectionSVGG } from '../../extras/SelectionTypes';

export enum BoxplotGraphType {
  Traditional = 'Traditional',
  VariableWidth = 'VariableWidth',
  Notched = 'Notched',
  ViolinPlot = 'ViolinPlot',
  VasePlot = 'VasePlot',
  BeanPlot = 'BeanPlot'
}

export interface BoxplotValue {
  name?: string | null;
  min?: number;
  q1?: number;
  median?: number;
  q3?: number;
  max?: number;
  color?: string | null;
  dataSetSourceUrl?: string | null;
}


@Component({
  selector: 'ekon-boxplot-graph',
  template: '',
  styles: [
      `
      :host {
        display: block;
        width: 700px;
        height: 450px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxplotGraphComponent implements AfterViewInit {
  @Input() data: BoxplotValue[];
  @Input() ignoreZeros: boolean;
  @Input() type: BoxplotGraphType;

  private margin = { top: 20, right: 0, bottom: 30, left: 0 };
  private svg: SelectionSVG;
  private container: SelectionSVGG;

  hostEl: Element;
  private barsContainer: SelectionSVGG;
  private rangesContainer: SelectionSVGG;
  private mediansContainer: SelectionSVGG;
  private textContainer: SelectionSVGG;
  private xAxis: SelectionSVGG;
  private yAxis: SelectionSVGG;

  resizeObserver: ResizeObserver;

  constructor(private hostElRef: ElementRef<Element>) {
    this.hostEl = this.hostElRef.nativeElement;
  }

  xScaleGen(data: string[], width: number): ScaleBand<string> {
    return d3ScaleBand()
      .domain(data)
      .range([0, width])
      .paddingInner(0.2)
      .paddingOuter(0.5);
  }


  yScaleGen(domainMin: number, domainMax: number, rangeMax: number, rangeMin = 0): ScaleLinear<number, number> {
    const domainPadding = 10 * domainMax / 100;
    return d3ScaleLinear()
      .domain([domainMin - domainPadding, domainMax + domainPadding])
      .range([rangeMax, rangeMin]);
  }

  ngAfterViewInit(): void {
    this.svg = d3Select(this.hostEl)
      .append('svg');

    this.container = this.svg
      .append('g');

    // X axis group
    this.xAxis = this.container
      .append('g');
    // Y axis group
    this.yAxis = this.container
      .append('g');
    // ranges group
    this.rangesContainer = this.container
      .append('g');
    // bars group
    this.barsContainer = this.container
      .append('g');
    // medians group
    this.mediansContainer = this.container
      .append('g');
    // text group
    this.textContainer = this.container
      .append('g');

    this.data && this.drawGraph(
      this.type,
      this.data,
      this.hostEl.clientWidth,
      this.hostEl.clientHeight
    );

    //  .selectAll("*").interrupt()

    let timeout;

    this.resizeObserver = new ResizeObserver(
      ([{ contentRect: bounds }]: ResizeObserverEntry[]) => {
        timeout && clearTimeout(timeout);

        timeout = setTimeout(() => {
          this.data && this.drawGraph(
            this.type,
            this.data,
            bounds.width,
            bounds.height
          );
        }, 100);
      }
    );

    this.resizeObserver.observe(this.hostEl);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && !changes.data.firstChange && changes.data.currentValue) {
      this.drawGraph(
        this.type,
        changes.data.currentValue,
        this.hostEl.clientWidth,
        this.hostEl.clientHeight
      );
    }
    if (
      changes.ignoreZeros
      && !changes.ignoreZeros.firstChange
      && changes.ignoreZeros.currentValue !== changes.ignoreZeros.previousValue
      && this.data
    ) {
      this.drawGraph(
        this.type,
        this.data,
        this.hostEl.clientWidth,
        this.hostEl.clientHeight
      );
    }
  }

  private drawGraph(
    type: BoxplotGraphType,
    data: BoxplotValue[],
    pWidth: number,
    pHeight: number
  ): void {
    if (this.ignoreZeros) {
      // todo: improve filter condition
      data = data.filter(d => !_isNil(d.min) && !_isNil(d.max));
    }

    const width = pWidth - this.margin.left - this.margin.right;
    const height = pHeight - this.margin.top - this.margin.bottom;
    if (width < 0 || height < 0) {
      return;
    }

    const xScale = this.xScaleGen(
      data.map((item: BoxplotValue) => item.name),
      width
    );
    const yScale = this.yScaleGen(
      Math.min(...data.map((item: BoxplotValue) => item.min)),
      Math.max(...data.map((item: BoxplotValue) => item.max)),
      height
    );

    this.svg
      .attr('width', pWidth)
      .attr('height', pHeight)
      .selectAll('*').interrupt();

    this.container
      .attr('transform',
        `translate(${this.margin.left},${this.margin.top})`);

    this.xAxis
      // .transition().duration(1000)
      .attr(
        'transform',
        `translate(0,${height})`
      )
      .call(d3AxisBottom(xScale)
      )
      .call(
        g => g.select('.domain')
        // .remove()
      )
      .call(g => {
          const shouldRotate = g.selectAll('.tick text')
            .nodes()
            .some((tn: SVGTextContentElement) => tn.getComputedTextLength() > xScale.bandwidth());

          g.selectAll('.tick text')
            .attr('text-anchor', shouldRotate ? 'start' : 'middle')
            .attr(
              'transform',
              () => `rotate(${shouldRotate ? 45 : 0})`
            );
        }
      );

    this.yAxis
      // .transition().duration(1000)
      .call(
        d3AxisRight(yScale)
          .tickSize(width - this.margin.left - this.margin.right)
      )
      .call(
        g => g.select('.domain')
          .remove()
      )
      .call(
        g => g.selectAll('.tick line')
          .attr('stroke-opacity', 0.1)
      )
      .call(g => g.selectAll('.tick text')
        .attr('x', 4)
        .attr('dy', -4)
      );


    if (type === BoxplotGraphType.Traditional) {
      this.rangesContainer
        .selectAll('g')
        .data(data, (d: BoxplotValue) => d.name)
        .join(
          enter => enter.append('g')
            .call(range => {
              range
                .append('line')
                .attr('class', 'max')
                .attr('y1', d => yScale(d.max))
                .attr('y2', d => yScale(d.max));


              range
                .append('line')
                .attr('class', 'min')
                .attr('y1', d => yScale(d.min))
                .attr('y2', d => yScale(d.min));


              range
                .append('line')
                .attr('class', 'range')
                .attr('y1', 0)
                .attr('y2', 0);


              range
                .selectAll<BaseType, BoxplotValue>('line')
                .attr('opacity', '0')
                .attr('stroke', '#fff')
                .attr('x1', d => xScale(d.name) + xScale.bandwidth() / 2)
                .attr('x2', d => xScale(d.name) + xScale.bandwidth() / 2);
            }),

          update => update,

          exit => exit.call(range => {
            range.selectAll<BaseType, BoxplotValue>('line')
              .transition()
              .duration(300)
              .attr('opacity', '0')
              .attr('x1', d => xScale(d.name) + xScale.bandwidth() / 2)
              .attr('x2', d => xScale(d.name) + xScale.bandwidth() / 2)
              .attr('y1', 0)
              .attr('y2', 0)
              .call(rangeLine => rangeLine.remove());

            range
              .transition()
              .delay(300)
              .call(rangeGroup => rangeGroup.remove());
          })
        )
        .call(range => {
          range.selectAll<BaseType, BoxplotValue>('line.min, line.max')
            .transition()
            .duration(300)
            .attr('opacity', '1')
            .attr('x1', d => xScale(d.name) + xScale.bandwidth() / 4)
            .attr('x2', d => xScale(d.name) + (xScale.bandwidth() / 4) * 3);


          range.select('line.range')
            .transition()
            .duration(300)
            .attr('opacity', '1')
            .attr('x1', d => xScale(d.name) + xScale.bandwidth() / 2)
            .attr('x2', d => xScale(d.name) + xScale.bandwidth() / 2)
            .attr('y1', d => yScale(d.min))
            .attr('y2', d => yScale(d.max));
        });

      this.barsContainer
        .selectAll('rect')
        .data(
          data.filter(d => !_isNil(d.q1)
            && !_isNil(d.q3)
            && d.q1 <= d.q3),
          (d: BoxplotValue) => d.name)
        .join(
          enter => enter
            .append('rect')
            .attr('opacity', '0')
            .attr('fill', '#69b3a2')
            .attr('x', d => xScale(d.name))
            .attr('width', xScale.bandwidth())
            .attr('y', height)
            .attr('height', 0),

          update => update,

          exit => exit
            .transition()
            .duration(300)
            // .attr('r', 0)
            .attr('opacity', '0')
            .attr('y', height)
            .attr('height', 0)
            .call(bar => bar.remove())
        )
        .attr('opacity', '1')
        .transition()
        .duration(300)
        .attr('x', d => xScale(d.name))
        .attr('width', xScale.bandwidth())
        .attr('y', d => yScale(d.q3))
        .attr('height', d => yScale(d.q1) - yScale(d.q3));

      this.mediansContainer
        .selectAll('line')
        .data(
          data.filter(d => !_isNil(d.median)
            && !_isNil(d.q1)
            && !_isNil(d.q3)
            && d.median >= d.q1
            && d.median <= d.q3),
          (d: BoxplotValue) => d.name
        )
        .join(
          enter => enter
            .append('line')
            .attr('opacity', '0')
            .attr('stroke', '#fff')
            .attr('x1', d => xScale(d.name) + xScale.bandwidth() / 2)
            .attr('x2', d => xScale(d.name) + xScale.bandwidth() / 2)
            // .attr('width', xScale.bandwidth())
            .attr('y1', 0)
            .attr('y2', 0),

          update => update,

          exit => exit
            .transition()
            .duration(300)
            // .attr('r', 0)
            .attr('opacity', '0')
            .attr('y1', 0)
            .attr('y2', 0)
            // .attr('y', height)
            // .attr('height', 0)
            .call(bar => bar.remove())
        )
        .attr('opacity', '1')
        .transition()
        .duration(300)
        .attr('x1', d => xScale(d.name))
        .attr('x2', d => xScale(d.name) + xScale.bandwidth())
        .attr('y1', d => yScale(d.median))
        .attr('y2', d => yScale(d.median));

    } else {
      this.barsContainer
        .selectAll('rect')
        .remove();
    }

    this.textContainer
      .selectAll('g')
      .data(data, (d: BoxplotValue) => d.name)
      .join(
        enter => enter
          .append('g')
          .call(textGroup => {
            textGroup
              .attr('opacity', 0);

            textGroup
              .append('text')
              .attr('class', 'min');
            textGroup
              .append('text')
              .attr('class', 'max');

            textGroup
              .selectAll<BaseType, BoxplotValue>('text')
              .attr('fill', '#9c9c9c')
              .attr('alignment-baseline', 'middle')
              .attr('text-anchor', 'middle');

          }),

        update => update,

        exit => exit
          .transition()
          .duration(300)
          .attr('opacity', 0)
          .call(text => text.remove())
      )
      .transition()
      .duration(300)
      .attr('opacity', 1)
      .call(textGroup => {
        textGroup
          .selectAll<BaseType, BoxplotValue>('text')
          .attr('x', d => xScale(d.name) + xScale.bandwidth() / 2);

        // TODO: improve padding calculation
        textGroup
          .select('text.min')
          .text(d => d.min)
          .transition()
          .duration(300)
          .attr('y', d => yScale(d.min) + this.margin.top / 2);

        textGroup
          .select('text.max')
          .text(d => d.max)
          .transition()
          .duration(300)
          .attr('y', d => yScale(d.max) - this.margin.top / 2);
      });


  }
}
