import { InjectionToken } from '@angular/core';
import {
  BlogInvitationModel,
  DomainInvitationModel,
DomainInviteesModel,
  InvitationResponseModel,
  LegalEntityInvitationModel, PageInvitationModel} from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const INVITATION_ACTIONS = new InjectionToken('Invitation actions');
export const INVITATION_EVENTS = new InjectionToken('Invitation events');


export interface InvitationEventsServiceInterface {
  invitationToDomainCreated: Observable<DomainInvitationModel>
  invitationToLegalEntityCreated: Observable<LegalEntityInvitationModel>
  invitationToPageCreated: Observable<PageInvitationModel>
  invitationToBlogCreated: Observable<BlogInvitationModel>
  invitationResponded: Observable<InvitationResponseModel>
  invitationDeleted: Observable<string>
}
