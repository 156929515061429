<mat-form-field [appearance]="appearance">
  <mat-label>{{(multiple
    ? (globalConfig.domainTitlePlural | uppercase | translate)
    : (globalConfig.domainTitleSingular | uppercase | translate)
  ) | titlecase}}</mat-label>
  <mat-select
    [value]="selectedCur"
    [multiple]="multiple"
    [disabled]="disabled"
    (closed)="onTouched()"
  >
    <div class="selection-actions">
      <button type="button" mat-button color="primary">Select from workspace</button>
      <button type="button" mat-button color="primary" (click)="clearSelection()">Clear selection</button>
    </div>

    <ekon-paginator
      [config]="paginatorConfig"
      #paginator
      [sortingNFilteringHidden]="true"
      [topPaginatorHidden]="true"
    >
      <ng-container *ngIf="paginator.items$ | async as domains">
        <!--<mat-option></mat-option>-->
        <mat-option
          [value]="option.id"
          (onSelectionChange)="setItemSelection($event)"
          *ngFor="let option of domains; trackBy: trackById"
        >{{ option.name }}</mat-option>
      </ng-container>
    </ekon-paginator>
  </mat-select>
</mat-form-field>
