import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  forwardRef, Inject,
  Provider,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent, MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  UserHeaderModel,
  UserHeaderModelPagedResult,
  UserProfileServiceInterface
} from '@ekon-client/dkm-api';
import { USERPROFILE_ACTIONS } from '@ekon-client/dkm-events';
import { ekIconTimesCircle } from '@ekon-client/shared/common/ekon-icons';
import { PaginationModel, PaginatorConfig } from '@ekon-client/shared/common/ekon-pagination';
import { AutocompleteService } from '@ekon-client/shared/features/dkm-autocompletion';
import { uniqBy as _uniqBy } from 'lodash-es';
import { Observable } from 'rxjs';

const CUSTOM_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UserChipsAutocompleteComponent),
  multi: true
};

@Component({
  selector: 'ekon-user-chips-autocomplete',
  templateUrl: './user-chips-autocomplete.component.html',
  styles: [''],
  providers: [CUSTOM_VALUE_ACCESSOR]
})
export class UserChipsAutocompleteComponent
  implements ControlValueAccessor {
  ekIconTimesCircle = ekIconTimesCircle;

  users: UserHeaderModel[] = [];
  disabled: boolean;

  filteredUsers: Observable<UserHeaderModel[]>;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('autocmplUsers') matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  onChange: (x?: unknown) => void;
  onTouched: (x?: unknown) => void;

  trackById = (index: number, item: UserHeaderModel): string => item.id;
  paginatorConfig: PaginatorConfig = {
    loader: (pagination: PaginationModel): Observable<UserHeaderModelPagedResult> =>
      this.userProfileActions.listUserProfileHeaders(pagination),
    initialOptions: {
      pageSize: 10
    }
  };

  constructor(
    private autocompleteService: AutocompleteService,
    @Inject(USERPROFILE_ACTIONS) private userProfileActions: UserProfileServiceInterface
  ) {
  }

  /* ControlValueAccessor implementation */

  registerOnChange(fn: (x?: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (x?: unknown) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: UserHeaderModel[]): void {
    this.users = obj?.filter(a => Boolean(a)) || [];
  }

  /* Control logic */

  addUser(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = (event.value || '').trim();

    if(value.length) {
      this.applyChanges({
        email: value
      });
    }

    input.value = '';
  }

  removeUser(user: UserHeaderModel): void {
    const index = this.users.indexOf(user);

    this.users.splice(index, 1);

    this.applyChanges();
  }

  applyChanges(item?: UserHeaderModel): void {
    this.matAutocomplete.isOpen && this.autocompleteTrigger.closePanel();

    item && this.users.push(item);

    const res = this.users ? this.users : [];

    this.users = _uniqBy(this.users, 'email');

    if(this.onChange) {
      this.onChange(res);
    }
  }

  autoCompleteUserSelected($event: MatAutocompleteSelectedEvent): void {
    this.applyChanges($event.option.value);
    this.userInput.nativeElement.value = '';
  }
}
