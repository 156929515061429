import { Component, Input } from '@angular/core';
import { DkmDomain } from '@ekon-client/dkm-api';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { DomainSelectorService } from '@ekon-client/shared/features/dkm-domains-services';
import { Observable } from 'rxjs';

import { DomainMembershipService } from '../../services/domain-membership.service';


@Component({
  selector: 'dkm-domains-list',
  templateUrl: './domains-list.component.html',
  styleUrls: ['./domains-list.component.scss']
})
export class DomainsListComponent {

  @Input() limit: number;

  availableDomains: Observable<DkmDomain[]>;

  constructor(
    private domainMembership: DomainMembershipService,
    private domainSelectorService: DomainSelectorService,
    public globalConfig: GlobalConfigurationService
  ) {
    // this.availableDomains = domainSelectorService.domains$
    //   .pipe(
    //     filter(d => Boolean(d)),
    //     map((domains: DkmDomain[]) => this.limit ? domains.slice(0, this.limit) : domains)
    //   );
  }

  leaveDomain(domain: DkmDomain): void {
    this.domainMembership.leaveDomain(domain);
  }

  joinDomain(): void {
    this.domainMembership.joinDomain();
  }
}
