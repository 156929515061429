/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Category } from './category';
import { ProductHeader } from './productHeader';
import { UserHeaderModel } from './userHeaderModel';
import { DkmDomainHeaderModel } from './dkmDomainHeaderModel';


export interface PageHeader { 
    kind: string;
    id: string;
    name: string;
    shortDescription: string;
    description?: string | null;
    isTemplate?: boolean;
    /**
     * Rating - 1-5 stars  This is calculated results arrived from external analytics system, if applicable, or DKM\'s Analytics.  One use case: WeldGalaxy, calculated and arrived from UPM analytics
     */
    externalRatingResult?: number;
    /**
     * An Id from external populations.
     */
    externalId_fromPopulation?: string | null;
    thumbprintUrl?: string | null;
    imageId?: string | null;
    lastUpdateAt?: Date;
    publishedAt?: Date | null;
    publishedBy?: string | null;
    publisher?: UserHeaderModel;
    createdBy?: string | null;
    createdAt?: Date;
    creator?: UserHeaderModel;
    /**
     * UI Only. Display page\'s domain/space header.
     */
    domains?: Array<DkmDomainHeaderModel> | null;
    categories?: Array<Category> | null;
    tags?: Array<string> | null;
    /**
     * The Average rate of this page
     */
    averageRate?: number;
    create?: boolean;
    read?: boolean;
    update?: boolean;
    remove?: boolean;
}

