import { Component, Input, SimpleChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { FileMetadata, UserProfileHeader } from '@ekon-client/dkm-api';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { FileView } from '../../../../../extras';
import { FileService } from '../../../../../services';

@Component({
  selector: 'dkm-file-view-video',
  templateUrl: './file-view-video.component.html',
  styleUrls: ['./file-view-video.component.scss']
})
export class FileViewVideoComponent implements FileView {
  @Input() fileAuthor: UserProfileHeader;
  @Input() fileMeta: FileMetadata;
  @Input() hideCredentials: boolean;

  private fileUrl: string;

  private _fileId$: BehaviorSubject<string> = new BehaviorSubject(null);
  fileBodyURI$: Observable<SafeResourceUrl> = this._fileId$.asObservable().pipe(
    switchMap((fileId: string) => fileId ? this.fileService.loadFileBlobURL(fileId) : EMPTY),
    tap((url: string) => (this.fileUrl = url)),
    map((url: string) => this.fileService.bypassSecurityBlobURL(url))
  );

  constructor(
    private fileService: FileService
  ) {
  }

  ngOnDestroy(): void {
    this._fileId$.next(null);
    this._fileId$.complete();
    this.fileService.clearBlobURL(this.fileUrl);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.fileMeta
      && changes.fileMeta.currentValue !== changes.fileMeta.previousValue
    ) {
      if (changes.fileMeta.currentValue) {
        this._fileId$.next(this.fileMeta.id);
        this.fileService.clearBlobURL(this.fileUrl);
      } else {
        this._fileId$.next(null);
        this.fileService.clearBlobURL(this.fileUrl);
      }
    }
  }
}
