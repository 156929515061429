/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { DltTenderModel } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { ProblemDetails } from '../model/models';
import { TenderCreatedModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface DLTServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param basketId 
     */
    findTenderByBasketId(basketId: string, extraHttpRequestParams?: any): Observable<Array<DltTenderModel>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param customerLegalEntityId 
     */
    findTenderByCustomerLegalEntityId(customerLegalEntityId: string, extraHttpRequestParams?: any): Observable<Array<DltTenderModel>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param supplierLegalEntityId 
     */
    findTenderBySupplierLegalEntityId(supplierLegalEntityId: string, extraHttpRequestParams?: any): Observable<Array<DltTenderModel>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param tenderId 
     */
    findTenderByTenderId(tenderId: string, extraHttpRequestParams?: any): Observable<Array<DltTenderModel>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     */
    findTenderByUser(extraHttpRequestParams?: any): Observable<Array<DltTenderModel>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param tenderId 
     * @param supplierId 
     */
    tenderBidAcceptUpdate(tenderId: string, supplierId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param tenderId 
     * @param supplierId 
     */
    tenderBidDeclineUpdate(tenderId: string, supplierId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param tenderId 
     * @param supplierId 
     */
    tenderBidUpdate(tenderId: string, supplierId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * On DLT Tender creation, update the basket with Tender details (id, dates)
     * 
     * @param tenderCreatedModel 
     */
    tenderCreated(tenderCreatedModel?: TenderCreatedModel, extraHttpRequestParams?: any): Observable<{}>;

}
