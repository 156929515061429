/* eslint-disable rxjs-angular/prefer-takeuntil */
/* eslint-disable rxjs-angular/prefer-composition */
/* eslint-disable rxjs/no-ignored-subscription */
/* eslint-disable rxjs-angular/prefer-async-pipe */
import { Component, Inject, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EkonAuthService } from '@ekon-client/auth';
import {
  LandingPage,
  LandingPageServiceInterface,
  UserProfileServiceInterface
} from '@ekon-client/dkm-api';
import {
  LANDING_PAGE_ACTIONS,
  LANDING_PAGE_EVENTS,
  LandingPageEventsServiceInterface,
  USERPROFILE_ACTIONS
} from '@ekon-client/dkm-events';
import { merge, Observable, of, Subject } from 'rxjs';
import { filter, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'tou-display',
  templateUrl: './terms-of-use-display.component.html',
  styleUrls: ['./terms-of-use-display.component.scss']
})
export class TermsOfUseDisplayComponent implements OnDestroy {
  private readonly _unsubscribeAll: Subject<void> = new Subject<void>();

  get needAcceptance(): boolean {
    return history?.state?.userProfile && !history?.state?.userProfile?.touAccepted
  }

  tou$: Observable<LandingPage>;

  constructor(
    @Inject(USERPROFILE_ACTIONS) private userProfileActions: UserProfileServiceInterface,
    private router: Router,
    private authService: EkonAuthService,
    @Inject(LANDING_PAGE_ACTIONS) private lpActions: LandingPageServiceInterface,
    @Inject(LANDING_PAGE_EVENTS) private lpEvents: LandingPageEventsServiceInterface,
    public sanitizer: DomSanitizer
  ) {
    this.tou$ = merge(
      of(true),
      lpEvents.landingPageUpdated
    ).pipe(
      switchMap(() => this.lpActions.findDefaultLandingPage()),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this._unsubscribeAll),
    );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  respondTOU(): void {
    if (history.state.userProfile) {
      this.userProfileActions.updateUserAcceptTouPp(history.state.userProfile.id)
        .pipe(switchMap(() =>
          this.authService.refreshTokenAndUpdateUser()),
          switchMap(() => this.authService.isLoggedIn$),
          filter((isLoggedIn: boolean) => isLoggedIn === true),
          tap(() => {
            history.state.prev && history.state.prev !== '/terms-of-use'
              ? this.router.navigateByUrl(history.state.prev)
              : this.router.navigate(['dashboard']);
          }),
          take(1)
        ).subscribe();
    }
  }
}
