/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { BooleanElement } from '../model/models';
import { BoxPlotGraphElement } from '../model/models';
import { CurrencyInputElement } from '../model/models';
import { DateTimeElement } from '../model/models';
import { DropDownListElement } from '../model/models';
import { ElementType } from '../model/models';
import { FileViewerElement } from '../model/models';
import { GridViewElement } from '../model/models';
import { HtmlValueElement } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { IframeValueElement } from '../model/models';
import { JsonValueElement } from '../model/models';
import { NameValueGraphElement } from '../model/models';
import { NumericInputElement } from '../model/models';
import { ProblemDetails } from '../model/models';
import { RichTextElement } from '../model/models';
import { TextAreaInputElement } from '../model/models';
import { TextInputElement } from '../model/models';
import { YouTubeElement } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ElementTemplatesServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param elementType 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createElementTemplate(elementType?: ElementType, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteElementTemplate(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findElementTemplateById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<BooleanElement | BoxPlotGraphElement | CurrencyInputElement | DateTimeElement | DropDownListElement | FileViewerElement | GridViewElement | HtmlValueElement | IframeValueElement | JsonValueElement | NameValueGraphElement | NumericInputElement | RichTextElement | TextAreaInputElement | TextInputElement | YouTubeElement>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listElementTemplates(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<BooleanElement | BoxPlotGraphElement | CurrencyInputElement | DateTimeElement | DropDownListElement | FileViewerElement | GridViewElement | HtmlValueElement | IframeValueElement | JsonValueElement | NameValueGraphElement | NumericInputElement | RichTextElement | TextAreaInputElement | TextInputElement | YouTubeElement>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listElementTypes(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<ElementType>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param booleanElementBoxPlotGraphElementCurrencyInputElementDateTimeElementDropDownListElementFileViewerElementGridViewElementHtmlValueElementIframeValueElementJsonValueElementNameValueGraphElementNumericInputElementRichTextElementTextAreaInputElementTextInputElementYouTubeElement 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateElementTemplate(id: string, booleanElementBoxPlotGraphElementCurrencyInputElementDateTimeElementDropDownListElementFileViewerElementGridViewElementHtmlValueElementIframeValueElementJsonValueElementNameValueGraphElementNumericInputElementRichTextElementTextAreaInputElementTextInputElementYouTubeElement?: BooleanElement | BoxPlotGraphElement | CurrencyInputElement | DateTimeElement | DropDownListElement | FileViewerElement | GridViewElement | HtmlValueElement | IframeValueElement | JsonValueElement | NameValueGraphElement | NumericInputElement | RichTextElement | TextAreaInputElement | TextInputElement | YouTubeElement, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

}
