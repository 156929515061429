import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { EkonAuthService } from '@ekon-client/auth';
import { UserProfile } from '@ekon-client/dkm-api';
import { ekIconChevronDown, ekIconDownload, ekIconRedo } from '@ekon-client/shared/common/ekon-icons';
import { PwaUpdateService } from '@ekon-client/shared/common/ekon-pwa';
import { PermissionsService } from '@ekon-client/shared/features/dkm-permissions';
import { getUserFullName, UserProfileService } from '@ekon-client/shared/features/dkm-user-profile';
import { Observable } from 'rxjs';

@Component({
  selector: 'dkm-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileMenuComponent {
  ekIconChevronDown = ekIconChevronDown;
  ekIconDownload = ekIconDownload;
  ekIconRedo = ekIconRedo;

  userData: Observable<UserProfile>;

  constructor(
    private authService: EkonAuthService,
    private _cdr: ChangeDetectorRef,
    private userProfile: UserProfileService,
    public permissionsService: PermissionsService,
    public pwa: PwaUpdateService
  ) {
    this.userData = userProfile.currentUser$;
  }

  logout(): void {
    this.authService.logout();
  }

  login(): void {
    this.authService.login();
  }

  getUserFullName(userProfile: UserProfile): string | null {
    return getUserFullName(userProfile);
  }

  installApp(): void {
    this.pwa.installOrShowHelper();
  }

  checkUpdates(): void {
    this.pwa.checkUpdates();
  }
}
