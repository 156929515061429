export interface Translation {
  lang: string;
  data: Record<string, Record<string, TranslationItem | string>>;
}

export interface TranslationItem {
  TITLE: string;

  SUB?: Record<string, TranslationItem | string>;
}
