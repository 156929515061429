import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import {
  ekIconBars,
  ekIconBlogs,
  ekIconCatalog,
  ekIconCool,
  ekIconHome, ekIconHouseUser, ekIconLogout, ekIconMySpace, ekIconNewspaper,
  ekIconPages, ekIconShoppingCart,
  ekIconStores, EkonIconsLibraryService, EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { DkmDomainsModule } from '@ekon-client/shared/features/dkm-domains';
import { EkonSearchNFilterModule } from '@ekon-client/shared/features/ekon-search-n-filter';
import { TranslateModule } from '@ngx-translate/core';

import { LanguageSetterModule } from '../language-setter/language-setter.module';
import { MobileNavComponent } from './mobile-nav.component';


@NgModule({
  declarations: [
    MobileNavComponent,
  ],
    imports: [
        CommonModule,
        MatRippleModule,
        DkmDomainsModule,
        RouterModule,
        MatDividerModule,
        EkonSearchNFilterModule,
        LanguageSetterModule,
        EkonIconsModule,
        TranslateModule
    ],
  exports: [
    MobileNavComponent
  ]
})
export class MobileNavModule {
  constructor(ekLib: EkonIconsLibraryService) {
    ekLib.addIcons(
      ekIconStores,
      ekIconHome,
      ekIconCool,
      ekIconCatalog,
      ekIconPages,
      ekIconBlogs,
      ekIconMySpace,
      ekIconShoppingCart,
      ekIconBars,
      ekIconLogout,
      ekIconNewspaper,
      ekIconHouseUser
    );
  }
}
