<ekon-dialog-wrapper>
  <form [formGroup]="form" (ngSubmit)="validateAndSave()" fxLayout="column" class="h-100-p">
    <div mat-dialog-title class="h2 mb-24" fxLayoutAlign="start center">
      {{(data ? 'EDIT_NOTE' : 'ADD_NEW_NOTE') | translate}}</div>
    <mat-dialog-content fxFlex="1 0 auto">
      <div fxLayout="column" fxLayoutAlign="start stretch">
        <ekon-wysiwyg-editor
          formControlName="value"
          mode="default"
        ></ekon-wysiwyg-editor>
        <mat-error>
          <span *ngIf="form?.controls?.value?.errors?.required">{{'VALUE_IS_REQUIRED' | translate}}</span>
        </mat-error>

        <mat-form-field appearance="outline" class="w-100-p">
          <mat-label>{{'EXTERNAL_LINK' | translate}}</mat-label>
          <input matInput formControlName="externalLink">
        </mat-form-field>

        <mat-button-toggle-group
          #colors="matButtonToggleGroup"
          formControlName="color"
          class="mb-24"
        >
          <mat-button-toggle
            *ngFor="let color of presetColors$ | async"
            [value]="color"
            [style.background]="color"
            style="width: 48px; height: 48px"
          >
            <ekon-icon *ngIf="colors.value === color; else emptyColor" [icon]="ekIconCompletion"></ekon-icon>

            <ng-template #emptyColor>&nbsp;</ng-template>
          </mat-button-toggle>
        </mat-button-toggle-group>

        <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

          <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
            <mat-label>{{'REMINDER_DATE' | translate}}</mat-label>
            <input matInput
                   [matDatepicker]="reminderDatePicker"
                   formControlName="reminderDateTime">
            <mat-datepicker-toggle matSuffix
                                   [for]="reminderDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #reminderDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline"
                          class="pl-sm-8 no-errors-spacer"
                          fxFlex="50">
            <mat-label>{{'REMINDER TIME'| translate}}</mat-label>
            <input matInput
                   type="time"
                   [value]="form?.controls['reminderDateTime'].value | date:'HH:mm'"
                   (change)="timeChanged($event, 'reminderDateTime')">
          </mat-form-field>

        </div>

        <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

          <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
            <mat-label>{{'CLOSE_DATE' | translate}}</mat-label>
            <input matInput
                   [matDatepicker]="closeAfterDatePicker"
                   formControlName="closeAfterDateTime">
            <mat-datepicker-toggle matSuffix
                                   [for]="closeAfterDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #closeAfterDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline"
                          class="pl-sm-8 no-errors-spacer"
                          fxFlex="50">
            <mat-label>{{'CLOSE_TIME' | translate}}</mat-label>
            <input matInput
                   type="time"
                   [value]="form.controls['closeAfterDateTime'].value | date:'HH:mm'"
                   (change)="timeChanged($event, 'closeAfterDateTime')">
          </mat-form-field>

        </div>

        <dkm-tags-autocomplete formControlName="tags"></dkm-tags-autocomplete>

      </div>

    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
      <mat-slide-toggle formControlName="isClosed">{{'IS_CLOSED' | translate}}</mat-slide-toggle>
      <mat-slide-toggle
        formControlName="isPlatformNote"
        class="ml-8"
        *ngIf="(isAdmin$ | async) && globalConfig.enablePlatformNotes$ | async"
      >{{'IS_PLATFORM_NOTE' | translate}}</mat-slide-toggle>

      <div fxLayout="row" fxLayoutAlign="end center" fxFlex="0 0 auto">
        <button mat-button [mat-dialog-close]="false">{{'CANCEL' | translate}}</button>
        <button mat-button type="submit" [disabled]="!form.pristine && !form.valid">
          {{ (data ? 'SAVE' : 'ADD') | translate }}</button>
      </div>
    </mat-dialog-actions>
  </form>
</ekon-dialog-wrapper>
