import { ChangeDetectionStrategy, Component, Inject,TrackByFunction } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarItem, CalendarItemHeader, CalendarServiceInterface } from '@ekon-client/dkm-api';
import {
  CALENDAR_ACTIONS, CALENDAR_EVENTS,
  CalendarEventsServiceInterface,
} from '@ekon-client/dkm-events';
import { PaginationModel, PaginatorConfig } from '@ekon-client/shared/common/ekon-pagination';

@Component({
  selector: 'ekon-client-user-post-add-attachment-meetup',
  templateUrl: './meetup-selector-dialog.component.html',
  styleUrls: ['./meetup-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeetupSelectorDialogComponent {

  paginatorConfig: PaginatorConfig;

  trackById: TrackByFunction<CalendarItemHeader> = (index: number, item: CalendarItemHeader) => item.id;

  constructor(
    private dialogRef: MatDialogRef<MeetupSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      kind: string,
      domain: string,
      isPrivate: boolean
    },
    @Inject(CALENDAR_ACTIONS) protected calendarActions: CalendarServiceInterface,
    @Inject(CALENDAR_EVENTS) protected calendarEvents: CalendarEventsServiceInterface,
  ) {
    this.paginatorConfig = {
      loader: (pagination: PaginationModel) => this.calendarActions.getCalendar(
        pagination,
        data.domain,
        data.isPrivate
      ),
      observables: data.kind === 'event' ? [
        this.calendarEvents.calendarItemCreated,
        this.calendarEvents.calendarItemDeleted,
        this.calendarEvents.calendarItemUpdated
      ] : [
        this.calendarEvents.calendarItemCreated,
        this.calendarEvents.calendarItemDeleted,
        this.calendarEvents.calendarItemUpdated
      ]
    }
  }

  validateAndSave(calendarItem: CalendarItemHeader | CalendarItem): void {
    console.warn('meetup selected', calendarItem);
    this.dialogRef.close(calendarItem);
  }
}
