import { Category, PageHeader } from '@ekon-client/dkm-api';

export interface EkonFiltersUpdated {
  search?: string;
  categories?: Category[];
  tags?: string[];
  tagFilterMode?: boolean;
  templates?: PageHeader[];

  /**
   *  for Files API only
   */
  contentType?: string[];
}
