import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BlockedContentType,
  Configuration,
  ReportContentService,
  ReportContentServiceInterface, SortDirection
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ReportContentEventsServiceInterface } from './ReportContentEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class ReportContentEventsService
  implements ReportContentEventsServiceInterface, ReportContentServiceInterface {
  get defaultHeaders(): HttpHeaders {
    return this.tenderService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.tenderService.defaultHeaders = val;
  }

  get configuration(): Configuration {
    return this.tenderService.configuration;
  }

  set configuration(val: Configuration) {
    this.tenderService.configuration = val;
  }

  private contentBlockedSubject = new Subject<string>();

  get contentBlocked(): Observable<string> {
    return this.contentBlockedSubject.asObservable();
  }

  private contentReportedSubject = new Subject<string>();

  get contentReported(): Observable<string> {
    return this.contentReportedSubject.asObservable();
  }

  constructor(
    private tenderService: ReportContentService,
    private progressBar: EkonProgressBarService
  ) { }

  blockContent(
    reportId: string,
    contentType: BlockedContentType,
    contentId: string,
    reason?: string
  ): Observable<unknown> {
    return this.progressBar.apply(
      this.tenderService.blockContent(reportId, contentType, contentId, reason)
    ).pipe(
      tap({
        next: () => this.contentBlockedSubject.next(contentId)
      })
    );
  }

  reportContent(
    contentType: BlockedContentType,
    contentId: string,
    reason?: string
  ): Observable<unknown> {
    return this.progressBar.apply(
      this.tenderService.reportContent(contentType, contentId, reason)
    ).pipe(
      tap({
        next: () => this.contentReportedSubject.next(contentId)
      })
    );
  }

  reportedContents(
    pageNumber?: number,
    pageSize?: number,
    sortBy?: string,
    sortOrder?: number,
    sortPartition?: number,
    type?: SortDirection
  ): Observable<unknown> {
    return this.progressBar.apply(
      this.tenderService.reportedContents(
        pageNumber,
        pageSize,
        sortBy,
        sortOrder,
        sortPartition,
        type
      )
    );
  }
}
