/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CalendarItemHeaderPagedResult } from '../model/models';
import { Event } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';
import { TaskAction } from '../model/models';
import { UserHeaderModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface CalendarServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param noteId 
     * @param xSelectedDomain Comma-delimited domain id
     */
    copyNoteToTask(noteId: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Event | TaskAction>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param eventTaskAction 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createCalendarItem(eventTaskAction?: Event | TaskAction, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Event | TaskAction>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteCalendarItem(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findCalendarItemById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Event | TaskAction>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    getAttendees(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<UserHeaderModel>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param kind 
     * @param isPrivate 
     * @param from 
     * @param to 
     * @param xSelectedDomain Comma-delimited domain id
     */
    getCalendar(pagePaginationModel?: PagePaginationModel, kind?: string, isPrivate?: boolean, from?: Date, to?: Date, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<CalendarItemHeaderPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listCalendarItemsTypes(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<string>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param eventTaskAction 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateCalendarItem(eventTaskAction?: Event | TaskAction, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

}
