<ekon-dialog-wrapper>
  <div mat-dialog-title class="h2 mb-24"
       fxLayoutAlign="start center">Select parent category
  </div>
  <mat-dialog-content fxFlex="1 0 auto">
    <mat-tree
      *ngIf="dataSource$ | async as dataSource"
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="categories-tree"
    >
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <!--suppress TypeScriptUnresolvedVariable -->
        <li class="mat-tree-node"
            fxLayout="row"
            fxLayoutAlign="stretch center"
            [class.accent]="mode === MODES.SINGLE && itemSelected(node)"
            [class.grey-700]="mode === MODES.SINGLE && descendantsPartiallySelected(node)"
        >
          <button mat-icon-button disabled fxFlex="0 0 auto"></button>
          <ng-container *ngTemplateOutlet="treeItemContent; context: {$implicit: node}"></ng-container>
        </li>
      </mat-tree-node>

      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <!--suppress TypeScriptUnresolvedVariable -->
        <li>
          <div class="mat-tree-node"
               fxLayout="row"
               fxLayoutAlign="stretch center"
               [class.accent]="mode === MODES.SINGLE && itemSelected(node)"
               [class.grey-700]="mode === MODES.SINGLE && descendantsPartiallySelected(node)"
          >
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.name"
                    fxFlex="0 0 auto"
            >
              <ekon-icon [icon]="treeControl.isExpanded(node) ? 'chevron-down' : 'angle-right'"></ekon-icon>
            </button>
            <ng-container *ngTemplateOutlet="treeItemContent; context: {$implicit: node}"></ng-container>
          </div>
          <ul [class.categories-tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>

  </mat-dialog-content>
  <mat-dialog-actions *ngIf="mode === MODES.MULTIPLE"
                      fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="clearSelection()">Clear</button>
    <div>
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button (click)="applySelection()">Apply</button>
    </div>
  </mat-dialog-actions>
</ekon-dialog-wrapper>


<ng-template #treeItemContent let-category>
  <div *ngIf="mode === MODES.SINGLE"
       (click)="selectCategory(category)"
       class="category-selector"
       matRipple
       fxFlex="1 1 100%"
       fxLayout="row"
       fxLayoutAlign="start center">{{category.name}}</div>

  <!--suppress TypeScriptUnresolvedVariable -->
  <mat-checkbox *ngIf="mode === MODES.MULTIPLE"
                [checked]="itemSelected(category)"
                [indeterminate]="descendantsPartiallySelected(category)"
                (change)="toggleCategorySelection(category)">{{category.name}}</mat-checkbox>
</ng-template>
