import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ElementRef, Input, OnChanges, SimpleChanges
} from '@angular/core';
import {
  axisBottom as d3AxisBottom,
  axisRight as d3AxisRight,
  line as d3Line,
  ScaleBand,
  scaleBand as d3ScaleBand,
  ScaleLinear,
  scaleLinear as d3ScaleLinear,
  select as d3Select
} from 'd3';
import ResizeObserver from 'resize-observer-polyfill';

import { NameValuePair } from '../../extras/NameValuePair';
import {
  SelectionSVG,
  SelectionSVGG
} from '../../extras/SelectionTypes';

export enum BarGraphModes {
  BAR = 'bar',
  LINE = 'line',
  BAR_WITH_LINE = 'barWithLine'
}

@Component({
  selector: 'ekon-bar-graph',
  template: ``,
  styleUrls: ['./bar-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarGraphComponent implements AfterViewInit, OnChanges {
  @Input() mode: BarGraphModes = BarGraphModes.BAR_WITH_LINE;
  @Input() data: NameValuePair[];
  @Input() ignoreZeros: boolean;

  private margin = { top: 20, right: 0, bottom: 30, left: 0 };
  private svg: SelectionSVG;
  private container: SelectionSVGG;

  hostEl: Element;
  private barsContainer: SelectionSVGG;
  private lineContainer: SelectionSVGG;
  private textContainer: SelectionSVGG;
  private xAxis: SelectionSVGG;
  private yAxis: SelectionSVGG;

  private resizeObserver: ResizeObserver;

  constructor(private hostElRef: ElementRef<Element>) {
    this.hostEl = this.hostElRef.nativeElement;
  }

  xScaleGen(data: string[], width: number): ScaleBand<string> {
    return d3ScaleBand()
      .domain(data)
      .range([0, width])
      .paddingInner(0.2)
      .paddingOuter(0.5);
  }


  // values = ;
  yScaleGen(data: number[], rangeMax: number, rangeMin = 0): ScaleLinear<number, number> {
    const domainMin = Math.min(...data);
    const domainMax = Math.max(...data);
    const domainPadding = 5 * domainMax / 100;
    return d3ScaleLinear()
      .domain([domainMin - domainPadding, domainMax + domainPadding])
      .range([rangeMax, rangeMin]);
  }

  ngAfterViewInit(): void {
    this.svg = d3Select(this.hostEl)
      .append('svg');

    this.container = this.svg
      .append('g');

    // X axis group
    this.xAxis = this.container
      .append('g');
    // Y axis group
    this.yAxis = this.container
      .append('g');
    // bars group
    this.barsContainer = this.container
      .append('g');
    // line group
    this.lineContainer = this.container
      .append('g');
    this.lineContainer.append('path');
    // text group
    this.textContainer = this.container
      .append('g');

    this.data && this.drawGraph(
      this.mode,
      this.data,
      this.hostEl.clientWidth,
      this.hostEl.clientHeight
    );

    //  .selectAll("*").interrupt()

    let timeout;

    this.resizeObserver = new ResizeObserver(
      ([{ contentRect: bounds }]: ResizeObserverEntry[]) => {
        timeout && clearTimeout(timeout);

        timeout = setTimeout(() => {
          this.data && this.drawGraph(
            this.mode,
            this.data,
            bounds.width,
            bounds.height
          );
        }, 100);
      }
    );

    this.resizeObserver.observe(this.hostEl);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.data && !changes.data.firstChange && changes.data.currentValue) {
      this.drawGraph(
        this.mode,
        changes.data.currentValue,
        this.hostEl.clientWidth,
        this.hostEl.clientHeight
      );
    }
    if(
      changes.ignoreZeros
      && !changes.ignoreZeros.firstChange
      && changes.ignoreZeros.currentValue !== changes.ignoreZeros.previousValue
      && this.data
    ) {
      this.drawGraph(
        this.mode,
        this.data,
        this.hostEl.clientWidth,
        this.hostEl.clientHeight
      );
    }
  }


  private drawGraph(
    mode: BarGraphModes,
    data: NameValuePair[],
    pWidth: number,
    pHeight: number
  ): void {
    if(this.ignoreZeros) {
      data = data.filter(d => d.value);
    }

    const width = pWidth - this.margin.left - this.margin.right;
    const height = pHeight - this.margin.top - this.margin.bottom;
    if(width < 0 || height < 0) {
      return;
    }

    const xScale = this.xScaleGen(
      data.map((item: NameValuePair) => item.name),
      width
    );
    const yScale = this.yScaleGen(
      data.map((item: NameValuePair) => item.value),
      height
    );
    const line = d3Line<NameValuePair>()
      .x(d => xScale(d.name) + xScale.bandwidth() / 2)
      .y(d => yScale(d.value))(data);

    this.svg
      .attr('width', pWidth)
      .attr('height', pHeight)
      .selectAll('*').interrupt();

    this.container
      .attr('transform',
        `translate(${this.margin.left},${this.margin.top})`);

    this.xAxis
      // .transition().duration(1000)
      .attr(
        'transform',
        `translate(0,${height})`
      )
      .call(d3AxisBottom(xScale)
      )
      .call(
        g => g.select('.domain')
        // .remove()
      )
      .call(g => {
          const shouldRotate = g.selectAll('.tick text')
            .nodes()
            .some((tn: SVGTextContentElement) => tn.getComputedTextLength() > xScale.bandwidth());

          g.selectAll('.tick text')
            .attr('text-anchor', shouldRotate ? 'start' : 'middle')
            .attr(
              'transform',
              () => `rotate(${shouldRotate ? 45 : 0})`
            );
        }
      );

    this.yAxis
      // .transition().duration(1000)
      .call(
        d3AxisRight(yScale)
          .tickSize(width - this.margin.left - this.margin.right)
      )
      .call(
        g => g.select('.domain')
          .remove()
      )
      .call(
        g => g.selectAll('.tick line')
          .attr('stroke-opacity', 0.1)
      )
      .call(g => g.selectAll('.tick text')
        .attr('x', 4)
        .attr('dy', -4)
      );


    if(mode === BarGraphModes.BAR || mode === BarGraphModes.BAR_WITH_LINE) {
      this.barsContainer
        .selectAll('rect')
        .data(data, (d: NameValuePair) => d.name)
        .join(
          enter => enter
            .append('rect')
            .attr('opacity', '0')
            .attr('fill', '#69b3a2')
            .attr('x', d => xScale(d.name))
            .attr('width', xScale.bandwidth())
            .attr('y', height)
            .attr('height', 0),

          update => update,

          exit => exit
            .transition()
            .duration(300)
            // .attr('r', 0)
            .attr('opacity', '0')
            .attr('y', height)
            .attr('height', 0)
            .call(bar => bar.remove())
        )
        .attr('opacity', mode === BarGraphModes.BAR ? '1' : '0.2')
        .transition()
        .duration(300)
        .attr('x', d => xScale(d.name))
        .attr('width', xScale.bandwidth())
        .attr('y', d => yScale(d.value))
        .attr('height', d => height - yScale(d.value));
    } else {
      this.barsContainer
        .selectAll('rect')
        .remove();
    }

    if(mode === BarGraphModes.LINE || mode === BarGraphModes.BAR_WITH_LINE) {
      this.lineContainer
        .select('path')
        .transition()
        .duration(300)
        .attr('d', line)
        .attr('fill', 'none')
        .attr('stroke', '#ffffff');

      this.lineContainer
        .selectAll('circle')
        .data(data, (d: NameValuePair) => d.name)
        .join(
          enter => enter
            .append('circle')
            .attr('r', 0)
            // .attr('opacity', 0)
            .attr('fill', '#69b3a2')
            .attr('stroke', '#ffffff')
            .attr('stroke-width', '1')
            .attr('cx', (d) => xScale(d.name) + xScale.bandwidth() / 2)
            .attr('cy', d => yScale(d.value)),

          update => update,

          exit => exit
            .transition()
            .duration(300)
            .attr('r', 0)
            .call(circle => circle.remove())
        )
        .transition()
        .duration(300)
        .attr('cx', (d) => xScale(d.name) + xScale.bandwidth() / 2)
        .attr('cy', d => yScale(d.value))
        .transition()
        .duration(1000)
        .attr('r', 4);
    } else {
      this.lineContainer
        .select('path')
        .attr('d', '');
      this.lineContainer
        .selectAll('circle')
        .remove();
    }

    this.textContainer
      .selectAll('text')
      .data(data, (d: NameValuePair) => d.name)
      .join(
        enter => enter
          .append('text')
          .attr('opacity', 0)
          .attr('fill', '#9c9c9c'),

        update => update,

        exit => exit
          .transition()
          .duration(300)
          .attr('opacity', 0)
          .call(text => text.remove())
      )
      .transition()
      .text(d => d.value.toFixed(2))
      .duration(300)
      .attr('opacity', 1)
      .attr('text-anchor', 'middle')
      .attr('x', d => xScale(d.name) + xScale.bandwidth() / 2)
      // TODO: improve padding calculation
      .attr('y', d => yScale(d.value) - this.margin.top / 2)
      .attr('height', d => height - yScale(d.value));

  }
}
