import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  ekIconTimesCircle,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import {
  AttendeesChipsAutocompleteComponent
} from './components/attendees-chips-autocomplete/attendees-chips-autocomplete.component';
import { EkonAutocompleteComponent } from './components/autocomplete/ekon-autocomplete.component';
import { ChipsAutocompleteComponent } from './components/chips-autocomplete/chips-autocomplete.component';
import {
  PagesChipsAutocompleteComponent
} from './components/pages-chips-autocomplete/pages-chips-autocomplete.component';
import { TagsAutocompleteComponent } from './components/tags-autocomplete/tags-autocomplete.component';
import { AutocompleteService } from './services';


@NgModule({
  declarations: [
    AttendeesChipsAutocompleteComponent,
    PagesChipsAutocompleteComponent,
    TagsAutocompleteComponent,
    ChipsAutocompleteComponent,
    EkonAutocompleteComponent
  ],
  imports: [
    CommonModule,

    ReactiveFormsModule,

    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    EkonIconsModule,
    TranslateModule,
    MatInputModule
  ],
  exports: [
    AttendeesChipsAutocompleteComponent,
    PagesChipsAutocompleteComponent,
    TagsAutocompleteComponent,
    ChipsAutocompleteComponent,
    EkonAutocompleteComponent
  ],
  providers: [
    AutocompleteService,
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-autocompletion/',
      multi: true
    }
  ]
})
export class DkmAutocompletionModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconTimesCircle
    );
  }
}
