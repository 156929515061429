/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CalendarItem } from './calendarItem';
import { UserHeaderModel } from './userHeaderModel';
import { TaskStatus } from './taskStatus';


export interface TaskAction extends CalendarItem { 
    /**
     * Task completion date-time
     */
    isCompleted?: boolean;
    /**
     * Task due date-time
     */
    deadline?: Date | null;
    isOverdue?: boolean;
    percentComplete?: number;
    priority?: number;
    status?: TaskStatus;
}

