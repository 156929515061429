/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TaskAction } from './taskAction';
import { UserHeaderModel } from './userHeaderModel';
import { Event } from './event';


export interface CalendarItem { 
    kind: string;
    /**
     * Item identification
     */
    id?: string;
    /**
     * Gets the attendees
     */
    attendees?: Array<UserHeaderModel> | null;
    /**
     * List of Tags
     */
    tags?: Array<string> | null;
    /**
     * Gets or sets the description
     */
    description?: string | null;
    /**
     * Gets or sets the name.
     */
    name: string;
    organizer?: UserHeaderModel;
    /**
     * Gets or sets the start.
     */
    start: Date;
    /**
     * Gets or sets the end
     */
    end?: Date | null;
    /**
     * Cron requrence expression
     */
    cronRule?: string | null;
    /**
     * Gets or sets the location description
     */
    location?: string | null;
    /**
     * Exact location geopoint
     */
    locationPoint?: Array<number> | null;
    /**
     * Indicates private calendar item (visible only to owner)
     */
    isPrivate?: boolean;
    /**
     * Set Primary Color of the event (text color)
     */
    primaryColor?: string | null;
    /**
     * Related files
     */
    attachments?: Array<string> | null;
    /**
     * Related pages
     */
    pages?: Array<string> | null;
    /**
     * Related DKM domains
     */
    domains?: Array<string> | null;
}

