import { Directive, HostBinding, Input } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { isArray as _isArray } from 'lodash-es';

export type SidebarFloatedBreakpoint = /* 'xs' | 'sm' | 'md' | 'lg' | 'xl' */ 'lt-lg';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'ekon-sidebar[sbFloated]',
})
export class SbFloatedDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('sbFloated') floated:
    | boolean
    | ''
    | 'false'
    | 'true'
    | SidebarFloatedBreakpoint
    | SidebarFloatedBreakpoint[];

  @HostBinding('class.floated-position') get floatedPosition(): boolean {
    if (this.floated === false || this.floated === 'false') {
      return false;
    }

    if (
      this.floated === '' ||
      this.floated === true ||
      this.floated === 'true'
    ) {
      return true;
    }

    if (_isArray(this.floated)) {
      return (
        this.floated.filter(
          (bp: SidebarFloatedBreakpoint) =>
            this.media.isActive(bp) && this.media.isActive('gt-xs')
        ).length !== 0
      );
    } else {
      return this.media.isActive(this.floated) && this.media.isActive('gt-xs');
    }
  }

  constructor(private media: MediaObserver) {}
}
