/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Tenders Model, use to dispaly tenders
 */
export interface DltTenderModel { 
    id?: string;
    /**
     * DLT Tender title. Used in UI for listing DLT Tenders .
     */
    title?: string | null;
    /**
     * A short description added by the user ab this DLT Tender
     */
    description?: string | null;
    /**
     * User Id who generated the DLT Tender
     */
    userId?: string;
    /**
     * User\'s basket related legal entity
     */
    customerLegalEntityId?: string | null;
    /**
     * Tender Id, generated by the DLT Service
     */
    tenderId?: string;
    /**
     * Basket Id, from which tender has been created
     */
    basketId?: string;
    /**
     * The supplier Id legal entity.   Note, since supplier is a user, they can see the status that is relevant for them ONLY!!
     */
    supplierLegalEntityId?: string;
    /**
     * This provided by the DLT Service, when a supplier bids on a tender.
     */
    tenderBided?: boolean;
    tenderBidedTimeStamp?: Date | null;
    /**
     * This provided by the DLT Service, when a accepts the bids on a tender.
     */
    tenderBidAccepted?: boolean;
    tenderBidAcceptedTimeStamp?: Date | null;
    /**
     * This provided by the DLT Service, when a declined the bids on a tender.
     */
    tenderBidDeclined?: boolean;
    tenderBidDeclinedTimeStamp?: Date | null;
    createdBy?: string | null;
    createdAt?: Date;
    updatedBy?: string | null;
    lastUpdateAt?: Date;
}

