import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { EkonAuthService } from '@ekon-client/auth';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EkonAuthGuardService implements CanActivate, CanLoad {
  constructor(
    private authService: EkonAuthService,
    private router: Router
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.runGuard();
  }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.runGuard();
  }

  runGuard(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isLoggedIn$.pipe(
      take(1),
      map(auth => {
        if (!auth) {
          // sessionStorage.setItem('ek-nonAuthNavFailed', this.router.getCurrentNavigation().extractedUrl.toString());
        }

        return auth || this.router.createUrlTree(['landing'])
      })
    );
  }
}
