/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Sort { 
    /**
     * Field name to sortby
     */
    fieldName?: string | null;
    /**
     * Set Ascending or Descending  True == Ascending (A..Z, 0..9)  False == Descending (Z..A, 9..0)
     */
    order?: boolean;
}

