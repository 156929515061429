/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BoxplotValue } from './boxplotValue';
import { Element } from './element';
import { BoxplotGraphType } from './boxplotGraphType';


/**
 * Note: we represent the calculated value already calculated in this Boxplot element, and not the dataset.
 */
export interface BoxPlotGraphElement extends Element { 
    type?: BoxplotGraphType;
    /**
     * Ignore zero values, and donot provide graphic presentation
     */
    ignoreZeroInGraph?: boolean;
    /**
     * Future usage, get json data from external Url source, for sevral BoxPlots (array below)  Json data should be compatible to D3 Graph
     */
    dataSetArraySourceUrl?: string | null;
    /**
     * Allow an Array of Boxplots
     */
    value?: Array<BoxplotValue> | null;
}

