import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { EKON_LAYOUT_CONFIG } from './services';

@NgModule()
export class EkonLayoutUtilsModule {
  constructor(@Optional() @SkipSelf() parentModule: EkonLayoutUtilsModule) {
    if (parentModule) {
      throw new Error(
        'EkonLayoutUtilsModule is already loaded. Import it in the AppModule only!'
      );
    }
  }

  static forRoot(config): ModuleWithProviders<EkonLayoutUtilsModule> {
    return {
      ngModule: EkonLayoutUtilsModule,
      providers: [
        {
          provide: EKON_LAYOUT_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
