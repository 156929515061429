import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ElementRef, EventEmitter, Input, NgZone, Output, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {
  scaleLinear,
  select
} from 'd3';
import cloud from 'd3-cloud';
import { constant as _constant } from 'lodash-es';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  selector: 'ekon-wordcloud',
  template: ``,
  styles: [`
    ekon-wordcloud {
      display: block;
      min-height: 100%;
      position: relative;
    }

    ekon-wordcloud .word {
      cursor: pointer;
      transition: .25s ease;
    }

    ekon-wordcloud .word:hover {
      opacity: 1 !important;
      /*text-shadow: 0 0 2px cyan;*/
      /*font-size: 20px !important;*/
    }

    ekon-wordcloud svg {
      width: 100%;
      height: 100%;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WordcloudComponent implements AfterViewInit {

  @Input() fontMinSize = 10;
  @Input() fontMaxSize = 40;
  @Input() list: [string, number][];
  @Output() wordSelected = new EventEmitter<string>();

  resizeObserver: ResizeObserver;

  layout: any;

  constructor(
    private rootEl: ElementRef,
    private zone: NgZone
  ) {
  }

  ngAfterViewInit(): void {
    this.initWC();

    let timeout;

    this.resizeObserver = new ResizeObserver(() => {
      timeout && clearTimeout(timeout);

      timeout = setTimeout(() => {
        this.initWC();
      }, 1000);
    });

    this.resizeObserver.observe(this.rootEl.nativeElement);
  }

  initWC() {
    const wordCloudEl: HTMLElement = this.rootEl.nativeElement;
    const wordCloudElBoundings: DOMRect = wordCloudEl.getBoundingClientRect();

    wordCloudEl.innerHTML = '';

    if (Math.min(wordCloudElBoundings.width, wordCloudElBoundings.height) > 0 && this?.list?.length) {

      const weightFactor = Math.min(wordCloudElBoundings.width, wordCloudElBoundings.height) * 0.14;

      const counts = this.list.map(i => i[1]);

      const scale = scaleLinear()
        .domain([Math.min(...counts), Math.max(...counts)])
        .range([this.fontMinSize, Math.min(weightFactor, this.fontMaxSize)]);

      const scaleColorOpacity = scaleLinear()
        .domain([scale(Math.min(...counts)), scale(Math.max(...counts))])
        .range([0.3, 1]);


      const layout = cloud()
        .size([wordCloudElBoundings.width, wordCloudElBoundings.height])
        .words(this.list.map((d) => {
          return { text: d[0], size: d[1] };
        }))
        .padding(5)
        .rotate(_constant(0))
        .font(_constant('Roboto'))
        .fontStyle(_constant('bold'))
        .fontSize((d) => {
          return scale(d.size);
        });

      const draw = (words) => {
        select(wordCloudEl).append('svg')
          .attr('width', layout.size()[0])
          .attr('height', layout.size()[1])
          .append('g')
          .attr('transform', 'translate(' + layout.size()[0] / 2 + ',' + layout.size()[1] / 2 + ')')
          .selectAll('text')
          .data(words)
          .enter().append('text')
          .classed('word', true)
          .style('font-size', (d: cloud.Word) => {
            return d.size + 'px';
          })
          .style('font-family', (d: cloud.Word) => d.font)
          .style('opacity', (d: cloud.Word) => scaleColorOpacity(d.size).toString())
          .attr('text-anchor', 'middle')
          .attr('transform', (d: cloud.Word) => {
            return 'translate(' + [d.x, d.y] + ')';
          })
          .text((d: cloud.Word) => {
            return d.text;
          })
          .on('click', (_, d: cloud.Word) =>
            this.zone.run(() => this.wordSelected.emit(d.text))
          );
      };

      layout.on('end', draw);

      layout.start();

      this.layout = layout;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.list && !changes.list.firstChange && changes.list.currentValue) {
      this.initWC();
    }
  }
}
