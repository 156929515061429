import { ComponentType } from '@angular/cdk/overlay';
import { Directive, OnDestroy, ViewContainerRef } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { EkonDialogHolderService } from '../../services';
import { getModalDefaultConfig } from '../../utilities';


@Directive()
export abstract class EkonDialogHolderComponent<C, D, R> implements OnDestroy {
  dialogConfig: MatDialogConfig = {};

  protected dialogRef: MatDialogRef<C, R>;
  protected unsubscribeAll: Subject<void> = new Subject();

  protected abstract dialogComponent: ComponentType<C>;

  protected constructor(
    protected dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    protected holderService?: EkonDialogHolderService,
    protected _vcr?: ViewContainerRef,
  ) {
  }

  ngOnDestroy(): void {
    this.dialogRef && this.dialogRef.close();
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  openDialogRAW(dialogData: D): void {
    this.dialogRef = this.dialog.open(this.dialogComponent, {
      ...getModalDefaultConfig(),
      ...this.dialogConfig,
      viewContainerRef: this._vcr,
      data: dialogData,
      disableClose: true
    });
  }

  openDialog(dialogData: D, disableDefaultFinalize?: boolean): Observable<R> {
    this.dialogRef = this.dialog.open(this.dialogComponent, {
      ...getModalDefaultConfig(),
      ...this.dialogConfig,
      data: dialogData
    });
    return this.dialogRef.afterClosed().pipe(
      finalize(
        () => !disableDefaultFinalize
          && (this.router.navigate(['../'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
          }))
      ),
      takeUntil(this.unsubscribeAll)
    );
  }
}
