import { InjectionToken } from '@angular/core';
import { DkmDomain } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const DOMAIN_ACTIONS = new InjectionToken('Domain model actions');
export const DOMAIN_EVENTS = new InjectionToken('Domain model events');

export interface DomainEventsServiceInterface {
  domainCreated: Observable<DkmDomain>;
  domainUpdated: Observable<string>;
  domainDeleted: Observable<string>;
  domainMembershipConfirmed: Observable<string>;
  domainMembershipDeclined: Observable<string>;
  domainMembershipLeft: Observable<string>;
}
