import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { DigitalPresence } from '@ekon-client/dkm-api';

import { ReusableFormBase } from '../../ReusableFormBase';

@Component({
  selector: 'dkm-core-digital-presence-form',
  templateUrl: './digital-presence-form.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DigitalPresenceFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DigitalPresenceFormComponent),
      multi: true,
    },
  ],
})
export class DigitalPresenceFormComponent extends ReusableFormBase<
  DigitalPresence
> {
  form: FormGroup = new FormGroup({
    name: new FormControl(null),
    shortDescription: new FormControl(null),
    url: new FormControl(null),
  });

  constructor() {
    super();
    this.initValueChanges();
  }

  validate(/*_control: AbstractControl*/): ValidationErrors | null {
    return ReusableFormBase.validate(this.form);
  }

  makeOutputValue(data: DigitalPresence): DigitalPresence | null {
    return ReusableFormBase.objToValueOrNull(data);
  }
}
