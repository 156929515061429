import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CalendarItem,
  CalendarItemHeaderPagedResult,
  CalendarService,
  CalendarServiceInterface,
  Configuration, PagePaginationModel, UserHeaderModel
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CalendarEventsServiceInterface } from './CalendarEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class CalendarEventsService
  implements CalendarServiceInterface, CalendarEventsServiceInterface {
  get configuration(): Configuration {
    return this.calendarService.configuration;
  }

  set configuration(val: Configuration) {
    this.calendarService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.calendarService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.calendarService.defaultHeaders = val;
  }

  constructor(
    private calendarService: CalendarService,
    private progressBar: EkonProgressBarService
  ) {
  }

  /**
   * The mechanism for monitoring Create action
   */
  private calendarItemCreatedSubject: Subject<CalendarItem> = new Subject();

  get calendarItemCreated(): Observable<CalendarItem> {
    return this.calendarItemCreatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Deleted action
   */
  private calendarItemDeletedSubject: Subject<string> = new Subject();

  get calendarItemDeleted(): Observable<string> {
    return this.calendarItemDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Updated action
   */
  private calendarItemUpdatedSubject: Subject<string> = new Subject();

  get calendarItemUpdated(): Observable<string> {
    return this.calendarItemUpdatedSubject.asObservable();
  }

  /**
   * Creating new Calendar Item
   *
   * @param calendarItem
   * @param domains
   */
  createCalendarItem(
    calendarItem?: CalendarItem,
    domains?: string
  ): Observable<CalendarItem> {
    return this.progressBar
      .apply(this.calendarService.createCalendarItem(calendarItem, domains))
      .pipe(
        tap((item: CalendarItem) => this.calendarItemCreatedSubject.next(item))
      );
  }

  /**
   * Deleting Calendar Item
   *
   * @param id
   */
  deleteCalendarItem(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.calendarService.deleteCalendarItem(id))
      .pipe(tap(() => this.calendarItemDeletedSubject.next(id)));
  }

  /**
   * Getting Calendar Item by ID
   *
   * @param id
   */
  findCalendarItemById(id: string): Observable<CalendarItem> {
    return this.progressBar.apply(
      this.calendarService.findCalendarItemById(id)
    );
  }

  /**
   * Getting a list of Calendar Items
   *
   * @param pagination
   * @param kind
   * @param isPrivate
   * @param from
   * @param to
   * @param domainId
   */
  getCalendar(pagination: PagePaginationModel, kind?: string, isPrivate?: boolean, from?: Date, to?: Date, domainId?: string): Observable<CalendarItemHeaderPagedResult> {
    return this.progressBar.apply(
      this.calendarService.getCalendar(pagination, kind, isPrivate, from, to, domainId)
    );
  }

  /**
   * Getting list of Calendar Items Types
   *
   */
  listCalendarItemsTypes(): Observable<Array<string>> {
    return this.progressBar.apply(
      this.calendarService.listCalendarItemsTypes()
    );
  }

  /**
   * Updating Calendar Item
   *
   * @param calendarItem
   * @param domains
   */
  updateCalendarItem(
    calendarItem?: CalendarItem,
    domains?: string
  ): Observable<unknown> {
    // this.progressBar.applyodo: move that to the core) calendar service
    // if (!calendarItem.domains) {
    //   calendarItem.domains = this.domainSelectorService.domainsSelected.map(domain => domain.id);
    // }

    return this.calendarService
      .updateCalendarItem(calendarItem, domains)
      .pipe(tap(() => this.calendarItemUpdatedSubject.next(calendarItem.id)));
  }

  getAttendees(): Observable<UserHeaderModel[]> {
    return this.progressBar.apply(this.calendarService.getAttendees());
  }

  copyNoteToTask(noteId: string): Observable<CalendarItem> {
    return this.progressBar.apply(this.calendarService.copyNoteToTask(noteId))
      .pipe(
        tap((item: CalendarItem) => this.calendarItemCreatedSubject.next(item))
      );
  }

}
