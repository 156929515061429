/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { BasketDetails } from '../model/models';
import { BasketHeaderPagedResult } from '../model/models';
import { BasketHeaderWithProductCountPagedResult } from '../model/models';
import { CreateBasket } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';
import { ProductsGoodsDetails } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface BasketServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param productId 
     * @param productsGoodsDetails 
     * @param xSelectedDomain Comma-delimited domain id
     */
    addProductToBasket(id: string, productId: string, productsGoodsDetails?: ProductsGoodsDetails, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createBasket 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createBasket(createBasket?: CreateBasket, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<BasketDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteBasket(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Provide human readable Basket document
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    downladBasketDocument(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Duplicate a basket.  Set the duplicated basket as open basket.
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    duplicateBasket(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<BasketDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findBasketById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<BasketDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param isOpen 
     * @param legalEntityId 
     * @param from 
     * @param to 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listBaskets(pagePaginationModel?: PagePaginationModel, isOpen?: boolean, legalEntityId?: string, from?: Date, to?: Date, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<BasketHeaderPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param isOpen 
     * @param legalEntityId 
     * @param productPageId 
     * @param from 
     * @param to 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listBasketsWithProductPageCount(pagePaginationModel?: PagePaginationModel, isOpen?: boolean, legalEntityId?: string, productPageId?: string, from?: Date, to?: Date, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<BasketHeaderWithProductCountPagedResult>;

    /**
     * Submit and close basket.  Send request notification to suppliers
     * 
     * @param id 
     * @param shallSubmit 
     * @param xSelectedDomain Comma-delimited domain id
     */
    submitAndCloseBasket(id: string, shallSubmit?: boolean, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param basketDetails 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateBasket(id: string, basketDetails?: BasketDetails, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param productId 
     * @param quantity 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateBasketItemQuantity(id: string, productId: string, quantity: number, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

}
