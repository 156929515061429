<mat-list>
  <h3 matSubheader fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <ekon-icon icon="sticky-note" class="mr-4"></ekon-icon>
      <span>{{'NOTES' | translate}}</span>
    </div>

    <div>
      <mat-slide-toggle [checked]="showNotes" (change)="showNotes = $event.checked"></mat-slide-toggle>
    </div>
  </h3>

  <div *ngIf="showNotes" class="note-list">
    <ekon-top-list
      [config]="notesPaginatorConfig"
      (viewAll)="exploreNotes()"
      [viewAllBtnAlwaysDisplay]="true"
      #topList="ekonTopList"
    >
      <mat-list-item *ngFor="let note of topList.items$ | async; trackBy: trackById"
                     class="mb-16 note-list-item"
                     (click)="editNote(note)">
        <!--      <div mat-line class="h3 text-truncate">{{event.name}}</div>-->
        <small mat-line class="secondary-text accent-100-fg text-truncate">{{note.value}}
          <ekon-icon icon="sticky-note" class="mr-4"></ekon-icon>
        </small>
      </mat-list-item>
    </ekon-top-list>
  </div>
</mat-list>
