import { EkonLayoutConfig } from '@ekon-layout-utils';


export const ekonLayoutConfig: EkonLayoutConfig = {
  customScrollbars: true,
  layout: {
    // style    : localStorage.getItem('layoutStyle') || 'vertical-layout-3',
    style: 'vertical-layout-3',
    width: 'fullwidth',
    navbar: {
      primaryBackground: 'dkm-black-700',
      secondaryBackground: 'dkm-black-700',
      folded: false,
      hidden: false,
      // TODO: do not need
      position: 'left',
      variant: 'vertical-style-1',
    },
    toolbar: {
      customBackgroundColor: false,
      background: 'dkm-white-500',
      hidden: false,
      position: 'above-fixed',
    },
    footer: {
      customBackgroundColor: true,
      background: 'dkm-black-700',
      hidden: false,
      position: 'above-fixed',
    },
    sidepanel: {
      hidden: false,
      position: 'right',
    },
  },
};
