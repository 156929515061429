<div id="main">

  <toolbar></toolbar>

  <div id="container-1" class="container">

    <div id="container-2" class="container">
      <div
        class="outlet-content"
        [ngClass]="{ maximized: !(sidebarService.get('dashboard-time-bar') | async)?.visible }"
      >
        <router-outlet></router-outlet>
      </div>

      <ekon-sidebar
        position="right"
        key="dashboard-time-bar"
        [visible]="true"
        *ngIf="user | async"
        class="sidebar-right"
      >
        <div class="top-bar">
          <h3></h3>
          <button
            mat-icon-button
            (click)="toggleSidebarStatus('dashboard-time-bar')"
          >
            <ekon-icon
              [icon]="(sidebarService.get('dashboard-left-bar') | async)?.visible
                ? 'arrow-double-left' : 'arrow-double-right'"
              size="lg"
            ></ekon-icon>
          </button>
        </div>

        <!-- PLATFORM NOTES -->
        <mat-card
          *ngIf="(globalConfig.enablePlatformNotes$ | async)
           && (permissions.hasPermission('NOTES', 'read') | async)
            && (showPlatformNotes$ | async)"
        >
          <h3>
            <div>
              <ekon-icon [icon]="ekIconInfo"></ekon-icon>
              <span class="ml-8">{{'SYSTEM_NOTES' | translate: { default: 'System Notes' } }}</span>
            </div>
          </h3>

          <ekon-slider
            position="bellow"
            class="items-slider"
          >
            <ekon-top-list
              [config]="platformNotesPaginatorConfig"
              [viewAllBtnAlwaysHidden]="true"
              #platformNotesTopList="ekonTopList"
            >
              <div class="slider-list">
                <div
                  *ngFor="let note of platformNotesTopList.items$ | async; trackBy: trackNotesById"
                  class="mb-16 slider-list-item"
                  [style.borderInlineStartColor]="note?.color || 'transparent'"
                >
                  <p class="mat-body-1 mb-24"
                     [ekonHandleAppLinks]="note.value"
                     [ekonHandleAppLinksPropagation]="false"
                     [innerHTML]="note.value"></p>
                  <a
                    class="ext-link"
                    *ngIf="note?.externalLink as externalLink"
                    [href]="externalLink"
                    target="_blank"
                    [ekonHandleAppLinks]="externalLink"
                    [ekonHandleAppLinksPropagation]="false"
                    (click)="$event.stopPropagation()"
                  >{{externalLink}}</a>
                  <small
                    class="secondary-text text-truncate">{{note.reminderDateTime | date : 'dd MMM yyyy, hh:mm'}}</small>
                </div>
              </div>
            </ekon-top-list>
          </ekon-slider>

          <!--<ekon-filter-badge></ekon-filter-badge>-->
        </mat-card>

        <!-- TagsCloud -->
        <mat-card
          *ngIf="globalConfig.enableTagCloud && (permissions.hasPermission('DASHBOARD', 'read') | async)"
        >
          <h3>
            <a (click)="magnifyWordCloud()">
              {{'POPULAR_KEYWORDS' | translate: { default: 'Popular keywords' } }}
            </a>
            <a (click)="magnifyWordCloud()">
              <!--<span>Magnify</span>-->
              <ekon-icon icon="expand-arrows-alt" class="ml-8"></ekon-icon>
            </a>
          </h3>
          <ekon-wordcloud
            class="tag-cloud-min"
            *ngIf="wordCloud$ | async as list"
            [list]="list"
            (wordSelected)="searchByTag($event)"
          ></ekon-wordcloud>

          <ekon-filter-badge></ekon-filter-badge>
        </mat-card>
        <!-- NAVIGATION HISTORY -->
        <mat-accordion>
          <mat-expansion-panel hideToggle class="mb-16">
            <mat-expansion-panel-header>
              <div>
                <ekon-icon [icon]="ekIconClock"></ekon-icon>
                <span class="ml-8">{{'RECENT' | translate }}</span>
              </div>
            </mat-expansion-panel-header>
            <div>
              <div
                *ngFor="let hi of navHistory.history$ | async"
                class="mb-8"
              >
                <a [routerLink]="hi.path">
                  <ekon-icon
                    [icon]="hi.kind === 'domain' ? ekIconStores : (hi.kind === 'calendar' ? ekIconCalendarEvent : (hi.kind === 'product' ? ekIconShoppingBag : ekIconPages))"
                    class="mr-4"
                  ></ekon-icon>
                  <span>{{hi.title}}</span>
                </a>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- NEWS & UPDATES -->
        <!--<mat-card>
          <h3>News & updates</h3>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam vel quam ac consequat. Fusce
            imperdiet lectus ut turpis commodo, ut tempus nibh eleifend. Praesent nulla diam, accumsan sit amet velit a,
            finibus bibendum metus. In hac habitasse platea dictumst. Suspendisse eros odio...
          </div>
        </mat-card>-->

        <!-- EVENTS -->
        <mat-card
          *ngIf="globalConfig.enableMeetups && permissions.hasPermission('CALENDAR', 'read') | async"
        >
          <h3>
            <a (click)="goToEvents()">
              <ekon-icon icon="calendar-event"></ekon-icon>
              <span class="ml-8">{{'EVENTS' | translate: { default: 'Events' } }}</span>
            </a>
            <a
              *ngIf="permissions.hasPermission('CALENDAR', 'create') | async"
              routerLink="/calendar/create/Event"
            >
              <span>{{'ADD' | translate: { default: 'Add' } }}</span>
              <ekon-icon icon="plus" class="ml-8"></ekon-icon>
            </a>
          </h3>
          <ekon-top-list
            [config]="eventsTopConfig"
            [viewAllBtnAlwaysHidden]="true"
            (viewAll)="goToEvents()"
            #eventsTopList="ekonTopList"
          >
            <ekon-slider
              position="bellow"
              class="items-slider"
            >
              <dkm-calendar-item-list
                [tasks]="eventsTopList.items$ | async"
                [viewOnly]="true"
                [minimized]="true"
                [horizontal]="true"
                (view)="viewEvent($event)"
              ></dkm-calendar-item-list>
            </ekon-slider>
          </ekon-top-list>
        </mat-card>

        <!-- TASKS -->
        <mat-card
          *ngIf="globalConfig.enableTasks && permissions.hasPermission('TASKS', 'read') | async"
        >
          <h3>
            <a (click)="goToTasks()">
              <ekon-icon icon="task-outlined"></ekon-icon>
              <span class="ml-8">{{'TASKS' | translate: { default: 'Tasks' } }}</span>
            </a>
            <a
              *ngIf="permissions.hasPermission('TASKS', 'create') | async"
              routerLink="/calendar/create/TaskAction"
            >
              <span>{{'ADD' | translate: { default: 'Add' } }}</span>
              <ekon-icon icon="plus" class="ml-8"></ekon-icon>
            </a>
          </h3>
          <ekon-top-list
            [config]="tasksTopConfig"
            [viewAllBtnAlwaysHidden]="true"
            (viewAll)="goToTasks()"
            #tasksTopList="ekonTopList"
          >
            <ekon-slider
              position="bellow"
              class="items-slider"
            >
              <dkm-calendar-item-list
                [tasks]="tasksTopList.items$ | async"
                [viewOnly]="true"
                [minimized]="true"
                [horizontal]="true"
                (view)="viewTask($event)"
              ></dkm-calendar-item-list>
            </ekon-slider>
          </ekon-top-list>
        </mat-card>

        <!-- BLOGS -->
        <mat-card
          *ngIf="globalConfig.enableBlogs && permissions.hasPermission('BLOGS', 'read') | async"
        >
          <h3>
            <a routerLink="my-space/blogs">
              <ekon-icon icon="blogs" [directionSensitive]="false"></ekon-icon>
              <span class="ml-8">{{'BLOGS' | translate: { default: 'Blogs' } }}</span>
            </a>
            <!--            <a
                          *ngIf="permissions.hasPermission('BLOGS', 'create') | async"
                          (click)="addBlog()"
                        >
                          <span>{{'ADD' | translate: { default: 'Add' } }}</span>
                          <ekon-icon icon="plus" class="ml-8"></ekon-icon>
                        </a>-->
          </h3>

          <ekon-slider
            position="bellow"
            class="items-slider"
          >
            <ekon-top-list
              [config]="blogsTopConfig"
              [viewAllBtnAlwaysHidden]="true"
              #blogList
            >
              <div class="slider-list">
                <div
                  *ngFor="let blog of blogList.items$ | async; trackBy: trackBlogsBy; let i = index"
                  class="slider-list-item"
                  [routerLink]="['/blogs', blog.id]"
                >
                  <div class="text-truncate">{{blog.title}}</div>
                  <div class="date mat-small grey-500-fg my-4">{{blog.publishedAt | date: 'dd MMM yyyy, hh:mm'}}</div>
                </div>
              </div>
            </ekon-top-list>

          </ekon-slider>
        </mat-card>

        <!-- RSS -->
        <!--<mat-card *ngIf="globalConfig.enableRSS">
          <h3>
            <a routerLink="/rss">
              <fa-icon [icon]="['fas', 'sticky-note']"></fa-icon>
              <span class="ml-8">RSS</span>
            </a>
          </h3>

          <ekon-slider
            position="bellow"
            class="items-slider"
          >
            <div class="slider-list">
              <div
                *ngFor="let rss of rssList$ | async; trackBy: trackRssById"
                class="mb-16 slider-list-item"
                (click)="readRss(rss)"
              >
                <div>{{rss.name}}</div>
                <small class="secondary-text text-truncate">{{rss.description}}</small>
              </div>
            </div>
          </ekon-slider>
        </mat-card>-->

        <!-- NOTES -->
        <mat-card
          *ngIf="(globalConfig.enableNotes$ | async) && permissions.hasPermission('NOTES', 'read') | async"
        >
          <h3>
            <a routerLink="my-space/notes">
              <ekon-icon icon="sticky-note"></ekon-icon>
              <span class="ml-8">{{'NOTES' | translate: { default: 'Notes' } }}</span>
            </a>
            <a
              *ngIf="permissions.hasPermission('NOTES', 'create') | async"
              (click)="addNote()"
            >
              <span>{{'ADD' | translate: { default: 'Add' } }}</span>
              <ekon-icon icon="plus" class="ml-8"></ekon-icon>
            </a>
          </h3>

          <ekon-slider
            position="bellow"
            class="items-slider"
          >
            <ekon-top-list
              [config]="notesPaginatorConfig"
              [viewAllBtnAlwaysHidden]="true"
              (viewAll)="goToNotes()"
              #notesTopList="ekonTopList"
            >
              <div class="slider-list">
                <div
                  *ngFor="let note of notesTopList.items$ | async; trackBy: trackNotesById"
                  class="mb-16 slider-list-item"
                  [style.borderInlineStartColor]="note?.color || 'transparent'"
                  (click)="editNote(note)"
                >
                  <div
                    [ekonHandleAppLinks]="note.value"
                    [ekonHandleAppLinksPropagation]="false"
                    [innerHTML]="note.value"
                  ></div>
                  <a

                    class="ext-link"
                    *ngIf="note?.externalLink as externalLink"
                    [href]="externalLink"
                    target="_blank"
                    [ekonHandleAppLinks]="externalLink"
                    [ekonHandleAppLinksPropagation]="false"
                    (click)="$event.stopPropagation()"
                  >{{externalLink}}</a>
                  <small
                    class="secondary-text text-truncate">{{note.reminderDateTime | date : 'dd MMM yyyy, hh:mm'}}</small>
                </div>
              </div>
            </ekon-top-list>
          </ekon-slider>
        </mat-card>

        <!-- MAP -->
        <mat-card
          *ngIf="globalConfig.enableMap
            && permissions.hasPermission('MAPS', 'read') | async"
        >
          <h3>
            <a routerLink="map">
              <ekon-icon icon="map-marked-alt"></ekon-icon>
              <span class="ml-8">{{'MAP_VIEW' | translate: { default: 'Map view' } }}</span>
            </a>
          </h3>
          <div
            dkmMap
            [mouseWheelDisabled]="true"
            [controlsDisabled]="true"
            [interactionsDisabled]="true"
            class="map-view  h-148"
          ></div>
        </mat-card>



        <!-- SETTINGS -->
        <mat-card
          *ngIf="this.globalConfig.chatbotEnabled"
        >
          <dkm-chatbot-settings></dkm-chatbot-settings>
        </mat-card>
      </ekon-sidebar>
    </div>

  </div>

  <footer></footer>
</div>

<!-- MOBILE NAVIGATION -->
<ekon-sidebar
  *ngIf="(isMobile$ | async) && user | async"
  position="right"
  key="mobileNav"
  fixed="true"
>
  <ekon-mobile-nav></ekon-mobile-nav>
</ekon-sidebar>


<ng-template #tagCloudDialog>
  <ekon-dialog-wrapper (closeDialog)="closeTagCloud()">
    <h3 mat-dialog-title>{{'POPULAR_KEYWORDS' | translate: { default: 'Popular keywords' } }}</h3>
    <mat-dialog-content class="tag-cloud-dialog-content">
      <ekon-wordcloud
        class="tag-cloud-max"
        *ngIf="wordCloud$ | async as list"
        [list]="list"
        [fontMinSize]="14"
        (wordSelected)="closeTagCloud($event)"
      ></ekon-wordcloud>
    </mat-dialog-content>
  </ekon-dialog-wrapper>
</ng-template>
