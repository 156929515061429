<ng-container
  *ngIf="position === 'aside'"
  [ngTemplateOutlet]="leftBtn"
></ng-container>
<div
  class="content"
  #content
>
  <ng-content></ng-content>
</div>
<ng-container
  *ngIf="position === 'aside'"
  [ngTemplateOutlet]="rightBtn"
></ng-container>

<div
  class="bellow-btns"
  *ngIf="position === 'bellow'"
>
  <ng-container
    [ngTemplateOutlet]="leftBtn"
  ></ng-container>
  <ng-container
    [ngTemplateOutlet]="rightBtn"
  ></ng-container>
</div>

<ng-template #leftBtn>
  <div
    matRipple
    class="nav-btn left"
    (click)="previous()"
  >
    <ekon-icon icon="angle-left"></ekon-icon>
  </div>
</ng-template>

<ng-template #rightBtn>
  <div
    matRipple
    class="nav-btn right"
    (click)="next()"
  >
    <ekon-icon icon="angle-right"></ekon-icon>
  </div>
</ng-template>
