import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { TourService } from 'ngx-ui-tour-md-menu';
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';
import { Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

// export const TOURS_USER_PROFILE_REF = new InjectionToken('User Profile Service Ref');


interface TourStep extends IMdStepOption {
  content: string;
  contentInterpolateTranslationParams?: Record<string, unknown>;
  title: string;
  titleInterpolateTranslationParams?: Record<string, unknown>;
}

interface TourGroup {
  key: string;
  title: string;
  route?: string;
  nextGroupKey?: string;
  steps: TourStep[];
}

@Injectable({
  providedIn: 'root'
})
export class EkonTourService {

  private tourGroups: TourGroup[] = [
    {
      key: 'main',
      title: 'TOUR.MAIN.TITLE',
      nextGroupKey: 'user_area',
      steps: [
        {
          anchorId: 'main.toolbar.home',
          title: 'TOUR.MAIN.TOOLBAR.HOME.TITLE',
          content: 'TOUR.MAIN.TOOLBAR.HOME.CONTENT',
          route: '/dashboard',
          // isAsync: true,
          isOptional: true
        },
        {
          anchorId: 'main.toolbar.domains',
          title: 'TOUR.MAIN.TOOLBAR.DOMAINS.TITLE',
          content: 'TOUR.MAIN.TOOLBAR.DOMAINS.CONTENT',
          route: '/domains',
          isOptional: true
        },
        {
          anchorId: 'main.toolbar.pages',
          title: 'TOUR.MAIN.TOOLBAR.PAGES.TITLE',
          content: 'TOUR.MAIN.TOOLBAR.PAGES.CONTENT',
          route: '/pages',
          isOptional: true
        },
        {
          anchorId: 'main.toolbar.my-space',
          title: 'TOUR.MAIN.TOOLBAR.MY_SPACE.TITLE',
          content: 'TOUR.MAIN.TOOLBAR.MY_SPACE.CONTENT',
          route: '/my-space/feed',
          isOptional: true
        },
        {
          anchorId: 'main.toolbar.profile-menu',
          title: 'TOUR.MAIN.TOOLBAR.PROFILE_MENU.TITLE',
          content: 'TOUR.MAIN.TOOLBAR.PROFILE_MENU.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'main.toolbar.logo',
          title: 'TOUR.MAIN.TOOLBAR.LOGO.TITLE',
          content: 'TOUR.MAIN.TOOLBAR.LOGO.CONTENT',
          route: '/dashboard',
          isOptional: true
        },
        {
          anchorId: 'main.toolbar.spaces_select',
          title: 'TOUR.MAIN.TOOLBAR.SPACES_SELECT.TITLE',
          content: 'TOUR.MAIN.TOOLBAR.SPACES_SELECT.CONTENT',
          isOptional: true
        }
      ]
    },
    {
      key: 'user_area',
      title: 'User area',
      steps: [
        {
          anchorId: 'user_area.about',
          title: 'TOUR.USER_AREA.PROFILE.ABOUT.TITLE',
          content: 'TOUR.USER_AREA.PROFILE.ABOUT.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'user_area.avatar',
          title: 'TOUR.USER_AREA.PROFILE.AVATAR.TITLE',
          content: 'TOUR.USER_AREA.PROFILE.AVATAR.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'user_area.header',
          title: 'TOUR.USER_AREA.PROFILE.HEADER.TITLE',
          content: 'TOUR.USER_AREA.PROFILE.HEADER.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'user_area.edit',
          title: 'TOUR.USER_AREA.PROFILE.EDIT.TITLE',
          content: 'TOUR.USER_AREA.PROFILE.EDIT.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'user_area.editmenu',
          title: 'TOUR.USER_AREA.PROFILE.EDITMENU.TITLE',
          content: 'TOUR.USER_AREA.PROFILE.EDITMENU.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'user_area.general',
          title: 'TOUR.USER_AREA.PROFILE.GENERAL.TITLE',
          content: 'TOUR.USER_AREA.PROFILE.GENERAL.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'user_area.tags',
          title: 'TOUR.USER_AREA.PROFILE.TAGS.TITLE',
          content: 'TOUR.USER_AREA.PROFILE.TAGS.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'user_area.tags.pages',
          title: 'TOUR.USER_AREA.PROFILE.TAG_PAGES.TITLE',
          content: 'TOUR.USER_AREA.PROFILE.TAG_PAGES.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'user_area.user.pages',
          title: 'TOUR.USER_AREA.PROFILE.USER_PAGES.TITLE',
          content: 'TOUR.USER_AREA.PROFILE.USER_PAGES.CONTENT',
          isOptional: true
        }
      ]
    },
    {
      key: 'filter_search',
      title: 'Filter and search',
      steps: [
        {
          anchorId: 'filter.combine',
          title: 'TOUR.SEARCH_FILTER.COMPONENT.COMBINE.TITLE',
          content: 'TOUR.SEARCH_FILTER.COMPONENT.COMBINE.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'filter.search',
          title: 'TOUR.SEARCH_FILTER.COMPONENT.SEARCH.TITLE',
          content: 'TOUR.SEARCH_FILTER.COMPONENT.SEARCH.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'filter.field',
          title: 'TOUR.SEARCH_FILTER.COMPONENT.FIELD.TITLE',
          content: 'TOUR.SEARCH_FILTER.COMPONENT.FIELD.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'filter.sort',
          title: 'TOUR.SEARCH_FILTER.COMPONENT.SORT.TITLE',
          content: 'TOUR.SEARCH_FILTER.COMPONENT.SORT.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'filter.hide',
          title: 'TOUR.SEARCH_FILTER.COMPONENT.HIDE.TITLE',
          content: 'TOUR.SEARCH_FILTER.COMPONENT.HIDE.CONTENT',
          isOptional: true
        },
/*        {
          anchorId: 'filter.switch',
          title: 'TOUR.SEARCH_FILTER.COMPONENT.SWITCH.TITLE',
          content: 'TOUR.SEARCH_FILTER.COMPONENT.SWITCH.CONTENT',
          isOptional: true
        }*/
        {
          anchorId: 'filter.tags',
          title: 'TOUR.SEARCH_FILTER.COMPONENT.TAGS.TITLE',
          content: 'TOUR.SEARCH_FILTER.COMPONENT.TAGS.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'filter.and',
          title: 'TOUR.SEARCH_FILTER.COMPONENT.AND.TITLE',
          content: 'TOUR.SEARCH_FILTER.COMPONENT.AND.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'filter.close',
          title: 'TOUR.SEARCH_FILTER.COMPONENT.CLOSE.TITLE',
          content: 'TOUR.SEARCH_FILTER.COMPONENT.CLOSE.CONTENT',
          isOptional: true
        }
      ]
    },
    {
      key: 'view_page',
      title: 'Page structure',
      steps: [
        {
          anchorId: 'view_page.about',
          title: 'TOUR.VIEW_PAGE.PAGE.ABOUT.TITLE',
          content: 'TOUR.VIEW_PAGE.PAGE.ABOUT.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'view_page.owners',
          title: 'TOUR.VIEW_PAGE.PAGE.OWNERS.TITLE',
          content: 'TOUR.VIEW_PAGE.PAGE.OWNERS.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'view_page.rate',
          title: 'TOUR.VIEW_PAGE.PAGE.RATE.TITLE',
          content: 'TOUR.VIEW_PAGE.PAGE.RATE.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'view_page.comment',
          title: 'TOUR.VIEW_PAGE.PAGE.COMMENT.TITLE',
          content: 'TOUR.VIEW_PAGE.PAGE.COMMENT.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'view_page.share',
          title: 'TOUR.VIEW_PAGE.PAGE.SHARE.TITLE',
          content: 'TOUR.VIEW_PAGE.PAGE.SHARE.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'view_page.build',
          title: 'TOUR.VIEW_PAGE.PAGE.BUILD.TITLE',
          content: 'TOUR.VIEW_PAGE.PAGE.BUILD.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'view_page.expand',
          title: 'TOUR.VIEW_PAGE.PAGE.EXPAND.TITLE',
          content: 'TOUR.VIEW_PAGE.PAGE.EXPAND.CONTENT',
          isOptional: true
        }
      ]
    },
    {
      key: 'my_spaces_tab',
      title: 'My Spaces',
      route: '/my-space/domains',
      steps: [
        {
          anchorId: 'domains.manager.my.spaces',
          title: 'TOUR.MY_SPACES.TABS.MY_SPACES.TITLE',
          content: 'TOUR.MY_SPACES.TABS.MY_SPACES.CONTENT',
          isOptional: true
        }
      ]
    },
    {
      key: 'domain-manager',
      title: 'My Spaces',
      route: '/my-space/domains',
      steps: [
        {
          anchorId: 'domains.manager.about',
          title: 'TOUR.MY_SPACES.SPACES.ABOUT.TITLE',
          content: 'TOUR.MY_SPACES.SPACES.ABOUT.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'domains.manager.create-btn',
          title: 'TOUR.MY_SPACES.SPACES.CREATE.TITLE',
          content: 'TOUR.MY_SPACES.SPACES.CREATE.CONTENT',
          isOptional: true
        },
        {
          anchorId: 'domains.manager.1',
          title: 'TOUR.MY_SPACES.SPACES.CARD.TITLE',
          content: 'TOUR.MY_SPACES.SPACES.CARD.CONTENT',
          isAsync: true
        },
      ]
    }
  ];

  private _tourEnd$: Subject<TourGroup> = new Subject();

  constructor(
    private tourService: TourService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this._tourEnd$.asObservable().pipe(
      switchMap((tour: TourGroup) => {
        const nextGroup = this.tourGroups.find(g => g.key === tour.nextGroupKey);

        const snack = this.snackBar.open(`You completed "${tour.title}"`, nextGroup?.title, {
          duration: 10000
          // panelClass: ['message-box', !type ? EkonMessageTypes.INFO : type],
        });

        return snack.onAction().pipe(map(() => tour));
      })
    ).subscribe({
      next: (tour: TourGroup) => {
        this.start(tour.nextGroupKey);
      }
    });
  }

  start(key?: string): void {
    const tour: TourGroup = (
      key
      ? this.tourGroups.find(g => g.key === key)
      : this.tourGroups.find(g => g.key === 'main')
    ) as TourGroup;

    this.tourService.initialize(
      this.applyTranslations(tour.steps),
      {
        delayAfterNavigation: 1000,
        enableBackdrop: true
      }
    );

    this.tourService.end$.asObservable().pipe(
      take(1)
    ).subscribe({
      next: () => {
        this._tourEnd$.next(tour);
      }
    });
    this.tourService.start();
  }

  private applyTranslations(steps: TourStep[]): TourStep[] {
    return steps.map(step => ({
      ...step,
      content: this.translate.instant(step.content, step.contentInterpolateTranslationParams),
      title: this.translate.instant(step.title, step.titleInterpolateTranslationParams)
    }));
  }
}
