import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export interface SidebarMeta {
  key: string;
  visible?: boolean;
  initialVisibility?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private _sidebarList: Map<string, BehaviorSubject<SidebarMeta>> = new Map();
  // private _temporaryVisibilityState: Map<string, BehaviorSubject<boolean | null>> = new Map();

  register(sidebar: SidebarMeta): Observable<SidebarMeta> {
    this._sidebarList.set(sidebar.key, new BehaviorSubject(sidebar));
    return this.get(sidebar.key);
  }

  unregister(key: string): void {
    this._sidebarList.delete(key);
  }

  get(key: string): Observable<SidebarMeta> {
    const sidebar = this._sidebarList.get(key);
    return sidebar ? sidebar.asObservable() : undefined;
  }

  show(key: string): void {
    const sidebarSubject$ = this._sidebarList.get(key);

    sidebarSubject$?.pipe(take(1)).subscribe({
      next: (meta: SidebarMeta) => {
        sidebarSubject$.next({ ...meta, visible: true });

        // console.log(document.getElementById('container-1'));
        // document.getElementById('container-1').style.zIndex = '9999';
      },
    });
  }

  hide(key: string): void {
    const sidebarSubject$ = this._sidebarList.get(key);

    sidebarSubject$?.pipe(take(1)).subscribe({
      next: (meta: SidebarMeta) =>
        sidebarSubject$.next({ ...meta, visible: false }),
    });
  }

  toggle(key: string): void {
    const sidebarSubject$ = this._sidebarList.get(key);

    sidebarSubject$?.pipe(take(1)).subscribe({
      next: (meta: SidebarMeta) =>
        sidebarSubject$.next({ ...meta, visible: !meta.visible }),
    });
  }

  // showTemporary(key: string): void {
  //   const visibility$ = this._temporaryVisibilityState.get(key);
  //
  //
  // }
  //
  // hideTemporary(key: string): void {
  //   let visibility$ = this._temporaryVisibilityState.get(key);
  //
  //   if (!visibility$) {
  //     visibility$ = new BehaviorSubject(sidebar)
  //   }
  // }
  //
  // createTempVisibilityState(initialValue): BehaviorSubject<boolean | null>
}
