import { InjectionToken } from '@angular/core';
import { RateDetails } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const RATE_ACTIONS = new InjectionToken('Rate model actions');
export const RATE_EVENTS = new InjectionToken('Rate model events');

export interface RateEventsServiceInterface {
  rateCreated: Observable<RateDetails>;
  rateDeleted: Observable<string>;
  rateUpdated: Observable<string>;
}
