import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
})
export class FilesizePipe implements PipeTransform {
  transform(value: number, digits?: number): string {
    const KB = 1024;
    const MB = Math.pow(1024, 2);
    const GB = Math.pow(1024, 3);

    let fileSizeTransformed: string;
    if (value < KB) {
      fileSizeTransformed = value + ' B';
    } else if (value < MB) {
      fileSizeTransformed = (value / KB).toFixed(digits | 2) + ' KB';
    } else if (value < GB) {
      fileSizeTransformed = (value / MB).toFixed(digits | 2) + ' MB';
    } else {
      fileSizeTransformed = (value / GB).toFixed(digits | 2) + ' GB';
    }
    return fileSizeTransformed;
  }
}
