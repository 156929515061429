import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ekIconBox,   ekIconClone, ekIconGlasses,
  ekIconNewspaper, ekIconShareOutline,
ekIconTemplate,
ekIconTrashAlt,
  EkonIconsLibraryService,
  EkonIconsModule} from '@ekon-client/shared/common/ekon-icons';
import { EkonPaginationModule } from '@ekon-client/shared/common/ekon-pagination';
import { EkonRatingModule } from '@ekon-client/shared/common/ekon-ui';
import { EkonTagsModule } from '@ekon-client/shared/common/ekon-utils';
import { DkmBasketsModule } from '@ekon-client/shared/features/dkm-baskets';
import { AvaViewerModule } from '@ekon-client/shared/features/dkm-files';

import { PageListMiniComponent } from './components/page-list-mini/page-list-mini.component';
import { PageCardComponent } from './page-card.component';


@NgModule({
  declarations: [
    PageCardComponent,
    PageListMiniComponent
  ],
  imports: [
    CommonModule,
    DkmBasketsModule,
    AvaViewerModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    FlexModule,
    ExtendedModule,
    EkonIconsModule,
    EkonTagsModule,
    EkonRatingModule,
    MatListModule,
    EkonPaginationModule
  ],
  exports: [
    PageCardComponent,
    PageListMiniComponent
  ]
})
export class PageCardModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconNewspaper,
      ekIconBox,
      ekIconClone,
      ekIconShareOutline,
      ekIconGlasses,
      ekIconTrashAlt,
      ekIconTemplate
    );
  }
}
