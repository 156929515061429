import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BlogServiceInterface,
  CreateFeedback, DomainServiceInterface,
  FeedbackDetails,
  FeedbackServiceInterface, PageServiceInterface, UserPostsServiceInterface
} from '@ekon-client/dkm-api';
import {
  BLOG_ACTIONS,
  DOMAIN_ACTIONS,
  FEEDBACK_ACTIONS,
  PAGE_ACTIONS,
  USER_POSTS_ACTIONS
} from '@ekon-client/dkm-events';
import { getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import { EkonMessageService, EkonMessageTypes } from '@ekon-client/shared/common/ekon-utils';
import { Observable, throwError } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { FeedbackAddEditDialogComponent } from '../components/feedback-add-edit-dialog/feedback-add-edit-dialog.component';
import { ReportEntityDialogComponent } from '../components/report-entity-dialog/report-entity-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class FeedbackSharedService {
  constructor(
    private dialog: MatDialog,
    private messageService: EkonMessageService,
    @Inject(FEEDBACK_ACTIONS) private feedbackActions: FeedbackServiceInterface,
    @Inject(PAGE_ACTIONS) private pageActions: PageServiceInterface,
    @Inject(BLOG_ACTIONS) private blogActions: BlogServiceInterface,
    @Inject(USER_POSTS_ACTIONS) private userPostActions: UserPostsServiceInterface,
    @Inject(DOMAIN_ACTIONS) private domainActions: DomainServiceInterface
  ) {
  }

  openFeedBackDialog(): Observable<FeedbackDetails> {
    return this.dialog
      .open(FeedbackAddEditDialogComponent, {
        ...getModalDefaultConfig(),
        maxWidth: 600
      })
      .afterClosed()
      .pipe(
        filter((r) => Boolean(r)),
        switchMap((feedback: CreateFeedback) =>
          this.feedbackActions.createFeedback(feedback)
        ),
        tap({
          next: () =>
            this.messageService.show(
              'Feedback added successfully',
              EkonMessageTypes.SUCCESS
            ),
          error: () =>
            this.messageService.show(
              'Error occurred while adding feedback',
              EkonMessageTypes.ERROR
            )
        })
        // switchMap(() => this.feedbackActions.submitFeedback()),
      );
  }

  reportEntity(kind: 'domain' | 'userPost' | 'blog' | 'page', id: string): Observable<FeedbackDetails> {
    return this.dialog
      .open(ReportEntityDialogComponent, {
        ...getModalDefaultConfig(),
        maxWidth: 600
      })
      .afterClosed()
      .pipe(
        filter((r) => Boolean(r)),
        switchMap((feedback: CreateFeedback) => {
          feedback.referrerId = id;

          switch (kind) {
            case 'domain':
              return this.domainActions.createDomainFeedback(id, feedback);
            case 'blog':
              return this.blogActions.createBlogFeedback(id, feedback);
            case 'page':
              return this.pageActions.createPageFeedback(id, feedback);
            case 'userPost':
              return this.userPostActions.createUserPostFeedback(id, feedback);
            default:
              return throwError(new Error('kind isn`t specified'))
          }
        }),
        tap({
          next: () =>
            this.messageService.show(
              'Report sent',
              EkonMessageTypes.SUCCESS
            ),
          error: () =>
            this.messageService.show(
              'Error occurred while sending report',
              EkonMessageTypes.ERROR
            )
        })
        // switchMap(() => this.feedbackActions.submitFeedback()),
      );
  }
}
