import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable} from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { BaseChipsAutocomplete } from '../../base/base-chips-autocomplete';
import { AutocompleteService } from '../../services';

const CUSTOM_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ChipsAutocompleteComponent),
  multi: true
};

@Component({
  selector: 'ekon-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styles: [`
    :host {
      display: block;
    }
  `],
  providers: [CUSTOM_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipsAutocompleteComponent extends BaseChipsAutocomplete<string, string> {

  @Input() requestApi: (query: string) => Observable<string[]>;

  constructor(
    private autocomleteService: AutocompleteService,
    protected _cdr: ChangeDetectorRef
  ) {
    super(_cdr, true);

    this.filtered = this.inputControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((query: string) => this.requestApi(query))
    );
  }

  writeValue(obj: string[]): void {
    this.items = obj || [];
    this._cdr.detectChanges();
  }

  prepareFormValue(items: string[]): string[] {
    return items;
  }

  createNewItem(value: string): string {
    return value;
  }
}
