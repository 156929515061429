import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import { EkonPaginationModule } from '@ekon-client/shared/common/ekon-pagination';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { FileListModule } from '../file-list';
import { FileUploadModule } from '../file-upload';
import { FileViewModule } from '../file-view';
import { FileSelectDialogComponent } from './components/file-select-dialog/file-select-dialog.component';



@NgModule({
  declarations: [
    FileSelectDialogComponent,
  ],
  exports: [
    FileListModule,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    FileUploadModule,
    FileListModule,
    FileViewModule,
    FlexModule,
    EkonDialogsModule,
    EkonPaginationModule,
    TranslateModule
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-files/src/lib/file-select/',
      multi: true
    }
  ]
})
export class FileSelectModule {}
