/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EditabilityTypes } from './editabilityTypes';
import { Address } from './address';
import { EmailModel } from './emailModel';
import { Phone } from './phone';
import { UserHeaderModel } from './userHeaderModel';
import { VisibilityTypes } from './visibilityTypes';
import { DigitalPresence } from './digitalPresence';
import { LegalPerson } from './legalPerson';


/**
 * Legal Entity detailed model
 */
export interface LegalEntityDetails { 
    id?: string;
    name?: string | null;
    shortDescription?: string | null;
    description?: string | null;
    thumbprintUrl?: string | null;
    dashboardImageUrl?: string | null;
    vatNumber?: string | null;
    authorizedId?: string | null;
    /**
     * Digital Presence of the Legal entity  I.e. list of social networks, web site etc.
     */
    ourDigitalPresence?: Array<DigitalPresence> | null;
    legalEntityAddress?: Address;
    /**
     * Define who are the Legal persons of the entity.
     */
    entityLegalPerson?: Array<LegalPerson> | null;
    shippingInformation?: Address;
    otherAddresses?: Array<Address> | null;
    otherPhones?: Array<Phone> | null;
    otherEmails?: Array<EmailModel> | null;
    /**
     * External ID, for example, WG Source of supplier ID
     */
    externalId?: string | null;
    create?: boolean;
    read?: boolean;
    update?: boolean;
    remove?: boolean;
    createdBy?: string | null;
    createdAt?: Date;
    creator?: UserHeaderModel;
    visibleTo?: VisibilityTypes;
    editableBy?: EditabilityTypes;
}

