import { ChangeDetectionStrategy, Component, Inject, QueryList } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { DkmDomain, DomainServiceInterface } from '@ekon-client/dkm-api';
import { DOMAIN_ACTIONS } from '@ekon-client/dkm-events';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import { DomainSelectorService } from '@ekon-client/shared/features/dkm-domains-services';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { DomainSelectorDialogComponent } from '../domain-selector-dialog/domain-selector-dialog.component';

@Component({
  selector: 'dkm-domain-selector',
  templateUrl: './domain-selector.component.html',
  styleUrls: ['./domain-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainSelectorComponent {
  domains$: Observable<DkmDomain[]>;

  selection: DkmDomain[];

  constructor(
    @Inject(DOMAIN_ACTIONS) private domainActions: DomainServiceInterface,
    private domainSelectorService: DomainSelectorService,
    private dialog: MatDialog,
    public globalConfig: GlobalConfigurationService
  ) {
    this.domains$ = this.domainSelectorService.domainsSelected$.pipe(
      // map((domains: DkmDomain[]) => domains.slice(0, 10)),
      tap((domains: DkmDomain[]) => this.selection = domains)
    );
  }

  domainSelectionChanged(visibleSelection: DkmDomain[], options: QueryList<MatOption>): void {
    const unselected = options
      .filter((o: MatOption) => !o.selected && o.value)
      .map((o: MatOption) => o.value);

    this.domainSelectorService.domainsSelected$
      .pipe(
        take(1),
        map((selection: DkmDomain[]) =>
          selection.filter(d => !unselected.some(u => u.id === d.id) && !visibleSelection.some(v => v.id === d.id))
        )
      )
      .subscribe({
        next: (restSelection: DkmDomain[]) => this.domainSelectorService.setDomains([
          ...visibleSelection.filter(d => Boolean(d)),
          ...restSelection
        ])
      });
  }

  openDialog(): void {
    this.dialog.open(DomainSelectorDialogComponent, {
      ...getModalDefaultConfig(),
      maxWidth: '500px'
    });
  }
}
