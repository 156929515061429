import {
  ekIconArc,
  ekIconArw,
  ekIconCs,
  ekIconCss, ekIconCsv,
  ekIconDoc,
  ekIconDocx,
  ekIconFile,
  ekIconFlac,
  ekIconGif,
  ekIconHtml,
  ekIconJpeg,
  ekIconJpg,
  ekIconJs,
  ekIconJson,
  ekIconMov,
  ekIconMp3,
  ekIconMp4,
  ekIconPdf,
  ekIconPng,
  ekIconRaw,
  ekIconScss,
  ekIconTs,
  EkonIconDefinition
} from '@ekon-client/shared/common/ekon-icons';

/**
 * @param fileName
 */
export function getFileExt(fileName: string): string {
  const fileExtArr = fileName.split('.');
  return fileExtArr[fileExtArr.length - 1].toLowerCase();
}

/**
 * @param fileName
 */
export function getFileTypeIcon(fileName: string): EkonIconDefinition {
  const fileExt = getFileExt(fileName);
  switch (fileExt) {
    case 'pdf':
      return ekIconPdf;
    case 'doc':
      return ekIconDoc;
    case 'docx':
      return ekIconDocx;
    case 'mov':
      return ekIconMov;
    case 'mp4':
      return ekIconMp4;
    case 'jpg':
      return ekIconJpg;
    case 'jpeg':
      return ekIconJpeg;
    case 'png':
      return ekIconPng;
    case 'gif':
      return ekIconGif;
    case 'arw':
      return ekIconArw;
    case 'raw':
      return ekIconRaw;
    case 'cs':
      return ekIconCs;
    case 'ts':
      return ekIconTs;
    case 'js':
      return ekIconJs;
    case 'html':
      return ekIconHtml;
    case 'css':
      return ekIconCss;
    case 'scss':
      return ekIconScss;
    case 'mp3':
      return ekIconMp3;
    case 'flac':
      return ekIconFlac;
    case 'json':
      return ekIconJson;
    case 'csv':
      return ekIconCsv;
    case 'rar':
    case 'zip':
    case 'tar':
    case '7z':
    case 'arc':
      return ekIconArc;
    default:
      return ekIconFile;
  }
}
