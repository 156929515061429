import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { EkonProgressBarService } from '../../services/ekon-progress-bar.service';

@Component({
  selector: 'ekon-progress-bar',
  templateUrl: './ekon-progress-bar.component.html',
  styleUrls: ['./ekon-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class EkonProgressBarComponent {
  visible: Observable<boolean>;

  constructor(progressBar: EkonProgressBarService, private cdr: ChangeDetectorRef) {
    this.visible = progressBar.visible;
  }
}
