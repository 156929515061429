import { HttpErrorResponse } from '@angular/common/http';
import {
  get as _get,
  isNil as _isNil,
  isUndefined as _isUndefined
} from 'lodash-es';
import {
  BehaviorSubject, merge, Observable,
  ObservableInput,
  of, Subject,
  throwError
} from 'rxjs';
import {
  catchError, debounceTime, distinctUntilChanged, filter, map, retryWhen,
  shareReplay, startWith,
  switchMap, take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';

export type PaginatorMode = 'paginated' | 'infinite';

export interface PaginationState {
  pageSize: number,
  pageNumber: number;
}

export interface SortingNFilteringState {
  sorting?: Sort,
  filtering?: Filter;
}

export interface PaginatorInitialOptions {
  pageSize?: number,
  pageNumber?: number;
  pageSizeOptions?: number[];
}

export interface PagedResult<T> {
  items?: Array<T> | null;
  pageSize?: number;
  pageNumber?: number;
  totalItems?: number;
}

export class PaginatorLoaderError implements Error {
  name: string;

  constructor(
    public type: 'error' | 'info',
    public message: string,
    name?: string
  ) {
    this.name = name;
  }
}

export interface PaginationModel {
  pageSize?: number;
  pageNumber?: number;
  sorting?: Sort;
  filters?: Filter;
}

export interface Sort {
  fieldName?: string | null;
  order?: boolean;
}

export interface Filter {
  freeTextSearchFilter?: string | null;
  tagFilter?: Array<string> | null;
  tagFilterMode?: boolean;
  categoryFilter?: Array<string> | null;
  /**
   * For usage in Files context, otherwise ignore
   */
  contentType?: Array<string> | null;
}

export type PaginatorResolver<T> = (pagination: PaginationModel) => Observable<PagedResult<T>>

export type PaginatorObservables = []
  | [ObservableInput<unknown>]
  | [ObservableInput<unknown>, ObservableInput<unknown>]
  | [ObservableInput<unknown>, ObservableInput<unknown>, ObservableInput<unknown>]
  | [ObservableInput<unknown>, ObservableInput<unknown>, ObservableInput<unknown>, ObservableInput<unknown>]
  | [ObservableInput<unknown>, ObservableInput<unknown>, ObservableInput<unknown>, ObservableInput<unknown>, ObservableInput<unknown>];

export class Paginator<T> {
  private _infiniteList$: BehaviorSubject<T[]> = new BehaviorSubject([]);
  get infiniteList$(): Observable<T[]> {
    return this._infiniteList$.asObservable();
  }

  private _items$: Observable<T[]>;
  get items$(): Observable<T[]> {
    // console.warn('get items$');
    return this.paginatorMode$.pipe(
      switchMap((mode: PaginatorMode) => mode === 'paginated'
        ? this._items$
        : this._items$.pipe(
          startWith(([] as T[])),
          withLatestFrom(this.infiniteList$),
          map(([next, prev]: [T[], T[]]) => ([...prev, ...next])),
          tap((items: T[]) => {
            this._infiniteList$.next(items);
          })
        )),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };

  private readonly _totalItems$: BehaviorSubject<number> = new BehaviorSubject(null);
  get totalItems$(): Observable<number> {
    return this._totalItems$.asObservable().pipe(filter(p => !_isNil(p)));
  };

  private readonly _paginationStateChanged$: Subject<PaginationState> = new Subject();

  get paginationStateChanged$(): Observable<PaginationState> {
    return this._paginationStateChanged$.asObservable();
  };

  private readonly _paginatorMode$: BehaviorSubject<PaginatorMode>;

  get paginatorMode$(): Observable<PaginatorMode> {
    return this._paginatorMode$.asObservable();
  };

  private readonly _paginationState$: BehaviorSubject<PaginationState>;

  get paginationState$(): Observable<PaginationState> {
    return this._paginationState$.asObservable();
  };

  get pageSize$(): Observable<number> {
    return this._paginationState$.asObservable()
      .pipe(
        map((state: PaginationState) => state.pageSize)
      );
  };

  get pageNumber$(): Observable<number> {
    return this._paginationState$.asObservable()
      .pipe(
        map((state: PaginationState) => state.pageNumber)
      );
  };

  private readonly _pageSizeOptions$: BehaviorSubject<number[]>;
  get pageSizeOptions$(): Observable<number[]> {
    return this._pageSizeOptions$.asObservable();
  };

  private readonly _error$: BehaviorSubject<PaginatorLoaderError> = new BehaviorSubject(null);
  get error$(): Observable<PaginatorLoaderError> {
    return this._error$.asObservable();
  };

  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  };

  private readonly _sortingNFilteringChanged$: Subject<SortingNFilteringState> = new Subject();

  get sortingNFilteringChanged$(): Observable<SortingNFilteringState> {
    return this._sortingNFilteringChanged$.asObservable();
  };

  private readonly _sortingNFiltering$: BehaviorSubject<SortingNFilteringState>;

  get sorting$(): Observable<unknown> {
    return this._sortingNFiltering$.asObservable().pipe(map((state: SortingNFilteringState) => state.sorting));
  };

  get filtering$(): Observable<Filter> {
    return this._sortingNFiltering$.asObservable().pipe(map((state: SortingNFilteringState) => state.filtering));
  };

  private readonly _manualReload$: Subject<void> = new Subject();

  public _unsubscribe: Subject<void> = new Subject();

  constructor(
    private resolver: PaginatorResolver<T>,
    initialOptions: PaginatorInitialOptions = {},
    private observables: PaginatorObservables = [],
    private mode: PaginatorMode = 'paginated',
    sNf: SortingNFilteringState = {}
  ) {
    this._paginationState$ = new BehaviorSubject({
      pageSize: initialOptions.pageSize || 12,
      pageNumber: initialOptions.pageNumber || 1
    });

    this._sortingNFiltering$ = new BehaviorSubject(sNf);

    this._paginatorMode$ = new BehaviorSubject(mode);

    this._pageSizeOptions$ = new BehaviorSubject(initialOptions.pageSizeOptions || [6, 12, 24, 96]);

    this.init();
  }

  init(): void {
    // this._loading$.next(true);

    this._items$ = merge(
      merge(
        ...(Array.isArray(this.observables) ? this.observables : []),
        this._sortingNFiltering$.asObservable()
      ).pipe(
        // switchMap(() => this.infiniteList$),
        // withLatestFrom(this._paginationState$.asObservable()),
        // take(1),
        // map(([items, state]: [T[], PaginationState]) =>
        //   _take(items, items.length - state.pageSize)
        // ),
        // tap((items: T[]) => this._infiniteList$.next(items))
        withLatestFrom(
          this._paginationState$.asObservable(),
          this._paginatorMode$.asObservable()
        ),
        tap(([, state, mode]: [unknown, PaginationState, PaginatorMode]) => {
          this._infiniteList$.next([]);
          mode === 'infinite' && this.pushPaginationState({
            ...state,
            pageNumber: 1
          });
        })
      ),
      this._paginationState$.asObservable()
      // this._sortingNFiltering$.asObservable()
    ).pipe(
      debounceTime(500),
      distinctUntilChanged(),

      withLatestFrom(
        this._paginationState$.asObservable(),
        this._sortingNFiltering$.asObservable()
      ),

      tap(() => this._loading$.next(true)),

      tap(() => this._error$.next(null)),

      switchMap(d => this.loadData(d)),

      catchError((r: unknown) => this.handleTopLevelErrors(r)),

      tap({
        next: (r: PagedResult<T>) => this._totalItems$.next(r.totalItems),
        error: (r: unknown) => this._error$.next(r as PaginatorLoaderError)
      }),

      retryWhen(n => this.retryCondition(n)),

      map((r: PagedResult<T>) => r.items),

      takeUntil(this._unsubscribe)
    ) as Observable<T[]>;
  }

  private retryCondition(notifier: Observable<HttpErrorResponse>): Observable<unknown> {
    return notifier.pipe(
      switchMap(() => merge(
        ...(Array.isArray(this.observables) ? this.observables : []),
        merge(
          this._manualReload$.asObservable(),
          this._paginationStateChanged$.asObservable(),
          this._sortingNFilteringChanged$.asObservable()
        )
      ))
    );
  }

  private handleDataErrors(r: Error | unknown, paginationState: PaginationState): Observable<unknown> {
    if (r instanceof HttpErrorResponse && r.status === 404) {
      return of({
        totalItems: 0,
        pageNumber: paginationState.pageNumber,
        pageSize: paginationState.pageSize,
        items: []
      } as PagedResult<T>);
    }
    return throwError(() => r);
  }

  private handleTopLevelErrors(r: Error | unknown): Observable<never> {
    let error: PaginatorLoaderError;

    if (r instanceof HttpErrorResponse) {
      error = new PaginatorLoaderError(
        'error',
        r.error.title,
        r.name
      );
    } else if (r instanceof PaginatorLoaderError) {
      error = r;
    } else {
      error = new PaginatorLoaderError(
        'error',
        (r as Error).message,
        (r as Error).name
      );
    }

    return throwError(error);
  };

  pushPaginationState(state: PaginationState): void {
    this._paginationState$.next(state);
    this._paginationStateChanged$.next(state);
  }

  pushSortingNFilteringState(filters?: Filter, sorting?: Sort): void {
    this._sortingNFiltering$
      .pipe(
        take(1),
        withLatestFrom(this.pageSize$)
      )
      .subscribe({
        next: ([state, pageSize]: [SortingNFilteringState, number]) => {
          const sf = {
            sorting: _isUndefined(sorting) ? state.sorting : sorting,
            filtering: _isUndefined(filters) ? state.filtering : {
              freeTextSearchFilter: _get(filters, 'freeTextSearchFilter') || '',
              categoryFilter: _get(filters, 'categoryFilter', [] as string[]),
              tagFilter: _get(filters, 'tagFilter') || [],
              tagFilterMode: _get(filters, 'tagFilterMode', false),
              contentType: _get(filters, 'contentType')
            }
          };

          this._sortingNFiltering$.next(sf);
          this._sortingNFilteringChanged$.next(sf);

          this.pushPaginationState({
            pageSize,
            pageNumber: 1
          });
        }
      });
  }

  forceReload(): void {
    this._manualReload$.next();
  }

  unsubscribe(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  private loadData(
    [, paginationState, sortingNFilteringState]: [unknown, PaginationState, SortingNFilteringState]
  ): Observable<PagedResult<T>> {
    // const filters: Filter = {
    //   ...sortingNFilteringState?.filtering,
    //   freeTextSearchFilter: encodeURIComponent(sortingNFilteringState?.filtering?.freeTextSearchFilter)
    // };

    return this.resolver({
      pageSize: paginationState.pageSize,
      pageNumber: paginationState.pageNumber,
      // filters,
      filters: sortingNFilteringState.filtering,
      sorting: sortingNFilteringState.sorting
    }).pipe(
      tap({
        next: () => this._loading$.next(false),
        error: () => this._loading$.next(false)
      }),
      catchError((r: unknown) => this.handleDataErrors(r, paginationState)),
      // finalize(() => this._loading$.next(false))
    );
  }

  setMode(mode: PaginatorMode): void {
    this.paginationState$.pipe(
      take(1)
    ).subscribe({
      next: (state: PaginationState) => {
        this._infiniteList$.next([]);
        this.pushPaginationState({
          ...state,
          pageNumber: 1
        });
        this._paginatorMode$.next(mode);
      }
    });
  }
}
