import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, Validators } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { Observable } from 'rxjs';

@Directive()
export abstract class BaseAutocomplete<T, R> implements ControlValueAccessor {
  @Input() label: string;
  @Input() hint: string;
  @Input() placeholder: string;
  @Input() outlineStyle = true;
  @Input() set required(required: boolean) {
    required
      ? this.inputControl.addValidators(Validators.required)
      : this.inputControl.removeValidators(Validators.required)
  };

  @Output() selectionChanged: EventEmitter<R> = new EventEmitter();

  value: T;
  disabled: boolean;

  inputControl = new FormControl();
  filtered: Observable<T[]>;

  @ViewChild('itemInput') itemsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  onChange?: (x?: unknown) => void;
  onTouched?: (x?: unknown) => void;

  protected constructor(
    protected _cdr: ChangeDetectorRef,
    private enableNew?: boolean,
  ) {}

  /* ControlValueAccessor implementation */

  registerOnChange(fn?: (x: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn?: (x: unknown) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
    this._cdr.detectChanges();
  }

  abstract writeValue(obj: R): void;

}
