import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { DkmAutocompletionModule } from '@ekon-client/shared/features/dkm-autocompletion';
import { TranslateModule } from '@ngx-translate/core';

import { FeedbackAddEditDialogComponent } from './components/feedback-add-edit-dialog/feedback-add-edit-dialog.component';
import { ReportEntityDialogComponent } from './components/report-entity-dialog/report-entity-dialog.component';

@NgModule({
  declarations: [FeedbackAddEditDialogComponent, ReportEntityDialogComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    MatListModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatChipsModule,
    MatSelectModule,
    FlexModule,
    MatCardModule,
    MatRadioModule,
    FormsModule,
    EkonDialogsModule,
    DkmAutocompletionModule,
    TranslateModule
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-feedback/',
      multi: true
    }
  ]
})
export class DkmFeedbackModule {}
