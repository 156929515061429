import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LegalEntityServiceInterface } from '@ekon-client/dkm-api';
import { BreadcrumbTitleResolver } from '@ekon-client/shared/common/ekon-breadcrumbs';
import { map } from 'rxjs/operators';

import { LEGAL_ENTITY_ACTIONS } from '../LegalEntityEventsServiceInterface';

export const LegalEntityBreadcrumbResolver: BreadcrumbTitleResolver = (route: ActivatedRouteSnapshot, inj: Injector) =>
  (inj.get(LEGAL_ENTITY_ACTIONS) as LegalEntityServiceInterface).findLegalEntityById(route.params.leId)
    .pipe(
      map(p => p.name)
    );
