<dkm-core-ava-viewer
  [cacheEnabled]="cacheEnabled"
  [editDisabled]="editDisabled"
  [previewEnabled]="true"
  [fileLink]="fileLink"
  [noShape]="noShape"
  [noBackground]="noBackground"
  [checkFileLink]="false"
  (imageClick)="imageClick.emit($event)"
  (fileIdToBase64)="this.imageChange.emit($event)"
>
  <ng-content></ng-content>
</dkm-core-ava-viewer>
<div class="clear-image" (click)="clearImage()" *ngIf="!editDisabled && fileLink">
  <ekon-icon icon="trash-alt" size="sm"></ekon-icon>
</div>
<div class="edit-image" (click)="changeImage()" *ngIf="!editDisabled">
  <ekon-icon icon="edit" size="sm"></ekon-icon>
</div>
