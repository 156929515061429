import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import {
  ekIconFlagDe,
  ekIconFlagFr,
  ekIconFlagHu,
  ekIconFlagIl,
  ekIconFlagRu,
  ekIconFlagUk,
  ekIconFlagUs,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';

import { LanguageSetterComponent } from './language-setter.component';


@NgModule({
  declarations: [LanguageSetterComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    EkonIconsModule
  ],
  exports: [LanguageSetterComponent]
})
export class LanguageSetterModule {
  constructor(ekLib: EkonIconsLibraryService) {
    ekLib.addIcons(
      ekIconFlagRu,
      ekIconFlagIl,
      ekIconFlagUk,
      ekIconFlagFr,
      ekIconFlagUs,
      ekIconFlagHu,
      ekIconFlagDe
    );
  }
}
