/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CreateLegalEntity } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { LegalEntityDetails } from '../model/models';
import { LegalEntityDetailsPagedResult } from '../model/models';
import { LegalEntityHeaderModel } from '../model/models';
import { LegalEntityHeaderModelPagedResult } from '../model/models';
import { LegalEntityUserJoinWithdrawModel } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface LegalEntityServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param createLegalEntity 
     */
    createLegalEntity(createLegalEntity?: CreateLegalEntity, extraHttpRequestParams?: any): Observable<LegalEntityDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     */
    deleteLegalEntity(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     */
    findLegalEntityById(id: string, extraHttpRequestParams?: any): Observable<LegalEntityDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param requestBody 
     */
    listLegalEntitiesByIds(requestBody?: Array<string>, extraHttpRequestParams?: any): Observable<Array<LegalEntityDetails>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     */
    listOwnedLegalEntities(extraHttpRequestParams?: any): Observable<Array<LegalEntityHeaderModel>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     */
    listUserLegalEntities(pagePaginationModel?: PagePaginationModel, extraHttpRequestParams?: any): Observable<LegalEntityHeaderModelPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     */
    listUserLegalEntitiesDetails(pagePaginationModel?: PagePaginationModel, extraHttpRequestParams?: any): Observable<LegalEntityDetailsPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     * @param legalEntityDetails 
     */
    updateLegalEntity(id: string, legalEntityDetails?: LegalEntityDetails, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Join or redraw a user from a Legal Entity
     * 
     * @param id 
     * @param legalEntityUserJoinWithdrawModel 
     */
    userJoinWithrawLegalEntity(id: string, legalEntityUserJoinWithdrawModel?: LegalEntityUserJoinWithdrawModel, extraHttpRequestParams?: any): Observable<{}>;

}
