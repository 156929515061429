/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Element } from './element';


export interface NumericInputElement extends Element { 
    /**
     * Optional: Specifies the maximum value for an element
     */
    max?: number | null;
    /**
     * Optional: Specifies a minimum value for an element
     */
    min?: number | null;
    /**
     * Optional: Specifies the width of an element, in characters. Default value is 20
     */
    size?: number | null;
    /**
     * Optional: Specifies the legal number intervals for an element
     */
    step?: number | null;
    /**
     * Optional:  Decimal places after floating point
     */
    decimalPlaces?: number | null;
    /**
     * Optional: UI formating
     */
    representationFormat?: string | null;
    /**
     * Show meter graph, use min and max values
     */
    showGraph?: boolean;
    /**
     * Element instance value
     */
    value?: number;
}

