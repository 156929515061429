import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  HostBinding, Inject, InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { EkonDialogsService } from '@ekon-client/shared/common/ekon-dialogs';
import { includes as _includes } from 'lodash-es';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { FileService } from '../services/file.service';
import { FileCacheService } from '../services/file-cache.service';

export const IMAGE_LOAD_DISABLED = new InjectionToken('imageLoadDisabled');

@Component({
  selector: 'dkm-core-ava-viewer',
  templateUrl: './ava-viewer.component.html',
  styleUrls: ['./ava-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvaViewerComponent implements OnDestroy, OnChanges {
  @Input() fileLink: string;
  @Input() noShape: boolean;
  @Input() noBackground: boolean;
  @Input() editDisabled: boolean;
  @Input() cacheEnabled: boolean;
  @Input() checkFileLink: boolean;
  @Input() previewEnabled = false;

  @Output() imageClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() fileIdToBase64: EventEmitter<string> = new EventEmitter<string>();

  @HostBinding('class.circle-layout') get circleLayoutClass(): boolean {
    return !this.noShape;
  }

  @HostBinding('class.with-bg') get noBgClass(): boolean {
    return !this.noBackground;
  }

  fileUrl: string;

  private logoImage$: BehaviorSubject<string> = new BehaviorSubject(null);
  logoImage: Observable<SafeStyle>;


  constructor(
    protected fileService: FileService,
    protected dialogsService: EkonDialogsService,
    sanitizer: DomSanitizer,
    protected fileCache: FileCacheService,
    @Inject(IMAGE_LOAD_DISABLED) public imageLoadDisabled: boolean,
    protected elRef: ElementRef
  ) {

    this.logoImage = this.logoImage$.asObservable().pipe(
      delay(100),
      switchMap((fileLink: string) => {
        const rootWidth = elRef.nativeElement.getBoundingClientRect().width;

        return fileLink ? (
          _includes(fileLink, 'data:') ? of(fileLink) : (
            this.fileService.loadImageBase64(fileLink, rootWidth)
          )
        ) : of(null);
      }),
      tap((url: string) => (this.fileUrl = url)),
      tap((url: string) => {
        this.checkFileLink && this.fileLink && !_includes(this.fileLink, 'data:') && this.emitSaveAsBase64(url);
      }),
      map((url: string) =>
        url ? sanitizer.bypassSecurityTrustStyle(`url(${url})`) : null
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  ngOnDestroy(): void {
    !this.cacheEnabled && this.fileService.clearBlobURL(this.fileUrl);
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes && changes.fileLink && this.logoImage$.next(changes.fileLink.currentValue);
  }

  emitSaveAsBase64(dataLink: string): void {
    this.fileService.limitImageSize(dataLink, 256).subscribe({
      next: (link: string) => {
        this.fileIdToBase64.emit(link);
      }
    });
  }
}
