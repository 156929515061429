import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { EkonIsEmptyPipeModule } from '@ekon-client/shared/common/ekon-utils';
import { AvaViewerModule } from '@ekon-client/shared/features/dkm-files';

import { UserCardMiniComponent } from './user-card-mini.component';


@NgModule({
  declarations: [UserCardMiniComponent],
  imports: [
    CommonModule,
    AvaViewerModule,
    RouterModule,
    MatCardModule,
    EkonIsEmptyPipeModule
  ],
  exports: [UserCardMiniComponent]
})
export class UserCardMiniModule {}
