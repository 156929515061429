import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  CreateFaqModel,
  FaqDetails,
  FaqDetailsPagedResult,
  FaqService,
  FaqServiceInterface, PagePaginationModel
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FaqEventsServiceInterface } from './FaqEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class FaqEventsService
  implements FaqServiceInterface, FaqEventsServiceInterface {
  get configuration(): Configuration {
    return this.faqService.configuration;
  }

  set configuration(val: Configuration) {
    this.faqService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.faqService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.faqService.defaultHeaders = val;
  }

  constructor(
    private faqService: FaqService,
    private progressBar: EkonProgressBarService
  ) {
    // this.faqService.configuration.withCredentials = true;
  }

  /**
   * The mechanism for monitoring Create action
   */
  private faqCreatedSubject: Subject<FaqDetails> = new Subject();

  get faqCreated(): Observable<FaqDetails> {
    return this.faqCreatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Deleted action
   */
  private faqDeletedSubject: Subject<string> = new Subject();

  get faqDeleted(): Observable<string> {
    return this.faqDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Updated action
   */
  private faqUpdatedSubject: Subject<string> = new Subject();

  get faqUpdated(): Observable<string> {
    return this.faqUpdatedSubject.asObservable();
  }

  /**
   * Creating new FAQ item
   *
   * @param createFaq
   * @param xSelectedDomain
   */
  createFaq(
    createFaq?: CreateFaqModel,
    xSelectedDomain?: string
  ): Observable<FaqDetails> {
    return this.progressBar
      .apply(this.faqService.createFaq(createFaq, xSelectedDomain))
      .pipe(tap((res: FaqDetails) => this.faqCreatedSubject.next(res)));
  }

  /**
   * Deleting FAQ item
   *
   * @param id
   */
  deleteFaq(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.faqService.deleteFaq(id))
      .pipe(tap((res) => this.faqDeletedSubject.next(res)));
  }

  /**
   * Getting FAQ by ID
   *
   * @param id
   */
  findFaqById(id: string): Observable<FaqDetails> {
    return this.progressBar.apply(this.faqService.findFaqById(id));
  }

  /**
   * Getting a list of FAQs
   *
   */
  listFaqs(
    pagination: PagePaginationModel,
    xSelectedDomain?: string
  ): Observable<FaqDetailsPagedResult> {
    return this.progressBar.apply(this.faqService.listFaqs(pagination, xSelectedDomain));
  }

  /**
   * Updating FAQ item
   *
   * @param id
   * @param faqDetails
   * @param xSelectedDomain
   */
  updateFaq(
    id: string,
    faqDetails: FaqDetails,
    xSelectedDomain?: string
  ): Observable<unknown> {
    return this.progressBar
      .apply(this.faqService.updateFaq(id, faqDetails, xSelectedDomain))
      .pipe(tap((res) => this.faqUpdatedSubject.next(res)));
  }

  updateFaqSequence(fromId: string, toId: string): Observable<unknown> {
    return this.progressBar
      .apply(this.faqService.updateFaqSequence(fromId, toId))
      .pipe(tap(() => this.faqUpdatedSubject.next(fromId)));
  }

  listPlatformFaqs(pagination?: PagePaginationModel, xSelectedDomain?: string): Observable<FaqDetailsPagedResult> {
    return this.progressBar.apply(this.faqService.listPlatformFaqs(pagination, xSelectedDomain));
  }
}
