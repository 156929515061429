/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from './geometry';


export interface Point extends Geometry { 
    /**
     * https://tools.ietf.org/html/rfc7946  Point coordinates are in x, y order (easting, northing for projected coordinates, longitude, and latitude for geographic coordinates)
     */
    coordinates?: Array<number> | null;
}

