import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BlogPostHeader, RefType } from '@ekon-client/dkm-api';

@Component({
  selector: 'dkm-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogCardComponent {
  UsageRefTypeEnum = RefType;

  @Input() data: BlogPostHeader;
  @Input() editAllowed: boolean;
  @Input() disableAllActions = false;

  @Output() onViewBlogPost: EventEmitter<BlogPostHeader> = new EventEmitter();
  @Output() onEditBlogPost: EventEmitter<BlogPostHeader> = new EventEmitter();
  @Output() onDeleteBlogPost: EventEmitter<BlogPostHeader> = new EventEmitter();
  @Output() select: EventEmitter<BlogPostHeader> = new EventEmitter();


  editBlogPost(): void {
    this.onEditBlogPost.emit(this.data);
  }

  deleteBlogPost(): void {
    this.onDeleteBlogPost.emit(this.data);
  }

  viewBlogPost(): void {
    this.onViewBlogPost.emit(this.data);
  }

  selectBlogPost(): void {
    this.select.emit(this.data);
  }
}
