import { InjectionToken } from '@angular/core';
import { RssDetails } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const RSS_ACTIONS = new InjectionToken('Rss model actions');
export const RSS_EVENTS = new InjectionToken('Rss model events');

export interface RssEventsServiceInterface {
  rssCreated: Observable<RssDetails>;
  rssDeleted: Observable<string>;
  rssUpdated: Observable<string>;
}
