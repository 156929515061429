import { ChangeDetectionStrategy, Component, Injector, Input, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { EkonNavigationHistoryService } from '@ekon-client/shared/common/ekon-utils';
import { TranslateService } from '@ngx-translate/core';
import { isFunction as _isFunction } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

import { BreadcrumbItem, BreadcrumbItemLabel, BreadcrumbTitleResolver } from '../../extras';

@Component({
  selector: 'ekon-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnDestroy {

  private _customTitle: boolean | string;
  private _customTitle$: BehaviorSubject<string> = new BehaviorSubject(null);
  private _customTitleTpl: boolean;
  title$: Observable<string | BreadcrumbItemLabel>;
  historyRecord$: Observable<unknown>;

  @Input() set customTitle(v: boolean | string) {
    this._customTitle = v;
    if(v && typeof v === 'string') {
      this._customTitle$.next(v);
    }
  };

  get customTitle(): boolean | string {
    return this._customTitle;
  };

  @Input() set customTitleTpl(v: boolean | '') {
    this._customTitleTpl = v || v === '';
  };

  get customTitleTpl(): boolean {
    return this._customTitleTpl;
  };

  @Input() titleUppercase: boolean;
  @Input() titleTitlecase: boolean;
  @Input() aggregate: boolean;
  @Input() breadcrumbs: BreadcrumbItem[];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private inj: Injector,
    private titleService: Title,
    private globalConfig: GlobalConfigurationService,
    private translateService: TranslateService,
    private navigationHistory: EkonNavigationHistoryService
  ) {
    this.breadcrumbs = route.snapshot.data.breadcrumbs ? [...route.snapshot.data.breadcrumbs] : [];
    const title = route.snapshot.data['ekon-breadcrumb-route-title'];
    let _title$: Observable<string | BreadcrumbItemLabel>;


    if(title) {
      if(_isFunction(title)) {
        const _title = (title as BreadcrumbTitleResolver)(route.snapshot, this.inj);
        if(_title instanceof Observable) {
          _title$ = _title;
        } else {
          _title$ = of(_isFunction(_title) ? _title(this.route.snapshot.data) : _title);
        }
      } else {
        _title$ = of(title);
      }
    }

    this.title$ = _title$?.pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.aggregate = true;

    this.historyRecord$ = combineLatest([
      this.title$,
      this._customTitle$
    ]).pipe(
      tap({
        next: ([t, custom]: [unknown, string]) => {
          let siteTitle: string;
          const titleAsObj: BreadcrumbItemLabel = t as BreadcrumbItemLabel;

          if(titleAsObj.label) {
            if(titleAsObj.isTranslationKey) {
              siteTitle = translateService.instant(titleAsObj.label);
            } else {
              siteTitle = titleAsObj.label;
            }
          } else {
            siteTitle = t as string;
          }

          this.setTabTitle(custom || siteTitle);


          if (this.route.snapshot.data['saveToHistory']) {
            this.navigationHistory.addRecord(
              router.createUrlTree([], { relativeTo: route }).toString(),
              custom || siteTitle,
              this.route.snapshot.data['saveToHistoryKind']
            );
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(this.globalConfig.projectDisplayName);
  }

  setTabTitle(siteTitle: string): void {
    this.titleService.setTitle(
      `${this.globalConfig.projectDisplayNameShort} - ${siteTitle}`
    );
  }

  getCrumbUrl(i: number): string[] {
    return this.aggregate
           ? ['/', ...this.breadcrumbs.slice(0, i + 1).map(crumb => crumb.url).flat()]
           : this.breadcrumbs[i].url;
  }
}
