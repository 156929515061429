import { ChangeDetectionStrategy, Component, Inject, TrackByFunction } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  LegalEntityHeaderModel,
  LegalEntityServiceInterface
} from '@ekon-client/dkm-api';
import {
  LEGAL_ENTITY_ACTIONS,
  LEGAL_ENTITY_EVENTS,
  LegalEntityEventsServiceInterface
} from '@ekon-client/dkm-events';
import { PaginationModel, PaginatorConfig } from '@ekon-client/shared/common/ekon-pagination';

@Component({
  templateUrl: './legal-entity-selector-dialog.component.html',
  styleUrls: ['./legal-entity-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalEntitySelectorDialogComponent {

  paginatorConfig: PaginatorConfig;

  trackById: TrackByFunction<LegalEntityHeaderModel> = (index: number, item: LegalEntityHeaderModel) => item.id;

  constructor(
    private dialogRef: MatDialogRef<LegalEntitySelectorDialogComponent>,
    @Inject(LEGAL_ENTITY_ACTIONS) private leActions: LegalEntityServiceInterface,
    @Inject(LEGAL_ENTITY_EVENTS) private leEvents: LegalEntityEventsServiceInterface
  ) {
    this.paginatorConfig = {
      loader: (p: PaginationModel) => this.leActions.listUserLegalEntities(p),
      observables: [
        this.leEvents.entityCreated,
        this.leEvents.entityUpdated,
        this.leEvents.entityDeleted
      ]
    };
  }

  validateAndSave(entity: LegalEntityHeaderModel): void {
    this.dialogRef.close(entity);
  }
}
