/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CreateProductPageModel } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { PageDetails } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';
import { ProductDetails } from '../model/models';
import { ProductHeaderPagedProductResult } from '../model/models';
import { ProductHeaderPagedResult } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ProductServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createProductPageModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createProduct(createProductPageModel?: CreateProductPageModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<ProductDetails>;

    /**
     * Create from population batch.
     * 
     * @param pageDetailsProductDetails 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createProductFromPopulation(pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageDetails | ProductDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param kbeId 
     * @param filterByDomains 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listProductPagesByKbe(kbeId: string, filterByDomains: boolean, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<ProductHeaderPagedProductResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param legalEntityId 
     * @param filterByDomains 
     * @param isTemplate 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listProductPagesByLegalEntity(legalEntityId: string, filterByDomains: boolean, isTemplate: boolean, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<ProductHeaderPagedResult>;

}
