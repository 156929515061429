<mat-card fxLayout="row" fxLayoutAlign="space-between stretch">

  <dkm-core-ava-viewer
    fxFlex="0 0 auto"
    class="entity-logo"
    [editDisabled]="true"
    [fileLink]="data.dashboardImageUrl"
    (imageClick)="disableAllActions ? selectEntity() : callViewEntity()"
  >
    <ekon-icon icon="briefcase" size="3x"></ekon-icon>
  </dkm-core-ava-viewer>

  <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="space-between stretch" class="pl-16">
    <div>
      <div class="h2 mb-8"><a (click)="callViewEntity()">{{data.name}}</a></div>

      <div class="fadeout m-0">{{data.shortDescription}}</div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">

      <div fxLayout="row" fxLayoutAlign="end center">
        <!--Removed to be consistant in functionality :) 20/07/2021-->
<!--        <button
          *ngIf="!disableAllActions && editEnabled"
          mat-icon-button
          aria-label="edit"
          (click)="callEditEntity()"
          matTooltip="Edit"
        >
          <fa-icon [icon]="['fas', 'edit']"></fa-icon>
        </button>-->

        <button
          *ngIf="!disableAllActions && deleteEnabled"
          mat-icon-button
          color="warn"
          aria-label="delete"
          (click)="callDeleteEntity()"
          matTooltip="Delete"
        >
          <ekon-icon icon="trash-alt"></ekon-icon>
        </button>
      </div>
    </div>
  </div>
</mat-card>
