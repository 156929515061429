import { InjectionToken } from '@angular/core';
import { NotificationTemplateModel } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const NOTIFICATION_TEMPLATE_ACTIONS = new InjectionToken('Notification template model actions');
export const NOTIFICATION_TEMPLATE_EVENTS = new InjectionToken('Notification template model events');

export interface NotificationTemplateEventsServiceInterface {
  notificationCreated: Observable<NotificationTemplateModel>;
  notificationDeleted: Observable<string>;
  notificationUpdated: Observable<string>;
}
