/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CreateRssModel } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { ProblemDetails } from '../model/models';
import { RssDetails } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface RssServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createRssModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createRss(createRssModel?: CreateRssModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<RssDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteRss(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findRssById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<RssDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listRsses(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<RssDetails>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param rssDetails 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateRss(id: string, rssDetails?: RssDetails, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

}
