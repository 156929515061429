import { CommonModule } from '@angular/common';
import {
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { DkmTourModule } from '@ekon-client/shared/features/dkm-tour';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

import { LayoutsGodComponent } from './layouts-god/layouts-god.component';


@NgModule({
  declarations: [
    LayoutsGodComponent
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: 'No data to display', // Message to show when array is presented, but contains no values
        totalMessage: 'total', // Footer total message
        selectedMessage: 'selected' // Footer selected message
      }
    }),

    TourMatMenuModule.forRoot(),
    DkmTourModule
  ],
  exports: [
    LayoutsGodComponent
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/dkm-layouts/',
      multi: true
    }
  ]
})
export class DkmLayoutsModule {
  constructor(
    @Optional() @SkipSelf() parentModule: DkmLayoutsModule
  ) {
    if (parentModule) {
      throw new Error(
        'DkmLayoutsModule is already loaded. Import it in the AppModule only!'
      );
    }
  }
}
