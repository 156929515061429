import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EkonIconComponent } from './components/ekon-icon/ekon-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    EkonIconComponent
  ],
  exports: [
    EkonIconComponent
  ],
})
export class EkonIconsModule {}
