import { InjectionToken } from '@angular/core';
import { NoteDetails } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const NOTE_ACTIONS = new InjectionToken('Note model actions');
export const NOTE_EVENTS = new InjectionToken('Note model events');

export interface NoteEventsServiceInterface {
  noteCreated: Observable<NoteDetails>;
  noteDeleted: Observable<string>;
  noteUpdated: Observable<string>;
}
