/* eslint-disable lodash/prefer-lodash-typecheck */
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { GlobalConfiguration } from '../classes';
import { GlobalConfigurationService } from './global-configuration.service';

@Injectable({
  providedIn: 'platform'
})
export class AppGlobalConfigurationInitService {

  /** config.json file that will contain out environment variables */
  private readonly STATIC_CONFIG_URL = './assets/config/config.json';
  private readonly ENV_CONFIG_URL = './assets/config/config.env.json';
  private http: HttpClient;
  // private config$: BehaviorSubject<GlobalConfiguration> = new BehaviorSubject(null);

  constructor(private environmentService: GlobalConfigurationService, handler: HttpBackend) {
    // create client without interceptors
    this.http = new HttpClient(handler);
  }

  private parseValue(value: string): unknown {
    // Check for boolean
    if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
      return value.toLowerCase() === 'true';
    }

    // Check for number
    if (!isNaN(Number(value)) && value.trim() !== '') {
      return Number(value);
    }

    // Check for date/datetime
    const dateRegex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d{1,3})?(Z|[\+\-]\d{2}:\d{2})?)?$/;
    if (dateRegex.test(value)) {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        return date;
      }
    }

    // Default to string
    return value;
  }

  private parseObject(obj: unknown): Partial<GlobalConfiguration> {
    const parsedObj: unknown = {};
    for (const [key, value] of Object.entries(obj)) {
      parsedObj[key] = typeof value === 'string' ? this.parseValue(value) : value;
    }
    return parsedObj as Partial<GlobalConfiguration>;
  }


  /**
   * Method for loading configuration
   */
  public loadConfiguration(environment: unknown & Partial<GlobalConfiguration>): Observable<GlobalConfiguration> {
    return forkJoin([
      this.http.get<Partial<GlobalConfiguration>>(this.STATIC_CONFIG_URL)
        .pipe(
          map(data => this.parseObject(data) as Partial<GlobalConfiguration>),
          catchError(() => of({} as Partial<GlobalConfiguration>))
        ),
      this.http.get<Partial<GlobalConfiguration>>(this.ENV_CONFIG_URL)
        .pipe(
          map(data => this.parseObject(data) as Partial<GlobalConfiguration>),
          catchError(() => of({} as Partial<GlobalConfiguration>))
        )
    ])
      .pipe(
        map(([static_cfg, dynamic_cfg]) => {
          const mergedConfig = { ...environment, ...static_cfg, ...dynamic_cfg };
          console.log("***********Merged config**************");
          console.log(mergedConfig);
          return mergedConfig as GlobalConfiguration;
        }),
        switchMap(cfg => this.environmentService.setEnvironment(of(cfg))),
        shareReplay({ bufferSize: 1, refCount: true }),
        // tap(cfg => this.config$.next(cfg)) // Use tap for side-effects like updating config$
      );
  }

}

export function loadAppGlobalConfigurationInitService(
  configInitService: AppGlobalConfigurationInitService,
  environment: unknown & Partial<GlobalConfiguration>
): () => Observable<unknown> {
  return () => configInitService.loadConfiguration(environment)
    .pipe(
      filter(cfg => !!cfg),
      tap({
        next: () => {
          console.log('loadAppGlobalConfigurationInitService Finished');
        }
      }),
      take(1)
    );
}
