import { AuthConfig, Language } from '.';

export interface LanguageSet {
  [key: string]: Language
}

export interface ColorThemes {
  [key: string]: boolean
}

export interface PresetColors {
  [key: string]: string
}

export type WysiwygType = 'quill' | 'ckeditor' | 'tinymce';

export interface GlobalConfiguration {
  production?: boolean,
  API_PATH: string,
  LOGO_URI: string,
  PROJECT_DISPLAY_NAME?: unknown & string,
  PROJECT_DISPLAY_NAME_SHORT?: unknown & string,
  LANGUAGES: LanguageSet,
  DEFAULT_LANGUAGE: string,
  AUTH_SETTINGS: unknown & AuthConfig,

  // todo: check it. could be odd
  DOMAIN_TITLE_SINGULAR?: string,
  DOMAIN_TITLE_PLURAL?: string,

  /*********************/
  // TODO: Refactor env settings area
  FOOTER: unknown & { NAME?: string, SITE_URI?: string },
  ENABLE_WG_CHATBOT?: unknown & boolean,
  LAYOUT_SELECTOR?: unknown & boolean,
  USE_BASKET?: unknown & boolean,
  ENABLE_LEGAL_ENTITY?: unknown & boolean,
  ENABLE_FEED?: unknown & boolean,
  ENABLE_RSS?: unknown & boolean,
  ENABLE_MAP?: unknown & boolean,
  ENABLE_KBE?: unknown & boolean,
  ENABLE_TAG_CLOUD?: unknown & boolean,
  ENABLE_MEETUPS?: unknown & boolean,
  ENABLE_TASKS?: unknown & boolean,
  ENABLE_NOTES?: unknown & boolean,
  ENABLE_PLATFORM_NOTES?: unknown & boolean,
  ENABLE_BLOGS?: unknown & boolean,
  ENABLE_BLOCK_N_REPORT_CONTENT?: unknown & boolean,

  USE_ALT_HOME_DASHBOARD?: unknown & boolean,

  DASHBOARD_GREETING?: string,
  DASHBOARD_GREETING_BACKGROUND?: string,

  COLOR_THEMES?: ColorThemes,

  WG_ANALYTICS_URL?: string,
  WG_SIMULATION_SUB?: string,
  WG_TENDERING_SUB?: string,
  WG_TENDERING?: string,
  WG_SIMULATION?: string,
  WG_ONTOLOGY?: string,
  WG_KBE?: string,
  WG_KBE_COST_ASSESMENT?: string,
  WG_KBE_LYFECYCLE_ASSESMENT?: string,

  PRESET_COLORS?: unknown & PresetColors,

  WYSIWYG?: unknown & WysiwygType,

  [key: string]: unknown,
}
