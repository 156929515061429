import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LegalEntityDetails,
  LegalEntityServiceInterface
} from '@ekon-client/dkm-api';
import { LEGAL_ENTITY_ACTIONS } from '@ekon-client/dkm-events';
import { EkonDialogsService, getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import { EkonMessageService, EkonMessageTypes } from '@ekon-client/shared/common/ekon-utils';
import {
  SHARING_OPTIONS_DIALOG_CONFIG_DEFAULT,
  SharingOptionsDialogComponent, SharingOptionsDialogData
} from '@ekon-client/shared/features/dkm-partials';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';


@Injectable()
export class LegalEntityService {
  private rootRoute: ActivatedRoute;

  constructor(
    private dialog: MatDialog,
    private dialogService: EkonDialogsService,
    private message: EkonMessageService,
    @Inject(LEGAL_ENTITY_ACTIONS)
    private legalEntityActions: LegalEntityServiceInterface,
    private router: Router,
  ) { }

  setRootRoute(route: ActivatedRoute): void {
    this.rootRoute = route;
  }

  viewEntity(id: string): void {
    this.router.navigate([id], {
      relativeTo: this.rootRoute
    });
  }

  editEntity(id: string, tab?: string): void {
    this.router.navigate([id, 'edit'], {
      queryParams: { tab },
      relativeTo: this.rootRoute
    });
  }

  setLogo(imageId: string, led: LegalEntityDetails): Observable<unknown> {
    return this.legalEntityActions
      .updateLegalEntity(
        led.id,
        {
          ...led,
          dashboardImageUrl: imageId,
        },
      )
      .pipe(
        tap({
          next: () =>
            this.message.show(
              'Legal Entity logo set successfully',
              EkonMessageTypes.SUCCESS
            ),
          error: () =>
            this.message.show(
              'Error occurred setting Legal Entity logo',
              EkonMessageTypes.ERROR
            ),
        })
      );
  }

  setSharingOptions(led: LegalEntityDetails): Observable<unknown> {
    const dialogConfig = getModalDefaultConfig();
    dialogConfig.maxWidth = '400px';
    dialogConfig.data = {
      entity: led,
      config: SHARING_OPTIONS_DIALOG_CONFIG_DEFAULT
    } as SharingOptionsDialogData<LegalEntityDetails>;

    return this.dialog
      .open(SharingOptionsDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap((result: Partial<LegalEntityDetails>) =>
          this.legalEntityActions.updateLegalEntity(
            led.id,
            {
              ...led,
              ...result
            })
        ),
        tap({
          next: () =>
            this.message.show(
              `Sharing options updated successfully`,
              EkonMessageTypes.SUCCESS
            ),
          error: () =>
            this.message.show(
              `Error occurred while updating sharing options`,
              EkonMessageTypes.ERROR
            ),
        })
      );
  }

  deleteEntity(id: string): Observable<unknown> {
    return this.dialogService
      .confirm('Are u sure to delete selected entity?', 'Deleting Legal Entity')
      .pipe(
        filter((r) => Boolean(r)),
        switchMap(() => this.legalEntityActions.deleteLegalEntity(id)),
        tap({
          next: () =>
            this.message.show(
              'Legal Entity deleted successfully',
              EkonMessageTypes.SUCCESS
            ),
          error: () =>
            this.message.show(
              'Error occurred deleting Legal Entity',
              EkonMessageTypes.ERROR
            ),
        })
      );
  }
}
