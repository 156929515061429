/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RichTextElement } from './richTextElement';
import { YouTubeElement } from './youTubeElement';
import { TextInputElement } from './textInputElement';
import { HtmlValueElement } from './htmlValueElement';
import { IframeValueElement } from './iframeValueElement';
import { NameValueGraphElement } from './nameValueGraphElement';
import { DateTimeElement } from './dateTimeElement';
import { TextAreaInputElement } from './textAreaInputElement';
import { DropDownListElement } from './dropDownListElement';
import { GridViewElement } from './gridViewElement';
import { BoxPlotGraphElement } from './boxPlotGraphElement';
import { JsonValueElement } from './jsonValueElement';
import { BooleanElement } from './booleanElement';
import { CurrencyInputElement } from './currencyInputElement';
import { FileViewerElement } from './fileViewerElement';
import { NumericInputElement } from './numericInputElement';


export interface LayoutItem { 
    id?: string;
    /**
     * x position if missing will auto position
     */
    x?: number | null;
    /**
     * y position if missing will auto position
     */
    y?: number | null;
    /**
     * number of rows if missing will use grid option defaultItemRows
     */
    rows?: number | null;
    /**
     * number of columns if missing will use grid option defaultItemCols
     */
    cols?: number | null;
    /**
     * override grid option minItemRows
     */
    minItemRows?: number | null;
    /**
     * override grid option maxItemRows
     */
    maxItemRows?: number | null;
    /**
     * override grid option minItemCols
     */
    minItemCols?: number | null;
    /**
     * override grid option maxItemCols
     */
    maxItemCols?: number | null;
    /**
     * override grid option minItemArea
     */
    minItemArea?: number | null;
    /**
     * override grid option maxItemArea
     */
    maxItemArea?: number | null;
    /**
     * override grid option draggable.enabled
     */
    dragEnabled?: boolean | null;
    /**
     * override grid option resizable.enabled
     */
    resizeEnabled?: boolean | null;
    /**
     * disable grid option compact for this item
     */
    compactEnabled?: boolean | null;
    /**
     * Item content element
     */
    content?: BooleanElement | BoxPlotGraphElement | CurrencyInputElement | DateTimeElement | DropDownListElement | FileViewerElement | GridViewElement | HtmlValueElement | IframeValueElement | JsonValueElement | NameValueGraphElement | NumericInputElement | RichTextElement | TextAreaInputElement | TextInputElement | YouTubeElement | null;
}

