/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionGroup } from './permissionGroup';
import { SubscriptionPlans } from './subscriptionPlans';


export interface SubscriptionPlan { 
    /**
     * The order to display sbuscription plans
     */
    order?: number;
    subscriptionPlanType?: SubscriptionPlans;
    title?: string | null;
    shortDescription?: string | null;
    /**
     * Encoded html string
     */
    value?: string | null;
    /**
     * During EU projects, all plans are free.  If indicated as free, do not display monthly and yearly prices.
     */
    isFree?: boolean;
    monthlyPrice?: number;
    yearlyPrice?: number;
    /**
     * A plan that requires manual discussion
     */
    contactUs?: boolean;
    /**
     * Set subscription plan visable
     */
    visibleOnLanding?: boolean;
    featuresPermission?: PermissionGroup;
}

