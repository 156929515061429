import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  CreateProductPageModel,
  PageDetails,
  PagePaginationModel,
  PageService,
  ProductDetails,
  ProductHeaderPagedProductResult,
  ProductHeaderPagedResult,
  ProductService, ProductServiceInterface
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PageEventsService } from './PageEventsService';
import { ProductEventsServiceInterface } from './ProductEventsServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class ProductEventsService extends PageEventsService implements ProductServiceInterface, ProductEventsServiceInterface {
  get defaultHeaders(): HttpHeaders {
    return this.productService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.productService.defaultHeaders = val;
  }

  get configuration(): Configuration {
    return this.productService.configuration;
  }

  set configuration(val: Configuration) {
    this.productService.configuration = val;
  }

  private itemCreatedSubject: Subject<ProductDetails> = new Subject();
  get itemCreated(): Observable<ProductDetails> {
    return this.itemCreatedSubject.asObservable();
  }

  private itemDeletedSubject: Subject<string> = new Subject();
  get itemDeleted(): Observable<string> {
    return this.itemDeletedSubject.asObservable();
  }

  private itemUpdatedSubject: Subject<string> = new Subject();
  get itemUpdated(): Observable<string> {
    return this.itemUpdatedSubject.asObservable();
  }

  constructor(
    protected pageService: PageService,
    protected progressBar: EkonProgressBarService,
    private productService: ProductService
  ) {
    super(pageService, progressBar);
  }

  createPage(
    createProductPageModel: CreateProductPageModel,
    xSelectedDomain: string
  ): Observable<ProductDetails> {
    return this.createProduct(createProductPageModel, xSelectedDomain);
  }

  updatePage(id: string, productDetails: ProductDetails): Observable<unknown> {
    return this.progressBar
      .apply(this.pageService.updatePage(id, productDetails))
      .pipe(tap(() => this.itemUpdatedSubject.next(id)));
  }

  deletePage(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.pageService.deletePage(id))
      .pipe(tap(() => this.itemDeletedSubject.next(id)));
  }

  createProduct(createProductPageModel: CreateProductPageModel, xSelectedDomain: string): Observable<ProductDetails> {
    return this.progressBar
      .apply(this.productService.createProduct(createProductPageModel, xSelectedDomain))
      .pipe(tap((res: PageDetails) => this.itemCreatedSubject.next(res)));
  }

  listProductPagesByLegalEntity(
    legalEntityId: string,
    filterByDomains: boolean,
    isTemplate: boolean,
    pagination: PagePaginationModel,
    xSelectedDomain?: string
  ): Observable<ProductHeaderPagedResult> {
    return this.progressBar.apply(
      this.productService.listProductPagesByLegalEntity(
        legalEntityId,
        filterByDomains,
        isTemplate,
        pagination,
        xSelectedDomain
      )
    );
  }

  listProductPagesByKbe(

    kbeId: string,
    filterByDomains: boolean,
    pagination: PagePaginationModel,
    xSelectedDomain?: string,
  ): Observable<ProductHeaderPagedProductResult> {
    return this.progressBar.apply(
      this.productService.listProductPagesByKbe(
        kbeId,
        filterByDomains,
        pagination,
        xSelectedDomain
      )
    );
  }


  createProductFromPopulation(pageDetailsProductDetails?: ProductDetails, xSelectedDomain?: string): Observable<ProductDetails> {
    return this.progressBar.apply(
      this.productService.createProductFromPopulation(pageDetailsProductDetails, xSelectedDomain)
    );
  }
}
