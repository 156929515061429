import { OverlayRef } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Inject, InjectionToken } from '@angular/core';
import { FileMetadata } from '@ekon-client/dkm-api';
import { ekIconTimes } from '@ekon-client/shared/common/ekon-icons';
import { Observable, of } from 'rxjs';

import { FileDataSource } from '../../../../../extras';
import { FileService } from '../../../../../services';

export const PREVIEW_FILE_DATA = new InjectionToken<FileDataSource>('Preview File Data');
export const PREVIEW_FILE_ID = new InjectionToken<string>('Preview File Id');
export const PREVIEW_OVERLAY_REF = new InjectionToken<OverlayRef>('Preview Overlay Ref');


@Component({
  selector: 'ekon-image-preview-overlay',
  template: `
    <button
      mat-mini-fab
      class="close-btn"
      (click)="close()"
    >
      <ekon-icon [icon]="ekIconTimes"></ekon-icon>
    </button>

    <dkm-file-view-image
      [fileMeta]="fileMeta | async"
      [hideCredentials]="true"
      (wrapperClick)="close()"
    ></dkm-file-view-image>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }

      .close-btn {
        position: fixed;
        z-index: 999;
        inset-block-start: 16px;
        inset-inline-end: 16px;
        background-color: rgba(0, 0, 0, 0.5) !important;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePreviewOverlayComponent {
  ekIconTimes = ekIconTimes;

  fileMeta: Observable<FileMetadata>;

  constructor(
    @Inject(PREVIEW_FILE_DATA) fileData: FileDataSource,
    @Inject(PREVIEW_FILE_ID) fileId: string,
    @Inject(PREVIEW_OVERLAY_REF) private overlayRef: OverlayRef,
    private fileService: FileService
  ) {
    this.fileMeta = fileData ? of(fileData.file) : fileService.getFileMeta(fileId);
  }

  close(): void {
    this.overlayRef.hasAttached() && this.overlayRef.detach();
  }
}
