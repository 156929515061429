import { Component, NgZone, OnInit } from '@angular/core';
import { EkonAuthService } from '@ekon-client/auth';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'dkm-chatbot-settings',
  templateUrl: './chatbot-settings.component.html',
  styleUrls: ['./chatbot-settings.component.scss']
})
export class ChatbotSettingsComponent implements OnInit {
  chatBotEnabled = true;

  constructor(
    private zone: NgZone,
    private authService: EkonAuthService,
  ) {
    this.authService.isLoggedIn$.pipe(
      filter(r => !r),
      take(1)
    ).subscribe({
      next: () => {
        this.chatBotEnabled = false;
        this.setChatBotEnabled(false);
      }
    });
  }

  ngOnInit(): void {
    // const script = document.createElement('script');
    // script.onload = () => {
    //   console.warn('chatbot script loaded');
    //   this.zone.runOutsideAngular(() => {
    //     this.setChatBotEnabled(this.chatBotEnabled);
    //   });
    // };
    // script.src = 'https://wgchatbotstorage.blob.core.windows.net/messenger/aeonx_chat_messenger_weldgalaxyv5.min.js';
    //
    // document.body.appendChild(script);
    //
    this.setChatBotEnabled(this.chatBotEnabled);

  }

  toggleChatBot(checked: boolean): void {
    this.chatBotEnabled = checked;

    this.setChatBotEnabled(checked);
  }

  setChatBotEnabled(checked: boolean): void {
    this.zone.runOutsideAngular(() => {
      if (checked) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).__show_aeonx_chat_messenger__) {
          console.warn('enable chatbot');
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).__show_aeonx_chat_messenger__();
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).__hide_aeonx_chat_messenger__) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).__hide_aeonx_chat_messenger__();
        }
      }
    });
  }
}
