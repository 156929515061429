import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatLineModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import {
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EkonPaginationModule } from '@ekon-client/shared/common/ekon-pagination';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { BasketAddEditDialogComponent } from './components/basket-add-edit-dialog/basket-add-edit-dialog.component';
import { BasketAddItemComponent } from './components/basket-add-item/basket-add-item.component';
import { BasketSelectDialogComponent } from './components/basket-select-dialog/basket-select-dialog.component';

@NgModule({
  declarations: [
    BasketAddItemComponent,
    BasketAddEditDialogComponent,
    BasketSelectDialogComponent
  ],
  imports: [
    CommonModule,
    EkonIconsModule,
    MatButtonModule,
    MatMenuModule,
    MatLineModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatDialogModule,
    FlexModule,
    EkonDialogsModule,
    MatBadgeModule,
    EkonPaginationModule,
    MatListModule,
    TranslateModule
  ],
  exports: [
    BasketAddItemComponent,
    BasketSelectDialogComponent,
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-baskets/',
      multi: true
    }
  ]
})
export class DkmBasketsModule {}
