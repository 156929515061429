import { Observable } from 'rxjs';

export interface BreadcrumbItemLabel {
  label: string;
  isTranslationKey?: boolean;
}

export interface BreadcrumbItem {
  title: BreadcrumbItemLabel;
  url: string[];
  useAsTranslateKey?: boolean;
}

export type BreadcrumbItemTitle = BreadcrumbItemLabel
  | Observable<BreadcrumbItemLabel>
  | string
  | Observable<string>;
