<div class="breadcrumbs" *ngIf="breadcrumbs && breadcrumbs.length">
  <div *ngFor="let crumb of breadcrumbs; let i = index">
    <a [routerLink]="getCrumbUrl(i)" *ngIf="crumb.title as title">{{
      (title?.label ? (title?.isTranslationKey ? (title?.label | translate) : title?.label) : title) | titlecase
      }}</a>
    <div class="crumb-arrow">
      <ekon-icon
        *ngIf="i < breadcrumbs.length - 1; else downIcon"
        icon="angle-right"
      ></ekon-icon>
      <ng-template #downIcon>
        <ekon-icon icon="chevron-down"></ekon-icon>
      </ng-template>
    </div>
  </div>
</div>
<ng-content
  *ngIf="customTitle === true || customTitleTpl; else breadcrumbHeader"
></ng-content>
<ng-template #breadcrumbHeader>
  <h2
    *ngIf="(customTitle ? customTitle : (title$ | async)) as titleText"
    class="m-0 mt-8 title text-truncate"
  ><ng-container *ngIf="(titleText?.label ? (titleText?.isTranslationKey ? (titleText?.label | translate) : titleText?.label) : titleText) as text">{{
    titleUppercase ? (text | uppercase) : (titleTitlecase ? (text | titlecase) : (text))
    }}</ng-container></h2>
</ng-template>

<ng-container *ngIf="customTitleTpl && title$ | async"></ng-container>
<ng-container *ngIf="historyRecord$ | async as history"></ng-container>
