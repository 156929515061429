import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';

import { EkonAuthService } from './auth.service';
import { HybridOAuthStorage } from './hybrid-oauth-storage.service';  // Import HybridOAuthStorage


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OAuthModule.forRoot() // Initialize OAuthModule
  ],
  exports: [
    OAuthModule
  ]
})
export class AuthModule {

  constructor(
    @Optional() @SkipSelf() parentModule: AuthModule // Prevent multiple imports
  ) {
    if (parentModule) {
      throw new Error(
        'AuthModule is already loaded. Import in your base AppModule only.'
      );
    }
  }

  // Configure the AuthModule with providers
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        // OAuthService is required for handling OAuth2/OIDC flows
        OAuthService,

        // Use HybridOAuthStorage as the token storage service
        {
          provide: OAuthStorage,
          useClass: HybridOAuthStorage
        },

        // Provide the EkonAuthService
        EkonAuthService,

        // // Configure AuthConfig based on GlobalConfigurationService
        // {
        //   provide: AuthConfig,
        //   useFactory: (configService: GlobalConfigurationService) => {
        //     const authSettings = configService.authSettings;

        //     return ({
        //       issuer: authSettings.AUTHORITY,
        //       redirectUri: authSettings.REDIRECT_URI,
        //       clientId: authSettings.CLIENT_ID,
        //       responseType: authSettings.RESPONSE_TYPE,
        //       scope: authSettings.SCOPE,
        //       postLogoutRedirectUri: `${window.location.origin}`, // Add postLogoutRedirectUri
        //       silentRefreshRedirectUri: `${window.location.origin}/silent-refresh`,   // Add silent refresh redirect URI
        //       sessionChecksEnabled: true,                                  // Enable session checks for session expiry
        //       useIdTokenHintForSilentRefresh: true,                        // Use ID token hint for silent refresh
        //       clearHashAfterLogin: true,                                   // Clears hash after login (default behavior)
        //       showDebugInformation: authSettings.DEBUG,
        //       strictDiscoveryDocumentValidation: authSettings.STRICT_DISCOVERY_DOCUMENT
        //     });
        //   },
        //   deps: [GlobalConfigurationService]
        // },

        // Configure OAuthModuleConfig based on GlobalConfigurationService
        {
          provide: OAuthModuleConfig,
          useFactory: (configService: GlobalConfigurationService) => ({
            resourceServer: {
              allowedUrls: [configService.environment.API_PATH],
              sendAccessToken: true
            }
          }),
          deps: [GlobalConfigurationService]
        }
      ]
    };
  }
}
