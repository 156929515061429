import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
  ekIconEllipsisV,
  ekIconLink, ekIconRelatedPosts,
  ekIconShareOutline, ekIconStores,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { SharingActionsMenuComponent } from './components/sharing-actions-menu/sharing-actions-menu.component';



@NgModule({
  declarations: [SharingActionsMenuComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    MatMenuModule,
    MatTooltipModule,
    EkonIconsModule,
    TranslateModule
  ],
  exports: [SharingActionsMenuComponent],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-partials/src/lib/sharing/',
      multi: true
    }
  ]
})
export class EkonPartialsSharingModule {
  constructor(
    library: EkonIconsLibraryService
  ) {
    library.addIcons(
      ekIconLink,
      ekIconShareOutline,
      ekIconEllipsisV,
      ekIconRelatedPosts,
      ekIconStores,
    );
  }
}
