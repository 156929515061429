/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserHeaderModel } from './userHeaderModel';


export interface BlogPostHeader { 
    id?: string;
    title?: string | null;
    /**
     * Gets or sets the picture identifier
     */
    imageId?: string | null;
    bodyOverview?: string | null;
    domains?: Array<string> | null;
    tags?: Array<string> | null;
    lastUpdateAt?: Date;
    publishedAt?: Date | null;
    publishedBy?: string | null;
    publisher?: UserHeaderModel;
    create?: boolean;
    read?: boolean;
    update?: boolean;
    remove?: boolean;
}

