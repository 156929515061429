import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DkmDomain } from '@ekon-client/dkm-api';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import { DomainSelectorService } from '@ekon-client/shared/features/dkm-domains-services';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { DomainSelectorDialogComponent } from '../domain-selector-dialog/domain-selector-dialog.component';

@Component({
  selector: 'dkm-core-domains-bar',
  templateUrl: './domains-bar.component.html',
  styleUrls: ['./domains-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainsBarComponent {
  domainsSelected: Observable<DkmDomain[]>;

  constructor(
    private domainSelectorService: DomainSelectorService,
    private dialog: MatDialog,
    public globalConfig: GlobalConfigurationService,
    public router: Router
  ) {

    this.domainsSelected = domainSelectorService.domainsSelected$.pipe(
      map((d: DkmDomain[]) => (d && d.length ? d : null))
    );
  }

  selectDomains(): void {
    this.dialog.open(DomainSelectorDialogComponent, {
      ...getModalDefaultConfig(),
      maxWidth: '500px'
    });
  }

  goToDomain(id: string): void {
    this.router.navigate(['domains', id]);
  }

  deselectDomain(id: string): void {
    this.domainSelectorService.domainsSelected$.pipe(
      take(1)
    ).subscribe({
      next: (selected: DkmDomain[]) => this.domainSelectorService.setDomains(
        selected.filter(d => d.id !== id)
      )
    });
  }
}
