/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * DKM Subscription plans.   Each plan is represented by a claim.  consumer  serviceProvider  domainManager  siteManager  admin
 */
export type SubscriptionPlans = 'consumer' | 'serviceProvider' | 'domainManager' | 'siteManager' | 'admin';

export const SubscriptionPlans = {
    Consumer: 'consumer' as SubscriptionPlans,
    ServiceProvider: 'serviceProvider' as SubscriptionPlans,
    DomainManager: 'domainManager' as SubscriptionPlans,
    SiteManager: 'siteManager' as SubscriptionPlans,
    Admin: 'admin' as SubscriptionPlans
};

