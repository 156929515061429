<mat-card *ngIf="user">
  <dkm-core-ava-viewer
    [fileLink]="user.avatarUrl"
    [noShape]="true"
    (click)="handleClick(user)"
  ></dkm-core-ava-viewer>

  <h3>
    <a (click)="handleClick(user)">{{ (user?.fullName | ekonNotEmpty) ? user?.fullName : user?.email }}</a>
  </h3>

  <ekon-tags
    *ngIf="user.tags && user.tags.length"
    [tags]="user.tags"
    [minimal]="true"
    class="card-tags fadeout"
  ></ekon-tags>

</mat-card>
