import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Paginator } from '@ekon-client/shared/common/ekon-pagination';
import { isString as _isString } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';

import {
  FileDataSource,
  FileManagerModes,
  FileTypesWeCanView
} from '../../../../../extras';
import { FileService } from '../../../../../services';

@Component({
  selector: 'ekon-file-viewer-carousel',
  templateUrl: './file-viewer-carousel.component.html',
  styleUrls: ['./file-viewer-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileViewerCarouselComponent implements OnInit {

  FileTypesWeCanView = FileTypesWeCanView;

  currentItem$: Observable<FileDataSource>;
  paginator: Paginator<FileDataSource>;

  @Input() fileType: string | string[];


  @Input() hideCredentials: boolean;
  @Input() initialFileIndex: number;
  @Input() specificDomain: string;
  @Input() userFilesOnly: boolean;

  @Output() fileChanged: EventEmitter<FileDataSource> = new EventEmitter();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private fileService: FileService
  ) {
  }

  ngOnInit(): void {
    this.paginator = new Paginator<FileDataSource>(
      pagination => this.fileService.listFilesWithAuthorsAlt(
        this.userFilesOnly ? FileManagerModes.PROFILE : FileManagerModes.GENERAL,
        {
          ...pagination,
          filters: {
            ...pagination.filters,
            contentType: this.fileType
              ? (_isString(this.fileType)
                ? FileTypesWeCanView.types[this.fileType]
                : this.fileType)
              : undefined
          }
        },
        this.specificDomain
      ),
      {
        pageNumber: this.initialFileIndex + 1 || 1,
        pageSize: 1
      }
    );

    this.currentItem$ = this.paginator.items$.pipe(
      map(files => {
        console.warn('currentItem$ map', files && files[0]);
        return files && files[0]
      }),
      tap(fileData => console.warn('currentItem$', fileData)),
      tap(fileData => this.setCurrentFile(fileData))
    );
  }

  setCurrentFile(fileData: FileDataSource): void {
    this.fileChanged.emit(fileData ? fileData : null);
  }

  prevFile(): void {
    this.paginator.pageNumber$.pipe(
      take(1),
      withLatestFrom(this.paginator.totalItems$)
    ).subscribe({
      next: ([pageNumber, totalItems]: number[]) => this.paginator.pushPaginationState({
        pageNumber: (pageNumber - 1) < 1 ? totalItems : pageNumber - 1,
        pageSize: 1
      })
    });
  }

  nextFile(): void {
    this.paginator.pageNumber$.pipe(
      take(1),
      withLatestFrom(this.paginator.totalItems$)
    ).subscribe({
      next: ([pageNumber, totalItems]: number[]) => this.paginator.pushPaginationState({
        pageNumber: (pageNumber + 1) > totalItems ? 1 : pageNumber + 1,
        pageSize: 1
      })
    });
  }
}
