export interface TrackByItemData {
  id?: string;
}

/**
 * @param index
 * @param item
 */
export function trackById<T extends TrackByItemData = TrackByItemData>(
  index: number,
  item: T
): string {
  return item?.id;
}
