import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { BarGraphComponent } from './components/bar-graph/bar-graph.component';
import { BoxplotGraphComponent } from './components/boxplot-graph/boxplot-graph.component';
import { MeterGraphComponent } from './components/meter-graph/meter-graph.component';
import { PieGraphComponent } from './components/pie-graph/pie-graph.component';
import { WordcloudComponent } from './components/wordcloud/wordcloud.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    MeterGraphComponent,
    BarGraphComponent,
    PieGraphComponent,
    BoxplotGraphComponent,
    WordcloudComponent
  ],
  exports: [
    MeterGraphComponent,
    BarGraphComponent,
    PieGraphComponent,
    BoxplotGraphComponent,
    WordcloudComponent
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/common/ekon-graphs/',
      multi: true
    }
  ]
})
export class EkonGraphsModule {}
