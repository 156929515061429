/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateFilesUserPostModel } from './createFilesUserPostModel';
import { CreateExternalLinkUserPostModel } from './createExternalLinkUserPostModel';
import { CreateLegalEntityUserPostModel } from './createLegalEntityUserPostModel';
import { CreateMeetupUserPostModel } from './createMeetupUserPostModel';
import { CreatePageUserPostModel } from './createPageUserPostModel';
import { CreateDomainUserPostModel } from './createDomainUserPostModel';
import { CreateProductUserPostModel } from './createProductUserPostModel';
import { CreateReshareUserPostModel } from './createReshareUserPostModel';
import { CreateBlogUserPostModel } from './createBlogUserPostModel';


export interface CreatePlainUserPostModel { 
    kind: string;
    /**
     * Feed content
     */
    value: string;
    /**
     * Tags to filter Feeds
     */
    tags?: Array<string> | null;
}

