/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InvitationLevel } from './invitationLevel';


export interface CreateInvitationModel { 
    toId?: string;
    inviteeUserEmail?: string | null;
    membershipLevel?: InvitationLevel;
    /**
     * Use for invitation name/title
     */
    name?: string | null;
    /**
     * Use for short invitation message
     */
    shortDescription?: string | null;
}

