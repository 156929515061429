<ekon-dialog-wrapper [isFullscreen]="false" [transparentMode]="true">
  <div mat-dialog-title class="h2 mb-24">
    <ng-container *ngIf="viewState">
      <div fxLayout="row" fxLayoutAlign="start">
        <ekon-icon
          icon="glasses"
          size="sm"
          class="pr-8"
          fxFlex="0 0 auto"
        ></ekon-icon>
        <span
          fxFlex="1 1 100%"
          class="text-truncate"
        >{{viewState.file.name}}</span>

        <button
          mat-button
          aria-label="download"
          (click)="downloadFile(viewState.file)"
          fxFlex="0 0 auto"
        >
          <ekon-icon icon="download" class="pr-8"></ekon-icon>
          <span>Download</span>
        </button>

        <button
          mat-icon-button
          fxFlex="0 0 auto"
          [matMenuTriggerFor]="manageMenu"
        >
          <ekon-icon icon="ellipsis-v" size="lg"></ekon-icon>
        </button>

        <mat-menu #manageMenu="matMenu">
          <ng-template matMenuContent>
            <button mat-menu-item aria-label="edit" (click)="editFile(viewState.file)">
              <ekon-icon icon="edit" class="pr-8"></ekon-icon>
              <span>Edit</span>
            </button>
            <button
              mat-menu-item
              aria-label="delete"
              (click)="deleteFile(viewState.file, carousel)"
              class="warn-fg"
            >
              <ekon-icon icon="trash-alt" class="pr-8"></ekon-icon>
              <span>Delete</span>
            </button>
          </ng-template>
        </mat-menu>
      </div>
      <div class="author"> by
        <dkm-core-user-name [user]="viewState.user"></dkm-core-user-name>
      </div>
    </ng-container>
  </div>
  <mat-dialog-content>
    <ekon-file-viewer-carousel #carousel
      [fileType]="data.fileType"
      [initialFileIndex]="data.initialFileIndex"
      (fileChanged)="fileChanged($event)"
      [hideCredentials]="true"
      [specificDomain]="data.specificDomain"
      [userFilesOnly]="data.userFilesOnly"
    ></ekon-file-viewer-carousel>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayoutAlign="end center"
    *ngIf="data.selectable"
  >
    <button
      *ngIf="data.selectable && viewState"
      mat-button
      (click)="fileSelected(viewState)"
      color="primary"
    >Select
    </button>
  </mat-dialog-actions>
</ekon-dialog-wrapper>
