import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import {
  ekIconAngleLeft,
  ekIconAngleRight,
  ekIconDownload,
  ekIconEdit,
  ekIconEllipsisV,
  ekIconExclamationTriangle,
  ekIconGlasses,
  ekIconTrashAlt,
  EkonIconsLibraryService,
  EkonIconsModule,
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { DkmUserProfileModule } from '@ekon-client/shared/features/dkm-user-profile';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxAudioPlayerModule } from 'ngx-audio-player';

import { FileViewDialogComponent } from './components/file-view-dialog/file-view-dialog.component';
import { FileViewAudioComponent } from './components/file-viewer/components/file-view-audio/file-view-audio.component';
import { FileViewDocumentComponent } from './components/file-viewer/components/file-view-document/file-view-document.component';
import { FileViewImageComponent } from './components/file-viewer/components/file-view-image/file-view-image.component';
import { FileViewUnviewableComponent } from './components/file-viewer/components/file-view-unviewable/file-view-unviewable.component';
import { FileViewVideoComponent } from './components/file-viewer/components/file-view-video/file-view-video.component';
import { FileViewerComponent } from './components/file-viewer/components/file-viewer/file-viewer.component';
import { FileViewerCarouselComponent } from './components/file-viewer/components/file-viewer-carousel/file-viewer-carousel.component';
import { ImagePreviewOverlayComponent } from './components/file-viewer/components/image-preview-overlay/image-preview-overlay.component';
import { FileViewImageFullsizeDirective } from './components/file-viewer/directives/file-view-image-fullsize.directive';

@NgModule({
  declarations: [
    FileViewDialogComponent,
    FileViewerComponent,
    FileViewerCarouselComponent,
    FileViewImageComponent,
    FileViewAudioComponent,
    FileViewVideoComponent,
    FileViewDocumentComponent,
    FileViewUnviewableComponent,
    FileViewImageFullsizeDirective,
    ImagePreviewOverlayComponent,
  ],
  imports: [
    CommonModule,

    MatProgressSpinnerModule,
    PdfViewerModule,
    NgxAudioPlayerModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    FlexModule,
    EkonDialogsModule,
    DkmUserProfileModule,
    MatTooltipModule,
    MatRippleModule,
    EkonIconsModule,
    TranslateModule,
  ],
  exports: [
    FileViewerComponent,
    FileViewerCarouselComponent,
    FileViewImageFullsizeDirective,
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-files/src/lib/file-view/',
      multi: true,
    },
  ],
})
export class FileViewModule {
  constructor(ekLib: EkonIconsLibraryService) {
    ekLib.addIcons(
      ekIconEllipsisV,
      ekIconEdit,
      ekIconTrashAlt,
      ekIconExclamationTriangle,
      ekIconAngleLeft,
      ekIconAngleRight,
      ekIconGlasses,
      ekIconDownload
    );
  }
}
