import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate, CanLoad, Data, Route, UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

import { GlobalConfigurationService } from '../global-configuration/global-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class EkonConfigGuardService implements CanActivate, CanLoad {
  constructor(
    private globalConfig: GlobalConfigurationService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.runGuard(route.data);
  }

  canLoad(route: Route): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.runGuard(route.data);
  }

  runGuard(data: Data): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return data.envProp ? this.globalConfig[data.envProp] : true;
  }
}
