/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Filter { 
    freeTextSearchFilter?: string | null;
    tagFilter?: Array<string> | null;
    /**
     * User OR or AND.  Default is OR
     */
    tagFilterMode?: boolean;
    categoryFilter?: Array<string> | null;
    /**
     * For usage in Files context, otherwise ignore
     */
    contentType?: Array<string> | null;
    /**
     * Filter for a specific user.  Used for example, when viewing other user profile, to get user\'s posts, products/services, pages, etc.  Otherwise, ignore.
     */
    userId?: string;
}

