import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthModule } from '@ekon-client/auth';
import { BASE_PATH } from '@ekon-client/dkm-api';
import { DkmEventsModule } from '@ekon-client/dkm-events';
import {
  DkmLayoutsModule,
  VerticalLayout3Module
} from '@ekon-client/dkm-layouts';
import { CloudConfigModule, getBaseApi, GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import {
  EKON_TRANSLATE_LOADER_PATH,
  initTranslations, messageFormatConfigProvider,
  translateModuleSetup
} from '@ekon-client/shared/common/ekon-translation';
import { SelectedDomainsInterceptor } from '@ekon-client/shared/features/dkm-domains';
import { EkonAuthModule } from '@ekon-client/shared/features/ekon-auth';
import { EkonLayoutUtilsModule } from '@ekon-layout-utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ekonLayoutConfig } from './ekon-layout-config';
import { StoreModule } from '@ngrx/store';
import { ENTITY_SELECTOR } from '@ekon-client/shared/common/ekon-wysiwyg-adapter';
import { EntitySelectorService } from '@ekon-client/shared/features/dkm-entity-selectors';

const LAYOUTS = [
  VerticalLayout3Module.forRoot()
  // VerticalLayout2Module.forRoot()
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,

    CloudConfigModule.forRoot(environment),

    TranslateModule.forRoot(translateModuleSetup()),

    EkonAuthModule.forRoot(),
    AppRoutingModule.forRoot(),

    EkonLayoutUtilsModule.forRoot(ekonLayoutConfig),

    ...LAYOUTS,
    DkmLayoutsModule,

    DkmEventsModule,

    AuthModule.forRoot(),

    StoreModule.forRoot({}, {}),

    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    //StoreDevtoolsModule.instrument(),
  ],
  providers: [
    Title,

    { provide: BASE_PATH, useFactory: getBaseApi, deps: [GlobalConfigurationService] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SelectedDomainsInterceptor,
      multi: true
    },
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: '',
      multi: true
    },

    // HACKS & WORKAROUNDS
    { provide: ENTITY_SELECTOR, useExisting: EntitySelectorService },
    // { provide: TOURS_USER_PROFILE_REF, useExisting: UserProfileService },


    ...messageFormatConfigProvider()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    translateService: TranslateService
  ) {
    initTranslations(translateService, true);
  }
}
