/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CreateResponse } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { ProblemDetails } from '../model/models';
import { ResponseDetails } from '../model/models';
import { ResponseTotals } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ResponseServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createResponse 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createResponse(createResponse?: CreateResponse, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<ResponseDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteResponse(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param refId 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteResponseByRedId(refId: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findResponseById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<ResponseDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param refId 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findResponseByRefId(refId: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<ResponseDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listUserResponses(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<ResponseDetails>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param refId 
     * @param xSelectedDomain Comma-delimited domain id
     */
    totalResponsesByRedId(refId: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<ResponseTotals>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param responseDetails 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateResponse(id: string, responseDetails?: ResponseDetails, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

}
