/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Element } from './element';
import { Tile } from './tile';


export interface GridViewElement extends Element { 
    tiles?: Array<Tile> | null;
    gutterSize?: string | null;
    readonly hasGutterSize?: boolean;
    rowHeight?: string | null;
    readonly hasRowHeight?: boolean;
}

