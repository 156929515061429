import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';
import { EkonIsEmptyPipeModule } from '@ekon-client/shared/common/ekon-utils';
import { AvaViewerModule } from '@ekon-client/shared/features/dkm-files';

import { UserListMiniComponent } from './user-list-mini.component';



@NgModule({
  declarations: [
    UserListMiniComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    AvaViewerModule,
    EkonIconsModule,
    MatButtonModule,
    EkonIsEmptyPipeModule
  ],
  exports: [
    UserListMiniComponent
  ]
})
export class UserListMiniModule { }
