import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Category,
  CategoryService,
  CategoryServiceInterface,
  Configuration,
  CreateCategoryContent,
  UpdateCategoryContent,
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CategoryEventsServiceInterface } from './CategoryEventsServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class CategoryEventsService
  implements CategoryServiceInterface, CategoryEventsServiceInterface {
  get configuration(): Configuration {
    return this.categoryService.configuration;
  }

  set configuration(val: Configuration) {
    this.categoryService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.categoryService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.categoryService.defaultHeaders = val;
  }

  /**
   * The mechanism for monitoring Create action
   */
  private categoryCreatedSubject: Subject<Category> = new Subject();
  get categoryCreated(): Observable<Category> {
    return this.categoryCreatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Delete action
   */
  private categoryDeletedSubject: Subject<string> = new Subject();
  get categoryDeleted(): Observable<string> {
    return this.categoryDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Update action
   */
  private categoryUpdatedSubject: Subject<string> = new Subject();
  get categoryUpdated(): Observable<string> {
    return this.categoryUpdatedSubject.asObservable();
  }

  constructor(
    private categoryService: CategoryService,
    private progressBar: EkonProgressBarService
  ) { }

  createCategory(
    createCategoryContent?: CreateCategoryContent,
    domains?: string
  ): Observable<Category> {
    return this.progressBar
      .apply(this.categoryService.createCategory(createCategoryContent, domains))
      .pipe(tap((c: Category) => this.categoryCreatedSubject.next(c)));
  }

  deleteCategory(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.categoryService.deleteCategory(id))
      .pipe(tap(() => this.categoryDeletedSubject.next(id)));
  }

  findCategoryById(id: string): Observable<Category> {
    return this.progressBar.apply(this.categoryService.findCategoryById(id));
  }

  listCategories(): Observable<Array<Category>> {
    return this.progressBar.apply(this.categoryService.listCategories());
  }

  updateCategory(
    id: string,
    categoryContent: UpdateCategoryContent,
    domains?: string
  ): Observable<unknown> {
    return this.progressBar
      .apply(this.categoryService.updateCategory(id, categoryContent, domains))
      .pipe(tap(() => this.categoryUpdatedSubject.next(id)));
  }

  nestCategory(categoryId: string, parentId?: string): Observable<unknown> {
    return this.progressBar.apply(
      this.categoryService.nestCategory(categoryId, parentId)
    );
  }
}
