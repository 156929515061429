<button
  mat-button
  class="language-button"
  [matMenuTriggerFor]="languageMenu"
>
  <div class="selected-lang">
    <ekon-icon [icon]="'flag-'+selectedLanguage.flag" [color]="true" class="locale-flag"></ekon-icon>
    <span class="iso text-uppercase">{{selectedLanguage.key}}</span>
  </div>
</button>

<mat-menu
  #languageMenu="matMenu"
  [overlapTrigger]="false"
>
  <button
    mat-menu-item
    *ngFor="let lang of languages"
    (click)="setLanguage(lang)"
  >
    <div class="lang-item-content">
      <ekon-icon [icon]="'flag-'+lang.flag" [color]="true" class="locale-flag"></ekon-icon>
      <span class="iso">{{lang.label}}</span>
    </div>
  </button>
</mat-menu>
