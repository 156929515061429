<div class="image-wrapper" (click)="imageClick.emit(fileLink)">
  <div
    class="logo-image"
    *ngIf="!imageLoadDisabled && logoImage | async as logo; else logoPlaceholder"
    [ekonImageFullscreen]="fileLink"
    [ekonImageFullscreenEnabled]="previewEnabled"
    [style.background-image]="logo"
  ></div>
  <ng-template #logoPlaceholder>
    <ng-content></ng-content>
  </ng-template>
</div>
