/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Invitation level, to be used in: Domains, Pages, Legal Entities, Blog, etc.  Guest  Contributor  Owner
 */
export type InvitationLevel = 'Guest' | 'Contributor' | 'Owner';

export const InvitationLevel = {
    Guest: 'Guest' as InvitationLevel,
    Contributor: 'Contributor' as InvitationLevel,
    Owner: 'Owner' as InvitationLevel
};

