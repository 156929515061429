import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EkonOverflowDirective } from './ekon-overflow.directive';



@NgModule({
  declarations: [
    EkonOverflowDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EkonOverflowDirective
  ]
})
export class EkonOverflowModule { }
