<div
  [formGroup]="form"
  fxLayout="column" fxLayoutAlign="start stretch"
>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="16px">
    <mat-form-field appearance="outline" fxFlex="1 1 50%">
      <mat-label>Country</mat-label>
      <input matInput formControlName="country">
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="1 1 50%">
      <mat-label>City</mat-label>
      <input matInput formControlName="city">
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="16px">
    <mat-form-field appearance="outline" fxFlex="1 1 100%">
      <mat-label>Street</mat-label>
      <input matInput formControlName="street">
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="0 0 100px">
      <mat-label>Number</mat-label>
      <input matInput formControlName="number">
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="16px">
    <mat-form-field appearance="outline" fxFlex="1 1 100%">
      <mat-label>Address name</mat-label>
      <input matInput formControlName="addressName">
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="0 0 200px">
      <mat-label>Postal code</mat-label>
      <input matInput formControlName="postCode">
    </mat-form-field>
  </div>
</div>
