import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ekIconEdit,
  ekIconTrashAlt,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { AvaViewerModule } from '../ava-viewer';
import { FileSelectModule } from '../file-select';
import { AvaSetterComponent } from './ava-setter.component';



@NgModule({
  declarations: [
    AvaSetterComponent
  ],
  exports: [
    AvaSetterComponent,
    FileSelectModule,
    AvaViewerModule
  ],
  imports: [
    CommonModule,
    FileSelectModule,
    AvaViewerModule,
    EkonIconsModule,
    TranslateModule
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-files/src/lib/ava-setter/',
      multi: true
    }
  ]
})
export class AvaSetterModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconTrashAlt,
      ekIconEdit
    );
  }
}
