import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ekIconQuestion } from '@ekon-client/shared/common/ekon-icons';

import { EkonTourService } from '../../services/ekon-tour.service';

@Component({
  selector: 'ekon-client-tour-launch',
  templateUrl: './tour-launch.component.html',
  styleUrls: ['./tour-launch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TourLaunchComponent {
  @Input() tourKey?: string;

  ekIconQuestion = ekIconQuestion;

  constructor(
    private tourService: EkonTourService
  ) {}

  startTour(): void {
    this.tourService.start(this.tourKey);
  }
}
