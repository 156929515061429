<ekon-dialog-wrapper [isFullscreen]="true">
  <div mat-dialog-title>{{'USER_SELECTION' | translate }}</div>
  <mat-dialog-content>
    <ekon-paginator [config]="paginatorConfig" #paginator="ekonPaginator">
      <ekon-cards-grid>
        <ekon-client-user-card
          *ngFor="let user of paginator.items$ | async; trackBy: trackById"
          [user]="user"
          [selectMode]="true"
          (selected)="select(user)"
        ></ekon-client-user-card>
      </ekon-cards-grid>
    </ekon-paginator>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxFlex="0 0 auto">
    <button mat-button [mat-dialog-close]="false">{{'CANCEL' | translate: { default: 'Cancel' } }}</button>
  </mat-dialog-actions>
</ekon-dialog-wrapper>
