/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BlogInvitationModel } from './blogInvitationModel';
import { PageInvitationModel } from './pageInvitationModel';
import { DomainInvitationModel } from './domainInvitationModel';
import { LegalEntityInvitationModel } from './legalEntityInvitationModel';


export interface UserInvitationsListModel { 
    domainInvitations?: Array<DomainInvitationModel> | null;
    legalEntityInvitations?: Array<LegalEntityInvitationModel> | null;
    pageInvitations?: Array<PageInvitationModel> | null;
    blogInvitations?: Array<BlogInvitationModel> | null;
}

