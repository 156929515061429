import Control, { Options } from 'ol/control/Control';
import Geometry from 'ol/geom/Geometry';
import GeometryType from 'ol/geom/GeometryType';
import Draw, {
  createBox,
  createRegularPolygon,
  DrawEvent,
  GeometryFunction,
} from 'ol/interaction/Draw';
import { Vector } from 'ol/source'

export interface ShapesToolbarControlOptions extends Options {
  source?: Vector<Geometry>;
  drawEndCallback: (e: DrawEvent) => void;
}

export class ShapesToolbarControl extends Control {
  constructor(private options: ShapesToolbarControlOptions) {
    super({});
    Control.call(this, { ...options, element: this.initButtons() });
  }

  private initButtons(): HTMLElement {
    const toolbar = document.createElement('div');
    toolbar.className = 'shapes-toolbar ol-unselectable ol-control';
    toolbar.style.top = '4.5em';
    toolbar.style.left = '.5em';

    toolbar.appendChild(this.makeButton(GeometryType.POINT, 'P'));
    toolbar.appendChild(this.makeButton(GeometryType.LINE_STRING, 'L'));
    toolbar.appendChild(this.makeButton(GeometryType.POLYGON, 'Pl'));
    toolbar.appendChild(this.makeButton(GeometryType.CIRCLE, 'C'));
    toolbar.appendChild(this.makeButton(GeometryType.CIRCLE, 'B', createBox()));
    toolbar.appendChild(
      this.makeButton(GeometryType.CIRCLE, 'R', createRegularPolygon(4))
    );

    return toolbar;
  }

  makeButton(
    type: string,
    icon: string,
    geometryFunction?: GeometryFunction
  ): HTMLElement {
    const btn = document.createElement('button');
    btn.innerHTML = icon;
    btn.addEventListener('click', () => this.drawShape(type, geometryFunction));
    return btn;
  }

  drawShape(type: string, geometryFunction: GeometryFunction): void {
    const draw = new Draw({
      source: this.options.source,
      type,
      geometryFunction,
    });
    draw.once('drawend', (e: DrawEvent) => {
      // eslint-disable-next-line rxjs/no-ignored-observable
      this.getMap().removeInteraction(draw);
      this.options.drawEndCallback(e);
    });
    this.getMap().addInteraction(draw);
  }
}
