/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FaqDetails { 
    id: string;
    question?: string | null;
    answer?: string | null;
    /**
     * Set to TRUE for Platform\'s FAQ
     */
    isPlatformFaq?: boolean;
    domains?: Array<string> | null;
    tags?: Array<string> | null;
    sequenceNumber?: number | null;
    lastUpdateAt?: Date;
}

