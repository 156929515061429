import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { EkonCardsGridModule } from '@ekon-client/shared/common/ekon-cards-grid';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import { EkonPaginationModule } from '@ekon-client/shared/common/ekon-pagination';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { DkmBlogsModule as BlogsSharedModule } from '@ekon-client/shared/features/dkm-blogs';
import { DkmDomainsModule as DkmDomainsSharedModule } from '@ekon-client/shared/features/dkm-domains';
import { DkmLegalEntityModule as LegalEntitySharedModule } from '@ekon-client/shared/features/dkm-legal-entity';
import { CalendarListModule, PageCardModule, UserCardModule } from '@ekon-client/shared/features/dkm-partials';
import { TranslateModule } from '@ngx-translate/core';

import {
  BlogSelectorDialogComponent,
  DomainSelectorDialogComponent,
  LegalEntitySelectorDialogComponent,
  MeetupSelectorDialogComponent,
  PageSelectorDialogComponent
} from './components';
import { UserSelectorDialogComponent } from './components/user-selector-dialog/user-selector-dialog.component';



@NgModule({
  declarations: [
    PageSelectorDialogComponent,
    BlogSelectorDialogComponent,
    DomainSelectorDialogComponent,
    LegalEntitySelectorDialogComponent,
    MeetupSelectorDialogComponent,
    UserSelectorDialogComponent
  ],
  imports: [
    CommonModule,
    BlogsSharedModule,
    LegalEntitySharedModule,
    EkonPaginationModule,
    DkmDomainsSharedModule,
    PageCardModule,
    CalendarListModule,
    EkonDialogsModule,
    MatDialogModule,
    EkonCardsGridModule,
    FlexModule,
    MatButtonModule,
    TranslateModule,
    UserCardModule
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-entity-selectors/',
      multi: true
    },
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-pages/',
      multi: true
    }
  ]
})
export class DkmEntitySelectorsModule {}
