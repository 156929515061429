import { CommonModule } from '@angular/common';
import { NgModule,Pipe, PipeTransform } from '@angular/core';
import { isEmpty as _isEmpty } from 'lodash-es';


@Pipe({
  name: 'ekonIsEmpty',
})
export class EkonIsEmptyPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return _isEmpty(typeof value === 'string' ? value.trim() : value);
  }
}

@Pipe({
  name: 'ekonNotEmpty',
})
export class EkonNotEmptyPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return !_isEmpty(typeof value === 'string' ? value.trim() : value);
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [
    EkonIsEmptyPipe,
    EkonNotEmptyPipe
  ],
  exports: [
    EkonIsEmptyPipe,
    EkonNotEmptyPipe
  ],
})
export class EkonIsEmptyPipeModule {}
