import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ekon-report-entity',
  templateUrl: './report-entity-dialog.component.html',
  styleUrls: ['./report-entity-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportEntityDialogComponent {

  reasons = [
    {
      title: 'Suspicious or fake',
      summary: [
        'Misinformation (Spreading false or misleading information as if it were factual)',
        'Fraud or scam (Deceiving others to obtain money or access private information)',
        'Spam (Sharing irrelevant or repeated content to boost visibility or for monetary gain)',
        'Fake account (Inaccurate or misleading representation)',
        'Hacked account (Unauthorized account takeover)'
      ]
    },
    {
      title: 'Harassment of hateful speech',
      summary: [
        'Bullying or trolling (Attacking or intimidating others, or deliberately and repeatedly disrupting conversations)',
        'Sexual harassment (Unwanted romantic advances, requests for sexual favors, or unwelcome sexual remarks)',
        'Hateful or abusive speech (Hateful, degrading, or inflammatory speech)',
        'Spam (Sharing irrelevant or repeated content to boost visibility or for monetary gain)'
      ]
    },
    {
      title: 'Violence of physical harm',
      summary: [
        'Incites violence or is a threat (Encouraging violent acts or threatening physical harm)',
        'Shocking or gory (Shocking or graphic content)',
        'Terrorism or act of extreme violence (Depicting or encouraging terrorist acts or severe harm)'
      ]
    },
    {
      title: 'Adult content',
      summary: [
        'Nudity or sexual content (Nudity, sexual scenes or language, or sex trafficking)',
        'Shocking or gory (Shocking or graphic content)',
        'Sexual harassment (Unwanted romantic advances, requests for sexual favors, or unwelcome sexual remarks)'
      ]
    },
    {
      title: 'Intellectual property infringement of defamation',
      summary: [
        'Copyright infringement (Using your copyrighted work without your permission)',
        'Trademark infringement (Using your trademark without your permission)',
        'Defamation (Sharing falsehoods about you that could harm your reputation)'
      ]
    },
    {
      title: 'I don not want to see this',
      summary: [
        'I\'m not interested in the author',
        'I\'m not interested in this topic',
        'I\'ve seen too many posts on this topic',
        'I\'ve seen this post before',
        'This post is old',
        'It\'s something else'
      ]
    }
  ];

  currentSummaries: string[];

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialogRef<ReportEntityDialogComponent>,
    private _cdr: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      title: [null, Validators.required],
      summary: [null, Validators.required]
    });
  }

  validateAndSubmit(): void {
    if (this.form.valid) {
      this.dialog.close(this.form.getRawValue());
    }
  }

  setSummaries(summary: string[]) {
    console.warn('setSummaries', summary);
    this.currentSummaries = summary;
    this._cdr.detectChanges();
  }
}
