import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogConfirmData {
  title?: string;
  message?: string;
  messageHTML?: string;
  actionLabels?: DialogConfirmActionsData,
  description?: DialogConfirmDescriptionConfig
}

export interface DialogConfirmActionsData {
  cancel?: string;
  ok?: string;
}

export interface DialogConfirmDescriptionConfig {
  enabled?: boolean;
  required?: boolean;
  label?: string;
  placeholder?: string;
}

@Component({
  template: `
    <div *ngIf="data.title" mat-dialog-title class="h3 p-0">
      {{ data.title }}
    </div>

    <mat-dialog-content>
      <div class="mb-4" *ngIf="data.message">{{ data.message }}</div>
      <div
        class="mb-4"
        *ngIf="data.messageHTML"
        [innerHTML]="data.messageHTML"
      ></div>

      <mat-form-field
        *ngIf="data?.description?.enabled"
        appearance="outline" class="w-100-p mt-16"
      >
        <mat-label *ngIf="data?.description?.label">{{ data?.description?.label }}</mat-label>
        <textarea
          matInput
          [formControl]="descriptionControl"
          [placeholder]="data?.description?.placeholder"
          cdkTextareaAutosize
          cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="10"
        ></textarea>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions
      fxLayout="row"
      fxLayoutAlign="end"
      fxLayoutGap="8px"
      class="py-24"
    >
      <button
        mat-button
        [mat-dialog-close]="false"
      >{{data?.actionLabels?.cancel || ('CANCEL' | translate)}}</button>
      <button
        mat-button
        class="mat-primary"
        [mat-dialog-close]="data?.description?.enabled && descriptionControl.value || true"
        [disabled]="descriptionControl.invalid"
      >{{data?.actionLabels?.ok || ('OK'| translate)}}</button>
    </mat-dialog-actions>
  `,
  styles: []
})
export class EkonDialogConfirmComponent {
  public descriptionControl: FormControl;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogConfirmData) {
    this.descriptionControl = new FormControl(
      null,
      data?.description?.required ? Validators.required : undefined
    );
  }
}
