import { ChangeDetectionStrategy, Component, EventEmitter,Input, Output } from '@angular/core';
import {
  HiddenContentDTO,
  PageDetails,
  PageHeader,
  ReportedContentDTO,
  UserPostModel,
  UserProfileHeader
} from '@ekon-client/dkm-api';
import { ekIconNewspaper, ekIconPosts, ekIconShoppingBag, ekIconStores } from '@ekon-client/shared/common/ekon-icons';

interface ReportedContent extends ReportedContentDTO {
  data?: PageHeader | PageDetails/* | UserPostModel*/,
  userReported?: UserProfileHeader
}

@Component({
  selector: 'ekon-client-blocked-item',
  templateUrl: './blocked-item.component.html',
  styleUrls: ['./blocked-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockedItemComponent {

  typeIcons = {
    'Page': ekIconNewspaper,
    'Product': ekIconShoppingBag,
    'Domain': ekIconStores,
    'Post': ekIconPosts,
  }

  @Input() data?: ReportedContent;
  @Input() reportMode?: boolean;
  @Input() useReasonAsTitle?: boolean;

  @Output() unblock = new EventEmitter<HiddenContentDTO>();
  @Output() acceptReport = new EventEmitter<ReportedContentDTO>();
  @Output() viewContent = new EventEmitter<ReportedContentDTO>();

}
