import { Direction, Directionality } from '@angular/cdk/bidi';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter, HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  ekIconChevronDown,
  ekIconChevronUp, ekIconCompressAlt, ekIconExpandArrowsAlt,
  ekIconInfo, ekIconPlus,
  ekIconProjectDiagram, ekIconTimes, ekIconUnlock, EkonIconDefinition
} from '@ekon-client/shared/common/ekon-icons';
import { EkonPermissionActionType, PermissionsService } from '@ekon-client/shared/features/dkm-permissions';
import {
  create,
  D3ZoomEvent,
  drag,
  DragBehavior,
  DraggedElementBaseType,
  forceCollide,
  forceLink,
  forceManyBody,
  forceSimulation,
  line,
  select, Simulation, SimulationLinkDatum,
  SimulationNodeDatum,
  SubjectPosition,
  zoom
} from 'd3';
import {
  keyBy as _keyBy,
  merge as _merge,
  values as _values
} from 'lodash-es';
import ResizeObserver from 'resize-observer-polyfill';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  SelectionSVG,
  SelectionSVGCircle,
  SelectionSVGG,
  SelectionSVGLine,
  SelectionSVGPath, SelectionSVGRect, SelectionSVGText
} from '../../extras/SelectionTypes';
import {
  ForceGraphConfig,
  ForceGraphData, ForceGraphNode,
  ForceGraphNodeKind, ForceGraphNodeKindConfig, ForceGraphNodeSimulated,
  ForceSelectionSVGG
} from './extras';
import { arrangeToolBtns, bakeToolButton } from './helpers';


// isRoot Offset of Node
const IRON = 8;

@Component({
  selector: 'ekon-force-directed',
  template: `
    <button
      mat-icon-button
      class="toggle-fullscreen-btn"
      (click)="toggleFullscreen()"
    >
      <ekon-icon [icon]="maximized ? ekIconCompressAlt : ekIconExpandArrowsAlt" size="lg"></ekon-icon>
    </button>

    <div
      class="graph-legend"
      [ngClass]="{ 'minimized': !(legendExpanded$ | async) }"
    >
      <h3
        class="legend-title"
        (click)="toggleLegend()"
        matRipple
      >
        <span>Legend</span>
        <ekon-icon [icon]="(legendExpanded$ | async) ? ekIconChevronUp : ekIconChevronDown"></ekon-icon>
      </h3>

      <div class="legend-group">
        <h4 class="legend-group-title">Entities</h4>
        <div
          *ngFor="let kind of kinds"
          class="legend-item"
        >
          <div
            class="legend-item-icon node-point node-btn"
            [style.border-color]="kind.color"
          >
            <ekon-icon [icon]="kind.icon"></ekon-icon>
          </div>
          <div class="legend-item-text">
            <div class="title">{{kind.label | titlecase}}</div>
          </div>
        </div>
      </div>

      <div class="legend-group">
        <h4 class="legend-group-title">Actions</h4>
        <ng-container *ngFor="let action of actions">
          <div
            *ngIf="!action.permission || (permissions.hasPermission(action.permission.featureKey, action.permission.action) | async)"
            class="legend-item"
          >
            <div class="legend-item-icon node-point action-btn">
              <ekon-icon [icon]="action.icon"></ekon-icon>
            </div>
            <div class="legend-item-text">
              <div class="title">{{action.label | titlecase}}</div>
              <div *ngIf="action.description as description" class="description secondary-text">{{description}}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="this.dirChange$ | async"></ng-container>
  `,
  styleUrls: ['force-directed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ForceDirectedComponent implements AfterViewInit, OnDestroy {
  ekIconChevronUp = ekIconChevronUp;
  ekIconChevronDown = ekIconChevronDown;
  ekIconExpandArrowsAlt = ekIconExpandArrowsAlt;
  ekIconCompressAlt = ekIconCompressAlt;

  _config: ForceGraphConfig<ForceGraphNodeKind>;
  kinds: ForceGraphNodeKindConfig[];

  @Input() set config(cfg: ForceGraphConfig<ForceGraphNodeKind>) {
    this._config = cfg;
    this.kinds = _values(cfg.kinds);
  }

  get config(): ForceGraphConfig<ForceGraphNodeKind> {
    return this._config;
  }

  @Input() set data(d: ForceGraphData<ForceGraphNodeKind>) {
    this.graph = d;
    const rootNode = this.graph?.nodes.find(n => n.isRoot);
    if(rootNode) {
      rootNode.fx = this.width / 2;
      rootNode.fy = this.height / 2;
    }


    this.renderGraph();
  }

  @Output() nodeClick = new EventEmitter<ForceGraphNode<ForceGraphNodeKind>>();

  maximized = false;

  @HostBinding('class.maximized') get classsMaximized(): boolean {
    return this.maximized;
  };

  hostEl: Element;

  private svg: SelectionSVG;
  private origin: SelectionSVGG;
  private rect: SelectionSVGRect;
  private width: number;
  private height: number;
  private links: SelectionSVGG;
  private nodes: SelectionSVGG;
  private titles: SelectionSVGG;
  private link: SelectionSVGLine;
  private node: SelectionSVGCircle;
  private title: SelectionSVGText;
  private simulation: Simulation<SimulationNodeDatum, undefined>;
  private drag: DragBehavior<DraggedElementBaseType, unknown, SubjectPosition | unknown>;

  graph?: ForceGraphData<ForceGraphNodeKind>;


  private linkTo?: {
    source: SelectionSVGCircle;
    target?: SelectionSVGCircle;
    link?: SelectionSVGLine;
    xy0?: [number, number];
    path?: SelectionSVGPath;
  };

  line = line()
    .x((d) => d[0])
    .y((d) => d[1]);

  private resizeObserver: ResizeObserver;

  actions: Array<{
    key: string,
    permission?: { featureKey: string; action: EkonPermissionActionType },
    label: string,
    description?: string,
    icon: EkonIconDefinition,
    eventType: string,
    action: (event: any, d: ForceGraphNode<ForceGraphNodeKind> | SimulationNodeDatum) => void,
    predicate?: (d: ForceGraphNode<ForceGraphNodeKind>) => boolean
  }> = [
    {
      permission: {
        featureKey: 'PAGES',
        action: 'update'
      },
      key: 'link',
      label: 'Add relation',
      description: 'Press and drag to another node. Available node will be highlighted with green color',
      icon: ekIconProjectDiagram,
      eventType: 'mousedown',
      action: (...r: [any, ForceGraphNode<ForceGraphNodeKind>]) => this.handleLinkToMouseDown(...r),
      predicate: d => this.config.linkSourcePredicate?.(d)
    },
    {
      key: 'preview',
      label: 'Preview entity',
      description: 'Open entity preview dialog',
      icon: ekIconInfo,
      eventType: 'click',
      action: (...[, d]: [unknown, ForceGraphNode<ForceGraphNodeKind>]) => this.previewNode(d)
    },
    {
      key: 'preview',
      label: 'Close relations',
      description: 'Close & unload entity relations',
      icon: ekIconTimes,
      eventType: 'click',
      action: (...[, d]: [unknown, ForceGraphNode<ForceGraphNodeKind>]) => this.closeChildren(d)
    },
    {
      key: 'loadChildren',
      label: 'Load relations',
      description: 'Load entity relations',
      icon: ekIconPlus,
      eventType: 'click',
      action: (...r) => this.handleCircleClick(...r)
    },
    {
      key: 'unlock',
      label: 'Unlock node position',
      icon: ekIconUnlock,
      eventType: 'click',
      action: (...r: [any, SimulationNodeDatum]) => this.unlockNode(...r)
    }
    // {
    //   key: 'navToItem',
    //   label: 'Navigate',
    //   icon: ekIconGlasses,
    //   eventType: 'click',
    //   action: (...[, d]) => console.warn(d)
    // }
  ];

  private _legendExpanded$: BehaviorSubject<boolean> = new BehaviorSubject(this.legendExpanded);
  legendExpanded$: Observable<boolean> = this._legendExpanded$.asObservable();

  set legendExpanded(expanded: boolean) {
    localStorage.setItem('legendExpanded', JSON.stringify(expanded));
    this._legendExpanded$.next(expanded);
  }

  get legendExpanded(): boolean {
    return JSON.parse(localStorage.getItem('legendExpanded')) ?? true;
  }

  dirChange$: Observable<Direction>;

  get mSign(): number {
    return this.dir.value === 'rtl' ? -1 : 1;
  };

  constructor(
    private hostElRef: ElementRef<Element>,
    private dir: Directionality,
    public permissions: PermissionsService,
  ) {
    this.hostEl = this.hostElRef.nativeElement;

    this.dirChange$ = dir.change.pipe(
      tap(() => this.renderGraph())
    );
  }

  ngAfterViewInit(): void {
    // select(this.hostEl).attr('pointer-events', 'none');

    const bounds = this.hostEl.getBoundingClientRect();

    this.width = bounds.width;
    this.height = bounds.height;

    this.svg = select(this.hostEl).append('svg')
      .attr('viewBox', [0, 0, this.width, this.height])
      .attr('pointer-events', 'all')
      .call(zoom().on('zoom', (e) => this.handleZoom(e)));

    for(const key in this.config.kinds) {
      this.addOrUse(this.config.kinds[key].icon);
    }

    this.origin = this.svg
      .append('g')
      .append('g');

    this.rect = this.origin.append('svg:rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('fill', 'rgba(1,1,1,0)');

    this.links = this.origin
      .append('g')
      .classed('links', true);

    this.titles = this.origin
      .append('g')
      .classed('titles', true);

    this.nodes = this.origin
      .append('g')
      .classed('nodes', true);

    this.drag = drag()
      .on('start', () => this.dragstart())
      .on('drag', (event, d) => this.dragged(event, d));


    select(document.body)
      //   .on('mousedown', ($e, d) => this.handleBodyMouseDown($e, d))
      //   .on('mousemove', ($e, d) => this.handleBodyMouseMove($e))
      .on('mouseup', ($e) => this.handleBodyMouseUp($e));

    let timeout;

    this.resizeObserver = new ResizeObserver(
      ([{ contentRect: b }]: ResizeObserverEntry[]) => {
        timeout && clearTimeout(timeout);

        timeout = setTimeout(() => {
          this.width = b.width;
          this.height = b.height;

          // const rootNode = this.graph.nodes.find(n => n.isRoot);
          // delete rootNode.fx;
          // delete rootNode.fy;


          this.renderGraph();
        }, 100);
      }
    );

    this.resizeObserver.observe(this.hostEl);


    // this.initLinePainter();
  }

  ngOnDestroy(): void {
    // FIXME: TODO: check if code bellow removes all body listeners
    //  instead of specified d3-listeners only
    select(document.body)
      .on('mousemove', null)
      .on('mouseup', null);
  }

  renderGraph(): void {
    if(this.svg && this.rect) {
      this.svg.attr('viewBox', [0, 0, this.width, this.height]);
      this.rect
        .attr('width', this.width)
        .attr('height', this.height);
    }

    if(!this.origin || !this.graph) {
      return;
    }

    console.warn('nodes we deserve', this.graph.nodes);

    this.stop();

    // TODO: add edge description with small circle point centered on the line
    this.link = this.links
      .selectAll('.link')
      .data(this.graph.links)
      .join('line')
      .classed('link', true);

    this.link.append('title')
      .text((d, i) => i);

    this.title = this.titles
      .selectAll('.title')
      .data(this.graph.nodes)
      .join(
        enter => {
          return enter.append('text')
            .classed('title', true)
            .attr('x', (d: ForceGraphNode<ForceGraphNodeKind>) => this.mSign * (d.isRoot ? 15 + IRON : 15))
            .attr('y', '0.31em')
            .text((d: ForceGraphNode<ForceGraphNodeKind>) => d.label);
        },
        update => {
          update.attr('x', (d: ForceGraphNode<ForceGraphNodeKind>) => this.mSign * (d.isRoot ? 15 + IRON : 15))
            .text((d: ForceGraphNode<ForceGraphNodeKind>) => d.label);

          return update;
        }
      );

    this.node = this.nodes
      .selectAll('.node')
      .data(this.graph.nodes)
      .join(
        enter => {
          console.warn('node enter');
          const r = enter.append('g')
            .classed('node', true)
            .on('click', (...r) => this.handleNodeClick(...r))
            .on('mouseenter', (...r) => this.handleNodeMouseEnter(...r))
            .on('mouseleave', (...r) => this.handleNodeMouseLeave(...r));

          const tools = r.append('g')
            .classed('tools', true)
            .attr('display', 'none')
            .attr('pointer-events', 'all');

          tools.append('circle')
            .attr('r', (d: ForceGraphNode<ForceGraphNodeKind>) => d.isRoot ? 31 + IRON : 31)
            .attr('pointer-events', 'all');

          this.actions.forEach(action => tools.append((d: ForceGraphNode<ForceGraphNodeKind>) =>
            (action.predicate ? this.config.linkSourcePredicate?.(d) : true)
            ? bakeToolButton(action.icon).node()
            : create('svg:g').node()
          ).on(action.eventType, action.action));

          tools.call(d => this.arrangeToolBtns(d));


          r.append('circle')
            .classed('node-point', true)
            .attr('r', (d: ForceGraphNode<ForceGraphNodeKind>) => d.isRoot ? 12 + IRON : 12)
            .attr('stroke', d => this.config.kinds[d.kind].color)
            .attr('d-stroke', d => this.config.kinds[d.kind].color)
            .on('click', (...r) => this.handleCircleClick(...r))
            .on('mouseenter', (...r) => this.handleCircleMouseEnter(...r))
            .on('mouseleave', (...r) => this.handleCircleMouseLeave(...r))
            .on('mouseup', (...r) => this.handleCircleMouseUp(...r));

          // r.append('text')
          //   .classed('title', true)
          //   .attr('x', (d: ForceGraphNode<ForceGraphNodeKind>) => d.isRoot ? 15 + IRON : 15)
          //   .attr('y', '0.31em')
          //   .text((d: ForceGraphNode<ForceGraphNodeKind>) => d.label);

          r.append('svg:use')
            .attr('href', d => `#${this.config.kinds[d.kind].icon.name}`)
            .classed('node-icon', true)
            .on('click', (...r) => this.handleCircleClick(...r))
            .on('mouseenter', (...r) => this.handleCircleMouseEnter(...r))
            .on('mouseleave', (...r) => this.handleCircleMouseLeave(...r))
            .on('mouseup', (...r) => this.handleCircleMouseUp(...r));


          return r;
        },
        update => {
          console.warn('node update');

          // update.select('.title')
          //   .text((d: ForceGraphNode<ForceGraphNodeKind>) => d.label);

          return update;
        },
        exit => {
          console.warn('node exit');
          return exit.remove();
        }
      );

    this.simulation = forceSimulation()
      .nodes(this.graph.nodes as any)
      .force(
        'charge',
        forceManyBody()
          .strength(-200)
      )
      // .force(
      //   'center',
      //   // forceCenter(this.width / 2, this.height / 2)
      //   forceCenter()
      // )
      .force(
        'link',
        forceLink(this.graph.links)
          .id((d: any) => d.id)
          .distance(100)
          .strength(1)
      )
      .force(
        'collide',
        forceCollide(20)
      )
      .on('tick', () => this.tick());
    // .on('end', () => this.end());

    this.node.call(this.drag);
    this.title.call(this.drag);

    this.restart();
  }

  addOrUse(icon: EkonIconDefinition): void {
    const parser = new DOMParser();

    this.svg.append('svg:symbol')
      .append(() => parser
        .parseFromString(icon.data, 'image/svg+xml')
        .querySelector('svg')
      )
      .attr('viewBox', null)
      .classed('ekon-icon', true)
      .attr('id', icon.name);
  }

  arrangeToolBtns(tools: ForceSelectionSVGG): void {
    arrangeToolBtns(tools, IRON);
  }

  initLinePainter(): void {
    // var keep = false;
    //
    // this.svg
    //   .on('mousedown', (e) => {
    //     keep = true;
    //     xy0 = pointer(e);
    //     path = this.svg
    //       .append('path')
    //       .attr('d', this.line([xy0[0], xy0[1]]))
    //       .style('stroke', 'white')
    //       .style('stroke-width', '3px');
    //   })
    //   .on('mouseup', () => {
    //     keep = false;
    //   })
    //   .on('mousemove', (e) => {
    //     if(keep) {
    //       const ll = this.line([xy0, pointer(e)]/*.map((x) => x - 1 )*/);
    //       console.log(ll);
    //       path.attr('d', ll);
    //     }
    //   });
  }

  stop(): void {
    this.simulation?.stop();
  }

  restart(): void {
    this.simulation?.alpha(1).restart();
  }

  unlockNode(e: PointerEvent, d: SimulationNodeDatum): void {
    this.stop();
    delete d.fx;
    delete d.fy;
    this.restart();
  }

  handleZoom(e?: D3ZoomEvent<any, any>): void {
    this.origin.attr('transform', e?.transform.toString());
  }

  handleNodeClick(e: PointerEvent, d: any): void {
    console.warn('handleNodeClick', e, d);
  }

  handleNodeMouseEnter(e: MouseEvent, d: ForceGraphNodeSimulated<ForceGraphNodeKind>): void {
    !this.linkTo && select(e.target as Element).select('.tools')
      .attr('display', null);

    this.link.filter((_d: SimulationLinkDatum<unknown>) => {
      const
        source = _d.source as ForceGraphNodeSimulated<ForceGraphNodeKind>,
        target = _d.target as ForceGraphNodeSimulated<ForceGraphNodeKind>;
      return source.id === d.id || target.id === d.id;
    })
      .style('stroke', this.config.kinds[d.kind].color)
      .classed('highlighted', true);
  }

  handleNodeMouseLeave(e: MouseEvent, d: ForceGraphNodeSimulated<ForceGraphNodeKind>): void {
    const node = select(e.target as Element);

    node.select('.tools')
      .attr('display', 'none');

    this.link.filter((_d: SimulationLinkDatum<unknown>) => {
      const
        source = _d.source as ForceGraphNodeSimulated<ForceGraphNodeKind>,
        target = _d.target as ForceGraphNodeSimulated<ForceGraphNodeKind>;
      return source.id === d.id || target.id === d.id;
    })
      .style('stroke', null)
      .classed('highlighted', false);
  }

  handleLinkToMouseDown(e: /*MouseEvent*/ any, sourceData: ForceGraphNode<ForceGraphNodeKind>): void {
    console.warn('handleLinkToMouseDown', e, sourceData);
    e.preventDefault();
    e.stopPropagation();

    const node = select(e.target.parentNode.parentNode.parentNode);

    if(this.config.linkSourcePredicate?.(sourceData)) {
      // const xy0 = pointer(e);

      node.select('.node-point')
        .attr('stroke', '#1f74a2');

      this.linkTo = {
        source: node
        // xy0,
        // path: this.svg
        //   .append('path')
        //   .attr('d', this.line([xy0, xy0]))
        //   .style('stroke', 'white')
        //   .style('stroke-width', '3px')
      };
    } else {
      node.select('.node-point')
        .attr('stroke', '#962b2b');
    }
  }

  handleBodyMouseDown(e: /*MouseEvent*/ any, d: any): void {
    // console.warn('handleBodyMouseDown', e, d);
    // // e.preventDefault();
    // // e.stopPropagation();
    //
    // const xy0 = pointer(e);
    //
    // this.linkTo.xy0 = xy0;
    // this.linkTo.path = this.svg
    //   .append('path')
    //   .attr('d', this.line([xy0, xy0]))
    //   .style('stroke', 'white')
    //   .style('stroke-width', '3px');
    //
    // // this.linkTo = {
    // //   source: select(e.target.parentNode),
    // //   /*link: this.origin.append('line')
    // //     .attr('x1', x)
    // //     .attr('y1', y)
    // //     .attr('stroke', '#ffffff'),*/
    // //   xy0,
    // //   path: this.svg
    // //     .append('path')
    // //     .attr('d', this.line([xy0, xy0]))
    // //     .style('stroke', 'white')
    // //     .style('stroke-width', '3px')
    // // };
    //
    // // select(e.target.parentNode).select('.tools')
    // //   .attr("display", null);
    // // select(e.target.parentNode).select('.link-to')
    // //   .attr("display", null);
  }

  handleBodyMouseMove(e: MouseEvent): void {
    // e.preventDefault();
    // e.stopPropagation();

    // const [x, y] = pointer(e);
    //
    // if(this.linkTo) {
    //   // console.warn('handleBodyMouseMove', e);
    //   // // this.linkTo.link
    //   // //   .attr('x2', x)
    //   // //   .attr('y2', y);
    //   //
    //   // const ll = this.line([this.linkTo.xy0, pointer(e)]/*.map((x) => x - 1 )*/);
    //   // console.log(ll);
    //   // this.linkTo.path.attr('d', ll);
    // }
    // select(e.target.parentNode).select('.tools')
    //   .attr("display", 'none');
    // select(e.target.parentNode).select('.link-to')
    //   .attr("display", 'none');
  }

  handleBodyMouseUp(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();

    const source = this.linkTo?.source;

    if(this.linkTo) {
      console.warn('handleBodyMouseUp', e.target);

      const point = source.select('.node-point');
      point.attr('stroke', () => point.attr('d-stroke'));

      // this.linkTo.link.remove();
      this.linkTo = undefined;
    }
  }

  handleCircleMouseUp(e: /*MouseEvent*/ any, d: any): void {
    e.preventDefault();
    // e.stopPropagation();

    this.linkTo?.link?.remove();

    const source = this.linkTo?.source?.datum() as ForceGraphNode<ForceGraphNodeKind>;

    if(source && this.config?.linkTargetPredicate(source, d)) {
      this.addEdge(source, d);
    }
  }

  handleCircleMouseEnter(e: /*MouseEvent*/ any, d: any): void {
    e.preventDefault();
    // e.stopPropagation();

    const node = select(e.target.parentNode);
    const point = node.select('.node-point');

    const source = this.linkTo?.source?.datum() as ForceGraphNode<ForceGraphNodeKind>;

    if(source) {
      if(this.config?.linkTargetPredicate(source, d)) {
        this.linkTo.link = this.origin.append('line')
          .attr('x1', +this.linkTo.source.attr('cx'))
          .attr('y1', +this.linkTo.source.attr('cy'))
          .attr('x2', +node.attr('cx'))
          .attr('y2', +node.attr('cy'))
          .classed('link-to-line', true);

        point.attr('stroke', '#578132');
      } else {
        point.attr('stroke', '#962b2b');
      }
    }
  }

  handleCircleMouseLeave(e: /*MouseEvent*/ any, d: any): void {
    e.preventDefault();
    // e.stopPropagation();

    const node = select(e.target.parentNode);
    const point = node.select('.node-point');

    point.attr('stroke', () => point.attr('d-stroke'));

    this.linkTo?.link?.remove();
  }

  handleCircleClick(event, d) {
    this.loadChildren(d);
  }

  closeChildren(d: ForceGraphNode<ForceGraphNodeKind>) {
    this.deleteChildrenRecursive(d);
    this.renderGraph();
  }

  deleteChildrenRecursive(d: ForceGraphNodeSimulated<ForceGraphNodeKind>): void {
    const targets: ForceGraphNodeSimulated<ForceGraphNodeKind>[] = [];
    this.graph.links = this.graph.links.filter(l => {
      const condition = this.getSimulated(l.source).id !== d.id;
      if(!condition) {
        targets.push(this.getSimulated(l.target));
      }
      return condition;
    });

    // d.children = undefined;

    targets.forEach(t => {
      if(
        !this.graph.links.some(l =>
          this.getSimulated(l.target).id === t.id
        )
        && !this.graph.nodes.some(l =>
          l.children?.some(cId => cId === t.id)
        )
      ) {
        console.warn(t.label);
        this.deleteChildrenRecursive(t);

        console.warn('nodes to be deleted', this.graph.nodes.filter(n =>
          n.id !== t.id
        ));
        this.graph.nodes = this.graph.nodes.filter(n =>
          n.id !== t.id
        );
      }
    });
  }

  getSimulated(node: unknown): ForceGraphNodeSimulated<ForceGraphNodeKind> {
    return node as ForceGraphNodeSimulated<ForceGraphNodeKind>;
  }

  handleRefToClick(event, d) {
    this.nodeClick.emit(d);
  }


  tick() {
    this.link
      .attr('x1', (d: any) => d.source.x)
      .attr('y1', (d: any) => d.source.y)
      .attr('x2', (d: any) => d.target.x)
      .attr('y2', (d: any) => d.target.y);

    this.node
      .attr('cx', (d: any) => d.x)
      .attr('cy', (d: any) => d.y)
      .attr('transform', (d: any) => 'translate(' + d.x + ',' + d.y + ')');

    this.title
      .attr('cx', (d: any) => d.x)
      .attr('cy', (d: any) => d.y)
      .attr('transform', (d: any) => 'translate(' + d.x + ',' + d.y + ')');
  }

  dragstart() {
    // select(this.svg.node()).classed('fixed', true);
  }

  dragged(event, d) {
    d.fx = event.x;
    d.fy = event.y;
    // d.fx = this.clamp(event.x, 0, this.width);
    // d.fy = this.clamp(event.y, 0, this.height);
    this.restart();
  }

  // end() {
  //   selectAll(this.node).each((x: any) => {
  //     x.fx = this.clamp(x.x, 0, this.width);
  //     x.fy = this.clamp(x.y, 0, this.height);
  //   });
  // }

  clamp(x: number, lo: number, hi: number): number {
    return x < lo ? lo : x > hi ? hi : x;
  }

  addEdge(source: ForceGraphNode<ForceGraphNodeKind>, target: ForceGraphNode<ForceGraphNodeKind>): void {
    this.config.handleLink(source, target).subscribe({
      next: () => {
        this.graph.links.push({ source: source.id, target: target.id });

        this.renderGraph();
      }
    });

  }


  loadChildren(node: ForceGraphNode<ForceGraphNodeKind>): void {
    if(!this.graph.links.some(l => (l.source as any).id === node.id)) {
      this.config.handleLoadChildren(node).subscribe({
        next: (graph: ForceGraphData<ForceGraphNodeKind>) => {
          this.graph.nodes = _values(_merge(_keyBy(this.graph.nodes, 'id'), _keyBy(graph.nodes, 'id')));

          this.graph.links.push(...graph.links.filter(l => !this.graph.links.some(({
                                                                                     source: s,
                                                                                     target: t
                                                                                   }: { source: unknown, target: unknown }) => {
            const source = s as ForceGraphNodeSimulated<ForceGraphNodeKind>;
            const target = t as ForceGraphNodeSimulated<ForceGraphNodeKind>;
            return source.id === l.target && target.id === l.source;
          })));
          this.renderGraph();
        }
      });
    }
  }

  // relateX(x: number): number {
  //   const origin = this.svg.node().getBoundingClientRect();
  //   return x - origin.left;
  // }
  //
  // relateY(y: number): number {
  //   const origin = this.svg.node().getBoundingClientRect();
  //   return y - origin.top;
  // }


  private previewNode(node: ForceGraphNode<ForceGraphNodeKind>) {
    this.config.handlePreview?.(node);
  }

  toggleLegend(): void {
    this.legendExpanded = !this.legendExpanded;
  }

  toggleFullscreen(): void {
    this.maximized = !this.maximized;
  }
}
