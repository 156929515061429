import { EkonIconDefinition } from '@ekon-client/shared/common/ekon-icons';

import { getFileTypeIcon } from './files';

export interface MimeType {
  ext: string[];
  description: string;
  mime: string[];
}

export interface MimeTypeGroup {
  name: string;
  types: MimeType[];
  icon: EkonIconDefinition
}

export const mimeTypes: MimeTypeGroup[] = [
  {
    name: 'Document',
    types: [
      {
        ext: ['.abw'],
        description: 'AbiWord document',
        mime: ['application/x-abiword']
      },
      {
        ext: ['.azw'],
        description: 'Amazon Kindle eBook format',
        mime: ['application/vnd.amazon.ebook']
      },
      {
        ext: ['.doc'],
        description: 'Microsoft Word',
        mime: ['application/msword']
      },
      {
        ext: ['.docx'],
        description: 'Microsoft Word (OpenXML)',
        mime: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']
      },
      {
        ext: ['.epub'],
        description: 'Electronic publication (EPUB)',
        mime: ['application/epub+zip']
      },
      {
        ext: ['.odp'],
        description: 'OpenDocument presentation document',
        mime: ['application/vnd.oasis.opendocument.presentation']
      },
      {
        ext: ['.ods'],
        description: 'OpenDocument spreadsheet document',
        mime: ['application/vnd.oasis.opendocument.spreadsheet']
      },
      {
        ext: ['.odt'],
        description: 'OpenDocument text document',
        mime: ['application/vnd.oasis.opendocument.text']
      },
      {
        ext: ['.pdf'],
        description: 'Adobe Portable Document Format (PDF)',
        mime: ['application/pdf']
      },
      {
        ext: ['.ppt'],
        description: 'Microsoft PowerPoint',
        mime: ['application/vnd.ms-powerpoint']
      },
      {
        ext: ['.pptx'],
        description: 'Microsoft PowerPoint (OpenXML)',
        mime: ['application/vnd.openxmlformats-officedocument.presentationml.presentation']
      },
      {
        ext: ['.rtf'],
        description: 'Rich Text Format (RTF)',
        mime: ['application/rtf']
      },
      {
        ext: ['.txt'],
        description: 'Text, (generally ASCII or ISO 8859-n)',
        mime: ['text/plain']
      },
      {
        ext: ['.vsd'],
        description: 'Microsoft Visio',
        mime: ['application/vnd.visio']
      },
      {
        ext: ['.xls'],
        description: 'Microsoft Excel',
        mime: ['application/vnd.ms-excel']
      },
      {
        ext: ['.xlsx'],
        description: 'Microsoft Excel (OpenXML)',
        mime: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      },

    ],
    icon: getFileTypeIcon('f.doc')
  },
  {
    name: 'Video',
    types: [
      {
        ext: ['.avi'],
        description: 'AVI: Audio Video Interleave',
        mime: ['video/x-msvideo']
      },
      {
        ext: ['.mpeg'],
        description: 'MPEG Video',
        mime: ['video/mpeg']
      },
      {
        ext: ['.ogv'],
        description: 'OGG video',
        mime: ['video/ogg']
      },
      {
        ext: ['.ts'],
        description: 'MPEG transport stream',
        mime: ['video/mp2t']
      },
      {
        ext: ['.webm'],
        description: 'WEBM video',
        mime: ['video/webm']
      },
      {
        ext: ['.3gp'],
        description: '3GPP video container',
        mime: [
          'video/3gpp',
        ]
      },
      {
        ext: ['.3g2'],
        description: '3GPP2 video container',
        mime: [
          'video/3gpp2',
        ]
      },
      {
        ext: ['.mp4'],
        description: 'MP4 video container',
        mime: [
          'video/mp4',
        ]
      },

    ],
    icon: getFileTypeIcon('f.mov')
  },
  {
    name: 'Data',
    types: [
      {
        ext: ['.csv'],
        description: 'Comma-separated values (CSV)',
        mime: ['text/csv']
      },
      {
        ext: ['.json'],
        description: 'JSON format',
        mime: ['application/json']
      },
      {
        ext: ['.jsonld'],
        description: 'JSON-LD format',
        mime: ['application/ld+json']
      },
      {
        ext: ['.xml'],
        description: 'XML',
        mime: [
          // if not readable from casual users (RFC 3023 https://tools.ietf.org/html/rfc3023#section-3 , section 3)
          'application/xml',
          // if readable from casual users (RFC 3023 https://tools.ietf.org/html/rfc3023#section-3, section 3)
          'text/xml'
        ]
      },

    ],
    icon: getFileTypeIcon('f.csv')
  },
  {
    name: 'Image',
    types: [
      {
        ext: ['.bmp'],
        description: 'Windows OS/2 Bitmap Graphics',
        mime: ['image/bmp']
      },
      {
        ext: ['.gif'],
        description: 'Graphics Interchange Format (GIF)',
        mime: ['image/gif']
      },
      {
        ext: ['.ico'],
        description: 'Icon format',
        mime: ['image/vnd.microsoft.icon']
      },
      {
        ext: [
          '.jpeg',
          '.jpg'
        ],
        description: 'JPEG images',
        mime: ['image/jpeg']
      },
      {
        ext: ['.png'],
        description: 'Portable Network Graphics',
        mime: ['image/png']
      },
      {
        ext: ['.svg'],
        description: 'Scalable Vector Graphics (SVG)',
        mime: ['image/svg+xml']
      },
      {
        ext: ['.tif',
          '.tiff'],
        description: 'Tagged Image File Format (TIFF)',
        mime: ['image/tiff']
      },
      {
        ext: ['.webp'],
        description: 'WEBP image',
        mime: ['image/webp']
      },

    ],
    icon: getFileTypeIcon('f.jpg')
  },
  {
    name: 'Code',
    types: [
      {
        ext: ['.csh'],
        description: 'C-Shell script',
        mime: ['application/x-csh']
      },
      {
        ext: ['.css'],
        description: 'Cascading Style Sheets (CSS)',
        mime: ['text/css']
      },
      {
        ext: ['.htm',
          '.html'],
        description: 'HyperText Markup Language (HTML)',
        mime: ['text/html']
      },
      {
        ext: ['.js'],
        description: 'JavaScript',
        mime: ['text/javascript']
      },
      {
        ext: ['.mjs'],
        description: 'JavaScript module',
        mime: ['text/javascript']
      },
      {
        ext: ['.php'],
        description: 'Hypertext Preprocessor (Personal Home Page)',
        mime: ['application/x-httpd-php']
      },
      {
        ext: ['.sh'],
        description: 'Bourne shell script',
        mime: ['application/x-sh']
      },
      {
        ext: ['.xhtml'],
        description: 'XHTML',
        mime: ['application/xhtml+xml']
      },
      {
        ext: ['.xul'],
        description: 'XUL',
        mime: ['application/vnd.mozilla.xul+xml']
      },

    ],
    icon: getFileTypeIcon('f.js')
  },
  {
    name: 'Audio',
    types: [
      {
        ext: ['.aac'],
        description: 'AAC audio',
        mime: ['audio/aac']
      },
      {
        ext: [
          '.mid',
          '.midi'
        ],
        description: 'Musical Instrument Digital Interface (MIDI)',
        mime: [
          'audio/midi',
          'audio/x-midi'
        ]
      },
      {
        ext: ['.mp3'],
        description: 'MP3 audio',
        mime: ['audio/mpeg']
      },
      {
        ext: ['.oga'],
        description: 'OGG audio',
        mime: ['audio/ogg']
      },
      {
        ext: ['.opus'],
        description: 'Opus audio',
        mime: ['audio/opus']
      },
      {
        ext: ['.wav'],
        description: 'Waveform Audio Format',
        mime: ['audio/wav']
      },
      {
        ext: ['.weba'],
        description: 'WEBM audio',
        mime: ['audio/webm']
      },
      {
        ext: ['.3gp'],
        description: '3GPP audio container',
        mime: [
          'audio/3gpp'
        ]
      },
      {
        ext: ['.3g2'],
        description: '3GPP2 audio container',
        mime: [
          'audio/3gpp2'
        ]
      },

    ],
    icon: getFileTypeIcon('f.mp3')
  },
  {
    name: 'Archive',
    types: [
      {
        ext: ['.arc'],
        description: 'Archive document (multiple files embedded)',
        mime: ['application/x-freearc']
      },
      {
        ext: ['.bz'],
        description: 'BZip archive',
        mime: ['application/x-bzip']
      },
      {
        ext: ['.bz2'],
        description: 'BZip2 archive',
        mime: ['application/x-bzip2']
      },
      {
        ext: ['.gz'],
        description: 'GZip Compressed Archive',
        mime: ['application/gzip']
      },
      {
        ext: ['.rar'],
        description: 'RAR archive',
        mime: ['application/vnd.rar']
      },
      {
        ext: ['.tar'],
        description: 'Tape Archive (TAR)',
        mime: ['application/x-tar']
      },
      {
        ext: ['.zip'],
        description: 'ZIP archive',
        mime: ['application/zip']
      },
      {
        ext: ['.7z'],
        description: '7-zip archive',
        mime: ['application/x-7z-compressed']
      }

    ],
    icon: getFileTypeIcon('f.arc')
  },
  {
    name: 'Other',
    types: [
      {
        ext: ['.bin'],
        description: 'Any kind of binary data',
        mime: ['application/octet-stream']
      },
      {
        ext: ['.eot'],
        description: 'MS Embedded OpenType fonts',
        mime: ['application/vnd.ms-fontobject']
      },
      {
        ext: ['.otf'],
        description: 'OpenType font',
        mime: ['font/otf']
      },
      {
        ext: ['.ttf'],
        description: 'TrueType Font',
        mime: ['font/ttf']
      },
      {
        ext: ['.woff'],
        description: 'Web Open Font Format (WOFF)',
        mime: ['font/woff']
      },
      {
        ext: ['.woff2'],
        description: 'Web Open Font Format (WOFF)',
        mime: ['font/woff2']
      },
      {
        ext: ['.ics'],
        description: 'iCalendar format',
        mime: ['text/calendar']
      },
      {
        ext: ['.jar'],
        description: 'Java Archive (JAR)',
        mime: ['application/java-archive']
      },
      {
        ext: ['.mpkg'],
        description: 'Apple Installer Package',
        mime: ['application/vnd.apple.installer+xml']
      },
      {
        ext: ['.ogx'],
        description: 'OGG',
        mime: ['application/ogg']
      },
      {
        ext: ['.swf'],
        description: 'Small web format (SWF) or Adobe Flash document',
        mime: ['application/x-shockwave-flash']
      },

    ],
    icon: getFileTypeIcon('f.noop')
  }
];


// const mimes: [
//   {
//     ext: ['.aac'],
//     description: 'AAC audio',
//     mime: ['audio/aac']
//   },
//   {
//     ext: ['.abw'],
//     description: 'AbiWord document',
//     mime: ['application/x-abiword']
//   },
//   {
//     ext: ['.arc'],
//     description: 'Archive document (multiple files embedded)',
//     mime: ['application/x-freearc']
//   },
//   {
//     ext: ['.avi'],
//     description: 'AVI: Audio Video Interleave',
//     mime: ['video/x-msvideo']
//   },
//   {
//     ext: ['.azw'],
//     description: 'Amazon Kindle eBook format',
//     mime: ['application/vnd.amazon.ebook']
//   },
//   {
//     ext: ['.bin'],
//     description: 'Any kind of binary data',
//     mime: ['application/octet-stream']
//   },
//   {
//     ext: ['.bmp'],
//     description: 'Windows OS/2 Bitmap Graphics',
//     mime: ['image/bmp']
//   },
//   {
//     ext: ['.bz'],
//     description: 'BZip archive',
//     mime: ['application/x-bzip']
//   },
//   {
//     ext: ['.bz2'],
//     description: 'BZip2 archive',
//     mime: ['application/x-bzip2']
//   },
//   {
//     ext: ['.csh'],
//     description: 'C-Shell script',
//     mime: ['application/x-csh']
//   },
//   {
//     ext: ['.css'],
//     description: 'Cascading Style Sheets (CSS)',
//     mime: ['text/css']
//   },
//   {
//     ext: ['.csv'],
//     description: 'Comma-separated values (CSV)',
//     mime: ['text/csv']
//   },
//   {
//     ext: ['.doc'],
//     description: 'Microsoft Word',
//     mime: ['application/msword']
//   },
//   {
//     ext: ['.docx'],
//     description: 'Microsoft Word (OpenXML)',
//     mime: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']
//   },
//   {
//     ext: ['.eot'],
//     description: 'MS Embedded OpenType fonts',
//     mime: ['application/vnd.ms-fontobject']
//   },
//   {
//     ext: ['.epub'],
//     description: 'Electronic publication (EPUB)',
//     mime: ['application/epub+zip']
//   },
//   {
//     ext: ['.gz'],
//     description: 'GZip Compressed Archive',
//     mime: ['application/gzip']
//   },
//   {
//     ext: ['.gif'],
//     description: 'Graphics Interchange Format (GIF)',
//     mime: ['image/gif']
//   },
//   {
//     ext: ['.htm',
//       '.html'],
//     description: 'HyperText Markup Language (HTML)',
//     mime: ['text/html']
//   },
//   {
//     ext: ['.ico'],
//     description: 'Icon format',
//     mime: ['image/vnd.microsoft.icon']
//   },
//   {
//     ext: ['.ics'],
//     description: 'iCalendar format',
//     mime: ['text/calendar']
//   },
//   {
//     ext: ['.jar'],
//     description: 'Java Archive (JAR)',
//     mime: ['application/java-archive']
//   },
//   {
//     ext: [
//       '.jpeg',
//       '.jpg'
//     ],
//     description: 'JPEG images',
//     mime: ['image/jpeg']
//   },
//   {
//     ext: ['.js'],
//     description: 'JavaScript',
//     mime: ['text/javascript']
//   },
//   {
//     ext: ['.json'],
//     description: 'JSON format',
//     mime: ['application/json']
//   },
//   {
//     ext: ['.jsonld'],
//     description: 'JSON-LD format',
//     mime: ['application/ld+json']
//   },
//   {
//     ext: [
//       '.mid',
//       '.midi'
//     ]
//     description: 'Musical Instrument Digital Interface (MIDI)'
//     mimes: [
//       'audio/midi',
//       'audio/x-midi'
//     ]
//   },
//   {
//     ext: ['.mjs'],
//     description: 'JavaScript module',
//     mime: ['text/javascript']
//   },
//   {
//     ext: ['.mp3'],
//     description: 'MP3 audio',
//     mime: ['audio/mpeg']
//   },
//   {
//     ext: ['.mpeg'],
//     description: 'MPEG Video',
//     mime: ['video/mpeg']
//   },
//   {
//     ext: ['.mpkg'],
//     description: 'Apple Installer Package',
//     mime: ['application/vnd.apple.installer+xml']
//   },
//   {
//     ext: ['.odp'],
//     description: 'OpenDocument presentation document',
//     mime: ['application/vnd.oasis.opendocument.presentation']
//   },
//   {
//     ext: ['.ods'],
//     description: 'OpenDocument spreadsheet document',
//     mime: ['application/vnd.oasis.opendocument.spreadsheet']
//   },
//   {
//     ext: ['.odt'],
//     description: 'OpenDocument text document',
//     mime: ['application/vnd.oasis.opendocument.text']
//   },
//   {
//     ext: ['.oga'],
//     description: 'OGG audio',
//     mime: ['audio/ogg']
//   },
//   {
//     ext: ['.ogv'],
//     description: 'OGG video',
//     mime: ['video/ogg']
//   },
//   {
//     ext: ['.ogx'],
//     description: 'OGG',
//     mime: ['application/ogg']
//   },
//   {
//     ext: ['.opus'],
//     description: 'Opus audio',
//     mime: ['audio/opus']
//   },
//   {
//     ext: ['.otf'],
//     description: 'OpenType font',
//     mime: ['font/otf']
//   },
//   {
//     ext: ['.png'],
//     description: 'Portable Network Graphics',
//     mime: ['image/png']
//   },
//   {
//     ext: ['.pdf'],
//     description: 'Adobe Portable Document Format (PDF)',
//     mime: ['application/pdf']
//   },
//   {
//     ext: ['.php'],
//     description: 'Hypertext Preprocessor (Personal Home Page)',
//     mime: ['application/x-httpd-php']
//   },
//   {
//     ext: ['.ppt'],
//     description: 'Microsoft PowerPoint',
//     mime: ['application/vnd.ms-powerpoint']
//   },
//   {
//     ext: ['.pptx'],
//     description: 'Microsoft PowerPoint (OpenXML)',
//     mime: ['application/vnd.openxmlformats-officedocument.presentationml.presentation']
//   },
//   {
//     ext: ['.rar'],
//     description: 'RAR archive',
//     mime: ['application/vnd.rar']
//   },
//   {
//     ext: ['.rtf'],
//     description: 'Rich Text Format (RTF)',
//     mime: ['application/rtf']
//   },
//   {
//     ext: ['.sh'],
//     description: 'Bourne shell script',
//     mime: ['application/x-sh']
//   },
//   {
//     ext: ['.svg'],
//     description: 'Scalable Vector Graphics (SVG)',
//     mime: ['image/svg+xml']
//   },
//   {
//     ext: ['.swf'],
//     description: 'Small web format (SWF) or Adobe Flash document',
//     mime: ['application/x-shockwave-flash']
//   },
//   {
//     ext: ['.tar'],
//     description: 'Tape Archive (TAR)',
//     mime: ['application/x-tar']
//   },
//   {
//     ext: ['.tif',
//       '.tiff'],
//     description: 'Tagged Image File Format (TIFF)',
//     mime: ['image/tiff']
//   },
//   {
//     ext: ['.ts'],
//     description: 'MPEG transport stream',
//     mime: ['video/mp2t']
//   },
//   {
//     ext: ['.ttf'],
//     description: 'TrueType Font',
//     mime: ['font/ttf']
//   },
//   {
//     ext: ['.txt'],
//     description: 'Text, (generally ASCII or ISO 8859-n)',
//     mime: ['text/plain']
//   },
//   {
//     ext: ['.vsd'],
//     description: 'Microsoft Visio',
//     mime: ['application/vnd.visio']
//   },
//   {
//     ext: ['.wav'],
//     description: 'Waveform Audio Format',
//     mime: ['audio/wav']
//   },
//   {
//     ext: ['.weba'],
//     description: 'WEBM audio',
//     mime: ['audio/webm']
//   },
//   {
//     ext: ['.webm'],
//     description: 'WEBM video',
//     mime: ['video/webm']
//   },
//   {
//     ext: ['.webp'],
//     description: 'WEBP image',
//     mime: ['image/webp']
//   },
//   {
//     ext: ['.woff'],
//     description: 'Web Open Font Format (WOFF)',
//     mime: ['font/woff']
//   },
//   {
//     ext: ['.woff2'],
//     description: 'Web Open Font Format (WOFF)',
//     mime: ['font/woff2']
//   },
//   {
//     ext: ['.xhtml'],
//     description: 'XHTML',
//     mime: ['application/xhtml+xml']
//   },
//   {
//     ext: ['.xls'],
//     description: 'Microsoft Excel',
//     mime: ['application/vnd.ms-excel']
//   },
//   {
//     ext: ['.xlsx'],
//     description: 'Microsoft Excel (OpenXML)',
//     mime: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
//   },
//   {
//     ext: ['.xml'],
//     description: 'XML',
//     mime: [
//       // if not readable from casual users (RFC 3023 https://tools.ietf.org/html/rfc3023#section-3 , section 3)
//       'application/xml',
//       // if readable from casual users (RFC 3023 https://tools.ietf.org/html/rfc3023#section-3, section 3)
//       'text/xml'
//     ]
//   },
//   {
//     ext: ['.xul'],
//     description: 'XUL',
//     mime: ['application/vnd.mozilla.xul+xml']
//   },
//   {
//     ext: ['.zip'],
//     description: 'ZIP archive',
//     mime: ['application/zip']
//   },
//   {
//     ext: ['.3gp'],
//     description: '3GPP audio/video container',
//     mime: [
//       'video/3gpp',
//       // if it doesn't contain video
//       'audio/3gpp'
//     ]
//   },
//   {
//     ext: ['.3g2'],
//     description: '3GPP2 audio/video container',
//     mime: [
//       'video/3gpp2',
//       // if it doesn't contain video
//       'audio/3gpp2'
//     ]
//   },
//   {
//     ext: ['.7z'],
//     description: '7-zip archive',
//     mime: ['application/x-7z-compressed']
//   }
// ];
