import { ChangeDetectionStrategy, Component, Inject,TrackByFunction } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BlogPostHeader, BlogServiceInterface } from '@ekon-client/dkm-api';
import {
  BLOG_ACTIONS,
  BLOG_EVENTS, BlogEventsServiceInterface,
} from '@ekon-client/dkm-events';
import { PaginationModel, PaginatorConfig } from '@ekon-client/shared/common/ekon-pagination';

@Component({
  templateUrl: './blog-selector-dialog.component.html',
  styleUrls: ['./blog-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogSelectorDialogComponent {

  paginatorConfig: PaginatorConfig;

  trackById: TrackByFunction<BlogPostHeader> = (index: number, item: BlogPostHeader) => item.id;

  constructor(
    private dialogRef: MatDialogRef<BlogSelectorDialogComponent>,
    @Inject(BLOG_ACTIONS) private blogActions: BlogServiceInterface,
    @Inject(BLOG_EVENTS) private blogEvents: BlogEventsServiceInterface,
  ) {
    this.paginatorConfig = {
      loader: (p: PaginationModel) => this.blogActions.listBlogPosts(p),
      observables: [
        this.blogEvents.blogPostCreated,
        this.blogEvents.blogPostUpdated,
        this.blogEvents.blogPostDeleted,
      ]
    }
  }

  validateAndSave(post: BlogPostHeader): void {
    this.dialogRef.close(post);
  }
}
