/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeedbackContextType } from './feedbackContextType';
import { IssueType } from './issueType';
import { UserHeaderModel } from './userHeaderModel';
import { VisibilityTypes } from './visibilityTypes';
import { QuestionAnswer } from './questionAnswer';


/**
 * Feedback for system/domain/page/blogpost etc.  Feedbacks are saved, and can also be sent to specific functions by email, or by internal notifications.
 */
export interface FeedbackDetails { 
    id?: string;
    /**
     * Id reference to entity.  Feedback can be referred to an entity, not a must
     */
    referrerId?: string | null;
    contextSubjectType?: FeedbackContextType;
    feedbackType?: IssueType;
    /**
     * Feedback title, one liner
     */
    title: string;
    /**
     * Feedback Summary - free text description
     */
    summary?: string | null;
    /**
     * List of Q and A\'s   Note, the question is entered as well.
     */
    questionAndAnswer?: Array<QuestionAnswer> | null;
    /**
     * Get current DKM context (aka, url)
     */
    contextLink?: string | null;
    domains?: Array<string> | null;
    tags?: Array<string> | null;
    lastUpdateAt?: Date;
    createdAt?: Date | null;
    createdBy?: string | null;
    visibleTo?: VisibilityTypes;
    creator?: UserHeaderModel;
    create?: boolean;
    read?: boolean;
    update?: boolean;
    remove?: boolean;
}

