/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RichTextElement } from './richTextElement';
import { YouTubeElement } from './youTubeElement';
import { TextInputElement } from './textInputElement';
import { HtmlValueElement } from './htmlValueElement';
import { IframeValueElement } from './iframeValueElement';
import { NameValueGraphElement } from './nameValueGraphElement';
import { DateTimeElement } from './dateTimeElement';
import { TextAreaInputElement } from './textAreaInputElement';
import { DropDownListElement } from './dropDownListElement';
import { GridViewElement } from './gridViewElement';
import { BoxPlotGraphElement } from './boxPlotGraphElement';
import { JsonValueElement } from './jsonValueElement';
import { BooleanElement } from './booleanElement';
import { CurrencyInputElement } from './currencyInputElement';
import { FileViewerElement } from './fileViewerElement';
import { NumericInputElement } from './numericInputElement';


export interface Element { 
    kind: string;
    id?: string;
    name?: string | null;
    shortDescription?: string | null;
    description?: string | null;
    icon?: string | null;
    sourceId?: string | null;
}

