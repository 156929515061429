import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface EkonNotifierMessage {
  key: string;
  value: unknown
}

@Injectable({
  providedIn: 'platform',
})
export class EkonNotifierService {

  private _notifier: Subject<EkonNotifierMessage> = new Subject();
  get message(): Observable<EkonNotifierMessage> {
    return this._notifier.asObservable();
  }

  notify<T>(key: string, value?: T): void {
    this._notifier.next({ key, value });
  }

  getMessage<T>(key: string): Observable<T> {
    return this.message.pipe(
      filter(m => m.key === key),
      map(m => m.value as T)
    );
  }
}
