import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import { EkonGraphsModule } from '@ekon-client/shared/common/ekon-graphs';
import {
  ekIconArrowDoubleLeft, ekIconArrowDoubleRight,
  ekIconBlogs,
  ekIconCalendarEvent,
  ekIconExpandArrowsAlt, ekIconMapMarkedAlt,
  ekIconPlus,
  ekIconStickyNote, ekIconTaskOutlined,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EkonPaginationModule } from '@ekon-client/shared/common/ekon-pagination';
import { EkonSliderModule } from '@ekon-client/shared/common/ekon-slider';
import { EkonWysiwygModule } from '@ekon-client/shared/common/ekon-wysiwyg';
import { DkmChatbotModule } from '@ekon-client/shared/features/dkm-chatbot';
import { DkmMapModule as DkmMapSharedModule } from '@ekon-client/shared/features/dkm-map';
import { CalendarListModule } from '@ekon-client/shared/features/dkm-partials';
import { EkonFilterBadgeModule } from '@ekon-client/shared/features/ekon-search-n-filter';
import { TranslateModule } from '@ngx-translate/core';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

import { DKM_LAYOUTS_LIST } from '../../../extras';
import { FooterModule } from '../../components/footer/footer.module';
import { MobileNavModule } from '../../components/mobile-nav/mobile-nav.module';
import { SidebarModule } from '../../components/sidebar/sidebar.module';
import { ToolbarModule } from '../../components/toolbar/toolbar.module';
import { VerticalLayout3Component } from './layout-3.component';

@NgModule({
  declarations: [VerticalLayout3Component],
  imports: [
    RouterModule,
    FooterModule,
    ToolbarModule,
    SidebarModule,
    CommonModule,
    MatCardModule,
    CalendarListModule,
    MatListModule,
    EkonPaginationModule,
    EkonSliderModule,
    MatButtonModule,
    DkmMapSharedModule,
    FlexModule,
    DkmChatbotModule,
    MobileNavModule,
    EkonGraphsModule,
    MatDialogModule,
    EkonIconsModule,
    EkonDialogsModule,
    TranslateModule,
    EkonFilterBadgeModule,
    EkonWysiwygModule,
    TourMatMenuModule,
    MatButtonToggleModule,
    MatExpansionModule
  ],
  exports: [VerticalLayout3Component]
})
export class VerticalLayout3Module {
  constructor(
    @Optional() @SkipSelf() parentModule: VerticalLayout3Module,
    ekLib: EkonIconsLibraryService
  ) {
    if(parentModule) {
      throw new Error(
        'VerticalLayout3Module is already loaded. Import it in the AppModule only!'
      );
    }

    ekLib.addIcons(
      ekIconArrowDoubleLeft,
      ekIconArrowDoubleRight,
      ekIconExpandArrowsAlt,
      ekIconCalendarEvent,
      ekIconPlus,
      ekIconBlogs,
      ekIconStickyNote,
      ekIconMapMarkedAlt,
      ekIconTaskOutlined
    );
  }

  static forRoot(): ModuleWithProviders<VerticalLayout3Module> {
    return {
      ngModule: VerticalLayout3Module,
      providers: [
        {
          provide: DKM_LAYOUTS_LIST,
          useValue: {
            key: 'vertical-layout-3',
            name: 'Vertical Layout #3',
            entryComponent: VerticalLayout3Component
          },
          // https://angular.io/api/core/FactoryProvider
          multi: true
        }
      ]
    };
  }
}
