import { Data, Route } from '@angular/router';
import { BreadcrumbItemLabel, BreadcrumbTitleResolver } from '@ekon-client/shared/common/ekon-breadcrumbs';

import { BreadcrumbResolverService } from './breadcrumb-resolver.service';


type RouteBreadcrumbsConfigItem = BreadcrumbTitleResolver | string | BreadcrumbItemLabel | (() => (route: Data) => string);

interface RouteBreadcrumbsConfig {
  [name: string]: RouteBreadcrumbsConfigItem;
}

export function RouteWithBreadcrumb(
  routeConfig: Route,
  breadcrumbs: Array<RouteBreadcrumbsConfigItem>
): Route {
  const breadcrumbsConfig: RouteBreadcrumbsConfig = {};
  routeConfig.path.split('/').forEach(
    (path: string, i: number) => breadcrumbsConfig[`ekon-breadcrumb[${path}]`] = breadcrumbs[i]
  );

  return {
    ...routeConfig,
    data: {
      ...routeConfig.data,
      ...breadcrumbsConfig,
      'ekon-breadcrumb-route-title': breadcrumbs[breadcrumbs.length - 1],
    },
    resolve: {
      ...routeConfig.resolve,
      breadcrumbs: BreadcrumbResolverService
    }
  };
}
