import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import {
  ekIconBriefcase,
  ekIconTrashAlt,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { AvaViewerModule } from '@ekon-client/shared/features/dkm-files';
import { TranslateModule } from '@ngx-translate/core';

import { LegalEntityCardComponent } from './components';
import { LegalEntityService } from './services';

@NgModule({
  declarations: [LegalEntityCardComponent],
  exports: [LegalEntityCardComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    FlexModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatTabsModule,
    MatCardModule,
    AvaViewerModule,
    EkonDialogsModule,
    EkonIconsModule,
    TranslateModule
  ],
  providers: [
    LegalEntityService,
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-legal-entity/',
      multi: true
    }
  ]
})
export class DkmLegalEntityModule {
  constructor(ekLib: EkonIconsLibraryService) {
    ekLib.addIcons(
      ekIconBriefcase,
      ekIconTrashAlt
    )
  }
}
