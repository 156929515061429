import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileMetadata } from '@ekon-client/dkm-api';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FileService } from '../../../services';
import { ImageCropService } from '../image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'dkm-file-upload-control',
  templateUrl: './file-upload-control.component.html',
  styleUrls: ['./file-upload-control.component.scss']
})
export class FileUploadControlComponent {
  @Input() specificDomain: string;
  @Input() filesPersonalMode: boolean;
  @Input() dropZoneClass = '';
  @Input() multiple = false;
  @Input() aspectRatio?: number;

  @Output() fileUploaded: EventEmitter<FileMetadata> = new EventEmitter();

  public files: NgxFileDropEntry[] = [];

  constructor(
    private fileService: FileService,
    private imageCrop: ImageCropService
  ) {}

  public dropped(files: NgxFileDropEntry[]): void {
    this.files = files;
    const droppedFile = files[0];

    if(droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

      fileEntry.file((file: File) => {
        if(file.type.includes('image')) {
          this.openImageCropperDialog(file);
        } else {
          this.uploadFile(file).subscribe((fileMeta: FileMetadata) => {
            this.fileUploaded.emit(fileMeta);
          });
        }
      });
    }
  }

  uploadFile(file: File): Observable<unknown> {
    return this.fileService.uploadFile(file, this.filesPersonalMode, this.specificDomain);
  }

  openImageCropperDialog(file: File): void {
    this.imageCrop.openImageCropperDialog(file, this.aspectRatio)
      .pipe(
        switchMap((fileFromDialog: File) => this.uploadFile(fileFromDialog))
      )
      .subscribe((fileMeta: FileMetadata) => this.fileUploaded.emit(fileMeta));
  }
}
