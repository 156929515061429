/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateBlogPostModel { 
    title: string;
    /**
     * Gets or sets the blog post body
     */
    body?: string | null;
    imageId?: string | null;
    /**
     * Gets or sets the blog post overview. If specified, then it\'s used on the blog page instead of the \"Body\"
     */
    bodyOverview?: string | null;
    thumbprint?: string | null;
    /**
     * Gets or sets a value indicating whether the blog post comments are allowed
     */
    allowComments?: boolean;
    domains?: Array<string> | null;
    tags?: Array<string> | null;
}

