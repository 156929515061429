import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ekIconExternalLinkAlt,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { DkmMapModule as DkmMapSharedModule } from '@ekon-client/shared/features/dkm-map';
import { TranslateModule } from '@ngx-translate/core';

import { AddressViewComponent } from './components/address-view/address-view.component';
import { DigitalPresenceViewComponent } from './components/digital-presence-view/digital-presence-view.component';
import { LegalPersonViewComponent } from './components/legal-person-view/legal-person-view.component';
import { PhoneViewComponent } from './components/phone-view/phone-view.component';



@NgModule({
  declarations: [
    AddressViewComponent,
    DigitalPresenceViewComponent,
    LegalPersonViewComponent,
    PhoneViewComponent,
  ],
  imports: [
    CommonModule,
    DkmMapSharedModule,
    FlexModule,
    MatTooltipModule,
    EkonIconsModule,
    TranslateModule
  ],
  exports: [
    AddressViewComponent,
    DigitalPresenceViewComponent,
    LegalPersonViewComponent,
    PhoneViewComponent
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-partials/src/lib/cards/',
      multi: true
    }
  ]
})
export class EkonPartialsCardsModule {
  constructor(ekLib: EkonIconsLibraryService) {
    ekLib.addIcons(
      ekIconExternalLinkAlt,
    )
  }
}
