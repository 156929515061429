import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActionRequestType,
  Comment,
  Configuration,
  CreateComment,
  CreateFeedback,
  CreatePageModel,
  FeedbackDetails,
LayoutItem,
  PageDetails,
  PageDetailsAndRelations,
  PageHeaderPagedResult,
  PagePaginationModel,
  PageService,
  PageServiceInterface,
  ProductDetails,
  RelatePageToDomainModel,
  RelatePageToPageModel,
  RemovePageFromDomainModel,
  RemovePageFromPageModel} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PageEventsServiceInterface } from './PageEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class PageEventsService
  implements PageServiceInterface, PageEventsServiceInterface {
  get defaultHeaders(): HttpHeaders {
    return this.pageService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.pageService.defaultHeaders = val;
  }

  get configuration(): Configuration {
    return this.pageService.configuration;
  }

  set configuration(val: Configuration) {
    this.pageService.configuration = val;
  }

  constructor(
    protected pageService: PageService,
    protected progressBar: EkonProgressBarService
  ) {
  }

  private pageCreatedSubject: Subject<PageDetails> = new Subject();

  get itemCreated(): Observable<PageDetails> {
    return this.pageCreatedSubject.asObservable();
  }

  private pageDeletedSubject: Subject<string> = new Subject();

  get itemDeleted(): Observable<string> {
    return this.pageDeletedSubject.asObservable();
  }

  private pageUpdatedSubject: Subject<string> = new Subject();

  get itemUpdated(): Observable<string> {
    return this.pageUpdatedSubject.asObservable();
  }

  private pageElementUpdatedSubject: Subject<LayoutItem> = new Subject();

  get itemElementUpdated(): Observable<LayoutItem> {
    return this.pageElementUpdatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Comment Create action
   */
  private commentCreatedSubject: Subject<Comment> = new Subject();

  get commentCreated(): Observable<Comment> {
    return this.commentCreatedSubject.asObservable();
  }

  emitItemElementUpdated(element: LayoutItem): void {
    this.pageElementUpdatedSubject.next(element);
  }

  /**
   *  (Auth policies: dkm4u_api_auth)
   *
   * @param createPage
   * @param xSelectedDomain
   */
  createPage(createPage?: CreatePageModel, xSelectedDomain?: string): Observable<PageDetails> {
    return this.progressBar
      .apply(this.pageService.createPage(createPage, xSelectedDomain))
      .pipe(tap((res) => this.pageCreatedSubject.next(res)));
  }

  /**
   *  (Auth policies: dkm4u_api_auth)
   *
   * @param id
   */
  findPageById(id: string): Observable<PageDetails> {
    return this.progressBar.apply(this.pageService.findPageById(id));
  }

  /**
   *  (Auth policies: dkm4u_api_auth)
   *
   * @param id
   * @param pageDetails
   */
  updatePage(id: string, pageDetails: PageDetails): Observable<unknown> {
    return this.progressBar
      .apply(this.pageService.updatePage(id, pageDetails))
      .pipe(tap(() => this.pageUpdatedSubject.next(id)));
  }

  /**
   *  (Auth policies: dkm4u_api_auth)
   *
   * @param id
   * @param pageDetailsAndRelations
   */
  updatePageAndRelations(
    id: string,
    pageDetailsAndRelations: PageDetailsAndRelations
  ): Observable<unknown> {
    return this.progressBar
      .apply(
        this.pageService.updatePageAndRelations(id, pageDetailsAndRelations)
      )
      .pipe(tap(() => this.pageUpdatedSubject.next(id)));
  }

  deletePage(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.pageService.deletePage(id))
      .pipe(tap(() => this.pageDeletedSubject.next(id)));
  }

  listPages(
    pagination: PagePaginationModel,
    isTemplate?: boolean,
    isProduct?: boolean,
    domainId?: string
  ): Observable<PageHeaderPagedResult> {
    return this.progressBar
      .apply(this.pageService.listPages(pagination, isTemplate, isProduct, domainId));
  }

  listPagesByTags(
    pagination?: PagePaginationModel,
    isTemplate?: boolean,
    isProduct?: boolean,
    xSelectedDomain?: string
  ): Observable<PageHeaderPagedResult> {
    return this.progressBar.apply(
      this.pageService.listPagesByTags(pagination, isTemplate, isProduct, xSelectedDomain)
    );
  }

  listSelectedPagesByIds(
    pagination: PagePaginationModel,
    pageIds?: string[],
    xSelectedDomain?: string
  ): Observable<PageHeaderPagedResult> {
    return this.progressBar.apply(this.pageService.listSelectedPagesByIds(pagination, pageIds, xSelectedDomain));
  }

  createDerivedOrDuplicatePage(
    id: string,
    actionRequestType: ActionRequestType
  ): Observable<PageDetails> {
    return this.progressBar.apply(
      this.pageService.createDerivedOrDuplicatePage(id, actionRequestType)
    );
  }

  createPageComment(
    id: string,
    createComment: CreateComment
  ): Observable<Comment> {
    return this.progressBar
      .apply(this.pageService.createPageComment(id, createComment))
      .pipe(
        tap((comment: Comment) => this.commentCreatedSubject.next(comment))
      );
  }

  updatePageCategory(id: string, categoryId: string): Observable<unknown> {
    return this.progressBar.apply(
      this.pageService.updatePageCategory(id, categoryId)
    );
  }

  listPagesByCategory(
    categoryId: string,
    pagination: PagePaginationModel,
    xSelectedDomain?: string
  ): Observable<PageHeaderPagedResult> {
    return this.progressBar.apply(
      this.pageService.listPagesByCategory(categoryId, pagination, xSelectedDomain)
    );
  }

  listPagesByNames(
    pagination: PagePaginationModel,
    requestBody: Array<string>,
    xSelectedDomain?: string
  ): Observable<PageHeaderPagedResult> {
    return this.progressBar.apply(this.pageService.listPagesByNames(
      pagination,
      requestBody,
      xSelectedDomain
    ));
  }

  listPagesDashboard(
    pagination: PagePaginationModel,
    isTemplate?: boolean,
    isProduct?: boolean,
    xSelectedDomain?: string
  ): Observable<PageHeaderPagedResult> {
    return this.progressBar.apply(
      this.pageService.listPagesDashboard(
        pagination,
        isTemplate,
        isProduct,
        xSelectedDomain
      )
    );
  }

  listPagesDerivedByTemplate(
    templateId: string,
    pagination: PagePaginationModel,
    xSelectedDomain?: string
  ): Observable<PageHeaderPagedResult> {
    return this.progressBar.apply(this.pageService.listPagesDerivedByTemplate(
      templateId,
      pagination,
      xSelectedDomain
    ));
  }

  createPageFeedback(id: string, createFeedback: CreateFeedback): Observable<FeedbackDetails> {
    return this.progressBar.apply(
      this.pageService.createPageFeedback(id, createFeedback)
    );
  }

  relatePageToDomain(relatePageToDomainModel?: RelatePageToDomainModel): Observable<unknown> {
    return this.progressBar.apply(
      this.pageService.relatePageToDomain(relatePageToDomainModel)
    ).pipe(
      tap(() => this.pageUpdatedSubject.next(relatePageToDomainModel.pageId))
    );
  }

  relatePageToPage(relatePageToPageModel?: RelatePageToPageModel): Observable<PageDetails | ProductDetails> {
    return this.progressBar.apply(
      this.pageService.relatePageToPage(relatePageToPageModel)
    ).pipe(
      tap(() => this.pageUpdatedSubject.next(relatePageToPageModel.fromPageId))
    );
  }

  removePageFromDomain(removePageFromDomainModel?: RemovePageFromDomainModel): Observable<unknown> {
    return this.progressBar.apply(
      this.pageService.removePageFromDomain(removePageFromDomainModel)
    ).pipe(
      tap(() => this.pageUpdatedSubject.next(removePageFromDomainModel.pageId))
    );
  }

  removePageFromPage(removePageFromPageModel?: RemovePageFromPageModel): Observable<PageDetails | ProductDetails> {
    return this.progressBar.apply(
      this.pageService.removePageFromPage(removePageFromPageModel)
    ).pipe(
      tap(() => this.pageUpdatedSubject.next(removePageFromPageModel.fromPageId))
    );
  }

  getPageSiblingPages(
    pageId: string,
    pagePaginationModel?: PagePaginationModel,
    toDirection?: boolean,
    xSelectedDomain?: string
  ): Observable<PageHeaderPagedResult> {
    return this.progressBar.apply(
      this.pageService.getPageSiblingPages(pageId, pagePaginationModel, toDirection, xSelectedDomain)
    );
  }

  createPageFromPopulation(pageDetailsProductDetails?: PageDetails, xSelectedDomain?: string): Observable<PageDetails> {
    return this.progressBar.apply(
      this.pageService.createPageFromPopulation(pageDetailsProductDetails, xSelectedDomain)
    );
  }

  findPageByExternalId(id: string, xSelectedDomain?: string): Observable<PageDetails | ProductDetails> {
    return this.progressBar.apply(
      this.pageService.findPageByExternalId(id, xSelectedDomain)
    );
  }
}
