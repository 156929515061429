import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';
import { EkonTagsModule } from '@ekon-client/shared/common/ekon-utils';

import { CalendarItemListComponent } from './calendar-item-list/calendar-item-list.component';
import { CalendarItemListItemComponent } from './calendar-item-list-item/calendar-item-list-item.component';



@NgModule({
  declarations: [
    CalendarItemListComponent,
    CalendarItemListItemComponent
  ],
  exports: [
    CalendarItemListComponent,
    CalendarItemListItemComponent,
  ],
  imports: [
    CommonModule,
    EkonIconsModule,
    EkonTagsModule,
    MatRippleModule,
    MatMenuModule,
    MatButtonModule
  ]
})
export class CalendarListModule { }
