export enum FileMediaTypes {
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
}
export class FileTypesWeCanView {
  static types = {
    IMAGE: ['image/jpeg', 'image/png', 'image/gif', 'image/bmp'],
    AUDIO: ['audio/mpeg'],
    VIDEO: ['video/mp4', 'video/quicktime'],
    DOCUMENT: ['application/pdf'],
  };

  static canViewFileExt(mime: string): boolean {
    return Object.values(FileTypesWeCanView.types)
      .flat()
      .some(allowedMime => allowedMime === mime);
  }

  static isTypeOf(mime: string, type: FileMediaTypes): boolean {
    return !!FileTypesWeCanView.types[type].find(allowedMime => mime === allowedMime);
  }

  static isImage(mime: string): boolean {
    return !!FileTypesWeCanView.types.IMAGE.find(allowedMime => mime === allowedMime);
  }

  static isAudio(mime: string): boolean {
    return !!FileTypesWeCanView.types.AUDIO.find(allowedMime => mime === allowedMime);
  }

  static isVideo(mime: string): boolean {
    return !!FileTypesWeCanView.types.VIDEO.find(allowedMime => mime === allowedMime);
  }

  static isDocument(mime: string): boolean {
    return !!FileTypesWeCanView.types.DOCUMENT.find(allowedMime => mime === allowedMime);
  }
}
