import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve} from '@angular/router';
import { noop as _noop } from 'lodash-es';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export abstract class AbstractEntityResolver<T> implements Resolve<T> {

  idKey = 'id';

  private entityID: string;

  private _entitySubject$: BehaviorSubject<T> = new BehaviorSubject(null);

  readonly entity$: Observable<T> = this._entitySubject$.asObservable();
  // private entityUpdateEvent$: Observable<unknown>;
  private entityLoader: (id: string) => Observable<T>;

  // private unsubscribeAll: Subject<void> = new Subject();

  protected constructor(
    entityUpdateEvent: Observable<unknown>,
    entityLoader: (id: string) => Observable<T>
  ) {
    // this.entityUpdateEvent$ = entityUpdateEvent;
    this.entityLoader = entityLoader;

    entityUpdateEvent.pipe(
      filter((id: string) => Boolean(this.entityID) && id === this.entityID),
      // takeUntil(this.unsubscribeAll),
      switchMap(() => this.loadData(this.entityID))
    ).subscribe(_noop);
  }


  resolve(route: ActivatedRouteSnapshot): Observable<T> {
    return this.entity$.pipe(
      take(1),
      switchMap(() => this.loadData(route.params[this.idKey])
        // switchMap((data: T) => data
      //                        ? of(data)
      //                        : this.loadData(route.params[this.idKey])
      // )

      // switchMap((data: T) => this.entityID === route.params[this.idKey] && data
      //   ? of(data)
      //   : this.loadData(route.params[this.idKey])
      // )
      )
    );
  }

  loadData(id: string): Observable<T> {
    this.entityID = id;

    return this.entityLoader(id).pipe(
      tap({
        next: (d: T) => this._entitySubject$.next(d)
      })
    );
  }

  releaseMemory(): void {
    this.entityID = null;
    this._entitySubject$.next(null);
    // this.unsubscribeAll.next();
    // this.unsubscribeAll.complete();
  }

  ngOnDestroy() {
    this.releaseMemory();
  }
}
