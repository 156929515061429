<ekon-dialog-wrapper [isFullscreen]="true">
  <div mat-dialog-title>{{globalConfig.domainTitleSingular | uppercase | translate}} {{'SELECTION' | translate: { default: 'selection' } }}</div>
  <mat-dialog-content>
    <ekon-paginator [config]="paginatorConfig" #paginator="ekonPaginator">
      <ekon-cards-grid>
        <dkm-domain-card
          *ngFor="let domain of paginator.items$ | async; trackBy: trackById"
          [domain]="domain"
          [disableAllActions]="true"
          (select)="validateAndSave(domain)"
        ></dkm-domain-card>
      </ekon-cards-grid>
    </ekon-paginator>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxFlex="0 0 auto">
    <button mat-button [mat-dialog-close]="false">{{'CANCEL' | translate: { default: 'Cancel' } }}</button>
  </mat-dialog-actions>
</ekon-dialog-wrapper>
