import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { noop as _noop } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

export function initTranslations(_translateService: TranslateService, skipInitial = false): Subscription {
  const resetLang: (lang: string) => Observable<unknown> = (lang: string) => {
    _translateService.currentLang = '';
    return _translateService.use(lang);
    // return _translateService.reloadLang(lang);
  };

  const changeListener = _translateService.onLangChange.pipe(
    distinctUntilChanged((x: LangChangeEvent, y: LangChangeEvent) => x.lang === y.lang),
    switchMap((lang: LangChangeEvent) => resetLang(lang.lang)),
  );

  return (skipInitial
    ? changeListener
    : resetLang(_translateService.currentLang).pipe(
      switchMap(() => changeListener)
    )).subscribe(_noop);
}
