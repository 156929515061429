import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BreadcrumbItem, BreadcrumbItemTitle } from '@ekon-client/shared/common/ekon-breadcrumbs';
import { isFunction as _isFunction } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface BreadcrumbItemConfig {
  title: BreadcrumbItemTitle;
  url: string[];
}


@Injectable()
export class BreadcrumbResolverService implements Resolve<BreadcrumbItem[]> {
  constructor(
    private inj: Injector
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<BreadcrumbItem[]> | BreadcrumbItem[] {
    const previousSegment = route.pathFromRoot.filter(
      snapshot => Boolean(snapshot.data.breadcrumbs) && snapshot.routeConfig.path !== ''
    ).pop();

    const previousBreadcrumbs: BreadcrumbItem[] = previousSegment ? [...previousSegment.data.breadcrumbs] : [];

    const newUrls = [...route.url];
    newUrls.pop();
    const newBreadcrumbs = newUrls.map(
      (segment, i) => ({
        url: [segment.path],
        title: this.getData(i, route)
      }) as BreadcrumbItemConfig
    );

    previousSegment && newBreadcrumbs.unshift({
      url: [[...previousSegment.url].pop().path],
      title: this.getData(previousSegment.url.length - 1, previousSegment)
    });


    return combineLatest([
      of(previousBreadcrumbs) as Observable<BreadcrumbItem[]>,
      ...(newBreadcrumbs.map((breadCrumb: BreadcrumbItemConfig) => {
        return (
          breadCrumb.title && breadCrumb.title instanceof Observable
            ? breadCrumb.title
            : of(breadCrumb.title)
        ).pipe(
          map(title => ({ url: breadCrumb.url, title }) as BreadcrumbItem)
        );
      }) as Observable<BreadcrumbItem>[])
    ]).pipe(
      map(([parentBreadcrumbs, ...breadcrumbs]) => [...(parentBreadcrumbs as BreadcrumbItem[]), ...(breadcrumbs as BreadcrumbItem[])])
    );
  }

  getData(i: number, route: ActivatedRouteSnapshot): BreadcrumbItemTitle {
    const keys = Object.keys(route.data);
    const key = keys.find(k => {
      const r = k.match(/^ekon-breadcrumb\[(.+)]$/);
      return r && r[1] === route.routeConfig.path.split('/')[i];
    });

    return route.data[key] && _isFunction(route.data[key]) ? route.data[key](route, this.inj) : route.data[key];
  }
}
