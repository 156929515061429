import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isString as _isString } from 'lodash-es';

import { SizeProp } from '../../extras/SizeProp';
import { EkonIconDefinition, EkonIconType } from '../../icon-definitions';
import { EkonIconsLibraryService } from '../../services';


@Component({
  selector: 'ekon-icon',
  template: '',
  styleUrls: ['ekon-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class EkonIconComponent {
  private _icon: EkonIconDefinition | undefined;

  @Input() color = false;
  @Input() directionSensitive = true;
  @Input() set icon(value: EkonIconType | EkonIconDefinition) {
    this._icon = _isString(value) ? this.lib.getIcon(value) : value;
  };

  @Input() size: SizeProp;

  @HostBinding('class') get sizeClass(): string {
    return `eki-${this.size}`;
  };

  @HostBinding('class.no-color') get colored(): boolean {
    return !this.color;
  };

  @HostBinding('class.dir-sensitive') get dirSensitive(): boolean {
    return this.directionSensitive;
  };

  @HostBinding('innerHTML') get renderedIconHTML(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this._icon?.data || '');
  };

  constructor(
    private lib: EkonIconsLibraryService,
    private sanitizer: DomSanitizer
  ) {
  }
}
