/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  Inject,
  OnDestroy,
  TemplateRef,
  TrackByFunction,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BlogPostHeader,
  BlogServiceInterface,
  CalendarServiceInterface,
  NoteDetails, NoteDetailsPagedResult,
  NoteServiceInterface,
  RssDetails,
  RssServiceInterface,
  StatisticsServiceInterface,
  TagsWordCloudResults,
  UserProfile
} from '@ekon-client/dkm-api';
import {
  BLOG_ACTIONS, BLOG_EVENTS, BlogEventsServiceInterface,
  CALENDAR_ACTIONS,
  CALENDAR_EVENTS,
  CalendarEventsServiceInterface,
  NOTE_ACTIONS,
  NOTE_EVENTS,
  NoteEventsServiceInterface,
  RSS_ACTIONS, RSS_EVENTS,
  RssEventsServiceInterface, STATISTICS_ACTIONS
} from '@ekon-client/dkm-events';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import {
  ekIconCalendarEvent,
  ekIconClock,
  ekIconInfo, ekIconPages, ekIconShoppingBag, ekIconStores,
  ekIconTaskOutlined
} from '@ekon-client/shared/common/ekon-icons';
import { PaginationModel, TopListConfig } from '@ekon-client/shared/common/ekon-pagination';
import { EkonMediaQueryService, EkonNavigationHistoryService } from '@ekon-client/shared/common/ekon-utils';
import { DomainSelectorService } from '@ekon-client/shared/features/dkm-domains-services';
import { NotesService } from '@ekon-client/shared/features/dkm-notes';
import { PermissionsService } from '@ekon-client/shared/features/dkm-permissions';
import { UserProfileService } from '@ekon-client/shared/features/dkm-user-profile';
import { noop as _noop } from 'lodash-es';
import { merge, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  filter,
  map, shareReplay,
  switchMap, take,
  takeUntil, tap
} from 'rxjs/operators';

import { SidebarService } from '../../../services';

@Component({
  selector: 'vertical-layout-3',
  templateUrl: './layout-3.component.html',
  styleUrls: ['./layout-3.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalLayout3Component implements OnDestroy {
  ekIconInfo = ekIconInfo;
  ekIconClock = ekIconClock;
  ekIconCalendarEvent = ekIconCalendarEvent;
  ekIconPages = ekIconPages;
  ekIconStores = ekIconStores;
  ekIconShoppingBag = ekIconShoppingBag;
  ekIconTaskOutlined = ekIconTaskOutlined;

  panelOpenState = false;

  public showPlatformNotes$: Observable<boolean> = merge(
    of(true),
    this.noteEvent.noteCreated,
    this.noteEvent.noteUpdated,
    this.noteEvent.noteDeleted,
    this.domainsSelector.domainsSelectionChanged$
  ).pipe(
    switchMap(() => this.platformNotesLoader({
      pageNumber: 1,
      pageSize: 1
    })),
    map((r: NoteDetailsPagedResult) => Boolean(r.totalItems))
  );

  platformNotesLoader(pagination: PaginationModel): Observable<NoteDetailsPagedResult> {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    return this.noteActions.listNoteTodaysRemiders(pagination, today, true);
  };

  platformNotesPaginatorConfig: TopListConfig = {
    loader: pagination => this.platformNotesLoader(pagination),
    observables: [
      this.noteEvent.noteCreated,
      this.noteEvent.noteUpdated,
      this.noteEvent.noteDeleted,
      this.domainsSelector.domainsSelectionChanged$
    ],
    pageSize: 20
  };

  notesPaginatorConfig: TopListConfig = {
    loader: (pagination: PaginationModel) => {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);

      return this.noteActions.listNoteTodaysRemiders(pagination/*, today*/);
    },
    observables: [
      this.noteEvent.noteCreated,
      this.noteEvent.noteUpdated,
      this.noteEvent.noteDeleted,
      this.domainsSelector.domainsSelectionChanged$
    ]
  };
  trackNotesById: TrackByFunction<NoteDetails> = (index, item) => item.id;

  // rssPaginatorConfig: TopListConfig = {
  //   loader: (pagination: PaginationModel) => this.rssActions.listRsses(pagination/*, today*/),
  //   observables: [
  //     this.rssEvents.rssCreated,
  //     this.rssEvents.rssUpdated,
  //     this.rssEvents.rssDeleted,
  //     this.domainsSelector.domainsSelectionChanged$
  //   ]
  // };
  rssList$: Observable<RssDetails[]>;
  trackRssById: TrackByFunction<RssDetails> = (index, item) => item.id;

  eventsTopConfig: TopListConfig = {
    loader: (pagination: PaginationModel) => this.calendarActions.getCalendar(
      pagination,
      'Event',
      undefined,
      new Date(),
      undefined
    ),
    observables: [
      this.calendarEvents.calendarItemCreated,
      this.calendarEvents.calendarItemDeleted,
      this.calendarEvents.calendarItemUpdated,
      this.domainsSelector.domainsSelectionChanged$
    ]
  };

  tasksTopConfig: TopListConfig = {
    loader: (pagination: PaginationModel) => this.calendarActions.getCalendar(
      pagination,
      'TaskAction',
      undefined,
      new Date(),
      undefined
    ),
    observables: [
      this.calendarEvents.calendarItemCreated,
      this.calendarEvents.calendarItemDeleted,
      this.calendarEvents.calendarItemUpdated,
      this.domainsSelector.domainsSelectionChanged$
    ]
  };

  blogsTopConfig: TopListConfig = {
    pageSize: 10,
    loader: (pagination: PaginationModel) => this.blogActions.listBlogPosts(pagination),
    observables: [
      this.domainsSelector.domainsSelectionChanged$,
      this.blogEvents.blogPostUpdated,
      this.blogEvents.blogPostCreated,
      this.blogEvents.blogPostDeleted
    ]
  };
  trackBlogsBy: TrackByFunction<BlogPostHeader> = (index, item) => item.id;

  wordCloud$: Observable<[string, number][]>;
  tagCloudDialog: MatDialogRef<unknown, string>;

  private _unsubscribeAll: Subject<void> = new Subject();
  public user: Observable<UserProfile>;

  isMobile$: Observable<boolean>;

  @ViewChild('tagCloudDialog', { read: TemplateRef }) private tagCloudDialogTpl: TemplateRef<unknown>;

  constructor(
    private userProfile: UserProfileService,
    @Inject(CALENDAR_ACTIONS) private calendarActions: CalendarServiceInterface,
    @Inject(CALENDAR_EVENTS) private calendarEvents: CalendarEventsServiceInterface,
    @Inject(NOTE_ACTIONS) private noteActions: NoteServiceInterface,
    @Inject(NOTE_EVENTS) private noteEvent: NoteEventsServiceInterface,
    @Inject(RSS_ACTIONS) private rssActions: RssServiceInterface,
    @Inject(RSS_EVENTS) private rssEvents: RssEventsServiceInterface,
    @Inject(BLOG_ACTIONS) private blogActions: BlogServiceInterface,
    @Inject(BLOG_EVENTS) private blogEvents: BlogEventsServiceInterface,
    @Inject(STATISTICS_ACTIONS) private statisticsActions: StatisticsServiceInterface,
    private domainsSelector: DomainSelectorService,
    private notesService: NotesService,
    private router: Router,
    public globalConfig: GlobalConfigurationService,
    public route: ActivatedRoute,
    public sidebarService: SidebarService,
    private media: EkonMediaQueryService,
    public permissions: PermissionsService,
    private dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public navHistory: EkonNavigationHistoryService
  ) {
    this.user = userProfile.currentUser$;

    this.rssList$ = merge(
      of(true),
      this.rssEvents.rssCreated,
      this.rssEvents.rssUpdated,
      this.rssEvents.rssDeleted,
      this.domainsSelector.domainsSelectionChanged$
    ).pipe(
      switchMap(() => this.rssActions.listRsses())
    );

    this.isMobile$ = media.isMobile$;

    this.wordCloud$ = merge(
      of(true),
      this.domainsSelector.domainsSelectionChanged$
    ).pipe(
      switchMap(() => statisticsActions.pagesTagsWordCloud().pipe(
        catchError(() => of({ series: [] } as TagsWordCloudResults))
      )),
      shareReplay({ bufferSize: 1, refCount: true }),
      map((wcr: TagsWordCloudResults) => wcr.series.map(s => [s.tag, s.count] as [string, number])),
      takeUntil(this._unsubscribeAll)
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  editNote(note: NoteDetails): void {
    this.notesService.editNote(note).subscribe();
  }

  goToNotes(): void {
    this.router.navigate(['my-space', 'notes']);
  }

  goToEvents(): void {
    this.router.navigate(['calendar'], {
      queryParams: {
        type: 'Event'
      },
      queryParamsHandling: 'merge'
    });
  }

  goToTasks(): void {
    this.router.navigate(['calendar'], {
      queryParams: {
        type: 'TaskAction'
      },
      queryParamsHandling: 'merge'
    });
  }

  viewTask(itemId: string): void {
    this.router.navigate(['calendar', itemId], {
      queryParams: {
        type: 'TaskAction'
      },
      queryParamsHandling: 'merge'
    });
  }

  viewEvent(itemId: string): void {
    this.router.navigate(['calendar', itemId], {
      queryParams: {
        type: 'Event'
      },
      queryParamsHandling: 'merge'
    });
  }

  addNote(): void {
    this.notesService.addNote().subscribe(_noop);
  }

  readRss(rss: RssDetails): void {
    this.router.navigate(['rss', rss.id]);
  }

  addRss(): void {
    // todo: call add rss dialog
    //  OR navigate to rss/create route (BETTER solution, implementation needed)
  }

  toggleSidebarStatus(key): void {
    this.sidebarService.toggle(key);
  }

  addBlog() {
    this.router.navigate(['my-space/blogs/create']);
  }

  searchByTag(tag: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { 'tags': tag },
      queryParamsHandling: 'merge'
    });
  }

  magnifyWordCloud(): void {
    this.tagCloudDialog = this.dialog.open(this.tagCloudDialogTpl, {
      ...getModalDefaultConfig(),
      maxWidth: '800px'
    });

    this.tagCloudDialog.afterClosed().pipe(
      take(1),
      tap({
        next: () => this.tagCloudDialog = undefined
      }),
      filter(r => Boolean(r))
    ).subscribe({
      next: tag => tag && this.searchByTag(tag)
    });
  }

  closeTagCloud(tag?: string) {
    this.tagCloudDialog.close(tag);
  }

}
