import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { WYSIWYG_MODES, WysiwygMode } from '@ekon-client/shared/common/ekon-wysiwyg-adapter';

import { EkonWysiwygEditorComponent } from './components/ekon-wysiwyg-editor/ekon-wysiwyg-editor.component';
import { EkonHandleAppLinksDirective } from './directives/ekon-handle-app-links.directive';
import { WYSIWYG_MODE } from './extras/ModeInjector';



@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    EkonWysiwygEditorComponent,
    EkonHandleAppLinksDirective
  ],
  exports: [
    EkonWysiwygEditorComponent,
    EkonHandleAppLinksDirective
  ],
  providers: [{
    provide: WYSIWYG_MODE,
    useValue: WYSIWYG_MODES.DEFAULT
  }]
})
export class EkonWysiwygModule {
  static forChild(mode: WysiwygMode): ModuleWithProviders<EkonWysiwygModule> {
    return {
      ngModule: EkonWysiwygModule,
      providers: [{
        provide: WYSIWYG_MODE,
        useValue: mode
      }]
    };
  }
}
