<ekon-dialog-wrapper>
  <div mat-dialog-title class="h2 mb-24" fxLayoutAlign="start center">Title</div>
  <mat-dialog-content  fxFlex="1 0 auto">
  <form [formGroup]="form" fxLayout="column" class="h-100-p">
    <div mat-dialog-title class="h2 mb-24" fxLayoutAlign="start center">Geo Location</div>
    <div fxFlex="1 0 auto">
      <div fxLayout="column" fxLayoutAlign="start stretch">
        <mat-tab-group>
          <mat-tab label="Basic">
            <div fxLayout="column" fxLayoutAlign="start stretch" class="mt-16">

              <ng-container
                *ngTemplateOutlet="isArray(location[0]) ? LatLngList : LatLng; context: { $implicit: location}"
              ></ng-container>

              <ng-template #LatLngList let-location>
                <ng-container *ngFor="let locationPoint of location">
                  <ng-container *ngTemplateOutlet="LatLng; context: { $implicit: locationPoint }"></ng-container>
                </ng-container>
              </ng-template>

              <ng-template #LatLng let-location>
                <div class="mb-16">
                  <small class="text-boxed m-0 mr-8">lng.</small>{{location[0]}}
                  <small class="text-boxed ml-16 mr-8">lat.</small>{{location[1]}}
                </div>
              </ng-template>

              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Name</mat-label>
                <input
                  matInput
                  placeholder="Location name"
                  formControlName="name"
                />
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Pages</mat-label>
                <input
                  matInput
                  placeholder="Select pages"
                  formControlName="pages"
                />
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Tags</mat-label>
                <input
                  matInput
                  placeholder="Tags"
                  formControlName="tags"
                />
              </mat-form-field>
            </div>
          </mat-tab>

          <mat-tab label="Properties">
            <div
              fxLayout="column"
              formArrayName="properties"
            >
              <button
                mat-button color="primary"
                class="my-16 w-124"
                type="button"
                (click)="addProperty()"
              >
                <span>Add new</span>
                <ekon-icon icon="plus" class="ml-8"></ekon-icon>
              </button>
             <div
                *ngFor="let property of propertiesControls; let i = index"
                [formGroupName]="i"
                fxLayout="row"
              >
                <mat-form-field appearance="outline" fxFlex="50" class="pr-8">
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="name"/>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" class="pl-8">
                  <mat-label>Value</mat-label>
                  <input matInput formControlName="value"/>
                </mat-form-field>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxFlex="0 0 auto" fxLayoutGap="16px">
      <button
        mat-stroked-button color="warn"
        type="button"
        (click)="deleteMarker()"
      >Delete
      </button>
      <button
        mat-stroked-button color="primary"
        type="button"
        (click)="applyChanges()"
      >Save
      </button>
    </div>
  </form>
  </mat-dialog-content>
</ekon-dialog-wrapper>
