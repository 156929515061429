import { Directive, ElementRef, Input, OnChanges, Optional, SimpleChanges } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { AvaSetterComponent } from '@ekon-client/shared/features/dkm-files';
import { EkonPermissionActionType, PermissionsService } from '@ekon-client/shared/features/dkm-permissions';
import { isNil as _isNil } from 'lodash-es';
import { take } from 'rxjs/operators';

export interface EkonAuthGuardConfig {
  feature?: string;
  action: EkonPermissionActionType
}

@Directive({
  selector: '[ekonAuthGuard]'
})
export class AuthGuardDirective implements OnChanges {
  @Input() ekonAuthGuard: EkonAuthGuardConfig;
  @Input() ekonAuthHidden: boolean;

  constructor(
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private el: ElementRef,
    @Optional() private matButton: MatButton,
    @Optional() private avaSetter: AvaSetterComponent,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.ekonAuthGuard && !_isNil(changes.ekonAuthGuard.currentValue)) {
      this.permissionsService.hasPermission(
        this.ekonAuthGuard.feature
          ? this.ekonAuthGuard.feature
          : this.route.snapshot.data.permissionFeature,
        this.ekonAuthGuard.action
      ).pipe(
        take(1),
      ).subscribe({
        next: (hasPermission: boolean) => {
          if (this.matButton) {
            this.matButton.disabled = !hasPermission;
          } else if (this.avaSetter) {
            this.avaSetter.editDisabled = !hasPermission;
          } else {
            this.el.nativeElement.disabled = !hasPermission;
          }

          this.el.nativeElement.style.display = !hasPermission && this.ekonAuthHidden ? 'none' : null;
        }
      });

    }
  }
}
