import { Injectable } from '@angular/core';
import { compact as _compact, filter as _filter, map as _map } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import {
  AuthConfig,
  ColorThemes,
  GlobalConfiguration,
  LanguageSet,
  PresetColors,
  Translation,
  WysiwygType
} from '../classes';

@Injectable({
  providedIn: 'platform'
})
export class GlobalConfigurationService {
  private config$: BehaviorSubject<GlobalConfiguration> = new BehaviorSubject(null);

  public config: Observable<GlobalConfiguration> = this.config$.asObservable().pipe(filter(v => !!v));

  /** Setter for environment variable */
  setEnvironment(env: Observable<GlobalConfiguration>): Observable<GlobalConfiguration> {
    return env.pipe(
      filter(cfg => !!cfg),
      take(1),
      tap(cfg => {
        this.config$.next(cfg);
      })
    );
  }

  /** Getter for environment variable */
  public get environment(): GlobalConfiguration {
    return this.config$.getValue();
  };

  public get environment$(): Observable<GlobalConfiguration> {
    return this.config;
  };

  public get baseApi(): string {
    return this.config$.getValue()?.API_PATH;
  }

  public get baseApi$(): Observable<string> {
    return this.config.pipe(map(env => env.API_PATH));
  }

  public get WYSIWYG$(): Observable<WysiwygType> {
    return this.config.pipe(map(env => env.WYSIWYG || 'quill'));
  }

  public get presetColors$(): Observable<PresetColors> {
    return this.config.pipe(map(env => env.PRESET_COLORS));
  }

  public get presetColorsList$(): Observable<string[] | undefined> {
    return this.config.pipe(map(env => env.PRESET_COLORS && Object.values(env.PRESET_COLORS)));
  }

  public get langAvailable(): LanguageSet {
    return this.config$.getValue()?.LANGUAGES || {};
  }

  public get langAvailable$(): Observable<LanguageSet> {
    return this.config.pipe(map(env => env.LANGUAGES || {}));
  }

  public get langDefault(): string {
    return this.config$.getValue()?.DEFAULT_LANGUAGE;
  }

  public get translations(): Translation[] {
    // return this.config$.getValue()?.TRANSLATIONS;
    return [];
  }

  public get logoURL(): string {
    return this.config$.getValue()?.LOGO_URI;
  }

  public get projectDisplayName(): string {
    return this.config$.getValue()?.PROJECT_DISPLAY_NAME;
  }

  public get projectDisplayNameShort(): string {
    return this.config$.getValue()?.PROJECT_DISPLAY_NAME_SHORT;
  }

  public get chatbotEnabled$(): Observable<boolean> {
    return this.config.pipe(map(env => env.ENABLE_WG_CHATBOT));
  }

  public get chatbotEnabled(): boolean {
    return this.config$.getValue()?.ENABLE_WG_CHATBOT;
  }

  public get layoutSelector(): boolean {
    return this.config$.getValue()?.LAYOUT_SELECTOR;
  }

  public get domainTitleSingular$(): Observable<string> {
    return this.config.pipe(map(env => env.DOMAIN_TITLE_SINGULAR));
  }

  public get domainTitleSingular(): string {
    return this.config$.getValue()?.DOMAIN_TITLE_SINGULAR;
  }

  public get domainTitlePlural$(): Observable<string> {
    return this.config.pipe(map(env => env.DOMAIN_TITLE_PLURAL));
  }

  public get domainTitlePlural(): string {
    return this.config$.getValue()?.DOMAIN_TITLE_PLURAL;
  }

  public get footer(): GlobalConfiguration['FOOTER'] {
    return this.config$.getValue()?.FOOTER;
  }

  public get useBasket(): boolean {
    return this.config$.getValue()?.USE_BASKET;
  }
  public get useBasket$(): Observable<boolean> {
    return this.config.pipe(map(env => env.USE_BASKET));
  }

  public get enableLegalEntity(): boolean {
    return this.config$.getValue()?.ENABLE_LEGAL_ENTITY;
  }

  public get enableFeed(): boolean {
    return this.config$.getValue()?.ENABLE_FEED;
  }

  public get enableMap(): boolean {
    return this.config$.getValue()?.ENABLE_MAP;
  }

  public get enableKBE(): boolean {
    return this.config$.getValue()?.ENABLE_KBE;
  }

  public get enableRSS(): boolean {
    return this.config$.getValue()?.ENABLE_RSS;
  }

  public get enableMeetups(): boolean {
    return this.config$.getValue()?.ENABLE_MEETUPS;
  }

  public get enableMeetups$(): Observable<boolean> {
    return this.config.pipe(map(env => env.ENABLE_MEETUPS || false));
  }

  public get enableTasks(): boolean {
    return this.config$.getValue()?.ENABLE_TASKS;
  }

  public get enableTasks$(): Observable<boolean> {
    return this.config.pipe(map(env => env.ENABLE_TASKS || false));
  }

  public get enableNotes$(): Observable<boolean> {
    return this.config.pipe(map(env => env.ENABLE_NOTES || false));
  }

  public get enableNotes(): boolean {
    return this.config$.getValue()?.ENABLE_NOTES;
  }

  public get enablePlatformNotes$(): Observable<boolean> {
    return this.config.pipe(map(env => env.ENABLE_PLATFORM_NOTES || false));
  }

  public get enablePlatformNotes(): boolean {
    return this.config$.getValue()?.ENABLE_PLATFORM_NOTES;
  }

  public get enableBlogs$(): Observable<boolean> {
    return this.config.pipe(map(env => env.ENABLE_BLOGS || false));
  }

  public get enableBlogs(): boolean {
    return this.config$.getValue()?.ENABLE_BLOGS;
  }

  public get enableBlockNReportContent$(): Observable<boolean> {
    return this.config.pipe(map(env => env.ENABLE_BLOCK_N_REPORT_CONTENT || false));
  }

  public get enableBlockNReportContent(): boolean {
    return this.config$.getValue()?.ENABLE_BLOCK_N_REPORT_CONTENT;
  }

  public get enableTagCloud(): boolean {
    return this.config$.getValue()?.ENABLE_TAG_CLOUD;
  }

  public get useAltHomeDashboard(): boolean {
    return this.config$.getValue()?.USE_ALT_HOME_DASHBOARD;
  }

  public get dashboardGreeting(): string {
    return this.config$.getValue()?.DASHBOARD_GREETING;
  }

  public get dashboardGreetingBackground(): string {
    return this.config$.getValue()?.DASHBOARD_GREETING_BACKGROUND;
  }

  public get colorThemes$(): Observable<string[]> {
    return this.config.pipe(map(() => this.bakeThemes(this.config$.getValue().COLOR_THEMES)));
  }

  public get colorThemes(): string[] {
    return this.bakeThemes(this.config$.getValue().COLOR_THEMES);
  }

  bakeThemes(themes: ColorThemes): string[] {
    return themes && _compact(_map(themes)).length
      ? _filter(
        _map(
          themes,
          (v, key) => key
        ),
        key => themes[key]
      ).map(v => v.replace(/_/g, '-'))
      : [
        'theme-light-blue-dark',
        'theme-blue-light',
        'theme-yellow-light',
        'theme-pink-dark'
      ];
  }

  // public get authSettings(): Observable<AuthConfig> {
  //   return this.config.pipe(map(env => env.AUTH_SETTINGS));
  // }

  public get authSettings$(): Observable<AuthConfig> {
    return this.config.pipe(map(env => env.AUTH_SETTINGS));
  }
  public get authSettings(): AuthConfig {
    return this.config$.getValue()?.AUTH_SETTINGS;
  }


  getVar(key: string): unknown {
    return this.config$.getValue()[key];
  }
}

export function getBaseApi(config: GlobalConfigurationService): string {
  return config.baseApi;
}
// export function getBaseApi(config: GlobalConfigurationService): Observable<string> {
//   return config.baseApi;
// }
