import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserHeaderModel } from '@ekon-client/dkm-api';

@Component({
  selector: 'ekon-client-user-card-mini',
  templateUrl: './user-card-mini.component.html',
  styleUrls: ['./user-card-mini.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCardMiniComponent {

  @Input() user?: UserHeaderModel;
  @Input() selectMode?: boolean;

  @Output() selected = new EventEmitter<UserHeaderModel>();

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}


  handleClick(user: UserHeaderModel) {
    this.selectMode
    ? this.selected.emit(user)
    : this.router.navigate(
      [user.id],
      { relativeTo: this.route }
    );
  }
}
