/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ActionRequestType } from '../model/models';
import { Comment } from '../model/models';
import { CreateComment } from '../model/models';
import { CreateFeedback } from '../model/models';
import { CreatePageModel } from '../model/models';
import { FeedbackDetails } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { PageDetails } from '../model/models';
import { PageDetailsAndRelations } from '../model/models';
import { PageHeaderPagedResult } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';
import { ProductDetails } from '../model/models';
import { RelatePageToDomainModel } from '../model/models';
import { RelatePageToPageModel } from '../model/models';
import { RemovePageFromDomainModel } from '../model/models';
import { RemovePageFromPageModel } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    PageServiceInterface
} from './page.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class PageService implements PageServiceInterface {

    protected basePath = 'http://dkm-pl.ekonm.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param actionRequestType 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDerivedOrDuplicatePage(id: string, actionRequestType?: ActionRequestType, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageDetails | ProductDetails>;
    public createDerivedOrDuplicatePage(id: string, actionRequestType?: ActionRequestType, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageDetails | ProductDetails>>;
    public createDerivedOrDuplicatePage(id: string, actionRequestType?: ActionRequestType, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageDetails | ProductDetails>>;
    public createDerivedOrDuplicatePage(id: string, actionRequestType?: ActionRequestType, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling createDerivedOrDuplicatePage.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageDetails | ProductDetails>(`${this.configuration.basePath}/api/protected/v1/page/${encodeURIComponent(String(id))}/duplicate`,
            actionRequestType,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param createPageModel 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPage(createPageModel?: CreatePageModel, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageDetails | ProductDetails>;
    public createPage(createPageModel?: CreatePageModel, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageDetails | ProductDetails>>;
    public createPage(createPageModel?: CreatePageModel, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageDetails | ProductDetails>>;
    public createPage(createPageModel?: CreatePageModel, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageDetails | ProductDetails>(`${this.configuration.basePath}/api/protected/v1/page`,
            createPageModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param createComment 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPageComment(id: string, createComment?: CreateComment, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Comment>;
    public createPageComment(id: string, createComment?: CreateComment, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Comment>>;
    public createPageComment(id: string, createComment?: CreateComment, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Comment>>;
    public createPageComment(id: string, createComment?: CreateComment, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling createPageComment.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Comment>(`${this.configuration.basePath}/api/protected/v1/page/${encodeURIComponent(String(id))}/comment`,
            createComment,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param createFeedback 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPageFeedback(id: string, createFeedback?: CreateFeedback, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<FeedbackDetails>;
    public createPageFeedback(id: string, createFeedback?: CreateFeedback, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<FeedbackDetails>>;
    public createPageFeedback(id: string, createFeedback?: CreateFeedback, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<FeedbackDetails>>;
    public createPageFeedback(id: string, createFeedback?: CreateFeedback, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling createPageFeedback.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<FeedbackDetails>(`${this.configuration.basePath}/api/protected/v1/page/${encodeURIComponent(String(id))}/feedback`,
            createFeedback,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create from population batch. Productd are in products controllerss
     * @param pageDetailsProductDetails 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPageFromPopulation(pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageDetails | ProductDetails>;
    public createPageFromPopulation(pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageDetails | ProductDetails>>;
    public createPageFromPopulation(pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageDetails | ProductDetails>>;
    public createPageFromPopulation(pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageDetails | ProductDetails>(`${this.configuration.basePath}/api/protected/v1/page/create/page/from/population`,
            pageDetailsProductDetails,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePage(id: string, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public deletePage(id: string, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public deletePage(id: string, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public deletePage(id: string, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deletePage.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/protected/v1/page/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findPageByExternalId(id: string, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageDetails | ProductDetails>;
    public findPageByExternalId(id: string, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageDetails | ProductDetails>>;
    public findPageByExternalId(id: string, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageDetails | ProductDetails>>;
    public findPageByExternalId(id: string, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling findPageByExternalId.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageDetails | ProductDetails>(`${this.configuration.basePath}/api/protected/v1/page/findpagebyexternalid/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findPageById(id: string, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageDetails | ProductDetails>;
    public findPageById(id: string, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageDetails | ProductDetails>>;
    public findPageById(id: string, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageDetails | ProductDetails>>;
    public findPageById(id: string, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling findPageById.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageDetails | ProductDetails>(`${this.configuration.basePath}/api/protected/v1/page/findpagebyid/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param pageId 
     * @param pagePaginationModel 
     * @param toDirection 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPageSiblingPages(pageId: string, pagePaginationModel?: PagePaginationModel, toDirection?: boolean, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageHeaderPagedResult>;
    public getPageSiblingPages(pageId: string, pagePaginationModel?: PagePaginationModel, toDirection?: boolean, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageHeaderPagedResult>>;
    public getPageSiblingPages(pageId: string, pagePaginationModel?: PagePaginationModel, toDirection?: boolean, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageHeaderPagedResult>>;
    public getPageSiblingPages(pageId: string, pagePaginationModel?: PagePaginationModel, toDirection?: boolean, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (pageId === null || pageId === undefined) {
            throw new Error('Required parameter pageId was null or undefined when calling getPageSiblingPages.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (toDirection !== undefined && toDirection !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>toDirection, 'toDirection');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageHeaderPagedResult>(`${this.configuration.basePath}/api/protected/v1/page/${encodeURIComponent(String(pageId))}/list/sibling/pages`,
            pagePaginationModel,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param pagePaginationModel 
     * @param isTemplate 
     * @param isProduct 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listPages(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageHeaderPagedResult>;
    public listPages(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageHeaderPagedResult>>;
    public listPages(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageHeaderPagedResult>>;
    public listPages(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (isTemplate !== undefined && isTemplate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isTemplate, 'isTemplate');
        }
        if (isProduct !== undefined && isProduct !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isProduct, 'isProduct');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageHeaderPagedResult>(`${this.configuration.basePath}/api/protected/v1/page/pages`,
            pagePaginationModel,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param categoryId 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listPagesByCategory(categoryId: string, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageHeaderPagedResult>;
    public listPagesByCategory(categoryId: string, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageHeaderPagedResult>>;
    public listPagesByCategory(categoryId: string, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageHeaderPagedResult>>;
    public listPagesByCategory(categoryId: string, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling listPagesByCategory.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageHeaderPagedResult>(`${this.configuration.basePath}/api/protected/v1/page/by/category/${encodeURIComponent(String(categoryId))}`,
            pagePaginationModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param pagePaginationModel 
     * @param names 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listPagesByNames(pagePaginationModel?: PagePaginationModel, names?: Array<string>, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageHeaderPagedResult>;
    public listPagesByNames(pagePaginationModel?: PagePaginationModel, names?: Array<string>, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageHeaderPagedResult>>;
    public listPagesByNames(pagePaginationModel?: PagePaginationModel, names?: Array<string>, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageHeaderPagedResult>>;
    public listPagesByNames(pagePaginationModel?: PagePaginationModel, names?: Array<string>, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (names) {
            names.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'names');
            })
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageHeaderPagedResult>(`${this.configuration.basePath}/api/protected/v1/page/by/names`,
            pagePaginationModel,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param pagePaginationModel 
     * @param isTemplate 
     * @param isProduct 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listPagesByTags(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageHeaderPagedResult>;
    public listPagesByTags(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageHeaderPagedResult>>;
    public listPagesByTags(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageHeaderPagedResult>>;
    public listPagesByTags(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (isTemplate !== undefined && isTemplate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isTemplate, 'isTemplate');
        }
        if (isProduct !== undefined && isProduct !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isProduct, 'isProduct');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageHeaderPagedResult>(`${this.configuration.basePath}/api/protected/v1/page/tags`,
            pagePaginationModel,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param pagePaginationModel 
     * @param isTemplate 
     * @param isProduct 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listPagesDashboard(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageHeaderPagedResult>;
    public listPagesDashboard(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageHeaderPagedResult>>;
    public listPagesDashboard(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageHeaderPagedResult>>;
    public listPagesDashboard(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (isTemplate !== undefined && isTemplate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isTemplate, 'isTemplate');
        }
        if (isProduct !== undefined && isProduct !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isProduct, 'isProduct');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageHeaderPagedResult>(`${this.configuration.basePath}/api/protected/v1/page/dashboard`,
            pagePaginationModel,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param templateId 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listPagesDerivedByTemplate(templateId: string, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageHeaderPagedResult>;
    public listPagesDerivedByTemplate(templateId: string, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageHeaderPagedResult>>;
    public listPagesDerivedByTemplate(templateId: string, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageHeaderPagedResult>>;
    public listPagesDerivedByTemplate(templateId: string, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling listPagesDerivedByTemplate.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageHeaderPagedResult>(`${this.configuration.basePath}/api/protected/v1/page/derived/by/template/${encodeURIComponent(String(templateId))}`,
            pagePaginationModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param pagePaginationModel 
     * @param pageIds 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listSelectedPagesByIds(pagePaginationModel?: PagePaginationModel, pageIds?: Array<string>, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageHeaderPagedResult>;
    public listSelectedPagesByIds(pagePaginationModel?: PagePaginationModel, pageIds?: Array<string>, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageHeaderPagedResult>>;
    public listSelectedPagesByIds(pagePaginationModel?: PagePaginationModel, pageIds?: Array<string>, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageHeaderPagedResult>>;
    public listSelectedPagesByIds(pagePaginationModel?: PagePaginationModel, pageIds?: Array<string>, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (pageIds) {
            pageIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'pageIds');
            })
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PageHeaderPagedResult>(`${this.configuration.basePath}/api/protected/v1/page/selected/pages`,
            pagePaginationModel,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param relatePageToDomainModel 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public relatePageToDomain(relatePageToDomainModel?: RelatePageToDomainModel, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public relatePageToDomain(relatePageToDomainModel?: RelatePageToDomainModel, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public relatePageToDomain(relatePageToDomainModel?: RelatePageToDomainModel, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public relatePageToDomain(relatePageToDomainModel?: RelatePageToDomainModel, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/protected/v1/page/relate/to/domain`,
            relatePageToDomainModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param relatePageToPageModel 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public relatePageToPage(relatePageToPageModel?: RelatePageToPageModel, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public relatePageToPage(relatePageToPageModel?: RelatePageToPageModel, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public relatePageToPage(relatePageToPageModel?: RelatePageToPageModel, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public relatePageToPage(relatePageToPageModel?: RelatePageToPageModel, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/protected/v1/page/relate/to/page`,
            relatePageToPageModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param removePageFromDomainModel 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removePageFromDomain(removePageFromDomainModel?: RemovePageFromDomainModel, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public removePageFromDomain(removePageFromDomainModel?: RemovePageFromDomainModel, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public removePageFromDomain(removePageFromDomainModel?: RemovePageFromDomainModel, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public removePageFromDomain(removePageFromDomainModel?: RemovePageFromDomainModel, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/protected/v1/page/remove/from/domain`,
            removePageFromDomainModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param removePageFromPageModel 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removePageFromPage(removePageFromPageModel?: RemovePageFromPageModel, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public removePageFromPage(removePageFromPageModel?: RemovePageFromPageModel, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public removePageFromPage(removePageFromPageModel?: RemovePageFromPageModel, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public removePageFromPage(removePageFromPageModel?: RemovePageFromPageModel, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/protected/v1/page/remove/from/page`,
            removePageFromPageModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param pageDetailsProductDetails 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePage(id: string, pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public updatePage(id: string, pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public updatePage(id: string, pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public updatePage(id: string, pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updatePage.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/protected/v1/page/${encodeURIComponent(String(id))}`,
            pageDetailsProductDetails,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * @param id 
     * @param pageDetailsAndRelations 
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePageAndRelations(id: string, pageDetailsAndRelations?: PageDetailsAndRelations, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public updatePageAndRelations(id: string, pageDetailsAndRelations?: PageDetailsAndRelations, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public updatePageAndRelations(id: string, pageDetailsAndRelations?: PageDetailsAndRelations, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public updatePageAndRelations(id: string, pageDetailsAndRelations?: PageDetailsAndRelations, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updatePageAndRelations.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/protected/v1/page/${encodeURIComponent(String(id))}/relations`,
            pageDetailsAndRelations,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update page category (to add pass categoryId, to remove do not pass categoryId)
     * @param id Page Id
     * @param categoryId Category Id
     * @param xSelectedDomain Comma-delimited domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePageCategory(id: string, categoryId: string, xSelectedDomain?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public updatePageCategory(id: string, categoryId: string, xSelectedDomain?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public updatePageCategory(id: string, categoryId: string, xSelectedDomain?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public updatePageCategory(id: string, categoryId: string, xSelectedDomain?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updatePageCategory.');
        }
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling updatePageCategory.');
        }

        let headers = this.defaultHeaders;
        if (xSelectedDomain !== undefined && xSelectedDomain !== null) {
            headers = headers.set('x-selected-domain', String(xSelectedDomain));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/protected/v1/page/${encodeURIComponent(String(id))}/category/${encodeURIComponent(String(categoryId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
