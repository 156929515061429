import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FileMetadata,
  FilesServiceInterface,
  UpdateFileMetadata
} from '@ekon-client/dkm-api';
import { FILE_ACTIONS } from '@ekon-client/dkm-events';
import { EkonDialogsService, getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import { EkonMessageService, EkonMessageTypes } from '@ekon-client/shared/common/ekon-utils';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { FileEditDialogComponent } from '../file-manage/components/file-edit-dialog/file-edit-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {

  constructor(
    @Inject(FILE_ACTIONS) private fileActions: FilesServiceInterface,
    private dialog: MatDialog,
    private messageService: EkonMessageService,
    private dialogsService: EkonDialogsService
  ) {
  }

  deleteFile(file: FileMetadata): Observable<unknown> {
    const dialogConfig = getModalDefaultConfig();
    dialogConfig.data = file;

    return this.dialogsService.confirm(
      null,
      'Delete file',
      `Are you sure you want to delete the file <b>"${file.fileName}"</b>?`
    )
      .pipe(
        filter((result: boolean) => !!result),
        switchMap(() =>
          // todo: check why delete file API returns Blob
          this.fileActions.deleteFile(file.id)
        ),
        tap({
          next: () =>
            this.messageService.show(
              'File was deleted successfully',
              EkonMessageTypes.SUCCESS
            ),
          error: () =>
            this.messageService.show(
              'Error occurred while deleting file',
              EkonMessageTypes.ERROR
            )
        })
      );
  }

  editFileMeta(file: FileMetadata): Observable<unknown> {
    const dialogConfig = getModalDefaultConfig();
    dialogConfig.data = file;

    return this.dialog
      .open(FileEditDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        filter((result: UpdateFileMetadata | boolean) => !!result),
        switchMap((fileData: UpdateFileMetadata) =>
          this.fileActions.updateFileMetadata(fileData.id, fileData)
        ),
        tap({
          next: () =>
            this.messageService.show(
              'File meta data was updated successfully',
              EkonMessageTypes.SUCCESS
            ),
          error: () =>
            this.messageService.show(
              'Error occurred while updating file meta data',
              EkonMessageTypes.ERROR
            )
        })
      );
  }

}
