/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CreateGeoLayer } from '../model/models';
import { GeoLayerFeatureModel } from '../model/models';
import { GeoLayerModel } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { ProblemDetails } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface GeoLayerServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createGeoLayer 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createGeoLayer(createGeoLayer?: CreateGeoLayer, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<GeoLayerModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteGeoLayer(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findGeoLayerById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<GeoLayerModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listGeoLayersInDomain(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<GeoLayerModel>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param geoLayerModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateGeoLayer(id: string, geoLayerModel?: GeoLayerModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param geoLayerFeatureModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateGeoLayerFeature(id: string, geoLayerFeatureModel?: GeoLayerFeatureModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

}
