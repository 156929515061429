<ekon-dialog-wrapper>
  <div mat-dialog-title class="h2 mb-24"
       fxLayoutAlign="start center">{{globalConfig.domainTitlePlural | uppercase | translate | titlecase}}</div>
  <mat-dialog-content fxFlex="1 0 auto">
    <ekon-paginator [config]="paginatorConfig" #paginator="ekonPaginator">
      <mat-selection-list
        (selectionChange)="domainSelectionChanged($event.source.selectedOptions.selected, $event.source.options)"
      >
        <!-- todo: tracking breaks selection on pageSize change
              *ngFor="let domain of paginator.items$ | async; trackBy: trackById"-->
        <mat-list-option
          [value]="domain"
          [selected]="checkInitialSelection(domain.id) | async"
          *ngFor="let domain of paginator.items$ | async"
          class="custom-option"
        >
          <div fxLayout="row" fxLayoutAlign="start center" class="py-8">
            <dkm-core-ava-viewer
              [editDisabled]="true"
              [fileLink]="domain.thumbprintUrl"
              [cacheEnabled]="true"
              fxFlex="0 0 auto"
              class="mr-8"
            >
              {{ domain.name | ekonInitials }}
            </dkm-core-ava-viewer>
            <span>{{domain.name}}</span>
          </div>
          <mat-divider></mat-divider>
        </mat-list-option>
      </mat-selection-list>
    </ekon-paginator>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxFlex="0 0 auto">
    <button mat-button [mat-dialog-close]="false">Close</button>
  </mat-dialog-actions>
</ekon-dialog-wrapper>
