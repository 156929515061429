import { HttpClientModule } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { ApiModule, Configuration } from '@ekon-client/dkm-api';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';

import {
  BASKET_ACTIONS,
  BASKET_EVENTS, BLOCK_CONTENT_ACTIONS, BLOCK_CONTENT_EVENTS,
  BLOG_ACTIONS,
  BLOG_EVENTS,
  BreadcrumbResolverService,
  CALENDAR_ACTIONS,
  CALENDAR_EVENTS,
  CATEGORY_ACTIONS,
  CATEGORY_EVENTS,
  COMMENT_ACTIONS,
  COMMENT_EVENTS, DLT_ACTIONS, DLT_EVENTS,
  DOMAIN_ACTIONS,
  DOMAIN_EVENTS,
  ELEMENT_TEMPLATES_ACTIONS,
  ELEMENT_TEMPLATES_EVENTS,
  FAQ_ACTIONS,
  FAQ_EVENTS,
  FEEDBACK_ACTIONS,
  FEEDBACK_EVENTS,
  FILE_ACTIONS,
  FILE_EVENTS,
  GEO_ACTIONS,
  GEO_EVENTS,
  INVITATION_ACTIONS,
  INVITATION_EVENTS,
  KBESPECIFICATION_ACTIONS,
  KBESPECIFICATION_EVENTS,
  LANDING_PAGE_ACTIONS,
  LANDING_PAGE_EVENTS,
  LEGAL_ENTITY_ACTIONS,
  LEGAL_ENTITY_EVENTS,
  NOTE_ACTIONS,
  NOTE_EVENTS, NOTIFICATION_TEMPLATE_ACTIONS, NOTIFICATION_TEMPLATE_EVENTS,
  PAGE_ACTIONS,
  PAGE_EVENTS,
  PRODUCT_ACTIONS,
  PRODUCT_EVENTS,
  RATE_ACTIONS,
  RATE_EVENTS, REPORT_CONTENT_ACTIONS, REPORT_CONTENT_EVENTS,
  RSS_ACTIONS,
  RSS_EVENTS, STATISTICS_ACTIONS, STATISTICS_EVENTS,
  TAG_ACTIONS,
  TAG_EVENTS, USER_POSTS_ACTIONS, USER_POSTS_EVENTS,
  USERPROFILE_ACTIONS,
  USERPROFILE_EVENTS
} from './services';
import { BasketEventsService } from './services/BasketEventsService';
import { BlockContentEventsService } from './services/BlockContentEventsService';
import { BlogEventsService } from './services/BlogEventsService';
import { CalendarEventsService } from './services/CalendarEventsService';
import { CategoryEventsService } from './services/CategoryEventsService';
import { CommentEventsService } from './services/CommentEventsService';
import { DLTEventsService } from './services/DLTEventsService';
import { DomainEventsService } from './services/DomainEventsService';
import { ElementTemplatesEventsService } from './services/ElementTemplatesEventsService';
import { FaqEventsService } from './services/FaqEventsService';
import { FeedbackEventsService } from './services/FeedbackEventsService';
import { FileEventsService } from './services/FileEventsService';
import { GeoEventsService } from './services/GeoEventsService';
import { InvitationEventsService } from './services/InvitationEventsService';
import { KbeSpecificationEventsService } from './services/KbeSpecificationEventsService';
import { LandingPageEventsService } from './services/LandingPageEventsService';
import { LegalEntityEventsService } from './services/LegalEntityEventsService';
import { NoteEventsService } from './services/NoteEventsService';
import { NotificationTemplateEventsService } from './services/NotificationTemplateEventsService';
import { PageEventsService } from './services/PageEventsService';
import { ProductEventsService } from './services/ProductEventsService';
import { RateEventsService } from './services/RateEventsService';
import { ReportContentEventsService } from './services/ReportContentEventsService';
import { RssEventsService } from './services/RssEventsService';
import { StatisticsEventsService } from './services/StatisticsEventsService';
import { TagEventsService } from './services/TagEventsService';
import { UserPostsEventsService } from './services/UserPostsEventsService';
import { UserProfileEventsService } from './services/UserProfileEventsService';

@NgModule({
  imports: [HttpClientModule, ApiModule],
  declarations: [],
  exports: [],
  providers: [
    {
      provide: Configuration,
      useFactory: (configService: GlobalConfigurationService) =>
        new Configuration({
          basePath: configService.environment.API_PATH
        }),
      deps: [GlobalConfigurationService]
    },
    BreadcrumbResolverService,

    DomainEventsService,
    { provide: DOMAIN_ACTIONS, useExisting: DomainEventsService },
    { provide: DOMAIN_EVENTS, useExisting: DomainEventsService },

    ElementTemplatesEventsService,
    {
      provide: ELEMENT_TEMPLATES_ACTIONS,
      useExisting: ElementTemplatesEventsService
    },
    {
      provide: ELEMENT_TEMPLATES_EVENTS,
      useExisting: ElementTemplatesEventsService
    },

    FaqEventsService,
    { provide: FAQ_ACTIONS, useExisting: FaqEventsService },
    { provide: FAQ_EVENTS, useExisting: FaqEventsService },

    RssEventsService,
    { provide: RSS_ACTIONS, useExisting: RssEventsService },
    { provide: RSS_EVENTS, useExisting: RssEventsService },

    TagEventsService,
    { provide: TAG_ACTIONS, useExisting: TagEventsService },
    { provide: TAG_EVENTS, useExisting: TagEventsService },

    CalendarEventsService,
    { provide: CALENDAR_ACTIONS, useExisting: CalendarEventsService },
    { provide: CALENDAR_EVENTS, useExisting: CalendarEventsService },

    PageEventsService,
    { provide: PAGE_ACTIONS, useExisting: PageEventsService },
    { provide: PAGE_EVENTS, useExisting: PageEventsService },

    BlogEventsService,
    { provide: BLOG_ACTIONS, useExisting: BlogEventsService },
    { provide: BLOG_EVENTS, useExisting: BlogEventsService },

    CommentEventsService,
    { provide: COMMENT_ACTIONS, useExisting: CommentEventsService },
    { provide: COMMENT_EVENTS, useExisting: CommentEventsService },

    UserProfileEventsService,
    { provide: USERPROFILE_ACTIONS, useExisting: UserProfileEventsService },
    { provide: USERPROFILE_EVENTS, useExisting: UserProfileEventsService },

    FileEventsService,
    { provide: FILE_ACTIONS, useExisting: FileEventsService },
    { provide: FILE_EVENTS, useExisting: FileEventsService },

    NoteEventsService,
    { provide: NOTE_ACTIONS, useExisting: NoteEventsService },
    { provide: NOTE_EVENTS, useExisting: NoteEventsService },

    KbeSpecificationEventsService,
    {
      provide: KBESPECIFICATION_ACTIONS,
      useExisting: KbeSpecificationEventsService
    },
    {
      provide: KBESPECIFICATION_EVENTS,
      useExisting: KbeSpecificationEventsService
    },

    RateEventsService,
    { provide: RATE_ACTIONS, useExisting: RateEventsService },
    { provide: RATE_EVENTS, useExisting: RateEventsService },

    CategoryEventsService,
    { provide: CATEGORY_ACTIONS, useExisting: CategoryEventsService },
    { provide: CATEGORY_EVENTS, useExisting: CategoryEventsService },

    BasketEventsService,
    { provide: BASKET_ACTIONS, useExisting: BasketEventsService },
    { provide: BASKET_EVENTS, useExisting: BasketEventsService },

    ProductEventsService,
    { provide: PRODUCT_ACTIONS, useExisting: ProductEventsService },
    { provide: PRODUCT_EVENTS, useExisting: ProductEventsService },

    LegalEntityEventsService,
    { provide: LEGAL_ENTITY_ACTIONS, useExisting: LegalEntityEventsService },
    { provide: LEGAL_ENTITY_EVENTS, useExisting: LegalEntityEventsService },

    FeedbackEventsService,
    { provide: FEEDBACK_ACTIONS, useExisting: FeedbackEventsService },
    { provide: FEEDBACK_EVENTS, useExisting: FeedbackEventsService },

    GeoEventsService,
    { provide: GEO_ACTIONS, useExisting: GeoEventsService },
    { provide: GEO_EVENTS, useExisting: GeoEventsService },

    InvitationEventsService,
    { provide: INVITATION_ACTIONS, useExisting: InvitationEventsService },
    { provide: INVITATION_EVENTS, useExisting: InvitationEventsService },

    DLTEventsService,
    { provide: DLT_ACTIONS, useExisting: DLTEventsService },
    { provide: DLT_EVENTS, useExisting: DLTEventsService },

    LandingPageEventsService,
    { provide: LANDING_PAGE_ACTIONS, useExisting: LandingPageEventsService },
    { provide: LANDING_PAGE_EVENTS, useExisting: LandingPageEventsService },

    UserPostsEventsService,
    { provide: USER_POSTS_ACTIONS, useExisting: UserPostsEventsService },
    { provide: USER_POSTS_EVENTS, useExisting: UserPostsEventsService },

    StatisticsEventsService,
    { provide: STATISTICS_ACTIONS, useExisting: StatisticsEventsService },
    { provide: STATISTICS_EVENTS, useExisting: StatisticsEventsService },

    NotificationTemplateEventsService,
    { provide: NOTIFICATION_TEMPLATE_ACTIONS, useExisting: NotificationTemplateEventsService },
    { provide: NOTIFICATION_TEMPLATE_EVENTS, useExisting: NotificationTemplateEventsService },

    BlockContentEventsService,
    { provide: BLOCK_CONTENT_ACTIONS, useExisting: BlockContentEventsService },
    { provide: BLOCK_CONTENT_EVENTS, useExisting: BlockContentEventsService },

    ReportContentEventsService,
    { provide: REPORT_CONTENT_ACTIONS, useExisting: ReportContentEventsService },
    { provide: REPORT_CONTENT_EVENTS, useExisting: ReportContentEventsService }
  ]
})
export class DkmEventsModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<DkmEventsModule> {
    return {
      ngModule: DkmEventsModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: DkmEventsModule,
    @Optional() http: HttpClientModule
  ) {
    if(parentModule) {
      throw new Error(
        'DkmEventsModule is already loaded. Import in your base AppModule only.'
      );
    }
    if(!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
