import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BasketDetails } from '@ekon-client/dkm-api';

@Component({
  selector: 'dkm-basket-add-dialog',
  templateUrl: './basket-add-edit-dialog.component.html',
  styles: [],
})
export class BasketAddEditDialogComponent {
  form: FormGroup;

  constructor(
    private dialog: MatDialogRef<BasketAddEditDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: BasketDetails
  ) {
    this.form = fb.group({
      name: [data && data.name, Validators.required],
      shortDescription: [data && data.shortDescription],
    });
  }

  createBasket(): void {
    this.form.valid && this.dialog.close(this.form.getRawValue());
  }
}
