// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { GlobalConfiguration } from '@ekon-client/shared/cloud-config';

export const environment: GlobalConfiguration = {
  production: false,
  API_PATH: 'https://dkm.ekonm.com/gw',
  //API_PATH: 'https://localhost:40001',
  LOGO_URI: 'assets/images/logos/ekon_logo_trasparent.png', /*place4u_logo.png',*/
  LANGUAGES: {
    EN: { key: 'en', label: 'English', flag: 'uk' },
    HE: { key: 'he', direction: 'rtl', label: 'עברית', flag: 'il' },
    RU: { key: 'ru', label: 'Russian', flag: 'ru' },
    HU: { key: 'hu', label: 'Russian', flag: 'hu' },
    DE: { key: 'de', label: 'German', flag: 'de' }
  },
  DEFAULT_LANGUAGE: 'en',
  ENABLE_WG_CHATBOT: false,
  AUTH_SETTINGS: {
    // AUTHORITY: 'https://localhost:44318',
    AUTHORITY: 'https://eis.ekonm.com/auth/realms/ekon',
    CLIENT_ID: 'dkm_angular_client',
    RESPONSE_TYPE: 'code',
    SCOPE: 'openid dkm4u_api email profile',
    AUTOMATIC_SILENT_RENEW: true,
    FILTER_PROTOCOL_CLAIMS: true,
    REDIRECT_URI: 'http://localhost:4200'
  },
  FOOTER: {
    NAME:
      'Copyright © 2008 - 2022 EKON Modeling Software Systems Ltd. All rights reserved.',
    SITE_URI: 'https://ekonm.com'
  },
  ENABLE_LEGAL_ENTITY: true,
  DOMAIN_TITLE_SINGULAR: 'space',
  DOMAIN_TITLE_PLURAL: 'spaces',
  ENABLE_FEED: true,
  ENABLE_MAP: true,
  ENABLE_KBE: false,
  ENABLE_RSS: true,
  USE_ALT_HOME_DASHBOARD: true,
  DASHBOARD_GREETING: 'Welcome to ekonSPACE Platform',
  DASHBOARD_GREETING_BACKGROUND: 'linear-gradient(0.25turn, #004DA4, #00899B)',

  COLOR_THEMES: {
    'theme_p4u_light': false,
    'theme_p4u_dark': false,
  },

  ENABLE_TAG_CLOUD: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
