<div fxLayout="row" fxLayoutAlign="space-between stretch" class="viewer-container">
  <button
    matRipple
    (click)="prevFile()"
    class="nav-btn"
  >
    <ekon-icon icon="angle-left" size="lg"></ekon-icon>
  </button>

  <div fxFlex="1 1 100%" class="specific-view" fxLayoutAlign="center center">
    <ng-container *ngIf="currentItem$ | async as currentItem">
      <ekon-file-viewer
        *ngIf="currentItem && FileTypesWeCanView.canViewFileExt((currentItem.file)?.contentType)"
        [fileData]="currentItem"
        [hideCredentials]="hideCredentials"
      ></ekon-file-viewer>
    </ng-container>
  </div>

  <button
    matRipple
    (click)="nextFile()"
    class="nav-btn"
  >
    <ekon-icon icon="angle-right" size="lg"></ekon-icon>
  </button>
</div>
