import { Inject, Injectable } from '@angular/core';
import { BreakPoint, BREAKPOINTS } from '@angular/flex-layout';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EkonMediaQueryService {

  get isMobile$(): Observable<boolean> {
    const mediaQuery = window.matchMedia(this.breakPoints.find(
      // q => q.alias==='lt-sm'
      q => q.alias === 'lt-md'
    )?.mediaQuery);

    return fromEvent(mediaQuery, 'change').pipe(
      startWith(mediaQuery),
      debounceTime(500),
      distinctUntilChanged(),
      map((list: MediaQueryList) => list.matches)
    );
  }

  constructor(
    @Inject(BREAKPOINTS) private breakPoints: BreakPoint[]
  ) { }

}
