import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EkonAuthService } from '@ekon-client/auth';
import { NoteDetails } from '@ekon-client/dkm-api';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { ekIconCompletion } from '@ekon-client/shared/common/ekon-icons';
import { MatColors } from '@ekon-layout-utils/mat-colors';
import { parseJSON } from 'date-fns';
import { get as _get } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'dkm-note-add-edit-dialog',
  templateUrl: './note-add-edit-dialog.component.html',
  styleUrls: ['./note-add-edit-dialog.component.scss'],
})
export class NoteAddEditDialogComponent implements OnInit {
  ekIconCompletion = ekIconCompletion;

  form: FormGroup;

  public isAdmin$: Observable<boolean>;

  presetColors$: Observable<string[]>;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NoteAddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NoteDetails,
    private authService: EkonAuthService,
    public globalConfig: GlobalConfigurationService
  ) {
    this.presetColors$ = globalConfig.presetColorsList$.pipe(
      map(v => v || MatColors.miniSet)
    );

    this.isAdmin$ = authService.user$.pipe(
      map(u => u.role === 'admin')
    );
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      value: [_get(this.data, 'value'), Validators.required],
      reminderDateTime: [parseJSON(_get(this.data, 'reminderDateTime', new Date()))],
      closeAfterDateTime: [parseJSON(_get(this.data, 'closeAfterDateTime', new Date()))],
      isClosed: [_get(this.data, 'isClosed')],
      isPlatformNote: [_get(this.data, 'isPlatformNote')],
      color: [_get(this.data, 'color', null)],
      externalLink: [_get(this.data, 'externalLink')],
      tags: [_get(this.data, 'tags')],
      ...(this.data && { id: [_get(this.data, 'id'), Validators.required] }),
    });
  }

  get formValue(): NoteDetails {
    return this.form.getRawValue();
  }

  validateAndSave(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.formValue);
    }
  }

  timeChanged($event: Event, controlName: string): void {
    if (($event.target as HTMLInputElement).value) {
      const [hours, minutes] = ($event.target as HTMLInputElement).value.split(':');
      const date = this.form.get(controlName).value;
      date.setHours(+hours, +minutes);
      this.form.get(controlName).setValue(date);
    }
  }
}
