import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';

export type RedirectOptions = string[] | true | { commands: string[], extras: NavigationExtras }

export interface ConfirmDeactivate {
  route: ActivatedRoute;

  shouldOmit?(): Observable<boolean>;
  shouldSave(): Observable<boolean>;
  applyChanges(): Observable<unknown>;
  redirectTo?(data?: unknown): RedirectOptions;
  cleanOut?(): void;
}
