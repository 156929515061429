import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { EkonProgressBarComponent } from './components/ekon-progress-bar/ekon-progress-bar.component';

@NgModule({
  declarations: [EkonProgressBarComponent],
  imports: [CommonModule, MatProgressBarModule],
  exports: [EkonProgressBarComponent],
})
export class EkonProgressBarModule {}
