import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CustomEntityTitleComponent } from './custom-entity-title/custom-entity-title.component';



@NgModule({
  declarations: [
    CustomEntityTitleComponent
  ],
    imports: [
        CommonModule,
        MatTooltipModule,
        TranslateModule
    ],
  exports: [
    CustomEntityTitleComponent
  ]
})
export class EkonCustomHeadersModule { }
