<!--<dkm-core-domains-bar class="search-n-filter"></dkm-core-domains-bar>-->

<div class="search-n-filter">
  <ekon-search-n-filter></ekon-search-n-filter>
</div>

<div class="main-nav">
  <a
    class="main-nav-item"
    routerLink="/dashboard"
    [routerLinkActive]="['active']"
    matRipple
  >
    <ekon-icon icon="home"></ekon-icon>
    <span>{{'HOME'| translate}}</span>
  </a>
  <a
    *ngIf="globalConfig.enableFeed && !globalConfig.useAltHomeDashboard"
    class="main-nav-item"
    routerLink="/feed"
    [routerLinkActive]="['active']"
    matRipple
    [matRippleDisabled]="true"
  >
    <ekon-icon icon="newspaper"></ekon-icon>
    <span>{{'FEED'| translate}}</span>
  </a>
  <a
    class="main-nav-item"
    routerLink="/domains"
    [routerLinkActive]="['active']"
    matRipple
  >
    <ekon-icon icon="stores"></ekon-icon>
    <span>{{globalConfig.domainTitlePlural | uppercase | translate}}</span>
  </a>
  <a
    *ngIf="globalConfig.useBasket"
    class="main-nav-item"
    routerLink="/products"
    [routerLinkActive]="['active']"
    matRipple
  >
    <ekon-icon icon="box"></ekon-icon>
    <span>{{'CATALOG'| translate}}</span>
  </a>
  <a
    class="main-nav-item"
    routerLink="/pages"
    [routerLinkActive]="['active']"
    matRipple
  >
    <ekon-icon icon="pages"></ekon-icon>
    <span>{{'PAGES_LABEL'| translate}}</span>
  </a>
  <a
    class="main-nav-item"
    routerLink="/blogs"
    [routerLinkActive]="['active']"
    matRipple
    *ngIf="globalConfig.enableBlogs"
  >
    <ekon-icon icon="blogs" [directionSensitive]="false"></ekon-icon>
    <span>{{'BLOGS'| translate}}</span>
  </a>
  <a
    class="main-nav-item"
    routerLink="/my-space"
    [routerLinkActive]="['active']"
    matRipple
  >
    <ekon-icon icon="house-user"></ekon-icon>
    <span>{{'MY_SPACE'| translate}}</span>
  </a>

  <mat-divider></mat-divider>

  <ekon-language-setter></ekon-language-setter>

  <mat-divider></mat-divider>

  <a
    class="main-nav-item"
    matRipple
    (click)="sidebarService.hide('mobileNav'); sidebarService.show('dashboard-time-bar')"
  >
    <ekon-icon icon="bars"></ekon-icon>
    <span>{{'UTILITIES_BAR'| translate}}</span>
  </a>
</div>
