import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ekIconGridList, ekIconInfiniteList, ekIconRedo,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { DkmFiltersModule } from '@ekon-client/shared/features/dkm-filters';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { PaginatorComponent } from './components/paginator/paginator.component';
import { TopListComponent } from './components/top-list/top-list.component';

@NgModule({
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatButtonModule,
    MatRippleModule,
    DkmFiltersModule,
    MatFormFieldModule,
    FlexModule,
    MatInputModule,
    MatTooltipModule,
    InfiniteScrollModule,
    EkonIconsModule,
    TranslateModule
  ],
  declarations: [PaginatorComponent, TopListComponent],
  exports: [PaginatorComponent, TopListComponent],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/common/ekon-pagination/',
      multi: true
    }
  ]
})
export class EkonPaginationModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconGridList,
      ekIconInfiniteList,
      ekIconRedo
    );
  }
}
