import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FeedbackAnswers,
  FeedbackDetails,
  FeedbackService,
  IssueType,
  QuestionAnswer
} from '@ekon-client/dkm-api';
import { AutocompleteService } from '@ekon-client/shared/features/dkm-autocompletion';
import { get as _get } from 'lodash-es';
import { Observable, of, Subject } from 'rxjs';


export interface QuestionAndOptions {
  q: string;
  options: FeedbackAnswers[];
}

@Component({
  selector: 'dkm-core-feedback-add-dialog',
  templateUrl: './feedback-add-edit-dialog.component.html',
  styleUrls: ['./feedback-add-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackAddEditDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;

  questionAnswerList: Array<QuestionAnswer> = [];
  // ContextType = Object.keys(FeedbackContextType);
  // contextTypeToRoute = new Map([
  //   ['pages', FeedbackContextType.Page],
  //   ['blogs', FeedbackContextType.Blogpost],
  //   ['kbe', FeedbackContextType.KBE],
  //   ['analytics', FeedbackContextType.Analytics],
  //   ['simulation', FeedbackContextType.Simulation],
  //   ['ontology', FeedbackContextType.Ontology],
  //   ['kbe-spec', FeedbackContextType.KBE],
  //   ['landing', FeedbackContextType.Platform],
  //   ['domains', FeedbackContextType.Domain],
  // ]);

  IssueType = IssueType;
  IssueTypeArr = Object.keys(IssueType);

  questionsInitial: QuestionAndOptions[] = [
    {
      q:
        'How likely are you to recommend this solution to a friend or colleague?',
      options: [
        FeedbackAnswers.ExtremelyLikely,
        FeedbackAnswers.Likely,
        FeedbackAnswers.NeitherLikelyNorUnlikely,
        FeedbackAnswers.Unlikely,
        FeedbackAnswers.ExtremelyUnlikely,
        FeedbackAnswers.DonnotKnow
      ]
    },
    {
      q: 'Overall, how easy was it to find the solution for your request?',
      options: [
        FeedbackAnswers.VeryEasy,
        FeedbackAnswers.Easy,
        FeedbackAnswers.Neither,
        FeedbackAnswers.Difficult,
        FeedbackAnswers.VeryDifficult
      ]
    }
  ];

  public feedbackQuestions: Observable<QuestionAndOptions[]> = of(
    this.questionsInitial
  );

  private _unsubscribeAll: Subject<void> = new Subject();

  selectedValue;

  constructor(
    private _feedbackService: FeedbackService,
    public location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private autocomleteService: AutocompleteService,
    private fb: FormBuilder,
    private dialog: MatDialogRef<FeedbackAddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FeedbackDetails
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      title: [_get(this.data, 'title'), Validators.required],
      // contextSubjectType: [
      //   _get(
      //     this.data,
      //     'contextSubjectType',
      //     this.contextTypeToRoute.get(this.router.url.split('/')[1])
      //   ),
      // ],
      feedbackType: [_get(this.data, 'feedbackType')],
      summary: [_get(this.data, 'summary')],

      questionAndAnswer: this.fb.array([
        this.fb.group({
          question: _get(
            this.data,
            'questionAndAnswer[0].question',
            this.questionsInitial[0].q
          ),
          answer: _get(this.data, 'questionAndAnswer[0].answer'),
          elaborate: _get(this.data, 'questionAndAnswer[0].elaborate')
        }),
        this.fb.group({
          question: _get(
            this.data,
            'questionAndAnswer[1].question',
            this.questionsInitial[0].q
          ),
          answer: _get(this.data, 'questionAndAnswer[1].answer'),
          elaborate: _get(this.data, 'questionAndAnswer[1].elaborate')
        })
      ]),

      contextLink: location.origin + this.router.url,
      tags: [_get(this.data, 'tags')],
      ...(this.data && { id: [_get(this.data, 'id'), Validators.required] })
    });
  }

  changeFeedbackType(e: MatSelectChange): void {
    console.log(e.value);
    this.selectedValue = e.value;
  }

  validateAndSubmit(): void {
    console.warn(this.form.getRawValue());
    if (this.form.valid) {
      this.dialog.close(this.form.getRawValue());
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
