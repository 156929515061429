<div
  class="backdrop"
  [ngClass]="{'active': panelActive | async}"
  (click)="togglePanel()"
></div>

<div
  class="panel-trigger"
  (click)="togglePanel()"
  matRipple
>
  <ekon-icon icon="filter"></ekon-icon>
</div>

<div
  class="panel-content"
  [ngClass]="{'active': panelActive | async}"
>
  <dkm-core-domains-bar></dkm-core-domains-bar>

  <!--<button
    mat-icon-button
    class="search-btn"
  >
    <ekon-icon icon="search"></ekon-icon>
  </button>-->

  <div
    class="panel-arrow"
    (click)="togglePanel()"
    matRipple
  >
    <ekon-icon icon="angle-right" size="xs"></ekon-icon>
  </div>
</div>

<div
  class="panel-badge"
  (click)="togglePanel()"
  *ngIf="(domainSelector.domainsSelected$ | async)?.length as count"
>{{count}}</div>
