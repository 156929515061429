import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges
} from '@angular/core';
import {
  arc,
  DefaultArcObject,
  interpolate,
  select
} from 'd3';
import ResizeObserver from 'resize-observer-polyfill';

import {
  SelectionSVG,
  SelectionSVGArc,
  SelectionSVGG,
  SelectionSVGText
} from '../../extras/SelectionTypes';


@Component({
  selector: 'ekon-meter-graph',
  template: ``,
  styleUrls: ['./meter-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterGraphComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() min: number;
  @Input() max: number;
  @Input() value: number;

  resizeObserver: ResizeObserver;
  private valueTextSize: number;
  private svg: SelectionSVG;
  private g: SelectionSVGG;
  private valueText: SelectionSVGText;
  private minText: SelectionSVGText;
  private maxText: SelectionSVGText;
  private smallTextSize: number;
  private smallTextXPos: number;
  private rangeArc: SelectionSVGArc;
  private valueArc: SelectionSVGArc;
  private innerRadius: number;
  private outerRadius: number;

  hostEl: Element;

  constructor(private hostElRef: ElementRef<Element>) {
    this.hostEl = this.hostElRef.nativeElement;
  }

  arcRange = arc()
    .startAngle(-(Math.PI / 2))
    .endAngle(Math.PI / 2);

  initValueText(selection: SelectionSVGText, v: number): SelectionSVGText {
    return selection
      .attr('text-anchor', 'middle')
      .attr('fill', '#9c9c9c')
      .text(v);
  }

  setValueTextDim(selection: SelectionSVGText): SelectionSVGText {
    return selection
      .attr('font-size', `${this.valueTextSize}px`)
      .attr('y', -(this.valueTextSize / 2));
  }

  initSmallText(selection: SelectionSVGText, v: number): SelectionSVGText {
    return selection
      .attr('text-anchor', 'middle')
      .attr('fill', '#9c9c9c')
      .text(v);
  }

  setSmallTextDim(selection: SelectionSVGText, sign: 1 | -1): SelectionSVGText {
    return selection
      .attr('font-size', `${this.smallTextSize}px`)
      .attr('x', sign * this.smallTextXPos)
      .attr('y', this.smallTextSize);
  }

  setNullArc(selection: SelectionSVGArc): SelectionSVGArc {
    return selection
      .attr('d', arc()
        .startAngle(0)
        .endAngle(0)
        .innerRadius(0)
        .outerRadius(0)
      );
  }

  arcTween(
    startAngle: number,
    endAngle: number
  ): (d: DefaultArcObject) => (t) => string {
    return (/*_d: DefaultArcObject*/) => {
      const i = interpolate(startAngle, endAngle);
      return (t) => {
        return arc()({
          startAngle,
          endAngle: i(t),
          innerRadius: this.innerRadius,
          outerRadius: this.outerRadius
        });
      };
    };
  }

  updateValueArc(start: number, end: number): void {
    this.setNullArc(this.valueArc)
      .transition()
      .duration(1000)
      .delay(500)
      .attrTween('d', this.arcTween(
        start,
        end
      ));
  }

  updateValueArcValue(): void {
    this.updateValueArc(
      -(Math.PI / 2),
      (this.value - this.min) * Math.PI / (this.max - this.min) - Math.PI / 2
    );
  }

  ngAfterViewInit(): void {

    this.innerRadius = this.hostEl.clientWidth / 2 - 50;
    this.outerRadius = this.hostEl.clientWidth / 2;

    // add svg container element
    this.svg = select(this.hostEl)
      .append('svg')
      .attr('width', this.hostEl.clientWidth)
      .attr('height', this.hostEl.clientHeight);

    this.g = this.svg.append('g')
      .attr(
        'transform',
        `translate(${this.hostEl.clientWidth / 2},${this.hostEl.clientHeight + 150})`
      );

    this.valueTextSize = 10 * this.hostEl.clientWidth / 100;


    // value text
    this.valueText = this.g.append('text');
    this.initValueText(this.valueText, this.value);
    this.setValueTextDim(this.valueText);

    // this.valueText
    //   .transition()
    //   .duration(2000)
    //   .textTween(function() {
    //     return interpolateRound(this.min, this.value);
    //   });

    this.smallTextSize = 7 * this.hostEl.clientWidth / 100;
    this.smallTextXPos = this.hostEl.clientWidth / 2 - 10 * this.hostEl.clientWidth / 100;

    // min text
    this.minText = this.g.append('text');
    this.initSmallText(this.minText, this.min);
    this.setSmallTextDim(this.minText, -1);

    // max text
    this.maxText = this.g.append('text');
    this.initSmallText(this.maxText, this.max);
    this.setSmallTextDim(this.maxText, 1);

    // range arc
    this.rangeArc = this.g
      .append('path')
      .attr('fill', '#9c9c9c')
      .datum({
        innerRadius: this.innerRadius,
        outerRadius: this.outerRadius
      });

    this.rangeArc
      .transition()
      .duration(500)
      .attrTween('d', (d: DefaultArcObject) => {
        const i = interpolate(d.innerRadius + 0.1, d.outerRadius);
        return (t) => {
          d.outerRadius = i(t);
          return this.arcRange(d);
        };
      });

    // value arc
    this.valueArc = this.g
      .append('path')
      .attr('fill', '#69b3a2')
      .datum({
        startAngle: -(Math.PI / 2),
        endAngle: this.value * Math.PI / this.max - Math.PI / 2
      });

    this.updateValueArcValue();


    let timeout;

    this.resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      timeout && clearTimeout(timeout);

      this.valueArc
        .interrupt();
      this.rangeArc
        .interrupt();

      timeout = setTimeout(() => {
        this.innerRadius = entries[0].contentRect.width / 2 - 20 * entries[0].contentRect.width / 100;
        this.outerRadius = entries[0].contentRect.width / 2;

        this.valueTextSize = 10 * entries[0].contentRect.width / 100;
        this.smallTextSize = 7 * entries[0].contentRect.width / 100;
        this.smallTextXPos = entries[0].contentRect.width / 2 - 10 * entries[0].contentRect.width / 100;


        this.svg
          .attr('width', entries[0].contentRect.width)
          .attr('height', entries[0].contentRect.height);

        this.g
          .attr(
            'transform',
            `translate(${entries[0].contentRect.width / 2},${entries[0].contentRect.height - this.smallTextSize * 1.1})`
          );

        // range arc
        this.setNullArc(this.rangeArc)
          .datum({
            innerRadius: this.innerRadius,
            outerRadius: this.outerRadius
          })
          .transition()
          .duration(500)
          .attrTween('d', (d: DefaultArcObject) => {
            const i = interpolate(d.innerRadius + 0.1, d.outerRadius);
            return (t) => {
              d.outerRadius = i(t);
              return this.arcRange(d);
            };
          });

        // value arc
        this.updateValueArcValue();


        this.setValueTextDim(this.valueText);
        this.setSmallTextDim(this.minText, -1);
        this.setSmallTextDim(this.maxText, 1);
      }, 300);

    });

    this.resizeObserver.observe(this.hostEl);


  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.hostEl);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && !changes.value.firstChange) {
      this.updateValueArcValue();

      this.initValueText(this.valueText, this.value);
    }
    if (changes.min && !changes.min.firstChange) {
      this.updateValueArcValue();

      this.initSmallText(this.minText, this.min);
    }
    if (changes.max && !changes.max.firstChange) {
      this.updateValueArcValue();

      this.initSmallText(this.maxText, this.max);
    }
  }


}
