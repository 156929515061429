import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DomainServiceInterface } from '@ekon-client/dkm-api';
import { BreadcrumbTitleResolver } from '@ekon-client/shared/common/ekon-breadcrumbs';
import { map } from 'rxjs/operators';

import { DOMAIN_ACTIONS } from '../DomainEventsServiceInterface';

export const DomainBreadcrumbResolver: BreadcrumbTitleResolver = (route: ActivatedRouteSnapshot, inj: Injector) => {
  return (inj.get(DOMAIN_ACTIONS) as DomainServiceInterface).findDomainById(route.params.domainId)
    .pipe(
      map(p => p.name)
    );
}
