import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const COMMENT_ACTIONS = new InjectionToken('Comment model actions');
export const COMMENT_EVENTS = new InjectionToken('Comment model events');

export interface CommentEventsServiceInterface {
  commentDeleted: Observable<string>;
  commentUpdated: Observable<string>;
}
