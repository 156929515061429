<mat-toolbar>
  <mat-toolbar-row class="install-message">
    <ekon-client-pwa-install></ekon-client-pwa-install>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <div class="mat-body-1 footer-copyright">
      <a
        href="{{this.footerSiteUri}}"
        target="_blank"
      >
        {{this.footerName}}
      </a>
    </div>

    <div class="footer-menu">
      <ng-container *ngIf="user | async">
        <a
          mat-button
          routerLink="/faq"
          [queryParams]="{ mode: 'Platform' }"
        >
          <span>{{'FAQ' | translate}}</span>
        </a>
        <span>&bull;</span>
        <a
          mat-button
          (click)="openFeedBackDialog()"
        >
          <span>{{'SEND_FEEDBACK' | translate}}</span>
        </a>
        <span>&bull;</span>
      </ng-container>
      <a mat-button routerLink="/terms-of-use">{{'TERM_OF_USE' | translate}}</a>
      <span>&bull;</span>
      <a mat-button routerLink="/privacy-policy">{{'PRIVACY_POLICY'| translate}}</a>
      <span>&bull;</span>
      <a mat-button routerLink="/code-of-conduct">{{'CODE_OF_CONDUCT' | translate}}</a>

    </div>

    <div class="footer-menu-dropdown">
      <button
        mat-icon-button
        [matMenuTriggerFor]="footerMenu"
      >
        <ekon-icon [icon]="ekIconBars" size="lg"></ekon-icon>
      </button>

      <mat-menu #footerMenu="matMenu">
        <ng-template matMenuContent>
          <ng-container *ngIf="user | async">
            <button
              mat-menu-item
              routerLink="/faq"
              [queryParams]="{ mode: 'Platform' }"
            >
              <span class="ml-8">{{'FAQ' | translate}}</span>
            </button>
            <button
              mat-menu-item
              (click)="openFeedBackDialog()"
            >
              <span class="ml-8">{{'SEND_FEEDBACK' | translate}}</span>
            </button>
            <button
              mat-menu-item
              routerLink="/code-of-conduct"
            >{{'CODE_OF_CONDUCT' | translate}}</button>
          </ng-container>

          <button
            mat-menu-item
            routerLink="/terms-of-use"
          >{{'TERM_OF_USE' | translate}}</button>
          <button
            mat-menu-item
            routerLink="/privacy-policy"
          >{{'PRIVACY_POLICY' | translate}}</button>
        </ng-template>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
