import { Component, OnDestroy } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { EkonAuthService } from '@ekon-client/auth';
import { UserProfile } from '@ekon-client/dkm-api';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { PwaUpdateService } from '@ekon-client/shared/common/ekon-pwa';
import { EkonMediaQueryService,EkonNotifierService } from '@ekon-client/shared/common/ekon-utils';
import { PermissionsService } from '@ekon-client/shared/features/dkm-permissions';
import { UserProfileService } from '@ekon-client/shared/features/dkm-user-profile';
import { EkonLayoutConfigService } from '@ekon-layout-utils/services';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { SidebarService } from '../../../services/sidebar.service';


@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnDestroy {
  user: Observable<UserProfile>;

  useBasket: boolean;
  enableLegalEntity: boolean;

  isMobile$: Observable<boolean>;
  private _unsubscribeAll: Subject<void> = new Subject();

  constructor(
    public userProfile: UserProfileService,
    private router: Router,
    private sidebarService: SidebarService,
    public globalConfig: GlobalConfigurationService,
    public permissions: PermissionsService,
    private media: EkonMediaQueryService,
    public authService: EkonAuthService,
    private ekonLayoutConfigService: EkonLayoutConfigService,
    public notifier: EkonNotifierService,
    public pwa: PwaUpdateService
  ) {

    this.user = this.userProfile.currentUser$;

    this.useBasket = this.globalConfig.environment.USE_BASKET || false;
    this.enableLegalEntity = this.globalConfig.environment.ENABLE_LEGAL_ENTITY || false;

    this.isMobile$ = media.isMobile$;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  backToLanding(): void {
    this.router.navigate(['/']);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  toggleSidebarStatus(key): void {
    this.sidebarService.toggle(key);
  }

  toggleSidebarOpen(key): void {
    this.sidebarService.show(key);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleTheme($event: MatSlideToggleChange) {
    this.ekonLayoutConfigService.setConfig({ colorTheme: $event.checked ? 'theme-light-blue-dark' : 'theme-blue-light'});
  }

  logout(): void {
    this.authService.logout();
  }

  applyUpdates() {
    this.pwa.activateUpdate().pipe(
      take(1)
    ).subscribe({
      next: () => document.location.reload()
    });
  }
}
