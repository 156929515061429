import { SafeResourceUrl } from '@angular/platform-browser';
import { FileMetadata, UserProfileHeader } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export interface FileView {
  fileMeta: FileMetadata;
  fileBodyURI$?: Observable<SafeResourceUrl>;
  fileAuthor?: UserProfileHeader;
  hideCredentials?: boolean;
}
