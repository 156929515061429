/* eslint-disable lodash/prefer-constant */
/* eslint-disable rxjs/no-ignored-subscription */
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { asyncScheduler, BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { filter, map, subscribeOn, switchMap, take, takeUntil, tap } from 'rxjs/operators'; // Explicit scheduling

import { HybridOAuthStorage } from './hybrid-oauth-storage.service';

// Interfaces for OIDC User and WGChatBot
export interface OidcUser {
  userId: string;
  sub: string;
  role: string;
}

export interface UserProfile {
  info: OidcUser;
}

export interface WGChatBot {
  __setDKMTokenForChatbot__: (token: string) => void;
}

@Injectable({
  providedIn: 'root',
})
export class EkonAuthService implements OnDestroy {
  private readonly _unsubscribeAll = new Subject<void>();
  private readonly _user$ = new BehaviorSubject<OidcUser | null>(null);
  public user$: Observable<OidcUser | null> = this._user$.asObservable();
  public readonly _isLoggedIn$ = new BehaviorSubject(false);
  public isLoggedIn$: Observable<boolean> = this._isLoggedIn$.asObservable();

  constructor(
    private oauthService: OAuthService,
    private globalConfig: GlobalConfigurationService,
    private storage: HybridOAuthStorage,
    private router: Router
  ) {
    this.setupSubscriptions();
  }

  private setupSubscriptions(): void {
    // Load configuration from globalConfig
    this.globalConfig.config.pipe(
      filter(cfg => !!cfg),
      tap(cfg => {
        this.oauthService.configure({
          issuer: cfg.AUTH_SETTINGS.AUTHORITY,
          clientId: cfg.AUTH_SETTINGS.CLIENT_ID,
          responseType: cfg.AUTH_SETTINGS.RESPONSE_TYPE,
          scope: cfg.AUTH_SETTINGS.SCOPE,
          redirectUri: window.location.origin + '/dashboard',  // Default redirect URI
          postLogoutRedirectUri: window.location.origin,
          showDebugInformation: cfg.AUTH_SETTINGS.DEBUG,
          clearHashAfterLogin: true,
          sessionChecksEnabled: true,
          useIdTokenHintForSilentRefresh: true,
          useSilentRefresh: true,
        });
      }),
      tap(() => from(this.oauthService.loadDiscoveryDocument()).pipe(take(1))),
      takeUntil(this._unsubscribeAll)
    ).subscribe(() => {
      this.oauthService.setupAutomaticSilentRefresh();
    });

    // Listen for OAuth events
    this.oauthService.events.pipe(
      tap(event => {
        if (event.type === 'token_received') {
          this.loadOidcUserProfile().subscribe(); // Update user profile on token reception
        }
        if (event.type === 'token_expires' || event.type === 'session_changed') {
          this._isLoggedIn$.next(false);
          this.refreshTokenAndUpdateUser().subscribe(); // Handle refresh token error by logging out
        }
      }),
      takeUntil(this._unsubscribeAll)
    ).subscribe((event: OAuthEvent) => {
      if (event.type === 'token_refresh_error') {
        this.logout(); // Handle refresh token error by logging out
      }
    });

    // from(this.oauthService.loadDiscoveryDocument())
    //   .pipe(take(1))
    //   .subscribe();

    // this.loadDocAndTryLogin().subscribe();
  }

  // Trigger login flow with optional dynamic redirect URI
  login(redirectUri?: string): void {
    if (redirectUri) {
      this.oauthService.initCodeFlow(redirectUri);
    } else {
      this.oauthService.initCodeFlow();
    }
  }

  logout(): void {
    from(this.oauthService.revokeTokenAndLogout(true)).pipe(
      take(1),
    ).subscribe(() => {
      this._user$.next(null); // Clear user data
      this.clearStorage(); // Clear session and local storage
      this._isLoggedIn$.next(false);
      this.clearSubscriptions();
      this.router.navigate(['/']); // Redirect to root after logout
    });
  }

  // Load the OIDC user profile and save it in the storage
  loadOidcUserProfile(): Observable<void> {
    return from(this.oauthService.loadUserProfile()).pipe(
      take(1),
      map((userProfile: Partial<UserProfile>) => {
        const oidcUser = { ...userProfile.info, userId: userProfile.info?.sub || '' } as OidcUser;
        this._user$.next(oidcUser); // Update the current user observable
        if (userProfile.info) {
          this._isLoggedIn$.next(true);
        }
        this.storage.setItem('user_profile', JSON.stringify(oidcUser)); // Store user profile in storage
      })
    );
  }

  // Handle token refresh and update user profile
  refreshTokenAndUpdateUser(): Observable<void> {
    return from(this.oauthService.refreshToken()).pipe(
      take(1),
      switchMap(() => this.loadOidcUserProfile()) // Refresh token and update user profile
    );
  }

  // Load the discovery document and try login, restore user profile if available
  loadDocAndTryLogin(): Observable<boolean> {
    return from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
      take(1),
      switchMap(loaded => {
        if (loaded && this.oauthService.hasValidAccessToken()) {
          return this.loadOidcUserProfile().pipe(map(() => true)); // Load user profile if successful
        } else if (loaded && this.oauthService.getRefreshToken()) {
          return this.refreshTokenAndUpdateUser().pipe(map(() => true)); // Refresh token and update user profile
        } else {
          return this.loadUserProfileFromStorageAndUpdate().pipe(map(() => false));
        }
      })
    );
  }

  // Retrieve the user profile from storage on initialization if available
  public loadUserProfileFromStorageAndUpdate(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const storedUserProfile = this.storage.getItem('user_profile');
      if (storedUserProfile && this.oauthService.hasValidAccessToken()) {
        const oidcUser = JSON.parse(storedUserProfile) as OidcUser;
        this._user$.next(oidcUser); // Set the user profile to BehaviorSubject if found
        observer.next(true); // Successfully loaded the user profile
        this._isLoggedIn$.next(true);
      } else {
        observer.next(false); // No user profile found
      }
      observer.complete();
    }).pipe(take(1));
  }

  // Consolidated method to clear both sessionStorage and localStorage
  clearStorage(): void {
    this.storage.clear();
  }

  private clearSubscriptions(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}




