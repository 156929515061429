import { Component, Input } from '@angular/core';
import { FileMetadata, UserProfileHeader } from '@ekon-client/dkm-api';
import { EkonIconDefinition } from '@ekon-client/shared/common/ekon-icons';

import { FileView } from '../../../../../extras';
import { getFileTypeIcon } from '../../../../../utils';

@Component({
  selector: 'dkm-file-view-unviewable',
  templateUrl: './file-view-unviewable.component.html',
  styleUrls: ['./file-view-unviewable.component.scss'],
})
export class FileViewUnviewableComponent implements FileView {
  @Input() fileAuthor: UserProfileHeader;
  @Input() fileMeta: FileMetadata;
  @Input() hideCredentials: boolean;

  getTypeIcon(fileName: string): EkonIconDefinition {
    return getFileTypeIcon(fileName);
  }
}
