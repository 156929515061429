import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  Provider
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable} from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { BaseAutocomplete } from '../../base/base-autocomplete';
import { AutocompleteService } from '../../services';

const CUSTOM_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EkonAutocompleteComponent),
  multi: true
};

@Component({
  selector: 'ekon-autocomplete',
  templateUrl: './ekon-autocomplete.component.html',
  styles: [`
    :host {
      display: inline-block;
      width: 100%;
    }
  `],
  providers: [CUSTOM_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EkonAutocompleteComponent extends BaseAutocomplete<string, string> implements AfterViewInit {

  @Input() requestApi: (query: string) => Observable<string[]>;

  constructor(
    private autocomleteService: AutocompleteService,
    protected _cdr: ChangeDetectorRef
  ) {
    super(_cdr, true);

    this.filtered = this.inputControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((query: string) => this.requestApi(query))
    );
  }

  ngAfterViewInit(): void {
    this.inputControl.valueChanges.subscribe({
      next: v => this.onChange(v)
    });
  }

  writeValue(obj: string): void {
    this.value = obj;
    this.inputControl.setValue(obj);
    this._cdr.detectChanges();
  }

  updateChanges(): void {
    this.onChange?.(this.value);
  }
}
