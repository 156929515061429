import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  CreateDomainModel, CreateFeedback,
  DkmDomain,
  DkmDomainPagedResult,
  DomainService,   DomainServiceInterface, DomainUserJoinWithdrawModel,
FeedbackDetails,
  LegalEntityJoinWithdrawModel, PagePaginationModel,
  UserDomainRelations, UserDomainRelationType, UserProfileHeader
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DomainEventsServiceInterface } from './DomainEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class DomainEventsService
  implements DomainServiceInterface, DomainEventsServiceInterface {
  get defaultHeaders(): HttpHeaders {
    return this.domainsService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.domainsService.defaultHeaders = val;
  }

  get configuration(): Configuration {
    return this.domainsService.configuration;
  }

  set configuration(val: Configuration) {
    this.domainsService.configuration = val;
  }

  constructor(
    private domainsService: DomainService,
    private progressBar: EkonProgressBarService
  ) {
  }

  private domainCreatedSubject: Subject<DkmDomain> = new Subject();

  get domainCreated(): Observable<DkmDomain> {
    return this.domainCreatedSubject.asObservable();
  }

  private domainDeletedSubject: Subject<string> = new Subject();

  get domainDeleted(): Observable<string> {
    return this.domainDeletedSubject.asObservable();
  }

  private domainUpdatedSubject: Subject<string> = new Subject();

  get domainUpdated(): Observable<string> {
    return this.domainUpdatedSubject.asObservable();
  }

  private domainMembershipConfirmedSubject: Subject<string> = new Subject();

  get domainMembershipConfirmed(): Observable<string> {
    return this.domainMembershipConfirmedSubject.asObservable();
  }

  private domainMembershipDeclinedSubject: Subject<string> = new Subject();

  get domainMembershipDeclined(): Observable<string> {
    return this.domainMembershipDeclinedSubject.asObservable();
  }

  private domainMembershipLeftSubject: Subject<string> = new Subject();

  get domainMembershipLeft(): Observable<string> {
    return this.domainMembershipLeftSubject.asObservable();
  }

  /**
   *  (Auth policies: dkm4u_api_auth)
   *
   * @param domainDetails
   */
  createDomain(domainDetails?: CreateDomainModel): Observable<DkmDomain> {
    return this.progressBar
      .apply(this.domainsService.createDomain(domainDetails))
      .pipe(tap((res: DkmDomain) => this.domainCreatedSubject.next(res)));
  }

  deleteDomain(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.domainsService.deleteDomain(id))
      .pipe(tap((res) => this.domainDeletedSubject.next(res)));
  }

  updateDomain(id: string, dkmDomain: DkmDomain): Observable<unknown> {
    return this.progressBar
      .apply(this.domainsService.updateDomain(id, dkmDomain))
      .pipe(tap(() => this.domainUpdatedSubject.next(id)));
  }

  findDomainById(id: string): Observable<DkmDomain> {
    return this.progressBar.apply(this.domainsService.findDomainById(id));
  }

  listDomains(pagination?: PagePaginationModel): Observable<DkmDomainPagedResult> {
    return this.progressBar.apply(this.domainsService.listDomains(pagination));
  }

  listUserAvailableDomains(
    pagination: PagePaginationModel
  ): Observable<DkmDomainPagedResult> {
    return this.progressBar.apply(
      this.domainsService.listUserAvailableDomains(pagination)
    );
  }

  listProtectedDomains(
    pagination: PagePaginationModel
  ): Observable<DkmDomainPagedResult> {
    return this.progressBar.apply(this.domainsService.listProtectedDomains(pagination));
  }

  userJoinWithrawDomain(id: string, joinData: DomainUserJoinWithdrawModel): Observable<unknown> {
    return this.progressBar
      .apply(this.domainsService.userJoinWithrawDomain(id, joinData))
      .pipe(
        tap(
          () =>
            joinData.membershipAction === 'Join' &&
            joinData.membershipLevel === 'Guest' &&
            this.domainMembershipConfirmedSubject.next(id)
        ),
        tap(
          () =>
            joinData.membershipAction === 'Leave' &&
            this.domainMembershipLeftSubject.next(id)
        )
      );
  }

  listUserRelationDomains(): Observable<UserDomainRelations[]> {
    return this.progressBar.apply(
      this.domainsService.listUserRelationDomains()
    );
  }

  legalEntityJoinWithrawDomain(id: string, legalEntityJoinWithdrawModel: LegalEntityJoinWithdrawModel): Observable<unknown> {
    return this.progressBar.apply(
      this.domainsService.legalEntityJoinWithrawDomain(id, legalEntityJoinWithdrawModel)
    );
  }

  listDomainsRelatedUsers(id: string): Observable<Array<UserProfileHeader>> {
    return this.progressBar.apply(this.domainsService.listDomainsRelatedUsers(id));
  }

  updateLegalEntityInDomain(domainId: string, newLegalEntityId: string): Observable<unknown> {
    return this.progressBar.apply(this.domainsService.updateLegalEntityInDomain(domainId, newLegalEntityId))
      .pipe(tap(() => this.domainUpdatedSubject.next(domainId)));
  }

  listByCreteria(
    pagination: PagePaginationModel,
    types?: UserDomainRelationType[],
    legalEntityId?: string
  ): Observable<DkmDomainPagedResult> {
    return this.progressBar.apply(this.domainsService.listByCreteria(
      pagination,
      types,
      legalEntityId
    ));
  }

  listDomainsByIds(
    ids: Array<string>
  ): Observable<DkmDomain[]> {
    return this.progressBar.apply(this.domainsService.listDomainsByIds(ids));
  }

  listByLegalEntity(
    legalEntityId: string,
    pagination: PagePaginationModel,
  ): Observable<DkmDomainPagedResult> {
    return this.progressBar.apply(this.domainsService.listByLegalEntity(
      legalEntityId,
      pagination,
    ));
  }

  createDomainFeedback(id: string, createFeedback: CreateFeedback): Observable<FeedbackDetails> {
    return this.progressBar.apply(
      this.domainsService.createDomainFeedback(id, createFeedback)
    );
  }

}
