/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CalendarItemHeader { 
    id?: string;
    name: string;
    description: string;
    start: Date;
    end?: Date | null;
    requrence?: string | null;
    /**
     * Set Primary Color of the event (text color)
     */
    primaryColor?: string | null;
    readonly kind: string;
}

