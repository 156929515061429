import { InjectionToken } from '@angular/core';
import { Category } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const CATEGORY_ACTIONS = new InjectionToken('Category model actions');
export const CATEGORY_EVENTS = new InjectionToken('Category model events');

export interface CategoryEventsServiceInterface {
  categoryCreated: Observable<Category>;
  categoryUpdated: Observable<string>;
  categoryDeleted: Observable<string>;
}
