import { Component, Input, SimpleChanges } from '@angular/core';
import { FileMetadata, UserProfileHeader } from '@ekon-client/dkm-api';
import { Track } from 'ngx-audio-player';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { FileView } from '../../../../../extras';
import { FileService } from '../../../../../services';

@Component({
  selector: 'dkm-file-view-audio',
  templateUrl: './file-view-audio.component.html',
  styleUrls: ['./file-view-audio.component.scss']
})
export class FileViewAudioComponent implements FileView {
  @Input() fileMeta: FileMetadata;
  @Input() fileAuthor: UserProfileHeader;
  @Input() hideCredentials: boolean;

  private fileUrl: string;

  private _fileId$: BehaviorSubject<string> = new BehaviorSubject(null);
  fileBodyURI$: Observable<Track[]> = this._fileId$.asObservable().pipe(
    switchMap((fileId: string) => fileId ? this.fileService.loadFileBlobURL(fileId) : of('')),
    tap((url: string) => (this.fileUrl = url)),
    map((url: string) => this.fileService.bypassSecurityBlobURL(url)),
    map((dataUrl: string) => [{
      title: this.fileMeta.name,
      link: dataUrl
    }])
  );

  constructor(
    private fileService: FileService
  ) {
  }

  ngOnDestroy(): void {
    this._fileId$.next(null);
    this._fileId$.complete();
    this.fileService.clearBlobURL(this.fileUrl);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.fileMeta
      && changes.fileMeta.currentValue !== changes.fileMeta.previousValue
    ) {
      changes.fileMeta.currentValue
        ? this._fileId$.next(this.fileMeta.id)
        : this._fileId$.next(null);
    }
  }
}
