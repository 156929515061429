import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BlogPostHeader,
  CalendarItemHeader,
  DkmDomainHeaderModel,
  LegalEntityHeaderModel,
  PageHeader,
  ProductHeader, UserHeaderModel
} from '@ekon-client/dkm-api';
import { getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import { EMPTY, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  BlogSelectorDialogComponent,
  DomainSelectorDialogComponent,
  LegalEntitySelectorDialogComponent,
  MeetupSelectorDialogComponent,
  PageSelectorDialogComponent,
} from '../components';
import { UserSelectorDialogComponent } from '../components/user-selector-dialog/user-selector-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class EntitySelectorService {

  constructor(private dialog: MatDialog) {}

  openEntitySelector<T>(component: ComponentType<unknown>, data?: unknown): Observable<T> {
    return this.dialog.open(
      component,
      {
        ...getModalDefaultConfig(),
        maxWidth: '80vw',
        data
      }
    ).afterClosed().pipe(
      switchMap(r => r ? of(r) : EMPTY)
    );
  }

  selectDomain(isOwnerMode?: boolean): Observable<DkmDomainHeaderModel> {
    return this.openEntitySelector(
      DomainSelectorDialogComponent,
      { isOwnerMode }
    );
  }

  selectPage(kind?: 'page' | 'product', isTemplate?: boolean, domain?: string): Observable<PageHeader | ProductHeader> {
    return of(true).pipe(
      switchMap(() => this.openEntitySelector(
        PageSelectorDialogComponent,
        { kind, isTemplate, domain }
      ) as Observable<PageHeader | ProductHeader>)
    );
  }

  selectBlog(): Observable<BlogPostHeader> {
    return this.openEntitySelector(BlogSelectorDialogComponent);
  }

  selectLegalEntity(): Observable<LegalEntityHeaderModel> {
    return this.openEntitySelector(LegalEntitySelectorDialogComponent);
  }

  selectMeetup(isPrivate?: boolean): Observable<CalendarItemHeader> {
    return this.openEntitySelector(
      MeetupSelectorDialogComponent,
      { isPrivate }
    );
  }

  selectUser(): Observable<UserHeaderModel> {
    return this.openEntitySelector(
      UserSelectorDialogComponent
    );
  }

}
