import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';
import { TranslateModule } from '@ngx-translate/core';

import { PwaInstallComponent } from './components/pwa-install/pwa-install.component';

@NgModule({
  imports: [
    CommonModule,
    EkonIconsModule,
    TranslateModule,
    MatButtonModule
  ],
  declarations: [PwaInstallComponent],
  exports: [PwaInstallComponent]
})
export class EkonPwaModule {}
