<figure>
  <ngx-audio-player
    [playlist]="fileBodyURI$ | async"
    [displayPlaylist]="false"
    [displayVolumeControls]="true"
  ></ngx-audio-player>
  <figcaption>
    <div *ngIf="!hideCredentials"><small>
      <span>{{fileMeta.fileName}}</span>
      <em> by
        <dkm-core-user-name [user]="fileAuthor"></dkm-core-user-name>
      </em>
    </small></div>
    <div>{{fileMeta.shortDescription}}</div>
  </figcaption>
</figure>
