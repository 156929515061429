/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CreateFeedback } from '../model/models';
import { FeedbackDetails } from '../model/models';
import { FeedbackDetailsPagedResult } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface FeedbackServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createFeedback 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createFeedback(createFeedback?: CreateFeedback, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FeedbackDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteFeedback(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findFeedbackById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FeedbackDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listAllFeedbacks(pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FeedbackDetailsPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listDomainsFeedbacks(pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FeedbackDetailsPagedResult>;

    /**
     * Get list of optional feedback questions
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listFeedbackQuestions(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<string>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listUsersFeedbacks(pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FeedbackDetailsPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    submitFeedback(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param feedbackDetails 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateFeedback(id: string, feedbackDetails?: FeedbackDetails, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

}
