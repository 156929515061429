import { ChangeDetectorRef, Component, forwardRef, Inject, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PageHeader, PageHeaderPagedResult, PageServiceInterface } from '@ekon-client/dkm-api';
import { PAGE_ACTIONS } from '@ekon-client/dkm-events';

import { BaseChipsAutocomplete } from '../../base/base-chips-autocomplete';
import { AutocompleteService } from '../../services';

const CUSTOM_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PagesChipsAutocompleteComponent),
  multi: true,
};

@Component({
  selector: 'dkm-pages-chips-autocomplete',
  templateUrl: './pages-chips-autocomplete.component.html',
  styles: [''],
  providers: [CUSTOM_VALUE_ACCESSOR],
})
export class PagesChipsAutocompleteComponent extends BaseChipsAutocomplete<
  PageHeader,
  string
> {
  constructor(
    autocompleteService: AutocompleteService,
    @Inject(PAGE_ACTIONS) private pageActions: PageServiceInterface,
    protected _cdr: ChangeDetectorRef
  ) {
    super(_cdr);

    this.filtered = autocompleteService.autocompletePages(this.inputControl);
  }

  writeValue(items: string[]): void {
    if (items) {
      // todo: deal with pagination
      this.pageActions.listSelectedPagesByIds({
        pageSize: 100,
        pageNumber: 1
      }, items )
        .subscribe((pageHeaders: PageHeaderPagedResult) => {
          this.items = pageHeaders.items || [];
        });
    }
  }

  prepareFormValue(items: PageHeader[]): string[] {
    return items.map((item: PageHeader) => item.id);
  }
}
