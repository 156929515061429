import { Inject, Injectable } from '@angular/core';
import {
  BlockContentServiceInterface,
  BlockedContentType,
  HiddenContentDTO,
  ReportContentServiceInterface, ReportedContentDTO
} from '@ekon-client/dkm-api';
import { BLOCK_CONTENT_ACTIONS, REPORT_CONTENT_ACTIONS } from '@ekon-client/dkm-events';
import { EkonDialogsService } from '@ekon-client/shared/common/ekon-dialogs';
import { EkonMessageService, EkonMessageTypes } from '@ekon-client/shared/common/ekon-utils';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BlockNReportService {

  constructor(
    @Inject(BLOCK_CONTENT_ACTIONS) private blockContentActions: BlockContentServiceInterface,
    @Inject(REPORT_CONTENT_ACTIONS) private reportContentActions: ReportContentServiceInterface,
    private dialogsService: EkonDialogsService,
    private message: EkonMessageService
  ) { }

  report(contentId: string, contentType: BlockedContentType): Observable<unknown> {
    return this.dialogsService.confirm(
      `Are you sure you want to report this ${contentType.toLowerCase()}?`,
      `Report ${contentType}`,
      '<p>Thank you for your engagement report.</p> ' +
      '<p>We will also notify the content creator/owner.</p>' +
      '<h3>Please note, that such notification shall in no way obligate us to remove such content.</h3>' +
      '<h3><b>Contact law enforcement in your area, and/or get help if someone is in immediate danger.</b></h3>',
      { ok: 'Send' },
      {
        enabled: true,
        required: true,
        label: 'Reason',
        placeholder: 'Please, share a reason of your request'
      }
    ).pipe(
      filter(r => Boolean(r) || r === ''),
      map(r => r === true || r === '' ? undefined : (r as string)),
      switchMap((reason: string | undefined) => this.reportContentActions.reportContent(contentType, contentId, reason)),
      tap({
        next: () => this.message.show('Report sent', EkonMessageTypes.SUCCESS),
        error: () => this.message.show('Error occurred while sending report', EkonMessageTypes.ERROR)
      })
    );
  }

  block(contentId: string, contentType: BlockedContentType, useAsReason?:string): Observable<unknown> {
    return this.dialogsService.confirm(
      `Are you sure you want to hide this ${contentType.toLowerCase()}?`,
      `Hide ${contentType}`,
      '<p>You are about to hide this content for your self only.</p>' +
      '<p>You will no longer be able to see this content. However, others stil might see this content.</p>' +
      '<h3>Please note, you will still be able to see related content such as files (images/document etc.,), ' +
      'related pages, and so forth, if used in other places.</h3>',
      { ok: 'Hide' }
      // {
      //   enabled: true,
      //   label: 'Reason',
      //   placeholder: 'You are free to make a note on why you wanted to hide this content.',
      // }
    ).pipe(
      filter(r => Boolean(r) || r === ''),
      // map(r => r === true || r === '' ? undefined : (r as string)),
      switchMap((/*reason: string | undefined*/) => this.blockContentActions.hideContent(contentId, contentType, useAsReason)),
      tap({
        next: () => this.message.show(`${contentType} hided`, EkonMessageTypes.SUCCESS),
        error: () => this.message.show(`Error occurred while hiding ${contentType.toLowerCase()}`, EkonMessageTypes.ERROR)
      })
    );
  }

  unblock(id: string, item: HiddenContentDTO | ReportedContentDTO, enableReason?: boolean, reasonRequired?: boolean): Observable<unknown> {
    return this.dialogsService.confirm(
      `Are you sure you want to unblock this ${item.contentType?.toLowerCase()}?`,
      `Unblock ${item.contentType}`,
      undefined,
      { ok: 'Unblock' },
      {
        enabled: enableReason,
        required: reasonRequired,
        label: 'Reason',
        placeholder: 'Please, share a reason of Your decision'
      }
    ).pipe(
      filter(r => Boolean(r) || r === ''),
      map(r => r === true || r === '' ? undefined : (r as string)),
      switchMap((reason: string | undefined) => this.blockContentActions.unblockContent(id as string, reason)),
      tap({
        next: () => this.message.show(`${item.contentType} unblocked`, EkonMessageTypes.SUCCESS),
        error: () => this.message.show(`Error occurred while unblocking ${item.contentType?.toLowerCase()}`, EkonMessageTypes.ERROR)
      })
    );
  }

  acceptReport(item: ReportedContentDTO): Observable<unknown> {
    return this.dialogsService.confirm(
      `Are you sure you want to accept report for this ${item.contentType?.toLowerCase()}?`,
      `Accept report ${item.contentType}`,
      undefined,
      { ok: 'Accept' },
      {
        enabled: true,
        required: true,
        label: 'Reason',
        placeholder: 'Please, share a reason of Your decision'
      }
    ).pipe(
      filter(r => Boolean(r) || r === ''),
      map(r => r === true || r === '' ? undefined : (r as string)),
      switchMap((reason: string | undefined) => this.reportContentActions.blockContent(
        item.id as string,
        item.contentType as BlockedContentType,
        item.contentId as string,
        reason
      )),
      tap({
        next: () => this.message.show(`Report for ${item.contentType} accepted`, EkonMessageTypes.SUCCESS),
        error: () => this.message.show(`Error occurred while accepting report for ${item.contentType?.toLowerCase()}`, EkonMessageTypes.ERROR)
      })
    );
  }

}
