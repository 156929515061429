import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GlobalConfigurationService, Language } from '@ekon-client/shared/cloud-config';
import { TranslateService } from '@ngx-translate/core';
import { find as _find, map as _map, noop as _noop } from 'lodash-es';

@Component({
  selector: 'ekon-language-setter',
  templateUrl: './language-setter.component.html',
  styleUrls: ['./language-setter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSetterComponent {
  languages: Language[];
  selectedLanguage: Language;

  constructor(
    private _translateService: TranslateService,
    public globalConfig: GlobalConfigurationService
  ) {
    this.languages = _map(this.globalConfig.langAvailable);

    // Set the selected language from default languages
    this.selectedLanguage = _find(this.languages, {
      key: this._translateService.currentLang
    });
  }

  setLanguage(lang: Language): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.key).subscribe(_noop);

    // save to localStorage
    localStorage.setItem('locale', lang.key);
  }

}
