import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { DkmAutocompletionModule } from '@ekon-client/shared/features/dkm-autocompletion';
import { TranslateModule } from '@ngx-translate/core';

import { FileEditDialogComponent } from './components/file-edit-dialog/file-edit-dialog.component';

@NgModule({
  declarations: [
    FileEditDialogComponent,
  ],
  exports: [
    FileEditDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FlexModule,
    DkmAutocompletionModule,
    MatButtonModule,
    EkonDialogsModule,
    TranslateModule
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-files/src/lib/file-manage/',
      multi: true
    }
  ]
})
export class FileManageModule { }
