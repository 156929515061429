/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Baskets itmes content
 */
export interface ProductsGoodsDetails { 
    /**
     * Ref to product-page-id
     */
    productPageId?: string;
    /**
     * Copy name from product page
     */
    productName?: string | null;
    /**
     * Copy description from product page
     */
    productShortDescription?: string | null;
    /**
     * Legal Entity owner of this product
     */
    legalEntityId?: string;
    /**
     * Requested Quantity
     */
    quantity?: number;
    /**
     * Set the max and min allowed Quantity
     */
    maxQuantity?: number;
    minQuantity?: number;
    /**
     * Vendors base price per unit.   Copy from page-product
     */
    price?: number;
    /**
     * Product Page ThumbprintUrl  Copy from page-product
     */
    thumbprintUrl?: string | null;
    /**
     * Vendor page-id  Extract from page-product.  The prodct-page owner is the Vendor.
     */
    vendorId?: string | null;
    /**
     * Vendor name   Extract via page-product.
     */
    vendorName?: string | null;
    /**
     * Vendor short description  Extract via page-product.
     */
    vendorShortDescription?: string | null;
}

