/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { EmailModel } from './emailModel';
import { Phone } from './phone';


export interface LegalPerson { 
    familyName?: string | null;
    firstName?: string | null;
    title?: string | null;
    /**
     * Legal person position in org, VP, CEO, Director, etc . Optional.
     */
    positionInOrganisation?: string | null;
    /**
     * Legal person role could be accountant, legal representative etc. Optional.
     */
    roleInOrganisation?: string | null;
    department?: string | null;
    phones?: Array<Phone> | null;
    emailList?: Array<EmailModel> | null;
    address?: Address;
}

