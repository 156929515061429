<div [formGroup]="form" fxLayout="row" fxLayoutGap="16px">
  <ekon-autocomplete
    *ngIf="autocompleteList; else simpleInput"
    [formControlName]="control1Name"
    class="mb-16"
    [requestApi]="autocompleteList"
    [label]="control1Label"
    [placeholder]="control1Placeholder"
    [required]="control1Required"
  ></ekon-autocomplete>
  <ng-template #simpleInput>
    <mat-form-field
      appearance="outline"
      fxFlex="1 1 50%"
    >
      <mat-label>{{control1Label}}</mat-label>
      <input matInput [formControlName]="control1Name"/>
    </mat-form-field>
  </ng-template>

  <mat-form-field appearance="outline" fxFlex="1 1 50%">
    <mat-label>{{control2Label}}</mat-label>
    <mat-select
      [formControlName]="control2Name"
    >
      <ng-container *ngFor="let option of options; let i = index">
        <mat-option [value]="option">
          <ng-container *ngTemplateOutlet="cChild; context: { $implicit: option, index: i }"></ng-container>
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
