<ekon-dialog-wrapper [isFullscreen]="true">
  <div mat-dialog-title>{{data.kind === 'event' ? 'Event' : 'Task'}} {{'SELECTION' | translate: { default: 'selection' } }}</div>
  <mat-dialog-content>
    <ekon-paginator [config]="paginatorConfig" #paginator="ekonPaginator">
      <dkm-calendar-item-list
        [tasks]="paginator.items$ | async"
        [viewOnly]="true"
        [horizontal]="false"
        (select)="validateAndSave($event)"
        [selectMode]="true"
      ></dkm-calendar-item-list>
    </ekon-paginator>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxFlex="0 0 auto">
    <button mat-button [mat-dialog-close]="false">{{'CANCEL' | translate: { default: 'Cancel' } }}</button>
  </mat-dialog-actions>
</ekon-dialog-wrapper>
