import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageHeader } from '@ekon-client/dkm-api';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { ekIconBox, ekIconNewspaper } from '@ekon-client/shared/common/ekon-icons';

@Component({
  selector: 'dkm-page-card',
  templateUrl: './page-card.component.html',
  styleUrls: ['./page-card.component.scss']
})
export class PageCardComponent {
  ekIconNewspaper = ekIconNewspaper;
  ekIconBox = ekIconBox;

  @Input() isProduct: boolean;

  @Input()
  public page: PageHeader;

  @Input() disableAllActions = false;
  @Input() deriveEnabled = true;
  @Input() cloneEnabled = true;
  @Input() deleteEnabled = false;

  @Output()
  remove = new EventEmitter<PageHeader>();

  @Output()
  edit = new EventEmitter<PageHeader>();

  @Output()
  duplicate = new EventEmitter<PageHeader>();

  @Output()
  derive = new EventEmitter<PageHeader>();

  @Output()
  select = new EventEmitter<PageHeader>();

  constructor(
    public globalConfig: GlobalConfigurationService
  ) {}

  deletePage(): void {
    this.remove.emit(this.page);
  }

  viewPage(): void {
    this.edit.emit(this.page);
  }

  duplicatePage(): void {
    this.duplicate.emit(this.page);
  }

  derivePage(): void {
    this.derive.emit(this.page);
  }

  selectPage(): void {
    this.select.emit(this.page);
  }
}
