import { ChangeDetectionStrategy, Component, ContentChild, forwardRef, Input, TemplateRef } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors
} from '@angular/forms';
import { Observable } from 'rxjs';

import { ReusableFormBase } from '../../../../ReusableFormBase';
import { ControlsPairFormComponent } from '../../controls-pair-form.component';

@Component({
  selector: 'ekon-controls-pair-select',
  templateUrl: './controls-pair-select.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControlsPairSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ControlsPairSelectComponent),
      multi: true,
    },
  ],
})
export class ControlsPairSelectComponent extends ControlsPairFormComponent {
  @Input() options: unknown[];
  @Input() autocompleteList: (query: string) => Observable<string[]>;
  @ContentChild(TemplateRef) cChild: TemplateRef<unknown>;

  validate(/*_control: AbstractControl*/): ValidationErrors | null {
    return ReusableFormBase.validate(this.form);
  }

  makeOutputValue(data: unknown): unknown | null {
    return ReusableFormBase.objToValueOrNull(data);
  }
}
