<mat-list>
  <h3 matSubheader fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <ekon-icon icon="chat" class="mr-4"></ekon-icon>
      <span>Chat Bot</span>
    </div>

    <div>
      <mat-slide-toggle [checked]="chatBotEnabled" (change)="toggleChatBot($event.checked)"></mat-slide-toggle>
    </div>
  </h3>
</mat-list>
