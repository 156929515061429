import { ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { Address } from '@ekon-client/dkm-api';

import { ReusableFormBase } from '../../ReusableFormBase';

@Component({
  selector: 'dkm-core-address-form',
  templateUrl: './address-form.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true,
    },
  ],
})
export class AddressFormComponent extends ReusableFormBase<Address> {
  form: FormGroup = new FormGroup({
    addressName: new FormControl(null),
    country: new FormControl(null),
    city: new FormControl(null),
    street: new FormControl(null),
    number: new FormControl(null),
    postCode: new FormControl(null),
  });

  constructor(
    // @SkipSelf() private parentCdr: ChangeDetectorRef
  ) {
    super();
    this.initValueChanges();
  }

  validate(/*_control: AbstractControl*/): ValidationErrors | null {
    // console.warn('validate', ReusableFormBase.validate(this.form));
    // this.parentCdr.detectChanges();
    return ReusableFormBase.validate(this.form);
  }

  makeOutputValue(data: Address): Address | null {
    return ReusableFormBase.objToValueOrNull(data);
  }
}
