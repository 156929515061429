/* 🤖 this file was generated by svg-to-ts*/

import { EkonIconDefinition } from './icons-set/../EkonIconType';

export type EkonIconDefinitionNameSubset<
  T extends Readonly<EkonIconDefinition[]>
> = T[number]['name'];

export * from './icons-set/../EkonIconType';
export * from './icons-set/ekIcon-3rd.icon';
export * from './icons-set/ekIcon-add-square.icon';
export * from './icons-set/ekIcon-AddCart.icon';
export * from './icons-set/ekIcon-AddToBasket.icon';
export * from './icons-set/ekIcon-admin-panel.icon';
export * from './icons-set/ekIcon-Analytics.icon';
export * from './icons-set/ekIcon-angle-left.icon';
export * from './icons-set/ekIcon-angle-right.icon';
export * from './icons-set/ekIcon-ARC.icon';
export * from './icons-set/ekIcon-arrow-double-down.icon';
export * from './icons-set/ekIcon-arrow-double-left.icon';
export * from './icons-set/ekIcon-arrow-double-right.icon';
export * from './icons-set/ekIcon-arrow-double-up.icon';
export * from './icons-set/ekIcon-arrows-alt.icon';
export * from './icons-set/ekIcon-ARW.icon';
export * from './icons-set/ekIcon-attachment.icon';
export * from './icons-set/ekIcon-attendee.icon';
export * from './icons-set/ekIcon-bars.icon';
export * from './icons-set/ekIcon-bell.icon';
export * from './icons-set/ekIcon-Bid.icon';
export * from './icons-set/ekIcon-blogs.icon';
export * from './icons-set/ekIcon-book.icon';
export * from './icons-set/ekIcon-Boolean.icon';
export * from './icons-set/ekIcon-box.icon';
export * from './icons-set/ekIcon-briefcase.icon';
export * from './icons-set/ekIcon-build.icon';
export * from './icons-set/ekIcon-calendar.icon';
export * from './icons-set/ekIcon-calendar_day.icon';
export * from './icons-set/ekIcon-calendar_event.icon';
export * from './icons-set/ekIcon-calendar_plus.icon';
export * from './icons-set/ekIcon-calendar_week.icon';
export * from './icons-set/ekIcon-calendar-alt.icon';
export * from './icons-set/ekIcon-cart.icon';
export * from './icons-set/ekIcon-catalog.icon';
export * from './icons-set/ekIcon-chat.icon';
export * from './icons-set/ekIcon-check-square.icon';
export * from './icons-set/ekIcon-chevron-down.icon';
export * from './icons-set/ekIcon-chevron-up.icon';
export * from './icons-set/ekIcon-clock.icon';
export * from './icons-set/ekIcon-clone.icon';
export * from './icons-set/ekIcon-cog.icon';
export * from './icons-set/ekIcon-color-picker.icon';
export * from './icons-set/ekIcon-comment-reply.icon';
export * from './icons-set/ekIcon-comments.icon';
export * from './icons-set/ekIcon-completion.icon';
export * from './icons-set/ekIcon-compress_alt.icon';
export * from './icons-set/ekIcon-connect.icon';
export * from './icons-set/ekIcon-cool.icon';
export * from './icons-set/ekIcon-Cropper.icon';
export * from './icons-set/ekIcon-CS.icon';
export * from './icons-set/ekIcon-CSS.icon';
export * from './icons-set/ekIcon-CSV.icon';
export * from './icons-set/ekIcon-curious.icon';
export * from './icons-set/ekIcon-currency.icon';
export * from './icons-set/ekIcon-DateTime.icon';
export * from './icons-set/ekIcon-diagram.icon';
export * from './icons-set/ekIcon-direct-message.icon';
export * from './icons-set/ekIcon-doc.icon';
export * from './icons-set/ekIcon-docx.icon';
export * from './icons-set/ekIcon-download.icon';
export * from './icons-set/ekIcon-dropdown.icon';
export * from './icons-set/ekIcon-edit.icon';
export * from './icons-set/ekIcon-element_type.icon';
export * from './icons-set/ekIcon-ellipsis-v.icon';
export * from './icons-set/ekIcon-Event.icon';
export * from './icons-set/ekIcon-Exclamation.icon';
export * from './icons-set/ekIcon-exclamation-triangle.icon';
export * from './icons-set/ekIcon-expand-arrows-alt.icon';
export * from './icons-set/ekIcon-external-link-alt.icon';
export * from './icons-set/ekIcon-file.icon';
export * from './icons-set/ekIcon-file-view.icon';
export * from './icons-set/ekIcon-filter.icon';
export * from './icons-set/ekIcon-FLAC.icon';
export * from './icons-set/ekIcon-flag-de.icon';
export * from './icons-set/ekIcon-flag-fr.icon';
export * from './icons-set/ekIcon-flag-hu.icon';
export * from './icons-set/ekIcon-flag-il.icon';
export * from './icons-set/ekIcon-flag-ru.icon';
export * from './icons-set/ekIcon-flag-ua.icon';
export * from './icons-set/ekIcon-flag-uk.icon';
export * from './icons-set/ekIcon-flag-us.icon';
export * from './icons-set/ekIcon-folder.icon';
export * from './icons-set/ekIcon-folder_open.icon';
export * from './icons-set/ekIcon-gif.icon';
export * from './icons-set/ekIcon-glasses.icon';
export * from './icons-set/ekIcon-grid_list.icon';
export * from './icons-set/ekIcon-GridView.icon';
export * from './icons-set/ekIcon-hand-holding-heart.icon';
export * from './icons-set/ekIcon-hand-sparkles.icon';
export * from './icons-set/ekIcon-heart.icon';
export * from './icons-set/ekIcon-heart-outline.icon';
export * from './icons-set/ekIcon-home.icon';
export * from './icons-set/ekIcon-hourglass.icon';
export * from './icons-set/ekIcon-house-user.icon';
export * from './icons-set/ekIcon-HTML.icon';
export * from './icons-set/ekIcon-id_card.icon';
export * from './icons-set/ekIcon-IframeValue.icon';
export * from './icons-set/ekIcon-image.icon';
export * from './icons-set/ekIcon-infinite_list.icon';
export * from './icons-set/ekIcon-info.icon';
export * from './icons-set/ekIcon-Input.icon';
export * from './icons-set/ekIcon-insightful.icon';
export * from './icons-set/ekIcon-ios-share.icon';
export * from './icons-set/ekIcon-jpeg.icon';
export * from './icons-set/ekIcon-jpg.icon';
export * from './icons-set/ekIcon-JS.icon';
export * from './icons-set/ekIcon-Json.icon';
export * from './icons-set/ekIcon-laptop.icon';
export * from './icons-set/ekIcon-layer-group.icon';
export * from './icons-set/ekIcon-lightbulb-on.icon';
export * from './icons-set/ekIcon-like.icon';
export * from './icons-set/ekIcon-link.icon';
export * from './icons-set/ekIcon-location-outline.icon';
export * from './icons-set/ekIcon-lock.icon';
export * from './icons-set/ekIcon-logout.icon';
export * from './icons-set/ekIcon-love.icon';
export * from './icons-set/ekIcon-mail.icon';
export * from './icons-set/ekIcon-map-marked-alt.icon';
export * from './icons-set/ekIcon-media.icon';
export * from './icons-set/ekIcon-meh.icon';
export * from './icons-set/ekIcon-minus.icon';
export * from './icons-set/ekIcon-mov.icon';
export * from './icons-set/ekIcon-move.icon';
export * from './icons-set/ekIcon-MP3.icon';
export * from './icons-set/ekIcon-mp4.icon';
export * from './icons-set/ekIcon-my-space.icon';
export * from './icons-set/ekIcon-newspaper.icon';
export * from './icons-set/ekIcon-notes.icon';
export * from './icons-set/ekIcon-Numeric.icon';
export * from './icons-set/ekIcon-Ontology.icon';
export * from './icons-set/ekIcon-open-in-new.icon';
export * from './icons-set/ekIcon-pages.icon';
export * from './icons-set/ekIcon-palette.icon';
export * from './icons-set/ekIcon-palette_outline.icon';
export * from './icons-set/ekIcon-PDF.icon';
export * from './icons-set/ekIcon-pencil-ruler.icon';
export * from './icons-set/ekIcon-phone_outline.icon';
export * from './icons-set/ekIcon-plus.icon';
export * from './icons-set/ekIcon-png.icon';
export * from './icons-set/ekIcon-posts.icon';
export * from './icons-set/ekIcon-priority.icon';
export * from './icons-set/ekIcon-project-diagram.icon';
export * from './icons-set/ekIcon-question.icon';
export * from './icons-set/ekIcon-RAW.icon';
export * from './icons-set/ekIcon-redo.icon';
export * from './icons-set/ekIcon-Related Posts.icon';
export * from './icons-set/ekIcon-report.icon';
export * from './icons-set/ekIcon-RichText.icon';
export * from './icons-set/ekIcon-SCSS.icon';
export * from './icons-set/ekIcon-search.icon';
export * from './icons-set/ekIcon-sentiment.icon';
export * from './icons-set/ekIcon-share-outline.icon';
export * from './icons-set/ekIcon-share-square.icon';
export * from './icons-set/ekIcon-shipping-fast.icon';
export * from './icons-set/ekIcon-shopping-bag.icon';
export * from './icons-set/ekIcon-shopping-cart.icon';
export * from './icons-set/ekIcon-Simulation.icon';
export * from './icons-set/ekIcon-sorting asc.icon';
export * from './icons-set/ekIcon-sorting desc.icon';
export * from './icons-set/ekIcon-sorting.icon';
export * from './icons-set/ekIcon-square.icon';
export * from './icons-set/ekIcon-star-empty.icon';
export * from './icons-set/ekIcon-star-full.icon';
export * from './icons-set/ekIcon-star-half.icon';
export * from './icons-set/ekIcon-sticky-note.icon';
export * from './icons-set/ekIcon-stores.icon';
export * from './icons-set/ekIcon-subscription.icon';
export * from './icons-set/ekIcon-Support.icon';
export * from './icons-set/ekIcon-tags.icon';
export * from './icons-set/ekIcon-task.icon';
export * from './icons-set/ekIcon-task-outlined.icon';
export * from './icons-set/ekIcon-Template.icon';
export * from './icons-set/ekIcon-TextArea.icon';
export * from './icons-set/ekIcon-TextInput.icon';
export * from './icons-set/ekIcon-thumbs-up.icon';
export * from './icons-set/ekIcon-times.icon';
export * from './icons-set/ekIcon-times-circle.icon';
export * from './icons-set/ekIcon-trash-alt.icon';
export * from './icons-set/ekIcon-trash-full.icon';
export * from './icons-set/ekIcon-TS.icon';
export * from './icons-set/ekIcon-Unlock.icon';
export * from './icons-set/ekIcon-upload.icon';
export * from './icons-set/ekIcon-user.icon';
export * from './icons-set/ekIcon-user-plus.icon';
export * from './icons-set/ekIcon-Value.icon';
export * from './icons-set/ekIcon-vector-square.icon';
export * from './icons-set/ekIcon-work-outline.icon';
export * from './icons-set/ekIcon-youtube.icon';
