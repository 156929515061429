<div class="top-actions">
  <dkm-filters
    *ngIf="!sortingNFilteringHidden"
    (filtersUpdated)="pushFilteringState($event)"
    (sortingUpdated)="pushSortingState($event)"
    [categoriesFilterDisabled]="filtersConfig?.byCategories === false"
    [templatesFilterEnabled]="filtersConfig?.byTemplates !== false"
    [tagsFilterDisabled]="filtersConfig?.byTags === false"
    [sortingConfig]="sortingConfig"
    [filteringConfig]="!filtersConfig?.disabled"
  ></dkm-filters>
  <button
    mat-icon-button

    class="layout-mode"
    (click)="toggleMode()"
    *ngIf="!modeSwitcherHidden"
    [matTooltip]="(paginator.paginatorMode$ | async) === 'infinite' ? 'Switch view to pages' : 'Switch view to list'"
  >
    <ekon-icon
      [icon]="(paginator.paginatorMode$ | async) === 'infinite' ? 'infinite-list' : 'grid-list'"
      size="lg"
    ></ekon-icon>
  </button>
</div>
<mat-paginator
  *ngIf="(paginator.paginatorMode$ | async) === 'paginated' && !topPaginatorHidden && !allPaginatorsHidden"
  [length]="paginator.totalItems$ | async"
  [pageSize]="paginator.pageSize$ | async"
  [pageIndex]="(paginator.pageNumber$ | async) - 1"
  [pageSizeOptions]="paginator.pageSizeOptions$ | async"
  (page)="setPage($event)"
  [style.background]="noBackground ? 'none' : ''"
></mat-paginator>

<ng-container
  *ngIf="(!customEmptyHandling && (items$ | async)?.length)
   || (customEmptyHandling && !(paginator.error$ | async)); else emptyHandler"
>
  <div>
    <button
      mat-raised-button
      *ngIf="checkUpdates$ | async"
      type="button"
      class="updates-indicator"
      (click)="viewLatestItems()"
    >{{checkForUpdates?.label ? checkForUpdates.label : 'New items added'}}</button>
    <div
      *ngIf="(paginator.paginatorMode$ | async) === 'infinite'; else paginatedContent"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="250"
      (scrolled)="onScroll()"
      (scrolledUp)="onScrollUp()"
    >
      <ng-container *ngTemplateOutlet="paginatedContent"></ng-container>
    </div>
    <ng-template #paginatedContent>
      <ng-content></ng-content>
    </ng-template>
  </div>

  <mat-paginator
    *ngIf="(paginator.paginatorMode$ | async) === 'paginated' && !allPaginatorsHidden"
    [length]="paginator.totalItems$ | async"
    [pageSize]="paginator.pageSize$ | async"
    [pageIndex]="(paginator.pageNumber$ | async) - 1"
    [pageSizeOptions]="paginator.pageSizeOptions$ | async"
    (page)="setPage($event)"
    [style.background]="noBackground ? 'none' : ''"
  ></mat-paginator>
</ng-container>
<ng-template #emptyHandler>
  <div class="empty-handler">
    <div
      *ngIf="(items$ | async)?.length === 0"
      class="no-items"
    >No items found
      <ng-container *ngIf="paginator.filtering$ | async as ff">
        {{ (ff.tagFilter?.length || ff.categoryFilter?.length || ff.freeTextSearchFilter) ? 'for selected filters' : '' }}
      </ng-container>
    </div>
    <ng-container *ngIf="paginator.error$ | async as error">
      <!--<div [ngClass]="error.type + '-message'">{{error?.message}}</div>-->
      <div
        class="reload-btn"
        matRipple
        *ngIf="error.type === 'error'"
        (click)="forceReload()"
      >
        <ekon-icon icon="redo" size="3x"></ekon-icon>
      </div>
    </ng-container>
    <!--<ng-container *ngIf="paginator.loading$ | async">
      <div>Loading...</div>
    </ng-container>-->
  </div>
</ng-template>
