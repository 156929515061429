import { FileMetadata, FileMetadataPagedResult, UserProfileHeader } from '@ekon-client/dkm-api';

import { FileDataSource } from './FileDataSource';

export interface FileListDataSourcePaginated {
  files: FileMetadataPagedResult;
  users: UserProfileHeader[];
}

export interface FileListDataSourcePaginatedAlt {
  items?: Array<FileDataSource> | null ;
  pageSize?: number;
  pageNumber?: number;
  totalItems?: number;
}

export interface FileListDataSource {
  files: FileMetadata[];
  users: UserProfileHeader[];
}
