import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { BasketService } from './api/basket.service';
import { BlockContentService } from './api/blockContent.service';
import { BlogService } from './api/blog.service';
import { CalendarService } from './api/calendar.service';
import { CategoryService } from './api/category.service';
import { CommentService } from './api/comment.service';
import { DLTService } from './api/dLT.service';
import { DomainService } from './api/domain.service';
import { ElementTemplatesService } from './api/elementTemplates.service';
import { FaqService } from './api/faq.service';
import { FeedbackService } from './api/feedback.service';
import { FilesService } from './api/files.service';
import { GeoLayerService } from './api/geoLayer.service';
import { InvitationService } from './api/invitation.service';
import { KbeService } from './api/kbe.service';
import { LandingPageService } from './api/landingPage.service';
import { LegalService } from './api/legal.service';
import { LegalEntityService } from './api/legalEntity.service';
import { NoteService } from './api/note.service';
import { NotificationTemplateService } from './api/notificationTemplate.service';
import { PageService } from './api/page.service';
import { ProductService } from './api/product.service';
import { RateService } from './api/rate.service';
import { ReportContentService } from './api/reportContent.service';
import { ResponseService } from './api/response.service';
import { RssService } from './api/rss.service';
import { StatisticsService } from './api/statistics.service';
import { TagService } from './api/tag.service';
import { UserPostsService } from './api/userPosts.service';
import { UserProfileService } from './api/userProfile.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
