import { CommonModule } from '@angular/common';
import { NgModule, Optional } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

import { TourLaunchComponent } from './components/ekon-tour-launch/tour-launch.component';

@NgModule({
  imports: [
    CommonModule,
    EkonIconsModule,
    MatButtonModule
  ],
  declarations: [
    TourLaunchComponent
  ],
  exports: [
    TourLaunchComponent
  ]
})
export class DkmTourModule {
  constructor(@Optional() tourMatMenuModule: TourMatMenuModule) {
    if(!tourMatMenuModule) {
      console.error('TourMatMenuModule isn`t imported to root module');
    }
  }
}
