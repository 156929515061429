import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostBinding, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { FileMetadata, UserProfileHeader } from '@ekon-client/dkm-api';
import { fromEvent, Subscription } from 'rxjs';

import {
  ImagePreviewOverlayComponent, PREVIEW_FILE_DATA,
  PREVIEW_FILE_ID,
  PREVIEW_OVERLAY_REF
} from '../components/image-preview-overlay/image-preview-overlay.component';

@Directive({
  selector: '[ekonImageFullscreen]'
})
export class FileViewImageFullsizeDirective implements OnInit, OnDestroy {

  private _enabled = true;
  private clickListener: Subscription;

  @Input('ekonImageFullscreen') fileId?: string;
  @Input() fileMeta: FileMetadata;
  @Input() fileAuthor: UserProfileHeader;
  @Input('ekonImageFullscreenEnabled') set enabled(enable: boolean) {
    this._enabled = enable;

    if(enable) {
      this.addListener();
    } else {
      this.clearListener();
    }
  }

  get enabled(): boolean {
    return this._enabled;
  }

  @HostBinding('style.cursor') get imageClickable(): string {
    return this.enabled ? 'pointer' : 'unset';
  }


  constructor(
    private overlay: Overlay,
    private inj: Injector,
    private hostElRef: ElementRef<Element>
  ) { }

  ngOnInit(): void {
    if (this.enabled) {
      this.addListener();
    }
  }

  ngOnDestroy(): void {
    this.clearListener();
  }

  addListener(): void {
    if(!this.clickListener) {
      this.clickListener = fromEvent(this.hostElRef.nativeElement, 'click')
        .subscribe({
          next: () => this.openView()
        });
    }
  }

  clearListener(): void {
    if(this.clickListener) {
      !this.clickListener.closed && this.clickListener.unsubscribe();
      this.clickListener = undefined;
    }
  }

  openView(): void {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const scrollStrategy = this.overlay.scrollStrategies.block();

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: false,
      disposeOnNavigation: true,
      width: '100%',
      height: '100%',
    });

    // Create ComponentPortal that can be attached to a PortalHost
    const imgPreviewPortal = new ComponentPortal(
      ImagePreviewOverlayComponent,
      undefined,
      Injector.create({
        providers: [
          {
            provide: PREVIEW_FILE_DATA,
            useValue: this.fileMeta && ({
              file: this.fileMeta,
              user: this.fileAuthor
            })
          },
          {
            provide: PREVIEW_FILE_ID,
            useValue: this.fileId
          },
          {
            provide: PREVIEW_OVERLAY_REF,
            useValue: overlayRef
          },
        ],
        parent: this.inj
      })
    );

    // Attach ComponentPortal to PortalHost
    overlayRef.attach(imgPreviewPortal);

  }
}
