/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateNote { 
    /**
     * Text content
     */
    value: string;
    /**
     * Note reminder time stamp.
     */
    reminderDateTime?: Date | null;
    /**
     * Note closer time stamp.
     */
    closeAfterDateTime?: Date | null;
    /**
     * User mark is note/comment is closed
     */
    isClosed?: boolean;
    /**
     * Tags to filter Notes
     */
    tags?: Array<string> | null;
    color?: string | null;
    externalLink?: string | null;
    isPlatformNote?: boolean;
}

