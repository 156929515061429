/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Filter } from './filter';
import { Sort } from './sort';


export interface PagePaginationModel { 
    /**
     * Number of items per page
     */
    pageSize?: number;
    /**
     * Page number to retrieve
     */
    pageNumber?: number;
    sorting?: Sort;
    filters?: Filter;
}

