import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import Map from 'ol/Map';

import { MapService } from '../services/map.service';

@Directive({
  selector: '[dkmMap]',
  providers: [MapService]
})
export class MapDirective implements OnInit {
  @Input() mouseWheelDisabled: boolean;
  @Input() controlsDisabled: boolean;
  @Input() interactionsDisabled: boolean;

  map: Map;

  constructor(private elRef: ElementRef, private mapService: MapService) {
  }

  ngOnInit(): void {
    // eslint-disable-next-line rxjs/no-ignored-observable
    this.mapService.initMap({
      controlsDisabled: this.controlsDisabled,
      mouseWheelDisabled: this.mouseWheelDisabled,
      interactionsDisabled: this.interactionsDisabled
    });
    // this.mapService.setOptions({
    //   mouseWheelDisabled: this.mouseWheelDisabled === true, /* || this.mouseWheelDisabled === '' */
    //   controlsDisabled: this.controlsDisabled === true /* || this.mouseWheelDisabled === '' */
    // });
    this.mapService.setMapContainer(this.elRef.nativeElement);
  }
}
