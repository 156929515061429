<div class="text-boxed m-0 mb-16">{{data.title}}</div>
<div class="mb-16">{{data.firstName}} {{data.familyName}}</div>
<div class="mb-16">
  <span *ngIf="data.positionInOrganisation"><small class="text-boxed m-0 mr-8">position</small>{{data.positionInOrganisation}}</span>
</div>
<div class="mb-16">
  <span *ngIf="data.department"><small class="text-boxed m-0 mr-8">department</small>{{data.department}}</span>
</div>
<div class="mb-16">
  <span *ngIf="data.roleInOrganisation"><small class="text-boxed m-0 mr-8">role</small>{{data.roleInOrganisation}}</span>
</div>
<dkm-core-address-view *ngIf="data.address" [data]="data.address"></dkm-core-address-view>
<ng-container *ngIf="data.phones">
  <dkm-core-phone-view
    *ngFor="let phone of data.phones"
    [data]="phone"
  ></dkm-core-phone-view>
</ng-container>
