import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { FileViewModule } from '../file-view';
import { AvaViewerComponent, IMAGE_LOAD_DISABLED } from './ava-viewer.component';


@NgModule({
  declarations: [
    AvaViewerComponent
  ],
  exports: [
    AvaViewerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FileViewModule
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-files/src/lib/ava-viewer/',
      multi: true
    }
  ]
})
export class AvaViewerModule{
  static forRoot(options: { imageLoadDisabled?: boolean }): ModuleWithProviders<AvaViewerModule> {
    return {
      ngModule: AvaViewerModule,
      providers: [
        { provide: IMAGE_LOAD_DISABLED, useValue: options.imageLoadDisabled }
      ]
    };
  }
}
