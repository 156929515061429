/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { HttpValidationProblemDetails } from '../model/models';
import { KbeRequirements } from '../model/models';
import { KbeRequirementsModel } from '../model/models';
import { ProblemDetails } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface KbeServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param kbeRequirements 
     */
    createKbeRequirements(kbeRequirements?: KbeRequirements, extraHttpRequestParams?: any): Observable<KbeRequirementsModel>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     */
    deleteKbeRequirements(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Provide human readable KBE specification document
     * 
     * @param id 
     */
    downladKbeRequirementsDocument(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     */
    findKbeRequirementsById(id: string, extraHttpRequestParams?: any): Observable<KbeRequirementsModel>;

    /**
     * Return tendered or non-tendered kbe specifications
     * 
     */
    listKbeRequirements(extraHttpRequestParams?: any): Observable<Array<KbeRequirementsModel>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     * @param kbeRequirementsModel 
     */
    updateKbeRequirements(id: string, kbeRequirementsModel?: KbeRequirementsModel, extraHttpRequestParams?: any): Observable<{}>;

}
