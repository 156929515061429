import { Inject, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  CalendarServiceInterface, PageHeader,
  PageHeaderPagedResult,
  PageServiceInterface,
  TagContent,
  TagServiceInterface, UserHeaderModel, UserProfileServiceInterface
} from '@ekon-client/dkm-api';
import {
  CALENDAR_ACTIONS,
  PAGE_ACTIONS,
  TAG_ACTIONS, USERPROFILE_ACTIONS
} from '@ekon-client/dkm-events';
import { get as _get } from 'lodash-es';
import { forkJoin, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';

@Injectable()
export class AutocompleteService {
  constructor(
    @Inject(TAG_ACTIONS) private tagService: TagServiceInterface,
    @Inject(CALENDAR_ACTIONS) private calendarService: CalendarServiceInterface,
    @Inject(USERPROFILE_ACTIONS) private userProfileActions: UserProfileServiceInterface,
    @Inject(PAGE_ACTIONS) private pageActions: PageServiceInterface
  ) { }

  private autocomplete<T>(
    { inputControl, requestAPI, mapPredicate, filterKey }: { inputControl: FormControl; requestAPI: (query: string) => Observable<T[]>; mapPredicate: (item: T) => T; /* remove when server filtering is ready */ filterKey: string; }): Observable<T[]> {
    return inputControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((query: string) =>
        forkJoin({
          query: of(query),
          itemList: requestAPI(query),
        })
      ),
      map(({ query, itemList }: { query: string; itemList: T[] }) =>
        /* remove filter when server filtering is ready */
        itemList
          .filter((item) =>
            _get(item, filterKey).trim().toLowerCase().includes(
              // todo: find out why 'query' becomes type of 'object' after first chip selected
              query /* .trim().toLowerCase() */
            )
          )
          .map(mapPredicate)
      )
    );
  }

  autocompleteTags(inputControl: FormControl): Observable<TagContent[]> {
    return this.autocomplete(
      { inputControl, requestAPI: () => this.tagService.listTags( /* provide 'query' when server-side filtering is ready */), mapPredicate: (item: TagContent) => item, filterKey: 'tag' });
  }

  autocompleteAttendees(inputControl: FormControl): Observable<UserHeaderModel[]> {
    return this.autocomplete(
      { inputControl, requestAPI: () => this.calendarService.getAttendees( /* provide 'query' when server-side filtering is ready */), mapPredicate: (item: UserHeaderModel) => item, filterKey: 'email' });
  }

  autocompleteUsers(inputControl: FormControl): Observable<UserHeaderModel[]> {
    return this.autocomplete(
      {
        inputControl,
        requestAPI: () => this.userProfileActions.listUserProfileHeaders( /* provide 'query' when server-side filtering is ready */).pipe(
          map((response: unknown) => {
            if (typeof response === 'object' && response !== null && 'items' in response) {
              return (response as { items: UserHeaderModel[] }).items;
            }
            return [];
          })
        ),
        mapPredicate: (item: UserHeaderModel) => item, filterKey: 'email'
      });
  }

  autocompletePages(inputControl: FormControl): Observable<PageHeader[]> {
    return this.autocomplete(
      {
        inputControl, requestAPI: () => this.pageActions.listPages(
          {
            pageNumber: 1,
            pageSize: 100,
          }
          /* provide 'query' when server-side filtering is ready */
        ).pipe(map((pageHeaders: PageHeaderPagedResult) => pageHeaders.items)), mapPredicate: (item: PageHeader) => item, filterKey: 'name'
      });
  }
}
