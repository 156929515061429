/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeaturesAction } from './featuresAction';


export interface PermissionGroup { 
    read?: FeaturesAction;
    update?: FeaturesAction;
    create?: FeaturesAction;
    remove?: FeaturesAction;
    invite?: FeaturesAction;
    config?: FeaturesAction;
}

