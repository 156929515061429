import { InjectionToken } from '@angular/core';
import { DltTenderModel } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const DLT_ACTIONS = new InjectionToken('Tender model actions');
export const DLT_EVENTS = new InjectionToken('Tender model events');

export interface DLTEventsServiceInterface {
  itemCreated: Observable<DltTenderModel>;
  itemUpdated: Observable<string>; // tender id
  itemDeleted: Observable<string>; // tender id
}
