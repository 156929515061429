import { InjectionToken } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  BlogPostHeader,
  CalendarItemHeader, DkmDomainHeaderModel,
  LegalEntityHeaderModel,
  PageHeader, ProductHeader,
  UserHeaderModel
} from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export enum WYSIWYG_MODES {
  DEFAULT = 'default',
  MINIMAL = 'minimal'
}

export type WysiwygMode = WYSIWYG_MODES | 'default' | 'minimal';

export class EkonWysiwygAdapterBase {
  placeholder?: string;
  wysiwygControl?: FormControl;
  mode: WysiwygMode = WYSIWYG_MODES.DEFAULT;
  onFocus?: () => void;
}

export const ENTITY_SELECTOR = new InjectionToken('Entity Selector Service');

export interface EntitySelectorAliasInterface {
  selectUser(): Observable<UserHeaderModel>;
  selectMeetup(isPrivate?: boolean): Observable<CalendarItemHeader>;
  selectLegalEntity(): Observable<LegalEntityHeaderModel>;
  selectBlog(): Observable<BlogPostHeader>;
  selectPage(kind?: 'page' | 'product', isTemplate?: boolean, domain?: string): Observable<PageHeader | ProductHeader>;
  selectDomain(isOwnerMode?: boolean): Observable<DkmDomainHeaderModel>;
}
