<ekon-dialog-wrapper>
  <form [formGroup]="form" (ngSubmit)="validateAndSave()" fxLayout="column" class="h-100-p">
    <div mat-dialog-title class="h2 mb-24" fxLayoutAlign="start center">Edit File Meta</div>
    <mat-dialog-content  fxFlex="1 0 auto">
      <div fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name">
          <mat-error>
            <span *ngIf="form?.controls?.name?.errors?.required">Name is required</span>
          </mat-error>
        </mat-form-field>


        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="shortDescription"></textarea>
        </mat-form-field>

        <dkm-tags-autocomplete formControlName="tags"
                               class="mb-24"></dkm-tags-autocomplete>

      </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxFlex="0 0 auto">
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button type="submit" [disabled]="form.pristine || !form.valid">Save</button>
    </mat-dialog-actions>
  </form>
</ekon-dialog-wrapper>
