import { Category, PageHeader } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { EkonFiltersUpdated } from '../extras';

export interface FilterConfig {
  searchProperty?: string;
  filterProperties?: {
    categories?: string;
    tags?: string;
    template?: string;
  };
}

/**
 * @param search$
 */
export const prepareSearchObserver = (
  search$: Observable<string>
): Observable<string> =>
  search$.pipe(
    debounceTime(400),
    distinctUntilChanged(),
    map((query: string) => (query ? query.trim().toLowerCase() : query))
  );

/**
 * @param data
 * @param searchQuery
 * @param filters
 * @param config
 */
export function filterData<T>(
  data: T[],
  searchQuery?: string,
  filters?: EkonFiltersUpdated,
  config?: FilterConfig
): T[] {
  const _config: FilterConfig = {
    searchProperty: 'name',
    ...config,
    filterProperties: {
      categories: 'categories',
      tags: 'tags',
      template: 'template',
      ...(config && config.filterProperties ? config.filterProperties : {}),
    },
  };

  return !data
    ? ([] as T[])
    : data.filter(
        (item: T) =>
          (!searchQuery ||
            (searchQuery &&
              item[_config.searchProperty]
                .trim()
                .toLowerCase()
                .includes(searchQuery))) &&
          (!filters ||
            (filters &&
              (!filters.categories ||
                (filters.categories &&
                  filters.categories.some(
                    (c: Category) =>
                      item[_config.filterProperties.categories] &&
                      item[_config.filterProperties.categories][0] &&
                      c.id === item[_config.filterProperties.categories][0].id
                  ))) &&
              (!filters.tags ||
                (filters.tags &&
                  filters.tags.some(
                    (t: string) =>
                      item[_config.filterProperties.tags] &&
                      item[_config.filterProperties.tags].some(
                        (pt: string) =>
                          pt.trim().toLowerCase() === t.trim().toLowerCase()
                      )
                  ))) &&
              (!filters.templates ||
                (filters.templates &&
                  filters.templates.some(
                    (t: PageHeader) =>
                      item[_config.filterProperties.template] &&
                      item[_config.filterProperties.template] === t.id
                  )))))
      );
}
