import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileMetadata } from '@ekon-client/dkm-api';
import { noop as _noop } from 'lodash-es';

import { FileDataSource } from '../../../extras';
import { FileService } from '../../../services/file.service';
import { FileManagerService } from '../../../services/file-manager.service';
import { FileViewerCarouselComponent } from '../file-viewer/components/file-viewer-carousel/file-viewer-carousel.component';


@Component({
  selector: 'dkm-file-view-dialog',
  templateUrl: './file-view-dialog.component.html',
  styleUrls: ['./file-view-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileViewDialogComponent {
  viewState: FileDataSource;

  constructor(
    private fileService: FileService,
    private fileManager: FileManagerService,
    @Inject(MAT_DIALOG_DATA) public data: {
      selectable: boolean;
      fileType: string;
      initialFileIndex: number;
      specificDomain?: string;
      userFilesOnly?: boolean;
    },
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialogRef<FileViewDialogComponent>
  ) {}

  fileChanged(fileData: FileDataSource): void {
    this.viewState = fileData;
    this.changeDetector.detectChanges();
  }

  downloadFile(file: FileMetadata): void {
    this.fileService.downloadFile(file).subscribe(_noop);
  }

  editFile(file: FileMetadata): void {
    this.fileManager.editFileMeta(file).subscribe(_noop);
  }

  deleteFile(file: FileMetadata, carousel: FileViewerCarouselComponent ): void {
    this.fileManager.deleteFile(file).subscribe({
      next: () => carousel.nextFile()
    });
  }

  fileSelected(fileData: FileDataSource) {
    this.dialog.close(fileData);
  }
}
