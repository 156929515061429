import {
  AbstractControl,
  ControlValueAccessor, FormArray,
  FormGroup,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {
  assignWith as _assignWith,
  every as _every,
  isNil as _isNil,
  isString as _isString,
  reduce as _reduce
} from 'lodash-es';

export abstract class ReusableFormBase<FormDataType>
  implements ControlValueAccessor, Validator {
  static objToValueOrNull<T>(data: T): T | null {
    const d = _assignWith({}, data, (t, n) =>
      !_isNil(n) && (!_isString(n) || n.trim() !== '') ? n : null
    );
    return _every(d, (v) => v === null) ? null : d;
  }

  static validate(_control: FormGroup | FormArray): ValidationErrors | null {
    const errors = _reduce(
      _control.controls,
      (errs, control: AbstractControl, name) => {
        control.errors && (errs[name] = control.errors);
        return errs;
      },
      {}
    );
    return _control.invalid && Object.keys(errors).length ? errors : null;
  }

  private onChange: (data?: FormDataType) => void;
  private onTouched: () => void;

  abstract form: AbstractControl;

  abstract validate(_control: AbstractControl): ValidationErrors | null;
  abstract makeOutputValue(data: FormDataType): FormDataType | null;

  initValueChanges(): void {
    this.form.valueChanges.subscribe({
      next: (data: FormDataType) => {
        this.onChange && this.onChange(data);
        this.onTouched && this.onTouched();
      },
    });
  }

  registerOnChange(fn: (data: FormDataType) => void): void {
    this.onChange = (data: FormDataType) => fn(this.makeOutputValue(data));
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  writeValue(data: FormDataType): void {
    data ? this.form.patchValue(data) : this.form.reset();
  }
}
