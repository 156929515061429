/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KbeKeyValue } from './kbeKeyValue';


/**
 * KBE, Root object, the KBE Specification
 */
export interface KbeRequirementsModel { 
    id?: string;
    /**
     * KBE specification title. Used in UI for listing KBE specification pages, provided by the user.
     */
    title?: string | null;
    /**
     * A short description added by the user about this specification
     */
    description?: string | null;
    inputs?: Array<KbeKeyValue> | null;
    outputs?: Array<KbeKeyValue> | null;
    /**
     * List of product tags
     */
    productTags?: Array<string> | null;
    /**
     * Add by KBE to allow: Run cost assessment and Run Life cycle assessment
     */
    ruleId?: string | null;
}

