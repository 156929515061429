import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const KBESPECIFICATION_ACTIONS = new InjectionToken(
  'KbeSpecification model actions'
);
export const KBESPECIFICATION_EVENTS = new InjectionToken(
  'KbeSpecification model events'
);

export interface KbeRequirementsEventsServiceInterface {
  kbeSpecificationUpdated: Observable<string>;
  kbeSpecificationDeleted: Observable<string>;
}
