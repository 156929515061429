import { Inject,Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { DkmDomain, DomainServiceInterface } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

import { DOMAIN_ACTIONS } from '../DomainEventsServiceInterface';

@Injectable({ providedIn: 'root' })
export class SimpleDomainResolver implements Resolve<DkmDomain> {
  constructor(
    @Inject(DOMAIN_ACTIONS) private domainActions: DomainServiceInterface
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<DkmDomain> {
    return this.domainActions.findDomainById(route.params.domainId);
  }
}
