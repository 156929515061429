import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  ekIconEdit,
  ekIconShareOutline,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { EkonTagsModule } from '@ekon-client/shared/common/ekon-utils';
import { AvaViewerModule } from '@ekon-client/shared/features/dkm-files';
import { TranslateModule } from '@ngx-translate/core';

import { BlogCardComponent } from './components/blog-card/blog-card.component';

@NgModule({
  declarations: [
    BlogCardComponent
  ],
  imports: [
    CommonModule,
    AvaViewerModule,
    MatCardModule,
    EkonIconsModule,
    FlexModule,
    MatButtonModule,
    EkonTagsModule,
    TranslateModule
  ],
  exports: [
    BlogCardComponent
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-blogs/',
      multi: true
    }
  ]
})
export class DkmBlogsModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconEdit,
      ekIconShareOutline,
      ekIconEdit
    );
  }
}
