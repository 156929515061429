import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ekIconDownload,
  ekIconEdit,
  ekIconEllipsisV,
  ekIconGlasses, ekIconSearch, ekIconSorting, ekIconSortingAsc, ekIconSortingDesc, ekIconTimes, ekIconTrashAlt,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { EkonTagsModule, EkonUtilsModule } from '@ekon-client/shared/common/ekon-utils';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FileSharedModule } from '../file-shared';
import { FileViewModule } from '../file-view';
import { FileListComponent } from './file-list.component';


@NgModule({
  declarations: [
    FileListComponent
  ],
  exports: [
    FileListComponent
  ],
    imports: [
        CommonModule,

        MatTooltipModule,
        MatMenuModule,
        MatDividerModule,
        FileSharedModule,
        MatButtonModule,
        MatInputModule,
        NgxDatatableModule,
        EkonUtilsModule,
        FlexLayoutModule,
        EkonIconsModule,
        EkonTagsModule,
        TranslateModule,
        FileViewModule
    ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-files/src/lib/file-list/',
      multi: true
    }
  ]
})
export class FileListModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconGlasses,
      ekIconEllipsisV,
      ekIconEdit,
      ekIconTrashAlt,
      ekIconTimes,
      ekIconDownload,
      ekIconSorting,
      ekIconSortingAsc,
      ekIconSortingDesc,
      ekIconSearch
    );
  }
}
