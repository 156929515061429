import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import {
  ekIconArrowsAlt, ekIconRedo,
  ekIconUpload, ekIconVectorSquare,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ImageCropperModule } from 'ngx-image-cropper';

import { FileUploadControlComponent } from './components/file-upload-control/file-upload-control.component';
import { ImageCropperDialogComponent } from './components/image-cropper-dialog/image-cropper-dialog.component';



@NgModule({
  declarations: [
    FileUploadControlComponent,
    ImageCropperDialogComponent,
  ],
  imports: [
    CommonModule,

    ImageCropperModule,
    NgxFileDropModule,
    MatButtonModule,
    MatDialogModule,
    FlexModule,
    MatTooltipModule,
    EkonDialogsModule,
    EkonIconsModule,
    TranslateModule
  ],
  exports: [
    FileUploadControlComponent,
    ImageCropperDialogComponent,
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-files/src/lib/file-upload/',
      multi: true
    }
  ]
})
export class FileUploadModule {
  constructor(
    ekLib: EkonIconsLibraryService,
    ) {
    ekLib.addIcons(
      ekIconUpload,
      ekIconArrowsAlt,
      ekIconRedo,
      ekIconVectorSquare
    );
  }
}
