<ng-container
  *ngIf="(auth.isLoggedIn$ | async) && data"
>
  <button
    mat-icon-button
    class="cart-menu-toggle"
    *ngIf="!labelEnabled; else labelBtn"
    [matMenuTriggerFor]="cartMenu"
    [matTooltip]="'ADD_TO_BASKET' | translate"
    (click)="$event.stopPropagation()"
  >
    <ekon-icon
      [icon]="ekIconAddCart"
      [size]="iconSize"
    ></ekon-icon>
  </button>
</ng-container>

<ng-template #labelBtn>
  <button
    mat-raised-button
    color="accent"
    class="cart-menu-toggle labeled"
    [ngClass]="{ 'responsive': responsive }"
    [matMenuTriggerFor]="cartMenu"
    [matTooltip]="'ADD_TO_BASKET' | translate"
    (click)="$event.stopPropagation()"
  >
    <ekon-icon
      [icon]="ekIconAddCart"
      [size]="iconSize"
    ></ekon-icon>
    <span>{{'ADD_TO_BASKET' | translate}}</span>
  </button>
</ng-template>

<mat-menu #cartMenu="matMenu">
  <ng-template matMenuContent>
    <ekon-top-list
      [config]="topListConfig"
      (viewAll)="viewBaskets()"
      #topList="ekonTopList"
    >
      <button
        fxLayout="row"
        fxLayoutAlign="space-between center"
        mat-menu-item
        *ngFor="let basket of topList.items$ | async; trackBy: trackByFn"
        (click)="addToBasket(basket)"
      >
        <span>{{basket.name}}</span>
        <span
          class="item-quantity primary ml-8"
          *ngIf="!isMultiple && basket.countProductInBasket"
        >{{basket.countProductInBasket}}</span>
      </button>
    </ekon-top-list>
    <div
      mat-menu-item
      class="accent-200-fg text-center"
      (click)="addToNewBasket()"
    >
      <span>{{'ADD_TO_NEW_BASKET' | translate : { default: 'Add to new basket' } }}</span>
      <ekon-icon [icon]="ekIconPlus" class="ml-8"></ekon-icon>
    </div>
  </ng-template>
</mat-menu>
