import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { map, Observable, switchMap, take } from 'rxjs';

import { EkonAuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EkonAuthGuard implements CanActivate, CanLoad {
  constructor(private authService: EkonAuthService, private router: Router) { }

  canLoad(route: Route, _segments: UrlSegment[]): Observable<boolean | UrlTree> {
    return this.checkAuthentication(route.path);
  }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.checkAuthentication(state.url);
  }

  private checkAuthentication(path: string): Observable<boolean | UrlTree> {
    console.log("EkonAuthGuard checkAuthentication path: " + path);
    return this.authService.user$.pipe(
      take(1), // Only take the first emitted value
      switchMap(user => {
        if (user) {
          console.log("EkonAuthGuard checkAuthentication has user true path: " + path);
          return this.authService.isLoggedIn$.pipe(take(1));
        }
        console.log("EkonAuthGuard checkAuthentication has user false path: " + path);
        return this.authService.loadUserProfileFromStorageAndUpdate(); // Ensure profile is loaded from storage
      }),
      map(isLoggedIn => {
        if (!isLoggedIn && path !== '/landing') {
          console.log("EkonAuthGuard checkAuthentication redirecting to landing, path: " + path);
          return this.router.createUrlTree(['/landing']); // Redirect to landing if not logged in
        }
        else if (isLoggedIn && path === '/landing') {
          console.log("EkonAuthGuard checkAuthentication redirecting to dashboard, path: " + path);
          return this.router.createUrlTree(['/dashboard']); // Redirect to dashboard if logged in
        }

        console.log("EkonAuthGuard checkAuthentication allow access to path: " + path);
        return true; // Allow navigation if logged in
      })
    );
  }
}
