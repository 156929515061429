import { ChangeDetectionStrategy, Component, EventEmitter,Input, Output } from '@angular/core';
import { PageHeader, ProductHeader } from '@ekon-client/dkm-api';
import { ekIconTimes } from '@ekon-client/shared/common/ekon-icons';
import { PaginatorConfig, TopListConfig } from '@ekon-client/shared/common/ekon-pagination';

@Component({
  selector: 'ekon-page-list-mini',
  templateUrl: './page-list-mini.component.html',
  styleUrls: ['./page-list-mini.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageListMiniComponent {
  ekIconTimes = ekIconTimes;

  @Input() itemList: Array<PageHeader | ProductHeader>;
  @Input() topListConfig: TopListConfig;
  @Input() paginatorConfig: PaginatorConfig;
  @Input() multiColumn: boolean;
  @Input() displayRemoveBtn: boolean;
  @Input() viewAllBtnAlwaysHidden: boolean;
  @Input() viewAllBtnAlwaysDisplay: boolean;
  @Input() displayRating: boolean;

  @Output() selectItem: EventEmitter<PageHeader | ProductHeader> = new EventEmitter();
  @Output() removeItem: EventEmitter<PageHeader | ProductHeader> = new EventEmitter();
  @Output() viewAll: EventEmitter<PageHeader | ProductHeader> = new EventEmitter();

  goToPage(item: PageHeader | ProductHeader) {
    this.selectItem.emit(item);
  }

  emitViewAll() {
    this.viewAll.emit();
  }
}
