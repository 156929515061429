import { InjectionToken } from '@angular/core';
import { BlogPostDetails, Comment } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const BLOG_ACTIONS = new InjectionToken('Blog model actions');
export const BLOG_EVENTS = new InjectionToken('Blog model events');

export interface BlogEventsServiceInterface {
  blogPostCreated: Observable<BlogPostDetails>;
  blogPostDeleted: Observable<string>;
  blogPostUpdated: Observable<string>;
  commentCreated: Observable<Comment>;
}
