import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RateDetails, RateServiceInterface, RefType } from '@ekon-client/dkm-api';
import { RATE_ACTIONS } from '@ekon-client/dkm-events';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RatingService {
  constructor(
    @Inject(RATE_ACTIONS) private rateActions: RateServiceInterface
  ) { }

  getRate(refId: string): Observable<RateDetails> {
    return this.rateActions.findRateByRefId(refId);
  }

  getAverageRate(refId: string): Observable<number> {
    return this.rateActions.averageRateByRefId(refId);
  }

  setRate(
    rate: number,
    refId: string,
    refType: RefType,
  ): Observable<unknown> {
    return this.getRate(refId).pipe(
      switchMap((rateDetails?: RateDetails) => this.rateActions.updateRate(
        rateDetails.id,
        { ...rateDetails, rate }
      )),
      catchError((error: HttpErrorResponse) => error.status === 404 ? this.rateActions.createRate({
        rate,
        referrerId: refId,
        usageRefType: refType
      }) : throwError(error) )
    )
  }
}
