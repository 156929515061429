import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  CreateFeedback,
  FeedbackDetails,
  FeedbackDetailsPagedResult,
  FeedbackService,
  FeedbackServiceInterface, PagePaginationModel
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FeedbackEventsServiceInterface } from './FeedbackEventsServiceInterface';

@Injectable()
export class FeedbackEventsService
  implements FeedbackServiceInterface, FeedbackEventsServiceInterface {
  get defaultHeaders(): HttpHeaders {
    return this.feedbackService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.feedbackService.defaultHeaders = val;
  }

  get configuration(): Configuration {
    return this.feedbackService.configuration;
  }

  set configuration(val: Configuration) {
    this.feedbackService.configuration = val;
  }

  private feedbackItemCreatedSubject: Subject<FeedbackDetails> = new Subject();
  get feedbackItemCreated(): Observable<FeedbackDetails> {
    return this.feedbackItemCreatedSubject.asObservable();
  }

  private feedbackItemUpdatedSubject: Subject<string> = new Subject();
  get feedbackItemUpdated(): Observable<string> {
    return this.feedbackItemUpdatedSubject.asObservable();
  }

  private feedbackItemDeletedSubject: Subject<string> = new Subject();
  get feedbackItemDeleted(): Observable<string> {
    return this.feedbackItemDeletedSubject.asObservable();
  }

  constructor(
    private feedbackService: FeedbackService,
    private progressBar: EkonProgressBarService
  ) { }

  listAllFeedbacks(
    pagination: PagePaginationModel,
    xSelectedDomain?: string,
  ): Observable<FeedbackDetailsPagedResult> {
    return this.progressBar.apply(this.feedbackService.listAllFeedbacks(
      pagination,
      xSelectedDomain
    ));
  }

  listDomainsFeedbacks(
    pagination: PagePaginationModel,
    xSelectedDomain?: string,
  ): Observable<FeedbackDetailsPagedResult> {
    return this.progressBar.apply(this.feedbackService.listDomainsFeedbacks(
      pagination,
      xSelectedDomain
    ));
  }

  listFeedbackQuestions(): Observable<string[]> {
    return this.progressBar.apply(this.feedbackService.listFeedbackQuestions());
  }

  createFeedback(createFeedback: CreateFeedback): Observable<FeedbackDetails> {
    return this.progressBar.apply(
      this.feedbackService.createFeedback(createFeedback).pipe(
        tap({
          next: (fb: FeedbackDetails) =>
            this.feedbackItemCreatedSubject.next(fb),
        })
      )
    );
  }

  deleteFeedback(id: string): Observable<unknown> {
    return this.progressBar.apply(
      this.feedbackService.deleteFeedback(id).pipe(
        tap({
          next: () => this.feedbackItemDeletedSubject.next(id),
        })
      )
    );
  }

  findFeedbackById(id: string): Observable<FeedbackDetails> {
    return this.progressBar.apply(this.feedbackService.findFeedbackById(id));
  }

  listUsersFeedbacks(
    pagination: PagePaginationModel,
    xSelectedDomain?: string
  ): Observable<FeedbackDetailsPagedResult> {
    return this.progressBar.apply(this.feedbackService.listUsersFeedbacks(
      pagination,
      xSelectedDomain
    ));
  }

  submitFeedback(id: string): Observable<unknown> {
    return this.progressBar.apply(this.feedbackService.submitFeedback(id));
  }

  updateFeedback(id: string, feedbackDetails: FeedbackDetails): Observable<unknown> {
    return this.progressBar.apply(
      this.feedbackService.updateFeedback(id, feedbackDetails).pipe(
        tap({
          next: () => this.feedbackItemUpdatedSubject.next(id),
        })
      )
    );
  }
}
