import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration, PageRateDistributionResult,
  StatisticsService, StatisticsServiceInterface, TagsWordCloudResults
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable } from 'rxjs';

import { StatisticsEventsServiceInterface } from './StatisticsEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class StatisticsEventsService
  implements StatisticsServiceInterface, StatisticsEventsServiceInterface {
  get configuration(): Configuration {
    return this.statisticsService.configuration;
  }

  set configuration(val: Configuration) {
    this.statisticsService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.statisticsService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.statisticsService.defaultHeaders = val;
  }

  _noop: unknown;

  constructor(
    private statisticsService: StatisticsService,
    private progressBar: EkonProgressBarService
  ) {
  }


  pagesTagsWordCloud(xSelectedDomain?: string): Observable<TagsWordCloudResults> {
    return this.progressBar.apply(
      this.statisticsService.pagesTagsWordCloud(xSelectedDomain)
    );
  }

  pagesRateDistribution(xSelectedDomain?: string): Observable<PageRateDistributionResult> {
    return this.progressBar.apply(
      this.statisticsService.pagesRateDistribution(xSelectedDomain)
    );
  }
}
