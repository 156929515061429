import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class EkonDialogHolderService {

  currentCloseData: unknown = true;

  routerCommands: string[] | undefined;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
  ) { }


  close(data?: unknown, redirectTo?: string[]): void {
    this.currentCloseData = data;

    this.router.navigate(redirectTo || this.routerCommands || ['../'], {
      relativeTo: this.route,
    });
  }
}
