<h2 class="m-0 mt-8 text-truncate">
  {{title}}
  <ng-container
    *ngIf="canUpdate"
  >
    <small
      class="text-boxed mr-8 ekon-accent-bg"
      *ngIf="isTemplate"
    >Template</small>
    <small
      class="text-boxed mr-8 cursor-pointer"
      [ngClass]="{ 'ekon-warn-bg' : visibleTo === VisibilityTypes.Private }"
      (click)="setSharingOptions.emit()"
      matTooltip="Press to change visibility"
    >{{visibleTo === VisibilityTypes.Internal ? 'Within ' + (globalConfig.domainTitleSingular | uppercase | translate | lowercase) : visibleTo}}</small>
  </ng-container>
</h2>
