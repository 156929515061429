import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import { ekIconStickyNote, EkonIconsLibraryService, EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';
import { EkonPaginationModule } from '@ekon-client/shared/common/ekon-pagination';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { EkonWysiwygModule } from '@ekon-client/shared/common/ekon-wysiwyg';
import { DkmAutocompletionModule } from '@ekon-client/shared/features/dkm-autocompletion';
import { TranslateModule } from '@ngx-translate/core';

import { NoteAddEditDialogComponent } from './components/note-add-edit-dialog/note-add-edit-dialog.component';
import { NoteTodayListComponent } from './components/note-today-list/note-today-list.component';

@NgModule({
  declarations: [
    NoteAddEditDialogComponent,
    NoteTodayListComponent,
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    EkonDialogsModule,
    ReactiveFormsModule,
    FlexModule,
    EkonPaginationModule,
    EkonIconsModule,
    DkmAutocompletionModule,
    TranslateModule,
    MatButtonToggleModule,
    MatNativeDateModule,
    EkonWysiwygModule
  ],
  exports: [
    NoteTodayListComponent,
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-notes/',
      multi: true
    }
  ]
})
export class DkmNotesModule {
  constructor(ekLib: EkonIconsLibraryService) {
    ekLib.addIcons(
      ekIconStickyNote
    )
  }
}
