/* eslint-disable rxjs/no-ignored-subscription */
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { EkonAuthService, OidcUser } from '@ekon-client/auth';
import { UserProfile, UserProfileServiceInterface } from '@ekon-client/dkm-api';
import { USERPROFILE_ACTIONS, USERPROFILE_EVENTS, UserProfileEventsServiceInterface } from '@ekon-client/dkm-events';
import { TermsOfUseService } from '@ekon-client/shared/features/dkm-tou';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private _currentUser$: BehaviorSubject<UserProfile> = new BehaviorSubject(null);
  get currentUser$(): Observable<UserProfile> {
    return this._currentUser$.asObservable();
  }

  private _userSet$: Subject<UserProfile> = new Subject();
  get userSet$(): Observable<UserProfile> {
    return this._userSet$.asObservable()
      .pipe(filter(u => u !== null));
  }

  constructor(
    private tou: TermsOfUseService,
    private authService: EkonAuthService,
    @Inject(USERPROFILE_ACTIONS) private userProfileActions: UserProfileServiceInterface,
    @Inject(USERPROFILE_EVENTS) private userProfileEvents: UserProfileEventsServiceInterface
  ) {
    merge(
      this.authService.isLoggedIn$,
      this.userProfileEvents.userProfileUpdated
    ).pipe(
      switchMap(() => this.authService.user$),
      switchMap((user: OidcUser) => user ? this.userProfileActions.getUserProfile()
        // eslint-disable-next-line lodash/prefer-constant
        .pipe(switchMap((user: UserProfile) => {
          if (user?.touAccepted) {
            return of(user);
          }
          else {
            return this.authService.refreshTokenAndUpdateUser().pipe(
              switchMap(() => this.authService._isLoggedIn$),
              filter(isLoggedIn => isLoggedIn),
              switchMap(() => this.userProfileActions.getUserProfile()));
          }
        }))
        : of(null)),
      switchMap((user: UserProfile) => user ? this.tou.checkTermsOfUse(user)
        .pipe(map((touAccepted: boolean) => touAccepted
          ? { ...user, touAccepted: true }
          : null
        )) : of(null)
      ),
      tap((userProfile: UserProfile) => {
        this._currentUser$.next(userProfile);
        this._userSet$.next(userProfile);
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
