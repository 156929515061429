import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  Input,
  TemplateRef,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { trackById } from '@ekon-client/shared/common/ekon-utils';

import { ReusableFormBase } from '../../ReusableFormBase';

@Component({
  selector: 'dkm-core-controls-set',
  templateUrl: './controls-set.component.html',
  styles: [
    `
      :host {
        display: block;
      }

      .set-divider {
        position: relative;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControlsSetComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ControlsSetComponent),
      multi: true,
    },
  ],
})
export class ControlsSetComponent extends ReusableFormBase<unknown[]> {
  @Input() title: string;
  @Input() buttonLabel = 'Add';
  @Input() inlineControls: boolean;

  @ContentChild(TemplateRef) cChild: TemplateRef<unknown>;

  form: FormArray = new FormArray([]);
  trackById = trackById;

  constructor() {
    super();
    this.initValueChanges();
  }

  get controls(): AbstractControl[] {
    return this.form.controls;
  }

  addControl(): void {
    this.form.insert(0, new FormControl());
  }

  deleteControl(i: number): void {
    this.form.removeAt(i);
  }

  validate(/*_control: AbstractControl*/): ValidationErrors | null {
    return ReusableFormBase.validate(this.form);
  }

  makeOutputValue(data: unknown[]): unknown[] {
    data = data.filter((v) => v !== null);
    return data.length ? data : null;
  }

  writeValue(data: unknown[]): void {
    data = data === null ? [] : data;

    // todo: improve with adding/removing expecting controls
    //  instead of clearing/adding new
    this.form.clear();
    data.forEach((v) => this.form.push(new FormControl(v)));
    // super.writeValue(data);
  }
}
