import { UserProfile, UserProfileHeader, ViewUserProfile } from '@ekon-client/dkm-api';

/**
 * @param user
 */
export const getUserName = (user: UserProfileHeader): string =>
  user ? (user.givenName || user.lastName
    ? (user.givenName ? user.givenName + ' ' : '') + (user.lastName ? user.lastName : '')
    : user.email) : '';

/**
 * @param users
 * @param uID
 */
export function getUserNameFromList(
  users: UserProfileHeader[],
  uID: string
): string {
  const user = users.find(
    (u: UserProfileHeader) => u && uID === u.id
  ) as UserProfileHeader;
  if (!user) {
    return 'unknown';
  }
  return getUserName(user);
}

/**
 * @param userProfile
 */
export function getUserFullName(userProfile: UserProfile | ViewUserProfile): string | null {
  let name = '';

  if (userProfile.givenName) {
    name +=
      userProfile.givenName +
      (userProfile.middleName || userProfile.lastName ? ' ' : '');
  }

  if (userProfile.middleName) {
    name += userProfile.middleName + (userProfile.lastName ? ' ' : '');
  }

  if (userProfile.lastName) {
    name += userProfile.lastName;
  }

  return name === '' ? null : name;
}
