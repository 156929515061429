import { ChangeDetectionStrategy, Component, Inject,TrackByFunction } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BasketHeader,
  BasketHeaderWithProductCount,
  BasketServiceInterface
} from '@ekon-client/dkm-api';
import { BASKET_ACTIONS } from '@ekon-client/dkm-events';
import { PaginationModel, PaginatorConfig } from '@ekon-client/shared/common/ekon-pagination';

export interface BasketSelectDialogComponentData {
  productId: string;
}

export interface BasketSelectDialogComponentResult {
  selected: BasketHeader | BasketHeader[];
}

@Component({
  templateUrl: './basket-select-dialog.component.html',
  styleUrls: ['./basket-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasketSelectDialogComponent {
  trackByFn: TrackByFunction<BasketHeaderWithProductCount> = (index, item) => item.id;

  paginatorConfig: PaginatorConfig = {
    loader: (pagination: PaginationModel) =>
      this.basketActions.listBasketsWithProductPageCount(
        pagination,
        true,
        null,
        this.data.productId
      )
  };

  constructor(
    private dialogRef: MatDialogRef<BasketSelectDialogComponent, BasketSelectDialogComponentResult>,
    @Inject(MAT_DIALOG_DATA) private data: BasketSelectDialogComponentData,
    @Inject(BASKET_ACTIONS) private basketActions: BasketServiceInterface,
  ) { }

  selectNClose(basket: BasketHeaderWithProductCount): void {
    this.dialogRef.close({
      selected: basket
    })
  }
}
