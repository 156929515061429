import {
  AfterViewInit,
  Directive,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Directive({
  selector: 'ngx-datatable[ekonDatatableFlex]',
})
export class EkonDatatableFlexDirective implements AfterViewInit {
  @Input() ekonDatatableFlex: DatatableComponent;

  hostElement: HTMLElement;

  constructor(vcr: ViewContainerRef) {
    this.hostElement = vcr.element.nativeElement as HTMLElement;
    this.hostElement.style.opacity = '0';
    this.hostElement.style.transition = 'all .5s ease';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ekonDatatableFlex.recalculate();
      this.hostElement.style.opacity = '1';
    }, 500);
    // setTimeout(() => window.dispatchEvent(new Event('resize')), 300)
  }
}
