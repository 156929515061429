<!-- HEADER -->
<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="mb-24"
>
  <div class="mat-subheading-2 mb-0">{{globalConfig.domainTitlePlural | uppercase | translate | titlecase}}</div>

  <!-- todo: use 'plus' instead -->
  <button
    mat-button color="accent"
    (click)="joinDomain()"
  >Join
  </button>
</div>
<!-- / HEADER -->

<!-- CONTENT -->
<ul *ngIf="availableDomains | async as domains">
  <li *ngFor="let domain of domains" fxLayout="row" fxLayoutAlign="start center" class="my-8">
    <dkm-core-ava-viewer
      fxFlex="0 0 auto"
      [fileLink]="domain.thumbprintUrl"
    ></dkm-core-ava-viewer>
    <div class="pl-16" fxFlex="1 1 100%">{{domain.name}}</div>
    <button
      fxFlex="0 0 auto"
      mat-button color="accent"
      (click)="leaveDomain(domain)"
    >Leave
    </button>
  </li>
</ul>
<!-- / CONTENT -->

<button
  *ngIf="limit"
  mat-button
  color="primary"
  class="view-all-btn"
  routerLink="/my-space/domains"
>{{'VIEW_ALL' | translate}}</button>
