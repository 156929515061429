import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { FilesizePipe } from './pipes/filesize.pipe';

@NgModule({
  declarations: [
    FilesizePipe
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    FilesizePipe
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-files/src/lib/file-shared/',
      multi: true
    }
  ]
})
export class FileSharedModule { }
