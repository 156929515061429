import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LegalEntityDetails } from '@ekon-client/dkm-api';

@Component({
  selector: 'dkm-core-legal-entity-card',
  templateUrl: './legal-entity-card.component.html',
  styleUrls: ['./legal-entity-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalEntityCardComponent {
  @Input() data: LegalEntityDetails;
  @Input() deleteEnabled: boolean;
  @Input() editEnabled: boolean;
  @Input() viewEnabled: boolean;
  @Input() disableAllActions: boolean;

  @Output() viewEntity: EventEmitter<string> = new EventEmitter();
  @Output() editEntity: EventEmitter<string> = new EventEmitter();
  @Output() deleteEntity: EventEmitter<string> = new EventEmitter();
  @Output() select: EventEmitter<LegalEntityDetails> = new EventEmitter();


  callViewEntity(): void {
    this.viewEntity.emit(this.data.id);
  }

  callEditEntity(): void {
    this.editEntity.emit(this.data.id);
  }

  callDeleteEntity(): void {
    this.deleteEntity.emit(this.data.id);
  }

  selectEntity(): void {
    this.select.emit(this.data);
  }
}
