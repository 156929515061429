import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ekIconChat, EkonIconsLibraryService, EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { ChatBotComponent } from './components/chatbot/chat-bot.component';
import { ChatbotSettingsComponent } from './components/chatbot-settings/chatbot-settings.component';

@NgModule({
  declarations: [
    ChatBotComponent,
    ChatbotSettingsComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    FlexModule,
    EkonIconsModule,
    MatSlideToggleModule,
    TranslateModule
  ],
  exports: [
    ChatBotComponent,
    ChatbotSettingsComponent
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-chatbot/',
      multi: true
    }
  ]
})
export class DkmChatbotModule {
  constructor(library: EkonIconsLibraryService) {
    library.addIcons(
      ekIconChat
    );
  }
}
