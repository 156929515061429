<mat-form-field [appearance]="outlineStyle ? 'outline' : 'legacy'" class="w-100-p">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-chip-list
    #chipList
    aria-label="tags selection"
    [disabled]="disabled"
    class="dkm-chips"
  >
    <mat-chip
      *ngFor="let item of items"
      [selectable]="true"
      [removable]="true"

      (removed)="removeItem(item)"
    >
      {{item}}
      <ekon-icon matChipRemove icon="times-circle" size="lg"></ekon-icon>
    </mat-chip>
    <input
      [placeholder]="placeholder ? placeholder : ('ADD_TAG' | translate)"
      #itemInput
      [formControl]="inputControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addItem($event)">
  </mat-chip-list>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="autoCompleteItemSelected($event)"
  >
    <mat-option *ngFor="let item of filtered | async" [value]="item">
      {{item}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
