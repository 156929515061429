/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Address { 
    /**
     * Optional use, in cases of list of addresses such as: headquter, shipping, legal-person, etc. if
     */
    addressName?: string | null;
    /**
     * Shared address for elements usage.
     */
    country?: string | null;
    city?: string | null;
    street?: string | null;
    number?: string | null;
    postCode?: string | null;
}

