import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import {
  ekIconGlasses,
  ekIconPlus,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EkonPaginationModule } from '@ekon-client/shared/common/ekon-pagination';
import { EkonSliderModule } from '@ekon-client/shared/common/ekon-slider';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { EkonUtilsModule } from '@ekon-client/shared/common/ekon-utils';
import { AvaViewerModule } from '@ekon-client/shared/features/dkm-files';
import { TranslateModule } from '@ngx-translate/core';

import { DomainCardComponent } from './components/domain-card/domain-card.component';
import { DomainSelectorComponent } from './components/domain-selector/domain-selector.component';
import { DomainSelectorControlComponent } from './components/domain-selector-control/domain-selector-control.component';
import { DomainSelectorDialogComponent } from './components/domain-selector-dialog/domain-selector-dialog.component';
import { DomainsBarComponent } from './components/domains-bar/domains-bar.component';
import { DomainsJoinDialogComponent } from './components/domains-join-dialog/domains-join-dialog.component';
import { DomainsListComponent } from './components/domains-list/domains-list.component';


@NgModule({
  declarations: [
    DomainSelectorComponent,
    DomainSelectorDialogComponent,
    DomainsListComponent,
    DomainsJoinDialogComponent,
    DomainsBarComponent,
    DomainSelectorControlComponent,
    DomainCardComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatDialogModule,
    MatListModule,
    FlexModule,
    MatButtonModule,
    MatTooltipModule,
    AvaViewerModule.forRoot({
      imageLoadDisabled: false
    }),
    EkonUtilsModule,
    EkonDialogsModule,
    RouterModule,
    MatCardModule,
    MatPaginatorModule,
    EkonPaginationModule,
    MatBadgeModule,
    EkonSliderModule,
    MatRippleModule,
    EkonIconsModule,
    TranslateModule
  ],
  exports: [
    DomainsListComponent,
    DomainSelectorComponent,
    DomainsBarComponent,
    DomainSelectorControlComponent,
    DomainCardComponent
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-domains/',
      multi: true
    }
  ]
})
export class DkmDomainsModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconGlasses,
      ekIconPlus,
    );
  }
}
