<div [formGroup]="form">
  <div class="search-n-filter">
    <mat-form-field class="search-panel" tourAnchor="filter.search">
      <input
        #searchInput
        formControlName="search"
        matInput
        [placeholder]="'SEARCH' | translate"
      >
      <button
        mat-icon-button
        matSuffix
        (click)="clearSearchInput()"
      >
        <ekon-icon [icon]="searchInput.value ? 'times' : 'search'"></ekon-icon>
      </button>
    </mat-form-field>

    <div
      class="filter-btns"
      tourAnchor="filter.field"
      *ngIf="(this.filteringEnabled$ | async) || (this.sortingEnabled$ | async)"
    >
      <mat-button-toggle-group
        appearance="legacy"
        [formControl]="btnToggleGroup"
        (change)="btnToggleGroup.setValue(null)"
        *ngIf="this.sortingEnabled$ | async"
      >
        <mat-button-toggle [matMenuTriggerFor]="sortingMenu">
          <ng-container *ngIf="sortingType$ | async as type">
            <ekon-icon [icon]="type.icon"></ekon-icon>
            <span class="ml-8">{{ type.label }}</span>
          </ng-container>
          <ekon-icon [icon]="ekIconChevronDown" class="ml-8"></ekon-icon>
        </mat-button-toggle>

        <mat-button-toggle (click)="toggleSortingDir()" tourAnchor="filter.sort">
          <ekon-icon [icon]="ekIconSorting"></ekon-icon>
          <ekon-icon
            [icon]="(sortingDirection$ | async) === SortingDirection.ASC ? ekIconSortingAsc : ekIconSortingDesc"
            size="lg"
          ></ekon-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-menu #sortingMenu="matMenu">
        <ng-template matMenuContent>
          <button
            *ngFor="let type of sortingTypes$ | async"
            mat-menu-item
            (click)="selectSortingType(type)"
          >
            <ekon-icon [icon]="type.icon"></ekon-icon>
            <span class="ml-8">{{ type.label }}</span>
          </button>
        </ng-template>
      </mat-menu>

      <button
        tourAnchor="filter.hide"
        mat-icon-button
        (click)="filtersOpened = !filtersOpened"
        [color]="filtersOpened ? 'primary' : undefined"
        [matTooltip]="(filtersOpened ? 'HIDE_FILTERS' : 'SHOW_FILTERS') | translate"
        *ngIf="this.filteringEnabled$ | async"
      >
        <ekon-icon icon="filter" size="lg"></ekon-icon>
        <div
          class="count-badge ekon-primary-bg"
          *ngIf="filtersCount$ | async as count"
        >{{count}}</div>
      </button>
      <ekon-client-tour-launch
        tourKey="filter_search"
        tourAnchor="filter.combine"
      >
      </ekon-client-tour-launch>
    </div>
  </div>

  <div *ngIf="filtersOpened" class="filters">
    <!--Filter by Tags-->
    <div class="filters-item filters-tag" *ngIf="!tagsFilterDisabled" tourAnchor="filter.tags">
      <dkm-tags-autocomplete
        formControlName="tags"
        [outlineStyle]="false"
        #tagsAutocomplete
        fxFlex="1 1 100%"
        [placeholder]="'SELECT_TAG' | translate"
      ></dkm-tags-autocomplete>
      <button
        tourAnchor="filter.and"
        mat-button
        mat-icon-button
        [ngClass]="form.get('tagFilterMode').value ? 'ekon-primary-bg' : null"
        (click)="toggleTagFilterMode()"
        aria-label="'and' mode"
        matTooltip="'and' mode"
        fxFlex="0 0 auto"
        class="tag-filter-mode"
      >&
      </button>
      <button
        tourAnchor="filter.close"
        mat-button mat-icon-button
        (click)="tagsAutocomplete.clear()"
        aria-label="Clear tags"
        fxFlex="0 0 auto"
      >
        <ekon-icon icon="times"></ekon-icon>
      </button>
    </div>

    <!--Filter by Categories-->
    <div class="filters-item filters-category" *ngIf="!categoriesFilterDisabled">
      <mat-form-field class="example-full-width">
        <mat-label>Category</mat-label>
        <input
          type="text" matInput disabled
          [value]="(categoriesSelectedLabel | async)"
        >
      </mat-form-field>
      <button mat-button mat-icon-button
              (click)="openCategorySelector()"
              aria-label="Clear">
        <ekon-icon icon="chevron-down"></ekon-icon>
      </button>
      <button mat-button mat-icon-button
              (click)="clearCategoriesSelected()"
              aria-label="Clear categories">
        <ekon-icon icon="times"></ekon-icon>
      </button>
    </div>

  </div>

</div>
