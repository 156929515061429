import { Component, Input, OnInit } from '@angular/core';
import { ReferrerEntityMeta } from '@ekon-client/shared/features/dkm-comments';
import { Observable } from 'rxjs';

import { RatingService } from '../../services/rating.service';

@Component({
  selector: 'dkm-rating-average',
  templateUrl: './rating-average.component.html',
  styleUrls: ['./rating-average.component.scss'],
})
export class RatingAverageComponent implements OnInit {
  @Input() refEntityMeta: ReferrerEntityMeta;

  rate: Observable<number>;

  constructor(private ratingService: RatingService) {}

  ngOnInit(): void {
    this.rate = this.ratingService.getAverageRate(this.refEntityMeta.id);
  }
}
