import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTreeModule } from '@angular/material/tree';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import {
  ekIconAngleRight,
  ekIconChevronDown,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { CategoriesSelectorDialogComponent } from './components/categories-selector-dialog/categories-selector-dialog.component';

@NgModule({
  declarations: [
    CategoriesSelectorDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatTreeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRippleModule,
    EkonDialogsModule,
    FlexModule,
    EkonIconsModule,
    TranslateModule
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-categories/',
      multi: true
    }
  ]
})
export class DkmCategoriesModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconAngleRight,
      ekIconChevronDown
    );
  }
}
