import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Comment,
  CommentService,
  CommentServiceInterface,
  Configuration, Results
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { CommentEventsServiceInterface } from './CommentEventsServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class CommentEventsService
  implements CommentServiceInterface, CommentEventsServiceInterface {
  get configuration(): Configuration {
    return this.commentsService.configuration;
  }

  set configuration(val: Configuration) {
    this.commentsService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.commentsService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.commentsService.defaultHeaders = val;
  }

  /**
   * The mechanism for monitoring Delete action
   */
  private commentDeletedSubject: Subject<string> = new Subject();

  get commentDeleted(): Observable<string> {
    return this.commentDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Update action
   */
  private commentUpdatedSubject: Subject<string> = new Subject();

  get commentUpdated(): Observable<string> {
    return this.commentUpdatedSubject.asObservable();
  }

  constructor(
    private commentsService: CommentService,
    private progressBar: EkonProgressBarService
  ) { }

  deleteComment(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.commentsService.deleteComment(id))
      .pipe(tap(() => this.commentDeletedSubject.next(id)));
  }

  findCommentById(id: string): Observable<Comment> {
    return this.progressBar.apply(this.commentsService.findCommentById(id));
  }

  listEntityComment(refId: string): Observable<Comment[]> {
    return this.progressBar.apply(
      this.commentsService.listEntityComment(refId)
    ).pipe(
      catchError((error: HttpErrorResponse) =>
        error.status === 404 ? of([]) : throwError(error)
      )
    );
  }

  listUserComments(): Observable<Comment[]> {
    return this.progressBar.apply(this.commentsService.listUserComments());
  }

  updateComment(id: string, comment: Comment): Observable<unknown> {
    return this.progressBar
      .apply(this.commentsService.updateComment(id, comment))
      .pipe(tap(() => this.commentUpdatedSubject.next(id)));
  }

  getEntityCommentsCount(refId: string): Observable<Results> {
    return this.progressBar.apply(
      this.commentsService.getEntityCommentsCount(refId)
    );
  }
}
