import { ChangeDetectionStrategy, Component, OnDestroy,OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { SidebarService } from '../../../services';

@Component({
  selector: 'ekon-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileNavComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private router: Router,
    public sidebarService: SidebarService,
    public globalConfig: GlobalConfigurationService,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        switchMap(() => this.sidebarService.get('mobileNav').pipe(
          take(1)
        )),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((navbar) => {
        if (navbar && navbar.visible) {
          this.sidebarService.hide('mobileNav');
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
