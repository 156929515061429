<ngx-datatable
  [ekonDatatableFlex]="filesDataTable"
  #filesDataTable
  *ngIf="data"
  class="material"
  [columnMode]="ColumnMode.flex"
  [footerHeight]="50"
  rowHeight="auto"
  [rows]="data.files"
  [loadingIndicator]="true"
  [selectionType]="SelectionType.single"
  trackByProp="id"
>
  <!-- Row Detail Template -->
  <ngx-datatable-row-detail>
    <ng-template let-file="row" let-expanded="expanded" ngx-datatable-row-detail-template>
      <div fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign="stretch top" fxLayoutGap="24px"
           class="w-100-p px-12">
        <div fxFlex="0 0 40%" fxFlex.lt-sm="1 1 auto" fxFlexOrder.lt-sm="1" fxFlexOrder.lt-md="2"
             class="p-24"
             fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
          <ekon-file-viewer
            [fileData]="{ file: file, user: null }"
            [hideCredentials]="true"
            class="w-100-p h-300"
          ></ekon-file-viewer>
        </div>
        <div fxFlex="0 0 60%" fxFlex.lt-sm="1 1 auto" fxFlex.lt-md="1 1 auto" fxFlexOrder.lt-sm="3"
             fxFlexOrder.lt-md="3"
             class="py-24">
          <div class="mb-24 px-16">
            <div *ngIf="!(file.shortDescription || file.description)">
              <em>Description not provided</em>
            </div>
            <div class="mat-body-2" *ngIf="file.shortDescription">
              <div class="mat-caption">Short description</div>
              <div>{{file.shortDescription}}</div>
            </div>
            <div class="mat-body-1" *ngIf="file.description">
              <div class="mat-caption">Description</div>
              <div>{{file.description}}</div>
            </div>
            <ekon-tags [tags]="file.tags"></ekon-tags>
          </div>

          <table class="meta-grid mb-16">
            <tbody>
            <tr>
              <td>Initial name</td>
              <td>{{file.fileName}}</td>
            </tr>
            <tr>
              <td>File size</td>
              <td>{{file.fileSize | filesize}}</td>
            </tr>
            </tbody>
          </table>

          <mat-divider class="mx-12"></mat-divider>

          <table class="meta-grid mt-16">
            <thead>
            <tr>
              <th></th>
              <th>At</th>
              <th>By</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Modified</td>
              <td>{{file.lastModifiedAt | date:'dd.MM.yyyy - HH:mm'}}</td>
              <td>{{getUserName(data.users, file.lastModifiedBy)}}</td>
            </tr>
            <tr>
              <td>Uploaded</td>
              <td>{{file.uploadedAt | date:'dd.MM.yyyy - HH:mm'}}</td>
              <td>{{getUserName(data.users, file.uploadedBy)}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </ng-template>
  </ngx-datatable-row-detail>


  <!-- Column Templates -->
  <!--*ngIf="!(isDesktop | async)"-->
  <ngx-datatable-column
    [width]="36"
    [resizeable]="false"
    [sortable]="false"
    [draggable]="false"
    [canAutoResize]="false"
    headerClass="no-padding-cell"
    cellClass="no-padding-cell centered-cell"
  >
    <ng-template let-row="row" let-expanded="expanded" let-details="rowDetail" ngx-datatable-cell-template>
      <!--fxHide.gt-sm-->
      <div
        [class.datatable-icon-right]="!expanded"
        [class.datatable-icon-down]="expanded"
        title="Expand/Collapse Row"
        (click)="filesDataTable.rowDetail.toggleExpandRow(row); $event.stopPropagation()"
      >
      </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    *ngIf="!typeColHidden && mode !== FileListModes.MINI"
    [width]="50"
    [resizeable]="false"
    [sortable]="false"
    [draggable]="false"
    [canAutoResize]="false"
    headerClass="no-padding-cell"
    cellClass="no-padding-cell centered-cell"
  >
    <ng-template let-file="row" ngx-datatable-header-template>
      <button
        mat-icon-button
        [matMenuTriggerFor]="manageMenu"
      >
        <ekon-icon [icon]="currentFilterByType ? currentFilterByType.icon : 'search'" size="lg"></ekon-icon>
      </button>

      <mat-menu #manageMenu="matMenu">
        <ng-template matMenuContent>
          <button
            mat-menu-item
            *ngFor="let type of fileTypes"
            (click)="setFilterByType(type)"
          >
            <ekon-icon [icon]="type.icon" class="pr-8"></ekon-icon>
            <span>{{type.name | titlecase}}</span>
          </button>
          <button
            mat-menu-item
            (click)="setFilterByType()"
          >
            <span class="primary-500-fg">Select all</span>
          </button>
        </ng-template>
      </mat-menu>
    </ng-template>
    <ng-template let-file="row" ngx-datatable-cell-template>
      <ekon-icon [icon]="getTypeIcon(file.fileName)" size="lg"
                 [matTooltip]="getFileExt(file.fileName) | uppercase"
                 matTooltipClass="file-type-tooltip mat-subheading-1"
                 matTooltipPosition="right"></ekon-icon>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="Name"
    prop="name"
    [flexGrow]="2"
    [resizeable]="false"
    [draggable]="false"
    [headerTemplate]="filterableHeaderCellTpl"
  >
    <ng-template let-file="row" ngx-datatable-cell-template>
      <div
        (click)="callSelect(file, data.users)"
        class="cursor-pointer"
      >{{file.name}}</div>
    </ng-template>
  </ngx-datatable-column>

  <ng-container *ngIf="isDesktop | async">
    <ngx-datatable-column
      name="Size"
      prop="fileSize"
      *ngIf="mode !== FileListModes.MINI"
      [flexGrow]="1"
      [resizeable]="false"
      [draggable]="false"
      [headerTemplate]="sortableHeaderCellTpl"
    >
      <ng-template let-file="row" ngx-datatable-cell-template>
        {{ file.fileSize | filesize }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Owner"
      prop="uploadedBy"
      [flexGrow]="1"
      [resizeable]="false"
      [draggable]="false"
      [headerTemplate]="filterableHeaderCellTpl"
    >
      <ng-template let-file="row" ngx-datatable-cell-template>
        {{ getUserName(data.users, file.uploadedBy) }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Updated at"
      prop="lastModifiedAt"
      *ngIf="mode !== FileListModes.MINI"
      [flexGrow]="1"
      [resizeable]="false"
      [draggable]="false"
      [headerTemplate]="sortableHeaderCellTpl"
    >
      <ng-template let-file="row" ngx-datatable-cell-template>
        {{ file.lastModifiedAt | date:'dd.MM.yyyy - HH:mm' }}
      </ng-template>
    </ngx-datatable-column>
  </ng-container>

  <ngx-datatable-column
    [width]="mode === FileListModes.FULL ? 100 : 50"
    [flexGrow]="1"
    [canAutoResize]="false"
    [resizeable]="false"
    [draggable]="false"
    headerClass="no-padding-cell"
    cellClass="no-padding-cell"
  >
    <ng-template let-file="row" ngx-datatable-cell-template>
      <button
        mat-icon-button
        *ngIf="(mode === FileListModes.FULL || mode === FileListModes.VIEW) && canView(file)"
        (click)="callView(file, $event)"
        class="mr-8"
      >
        <ekon-icon icon="glasses"></ekon-icon>
      </button>

      <button
        mat-icon-button
        [matMenuTriggerFor]="manageMenu"
        *ngIf="mode === FileListModes.FULL || mode === FileListModes.MINI"
      >
        <ekon-icon icon="ellipsis-v" size="lg"></ekon-icon>
      </button>

      <mat-menu #manageMenu="matMenu">
        <ng-template matMenuContent>
          <button
            mat-menu-item
            *ngIf="mode !== FileListModes.FULL && canView(file)"
            (click)="callView(file, $event)"
          >
            <ekon-icon
              icon="glasses"
              class="pr-8"
            ></ekon-icon>
            <span>View</span>
          </button>
          <button
            mat-menu-item
            (click)="callDownload(file, $event)"
          >
            <ekon-icon
              icon="download"
              class="pr-8"
            ></ekon-icon>
            <span>Download</span>
          </button>
          <button
            mat-menu-item
            (click)="callEditMeta(file, $event)"
          >
            <ekon-icon icon="edit" class="pr-8"></ekon-icon>
            <span>Edit</span>
          </button>
          <button
            mat-menu-item
            (click)="callDelete(file, $event)"
            class="warn-fg"
          >
            <ekon-icon icon="trash-alt" class="pr-8"></ekon-icon>
            <span>Delete</span>
          </button>
        </ng-template>
      </mat-menu>

    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<ng-template
  #filterableHeaderCellTpl
  let-column="column"
  let-sort="sortFn"
  let-sortDir="sortDir"
  let-sortClass="sortClass"
>
  <div class="header-cell">
    <div class="header-cell-filter" [ngClass]="sortDir">
      <input #filterInput matInput class="header-cell-filter-input" autocomplete="off">
      <label class="header-cell-filter-label">{{column.name}}</label>
      <button
        mat-icon-button
        (click)="filterInput.value = ''; megaSupa({ dir: sortDir, class: sortClass })"
        class="header-cell-filter-clear"
      >
        <ekon-icon icon="times"></ekon-icon>
      </button>
    </div>
    <ng-container *ngTemplateOutlet="sortBtn; context: { dir: sortDir, sortFn: sort }"></ng-container>
  </div>
</ng-template>

<ng-template
  #sortableHeaderCellTpl
  let-column="column"
  let-sort="sortFn"
  let-sortDir="sortDir"
  let-sortClass="sortClass"
>
  <div
    class="header-cell"
    [ngClass]="{ 'sorted': !!sortDir }"
  >
    <div class="header-cell-filter" [ngClass]="sortDir">
      <label class="header-cell-filter-label">{{column.name}}</label>
    </div>
    <ng-container *ngTemplateOutlet="sortBtn; context: { dir: sortDir, sortFn: sort }"></ng-container>
  </div>
</ng-template>

<ng-template #sortBtn let-dir="dir" let-sortFn="sortFn">
  <button
    mat-icon-button
    (click)="sortFn()"
    class="header-cell-sort"
    [ngClass]="{ 'sorted': !!dir }"
  >
    <ekon-icon [icon]="(dir ? (dir === 'desc' ? 'sorting-desc' : 'sorting-asc') : 'sorting')"></ekon-icon>
  </button>
</ng-template>
