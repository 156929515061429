import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  CreateRate,
  RateDetails,
  RateService,
  RateServiceInterface
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RateEventsServiceInterface } from './RateEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class RateEventsService
  implements RateServiceInterface, RateEventsServiceInterface {
  get configuration(): Configuration {
    return this.rateApiService.configuration;
  }

  set configuration(val: Configuration) {
    this.rateApiService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.rateApiService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.rateApiService.defaultHeaders = val;
  }

  /**
   * The mechanism for monitoring Create action
   */
  private rateCreatedSubject: Subject<RateDetails> = new Subject();

  get rateCreated(): Observable<RateDetails> {
    return this.rateCreatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Delete action
   */
  private rateDeletedSubject: Subject<string> = new Subject();

  get rateDeleted(): Observable<string> {
    return this.rateDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Update action
   */
  private rateUpdatedSubject: Subject<string> = new Subject();

  get rateUpdated(): Observable<string> {
    return this.rateUpdatedSubject.asObservable();
  }

  constructor(
    private rateApiService: RateService,
    private progressBar: EkonProgressBarService
  ) {
  }

  averageRateByRefId(refId: string): Observable<number> {
    return this.progressBar.apply(
      this.rateApiService.averageRateByRefId(refId)
    );
  }

  createRate(createRate?: CreateRate): Observable<RateDetails> {
    return this.progressBar
      .apply(this.rateApiService.createRate(createRate))
      .pipe(tap((rate: RateDetails) => this.rateCreatedSubject.next(rate)));
  }

  deleteRate(id: string): Observable<unknown> {
    return this.progressBar.apply(this.rateApiService.deleteRate(id));
  }

  findRateById(id: string): Observable<RateDetails> {
    return this.progressBar.apply(this.rateApiService.findRateById(id));
  }

  findRateByRefId(refId: string): Observable<RateDetails> {
    return this.progressBar.apply(this.rateApiService.findRateByRefId(refId));
  }

  listUserRates(): Observable<Array<RateDetails>> {
    return this.progressBar.apply(this.rateApiService.listUserRates());
  }

  updateRate(id: string, rateDetails: RateDetails): Observable<unknown> {
    return this.progressBar
      .apply(this.rateApiService.updateRate(id, rateDetails));
  }

  deleteRateByRefId(refId: string): Observable<unknown> {
    return this.progressBar.apply(
      this.rateApiService.deleteRateByRefId(refId))
      .pipe(tap(() => this.rateDeletedSubject.next(refId))
      );
  }

}
