<dkm-core-ava-viewer
  class="select-btn"
  [editDisabled]="true"
  [matTooltip]="'Select ' + globalConfig.domainTitlePlural | uppercase | translate | lowercase"
  (imageClick)="selectDomains()"
  matRipple
>
  <ekon-icon [icon]="'plus'" size="lg"></ekon-icon>
</dkm-core-ava-viewer>
<ng-container *ngIf="domainsSelected | async">
  <ekon-slider>
    <div class="selected-items">
      <div
        class="selected-item"
        *ngFor="let domain of domainsSelected | async; let i = index"
      >
        <dkm-core-ava-viewer
          [fileLink]="domain.thumbprintUrl"
          [editDisabled]="true"
          [matTooltip]="domain.name"
          [ngClass]="'primary-' + (i % 9) + '00'"
          [cacheEnabled]="true"
          (imageClick)="goToDomain(domain.id)"
        >{{ domain.name | ekonInitials }}</dkm-core-ava-viewer>
        <div
          class="deselect-btn"
          matRipple
          (click)="deselectDomain(domain.id)"
        >
          <ekon-icon [icon]="'times'" size="xs"></ekon-icon>
        </div>
      </div>
    </div>
  </ekon-slider>
</ng-container>
