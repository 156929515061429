<ekon-dialog-wrapper>
  <form [formGroup]="form" (ngSubmit)="validateAndSubmit()" fxLayout="column" class="h-100-p">
    <div mat-dialog-title class="h2 mb-24">{{ (data ? 'Edit' : 'Send') + ' feedback'}}</div>
    <mat-dialog-content fxLayout="column">

      <mat-form-field appearance="outline" fxFlex="0 0 auto">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" type="text">
        <mat-error>
          <span *ngIf="form?.controls?.value?.errors?.required">Title is required</span>
        </mat-error>
      </mat-form-field>

<!-- EK: 3.10.2020: Removed Context type-->
<!--      <mat-form-field appearance="outline" fxFlex="0 0 auto">-->
<!--        <mat-label>Context Type</mat-label>-->
<!--        <mat-select formControlName="contextSubjectType">-->
<!--          <mat-option *ngFor="let feedbackContext of ContextType" [value]="feedbackContext">-->
<!--            {{feedbackContext}}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->

      <mat-form-field appearance="outline" fxFlex="0 0 auto">
        <mat-label>Select Issue</mat-label>
        <mat-select formControlName="feedbackType" (selectionChange)="changeFeedbackType($event)">
          <mat-option *ngFor="let issue of IssueTypeArr" [value]="issue">
            {{issue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- feedback card start -->
      <ng-container
        *ngIf="form.get('feedbackType').value == IssueType.Feedback"
        formArrayName="questionAndAnswer"
      >
        <div
          *ngFor="let fq of feedbackQuestions | async; let i = index"
          [formGroupName]="i"
          fxLayout="column"
          fxFlex="0 0 auto"
          class="mb-8"
        >
          <div fxFlex="0 0 auto" class="mb-16">Q{{i}}: {{fq.q}}</div>
          <mat-radio-group
            formControlName="answer"
            fxLayout="column"
            fxFlex="0 0 auto"
            class="mb-16"
          >
            <!-- TODO: what wrong is going with value attr?-->
            <mat-radio-button
              *ngFor="let option of fq.options"
              [value]="option"
              class="mb-8"
            >{{option}}</mat-radio-button>
          </mat-radio-group>

          <div fxFlex="0 0 auto">To help to improve the solution, please provide your comments or opinion</div>

          <mat-form-field
            fxFlex="0 0 auto"
            appearance="outline"
            class="mb-16"
          >
            <textarea matInput formControlName="elaborate"></textarea>
          </mat-form-field>
        </div>
      </ng-container>
      <!-- feedback card end -->

      <mat-form-field appearance="outline" *ngIf="selectedValue != 'Feedback'">
        <mat-label>Summary</mat-label>
        <textarea matInput formControlName="summary"></textarea>
        <mat-error>
          <span *ngIf="form?.controls?.summary?.errors?.required">Value is required</span>
        </mat-error>
      </mat-form-field>

      <dkm-tags-autocomplete formControlName="tags"></dkm-tags-autocomplete>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button type="submit" [disabled]="!form.pristine && !form.valid">Save</button>
    </mat-dialog-actions>
  </form>
</ekon-dialog-wrapper>
