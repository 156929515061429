<div id="pages" class="page-layout simple fullwidth">
  <!-- HEADER -->
  <div class="page-header">
    <ekon-breadcrumbs class="page-header-title"></ekon-breadcrumbs>
  </div>
  <!-- / HEADER -->

  <div>
    <div class="content p-24" [innerHTML]="sanitizer.bypassSecurityTrustHtml((tou$ | async)?.touDocument)"></div>

    <div fxLayoutAlign="end center" *ngIf="needAcceptance" class="p-24">
      <button
        mat-raised-button
        color="primary"
        (click)="respondTOU()"
      >{{ 'ACCEPT_TOU' | translate }}</button>
    </div>
  </div>
</div>
