/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateComment { 
    /**
     * Text content
     */
    value: string;
    /**
     * Id reference to entity.  A comment must be referred to an entity
     */
    referrerId: string;
    /**
     * Comment parent id in case of discussion reply
     */
    parentId?: string | null;
}

