import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EkonDialogHolderService } from '../../services';

@Component({
  selector: 'ekon-dialog-wrapper',
  templateUrl: './ekon-dialog-wrapper.component.html',
  styleUrls: ['./ekon-dialog-wrapper.component.scss']
})
export class EkonDialogWrapperComponent implements AfterViewInit, OnDestroy {
  private unsubscribeAll: Subject<void> = new Subject();

  // @Input() maximizeVertically = true;
  @Input() transparentMode = false;
  @Input() closeBtnDisabled = false;

  @Input() isFullscreen = false;
  @Input() disableToggler = false;

  @Input() cancelValue = true;

  @Output() closeDialog: EventEmitter<void> = new EventEmitter();

  @HostBinding('class.toggler-enabled') get togglerEnabled(): boolean {
    return !this.disableToggler;
  }

  @HostBinding('class.close-enabled') get closeEnabled(): boolean {
    return !this.closeBtnDisabled || this.transparentMode;
  }

  matDialogWrapper: HTMLElement;

  constructor(
    private elementRef: ElementRef,
    @Optional() private dialogHolder: EkonDialogHolderService,
    @Optional() private dialogRef: MatDialogRef<unknown>
  ) {
    this.dialogRef?.backdropClick().pipe(
      takeUntil(this.unsubscribeAll)
    ).subscribe({
      next: () => this.close()
    });
  }

  close(): void {
    if (this.dialogHolder) {
      return this.dialogHolder.close(this.cancelValue);
    }

    if (this.dialogRef) {
      return this.dialogRef.close();
    }

    this.closeDialog.emit();
  }

  setFullscreen(makeFullscreen: boolean): void {
    if (makeFullscreen) {
      this.matDialogWrapper.classList.add('maximized');
      if (this.transparentMode) {
        this.matDialogWrapper.classList.add('transparent-bg');
      }
    } else {
      this.matDialogWrapper.classList.remove('maximized');
      this.matDialogWrapper.classList.remove('transparent-bg');
    }

    window.dispatchEvent(new Event('resize'));

    // this.applyMaximizeVertically(makeFullscreen);
  }

  // applyMaximizeVertically(makeFullscreen: boolean) {
  //   if (makeFullscreen && this.maximizeVertically) {
  //       this.matDialogWrapper.classList.add('maximized-v');
  //   } else {
  //     this.matDialogWrapper.classList.remove('maximized-v');
  //   }
  // }

  toggleFullscreen(): void {
    this.isFullscreen = !this.isFullscreen;

    this.setFullscreen(this.isFullscreen);
  }

  ngAfterViewInit(): void {
    // fixme: check 'this.dialogHolder' case
    this.matDialogWrapper = this.dialogRef || this.dialogHolder
      ? this.elementRef.nativeElement.parentElement.parentElement.parentElement
      : this.elementRef.nativeElement.parentElement.parentElement;
    this.setFullscreen(this.isFullscreen);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
