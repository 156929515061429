import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '@ekon-client/dkm-api';

@Component({
  selector: 'dkm-core-address-view',
  templateUrl: './address-view.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressViewComponent {
  @Input() data: Address;
}
