import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ekIconChevronDown, ekIconFilter,
  ekIconSearch, ekIconSorting,
  ekIconTimes,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { DkmAutocompletionModule } from '@ekon-client/shared/features/dkm-autocompletion';
import { DkmCategoriesModule } from '@ekon-client/shared/features/dkm-categories';
import { DkmTourModule } from '@ekon-client/shared/features/dkm-tour';
import { TranslateModule } from '@ngx-translate/core';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

import { FiltersComponent } from './components/filters/filters.component';

@NgModule({
  declarations: [FiltersComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        DkmCategoriesModule,
        MatInputModule,
        MatButtonModule,
        DkmAutocompletionModule,
        FlexModule,
        MatSelectModule,
        MatTooltipModule,
        ReactiveFormsModule,
        EkonIconsModule,
        TranslateModule,
        MatMenuModule,
        MatButtonToggleModule,
        DkmTourModule,
        TourMatMenuModule
    ],
  exports: [FiltersComponent],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-filters/',
      multi: true
    }
  ]
})
export class DkmFiltersModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconSearch,
      ekIconTimes,
      ekIconChevronDown,
      ekIconFilter,
      ekIconSorting
    );
  }
}
