import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DigitalPresence } from '@ekon-client/dkm-api';

@Component({
  selector: 'dkm-core-digital-presence-view',
  templateUrl: './digital-presence-view.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigitalPresenceViewComponent {
  @Input() data: DigitalPresence;
}
