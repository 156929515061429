/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserPostPageModel } from './userPostPageModel';
import { UserPostMeetupModel } from './userPostMeetupModel';
import { UserPostProductModel } from './userPostProductModel';
import { UserPostBlogModel } from './userPostBlogModel';
import { UserHeaderModel } from './userHeaderModel';
import { UserPostDomainModel } from './userPostDomainModel';
import { UserPostExternalLinkModel } from './userPostExternalLinkModel';
import { UserPostLegalEntityModel } from './userPostLegalEntityModel';
import { UserPostReshareModel } from './userPostReshareModel';
import { UserPostFilesModel } from './userPostFilesModel';
import { UserPostPlainModel } from './userPostPlainModel';
import { UserPostReaction } from './userPostReaction';


/**
 * For editing and details view
 */
export interface UserPostModel { 
    kind: string;
    /**
     * Identifier
     */
    id: string;
    /**
     * Text or Html content
     */
    value?: string | null;
    reaction?: Array<UserPostReaction> | null;
    /**
     * Tags to filter Feeds
     */
    tags?: Array<string> | null;
    lastUpdateAt?: Date;
    publishedAt?: Date | null;
    publishedBy?: string | null;
    publisher?: UserHeaderModel;
    create?: boolean;
    read?: boolean;
    update?: boolean;
    remove?: boolean;
}

