<mat-card>
  <dkm-core-ava-viewer
    [editDisabled]="true"
    [noShape]="true"
    [fileLink]="data?.imageId"
    (imageClick)="disableAllActions ? selectBlogPost() : viewBlogPost()"
  >
    <ekon-icon icon="blogs" size="4x" [directionSensitive]="false"></ekon-icon>
  </dkm-core-ava-viewer>

  <div class="header border-bottom p-12 py-16" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="0 0 auto">
      <div
        fxLayoutGap="4px"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <dkm-core-ava-viewer [fileLink]="data?.publisher?.avatarUrl" class="author-avatar"></dkm-core-ava-viewer>
        <small class="grey-500-fg">{{data?.publisher?.fullName}} created at {{data?.publishedAt | date:'dd.MM.yyyy'}}</small>
      </div>
    </div>
  </div>

  <div class="title p-16">
    <div class="h2"><a (click)="disableAllActions ? selectBlogPost() : viewBlogPost()">{{data.title}}</a></div>
  </div>

  <div class="overview p-16 pt-0 fadeout m-0">{{data.bodyOverview}}</div>

  <!--<dkm-rating-average [refEntityMeta]="{id: data.id, type: UsageRefTypeEnum.BlogPost}"></dkm-rating-average>-->

  <ekon-tags
    [tags]="data.tags"
    [minimal]="true"
    class="px-16 fadeout m-0"
  ></ekon-tags>


<!--  <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
    &lt;!&ndash;<button
      mat-icon-button
      *ngIf="!disableAllActions"
    >
      <ekon-icon icon="share-outline"></ekon-icon>
    </button>&ndash;&gt;
    <button
      mat-icon-button
      (click)="editBlogPost()"
      *ngIf="!disableAllActions && editAllowed"
    >
      <ekon-icon icon="edit"></ekon-icon>
    </button>
    &lt;!&ndash;<button mat-icon-button *ngIf="editAllowed" (click)="deleteBlogPost(data)" color="warn"><fa-icon [icon]="['fas', 'trash-alt']"></fa-icon></button>&ndash;&gt;
  </mat-card-actions>-->
</mat-card>
