export interface AuthConfig {
    AUTHORITY: string,
    CLIENT_ID: string,
    REDIRECT_URI?: string,
    POST_LOGOUT_REDIRECT_URI?: string,
    RESPONSE_TYPE: string,
    SCOPE: string,
    FILTER_PROTOCOL_CLAIMS?: boolean,
    LOAD_USER_INFO?: boolean,
    AUTOMATIC_SILENT_RENEW?: boolean,
    SILENT_REDIRECT_URI?: string,
    STRICT_DISCOVERY_DOCUMENT?: boolean,
    DEBUG?: boolean
}
