<mat-form-field appearance="outline" class="w-100-p">
  <mat-label>Users</mat-label>
  <mat-chip-list #attendeeList aria-label="Users selection" [disabled]="disabled">
    <ng-container *ngFor="let attendee of users">
      <ng-container *ngIf="attendee?.email?.trim().length">
        <mat-chip
                  selectable="true"
                  [removable]="true"
                  (removed)="removeUser(attendee)">
            {{attendee?.email}}
          <ekon-icon matChipRemove [icon]="ekIconTimesCircle" size="lg" class="ml-4"></ekon-icon>
        </mat-chip>
      </ng-container>
    </ng-container>
    <input placeholder="Add user..."
           #userInput
           [matChipInputFor]="attendeeList"
           [matAutocomplete]="autocmplUsers"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="addUser($event)">
  </mat-chip-list>
  <mat-autocomplete #autocmplUsers="matAutocomplete" (optionSelected)="autoCompleteUserSelected($event)">
    <ekon-paginator
      [config]="paginatorConfig"
      #paginator
      [sortingNFilteringHidden]="true"
      [modeSwitcherHidden]="true"
      [topPaginatorHidden]="true"
    >
      <ng-container *ngIf="paginator.items$ | async as users">
        <mat-option *ngFor="let user of users" [value]="user">
          {{user?.fullName}}
          <ng-container *ngIf="user?.email?.trim().length">
            ({{user?.email}})
          </ng-container>
        </mat-option>
    </ng-container>
    </ekon-paginator>
  </mat-autocomplete>
</mat-form-field>
