<mat-form-field [appearance]="outlineStyle ? 'outline' : 'legacy'" class="w-100-p">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    #itemInput
    type="text"
    [placeholder]="placeholder ? placeholder : ('ADD_TAG' | translate)"
    matInput
    [formControl]="inputControl"
    [matAutocomplete]="auto"
  >
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-autocomplete
    #auto="matAutocomplete"
  >
    <mat-option *ngFor="let item of filtered | async" [value]="item">
      {{item}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
