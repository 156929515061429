<mat-form-field appearance="outline" class="w-100-p">
  <mat-label>Attendees</mat-label>
  <mat-chip-list #attendeeList aria-label="Attendees selection" [disabled]="disabled">
    <mat-chip *ngFor="let attendee of attendees"
              selectable="true"
              removable="true"
              (removed)="removeAttendee(attendee)">
      {{attendee?.email}}
      <ekon-icon icon="times-circle" size="lg"></ekon-icon>
    </mat-chip>
    <input placeholder="New attendee..."
           #attendeeInput
           [formControl]="attendeeInputControl"
           [matChipInputFor]="attendeeList"
           [matAutocomplete]="autocmplAttendees"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           matChipInputAddOnBlur="true"
           (matChipInputTokenEnd)="addAttendee($event)">
  </mat-chip-list>
  <mat-autocomplete #autocmplAttendees="matAutocomplete" (optionSelected)="autoCompleteAttendeeSelected($event)">
    <mat-option *ngFor="let attendee of filteredAttendees | async" [value]="attendee">
      {{attendee?.firstName}}
      {{attendee?.email}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
