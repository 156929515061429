import { ChangeDetectionStrategy, Component, Inject,TrackByFunction } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageHeader, PageServiceInterface } from '@ekon-client/dkm-api';
import {
  PAGE_ACTIONS,
  PAGE_EVENTS,
  PageEventsServiceInterface,
  PRODUCT_EVENTS,
  ProductEventsServiceInterface
} from '@ekon-client/dkm-events';
import { PaginationModel, PaginatorConfig } from '@ekon-client/shared/common/ekon-pagination';

@Component({
  selector: 'ekon-client-user-post-add-attachment-page',
  templateUrl: './page-selector-dialog.component.html',
  styleUrls: ['./page-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageSelectorDialogComponent {

  paginatorConfig: PaginatorConfig;

  trackById: TrackByFunction<PageHeader> = (index: number, item: PageHeader) => item.id;

  constructor(
    private dialogRef: MatDialogRef<PageSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      kind: 'page' | 'product',
      isTemplate: boolean,
      domain: string
    },
    @Inject(PAGE_ACTIONS) protected pageActions: PageServiceInterface,
    @Inject(PAGE_EVENTS) protected pageEvents: PageEventsServiceInterface,
    @Inject(PRODUCT_EVENTS) protected productEvents: ProductEventsServiceInterface
  ) {
    this.paginatorConfig = {
      loader: (pagination: PaginationModel) => this.pageActions.listPages(
        pagination,
        data.isTemplate,
        data.kind === 'product',
        data.domain
      ),
      observables: data.kind === 'page' ? [
        this.pageEvents.itemCreated,
        this.pageEvents.itemDeleted,
        this.pageEvents.itemUpdated
      ] : [
        this.productEvents.itemCreated,
        this.productEvents.itemDeleted,
        this.productEvents.itemUpdated
      ]
    }
  }

  validateAndSave(page: PageHeader): void {
    this.dialogRef.close(page);
  }
}
