import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  FileMetadata, FileMetadataPagedResult,
  FileOutputTypes,
  FilesServiceInterface, UserProfileHeader,
  UserProfileServiceInterface
} from '@ekon-client/dkm-api';
import { FILE_ACTIONS, USERPROFILE_ACTIONS } from '@ekon-client/dkm-events';
import { PaginationModel } from '@ekon-client/shared/common/ekon-pagination';
import { EkonMessageService, EkonMessageTypes } from '@ekon-client/shared/common/ekon-utils';
import { isString as _isString } from 'lodash-es';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import {
  FileDataSource,
  FileListDataSourcePaginated,
  FileListDataSourcePaginatedAlt,
  FileManagerModes
} from '../extras';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    @Inject(FILE_ACTIONS) private fileActions: FilesServiceInterface,
    @Inject(USERPROFILE_ACTIONS)
    private userProfileActions: UserProfileServiceInterface,
    private messageService: EkonMessageService,
    private sanitizer: DomSanitizer
  ) {
  }

  listFiles(
    mode: FileManagerModes = FileManagerModes.GENERAL,
    pagination: PaginationModel,
    domainId?: string
  ): Observable<FileMetadataPagedResult> {
    return mode === FileManagerModes.PROFILE
           ? this.fileActions.listFileByUser(pagination, domainId)
           : this.fileActions.listFileByDomains(pagination, domainId);
  }

  listFilesWithAuthors(
    mode: FileManagerModes = FileManagerModes.GENERAL,
    pagination: PaginationModel,
    domainId?: string
  ): Observable<FileListDataSourcePaginated> {
    return this.listFiles(mode, pagination, domainId).pipe(
      switchMap((files: FileMetadataPagedResult) =>
        forkJoin({
          files: of(files),
          users: this.userProfileActions.listUsers(
            Array.from(
              new Set([
                ...files.items.map((item: FileMetadata) => item.uploadedBy),
                ...files.items.map((item: FileMetadata) => item.lastModifiedBy)
              ])
            )
          ).pipe(map(users => users.filter(u => Boolean(u))))
        })
      )
    );
  }

  listFilesWithAuthorsAlt(
    mode: FileManagerModes = FileManagerModes.GENERAL,
    pagination: PaginationModel,
    domainId?: string
  ): Observable<FileListDataSourcePaginatedAlt> {
    return this.listFiles(mode, pagination, domainId).pipe(
      switchMap((files: FileMetadataPagedResult) =>
        this.userProfileActions.listUsers(Array.from(new Set([
          ...files.items.map((item: FileMetadata) => item.uploadedBy),
          ...files.items.map((item: FileMetadata) => item.lastModifiedBy)
        ]))).pipe(
          map((users: UserProfileHeader[]) => {
            users = users.filter(u => Boolean(u));
            return ({
              ...files,
              items: files.items.map((file: FileMetadata) =>
                ({
                  file,
                  user: users.find(u => u.id === file.uploadedBy || u.id === file.lastModifiedBy)
                })
              )
            });
          })
        )
      )
    );
  }

  getFileMetaWithAuthor(fileId: string): Observable<FileDataSource> {
    return this.fileActions.findFileMetadata(fileId).pipe(
      switchMap((file: FileMetadata) =>
        forkJoin({
          file: of(file),
          user: this.userProfileActions
            .listUsers([file.uploadedBy])
            .pipe(map((users: UserProfileHeader[]) => users[0]))
        })
      )
    );
  }

  getFileAuthor(authorId: string): Observable<UserProfileHeader> {
    return this.userProfileActions
      .listUsers([authorId])
      .pipe(map((users: UserProfileHeader[]) => users[0]));
  }

  getFileMeta(id: string): Observable<FileMetadata> {
    return this.fileActions.findFileMetadata(id);
  }

  uploadFile(file: File | Blob, filesPersonalMode?: boolean, specificDomain?: string): Observable<FileMetadata> {
    return (filesPersonalMode ? this.fileActions.personalUpload(file)
                              : this.fileActions.upload(file, specificDomain)).pipe(
      tap(
        () =>
          this.messageService.show(
            'File was uploaded successfully',
            EkonMessageTypes.SUCCESS
          ),
        () =>
          this.messageService.show(
            'Error occurred while uploading file',
            EkonMessageTypes.ERROR
          )
      )
    );
  }

  loadFileBlob(fileId: string): Observable<Blob> {
    return this.fileActions.download(fileId);
  }

  loadFile(file: string | FileMetadata, name?: string): Observable<File> {
    return this.loadFileBlob(_isString(file) ? file : file.id).pipe(
      map((blob: Blob) => new File(
        [blob],
        name || (!_isString(file) ? file.name : 'noname'),
        { type: blob.type }
      ))
    );
  }

  loadImageBlob(
    fileId: string,
    width?: number,
    height?: number,
    outputType?: FileOutputTypes
  ): Observable<Blob> {
    return this.fileActions.downloadImage(
      fileId,
      width ? Math.ceil(width) : 0,
      height ? Math.ceil(height) : 0,
      outputType
    );
  }

  loadFileBlobURL(fileId: string): Observable<string> {
    return this.loadFileBlob(fileId).pipe(
      map((data) => window.URL.createObjectURL(data))
    );
  }

  loadFileUint(fileId: string): Observable<Uint8Array> {
    return this.loadFileBlob(fileId).pipe(
      switchMap(
        (data) =>
          new Observable<Uint8Array>((observer) => {
            const fr = new FileReader();
            fr.onload = () => {
              observer.next(new Uint8Array(fr.result as ArrayBuffer));
              observer.complete();
            };

            fr.readAsArrayBuffer(data);
          })
      )
    );
  }

  loadImageBase64(
    fileId: string,
    width?: number,
    height?: number,
    outputType?: FileOutputTypes
  ): Observable<string> {
    return this.loadImageBlob(fileId, width, height, outputType)
      .pipe(
        switchMap(
          (data) =>
            data ? new Observable<string>((observer) => {
              const fr = new FileReader();
              fr.onload = () => {
                observer.next(fr.result as string);
                observer.complete();
              };

              fr.readAsDataURL(data);
            }) : EMPTY
        )
      );
  }

  loadImageElement(
    fileId: string,
    width?: number,
    height?: number,
    outputType?: FileOutputTypes
  ): Observable<HTMLImageElement> {
    return this.loadImageBlob(fileId, width, height, outputType)
      .pipe(
        switchMap(
          (data: Blob) =>
            data ? new Observable<HTMLImageElement>((observer) => {
              const url = window.URL.createObjectURL(data);

              const img = new Image();
              img.onload = () => {
                window.URL.revokeObjectURL(url);
                observer.next(img);
                observer.complete();
              };

              img.src = url;
            }) : EMPTY
        )
      );
  }

  clearBlobURL(url: string): void {
    window.URL.revokeObjectURL(url);
  }

  bypassSecurityBlobURL(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  downloadFile(file: FileMetadata): Observable<Blob> {
    return this.fileActions.download(file.id).pipe(
      tap({
        next: (data) => {
          this.downloadBlob(data, file.fileName, 'application/octet-stream');

          this.messageService.show(
            'File was downloaded successfully. Just save it.',
            EkonMessageTypes.SUCCESS
          );
        },
        error: () =>
          this.messageService.show(
            'Error occurred while downloading file',
            EkonMessageTypes.ERROR
          )
      })
    );
  }

  downloadBlob(data: BlobPart, name: string, type: string): void {
    const blob = new Blob([data], { type });
    const fileUrl = window.URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', fileUrl);
    downloadLink.setAttribute('download', name);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    setTimeout(() => {
      downloadLink.click();
      window.URL.revokeObjectURL(fileUrl);
      document.body.removeChild(downloadLink);
    }, 100);
  }

  limitImageSize(dataLink: string, max: number): Observable<string> {
    return new Observable<string>((observer) => {

      const img = new Image();
      img.onload = () => {
        if(img.width > max || img.height > max) {
          const oc = document.createElement('canvas');

          let width: number;
          let height: number;

          if(img.width > img.height) {
            height = img.height * max / img.width;
            width = max;
          } else {
            if(img.width < img.height) {
              width = img.width * max / img.height;
              height = max;
            } else {
              width = height = max;
            }
          }
          oc.width = width;
          oc.height = height;

          oc.getContext('2d').drawImage(img, 0, 0, width, height);

          observer.next(oc.toDataURL());
        } else {
          observer.next(dataLink);
        }

        observer.complete();
      };

      img.src = dataLink;

    });
  }
}
