/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *   Feedback (User feedback)  Proposal (User proposal)  Incident (Reporting an incident or IT service outage.)  ServiceRequest (Requesting help from an internal or customer service desk.)  ContactUs (Contact us - site/business owner)
 */
export type IssueType = 'Feedback' | 'Proposal' | 'Incident' | 'ServiceRequest' | 'ContactUs';

export const IssueType = {
    Feedback: 'Feedback' as IssueType,
    Proposal: 'Proposal' as IssueType,
    Incident: 'Incident' as IssueType,
    ServiceRequest: 'ServiceRequest' as IssueType,
    ContactUs: 'ContactUs' as IssueType
};

