import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileMetadata, UserProfileHeader } from '@ekon-client/dkm-api';
import {
  FILE_EVENTS,
  FileEventsServiceInterface
} from '@ekon-client/dkm-events';
import { PaginatorConfig } from '@ekon-client/shared/common/ekon-pagination';
import { noop as _noop } from 'lodash-es';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import {
  FileDataSource,
  FileListDataSourcePaginated,
  FileListModes,
  FileManagerModes, FileMediaTypes, FileTypesWeCanView
} from '../../../extras';
import { ImageCropService } from '../../../file-upload/components/image-cropper-dialog/image-cropper-dialog.component';
import { FileService } from '../../../services/file.service';
import { FileViewService } from '../../../services/file-view.service';


@Component({
  templateUrl: './file-select-dialog.component.html',
  styleUrls: ['./file-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileSelectDialogComponent {
  FileListModes = FileListModes;

  mode: FileManagerModes = FileManagerModes.GENERAL;

  private readonly _authors$: BehaviorSubject<UserProfileHeader[]> = new BehaviorSubject([]);
  get authors$(): Observable<UserProfileHeader[]> {
    return this._authors$.asObservable();
  }

  filesPaginatorConfig: PaginatorConfig = {
    loader: pagination => this.fileService.listFilesWithAuthors(
      this.data.filesPersonalMode ? FileManagerModes.PROFILE : FileManagerModes.GENERAL,
      this.data.fileType ? {
        ...pagination,
        filters: {
          ...pagination.filters,
          contentType: FileTypesWeCanView.types[this.data.fileType]
        }
      } : pagination,
      this.data.domainFilter
    ).pipe(
      tap((dataSource: FileListDataSourcePaginated) => this._authors$.next(dataSource.users)),
      map((dataSource: FileListDataSourcePaginated) => dataSource.files)
    )
  };

  constructor(
    @Inject(FILE_EVENTS) private fileEvents: FileEventsServiceInterface,
    private fileService: FileService,
    private fileViewService: FileViewService,
    public dialogRef: MatDialogRef<FileSelectDialogComponent>,
    private imageCrop: ImageCropService,
    @Inject(MAT_DIALOG_DATA) public data: {
      fileType: FileMediaTypes,
      filesPersonalMode: boolean,
      domainFilter?: string,
      aspectRatio?: number
    }
  ) {
  }

  viewFile(initialFileIndex: number, pageNumber$: Observable<number>, pageSize$: Observable<number>): void {
    pageNumber$.pipe(
      take(1),
      withLatestFrom(pageSize$),
      switchMap(([pageNumber, pageSize]: number[]) => this.fileViewService.viewFile(
        (pageNumber - 1) * pageSize + initialFileIndex,
        true,
        this.data.fileType,
        this.data.domainFilter,
        this.data.filesPersonalMode
      ))
    ).subscribe({
      next: (selectedFile: FileDataSource) => selectedFile ? this.fileSelected(selectedFile, true) : _noop()
    });
  }

  fileSelected(fileData: FileDataSource, checkRatio?: boolean): void {
    if(checkRatio && this.data.aspectRatio) {
      this.checkRatio(fileData).subscribe({
        next: (fileData: FileDataSource) => this.dialogRef.close(fileData)
      });
    } else {
      this.dialogRef.close(fileData);
    }
  }

  onFileUploaded(file: FileMetadata): void {
    this.fileService
      .getFileAuthor(file.uploadedBy)
      .pipe(
        map((user: UserProfileHeader) => ({ file, user })),
        take(1)
      )
      .subscribe({
        next: (fileData: FileDataSource) => this.fileSelected(fileData)
      });
  }

  checkRatio(fileData: FileDataSource): Observable<FileDataSource> {
    return this.fileService.loadImageElement(fileData.file.id, 200)
      .pipe(
        switchMap((file: HTMLImageElement) => {
          const curRatio = file.width / file.height;
          const aspectRatioTop = this.data.aspectRatio + 0.1;
          const aspectRatioBottom = this.data.aspectRatio - 0.1;

          return (curRatio > aspectRatioBottom && curRatio < aspectRatioTop)
                 ? of(fileData)
                 : this.cropImage(fileData);
        })
      );
  }

  cropImage(fileData: FileDataSource): Observable<FileDataSource> {
    return this.fileService.loadFile(fileData.file.id, fileData.file.name + '-' + Date.now())
      .pipe(
        switchMap((file: File) => this.imageCrop.openImageCropperDialog(file, this.data.aspectRatio)),
        switchMap((fileFromDialog: File) => this.fileService.uploadFile(
          fileFromDialog,
          this.data.filesPersonalMode || !this.data.domainFilter,
          this.data.domainFilter
        )),
        map((fileMeta: FileMetadata) => ({ file: fileMeta }))
      );
  }
}
