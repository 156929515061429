import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';

import { TagsComponent } from './tags.component';



@NgModule({
  declarations: [
    TagsComponent
  ],
  imports: [
    CommonModule,
    MatChipsModule
  ],
  exports: [
    TagsComponent
  ]
})
export class EkonTagsModule { }
