import { ChangeDetectionStrategy,Component } from '@angular/core';
import { DomainSelectorService } from '@ekon-client/shared/features/dkm-domains-services';
import { Observable } from 'rxjs';

@Component({
  selector: 'ekon-search-n-filter',
  templateUrl: './search-n-filter.component.html',
  styleUrls: ['./search-n-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchNFilterComponent {
  get panelActive(): Observable<boolean> {
    return this.domainSelector.filterPanelActive$
  }

  constructor(
    public domainSelector: DomainSelectorService
  ) { }

  togglePanel(): void {
    this.domainSelector.togglePanel();
  }
}
