<div [formGroup]="form" fxLayout="row" fxLayoutGap="16px">
  <mat-form-field appearance="outline" fxFlex="1 1 50%">
<!--    <mat-label>Phone Title</mat-label>-->
    <mat-label>{{control1Label}}</mat-label>
    <input matInput [formControlName]="control1Name"/>
  </mat-form-field>
  <mat-form-field appearance="outline" fxFlex="1 1 50%">
<!--    <mat-label>Phone Number</mat-label>-->
    <mat-label>{{control2Label}}</mat-label>
    <input matInput [formControlName]="control2Name"/>
  </mat-form-field>
</div>
