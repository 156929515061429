<ekon-dialog-wrapper [isFullscreen]="true">
  <div mat-dialog-title>{{'LEGAL_ENTITY_SELECTION' | translate: { default: 'Legal entity selection' } }}</div>
  <mat-dialog-content>
    <ekon-paginator [config]="paginatorConfig" #paginator="ekonPaginator">
      <ekon-cards-grid>
        <dkm-core-legal-entity-card
          *ngFor="let entity of paginator.items$ | async; trackBy: trackById"
          [data]="entity"
          [disableAllActions]="true"
          (select)="validateAndSave(entity)"
        ></dkm-core-legal-entity-card>
      </ekon-cards-grid>
    </ekon-paginator>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxFlex="0 0 auto">
    <button mat-button [mat-dialog-close]="false">{{'CANCEL' | translate: { default: 'Cancel' } }}</button>
  </mat-dialog-actions>
</ekon-dialog-wrapper>
