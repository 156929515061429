/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BasketHeader { 
    id: string;
    /**
     * Basket name
     */
    name: string;
    /**
     * Basket short description, free text related to customer project etc.  Note, user can have several baskets open simultaneously.
     */
    shortDescription?: string | null;
    /**
     * Set the DLT TenderId form the BlockChain DLT Service
     */
    tenderId?: string | null;
    /**
     * DLT Tender date
     */
    tenderIssueDate?: Date | null;
    /**
     * Calculated field, total price
     */
    totalPrice?: number;
    /**
     * Indicate if basket is closed.
     */
    isOpen?: boolean;
    /**
     * Basket closure date
     */
    closureDate?: Date | null;
}

