import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ekIconAddSquare, ekIconIosShare, ekIconTimes } from '@ekon-client/shared/common/ekon-icons';

import { PwaUpdateService } from '../../services';

@Component({
  selector: 'ekon-client-pwa-install',
  templateUrl: './pwa-install.component.html',
  styleUrls: ['./pwa-install.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PwaInstallComponent {
  ekIconAddSquare = ekIconAddSquare;
  ekIconIosShare = ekIconIosShare;
  ekIconTimes = ekIconTimes;

  constructor(
    public pwa: PwaUpdateService
  ) {}
}
