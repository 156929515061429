import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserProfileHeader } from '@ekon-client/dkm-api';

import { getUserName } from '../../utils';

// TODO: make pipe instead of component
@Component({
  selector: 'dkm-core-user-name',
  templateUrl: './user-name.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNameComponent {
  @Input() user: UserProfileHeader;

  getName(user: UserProfileHeader): string {
    return getUserName(user);
  }
}
