import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors, Validators
} from '@angular/forms';

import { ReusableFormBase } from '../../ReusableFormBase';

@Component({
  selector: 'dkm-partials-controls-pair-form',
  templateUrl: './controls-pair-form.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControlsPairFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ControlsPairFormComponent),
      multi: true,
    },
  ],
})
export class ControlsPairFormComponent extends ReusableFormBase<unknown> implements OnInit {
  @Input() control1Name = 'name';
  @Input() control2Name = 'value';
  @Input() control1Label = 'Name';
  @Input() control2Label = 'Value';
  @Input() control1Placeholder = 'name';
  @Input() control2Placeholder = 'name';
  @Input() control1Required: boolean;
  @Input() control2Required: boolean;

  form: FormGroup;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      [this.control1Name]: new FormControl(
        null,
        this.control1Required ? Validators.required : undefined
      ),
      [this.control2Name]: new FormControl(
        null,
        this.control1Required ? Validators.required : undefined
      )
    });

    this.initValueChanges();
  }


  validate(/*_control: AbstractControl*/): ValidationErrors | null {
    return ReusableFormBase.validate(this.form);
  }

  makeOutputValue(data: unknown): unknown | null {
    return ReusableFormBase.objToValueOrNull(data);
  }
}
