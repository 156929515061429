/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *   Private (owner/creator/explicit grant (aka allowed group)  (uses explicit grant))  Internal (Visibe to domain users.  (Implicit context grant))  Protected (Visible to all registered users  Implicit authorized user grant)  Public (Visible to public users  (Anonymous user))  All (Visible to any users of the system (public and private))
 */
export type VisibilityTypes = 'Private' | 'Internal' | 'Protected' | 'Public' | 'All';

export const VisibilityTypes = {
    Private: 'Private' as VisibilityTypes,
    Internal: 'Internal' as VisibilityTypes,
    Protected: 'Protected' as VisibilityTypes,
    Public: 'Public' as VisibilityTypes,
    All: 'All' as VisibilityTypes
};

