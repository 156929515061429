import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[ekonHandleAppLinks]',
  exportAs: 'ekonHandleAppLinks'
})
export class EkonHandleAppLinksDirective implements OnChanges {

  @Input('ekonHandleAppLinks') source: unknown;
  @Input('ekonHandleAppLinksPropagation') propagation = true;

  truncated = false;

  constructor(
    private hostElRef: ElementRef<Element>,
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    let el: HTMLElement = this.hostElRef.nativeElement as HTMLElement;
    el = el.parentElement || el;

    (el.querySelectorAll('a') as NodeListOf<HTMLAnchorElement>).forEach(
      (a: HTMLAnchorElement) => {
        if(a.href.includes(window.location.hostname)/* || a.pathname.startsWith('/')*/) {
          a.addEventListener('click', $e => {
            $e.preventDefault();
            !this.propagation && $e.stopPropagation();

            this.router.navigate([a.pathname]);
          }, true);
        }
      }
    );
  }
}

