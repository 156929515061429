import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnDestroy, Optional, Output,
  SimpleChanges, SkipSelf
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { FileMetadata, UserProfileHeader } from '@ekon-client/dkm-api';
import ResizeObserver from 'resize-observer-polyfill';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { catchError, delay, switchMap, withLatestFrom } from 'rxjs/operators';

import { FileView } from '../../../../../extras';
import { FileService } from '../../../../../services';

@Component({
  selector: 'dkm-file-view-image',
  templateUrl: './file-view-image.component.html',
  styleUrls: ['./file-view-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileViewImageComponent implements FileView, OnDestroy, OnChanges {
  @Input() fileMeta: FileMetadata;
  @Input() fileAuthor: UserProfileHeader;

  @Output() wrapperClick: EventEmitter<void> = new EventEmitter();

  private _loadingError$: BehaviorSubject<HttpErrorResponse> = new BehaviorSubject(null);
  loadingError$: Observable<HttpErrorResponse> = this._loadingError$.asObservable();

  private _resize$: BehaviorSubject<DOMRectReadOnly> = new BehaviorSubject(null);

  private _fileId$: BehaviorSubject<string> = new BehaviorSubject(null);
  fileBodyURI$: Observable<SafeResourceUrl> = merge(
    this._fileId$.asObservable(),
    this._resize$.asObservable()
  ).pipe(
    // todo: solve the issue. hotfix applied
    delay(500),
    withLatestFrom(this._fileId$.asObservable()),
    switchMap(([, fileId]: [unknown, string]) => {
      const viewBounding = this.elRef.nativeElement.getBoundingClientRect();

      return fileId ? this.fileService.loadImageBase64(
        fileId,
        viewBounding.height < 60 || viewBounding.width <= viewBounding.height ? Math.ceil(viewBounding.width) : undefined,
        viewBounding.height > 60 && viewBounding.height < viewBounding.width ? Math.ceil(viewBounding.height) : undefined
      ).pipe(
        catchError((error: HttpErrorResponse) => {
          this._loadingError$.next(error);
          return of(null);
        })
      ) : of(null);
    })
  );
  @Input() hideCredentials: boolean;

  enableChildPreview: boolean;

  resizeObserver: ResizeObserver;

  constructor(
    private fileService: FileService,
    private elRef: ElementRef,
    @SkipSelf() @Optional() parentViewer: FileViewImageComponent
  ) {
    this.enableChildPreview = !parentViewer;

    let timeout;

    this.resizeObserver = new ResizeObserver(
      ([{ contentRect: bounds }]: ResizeObserverEntry[]) => {
        timeout && clearTimeout(timeout);

        timeout = setTimeout(() => {
          this._resize$.next(bounds);
        }, 100);
      }
    );

    this.resizeObserver.observe(this.elRef.nativeElement);

  }

  emitWrapperClick(event: Event): void {
    event.target === event.currentTarget && this.wrapperClick.emit();
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
    this._fileId$.complete();
    this._resize$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.fileMeta
      && changes.fileMeta.currentValue !== changes.fileMeta.previousValue
    ) {
      changes.fileMeta.currentValue
        ? this._fileId$.next(this.fileMeta.id)
        : this._fileId$.next(null);
    }
  }
}
