import { Injectable } from '@angular/core';
import { CanDeactivate, Router, UrlTree } from '@angular/router';
import { EkonDialogsService } from '@ekon-client/shared/common/ekon-dialogs';
import { TranslateService } from '@ngx-translate/core';
import {
  isArray as _isArray,
  isBoolean as _isBoolean
} from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ConfirmDeactivate, RedirectOptions } from '../extras';

@Injectable({
  providedIn: 'root'
})
export class EkonConfirmDeactivateGuard implements CanDeactivate<ConfirmDeactivate> {
  gonnaBeRedirected: boolean;


  constructor(
    private dialogService: EkonDialogsService,
    private router: Router,
    private translateService: TranslateService
  ) {
  }

  canDeactivate(
    component: ConfirmDeactivate
  ): Observable<boolean | UrlTree> | boolean | UrlTree {


    if(this.gonnaBeRedirected) {
      this.gonnaBeRedirected = false;
      return true;
    }

    let respData: unknown;

    return (component.shouldOmit
            ? component.shouldOmit().pipe(
          switchMap(omit => omit ? of(true) : this.dialogService.confirm(
            this.translateService.instant('DISCARD_MESSAGE', { default: 'Are you sure you want to leave and discard your changes' }),
            this.translateService.instant('DISCARD_TITLE', { default: 'Discard changes?' }),
            undefined,
            {
              cancel: this.translateService.instant('GO_BACK', { default: 'Go back' }),
              ok: this.translateService.instant('DISCARD', { default: 'Discard' })
            }
          )),
          map(r => Boolean(r))
        )
            : of(true)
    ).pipe(
      switchMap((allowClose: boolean) => combineLatest([
        of(allowClose),
        component.shouldSave()
      ])),
      switchMap(([allowClose, shouldSave]: boolean[]) => {
        return allowClose && shouldSave
               ? component.applyChanges().pipe(
            map(r => {
              respData = r;
              return true;
            }),
            catchError(() => {
              return of(false);
            })
          )
               : of(allowClose);
      }),
      map(allowClose => {
        allowClose && component?.cleanOut?.();
        return allowClose;
      }),
      map(allowClose => component.redirectTo
                        ? allowClose && this.bakeUrlTree(component.redirectTo(respData), component)
                        : allowClose
      )
    );
  }

  bakeUrlTree(value: RedirectOptions, component: ConfirmDeactivate): UrlTree | true {

    if(_isBoolean(value)) {
      return true;
    }

    this.gonnaBeRedirected = true;

    return this.router.createUrlTree(
      _isArray(value) ? value : value.commands,
      {
        relativeTo: component.route,
        ...(!_isArray(value) ? value.extras : {})
      }
    );

  }
}
