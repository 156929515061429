import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Comment, Configuration, CreateBlogUserPostModel, CreateComment,
  CreateDomainUserPostModel,
  CreateExternalLinkUserPostModel, CreateFeedback, CreateFilesUserPostModel,
  CreateLegalEntityUserPostModel, CreateMeetupUserPostModel, CreatePageUserPostModel,
  CreatePlainUserPostModel,
  CreateProductUserPostModel, CreateReshareUserPostModel,
  FeedbackDetails,
  PagePaginationModel,
  UserPostBlogModel,
  UserPostDetailedReactionModelPagedResult,
  UserPostDomainModel,
  UserPostExternalLinkModel,
  UserPostFilesModel,
  UserPostLegalEntityModel, UserPostMeetupModel,
  UserPostModel,
  UserPostModelPagedResult,
  UserPostPageModel,
  UserPostPlainModel,
  UserPostProductModel, UserPostReshareModel,
  UserPostsService,
  UserPostsServiceInterface,
  UserPostUpdateModel
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

// import { SignalrService } from './signalr/signalr.service';
import { UserPostsEventsServiceInterface } from './UserPostsEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class UserPostsEventsService
  implements UserPostsServiceInterface, UserPostsEventsServiceInterface {
  get configuration(): Configuration {
    return this.userPostsService.configuration;
  }

  set configuration(val: Configuration) {
    this.userPostsService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.userPostsService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.userPostsService.defaultHeaders = val;
  }

  private userPostCreatedSubject: Subject<UserPostModel> = new Subject();

  get userPostCreated(): Observable<UserPostModel> {
    return this.userPostCreatedSubject.asObservable();
  }

  private userPostDeletedSubject: Subject<string> = new Subject();

  get userPostDeleted(): Observable<string> {
    return this.userPostDeletedSubject.asObservable();
  }

  private userPostUpdatedSubject: Subject<string> = new Subject();

  get userPostUpdated(): Observable<string> {
    return this.userPostUpdatedSubject.asObservable();
  }

  private commentCreatedSubject: Subject<Comment> = new Subject();

  get commentCreated(): Observable<Comment> {
    return this.commentCreatedSubject.asObservable();
  }


  constructor(
    private userPostsService: UserPostsService,
    private progressBar: EkonProgressBarService,
    // private signalr: SignalrService
  ) {
  }

  findUserPostReactionsById(
    id: string,
    pagination: PagePaginationModel,
    reactionType?: string
  ): Observable<UserPostDetailedReactionModelPagedResult> {
    return this.progressBar.apply(
      this.userPostsService.findUserPostReactionsById(id, pagination, reactionType)
    );
  }

  createPlainPost(
    createPlainUserPostModel: CreatePlainUserPostModel
  ): Observable<UserPostPlainModel> {
    return this.progressBar.apply(
      this.userPostsService.createPlainPost(createPlainUserPostModel)
    ).pipe(
      tap((m: UserPostPlainModel) => this.userPostCreatedSubject.next(m))
    );
  }

  createUserPostOnBlog(createBlogUserPostModel: CreateBlogUserPostModel): Observable<UserPostBlogModel> {
    return this.progressBar.apply(
      this.userPostsService.createUserPostOnBlog(createBlogUserPostModel)
    ).pipe(
      tap((m: UserPostBlogModel) => this.userPostCreatedSubject.next(m))
    );
  }

  createUserPostOnDomain(createDomainUserPostModel: CreateDomainUserPostModel): Observable<UserPostDomainModel> {
    return this.progressBar.apply(
      this.userPostsService.createUserPostOnDomain(createDomainUserPostModel)
    ).pipe(
      tap((m: UserPostDomainModel) => this.userPostCreatedSubject.next(m))
    );
  }

  createUserPostOnExternalLink(createExternalLinkUserPostModel: CreateExternalLinkUserPostModel): Observable<UserPostExternalLinkModel> {
    return this.progressBar.apply(
      this.userPostsService.createUserPostOnExternalLink(createExternalLinkUserPostModel)
    ).pipe(
      tap((m: UserPostExternalLinkModel) => this.userPostCreatedSubject.next(m))
    );
  }

  createUserPostOnFiles(createFilesUserPostModel: CreateFilesUserPostModel): Observable<UserPostFilesModel> {
    return this.progressBar.apply(
      this.userPostsService.createUserPostOnFiles(createFilesUserPostModel)
    ).pipe(
      tap((m: UserPostFilesModel) => this.userPostCreatedSubject.next(m))
    );
  }

  createUserPostOnLegalEntity(createLegalEntityUserPostModel: CreateLegalEntityUserPostModel): Observable<UserPostLegalEntityModel> {
    return this.progressBar.apply(
      this.userPostsService.createUserPostOnLegalEntity(createLegalEntityUserPostModel)
    ).pipe(
      tap((m: UserPostLegalEntityModel) => this.userPostCreatedSubject.next(m))
    );
  }

  createUserPostOnPage(createPageUserPostModel: CreatePageUserPostModel): Observable<UserPostPageModel> {
    return this.progressBar.apply(
      this.userPostsService.createUserPostOnPage(createPageUserPostModel)
    ).pipe(
      tap((m: UserPostPageModel) => this.userPostCreatedSubject.next(m))
    );
  }

  createUserPostOnProduct(createProductUserPostModel: CreateProductUserPostModel): Observable<UserPostProductModel> {
    return this.progressBar.apply(
      this.userPostsService.createUserPostOnProduct(createProductUserPostModel)
    ).pipe(
      tap((m: UserPostProductModel) => this.userPostCreatedSubject.next(m))
    );
  }

  createUserPostOnMeetup(createMeetupUserPostModel?: CreateMeetupUserPostModel): Observable<UserPostMeetupModel> {
    return this.progressBar.apply(
      this.userPostsService.createUserPostOnMeetup(createMeetupUserPostModel)
    ).pipe(
      tap((m: UserPostMeetupModel) => this.userPostCreatedSubject.next(m))
    );
  }

  deleteUserPost(id: string): Observable<unknown> {
    return this.progressBar.apply(
      this.userPostsService.deleteUserPost(id)
    ).pipe(
      tap(() => this.userPostDeletedSubject.next(id))
    );
  }

  findUserPostById(id: string): Observable<UserPostModel> {
    return this.progressBar.apply(
      this.userPostsService.findUserPostById(id)
    );
  }

  listUserPosts(pagePaginationModel: PagePaginationModel): Observable<UserPostModelPagedResult> {
    return this.progressBar.apply(
      this.userPostsService.listUserPosts(pagePaginationModel)
    );
  }

  listUserPostsByUserId(pagePaginationModel: PagePaginationModel): Observable<UserPostModelPagedResult> {
    return this.progressBar.apply(
      this.userPostsService.listUserPostsByUserId(pagePaginationModel)
    );
  }

  listUserPostsRefEntityId(referenceId: string, pagePaginationModel: PagePaginationModel, postType?: string): Observable<UserPostModelPagedResult> {
    return this.progressBar.apply(
      this.userPostsService.listUserPostsRefEntityId(referenceId, pagePaginationModel, postType)
    );
  }

  updatePost(id: string, userPostUpdateModel: UserPostUpdateModel): Observable<unknown> {
    return this.progressBar.apply(
      this.userPostsService.updatePost(id, userPostUpdateModel)
    ).pipe(
      tap(() => this.userPostUpdatedSubject.next(id))
    );
  }

  reactionToUserPost(id: string, reactionType?: string): Observable<unknown> {
    return this.progressBar.apply(
      this.userPostsService.reactionToUserPost(id, reactionType)
    );
  }

  revertReactionToUserPost(id: string): Observable<unknown> {
    return this.progressBar.apply(
      this.userPostsService.revertReactionToUserPost(id)
    );
  }

  listEntitiesPostingTypes(): Observable<Array<string>> {
    return this.progressBar.apply(
      this.userPostsService.listEntitiesPostingTypes()
    );
  }

  createUserPostComment(id: string, createComment: CreateComment): Observable<Comment> {
    return this.progressBar.apply(
      this.userPostsService.createUserPostComment(id, createComment)
    ).pipe(
      tap((comment: Comment) => this.commentCreatedSubject.next(comment))
    );
  }

  createUserPostReshare(createReshareUserPostModel: CreateReshareUserPostModel): Observable<UserPostReshareModel> {
    return this.progressBar.apply(
      this.userPostsService.createUserPostReshare(createReshareUserPostModel)
    ).pipe(
      tap((m: UserPostReshareModel) => this.userPostCreatedSubject.next(m))
    );
  }

  createUserPostFeedback(id: string, createFeedback: CreateFeedback): Observable<FeedbackDetails> {
    return this.progressBar.apply(
      this.userPostsService.createUserPostFeedback(id, createFeedback)
    );
  }

  /* <UserPostModel| UserPostPlainModel | UserPostPageModel | UserPostProductModel | UserPostBlogModel | UserPostDomainModel | UserPostLegalEntityModel | UserPostFilesModel | UserPostExternalLinkModel | UserPostReshareModel > */
  getLatestPost(): Observable<UserPostModel> {
    // return this.progressBar.apply(
      return this.userPostsService.getLatestPost()
    // );
  }

}
