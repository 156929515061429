<mat-card>
  <mat-card-header>
    <div mat-card-avatar>
      <ekon-icon [icon]="typeIcons[data.contentType]" size="2x"></ekon-icon>
    </div>
    <mat-card-title>{{ (useReasonAsTitle && data.reason) || data.data?.name || data.contentId }}</mat-card-title>
    <mat-card-subtitle>
      <span>{{ data.contentType }}</span>
      <small
        *ngIf="data?.currentState"
        class="text-boxed ml-8"
        [ngClass]="{
         'ekon-warn-bg' : data?.currentState?.processingStatus === 'Blocked',
         'ekon-accent-bg' : data?.currentState?.processingStatus === 'Allowed'
        }"
      >{{data?.currentState?.processingStatus}}</small>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div
      *ngIf="data.reason"
      class="message-box error"
    >
      <h3>Report reason</h3>
      <div>{{ data.reason }}</div>
    </div>
    <ng-container *ngIf="data.currentState?.reason !== data.reason && data.currentState?.processingStatus as status">
      <div
        *ngIf="status === 'Blocked'"
        class="message-box warning"
      >
        <h3>Admin blocking reason</h3>
        <div>{{ data.currentState?.reason }}</div>
      </div>
      <div
        *ngIf="status === 'Allowed'"
        class="message-box success"
      >
        <h3>Admin unblocking reason</h3>
        <div>{{ data.currentState?.reason }}</div>
      </div>
    </ng-container>

    <div>
      <!--<h3>Content preview</h3>-->
      <em *ngIf="!data?.data">Content not loaded</em>
      <ng-container
        *ngIf="data?.data"
        [ngSwitch]="data.contentType"
      >
        <!--<dkm-page-card
          *ngSwitchCase="'Page'"
          [page]="data?.data"
          [disableAllActions]="true"
        ></dkm-page-card>-->
      </ng-container>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <div>
      <button
        *ngIf="reportMode"
        mat-button
        type="button"
        (click)="viewContent.emit(data)"
      >{{ 'VIEW' | translate}}</button>
    </div>
    <div>
      <button
        *ngIf="reportMode"
        mat-button
        type="button"
        (click)="acceptReport.emit(data)"
      >{{(data?.currentState?.processingStatus === 'Blocked' ? 'ACCEPT' : 'BLOCK') | translate}}</button>
      <button
        *ngIf="data?.currentState?.processingStatus !== 'Allowed'"
        mat-button
        type="button"
        (click)="unblock.emit(data)"
      >{{'UNBLOCK' | translate}}</button>
    </div>
  </mat-card-actions>
</mat-card>
