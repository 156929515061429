import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';
import { EkonPwaModule } from '@ekon-client/shared/common/ekon-pwa';
import { TranslateModule } from '@ngx-translate/core';

import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    RouterModule,

    CommonModule,

    MatButtonModule,
    MatToolbarModule,
    FlexLayoutModule,
    EkonIconsModule,
    TranslateModule,
    MatMenuModule,
    EkonPwaModule
  ],
  exports: [FooterComponent]
})
export class FooterModule {}
