<ekon-dialog-wrapper>
  <div mat-dialog-title class="h2 mb-24"
       fxLayoutAlign="start center">{{'All baskets' | titlecase}}</div>
  <mat-dialog-content fxFlex="1 0 auto">
    <ekon-paginator
      [config]="paginatorConfig"
      #paginator="ekonPaginator"
    >
      <mat-action-list>
        <mat-list-item
          *ngFor="let basket of paginator.items$ | async; trackBy: trackByFn"
          class="custom-option"
          (click)="selectNClose(basket)"
        >
          <div fxLayout="row" fxLayoutAlign="start center" class="py-8">
            <span>{{basket.name}}</span>
            <span
              class="item-quantity primary ml-8"
              *ngIf="basket.countProductInBasket"
            >{{basket.countProductInBasket}}</span>
          </div>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-action-list>
    </ekon-paginator>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxFlex="0 0 auto">
    <button mat-button [mat-dialog-close]="false">Close</button>
  </mat-dialog-actions>
</ekon-dialog-wrapper>
