import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const expandCollapseExtended = trigger('expandCollapseExtended', [
  state(
    'void',
    style({
      height: '0px',
      padding: '0',
      overflow: 'hidden',
    })
  ),
  state(
    '*',
    style({
      height: '*',
      padding: '*',
    })
  ),
  transition('void => *', animate('150ms ease-out')),
  transition('* => void', animate('150ms ease-in')),
]);

export const expandCollapseHorizontalExtended = trigger(
  'expandCollapseHorizontalExtended',
  [
    state(
      'void',
      style({
        width: '0px',
        minWidth: '0px',
        padding: '0',
        overflow: 'hidden',
        flex: '0 0 0'
      })
    ),
    state(
      '*',
      style({
        width: '*',
        minWidth: '*',
        padding: '*',
        flex: '*',
      })
    ),
    transition('void => *', animate('100ms ease-out')),
    transition('* => void', animate('100ms ease-in')),
  ]
);

export const ekonFade = trigger(
  'ekonFade',
  [
    state(
      'void',
      style({
        opacity: '0',
        visibility: 'hidden'
        // display: 'none'
      })
    ),
    state(
      '*',
      style({
        opacity: '*',
        visibility: '*'
        // display: '*'
      })
    ),
    transition('void => *', [
      animate('1000ms ease-out')
    ]),
    transition('* => void', [
      animate('1000ms ease-in')
    ]),
  ]
);

export const dkmFadeInOut = trigger('dkmFadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('1s', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('1s', style({ opacity: 0 })),
  ]),
]);

export const slideInOut = trigger('slideInOut', [
  state(
    'void',
    style({
      transform: 'translateX(-100%)',
    })
  ),
  state(
    '*',
    style({
      transform: '*',
    })
  ),
  transition('void => *', animate('150ms ease-out')),
  transition('* => void', animate('150ms ease-in')),
]);

export default [
  expandCollapseExtended,
  expandCollapseHorizontalExtended,
  dkmFadeInOut,
  slideInOut,
];
