import { InjectionToken } from '@angular/core';
import { LandingPage } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const LANDING_PAGE_ACTIONS = new InjectionToken('Landing Page model actions');
export const LANDING_PAGE_EVENTS = new InjectionToken('Landing Page model events');

export interface LandingPageEventsServiceInterface {
  landingPageCreated: Observable<LandingPage>;
  landingPageDeleted: Observable<string>;
  landingPageUpdated: Observable<string>;
}
