import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  Category,
  PageServiceInterface
} from '@ekon-client/dkm-api';
import { PAGE_ACTIONS } from '@ekon-client/dkm-events';
import { getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import {
  ekIconCalendar, ekIconChevronDown, ekIconChevronUp,
  ekIconSorting,
  ekIconSortingAsc,
  ekIconSortingDesc,
  ekIconStarEmpty, ekIconTextInput
} from '@ekon-client/shared/common/ekon-icons';
import {
  CATEGORIES_SELECTOR_DIALOG_MODES,
  CategoriesSelectorDialogComponent, CategoriesSelectorDialogData,
  CategoriesService
} from '@ekon-client/shared/features/dkm-categories';
import {
  get as _get,
  isArray as _isArray,
  isBoolean as _isBoolean,
  isEqual as _isEqual,
  isNil as _isNil,
  isObject as _isObject,
  keyBy as _keyBy,
  mapValues as _mapValues
} from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';

import { EkonFiltersUpdated } from '../../extras/EkonFiltersUpdated';
import {
  DefaultSortingProps,
  EkonSortingUpdated,
  SortingDirection,
  SortingType,
  SortingTypes, SortingTypesConfig
} from '../../extras/EkonSorting';

const DefaultSortingTypes: SortingTypes = {
  name: {
    prop: 'name',
    label: 'By name',
    icon: ekIconTextInput,
    defaultDir: SortingDirection.ASC
  },
  lastUpdateAt: {
    prop: 'lastUpdateAt',
    label: 'By update date',
    icon: ekIconCalendar,
    defaultDir: SortingDirection.DESC
  },
  averageRate: {
    prop: 'averageRate',
    label: 'By rate',
    icon: ekIconStarEmpty,
    defaultDir: SortingDirection.DESC
  }
};

@Component({
  selector: 'dkm-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersComponent implements OnInit, OnDestroy, OnInit {
  ekIconSorting = ekIconSorting;
  ekIconSortingAsc = ekIconSortingAsc;
  ekIconSortingDesc = ekIconSortingDesc;
  ekIconChevronDown = ekIconChevronDown;
  ekIconChevronUp = ekIconChevronUp;
  SortingDirection = SortingDirection;

  @Input() set tags(tags: string[]) {
    this.form.get('tags').setValue(tags, { emitEvent: false });
  }

  @Input() categoriesFilterDisabled: boolean;
  @Input() templatesFilterEnabled: boolean;
  @Input() tagsFilterDisabled: boolean;

  @Input() set sortingConfig(config: SortingTypesConfig | boolean) {
    if(_isArray(config)) {
      this.sortingEnabled = true;
      this._sortingConfig = _mapValues(
        _keyBy(config, 'prop'),
        (val) => _isObject(val) ? val : DefaultSortingTypes[val]
      );
      this._sortingTypes$.next(Object.values(this._sortingConfig));
      this.selectSortingType(_isObject(config[0]) ? config[0] : DefaultSortingTypes[config[0]]);
    } else {
      this.sortingEnabled = config;
    }
  }

  @Input() set filteringConfig(config: /*SortingTypesConfig |*/ boolean) {
    // if(_isArray(config)) {
    //   this.sortingEnabled = true;
    //   this._sortingConfig = _mapValues(
    //     _keyBy(config, 'prop'),
    //     (val) => _isObject(val) ? val : DefaultSortingTypes[val]
    //   );
    //   this._sortingTypes$.next(Object.values(this._sortingConfig));
    //   this.selectSortingType(_isObject(config[0]) ? config[0] : DefaultSortingTypes[config[0]]);
    // } else {
      this.filteringEnabled = config;
    // }
  }

  @Output() filtersUpdated: EventEmitter<EkonFiltersUpdated> = new EventEmitter<EkonFiltersUpdated>();
  @Output() sortingUpdated: EventEmitter<EkonSortingUpdated> = new EventEmitter<EkonSortingUpdated>();

  form: FormGroup;

  btnToggleGroup = new FormControl(null);

  filtersOpened: boolean;
  filtersCount$: Observable<number>;

  private _sortingEnabled = false;
  private _sortingEnabled$ = new BehaviorSubject(this._sortingEnabled);
  sortingEnabled$: Observable<boolean> = this._sortingEnabled$.asObservable();

  set sortingEnabled(enabled: boolean) {
    this._sortingEnabled = enabled;
    this._sortingEnabled$.next(enabled);
  }

  get sortingEnabled(): boolean {
    return this._sortingEnabled;
  }

  private _filteringEnabled = true;
  private _filteringEnabled$ = new BehaviorSubject(this._filteringEnabled);
  filteringEnabled$: Observable<boolean> = this._filteringEnabled$.asObservable();

  set filteringEnabled(enabled: boolean) {
    this._filteringEnabled = enabled;
    this._filteringEnabled$.next(enabled);
  }

  get filteringEnabled(): boolean {
    return this._filteringEnabled;
  }

  private _sortingDirection: SortingDirection = DefaultSortingTypes.lastUpdateAt.defaultDir;
  private _sortingDirection$ = new BehaviorSubject(this._sortingDirection);
  sortingDirection$: Observable<SortingDirection> = this._sortingDirection$.asObservable();

  set sortingDirection(dir: SortingDirection) {
    this._sortingDirection = dir;
    this._sortingDirection$.next(dir);
  }

  get sortingDirection(): SortingDirection {
    return this._sortingDirection;
  }


  private _sortingConfig: SortingTypes = DefaultSortingTypes;
  private _sortingTypes$ = new BehaviorSubject(Object.values(this._sortingConfig));
  sortingTypes$: Observable<SortingType[]> = this._sortingTypes$.asObservable();

  private _sortingType$ = new BehaviorSubject(DefaultSortingTypes.lastUpdateAt);
  sortingType$: Observable<SortingType> = this._sortingType$.asObservable();

  categoriesSelectedLabel: BehaviorSubject<string> = new BehaviorSubject(null);

  private unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private hostElRef: ElementRef<Element>,
    fb: FormBuilder,
    private dialog: MatDialog,
    private categoriesService: CategoriesService,
    @Inject(PAGE_ACTIONS) private pageActions: PageServiceInterface,
    private router: Router,
    private route: ActivatedRoute
  ) {

    const snapshotQParams = this.route.snapshot.queryParams;

    this.filtersOpened = Boolean(snapshotQParams.tags || snapshotQParams.categories);

    this.form = fb.group({
      // search: [snapshotQParams.search && [snapshotQParams.search]],
      search: [null],
      categories: [null],
      // tags: [snapshotQParams.tags ? _isArray(snapshotQParams.tags) ? snapshotQParams.tags : [snapshotQParams.tags] : []],
      tags: [null],
      templates: [null],
      tagFilterMode: [false]
    });

    this.route.queryParams.pipe(
      // skip(1),
      debounceTime(400),
      filter(() => !history.state.internalHook),
      takeUntil(this.unsubscribeAll)
    ).subscribe({
      next: (params: Params) => {
        this.hostElRef.nativeElement.scrollIntoView(true);

        if(params.tags || params.categories) {
          this.filtersOpened = true;
        }
        if(params.tags) {
          this.form.get('tags').setValue(Array.from(new Set([
            ...(this.form.get('tags').value ? this.form.get('tags').value : []),
            ...(_isArray(params.tags) ? params.tags : (params.tags ? [params.tags] : []))
          ])));
        } else {
          this.form.get('tags').setValue([]);
        }
        params.search && this.form.get('search').setValue(params.search);

        params.sortingProp && this._sortingConfig[params.sortingProp] && this._sortingType$.next(
          this._sortingConfig[params.sortingProp]
        );
        params.dir && (this.sortingDirection = params.dir);      }
    });

    this.filtersCount$ = this.form.valueChanges.pipe(
      map(data => _get(data, 'tags.length', 0) + _get(data, 'categories.length', 0))
    );

    this.form.valueChanges.pipe(
      debounceTime(400),
      tap({
        next: model => this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            tags: model.tags,
            search: model.search
          },
          queryParamsHandling: 'merge',
          state: {
            internalHook: true
          }
        })
      }),
      distinctUntilChanged((x: Params, y: Params) => _isEqual(x, y)),
      takeUntil(this.unsubscribeAll)
    ).subscribe({
      next: model => this.filtersUpdated.emit(model)
    });

    this.form.get('categories').valueChanges.pipe(
      takeUntil(this.unsubscribeAll)
    ).subscribe({
      next: (categories: Category[]) => {
        let label = null;
        if(categories && categories.length) {
          label =
            categories.length === 1
            ? categories[0].name
            : `${categories.length} selected`;
        }
        this.categoriesSelectedLabel.next(label);
      }
    });

    combineLatest([
      this.sortingType$,
      this.sortingDirection$
    ]).pipe(
      debounceTime(500),
      filter(([type, dir]: [SortingType, SortingDirection]) => Boolean(type && dir)),
      tap({
        next: ([{ prop: sortingProp }, dir]: [SortingType, SortingDirection]) => this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { sortingProp, dir },
          queryParamsHandling: 'merge',
          state: {
            internalHook: true
          }
        })
      }),
      distinctUntilChanged((x: Params, y: Params) => _isEqual(x, y)),
      takeUntil(this.unsubscribeAll)
    ).subscribe({
      next: ([{ prop }, dir]: [SortingType, SortingDirection]) =>
        this.sortingUpdated.emit({ prop, dir })
    });
  }

  ngOnInit(): void {
    this.filtersUpdated.emit(this.form.getRawValue());
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  openCategorySelector(): void {
    this.dialog
      .open(CategoriesSelectorDialogComponent, {
        ...getModalDefaultConfig(),
        data: {
          mode: CATEGORIES_SELECTOR_DIALOG_MODES.MULTIPLE,
          preselected: this.form.get('categories').value
        } as CategoriesSelectorDialogData
      }).afterClosed()
      .pipe(
        filter((r) => Boolean(r))
      )
      .subscribe((categories: Category[]) => {
        this.form.get('categories').setValue(categories);
      });
  }

  clearCategoriesSelected(): void {
    this.form.get('categories').setValue(undefined);
  }

  clearSearchInput(): void {
    !_isNil(this.form.get('search').value)
    && this.form.get('search').setValue(null);
  }

  toggleTagFilterMode(): void {
    const tagFilterMode = this.form.get('tagFilterMode');
    tagFilterMode.setValue(!tagFilterMode.value);
  }

  selectSortingType(type: SortingType): void {
    this._sortingType$.next(type);
    this.sortingDirection = type.defaultDir;
  }

  toggleSortingDir(): void {
    this.sortingDirection = this.sortingDirection === SortingDirection.ASC
                            ? SortingDirection.DESC
                            : SortingDirection.ASC;
  }
}
