/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BlogInvitationModel } from './blogInvitationModel';
import { InvitationLevel } from './invitationLevel';
import { PageInvitationModel } from './pageInvitationModel';
import { DomainInvitationModel } from './domainInvitationModel';
import { LegalEntityInvitationModel } from './legalEntityInvitationModel';


export interface InvitationModel { 
    kind: string;
    id?: string;
    inviteeUserEmail?: string | null;
    /**
     * The invited user  invitee = a person who is invited to something
     */
    inviteeUserId?: string;
    /**
     * User invitaion note (optional)
     */
    invitationNote?: string | null;
    /**
     * The user who invites. The inviting user
     */
    invitedByUserId?: string;
    invitationAcceptionDate?: Date | null;
    invitationDeclineDate?: Date | null;
    invitationAccepted?: boolean;
    invitationDeclined?: boolean;
    membershipLevel?: InvitationLevel;
    /**
     * Use for invitation name/title
     */
    name?: string | null;
    /**
     * Use for short invitation message
     */
    shortDescription?: string | null;
    createdAt?: Date;
    readonly createdBy?: string | null;
}

