/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *   Platform  Domain  Page  Blogpost  KBE  Analytics  Ontology  Simulation
 */
export type FeedbackContextType = 'Platform' | 'Domain' | 'Page' | 'Blogpost' | 'KBE' | 'Analytics' | 'Ontology' | 'Simulation';

export const FeedbackContextType = {
    Platform: 'Platform' as FeedbackContextType,
    Domain: 'Domain' as FeedbackContextType,
    Page: 'Page' as FeedbackContextType,
    Blogpost: 'Blogpost' as FeedbackContextType,
    Kbe: 'KBE' as FeedbackContextType,
    Analytics: 'Analytics' as FeedbackContextType,
    Ontology: 'Ontology' as FeedbackContextType,
    Simulation: 'Simulation' as FeedbackContextType
};

