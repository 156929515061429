import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LegalPerson } from '@ekon-client/dkm-api';

@Component({
  selector: 'dkm-core-entity-person-view',
  templateUrl: './legal-person-view.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalPersonViewComponent {
  @Input() data: LegalPerson;
}
