import { ChangeDetectionStrategy,Component } from '@angular/core';
import { DomainSelectorService } from '@ekon-client/shared/features/dkm-domains-services';

@Component({
  selector: 'ekon-filter-badge',
  templateUrl: './filter-badge.component.html',
  styleUrls: ['./filter-badge.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBadgeComponent {

  constructor(
    public domainSelector: DomainSelectorService
  ) { }

  openPanel(): void {
    this.domainSelector.togglePanel();
  }
}
