import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  TrackByFunction
} from '@angular/core';
import { Router } from '@angular/router';
import { NoteDetails, NoteServiceInterface } from '@ekon-client/dkm-api';
import {
  NOTE_ACTIONS,
  NOTE_EVENTS,
  NoteEventsServiceInterface
} from '@ekon-client/dkm-events';
import { PaginationModel, TopListConfig } from '@ekon-client/shared/common/ekon-pagination';
import { DomainSelectorService } from '@ekon-client/shared/features/dkm-domains-services';

import { NotesService } from '../../services';

@Component({
  selector: 'dkm-note-today-list',
  templateUrl: './note-today-list.component.html',
  styleUrls: ['./note-today-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteTodayListComponent {

  notesPaginatorConfig: TopListConfig = {
    loader: (pagination: PaginationModel) => {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);

      return this.noteActions.listNoteTodaysRemiders(pagination, today);
    },
    observables: [
      this.noteEvent.noteCreated,
      this.noteEvent.noteUpdated,
      this.noteEvent.noteDeleted,
      this.domainSelectorService.domainsSelectionChanged$
    ]
  };
  trackById: TrackByFunction<NoteDetails> = (index, item) => item.id;

  showNotes = false;

  constructor(
    private router: Router,
    @Inject(NOTE_ACTIONS) private noteActions: NoteServiceInterface,
    @Inject(NOTE_EVENTS) private noteEvent: NoteEventsServiceInterface,
    private notesService: NotesService,
    private domainSelectorService: DomainSelectorService
  ) {}

  editNote(note: NoteDetails): void {
    this.notesService.editNote(note).subscribe();
  }

  exploreNotes(): void {
    this.router.navigate(['notes']);
  }
}
