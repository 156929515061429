/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { BlockedContentType } from '../model/models';
import { HiddenContentDTO } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { ProblemDetails } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface BlockContentServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * DKM4U.Core.Entities.ReportBlockContent.BlockedContentType              contentType &#x3D; Comment,Post,Blog,Page,File,Domain,User
     * 
     * @param contentId target content id
     * @param contentType target content type  Comment  Post  Blog  Page  File
     */
    hideContent(contentId: string, contentType: BlockedContentType, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * DKM4U.Core.Entities.ReportBlockContent.BlockedContentType              contentType &#x3D; Comment,Post,Blog,Page,File,Domain,User
     * 
     */
    hidenContent(extraHttpRequestParams?: any): Observable<Array<HiddenContentDTO>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param blockId 
     * @param reason 
     */
    unblockContent(blockId: string, reason?: string, extraHttpRequestParams?: any): Observable<{}>;

}
