import { InjectionToken } from '@angular/core';
import { UserProfile } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const USERPROFILE_ACTIONS = new InjectionToken(
  'UserProfile model actions'
);
export const USERPROFILE_EVENTS = new InjectionToken(
  'UserProfile model events'
);

export interface UserProfileEventsServiceInterface {
  userProfileCreated: Observable<UserProfile>;
  userProfileDeleted: Observable<string>;
  userProfileUpdated: Observable<string>;
  touAccepted: Observable<string>;
}
