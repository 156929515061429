import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { Observable } from 'rxjs';

import { ReusableFormBase } from '../../ReusableFormBase';

@Component({
  selector: 'ekon-tags-mapping',
  templateUrl: './tags-mapping.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsMappingComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TagsMappingComponent),
      multi: true
    }
  ]
})
export class TagsMappingComponent extends ReusableFormBase<unknown> {
  @Input() autocompleteList: (query: string) => Observable<string[]>;
  @Input() label: string;
  @Input() placeholder: string;

  form: FormGroup = new FormGroup({
    tagsProp: new FormControl(null),
    tagsSeparator: new FormControl(null),
    tagsSpaceSeparator: new FormControl(null)
  });

  constructor() {
    super();
    this.initValueChanges();
  }

  validate(/*_control: AbstractControl*/): ValidationErrors | null {
    return ReusableFormBase.validate(this.form);
  }

  makeOutputValue(data: unknown): unknown | null {
    console.warn('TagsMapping makeOutputValue', data);
    return ReusableFormBase.objToValueOrNull(data);
  }
}
