/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';


export interface ViewUserProfile { 
    id?: string;
    /**
     * User avatar
     */
    avatarUrl?: string | null;
    /**
     * User cover image on own profile.
     */
    coverImageUrl?: string | null;
    /**
     * Main email
     */
    email?: string | null;
    /**
     * Given/First name
     */
    givenName?: string | null;
    /**
     * Last Name
     */
    lastName?: string | null;
    /**
     * Middle name
     */
    middleName?: string | null;
    /**
     * Main phone number
     */
    phoneNumber?: string | null;
    /**
     * Dr,Professor,QC,Eur Ing, Chancellor, Vice-Chancellor, Principal, President, Master, Warden, Dean, Regent, Rector, Provost, Director, Chief Executive etc...
     */
    title?: string | null;
    birthDate?: Date | null;
    about?: string | null;
    headline?: string | null;
    userAddress?: Address;
    /**
     * Help find users by interested Tags and filter by those
     */
    tags?: Array<string> | null;
    isHiddenByUser?: boolean;
    userRole?: string | null;
}

