export * from './basket.service';
import { BasketService } from './basket.service';
export * from './basket.serviceInterface'
export * from './blockContent.service';
import { BlockContentService } from './blockContent.service';
export * from './blockContent.serviceInterface'
export * from './blog.service';
import { BlogService } from './blog.service';
export * from './blog.serviceInterface'
export * from './calendar.service';
import { CalendarService } from './calendar.service';
export * from './calendar.serviceInterface'
export * from './category.service';
import { CategoryService } from './category.service';
export * from './category.serviceInterface'
export * from './comment.service';
import { CommentService } from './comment.service';
export * from './comment.serviceInterface'
export * from './dLT.service';
import { DLTService } from './dLT.service';
export * from './dLT.serviceInterface'
export * from './domain.service';
import { DomainService } from './domain.service';
export * from './domain.serviceInterface'
export * from './elementTemplates.service';
import { ElementTemplatesService } from './elementTemplates.service';
export * from './elementTemplates.serviceInterface'
export * from './faq.service';
import { FaqService } from './faq.service';
export * from './faq.serviceInterface'
export * from './feedback.service';
import { FeedbackService } from './feedback.service';
export * from './feedback.serviceInterface'
export * from './files.service';
import { FilesService } from './files.service';
export * from './files.serviceInterface'
export * from './geoLayer.service';
import { GeoLayerService } from './geoLayer.service';
export * from './geoLayer.serviceInterface'
export * from './invitation.service';
import { InvitationService } from './invitation.service';
export * from './invitation.serviceInterface'
export * from './kbe.service';
import { KbeService } from './kbe.service';
export * from './kbe.serviceInterface'
export * from './landingPage.service';
import { LandingPageService } from './landingPage.service';
export * from './landingPage.serviceInterface'
export * from './legal.service';
import { LegalService } from './legal.service';
export * from './legal.serviceInterface'
export * from './legalEntity.service';
import { LegalEntityService } from './legalEntity.service';
export * from './legalEntity.serviceInterface'
export * from './note.service';
import { NoteService } from './note.service';
export * from './note.serviceInterface'
export * from './notificationTemplate.service';
import { NotificationTemplateService } from './notificationTemplate.service';
export * from './notificationTemplate.serviceInterface'
export * from './page.service';
import { PageService } from './page.service';
export * from './page.serviceInterface'
export * from './product.service';
import { ProductService } from './product.service';
export * from './product.serviceInterface'
export * from './rate.service';
import { RateService } from './rate.service';
export * from './rate.serviceInterface'
export * from './reportContent.service';
import { ReportContentService } from './reportContent.service';
export * from './reportContent.serviceInterface'
export * from './response.service';
import { ResponseService } from './response.service';
export * from './response.serviceInterface'
export * from './rss.service';
import { RssService } from './rss.service';
export * from './rss.serviceInterface'
export * from './statistics.service';
import { StatisticsService } from './statistics.service';
export * from './statistics.serviceInterface'
export * from './tag.service';
import { TagService } from './tag.service';
export * from './tag.serviceInterface'
export * from './userPosts.service';
import { UserPostsService } from './userPosts.service';
export * from './userPosts.serviceInterface'
export * from './userProfile.service';
import { UserProfileService } from './userProfile.service';
export * from './userProfile.serviceInterface'
export const APIS = [BasketService, BlockContentService, BlogService, CalendarService, CategoryService, CommentService, DLTService, DomainService, ElementTemplatesService, FaqService, FeedbackService, FilesService, GeoLayerService, InvitationService, KbeService, LandingPageService, LegalService, LegalEntityService, NoteService, NotificationTemplateService, PageService, ProductService, RateService, ReportContentService, ResponseService, RssService, StatisticsService, TagService, UserPostsService, UserProfileService];
