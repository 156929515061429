<form
  [formGroup]="form"
  (ngSubmit)="validateAndSave()"
  fxLayout="column"
  class="h-100-p"
>
  <mat-dialog-content fxLayout="column" fxLayoutAlign="start stretch" fxFlex="1 0 auto">
    <div mat-dialog-title class="h2 mb-24" fxLayoutAlign="start center">Sharing Options</div>

    <mat-tab-group>
      <mat-tab [label]="'VISIBILITY' | translate">
        <label id="visibility-group-label"><h3>Select {{ 'VISIBILITY' | translate | lowercase }} option</h3></label>
        <mat-radio-group
          aria-labelledby="visibility-group-label"
          formControlName="visibleTo"
        >
          <mat-radio-button
            *ngFor="let visibilityOption of VisibilityTypes"
            [value]="visibilityOption"
          >
            <div>{{labels.visibility[visibilityOption].label}}</div>
            <mat-hint
              *ngIf="labels.visibility[visibilityOption].description as description"
            >{{ description }}</mat-hint>
          </mat-radio-button>
        </mat-radio-group>
      </mat-tab>

      <!--<mat-tab [label]="'EDITABILITY' | translate">
        <label id="editability-group-label"><h3>Pick {{ 'EDITABILITY' | translate | lowercase }} option</h3></label>
        <mat-radio-group
          aria-labelledby="editability-group-label"
          formControlName="editableBy"
        >
          <mat-radio-button
            *ngFor="let editabilityOption of EditabilityTypes"
            [value]="editabilityOption"
          >
            <div>{{labels.editability[editabilityOption].label}}</div>
            <mat-hint
              *ngIf="labels.editability[editabilityOption].description as description"
            >{{ description }}</mat-hint>
          </mat-radio-button>
        </mat-radio-group>
      </mat-tab>-->
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="end center">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-button type="submit" [disabled]="!form.valid">Save</button>
  </mat-dialog-actions>
</form>
