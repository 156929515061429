import { InjectionToken } from '@angular/core';
import { FeedbackDetails } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const FEEDBACK_ACTIONS = new InjectionToken('Feedback model actions');
export const FEEDBACK_EVENTS = new InjectionToken('Feedback model events');

export interface FeedbackEventsServiceInterface {
  feedbackItemCreated: Observable<FeedbackDetails>;
  feedbackItemUpdated: Observable<string>; // feedback item id
  feedbackItemDeleted: Observable<string>; // feedback item id
}
