import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const REPORT_CONTENT_ACTIONS = new InjectionToken('Report Content model actions');
export const REPORT_CONTENT_EVENTS = new InjectionToken('Report Content model events');

export interface ReportContentEventsServiceInterface {
  contentBlocked: Observable<string>;
  contentReported: Observable<string>;
}
