/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *   Private (owner/creator/explicit grant (aka allowed group) only  Allowed to edit  (uses explicit grant))  Internal (Editable to domain users.  Implicit context grant)  Protected (Editable to all registered users  Implicit authorized user grant)  Public (Editable to public users  (Anonymous user))  All (Editable to any users of the system (public and private))
 */
export type EditabilityTypes = 'Private' | 'Internal' | 'Protected' | 'Public' | 'All';

export const EditabilityTypes = {
    Private: 'Private' as EditabilityTypes,
    Internal: 'Internal' as EditabilityTypes,
    Protected: 'Protected' as EditabilityTypes,
    Public: 'Public' as EditabilityTypes,
    All: 'All' as EditabilityTypes
};

