import { Component, OnDestroy, OnInit } from '@angular/core';
import { EkonAuthService } from '@ekon-client/auth';
import { EkonLayoutConfig } from '@ekon-layout-utils';
import { EkonLayoutConfigService } from '@ekon-layout-utils/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ekon-client-vertical-layout-2',
  templateUrl: './layout-2.component.html',
  styleUrls: ['./layout-2.component.scss'],
})
export class VerticalLayout2Component implements OnInit, OnDestroy {
  ekonLayoutConfig: EkonLayoutConfig;

  // Private
  private _unsubscribeAll: Subject<void> = new Subject();

  /**
   * Constructor
   *
   * @param ekonLayoutConfigService
   * @param _authService
   */
  constructor(
    private ekonLayoutConfigService: EkonLayoutConfigService,
    private _authService: EkonAuthService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this.ekonLayoutConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.ekonLayoutConfig = config;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
