/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { BlogInvitationModel } from '../model/models';
import { CreateInvitationModel } from '../model/models';
import { DomainContributors } from '../model/models';
import { DomainInvitationModel } from '../model/models';
import { DomainInviteesModel } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { InvitationModel } from '../model/models';
import { InvitationResponseAllModel } from '../model/models';
import { InvitationResponseModel } from '../model/models';
import { LegalEntityInvitationModel } from '../model/models';
import { PageContributors } from '../model/models';
import { PageInvitationModel } from '../model/models';
import { PageInviteesModel } from '../model/models';
import { ProblemDetails } from '../model/models';
import { UserInvitationsListModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface InvitationServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createInvitationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createBlogInvitation(createInvitationModel?: CreateInvitationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createInvitationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createDomainInvitation(createInvitationModel?: CreateInvitationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createInvitationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createLegalEntityInvitation(createInvitationModel?: CreateInvitationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createInvitationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createPageInvitation(createInvitationModel?: CreateInvitationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteInvitation(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param domainInviteesModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    domainInvitees(domainInviteesModel?: DomainInviteesModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<DomainContributors>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findInvitationById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<InvitationModel | DomainInvitationModel | PageInvitationModel | BlogInvitationModel | LegalEntityInvitationModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findPendingInvitations(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserInvitationsListModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findPendingInvitationsByEntityId(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<InvitationModel | DomainInvitationModel | PageInvitationModel | BlogInvitationModel | LegalEntityInvitationModel>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param email 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findUsersInvitations(email: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserInvitationsListModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param invitationResponseModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    invitationRespond(id: string, invitationResponseModel?: InvitationResponseModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<InvitationModel | DomainInvitationModel | PageInvitationModel | BlogInvitationModel | LegalEntityInvitationModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param invitationResponseAllModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    invitationRespondAll(invitationResponseAllModel?: InvitationResponseAllModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pageInviteesModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    pageInvitees(pageInviteesModel?: PageInviteesModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<PageContributors>>;

}
