<ngx-file-drop dropZoneLabel="To upload file drop it here or "
               dropZoneClassName="file-upload-drop-zone {{dropZoneClass}}"
               contentClassName="file-upload-drop-zone__content accent--fg"
               (onFileDrop)="dropped($event)"
               [showBrowseBtn]="true"
               [multiple]="multiple">
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <div class="my-8 upload-control-text">
      <ekon-icon icon="upload" size="lg" class="mr-16"></ekon-icon>
      <span>To upload file drop it here or </span>
    </div>
    <button type="button" mat-raised-button class="ml-8 my-8"
            (click)="openFileSelector()">Browse file
    </button>
  </ng-template>
</ngx-file-drop>
