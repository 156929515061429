import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ekIconAngleRight,
  ekIconChevronDown,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';

@NgModule({
    imports: [CommonModule, RouterModule, EkonIconsModule, TranslateModule],
  declarations: [BreadcrumbsComponent],
  exports: [BreadcrumbsComponent],
})
export class EkonBreadcrumbsModule {
  constructor(
    ekLib: EkonIconsLibraryService
  ) {
    ekLib.addIcons(
      ekIconAngleRight,
      ekIconChevronDown
    );
  }
}
