import { InjectionToken } from '@angular/core';
import { CalendarItem } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const CALENDAR_EVENTS = new InjectionToken('Calendar model events');
export const CALENDAR_ACTIONS = new InjectionToken('Calendar model actions');

export interface CalendarEventsServiceInterface {
  calendarItemCreated: Observable<CalendarItem>;
  calendarItemDeleted: Observable<string>;
  calendarItemUpdated: Observable<string>;
}
