<mat-form-field appearance="outline" class="w-100-p">
  <mat-label>Pages</mat-label>
  <mat-chip-list #pagesList aria-label="Pages selection" [disabled]="disabled">
    <mat-chip *ngFor="let page of items"
              selectable="true"
              removable="true"
              (removed)="removeItem(page)">
      {{page.name}}
      <ekon-icon icon="times-circle" size="lg"></ekon-icon>
    </mat-chip>
    <input placeholder="Add page..."
           #itemInput
           [formControl]="inputControl"
           [matChipInputFor]="pagesList"
           [matAutocomplete]="autocmplItems"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           matChipInputAddOnBlur="true"
           (matChipInputTokenEnd)="addItem($event)">
  </mat-chip-list>
  <mat-autocomplete #autocmplItems="matAutocomplete" (optionSelected)="autoCompleteItemSelected($event)">
    <mat-option *ngFor="let page of filtered | async" [value]="page">
      {{page.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
