<ekon-dialog-wrapper>
  <form [formGroup]="form" (ngSubmit)="createBasket()">
    <div mat-dialog-title class="h3 p-0">{{ data ? 'Edit' : 'Create' }}</div>

    <mat-dialog-content fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="shortDescription"></textarea>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end" fxLayoutGap="8px" class="py-24">
      <button
        mat-button
        type="button"
        [mat-dialog-close]="false"
      >Cancel
      </button>
      <button
        mat-button
        [disabled]="form.invalid"
        class="mat-primary"
      >{{ data ? 'Save' : 'Create' }}</button>
    </mat-dialog-actions>
  </form>
</ekon-dialog-wrapper>
