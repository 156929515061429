import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  FileMetadata, FileMetadataPagedResult, FileOutputTypes,
  FilesService,
  FilesServiceInterface, PagePaginationModel,
  UpdateFileMetadata
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FileEventsServiceInterface } from './FileEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class FileEventsService
  implements FileEventsServiceInterface, FilesServiceInterface {
  get configuration(): Configuration {
    return this.fileService.configuration;
  }

  set configuration(val: Configuration) {
    this.fileService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.fileService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.fileService.defaultHeaders = val;
  }

  /**
   * The mechanism for monitoring Upload action
   */
  private fileUploadedSubject: Subject<FileMetadata> = new Subject();

  get fileUploaded(): Observable<FileMetadata> {
    return this.fileUploadedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Delete action
   */
  private fileDeletedSubject: Subject<Blob> = new Subject();

  get fileDeleted(): Observable<Blob> {
    return this.fileDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Update Meta action
   */
  private fileMetaUpdatedSubject: Subject<string> = new Subject();

  get fileMetaUpdated(): Observable<string> {
    return this.fileMetaUpdatedSubject.asObservable();
  }

  constructor(
    private fileService: FilesService,
    private progressBar: EkonProgressBarService
  ) {
  }

  download(id: string): Observable<Blob> {
    return this.progressBar.apply(this.fileService.download(id));
  }

  findFileMetadata(id: string): Observable<unknown> {
    return this.progressBar.apply(this.fileService.findFileMetadata(id));
  }

  listFileByDomains(
    pagination: PagePaginationModel,
    xSelectedDomain?: string
  ): Observable<FileMetadataPagedResult> {
    return this.progressBar.apply(this.fileService.listFileByDomains(
      pagination,
      xSelectedDomain
    ));
  }

  listFileByUser(
    pagination: PagePaginationModel,
    xSelectedDomain?: string
  ): Observable<FileMetadataPagedResult> {
    return this.progressBar.apply(this.fileService.listFileByUser(
      pagination,
      xSelectedDomain
    ));
  }

  upload(file?: Blob, xSelectedDomain?: string): Observable<FileMetadata> {
    return this.progressBar
      .apply(this.fileService.upload(file, xSelectedDomain))
      .pipe(
        tap((result: FileMetadata) => this.fileUploadedSubject.next(result))
      );
  }

  updateFileMetadata(
    id: string,
    updateFileMetadata: UpdateFileMetadata,
  ): Observable<unknown> {
    return this.progressBar
      .apply(this.fileService.updateFileMetadata(id, updateFileMetadata))
      .pipe(tap(() => this.fileMetaUpdatedSubject.next(id)));
  }

  deleteFile(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.fileService.deleteFile(id))
      .pipe(tap((res: Blob) => this.fileDeletedSubject.next(res)));
  }

  downloadImage(
    id: string,
    width?: number,
    height?: number,
    outputType?: FileOutputTypes
  ): Observable<Blob> {
    return this.progressBar.apply(this.fileService.downloadImage(
      id,
      width,
      height,
      outputType
    ));
  }

  personalUpload(file?: Blob): Observable<FileMetadata> {
    return this.progressBar
      .apply(this.fileService.personalUpload(file))
      .pipe(
        tap((result: FileMetadata) => this.fileUploadedSubject.next(result))
      );
  }


}
