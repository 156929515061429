import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy,OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Observable } from 'rxjs';

import { Paginator, PaginatorObservables, PaginatorResolver } from '../../extras/Pagination';

export interface TopListConfig {
  loader: PaginatorResolver<unknown>;
  pageSize?: number;
  observables?: PaginatorObservables;
  noItemsLabel?: string;
}

@Component({
  selector: 'ekon-top-list',
  exportAs: 'ekonTopList',
  templateUrl: './top-list.component.html',
  styleUrls: [
    '../paginator/paginator-base.component.scss',
    './top-list.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopListComponent implements OnInit, OnDestroy {
  @Input() config: TopListConfig;
  @Input() viewAllBtnAlwaysDisplay: boolean;
  @Input() viewAllBtnAlwaysHidden: boolean;

  @Output() viewAll: EventEmitter<void> = new EventEmitter();

  items$: Observable<unknown[]>;
  paginator: Paginator<unknown>;

  ngOnInit(): void {
    this.paginator = new Paginator<unknown>(
      this.config.loader,
      {
        pageNumber: 1,
        pageSize: this.config.pageSize || 10,
        pageSizeOptions: []
      },
      this.config.observables
    );

    this.items$ = this.paginator.items$;
  }

  ngOnDestroy(): void {
    this.paginator.unsubscribe();
  }

  setPage($event: PageEvent): void {
    this.paginator.pushPaginationState({
      pageSize: $event.pageSize,
      pageNumber: $event.pageIndex + 1
    });
  }

  forceReload(): void {
    this.paginator.forceReload();
  }

  emitViewAll(): void {
    this.viewAll.emit();
  }
}
