import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class HybridOAuthStorage implements OAuthStorage {

  // Set items in sessionStorage or localStorage
  setItem(key: string, value: string): void {
    if (key === 'access_token') {
      sessionStorage.setItem(key, value); // Use sessionStorage for access_token
    } else {
      localStorage.setItem(key, value);   // Use localStorage for refresh_token or other tokens
    }
  }

  // Get items from sessionStorage or localStorage
  getItem(key: string): string | null {
    if (key === 'access_token') {
      return sessionStorage.getItem(key); // Get from sessionStorage for access_token
    }
    return localStorage.getItem(key);     // Get from localStorage for other tokens
  }

  // Remove items from sessionStorage or localStorage
  removeItem(key: string): void {
    if (key === 'access_token') {
      sessionStorage.removeItem(key); // Remove from sessionStorage
    } else {
      localStorage.removeItem(key);   // Remove from localStorage
    }
  }

  // Clear both sessionStorage and localStorage
  clear(): void {
    sessionStorage.clear();
    localStorage.clear();
  }
}
