/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Comment } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { ProblemDetails } from '../model/models';
import { Results } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface CommentServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteComment(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findCommentById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Comment>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param refId 
     * @param xSelectedDomain Comma-delimited domain id
     */
    getEntityCommentsCount(refId: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Results>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param refId 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listEntityComment(refId: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<Comment>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listUserComments(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<Comment>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param comment 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateComment(id: string, comment?: Comment, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

}
