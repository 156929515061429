import { InjectionToken } from '@angular/core';
import { Comment, LayoutItem, PageDetails } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const PAGE_ACTIONS = new InjectionToken('Page model actions');
export const PAGE_EVENTS = new InjectionToken('Page model events');

export interface PageEventsServiceInterface {
  itemCreated: Observable<PageDetails>;
  itemUpdated: Observable<string>;
  itemDeleted: Observable<string>;
  commentCreated: Observable<Comment>;
  itemElementUpdated: Observable<LayoutItem>;

  emitItemElementUpdated(element: LayoutItem): void;
}
