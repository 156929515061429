/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { FileMetadata } from '../model/models';
import { FileMetadataPagedResult } from '../model/models';
import { FileOutputTypes } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';
import { UpdateFileMetadata } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface FilesServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteFile(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    download(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Blob>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param width 
     * @param height 
     * @param outputType   Jpeg  Png  Gif
     * @param xSelectedDomain Comma-delimited domain id
     */
    downloadImage(id: string, width?: number, height?: number, outputType?: FileOutputTypes, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Blob>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findFileMetadata(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FileMetadata>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listFileByDomains(pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FileMetadataPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listFileByUser(pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FileMetadataPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param file 
     * @param xSelectedDomain Comma-delimited domain id
     */
    personalUpload(file?: Blob, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FileMetadata>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param updateFileMetadata 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateFileMetadata(id: string, updateFileMetadata?: UpdateFileMetadata, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param file 
     * @param xSelectedDomain Comma-delimited domain id
     */
    upload(file?: Blob, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FileMetadata>;

}
