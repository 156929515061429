import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FilterBadgeComponent } from './filter-badge.component';



@NgModule({
  declarations: [
    FilterBadgeComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FilterBadgeComponent
  ]
})
export class EkonFilterBadgeModule { }
