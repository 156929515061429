import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UserProfileServiceInterface } from '@ekon-client/dkm-api';
import { BreadcrumbTitleResolver } from '@ekon-client/shared/common/ekon-breadcrumbs';
import { map } from 'rxjs/operators';

import { USERPROFILE_ACTIONS } from '../UserProfileEventsServiceInterface';

export const UserProfileBreadcrumbResolver: BreadcrumbTitleResolver = (route: ActivatedRouteSnapshot, inj: Injector) => {
  return (inj.get(USERPROFILE_ACTIONS) as UserProfileServiceInterface).findProtectedUserProfileById(route.params.userId)
    .pipe(
      map(u => `${u.title || ''} ${u.givenName || ''} ${u.lastName || ''}`)
    );
};
