import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { RateDetails, RefType } from '@ekon-client/dkm-api';
import { ekIconStarEmpty, ekIconStarFull, ekIconStarHalf } from '@ekon-client/shared/common/ekon-icons';
import { RatingService } from '@ekon-client/shared/features/dkm-rating';
import { isNumber as _isNumber } from 'lodash-es';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'ekon-rating',
  templateUrl: './ekon-rating.component.html',
  styleUrls: ['./ekon-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EkonRatingComponent implements OnDestroy {
  ekIconStarEmpty = ekIconStarEmpty;
  ekIconStarHalf = ekIconStarHalf;
  ekIconStarFull = ekIconStarFull;

  private _userViewEnabled$ = new BehaviorSubject(false);
  userViewEnabled = false;

  private _averageRate$: BehaviorSubject<number> = new BehaviorSubject(0);
  averageRate$: Observable<number> = this._averageRate$.asObservable();

  private _userRate$ = new BehaviorSubject(-1);
  userRate$: Observable<number> = this._userRate$.asObservable();

  get rate$(): Observable<number> {
    return (this.userViewEnabled ? this.userRate$ : this.averageRate$).pipe(
      map(r => r === -1 ? 0 : r)
    );
  };

  @Input() set rate(r: number) {
    this._averageRate$.next(_isNumber(r) ? r < 0 ? 0 : (r > 5 ? 5 : r) : 0);
  }

  @Input() displayValue?: boolean;
  @Input() size?: 'small' | 'normal' = 'normal';

  @Input() refType?: RefType;
  @Input() refId?: string;
  @Input() editEnabled = false;

  @HostBinding('class.edit-enabled') get editEnabledClass(): boolean {
    return this.editEnabled;
  }


  selection = 0;

  constructor(
    private ratingService: RatingService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this._userViewEnabled$.complete();
    this._userRate$.complete();
    this._averageRate$.complete();
  }

  setRate(rate: number): void {
    this.refId
    && this.refType
    && this.ratingService.setRate(rate, this.refId, this.refType)
      .pipe(
        switchMap(() =>
          this.refId
          ? this.ratingService.getAverageRate(this.refId)
          : EMPTY
        )
      )
      .subscribe({
        next: (r: number) => {
          this._averageRate$.next(r);
          this._userRate$.next(rate);
        }
      });
  }

  setSelection(selection: number): void {
    this.selection = selection;
  }

  clearSelection(): void {
    this.selection = 0;
  }

  toggleViewMode(): void {
    this.userViewEnabled = !this.userViewEnabled;

    this.userViewEnabled && this.userRate$.pipe(
      take(1),
      switchMap(rate =>
        rate === -1 && this.refId
        ? this.ratingService.getRate(this.refId)
        : EMPTY
      )
    ).subscribe({
      next: (r: RateDetails) => this._userRate$.next(r.rate as number)
    });

    this._cdr.detectChanges();
  }
}
