import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { EkonBreadcrumbsModule } from '@ekon-client/shared/common/ekon-breadcrumbs';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { TermsOfUseDisplayComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    EkonBreadcrumbsModule,
    TranslateModule
  ],
  declarations: [TermsOfUseDisplayComponent],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-tou/',
      multi: true
    }
  ]
})
export class DkmTouModule {}
