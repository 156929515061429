import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthRedirectComponent } from './components/auth-redirect/auth-redirect.component';
import { AuthGuardDirective } from './directories/auth-guard.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AuthGuardDirective,
    AuthRedirectComponent
  ],
  exports: [
    AuthGuardDirective,
    AuthRedirectComponent
  ]
})
export class EkonAuthModule {

  constructor(@Optional() @SkipSelf() parentModule?: EkonAuthModule) {
    if (parentModule) {
      throw new Error(
        'EkonAuthModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<EkonAuthModule> {
    return {
      ngModule: EkonAuthModule
    };
  }
}
