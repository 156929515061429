<div fxLayout="column">
  <div
    fxFlex="0 0 auto"
    class="mb-24"
    fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px"
  >
    <span>{{title}}</span>
    <button
      mat-button color="primary"
      type="button"
      (click)="addControl()"
    >
      <span>{{buttonLabel}}</span>
      <ekon-icon icon="plus" class="ml-8"></ekon-icon>
    </button>
  </div>

  <ng-container *ngFor="let c of controls; let i = index">
    <div [fxLayout]="inlineControls ? 'row' : 'column'">
      <ng-container *ngTemplateOutlet="cChild; context: { $implicit: c, index: i }"></ng-container>
      <button mat-button type="button" color="warn" (click)="deleteControl(i)" fxFlex="0 0 auto">delete</button>
    </div>
    <mat-divider *ngIf="!inlineControls && i < controls.length - 1" class="mb-36 mt-16 set-divider"></mat-divider>
  </ng-container>
</div>
