import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BasketDetails,
  BasketHeaderPagedResult,
  BasketHeaderWithProductCountPagedResult,
  BasketService,
  BasketServiceInterface,
  Configuration,
  CreateBasket, PagePaginationModel,
  ProductsGoodsDetails
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { BasketEventsServiceInterface } from './BasketEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class BasketEventsService
  implements BasketEventsServiceInterface, BasketServiceInterface {
  get defaultHeaders(): HttpHeaders {
    return this.basketService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.basketService.defaultHeaders = val;
  }

  get configuration(): Configuration {
    return this.basketService.configuration;
  }

  set configuration(val: Configuration) {
    this.basketService.configuration = val;
  }

  private basketCreatedSubject = new Subject<BasketDetails>();

  get basketCreated(): Observable<BasketDetails> {
    return this.basketCreatedSubject.asObservable();
  }

  private basketDeletedSubject = new Subject<string>();

  get basketDeleted(): Observable<string> {
    return this.basketDeletedSubject.asObservable();
  }

  private basketUpdatedSubject = new Subject<string>();

  get basketUpdated(): Observable<string> {
    return this.basketUpdatedSubject.asObservable();
  }

  private basketItemQuantityUpdatedSubject = new Subject<string>();

  get basketItemQuantityUpdated(): Observable<string> {
    return this.basketItemQuantityUpdatedSubject.asObservable();
  }

  constructor(
    private basketService: BasketService,
    private progressBar: EkonProgressBarService
  ) {
  }

  downladBasketDocument(/*_id: string*/): Observable<unknown> {
    throw new Error('Method not implemented.');
  }

  createBasket(createBasket: CreateBasket): Observable<BasketDetails> {
    return this.progressBar
      .apply(this.basketService.createBasket(createBasket))
      .pipe(
        tap((basket: BasketDetails) => this.basketCreatedSubject.next(basket))
      );
  }

  updateBasket(id: string, basketDetails: BasketDetails): Observable<unknown> {
    return this.progressBar
      .apply(this.basketService.updateBasket(id, basketDetails))
      .pipe(tap(() => this.basketUpdatedSubject.next(id)));
  }

  deleteBasket(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.basketService.deleteBasket(id))
      .pipe(tap(() => this.basketDeletedSubject.next(id)));
  }

  addProductToBasket(
    id: string,
    productId: string,
    productsGoodsDetails: ProductsGoodsDetails,
  ): Observable<unknown> {
    return this.progressBar
      .apply(
        this.basketService.addProductToBasket(
          id,
          productId,
          productsGoodsDetails,
        )
      )
      .pipe(tap(() => this.basketUpdatedSubject.next(id)));
  }

  findBasketById(id: string): Observable<BasketDetails> {
    return this.progressBar.apply(this.basketService.findBasketById(id));
  }

  listBaskets(
    pagination?: PagePaginationModel,

    isOpen?: boolean,
    legalEntityId?: string,
    from?: Date,
    to?: Date
  ): Observable<BasketHeaderPagedResult> {
    return this.progressBar.apply(
      this.basketService.listBaskets(pagination, isOpen, legalEntityId, from, to)
    );
  }

  updateBasketItemQuantity(
    id: string,
    productId: string,
    quantity: number
  ): Observable<unknown> {
    return this.progressBar
      .apply(
        this.basketService.updateBasketItemQuantity(id, productId, quantity)
      )
      .pipe(
        // tap(() => this.basketItemQuantityUpdatedSubject.next(id)),
        tap(() => this.basketUpdatedSubject.next(id)),
        catchError(() => of(0))
      );
  }

  submitAndCloseBasket(id: string, shallSubmit?: boolean): Observable<unknown> {
    return this.progressBar
      .apply(this.basketService.submitAndCloseBasket(id, shallSubmit))
      .pipe(tap(() => this.basketUpdatedSubject.next(id)));
  }

  duplicateBasket(id: string): Observable<BasketDetails> {
    return this.progressBar
      .apply(this.basketService.duplicateBasket(id))
      .pipe(
        tap((basket: BasketDetails) => this.basketCreatedSubject.next(basket))
      );
  }

  listBasketsWithProductPageCount(
    pagination?: PagePaginationModel,
    isOpen?: boolean,
    legalEntityId?: string,
    productPageId?: string,
    from?: Date,
    to?: Date,
    xSelectedDomain?: string
  ): Observable<BasketHeaderWithProductCountPagedResult> {
    return this.progressBar.apply(
      this.basketService.listBasketsWithProductPageCount(
        pagination,
        isOpen,
        legalEntityId,
        productPageId,
        from,
        to,
        xSelectedDomain
      )
    );
  }

}
