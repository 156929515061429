import { Injectable } from '@angular/core';

import { EkonIconDefinition, EkonIconType } from '../icon-definitions';

@Injectable({
  providedIn: 'root'
})
export class EkonIconsLibraryService {

  private registry: Map<EkonIconType, EkonIconDefinition> = new Map();

  addIcons(...icons: EkonIconDefinition[]): void {
    icons.forEach(icon => this.registry.set(icon.name, icon));
  }

  getIcon(name: EkonIconType): EkonIconDefinition | undefined {
    if (!this.registry.has(name)) {
      console.warn(`Icon ${name} is not found. Did u add it to library registry?`)
    }
    return this.registry.get(name);
  }
}
