import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DkmDomain, DkmDomainHeaderModel } from '@ekon-client/dkm-api';
import { ekIconTimes } from '@ekon-client/shared/common/ekon-icons';

@Component({
  selector: 'ekon-domain-list-mini',
  templateUrl: './domain-list-mini.component.html',
  styleUrls: ['./domain-list-mini.component.scss']
})
export class DomainListMiniComponent  {
  ekIconTimes = ekIconTimes;

  @Input() domains: Array<DkmDomainHeaderModel | DkmDomain>;
  @Input() displayRemoveBtn: boolean;
  @Input() multiColumn: boolean;

  @Output() selectItem: EventEmitter<DkmDomainHeaderModel | DkmDomain> = new EventEmitter();
  @Output() removeItem: EventEmitter<DkmDomainHeaderModel | DkmDomain> = new EventEmitter();
}
