<div class="dialog-wrapper-actions" fxLayout="row" fxLayoutAlign="end center">
  <button
    mat-icon-button
    *ngIf="!disableToggler"
    (click)="toggleFullscreen()"
    class="fullscreen-action"
  >
    <ekon-icon [icon]="isFullscreen ? 'compress-alt' : 'expand-arrows-alt'"></ekon-icon>
  </button>
  <button
    mat-icon-button
    type="button"
    *ngIf="transparentMode || !closeBtnDisabled"
    (click)="close()"
  >
    <ekon-icon icon="times"></ekon-icon>
  </button>
</div>
<ng-content></ng-content>
