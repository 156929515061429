/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CreateFaqModel } from '../model/models';
import { FaqDetails } from '../model/models';
import { FaqDetailsPagedResult } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface FaqServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createFaqModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createFaq(createFaqModel?: CreateFaqModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FaqDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteFaq(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findFaqById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FaqDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listFaqs(pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FaqDetailsPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listPlatformFaqs(pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FaqDetailsPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param faqDetails 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateFaq(id: string, faqDetails?: FaqDetails, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param fromId 
     * @param toId 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateFaqSequence(fromId: string, toId: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

}
