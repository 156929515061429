<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
  <!-- <bar-rating *ngIf="rate.rate" [(rate)]="rate.rate" [max]="5"
              (rateChange)="setRate($event)"></bar-rating> -->
  <!--  <bar-rating [(rate)]="rate" [max]="5" [theme]="'fontawesome'"></bar-rating>-->
  <star-rating
    *ngIf="rate"
    [value]="rate.rate"
    (rate)="setRate($event)"
  ></star-rating>
  <div>Average rate: {{ averageRate }}</div>
</div>
