import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccessLevelTypes, DkmDomain, DomainServiceInterface, JoinLeaveActionType } from '@ekon-client/dkm-api';
import { DOMAIN_ACTIONS } from '@ekon-client/dkm-events';
import { EkonDialogsService, getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import { EkonMessageService, EkonMessageTypes } from '@ekon-client/shared/common/ekon-utils';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  DomainsJoinDialogComponent,
  DomainsJoinDialogData
} from '../components/domains-join-dialog/domains-join-dialog.component';


@Injectable({
  providedIn: 'root',
})
export class DomainMembershipService {
  constructor(
    private dialogsService: EkonDialogsService,
    @Inject(DOMAIN_ACTIONS) private domainActions: DomainServiceInterface,
    private messageService: EkonMessageService,
    private dialog: MatDialog
  ) { }

  leaveDomain(domain: DkmDomain): void {
    this.dialogsService
      .confirm(
        `Are u sure u want to delete domain: "${domain.name}"?`,
        'Delete domain'
      )
      .pipe(
        filter((r) => !!r),
        switchMap(() =>
          this.domainActions.userJoinWithrawDomain(
            domain.id,
            {
              domainId: domain.id,
              membershipAction: 'Leave',
            },
          )
        )
      )
      .subscribe(
        () =>
          this.messageService.show(
            `You left domain "${domain.name}" successfully`,
            EkonMessageTypes.SUCCESS
          ),
        () =>
          this.messageService.show(
            `Error occurred while leaving domain "${domain.name}"`,
            EkonMessageTypes.ERROR
          )
      );
  }

  joinDomain(): void {
    this.domainActions
      // todo: pagination
      .listProtectedDomains({
        pageSize: 10,
        pageNumber: 1
      })
      .pipe(
        map(r => r.items),
        mergeMap((domains: DkmDomain[]) =>
          this.dialog
            .open(DomainsJoinDialogComponent, {
              ...getModalDefaultConfig(),
              data: { domains } as DomainsJoinDialogData,
            })
            .afterClosed()
        ),
        filter((r) => !!r),
        mergeMap((domain: DkmDomain) =>
          this.domainActions
            .userJoinWithrawDomain(
              domain.id,
              {
                domainId: domain.id,
                membershipAction: JoinLeaveActionType.Join,
                membershipLevel: AccessLevelTypes.Guest,
              },

            )
            .pipe(map(() => domain))
        )
      )
      .subscribe(
        (domain: DkmDomain) =>
          this.messageService.show(
            `Membership request to domain "${domain.name}" was sent successfully`,
            EkonMessageTypes.SUCCESS
          ),
        (domain: DkmDomain) =>
          this.messageService.show(
            `Error occurred while sending membership request to domain "${domain.name}"`,
            EkonMessageTypes.ERROR
          )
      );
  }
}
