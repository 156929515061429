import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BlogServiceInterface } from '@ekon-client/dkm-api';
import { BreadcrumbTitleResolver } from '@ekon-client/shared/common/ekon-breadcrumbs';
import { map } from 'rxjs/operators';

import { BLOG_ACTIONS } from '../BlogEventsServiceInterface';


export const BlogBreadcrumbResolver: BreadcrumbTitleResolver = (route: ActivatedRouteSnapshot, inj: Injector) =>
  (inj.get(BLOG_ACTIONS) as BlogServiceInterface).findBlogPostById(route.params.id)
    .pipe(
      map(p => p.title)
    );
