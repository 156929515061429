import { EkonIconDefinition } from '@ekon-client/shared/common/ekon-icons';
import { create, select } from 'd3';

import { ForceGraphNode, ForceGraphNodeKind, ForceSelectionSVGG, ForceToolBtn } from './extras';

export function getCleanIcon(icon: EkonIconDefinition): string {
  const strokeFixed = icon.data.replace(/stroke="#\w+/g, 'stroke="white');
  return strokeFixed.replace(/fill="#\w+/g, 'fill="white');
}

export function bakeToolButton(
  icon: EkonIconDefinition
): ForceToolBtn {
  const btn = create('svg:g')
    .classed('tool-btn', true);

  btn.append('circle');

  btn.append('image')
    .attr('href', () => `data:image/svg+xml;utf8,${getCleanIcon(icon)}`);

  return btn;
}

export function arrangeToolBtns(tools: ForceSelectionSVGG, iro: number): void {
  tools.each((
    d: ForceGraphNode<ForceGraphNodeKind>,
    toolIndex: number,
    toolNode: SVGGElement[]
  ) => {
    const rIro = d.isRoot ? 22 + iro : 22;
    select(toolNode[toolIndex])
      .selectAll('.tool-btn')
      .each((
        d: ForceGraphNode<ForceGraphNodeKind>,
        i: number,
        n: SVGGElement[]
      ) => {
        const
          toolBtn = select(n[i]),
          count = n.length,
          angle = 360 / count * i,
          x1 = -Math.sin((angle + 180) * Math.PI / 180) * rIro,
          y1 = Math.cos((angle + 180) * Math.PI / 180) * rIro;

        toolBtn.select('circle')
          .attr('cx', x1)
          .attr('cy', y1);

        toolBtn.select('image')
          .attr('x', x1 - 5)
          .attr('y', y1 - 5);
      });
  });
}
