import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  CreateLegalEntity,
  LegalEntityDetails,
  LegalEntityDetailsPagedResult, LegalEntityHeaderModel,
  LegalEntityHeaderModelPagedResult,
  LegalEntityService,
  LegalEntityServiceInterface,
  LegalEntityUserJoinWithdrawModel,
  PagePaginationModel
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LegalEntityEventsServiceInterface } from './LegalEntityEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class LegalEntityEventsService
  implements LegalEntityServiceInterface, LegalEntityEventsServiceInterface {
  get configuration(): Configuration {
    return this.legalEntityService.configuration;
  }

  set configuration(val: Configuration) {
    this.legalEntityService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.legalEntityService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.legalEntityService.defaultHeaders = val;
  }

  private entityCreatedSubject = new Subject<LegalEntityDetails>();
  entityCreated: Observable<LegalEntityDetails> = this.entityCreatedSubject.asObservable();

  private entityDeletedSubject = new Subject<string>();
  entityDeleted: Observable<string> = this.entityDeletedSubject.asObservable();

  private entityUpdatedSubject = new Subject<string>();
  entityUpdated: Observable<string> = this.entityUpdatedSubject.asObservable();

  constructor(
    private legalEntityService: LegalEntityService,
    private progressBar: EkonProgressBarService
  ) {
  }

  createLegalEntity(
    createLegalEntity: CreateLegalEntity
  ): Observable<LegalEntityDetails> {
    return this.progressBar
      .apply(this.legalEntityService.createLegalEntity(createLegalEntity))
      .pipe(tap((e: LegalEntityDetails) => this.entityCreatedSubject.next(e)));
  }

  deleteLegalEntity(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.legalEntityService.deleteLegalEntity(id))
      .pipe(tap(() => this.entityDeletedSubject.next(id)));
  }

  findLegalEntityById(id: string): Observable<LegalEntityDetails> {
    return this.progressBar.apply(
      this.legalEntityService.findLegalEntityById(id)
    );
  }

  listUserLegalEntities(pagination: PagePaginationModel): Observable<LegalEntityHeaderModelPagedResult> {
    return this.progressBar
      .apply(this.legalEntityService.listUserLegalEntities(pagination));
  }

  updateLegalEntity(
    id: string,
    legalEntityDetails: LegalEntityDetails
  ): Observable<unknown> {
    return this.progressBar
      .apply(this.legalEntityService.updateLegalEntity(id, legalEntityDetails))
      .pipe(tap(() => this.entityUpdatedSubject.next(id)));
  }

  userJoinWithrawLegalEntity(id: string, userJoinWithdrawModel: LegalEntityUserJoinWithdrawModel): Observable<unknown> {
    return this.progressBar.apply(
      this.legalEntityService.userJoinWithrawLegalEntity(id, userJoinWithdrawModel)
    );
  }

  listLegalEntitiesByIds(requestBody: Array<string>): Observable<Array<LegalEntityDetails>> {
    return this.progressBar.apply(
      this.legalEntityService.listLegalEntitiesByIds(requestBody)
    );
  }

  listUserLegalEntitiesDetails(
    pagination: PagePaginationModel
  ): Observable<LegalEntityDetailsPagedResult> {
    return this.progressBar.apply(
      this.legalEntityService.listUserLegalEntitiesDetails(pagination)
    );
  }

  listOwnedLegalEntities(): Observable<Array<LegalEntityHeaderModel>> {
    return this.progressBar.apply(
      this.legalEntityService.listOwnedLegalEntities()
    );
  }
}
