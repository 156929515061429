import { Component, Inject, Injectable, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileMetadata } from '@ekon-client/dkm-api';
import { getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import {
  base64ToFile,
  Dimensions,
  ImageCroppedEvent,
  ImageCropperComponent,
} from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'dkm-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
})
export class ImageCropperDialogComponent {
  croppedImage: Blob;
  showCropper = false;

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      file: File,
      config : {
        aspectRatio: number
      }
    }
  ) {}

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = base64ToFile(event.base64);
  }

  imageLoaded(): void {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions): void {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed(): void {
    console.log('Load failed');
  }

  rotateLeft(): void {
    this.imageCropper.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight(): void {
    this.imageCropper.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate(): void {
    const flippedH = this.imageCropper.transform.flipH;
    const flippedV = this.imageCropper.transform.flipV;
    this.imageCropper.transform = {
      ...this.imageCropper.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal(): void {
    this.imageCropper.transform = {
      ...this.imageCropper.transform,
      flipH: !this.imageCropper.transform.flipH,
    };
  }

  flipVertical(): void {
    this.imageCropper.transform = {
      ...this.imageCropper.transform,
      flipV: !this.imageCropper.transform.flipV,
    };
  }

  saveImage(): void {
    this.dialogRef.close(
      new File([this.croppedImage], this.data.file.name, { type: this.data.file.type })
    );
  }
}


@Injectable({
  providedIn: 'root'
})
export class ImageCropService {
  constructor(
    private dialog: MatDialog
  ) {}

  openImageCropperDialog(file: File, aspectRatio?: number): Observable<File> {
    return this.dialog
      .open(ImageCropperDialogComponent, {
        ...getModalDefaultConfig(),
        data: {
          file,
          config: {
            aspectRatio
          }
        },
      })
      .afterClosed()
      .pipe(
        filter(r => Boolean(r)),
      );
  }
}
