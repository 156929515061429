/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CreateNote } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { NoteDetails } from '../model/models';
import { NoteDetailsPagedResult } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface NoteServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param createNote 
     */
    createNote(createNote?: CreateNote, extraHttpRequestParams?: any): Observable<NoteDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     */
    deleteNote(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     */
    findNoteById(id: string, extraHttpRequestParams?: any): Observable<NoteDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param reminderDate 
     * @param isPlatformNote 
     */
    listNoteTodaysRemiders(pagePaginationModel?: PagePaginationModel, reminderDate?: Date, isPlatformNote?: boolean, extraHttpRequestParams?: any): Observable<NoteDetailsPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param closingDate 
     * @param isPlatformNote 
     */
    listNotesClosingToday(pagePaginationModel?: PagePaginationModel, closingDate?: Date, isPlatformNote?: boolean, extraHttpRequestParams?: any): Observable<NoteDetailsPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param isPlatformNote 
     */
    listUserNotes(pagePaginationModel?: PagePaginationModel, isPlatformNote?: boolean, extraHttpRequestParams?: any): Observable<NoteDetailsPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     * @param noteDetails 
     */
    updateNote(id: string, noteDetails?: NoteDetails, extraHttpRequestParams?: any): Observable<{}>;

}
