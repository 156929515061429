/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * User profile sharing consent class.
 */
export interface ShareConsent { 
    /**
     * if Share is false, all sub-Shared should be false!!!
     */
    share?: boolean;
    shareAvatarAndName?: boolean;
    shareGender?: boolean;
    shareHeadline?: boolean;
    shareAbout?: boolean;
    shareBirthday?: boolean;
    shareEmail?: boolean;
    shareAddress?: boolean;
    shareTags?: boolean;
}

