<ekon-dialog-wrapper [isFullscreen]="true">
  <div mat-dialog-title>{{
    'PAGE_SELECTION' | translate: { kind: ('PAGES.KIND' | translate : { pageKind: data.kind }) }
  }}</div>
  <mat-dialog-content>
    <ekon-paginator [config]="paginatorConfig" #paginator="ekonPaginator">
      <ekon-cards-grid>
        <dkm-page-card
          *ngFor="let page of paginator.items$ | async; trackBy: trackById"
          [page]="page"
          [isProduct]="data.kind === 'product'"
          [disableAllActions]="true"
          (select)="validateAndSave(page)"
        ></dkm-page-card>
      </ekon-cards-grid>
    </ekon-paginator>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxFlex="0 0 auto">
    <button mat-button [mat-dialog-close]="false">{{'CANCEL' | translate: { default: 'Cancel' } }}</button>
  </mat-dialog-actions>
</ekon-dialog-wrapper>
