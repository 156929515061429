import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import {
  ekIconAngleLeft,
  ekIconAngleRight,
  ekIconFilter,
  ekIconSearch,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { DkmDomainsModule } from '@ekon-client/shared/features/dkm-domains';
import { TranslateModule } from '@ngx-translate/core';

import { SearchNFilterComponent } from './components/search-n-filter/search-n-filter.component';

@NgModule({
  imports: [
    CommonModule,
    DkmDomainsModule,
    MatRippleModule,
    MatButtonModule,
    EkonIconsModule,
    TranslateModule
  ],
  declarations: [SearchNFilterComponent],
  exports: [SearchNFilterComponent],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/ekon-search-n-filter/',
      multi: true
    }
  ]
})
export class EkonSearchNFilterModule {
  constructor(
    ekIconsLib: EkonIconsLibraryService
  ) {
    ekIconsLib.addIcons(
      ekIconSearch,
      ekIconFilter,
      ekIconAngleRight,
      ekIconAngleLeft
    )

  }
}
