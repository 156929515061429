/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateFileMetadata { 
    id: string;
    name?: string | null;
    nameModified?: boolean;
    shortDescription?: string | null;
    shortDescriptionModified?: boolean;
    description?: string | null;
    descriptionModified?: boolean;
    tags?: Array<string> | null;
    tagsModified?: boolean;
}

