import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DkmDomain } from '@ekon-client/dkm-api';

export interface DomainsJoinDialogData {
  domains: DkmDomain[];
}

@Component({
  selector: 'dkm-domains-join-dialog',
  templateUrl: './domains-join-dialog.component.html',
  styleUrls: ['./domains-join-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainsJoinDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<DomainsJoinDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DomainsJoinDialogData
  ) {}

  joinDomain(domain: DkmDomain): void {
    this.dialogRef.close(domain);
  }
}
