import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
  ekIconBars,
  ekIconBlogs,
  ekIconCatalog,
  ekIconCool,
  ekIconHome, ekIconLogout, ekIconMySpace, ekIconNewspaper, ekIconPages, ekIconShoppingCart,
  ekIconStores,
  EkonIconsLibraryService,
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';
import { EkonProgressBarModule } from '@ekon-client/shared/common/ekon-progress-bar';
import { DkmDomainsModule } from '@ekon-client/shared/features/dkm-domains';
import { DkmFeedbackModule } from '@ekon-client/shared/features/dkm-feedback';
import { DkmNotesModule } from '@ekon-client/shared/features/dkm-notes';
import { DkmTourModule } from '@ekon-client/shared/features/dkm-tour';
import { EkonSearchNFilterModule } from '@ekon-client/shared/features/ekon-search-n-filter';
import { TranslateModule } from '@ngx-translate/core';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

import { LanguageSetterModule } from '../language-setter/language-setter.module';
import { ProfileMenuModule } from '../profile-menu/profile-menu.module';
import { ToolbarComponent } from './toolbar.component';


@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    RouterModule,
    MatButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    DkmDomainsModule,
    DkmNotesModule,
    DkmFeedbackModule,
    ProfileMenuModule,
    CommonModule,
    FlexLayoutModule,
    MatRippleModule,
    LanguageSetterModule,
    EkonSearchNFilterModule,
    MatSlideToggleModule,
    EkonIconsModule,
    TranslateModule,
    TourMatMenuModule,
    DkmTourModule,
    EkonProgressBarModule
  ],
  exports: [ToolbarComponent]
})
export class ToolbarModule {
  constructor(ekLib: EkonIconsLibraryService) {
    ekLib.addIcons(
      ekIconStores,
      ekIconHome,
      ekIconCool,
      ekIconCatalog,
      ekIconPages,
      ekIconBlogs,
      ekIconMySpace,
      ekIconShoppingCart,
      ekIconBars,
      ekIconLogout,
      ekIconNewspaper
    );
  }
}
