/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { HttpValidationProblemDetails } from '../model/models';
import { ProblemDetails } from '../model/models';
import { TagContent } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface TagServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param tagContent 
     */
    createTag(tagContent?: TagContent, extraHttpRequestParams?: any): Observable<TagContent>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     */
    listTags(extraHttpRequestParams?: any): Observable<Array<TagContent>>;

}
