import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TagContent } from '@ekon-client/dkm-api';

import { BaseChipsAutocomplete } from '../../base/base-chips-autocomplete';
import { AutocompleteService } from '../../services';

const CUSTOM_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TagsAutocompleteComponent),
  multi: true
};

@Component({
  selector: 'dkm-tags-autocomplete',
  templateUrl: './tags-autocomplete.component.html',
  styles: [`
    :host {
      display: block;
    }
  `],
  providers: [CUSTOM_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsAutocompleteComponent extends BaseChipsAutocomplete<TagContent,
  string> {
  constructor(
    private autocomleteService: AutocompleteService,
    protected _cdr: ChangeDetectorRef
  ) {
    super(_cdr, true);

    this.filtered = autocomleteService.autocompleteTags(this.inputControl);
  }

  writeValue(obj: string[]): void {
    this.items = obj ? obj.map((tag: string) => ({ tag } as TagContent)):[];
    this._cdr.detectChanges();
  }

  prepareFormValue(items: TagContent[]): string[] {
    return items.map((item: TagContent) => item.tag);
  }

  createNewItem(value: string): TagContent {
    return {
      tag: value
    };
  }
}
