import { BidiModule } from '@angular/cdk/bidi';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';

import { ForceDirectedComponent } from './force-directed.component';

@NgModule({
  declarations: [
    ForceDirectedComponent
  ],
  imports: [
    CommonModule,
    EkonIconsModule,
    MatRippleModule,
    BidiModule,
    MatButtonModule
  ],
  exports: [
    ForceDirectedComponent
  ]
})
export class ForceDirectedModule {}
