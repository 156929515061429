import {
  ChangeDetectionStrategy,
  Component, Inject,
  Input,
  OnDestroy,
  OnInit, TrackByFunction
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EkonAuthService } from '@ekon-client/auth';
import {
  BasketDetails, BasketHeaderWithProductCount, BasketServiceInterface, ProductHeader,
  ProductsGoodsDetails
} from '@ekon-client/dkm-api';
import { BASKET_ACTIONS } from '@ekon-client/dkm-events';
import { getModalDefaultConfig } from '@ekon-client/shared/common/ekon-dialogs';
import { ekIconAddCart, ekIconPlus, SizeProp } from '@ekon-client/shared/common/ekon-icons';
import { PaginationModel, TopListConfig } from '@ekon-client/shared/common/ekon-pagination';
import { isArray as _isArray, noop as _noop } from 'lodash-es';
import { Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { BasketService } from '../../services/basket.service';
import {
  BasketSelectDialogComponent,
  BasketSelectDialogComponentData,
  BasketSelectDialogComponentResult
} from '../basket-select-dialog/basket-select-dialog.component';

@Component({
  selector: 'dkm-basket-add-item',
  templateUrl: './basket-add-item.component.html',
  styleUrls: ['./basket-add-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasketAddItemComponent implements OnInit, OnDestroy {
  ekIconPlus = ekIconPlus;
  ekIconAddCart = ekIconAddCart;

  trackByFn: TrackByFunction<BasketHeaderWithProductCount> = (index, item) => item.id;

  @Input() data: ProductHeader | ProductHeader[];
  @Input() iconSize: SizeProp;
  @Input() labelEnabled: boolean;
  @Input() responsive: boolean;

  isMultiple: boolean;

  private unsubscribeAll: Subject<void> = new Subject<void>();

  topListConfig: TopListConfig = {
    noItemsLabel: 'No baskets found',
    pageSize: 6,
    loader: (pagination: PaginationModel) =>
      this.basketActions.listBasketsWithProductPageCount(
        pagination,
        true,
        null,
        this.isMultiple ? null : (this.data as ProductHeader).id
      )
  };

  constructor(
    public auth: EkonAuthService,
    public basketService: BasketService,
    @Inject(BASKET_ACTIONS) private basketActions: BasketServiceInterface,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    if (_isArray(this.data)) {
      this.isMultiple = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  addToNewBasket(): void {
    this.basketService
      .createBasket()
      .pipe(
        switchMap((basket: BasketDetails) =>
          this.basketService.addBasketItem(
            basket,
            this.makeAddToBasketReqBody()
          )
        ),
        take(1),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(_noop);
  }

  addToBasket(basket: BasketDetails): void {
    this.basketService.addBasketItem(
      basket,
      this.makeAddToBasketReqBody()
    )
      .pipe(take(1))
      .subscribe(_noop);
  }

  makeAddToBasketReqBody(): ProductsGoodsDetails[] {
    return this.isMultiple
      ? (this.data as ProductHeader[]).map((item) => ({
        productPageId: item.id,
        productName: item.name
      }))
      : [
        {
          productPageId: (this.data as ProductHeader).id,
          productName: (this.data as ProductHeader).name
        }
      ];
  }

  viewBaskets(): void {
    this.dialog.open(BasketSelectDialogComponent, {
      ...getModalDefaultConfig(),
      maxWidth: '600px',
      data: {
        productId: this.isMultiple ? null : (this.data as ProductHeader).id
      } as BasketSelectDialogComponentData
    }).afterClosed()
      .pipe(
        filter(r => Boolean(r)),
        switchMap((result: BasketSelectDialogComponentResult) => this.basketService.addBasketItem(
          _isArray(result.selected) ? result.selected[0] : result.selected,
          this.makeAddToBasketReqBody()
        ))
      )
      .subscribe(_noop);
  }
}
