import { InjectionToken } from '@angular/core';
import { GeoLayerModel } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const GEO_ACTIONS = new InjectionToken('Geo model actions');
export const GEO_EVENTS = new InjectionToken('Geo model events');

export interface GeoEventsServiceInterface {
  layerCreated: Observable<GeoLayerModel>;
  layerUpdated: Observable<string>;
  layerDeleted: Observable<string>;

  featureUpdated: Observable<string>;
  featureDeleted: Observable<string>;
}
