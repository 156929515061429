import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DkmDomain } from '@ekon-client/dkm-api';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { DomainSelectorService } from '@ekon-client/shared/features/dkm-domains-services';
import { isNil as _isNil } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';


@Injectable()
export class SelectedDomainsInterceptor implements HttpInterceptor {
  constructor(
    private inj: Injector,
    private configService: GlobalConfigurationService
  ) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      !request.url.startsWith(this.configService.baseApi) ||
      request.url.includes('assets/') ||
      request.url.includes('domain/by/ids') ||
      request.url.includes('for_user') ||
      request.url.includes('user_profile') ||
      request.url.includes('landing_page')
    ) {
      return next.handle(request);
    }

    // todo: handle empty injection
    const domainSelector: DomainSelectorService = this.inj.get(
      DomainSelectorService
    );

    return domainSelector.domainsSelected$.pipe(
      // filter((r) => Boolean(r)),
      take(1),
      map((d: DkmDomain[]) =>
        _isNil(request.headers.get('x-selected-domain'))
        ? request.clone({
          setHeaders: {
            'x-selected-domain': (d || []).map((v) => v.id).join()
          }
        })
        : request
      ),
      switchMap((reqCloned: HttpRequest<unknown>) => next.handle(reqCloned))
    );
  }
}
