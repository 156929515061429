<ng-container *ngIf="themes$ | async as themes">
  <mat-slide-toggle
    class="example-margin"
    [color]="'accent'"
    [checked]="isDarkMode$ | async"
    [disabled]="!themes?.length"
    (toggleChange)="toggleLight()"
  ></mat-slide-toggle>

  <button
    mat-icon-button
    [matMenuTriggerFor]="themeMenu"
  >
    <ekon-icon [icon]="ekIconChevronDown" size="xs" class="menu-icon"></ekon-icon>
  </button>

  <mat-menu
    #themeMenu="matMenu"
    [overlapTrigger]="false"
  >
    <button
      mat-menu-item
      *ngFor="let theme of themes"
      (click)="setTheme(theme.key)"
      class="theme-item"
      [ngClass]="{ 'ekon-accent-bg': theme.key === (selected$ | async) }"
    >
      <!--{{theme.key.toUpperCase() | translate}}-->
      <div>
        <span class="text-boxed theme-item-mode">{{ theme.mode }}</span>
        <span>{{theme.label}}</span>
      </div>
    </button>
  </mat-menu>
</ng-container>

<ng-container *ngIf="handleToggle$ | async"></ng-container>
