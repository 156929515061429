/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NotificationTypes } from './notificationTypes';
import { RecipientTypes } from './recipientTypes';


export interface NotificationTemplateModel { 
    id: string;
    providerTemplateId: string;
    staticRecipients?: Array<string> | null;
    dataType?: string | null;
    groupId?: number;
    notificationType?: NotificationTypes;
    recipientType?: RecipientTypes;
}

