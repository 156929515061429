import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Category } from '@ekon-client/dkm-api';
import {
  DefaultSortingProps,
  EkonFiltersUpdated,
  EkonSortingUpdated,
  SortingDirection, SortingTypes, SortingTypesConfig
} from '@ekon-client/shared/features/dkm-filters';
import { get as _get, noop as _noop } from 'lodash-es';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { filter, map, skip, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import {
  PaginationState,
  Paginator,
  PaginatorInitialOptions,
  PaginatorMode,
  PaginatorObservables,
  PaginatorResolver,
  SortingNFilteringState
} from '../../extras/Pagination';


export interface PaginatorFiltersConfig {
  disabled?: boolean;
  byCategories?: boolean;
  byTags?: boolean;
  byTemplates?: boolean;
}

export type PaginatorSortingConfig = SortingTypesConfig | boolean;

export interface PaginatorCheckForUpdates {
  timeout?: number;
  monitor: Observable<unknown>;
  onCreate: Observable<unknown>;
  comparator: (oldLatest: unknown, newLatest: unknown) => boolean;
  label?: string;
}

export interface PaginatorConfig {
  loader?: PaginatorResolver<unknown>;
  initialOptions?: PaginatorInitialOptions;
  observables?: PaginatorObservables;
  filtersConfig?: PaginatorFiltersConfig,
  sortingConfig?: PaginatorSortingConfig;
  kind?: PaginatorMode;
  initialSortingNFilters?: SortingNFilteringState;
  checkForUpdates?: PaginatorCheckForUpdates
}

@Component({
  selector: 'ekon-paginator',
  exportAs: 'ekonPaginator',
  templateUrl: './paginator.component.html',
  styleUrls: [
    './paginator-base.component.scss',
    './paginator.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent implements OnInit, OnDestroy {

  @Input() set config(config: PaginatorConfig) {
    this._config = config;

    this.filtersConfig = config?.filtersConfig;
    this.sortingConfig = config?.sortingConfig;
    this.checkForUpdates = config?.checkForUpdates;
  };

  _paginator: Paginator<unknown>;
  @Input() set paginator(paginator: Paginator<unknown>) {
    this._paginator = paginator;
  };

  get paginator(): Paginator<unknown> {
    // console.warn('get paginator');
    return this._paginator;
  };

  private _filtersConfig: PaginatorFiltersConfig;
  @Input() set filtersConfig(filtersConfig: PaginatorFiltersConfig) {
    this._filtersConfig = filtersConfig;
  };

  get filtersConfig(): PaginatorFiltersConfig {
    return this._filtersConfig;
  };

  private _sortingConfig: PaginatorSortingConfig;
  @Input() set sortingConfig(sortingConfig: PaginatorSortingConfig) {
    this._sortingConfig = sortingConfig;
  };

  get sortingConfig(): PaginatorSortingConfig {
    return this._sortingConfig;
  };

  private _checkForUpdates: PaginatorCheckForUpdates;
  @Input() set checkForUpdates(checkForUpdates: PaginatorCheckForUpdates) {
    this._checkForUpdates = checkForUpdates;
  };

  get checkForUpdates(): PaginatorCheckForUpdates {
    return this._checkForUpdates;
  };

  @Input() customEmptyHandling: boolean;
  @Input() sortingNFilteringHidden: boolean;
  @Input() allPaginatorsHidden: boolean;
  @Input() topPaginatorHidden: boolean;
  @Input() modeSwitcherHidden: boolean;
  @Input() noBackground = true;

  _config: PaginatorConfig;
  items$: Observable<unknown[]>;

  private _latestItem$: BehaviorSubject<unknown> = new BehaviorSubject(null);
  latestItem$: Observable<unknown> = this._latestItem$.asObservable().pipe(
    filter(r => Boolean(r))
  );

  private _checkUpdates$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  checkUpdates$: Observable<boolean> = this._checkUpdates$.asObservable();

  ngOnInit(): void {
    if(!this.paginator) {
      if(!this._config?.loader) {
        return console.warn('Setup a loader method');
      }

      this.paginator = new Paginator<unknown>(
        this._config.loader,
        this._config?.initialOptions,
        this._config?.observables,
        this._config?.kind,
        this._config?.initialSortingNFilters
      );
    }

    this.items$ = this.paginator.items$;

    this.checkForUpdates?.onCreate?.pipe(
      tap({
        next: (latest: unknown) => this._latestItem$.next(latest)
      }),
      takeUntil(this.paginator._unsubscribe),
    ).subscribe(_noop);

    this.checkForUpdates?.monitor?.pipe(
      tap({
        next: (latest: unknown) => this._latestItem$.next(latest)
      }),
      takeUntil(this.paginator._unsubscribe),
    ).subscribe(_noop);

    if(this.checkForUpdates) {
      timer(
        0,
        (this.checkForUpdates.timeout !== undefined
         ? this.checkForUpdates.timeout
         : 1) * 1000
      ).pipe(
        switchMap(() => this.checkForUpdates.monitor),
        withLatestFrom(this.latestItem$),
        map(([newLatest, oldLatest]: unknown[]) =>
          this.checkForUpdates.comparator(oldLatest, newLatest)),
        takeUntil(this.paginator._unsubscribe),
      ).subscribe({
        next: (updatesAvailable: boolean) => this._checkUpdates$.next(updatesAvailable)
      });
    }
  }

  viewLatestItems(): void {
    this.paginator.pageSize$.pipe(
      take(1),
      tap({
        next: (pageSize: number) => {
          this.paginator.pushPaginationState({
            pageSize: pageSize,
            pageNumber: 1
          });

          this._checkUpdates$.next(false);
        }
      }),
      switchMap(() => this.paginator.items$),
      skip(1),
      take(1),
      // withLatestFrom(this.latestItem$),
      tap((items: unknown[]) => {
        this._latestItem$.next(items[0]);
      })
    ).subscribe(_noop);
  }

  ngOnDestroy(): void {
    this.paginator.unsubscribe();
  }

  setPage($event: PageEvent): void {
    this.paginator.pushPaginationState({
      pageSize: $event.pageSize,
      pageNumber: $event.pageIndex + 1
    });
  }

  forceReload(): void {
    this.paginator.forceReload();
  }

  pushFilteringState($event: EkonFiltersUpdated): void {
    this.paginator.pushSortingNFilteringState({
      freeTextSearchFilter: _get($event, 'search'),
      // todo: shouldn't reference API
      categoryFilter: _get($event, 'categories', [] as Category[])?.map(i => i.id),
      tagFilter: _get($event, 'tags'),
      tagFilterMode: _get($event, 'tagFilterMode'),
      contentType: _get($event, 'contentType')
    }, undefined);
  }

  pushSortingState($event: EkonSortingUpdated): void {
    this.paginator.pushSortingNFilteringState(undefined, {
      fieldName: $event.prop,
      order: $event.dir === SortingDirection.ASC
    });
  }

  onScroll() {
    console.warn('scrollDown');

    this.paginator.paginationState$.pipe(
      take(1),
      tap({
        next: (state: PaginationState) => {
          this.paginator.pushPaginationState({
            pageSize: state.pageSize,
            pageNumber: state.pageNumber + 1
          });
        }
      })
    ).subscribe(_noop);

  }

  onScrollUp() {
    console.warn('scrollUp');
  }

  toggleMode(): void {
    this.paginator.paginatorMode$.pipe(
      take(1)
    ).subscribe({
      next: (mode: PaginatorMode) =>
        this.paginator.setMode(mode === 'infinite' ? 'paginated' : 'infinite')
    });
  }
}
