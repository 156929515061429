<div *ngIf="data.addressName" class="text-boxed m-0 mb-16">{{data.addressName}}</div>
<!--<div dkmMap mouseWheelDisabled class="w-100-p h-148 mb-16"></div>-->
<div class="mb-16">
  <span *ngIf="data.country" class="mr-16"><small class="text-boxed m-0 mr-8">country</small>{{data.country}}</span>
  <span *ngIf="data.city"><small class="text-boxed m-0 mr-8">city</small>{{data.city}}</span>
</div>
<div class="mb-16">
  <span *ngIf="data.street" class="mr-16"><small class="text-boxed m-0 mr-8">str.</small>{{data.street}}</span>
  <span *ngIf="data.number"><small class="text-boxed m-0 mr-8">bld.</small>{{data.number}}</span>
</div>
<div *ngIf="data.postCode" class="mb-16"><small class="text-boxed m-0 mr-8">postal</small>{{data.postCode}}</div>
