import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  LandingPage,
  LandingPageService,
  LandingPageServiceInterface
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { sortBy as _sortBy } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LandingPageEventsServiceInterface } from './LandingPageEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class LandingPageEventsService
  implements LandingPageServiceInterface, LandingPageEventsServiceInterface {
  get configuration(): Configuration {
    return this.landingPageService.configuration;
  }

  set configuration(val: Configuration) {
    this.landingPageService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.landingPageService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.landingPageService.defaultHeaders = val;
  }

  /**
   * The mechanism for monitoring Create action
   */
  private landingPageCreatedSubject: Subject<LandingPage> = new Subject();

  get landingPageCreated(): Observable<LandingPage> {
    return this.landingPageCreatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Delete action
   */
  private landingPageDeletedSubject: Subject<string> = new Subject();

  get landingPageDeleted(): Observable<string> {
    return this.landingPageDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Update action
   */
  private landingPageUpdatedSubject: Subject<string> = new Subject();

  get landingPageUpdated(): Observable<string> {
    return this.landingPageUpdatedSubject.asObservable();
  }

  constructor(
    private landingPageService: LandingPageService,
    private progressBar: EkonProgressBarService
  ) {
  }

  createLandingPage(): Observable<LandingPage> {
    return this.progressBar
      .apply(this.landingPageService.createLandingPage())
      .pipe(
        tap((landingPage: LandingPage) =>
          this.landingPageCreatedSubject.next(landingPage)
        )
      );  }

  findDefaultLandingPage(): Observable<LandingPage> {
    return this.progressBar.apply(this.landingPageService.findDefaultLandingPage()).pipe(
      map((lp: LandingPage) =>{
        lp.subscriptionPlans = _sortBy(lp.subscriptionPlans, 'order');
        return lp;
      })
    );
  }

  findLandingPageById(id: string): Observable<LandingPage> {
    return this.progressBar.apply(this.landingPageService.findLandingPageById(id));
  }

  updateLandingPage(landingPage: LandingPage): Observable<unknown> {
    return this.progressBar
      .apply(this.landingPageService.updateLandingPage(landingPage))
      .pipe(tap(() => this.landingPageUpdatedSubject.next(landingPage.id)));
  }


}
