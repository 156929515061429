import { InjectionToken } from '@angular/core';
import { ProductDetails } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const PRODUCT_ACTIONS = new InjectionToken('Product model actions');
export const PRODUCT_EVENTS = new InjectionToken('Product model events');

export interface ProductEventsServiceInterface {
  itemCreated: Observable<ProductDetails>;
  itemUpdated: Observable<string>;
  itemDeleted: Observable<string>;
}
