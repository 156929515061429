/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ActionRequestType } from '../model/models';
import { Comment } from '../model/models';
import { CreateComment } from '../model/models';
import { CreateFeedback } from '../model/models';
import { CreatePageModel } from '../model/models';
import { FeedbackDetails } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { PageDetails } from '../model/models';
import { PageDetailsAndRelations } from '../model/models';
import { PageHeaderPagedResult } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';
import { ProductDetails } from '../model/models';
import { RelatePageToDomainModel } from '../model/models';
import { RelatePageToPageModel } from '../model/models';
import { RemovePageFromDomainModel } from '../model/models';
import { RemovePageFromPageModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface PageServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param actionRequestType 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createDerivedOrDuplicatePage(id: string, actionRequestType?: ActionRequestType, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageDetails | ProductDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createPageModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createPage(createPageModel?: CreatePageModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageDetails | ProductDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param createComment 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createPageComment(id: string, createComment?: CreateComment, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Comment>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param createFeedback 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createPageFeedback(id: string, createFeedback?: CreateFeedback, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FeedbackDetails>;

    /**
     * Create from population batch. Productd are in products controllerss
     * 
     * @param pageDetailsProductDetails 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createPageFromPopulation(pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageDetails | ProductDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deletePage(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findPageByExternalId(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageDetails | ProductDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findPageById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageDetails | ProductDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pageId 
     * @param pagePaginationModel 
     * @param toDirection 
     * @param xSelectedDomain Comma-delimited domain id
     */
    getPageSiblingPages(pageId: string, pagePaginationModel?: PagePaginationModel, toDirection?: boolean, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageHeaderPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param isTemplate 
     * @param isProduct 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listPages(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageHeaderPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param categoryId 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listPagesByCategory(categoryId: string, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageHeaderPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param names 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listPagesByNames(pagePaginationModel?: PagePaginationModel, names?: Array<string>, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageHeaderPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param isTemplate 
     * @param isProduct 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listPagesByTags(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageHeaderPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param isTemplate 
     * @param isProduct 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listPagesDashboard(pagePaginationModel?: PagePaginationModel, isTemplate?: boolean, isProduct?: boolean, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageHeaderPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param templateId 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listPagesDerivedByTemplate(templateId: string, pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageHeaderPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param pageIds 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listSelectedPagesByIds(pagePaginationModel?: PagePaginationModel, pageIds?: Array<string>, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<PageHeaderPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param relatePageToDomainModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    relatePageToDomain(relatePageToDomainModel?: RelatePageToDomainModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param relatePageToPageModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    relatePageToPage(relatePageToPageModel?: RelatePageToPageModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param removePageFromDomainModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    removePageFromDomain(removePageFromDomainModel?: RemovePageFromDomainModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param removePageFromPageModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    removePageFromPage(removePageFromPageModel?: RemovePageFromPageModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param pageDetailsProductDetails 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updatePage(id: string, pageDetailsProductDetails?: PageDetails | ProductDetails, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param pageDetailsAndRelations 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updatePageAndRelations(id: string, pageDetailsAndRelations?: PageDetailsAndRelations, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Update page category (to add pass categoryId, to remove do not pass categoryId)
     * 
     * @param id Page Id
     * @param categoryId Category Id
     * @param xSelectedDomain Comma-delimited domain id
     */
    updatePageCategory(id: string, categoryId: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

}
