/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Genders } from './genders';
import { Address } from './address';
import { ShareConsent } from './shareConsent';


export interface UserProfile { 
    id: string;
    /**
     * User avatar
     */
    avatarUrl?: string | null;
    /**
     * User cover image on own profile.
     */
    coverImageUrl?: string | null;
    /**
     * Main email
     */
    email?: string | null;
    gender?: Genders;
    /**
     * Given/First name
     */
    givenName?: string | null;
    /**
     * Last Name
     */
    lastName?: string | null;
    /**
     * Middle name
     */
    middleName?: string | null;
    /**
     * Main phone number
     */
    phoneNumber?: string | null;
    /**
     * <see href=\"https://www.iana.org/time-zones\" />
     */
    preferedIAnaTimezone?: string | null;
    /**
     * The culture name in the format languagecode2-country/regioncode2.  languagecode2 is a lowercase two-letter code derived from ISO 639-1.  country/regioncode2 is derived from ISO 3166 and usually consists of two uppercase letters, or a BCP-47 language tag.  <see href=\"https://docs.microsoft.com/en-us/dotnet/api/system.globalization.cultureinfo.name?view=netcore-2.2\" />
     */
    preferedUICultureName?: string | null;
    /**
     * User name
     */
    userName?: string | null;
    /**
     * Dr,Professor,QC,Eur Ing, Chancellor, Vice-Chancellor, Principal, President, Master, Warden, Dean, Regent, Rector, Provost, Director, Chief Executive etc...
     */
    title?: string | null;
    birthDate?: Date | null;
    activatedAt?: Date | null;
    about?: string | null;
    headline?: string | null;
    userAddress?: Address;
    userShareConsent?: ShareConsent;
    publicUserShareConsent?: ShareConsent;
    /**
     * Help find users by interested Tags and filter by those
     */
    tags?: Array<string> | null;
    /**
     * User\'s current signed TOU
     */
    touAccepted?: boolean;
    touAcceptedTimeStamp?: Date;
    touAcceptedVersion?: string | null;
    isActive?: boolean;
    cryptoWalletId?: string | null;
}

