/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Short user details: Name, email, Avatar
 */
export interface UserProfileHeader { 
    id?: string;
    /**
     * User avatar
     */
    avatarUrl?: string | null;
    /**
     * Main email
     */
    email?: string | null;
    /**
     * Given/First name
     */
    givenName?: string | null;
    /**
     * Last Name
     */
    lastName?: string | null;
    /**
     * Middle name
     */
    middleName?: string | null;
    /**
     * User name
     */
    userName?: string | null;
    /**
     * Help find users by interested Tags and filter by those
     */
    tags?: Array<string> | null;
}

