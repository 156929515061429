import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  TagContent,
  TagService,
  TagServiceInterface,
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TagEventsServiceInterface } from './TagEventsServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class TagEventsService
  implements TagServiceInterface, TagEventsServiceInterface {
  get configuration(): Configuration {
    return this.tagService.configuration;
  }

  set configuration(val: Configuration) {
    this.tagService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.tagService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.tagService.defaultHeaders = val;
  }

  constructor(
    private tagService: TagService,
    private progressBar: EkonProgressBarService
  ) {}

  /**
   * The mechanism for monitoring Create action
   */
  private tagCreatedSubject: Subject<TagContent> = new Subject();
  get tagCreated(): Observable<TagContent> {
    return this.tagCreatedSubject.asObservable();
  }

  /**
   * Creating new Tag item
   *
   * @param createTag
   */
  createTag(createTag?: TagContent): Observable<TagContent> {
    return this.progressBar
      .apply(this.tagService.createTag(createTag))
      .pipe(tap((res) => this.tagCreatedSubject.next(res)));
  }

  /**
   * Getting a list of Tags
   *
   */
  listTags(): Observable<Array<TagContent>> {
    return this.progressBar.apply(this.tagService.listTags());
  }
}
