import { InjectionToken } from '@angular/core';
import { TagContent } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const TAG_ACTIONS = new InjectionToken('Tag model actions');
export const TAG_EVENTS = new InjectionToken('Tag model events');

export interface TagEventsServiceInterface {
  tagCreated: Observable<TagContent>;
}
