import { InjectionToken } from '@angular/core';
import { FaqDetails } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const FAQ_ACTIONS = new InjectionToken('Faq model actions');
export const FAQ_EVENTS = new InjectionToken('Faq model events');

export interface FaqEventsServiceInterface {
  faqCreated: Observable<FaqDetails>;
  faqDeleted: Observable<string>;
  faqUpdated: Observable<string>;
}
