import { EkonIconDefinition } from '@ekon-client/shared/common/ekon-icons';

export enum SortingDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export interface SortingType {
  prop: string;
  label: string;
  icon: EkonIconDefinition;
  defaultDir: SortingDirection;
}

export type DefaultSortingProps = 'name' | 'lastUpdateAt' | 'averageRate' | string;

export type SortingTypes = {
  [prop in DefaultSortingProps]: SortingType;
};

export type SortingTypesConfig = Array<SortingType | string>;

export interface EkonSortingUpdated {
  prop: string;
  dir?: SortingDirection;
}
