import { Injectable } from '@angular/core';
import { EkonUtils } from '@ekon-layout-utils/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, share, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EkonProgressBarService {
  visible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  visible: Observable<boolean> = this.visible$.asObservable();
  private invokers: Set<string> = new Set<string>();

  show(): string {
    const key: string = EkonUtils.generateGUID();

    if (!this.invokers.size) {
      this.visible$.next(true);
    }

    this.invokers.add(key);

    return key;
  }

  hide(key: string): void {
    this.invokers.delete(key);

    if (!this.invokers.size) {
      this.visible$.next(false);
    }
  }

  apply<T>(observable: Observable<T>): Observable<T> {
    let pbKey: string;
    return of(null).pipe(
      map(() => (setTimeout(() => pbKey = this.show()))),
      switchMap(() => observable),
      finalize(() => setTimeout(() => this.hide(pbKey))),
      share()
    );
  }
}
