import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { EkonDialogsModule } from '@ekon-client/shared/common/ekon-dialogs';
import { ekIconPlus, EkonIconsLibraryService, EkonIconsModule } from '@ekon-client/shared/common/ekon-icons';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { TranslateModule } from '@ngx-translate/core';

import { MapDialogComponent } from './components/map-dialog/map-dialog.component';
import { MapDirective } from './directives/map.directive';

@NgModule({
  declarations: [
    MapDialogComponent,
    MapDirective,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexModule,
    MatDialogModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    EkonDialogsModule,
    EkonIconsModule,
    TranslateModule
  ],
  exports: [MapDirective, MapDialogComponent],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-map/',
      multi: true
    }
  ]
})
export class DkmMapModule {
  constructor(ekLib: EkonIconsLibraryService) {
    ekLib.addIcons(
      ekIconPlus
    );
  }
}
