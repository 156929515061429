import { MatDialogConfig } from '@angular/material/dialog';

const modalDefaultConfig: MatDialogConfig = {
  autoFocus: true,
  maxWidth: '400px',
  panelClass: 'dkm-modal-overlay-pane',
  closeOnNavigation: true
};

export const getModalDefaultConfig = (noPadding?: boolean | 'mini'): MatDialogConfig => ({
  direction: document.dir as 'rtl' | 'ltr',
  ...modalDefaultConfig,
  ...(noPadding ? {
    panelClass: `dkm-modal-overlay-pane-${noPadding===true ? 'no':noPadding}-padding`
  }:{})
});
