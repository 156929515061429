/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CreateRate } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { ProblemDetails } from '../model/models';
import { RateDetails } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface RateServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param refId 
     */
    averageRateByRefId(refId: string, extraHttpRequestParams?: any): Observable<number>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param createRate 
     */
    createRate(createRate?: CreateRate, extraHttpRequestParams?: any): Observable<RateDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     */
    deleteRate(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param refId 
     */
    deleteRateByRefId(refId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     */
    findRateById(id: string, extraHttpRequestParams?: any): Observable<RateDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param refId 
     */
    findRateByRefId(refId: string, extraHttpRequestParams?: any): Observable<RateDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     */
    listUserRates(extraHttpRequestParams?: any): Observable<Array<RateDetails>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param id 
     * @param rateDetails 
     */
    updateRate(id: string, rateDetails?: RateDetails, extraHttpRequestParams?: any): Observable<{}>;

}
