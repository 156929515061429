import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  CreateNote,
  NoteDetails,
  NoteDetailsPagedResult,
  NoteService,
  NoteServiceInterface, PagePaginationModel
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NoteEventsServiceInterface } from './NoteEventsServiceInterface';

@Injectable({
  providedIn: 'root'
})
export class NoteEventsService
  implements NoteServiceInterface, NoteEventsServiceInterface {
  get configuration(): Configuration {
    return this.noteService.configuration;
  }

  set configuration(val: Configuration) {
    this.noteService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.noteService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.noteService.defaultHeaders = val;
  }

  /**
   * The mechanism for monitoring Create action
   */
  private noteCreatedSubject: Subject<NoteDetails> = new Subject();

  get noteCreated(): Observable<NoteDetails> {
    return this.noteCreatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Delete action
   */
  private noteDeletedSubject: Subject<string> = new Subject();

  get noteDeleted(): Observable<string> {
    return this.noteDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Update action
   */
  private noteUpdatedSubject: Subject<string> = new Subject();

  get noteUpdated(): Observable<string> {
    return this.noteUpdatedSubject.asObservable();
  }

  constructor(
    private noteService: NoteService,
    private progressBar: EkonProgressBarService
  ) {
  }

  createNote(createNote?: CreateNote): Observable<NoteDetails> {
    return this.progressBar
      .apply(this.noteService.createNote(createNote))
      .pipe(
        tap((noteDetails: NoteDetails) =>
          this.noteCreatedSubject.next(noteDetails)
        )
      );
  }

  deleteNote(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.noteService.deleteNote(id))
      .pipe(tap(() => this.noteDeletedSubject.next(id)));
  }

  findNoteById(id: string): Observable<NoteDetails> {
    return this.progressBar.apply(this.noteService.findNoteById(id));
  }

  listNoteTodaysRemiders(
    pagination: PagePaginationModel,
    reminderDate?: Date,
    isPlatformNote?: boolean
  ): Observable<NoteDetailsPagedResult> {
    return this.progressBar.apply(
      this.noteService.listNoteTodaysRemiders(pagination, reminderDate, isPlatformNote)
    );
  }

  listNotesClosingToday(
    pagination: PagePaginationModel,
    closingDate?: Date,
    isPlatformNote?: boolean
  ): Observable<NoteDetailsPagedResult> {
    return this.progressBar.apply(
      this.noteService.listNotesClosingToday(pagination, closingDate, isPlatformNote)
    );
  }

  listUserNotes(
    pagination: PagePaginationModel,
    isPlatformNote?: boolean
  ): Observable<NoteDetailsPagedResult> {
    return this.progressBar.apply(this.noteService.listUserNotes(pagination, isPlatformNote));
  }

  updateNote(id: string, noteDetails: NoteDetails): Observable<unknown> {
    return this.progressBar
      .apply(this.noteService.updateNote(id, noteDetails))
      .pipe(tap(() => this.noteUpdatedSubject.next(id)));
  }
}
