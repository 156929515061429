/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LayoutConfig { 
    /**
     * Default width of an item in columns
     */
    defaultItemCols?: number;
    /**
     * Default height of an item in rows
     */
    defaultItemRows?: number;
    /**
     * Maximum amount of columns in the grid
     */
    maxCols?: number;
    /**
     * maximum item area: cols * rows
     */
    maxItemArea?: number;
    /**
     * Maximum item number of cols
     */
    maxItemCols?: number;
    /**
     * Maximum item number of rows
     */
    maxItemRows?: number;
    /**
     * Maximum amount of rows in the grid
     */
    maxRows?: number;
    /**
     * Minimum amount of columns in the grid
     */
    minCols?: number;
    /**
     * minimum item area: cols * rows
     */
    minItemArea?: number;
    /**
     * Minimum item number of cols
     */
    minItemCols?: number;
    /**
     * Minimum item number of rows
     */
    minItemRows?: number;
    /**
     * Minimum amount of rows in the grid
     */
    minRows?: number;
}

