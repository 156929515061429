/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { BlockedContentType } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { ProblemDetails } from '../model/models';
import { ReportedContentPaginated } from '../model/models';
import { SortDirection } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ReportContentServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param reportId 
     * @param contentType   Comment  Post  Blog  Page  File
     * @param contentId 
     * @param reason 
     */
    blockContent(reportId: string, contentType: BlockedContentType, contentId: string, reason?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param contentType   Comment  Post  Blog  Page  File
     * @param contentId 
     * @param reason 
     */
    reportContent(contentType: BlockedContentType, contentId: string, reason?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param pageNumber 
     * @param pageSize 
     * @param sortBy 
     * @param sortOrder 
     * @param sortPartition 
     * @param type   asc  desc  text
     */
    reportedContents(pageNumber: number, pageSize?: number, sortBy?: string, sortOrder?: number, sortPartition?: number, type?: SortDirection, extraHttpRequestParams?: any): Observable<ReportedContentPaginated>;

}
