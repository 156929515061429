/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EditabilityTypes } from './editabilityTypes';
import { LegalEntityHeaderModel } from './legalEntityHeaderModel';
import { Category } from './category';
import { UserHeaderModel } from './userHeaderModel';
import { VisibilityTypes } from './visibilityTypes';
import { DkmDomainHeaderModel } from './dkmDomainHeaderModel';
import { Pricing } from './pricing';
import { Shipping } from './shipping';
import { LayoutDetails } from './layoutDetails';
import { PageDetails } from './pageDetails';


export interface ProductDetails extends PageDetails { 
    /**
     * A product must have the LegalEntityId that represents the supplier
     */
    legalEntityId?: string;
    legalentityHeader?: LegalEntityHeaderModel;
    /**
     * Specify the product identification.
     */
    productCode?: string | null;
    /**
     * Specify the status of the product.
     */
    productActive?: boolean;
    /**
     * By definition, a stock keeping unit (or SKU),   is a number assigned to a product by a retail store to identify the price, product options and manufacturer of the merchandise.
     */
    sku?: string | null;
    /**
     * Amazon Standard Identification Number  An Amazon Standard Identification Number is a 10-character alphanumeric unique identifier assigned by Amazon.com   and its partners for product identification within the Amazon organization.
     */
    asin?: string | null;
    /**
     * Set the max and min allowed Quantity
     */
    maxQuantity?: number;
    minQuantity?: number;
    shippingDetails?: Shipping;
    pricingDetails?: Pricing;
}

