<ekon-dialog-wrapper [isFullscreen]="false" [disableToggler]="false">
  <div mat-dialog-title class="h2 mb-24" fxLayoutAlign="start center">
    Crop the image
  </div>
  <mat-dialog-content fxLayout="column">
    <div class="cropper-actions">
      <button matTooltip="Rotate left" mat-icon-button (click)="rotateLeft()">
        <!--<ekon-icon icon="undo" size="sm"></ekon-icon>-->
        <ekon-icon icon="vector-square" size="sm"></ekon-icon>
      </button>
      <button matTooltip="Rotate right" mat-icon-button (click)="rotateRight()">
        <ekon-icon icon="redo" size="sm"></ekon-icon>
      </button>
      <button
        matTooltip="Flip horizontally"
        mat-icon-button
        (click)="flipHorizontal()"
      >
        <!--<ekon-icon icon="arrows-alt-h" size="sm"></ekon-icon>-->
        <ekon-icon icon="vector-square" size="sm"></ekon-icon>
      </button>
      <button
        matTooltip="Flip vertically"
        mat-icon-button
        (click)="flipVertical()"
      >
        <ekon-icon icon="arrows-alt" size="sm"></ekon-icon>
      </button>
    </div>

    <div>
      <!--suppress AngularInvalidExpressionResultType -->
      <image-cropper
        [imageFile]="data.file"
        [maintainAspectRatio]="!!data?.config?.aspectRatio"
        [aspectRatio]="data?.config?.aspectRatio"
        [resizeToWidth]="0"
        [cropperMinWidth]="64"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [format]="data.file.type.split('/')[1]"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
        [style.display]="showCropper ? null : 'none'"
        [alignImage]="'left'"
      ></image-cropper>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <button type="button" mat-button [mat-dialog-close]="false">Cancel</button>
      <button type="button" mat-button (click)="saveImage()">Save</button>
    </div>
  </mat-dialog-actions>
</ekon-dialog-wrapper>
