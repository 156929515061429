/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { BlogPostDetails } from '../model/models';
import { BlogPostHeaderPagedResult } from '../model/models';
import { Comment } from '../model/models';
import { CreateBlogPostModel } from '../model/models';
import { CreateComment } from '../model/models';
import { CreateFeedback } from '../model/models';
import { FeedbackDetails } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface BlogServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param createComment 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createBlogComment(id: string, createComment?: CreateComment, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Comment>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param createFeedback 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createBlogFeedback(id: string, createFeedback?: CreateFeedback, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FeedbackDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createBlogPostModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createBlogPost(createBlogPostModel?: CreateBlogPostModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<BlogPostDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteBlogPost(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findBlogPostById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<BlogPostDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listBlogPosts(pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<BlogPostHeaderPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listUserBlogPosts(pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<BlogPostHeaderPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param blogPostDetails 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updateBlogPost(id: string, blogPostDetails?: BlogPostDetails, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

}
