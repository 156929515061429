import { DatePipe, TitleCasePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Injector, Optional, Provider } from '@angular/core';
import MessageFormat, { MessageFormatOptions } from '@messageformat/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateCompiler, TranslateLoader
} from '@ngx-translate/core';
import { TranslateModuleConfig } from '@ngx-translate/core/public_api';
import {
  isArray as _isArray,
  isNil as _isNil,
  isObject as _isObject,
  isPlainObject as _isPlainObject,
  map as _map,
  mapValues as _mapValues,
  pickBy as _pickBy} from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const EKON_TRANSLATE_LOADER_PATH = new InjectionToken<string[]>('Ekon_Translate_Loader_path');
export const EKON_TRANSLATE_MESSAGE_FORMAT_OPTIONS = new InjectionToken<MessageFormatOptions>('Ekon Translate MessageFormat Options');

type Translate = {
  [key: string]: string
};

export const mapValuesDeep = (obj: unknown, fn, key?: string | number) =>
  _isArray(obj)
  ? _map(obj, (innerObj, idx) => mapValuesDeep(innerObj, fn, idx))
  : _isPlainObject(obj)
    ? _mapValues(obj as Record<string, unknown>, (val, key) => mapValuesDeep(val, fn, key))
    : _isObject(obj)
      ? obj
      : fn(obj, key);

const _mapValuesDeep = mapValuesDeep;

export class EkonTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private paths: string[]
  ) {
    this.paths.reverse();
  }

  getTranslation(lang: string): Observable<Translate> {
    return combineLatest(this.paths.map(
      path => this.http.get<Translate>(`./assets/i18n/${path}${lang}.json`).pipe(
        catchError((err: unknown) => {
          console.warn('Smth went wrong while loading translation file. Loading default...', err);
          // return of({})
          return this.http.get<Translate>(`./assets/i18n/${path}en.json`).pipe(
            catchError((err: unknown) => {
              console.log('Failed loading default translation file', err);
              return of({});
            })
          );
        })
      )
    )).pipe(
      map((r: Translate[]) => Object.assign(
        {},
        ...(r.map(t => _pickBy(t, ti => typeof ti === 'object' || (ti?.trim() !== '' && !_isNil(ti)))))
      ))
    );
  }
}

export function translateLoader(http: HttpClient, injector: Injector) {
  return new EkonTranslateLoader(
    http,
    injector.get(EKON_TRANSLATE_LOADER_PATH, [''])
  );
}

export class MissingTranslationHandlerDefault implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if(params.interpolateParams) {
      return (params.interpolateParams as { default: string })?.default || params.key;
    }
    return params.key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EkonTranslateMessageFormatCompiler extends TranslateCompiler {

  private messageFormat: MessageFormat;

  constructor(
    @Optional() @Inject(EKON_TRANSLATE_MESSAGE_FORMAT_OPTIONS) config?: MessageFormatOptions<'string'>
  ) {
    super();

    this.messageFormat = new MessageFormat([], config);
  }

  public compile(value: string): (params: any) => string {
    return this.messageFormat.compile(value);
  }

  public compileTranslations(translations: Record<string, unknown>): any {
    return _mapValuesDeep(translations, (t: string) => this.messageFormat.compile(t));
  }
}

export function messageFormatConfigProvider(): Provider[] {
  return [
    DatePipe,
    TitleCasePipe,

    {
      provide: EKON_TRANSLATE_MESSAGE_FORMAT_OPTIONS,
      useFactory: (datePipe: DatePipe, titleCasePipe: TitleCasePipe) => ({
        biDiSupport: true,
        customFormatters: {
          upcase: (v: string) => v.toUpperCase(),
          lowcase: (v: string) => v.toLowerCase(),
          titlecase: (v) => titleCasePipe.transform(v),
          datePipe: (v, l, format) => datePipe.transform(v, format)
        }
      }),
      deps: [DatePipe, TitleCasePipe]
    }
  ];
}

export function translateModuleSetup(
  extend?: boolean,
  isolate = false
): TranslateModuleConfig {
  return ({
    missingTranslationHandler: {
      provide: MissingTranslationHandler,
      useClass: MissingTranslationHandlerDefault
    },

    loader: {
      provide: TranslateLoader,
      useFactory: translateLoader,
      deps: [HttpClient, Injector]
    },

    compiler: {
      provide: TranslateCompiler,
      useClass: EkonTranslateMessageFormatCompiler
    },

    useDefaultLang: true,

    isolate,
    extend
  });
}


/** ADD to root Component
 */

/** save subscription ref */
// private transSubscr: Subscription;

/** inject TranslationService */
// translateService: TranslateService,

/**
 * init and save subscription
 */

// this.transSubscr = initTranslations(translateService);

/**
 * release subscription in OnDestroy hook
 */
// this.transSubscr.unsubscribe();

