import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import {
  EkonIconsModule
} from '@ekon-client/shared/common/ekon-icons';

import { EkonRatingComponent } from './ekon-rating.component';



@NgModule({
  declarations: [
    EkonRatingComponent
  ],
    imports: [
        CommonModule,
        EkonIconsModule,
        MatRippleModule
    ],
  exports: [
    EkonRatingComponent
  ]
})
export class EkonRatingModule {
}
