import { InjectionToken } from '@angular/core';
import { BasketDetails } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const BASKET_ACTIONS = new InjectionToken('Basket model actions');
export const BASKET_EVENTS = new InjectionToken('Basket model events');

export interface BasketEventsServiceInterface {
  basketCreated: Observable<BasketDetails>;
  basketUpdated: Observable<string>; // basket id
  basketDeleted: Observable<string>; // basket id
  basketItemQuantityUpdated: Observable<string>; // basket id
}
