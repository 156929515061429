/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Comment } from '../model/models';
import { CreateBlogUserPostModel } from '../model/models';
import { CreateComment } from '../model/models';
import { CreateDomainUserPostModel } from '../model/models';
import { CreateExternalLinkUserPostModel } from '../model/models';
import { CreateFeedback } from '../model/models';
import { CreateFilesUserPostModel } from '../model/models';
import { CreateLegalEntityUserPostModel } from '../model/models';
import { CreateMeetupUserPostModel } from '../model/models';
import { CreatePageUserPostModel } from '../model/models';
import { CreatePlainUserPostModel } from '../model/models';
import { CreateProductUserPostModel } from '../model/models';
import { CreateReshareUserPostModel } from '../model/models';
import { FeedbackDetails } from '../model/models';
import { HttpValidationProblemDetails } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';
import { UserPostBlogModel } from '../model/models';
import { UserPostDetailedReactionModelPagedResult } from '../model/models';
import { UserPostDomainModel } from '../model/models';
import { UserPostExternalLinkModel } from '../model/models';
import { UserPostFilesModel } from '../model/models';
import { UserPostLegalEntityModel } from '../model/models';
import { UserPostMeetupModel } from '../model/models';
import { UserPostModel } from '../model/models';
import { UserPostModelPagedResult } from '../model/models';
import { UserPostPageModel } from '../model/models';
import { UserPostPlainModel } from '../model/models';
import { UserPostProductModel } from '../model/models';
import { UserPostReshareModel } from '../model/models';
import { UserPostUpdateModel } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserPostsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createPlainUserPostModelCreatePageUserPostModelCreateProductUserPostModelCreateBlogUserPostModelCreateDomainUserPostModelCreateLegalEntityUserPostModelCreateMeetupUserPostModelCreateFilesUserPostModelCreateExternalLinkUserPostModelCreateReshareUserPostModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createPlainPost(createPlainUserPostModelCreatePageUserPostModelCreateProductUserPostModelCreateBlogUserPostModelCreateDomainUserPostModelCreateLegalEntityUserPostModelCreateMeetupUserPostModelCreateFilesUserPostModelCreateExternalLinkUserPostModelCreateReshareUserPostModel?: CreatePlainUserPostModel | CreatePageUserPostModel | CreateProductUserPostModel | CreateBlogUserPostModel | CreateDomainUserPostModel | CreateLegalEntityUserPostModel | CreateMeetupUserPostModel | CreateFilesUserPostModel | CreateExternalLinkUserPostModel | CreateReshareUserPostModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostPlainModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param createComment 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createUserPostComment(id: string, createComment?: CreateComment, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Comment>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param createFeedback 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createUserPostFeedback(id: string, createFeedback?: CreateFeedback, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<FeedbackDetails>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createBlogUserPostModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createUserPostOnBlog(createBlogUserPostModel?: CreateBlogUserPostModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostBlogModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createDomainUserPostModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createUserPostOnDomain(createDomainUserPostModel?: CreateDomainUserPostModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostDomainModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createExternalLinkUserPostModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createUserPostOnExternalLink(createExternalLinkUserPostModel?: CreateExternalLinkUserPostModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostExternalLinkModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createFilesUserPostModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createUserPostOnFiles(createFilesUserPostModel?: CreateFilesUserPostModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostFilesModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createLegalEntityUserPostModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createUserPostOnLegalEntity(createLegalEntityUserPostModel?: CreateLegalEntityUserPostModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostLegalEntityModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createMeetupUserPostModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createUserPostOnMeetup(createMeetupUserPostModel?: CreateMeetupUserPostModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostMeetupModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createPageUserPostModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createUserPostOnPage(createPageUserPostModel?: CreatePageUserPostModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostPageModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createProductUserPostModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createUserPostOnProduct(createProductUserPostModel?: CreateProductUserPostModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostProductModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param createReshareUserPostModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    createUserPostReshare(createReshareUserPostModel?: CreateReshareUserPostModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostReshareModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    deleteUserPost(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findUserPostById(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostModel | UserPostPlainModel | UserPostPageModel | UserPostProductModel | UserPostBlogModel | UserPostDomainModel | UserPostLegalEntityModel | UserPostMeetupModel | UserPostFilesModel | UserPostExternalLinkModel | UserPostReshareModel>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param pagePaginationModel 
     * @param reactionType 
     * @param xSelectedDomain Comma-delimited domain id
     */
    findUserPostReactionsById(id: string, pagePaginationModel?: PagePaginationModel, reactionType?: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostDetailedReactionModelPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    getLatestPost(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostModel | UserPostPlainModel | UserPostPageModel | UserPostProductModel | UserPostBlogModel | UserPostDomainModel | UserPostLegalEntityModel | UserPostMeetupModel | UserPostFilesModel | UserPostExternalLinkModel | UserPostReshareModel>;

    /**
     * Get list of User Post types related to data entities
     * 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listEntitiesPostingTypes(xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<Array<string>>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listUserPosts(pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostModelPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param pagePaginationModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listUserPostsByUserId(pagePaginationModel?: PagePaginationModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostModelPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param referenceId 
     * @param pagePaginationModel 
     * @param postType 
     * @param xSelectedDomain Comma-delimited domain id
     */
    listUserPostsRefEntityId(referenceId: string, pagePaginationModel?: PagePaginationModel, postType?: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<UserPostModelPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param reactionType 
     * @param xSelectedDomain Comma-delimited domain id
     */
    reactionToUserPost(id: string, reactionType?: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param xSelectedDomain Comma-delimited domain id
     */
    revertReactionToUserPost(id: string, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth, dkm4u_api_auth)
     * 
     * @param id 
     * @param userPostUpdateModel 
     * @param xSelectedDomain Comma-delimited domain id
     */
    updatePost(id: string, userPostUpdateModel?: UserPostUpdateModel, xSelectedDomain?: string, extraHttpRequestParams?: any): Observable<{}>;

}
