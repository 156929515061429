/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { HttpValidationProblemDetails } from '../model/models';
import { PagePaginationModel } from '../model/models';
import { ProblemDetails } from '../model/models';
import { RightToErsureModel } from '../model/models';
import { UpdateShareConsent } from '../model/models';
import { UserHeaderModelPagedResult } from '../model/models';
import { UserProfile } from '../model/models';
import { UserProfileHeader } from '../model/models';
import { ViewUserProfile } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserProfileServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param email 
     */
    activateUserProfile(email: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param userId 
     */
    findProtectedUserProfileById(userId: string, extraHttpRequestParams?: any): Observable<ViewUserProfile>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param userId 
     */
    findPublicUserProfileById(userId: string, extraHttpRequestParams?: any): Observable<ViewUserProfile>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param profileId 
     */
    getOneTimeVerificationCode(profileId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     */
    getUserProfile(extraHttpRequestParams?: any): Observable<UserProfile>;

    /**
     * Get user\&#39;s headers in paginated list
     * 
     * @param pagePaginationModel 
     */
    listUserProfileHeaders(pagePaginationModel?: PagePaginationModel, extraHttpRequestParams?: any): Observable<UserHeaderModelPagedResult>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param requestBody 
     */
    listUsers(requestBody?: Array<string>, extraHttpRequestParams?: any): Observable<Array<UserProfileHeader>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     
     * @deprecated
     */
    migrateUsersToIdentity(extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     */
    rightToErasureReasons(extraHttpRequestParams?: any): Observable<Array<string>>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param rightToErsureModel 
     */
    rightToErsureRequestForm(rightToErsureModel?: RightToErsureModel, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param profileId 
     */
    updateUserAcceptTouPp(profileId: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param userProfile 
     */
    updateUserProfile(userProfile?: UserProfile, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param profileId 
     * @param updateShareConsent 
     */
    updateUserShareConset(profileId: string, updateShareConsent?: UpdateShareConsent, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param body 
     */
    updateUserSubscription(body?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param body 
     */
    updateUserWalletId(body?: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     *  (Auth policies: dkm4u_api_auth)
     * 
     * @param body 
     */
    userSubscriptionPlanRequest(body?: string, extraHttpRequestParams?: any): Observable<{}>;

}
