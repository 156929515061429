import { InjectionToken } from '@angular/core';
import { Comment, UserPostModel } from '@ekon-client/dkm-api';
import { Observable } from 'rxjs';

export const USER_POSTS_ACTIONS = new InjectionToken('User Posts model actions');
export const USER_POSTS_EVENTS = new InjectionToken('User Posts model events');

export interface UserPostsEventsServiceInterface {
  // todo: ensure if plain type works for all cases
  userPostCreated: Observable<UserPostModel>;
  userPostDeleted: Observable<string>;
  userPostUpdated: Observable<string>;
  commentCreated: Observable<Comment>;
}
