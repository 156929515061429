import { Component } from '@angular/core';
import { UserProfile } from '@ekon-client/dkm-api';
import { GlobalConfigurationService } from '@ekon-client/shared/cloud-config';
import { ekIconBars, ekIconExclamationTriangle, ekIconQuestion } from '@ekon-client/shared/common/ekon-icons';
import { FeedbackSharedService } from '@ekon-client/shared/features/dkm-feedback';
import { UserProfileService } from '@ekon-client/shared/features/dkm-user-profile';
import { noop as _noop } from 'lodash-es';
import { Observable } from 'rxjs';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  ekIconBars = ekIconBars;

  user: Observable<UserProfile>;

  footerName: string;
  footerSiteUri: string;

  /**
   * Constructor
   */
  constructor(
    globalConfig: GlobalConfigurationService,
    private feedbackService: FeedbackSharedService,
    private userProfile: UserProfileService
  ) {
    this.user = this.userProfile.currentUser$;

    this.footerName = globalConfig.footer.NAME;
    this.footerSiteUri = globalConfig.footer.SITE_URI;
  }


  openFeedBackDialog(): void {
    this.feedbackService.openFeedBackDialog().subscribe(_noop);
  }

}
