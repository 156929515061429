/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *   NA  MALE  FEMALE  OTHER
 */
export type Genders = 'NA' | 'MALE' | 'FEMALE' | 'OTHER';

export const Genders = {
    Na: 'NA' as Genders,
    Male: 'MALE' as Genders,
    Female: 'FEMALE' as Genders,
    Other: 'OTHER' as Genders
};

