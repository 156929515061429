import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { FileMetadata, UserProfileHeader } from '@ekon-client/dkm-api';
import { EkonIconDefinition } from '@ekon-client/shared/common/ekon-icons';
import { getUserNameFromList } from '@ekon-client/shared/features/dkm-user-profile';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FileDataSource } from '../extras/FileDataSource';
import { FileListDataSource } from '../extras/FileListDataSource';
import { FileListModes } from '../extras/FileListModes';
import { FileTypesWeCanView } from '../extras/FileTypesWeCanView';
import { getFileExt, getFileTypeIcon, MimeTypeGroup, mimeTypes } from '../utils';

@Component({
  selector: 'dkm-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', padding: '0' })
      ),
      state('expanded', style({ height: '*', padding: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class FileListComponent {
  ColumnMode = ColumnMode;
  FileListModes = FileListModes;
  SelectionType = SelectionType;

  fileTypes = mimeTypes;
  currentFilterByType: MimeTypeGroup;

  expandedElement: FileMetadata | null;

  isDesktop: Observable<boolean>;

  @Input() mode: FileListModes = FileListModes.FULL;
  @Input() data: FileListDataSource;
  @Input() typeColHidden: boolean;

  @Output() fileDelete: EventEmitter<FileMetadata> = new EventEmitter();
  @Output() fileSelect: EventEmitter<FileDataSource> = new EventEmitter();
  @Output() fileView: EventEmitter<{file: FileMetadata, fileIndex: number}> = new EventEmitter();
  @Output() fileEditMeta: EventEmitter<FileMetadata> = new EventEmitter();
  @Output() fileDownload: EventEmitter<FileMetadata> = new EventEmitter();
  @Output() mimeTypesSelected: EventEmitter<string[]> = new EventEmitter();

  constructor(mediaObserver: MediaObserver) {
    this.isDesktop = mediaObserver
      .asObservable()
      .pipe(map(() => mediaObserver.isActive('gt-sm')));
  }

  getFileAuthor(users: UserProfileHeader[], uID: string): UserProfileHeader {
    return users.find((user: UserProfileHeader) => uID === user.id);
  }

  getUserName(users: UserProfileHeader[], uID: string): string {
    return getUserNameFromList(users, uID);
  }

  getTypeIcon(fileName: string): EkonIconDefinition {
    return getFileTypeIcon(fileName);
  }

  callSelect(file: FileMetadata, users: UserProfileHeader[]): void {
    const user = this.getFileAuthor(users, file.uploadedBy);

    if (this.mode === FileListModes.FULL) {
      this.expandedElement = this.expandedElement === file ? null : file;
    } else if (this.mode === FileListModes.MINI) {
      this.canView(file) && this.callView(file);
    } else {
      this.fileSelect.emit({ file, user });
    }
  }

  callDelete(file: FileMetadata): void {
    this.fileDelete.emit(file);
  }

  callView(file: FileMetadata): void {
    console.warn('callView', file, {file, fileIndex: this.data.files.indexOf(file)});
    this.fileView.emit({file, fileIndex: this.data.files.indexOf(file)});
  }

  callEditMeta(file: FileMetadata): void {
    this.fileEditMeta.emit(file);
  }

  callDownload(file: FileMetadata): void {
    this.fileDownload.emit(file);
  }

  canView(file: FileMetadata): boolean {
    return FileTypesWeCanView.canViewFileExt(file.contentType);
  }

  getFileExt(fileName: string): string {
    return getFileExt(fileName);
  }

  megaSupa(hola: unknown): void {
    console.log(hola);
  }

  setFilterByType(group?: MimeTypeGroup): void {
    this.currentFilterByType = group;
    this.mimeTypesSelected.emit(group ? group.types.map(type => type.mime).flat() : undefined)
  }
}
