<ekon-dialog-wrapper>
  <!-- HEADER -->
  <div mat-dialog-title class="h3 p-0">Available domains</div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <mat-dialog-content>
    <mat-action-list role="list">
      <mat-list-item
        *ngFor="let domain of data.domains"
        fxLayout="row" fxLayoutAlign="space-between center"
        (click)="joinDomain(domain)"
        role="listitem"
      >{{domain.name}}</mat-list-item>
    </mat-action-list>
  </mat-dialog-content>
  <!-- / CONTENT -->

  <!-- ACTIONS -->
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end" fxLayoutGap="8px" class="py-24">
    <button mat-button [mat-dialog-close]="false">Close</button>
  </mat-dialog-actions>
  <!-- / ACTIONS -->
</ekon-dialog-wrapper>
