<div
  #taskContent
  matRipple
  class="item-content"
  [ngClass]="{
    'minimized': minimized,
    'completed': isCompletedTask(),
    'selectable': selectMode,
    'standalone': standalone
  }"
  (click)="selectMode && selectTask()"
>

  <div class="info" (click)="viewTask()">

    <div
      class="title"
    >
      {{item.name}}
    </div>

    <div class="date mat-small grey-500-fg my-4">
      <span>{{item.start | date: 'dd MMM yyyy, hh:mm'}}</span>
      <span *ngIf="item.end"> - {{item.end | date: 'dd MMM yyyy, hh:mm'}}</span>
    </div>

    <div
      *ngIf="!minimized"
      class="notes"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(item.description)"
    ></div>

    <ekon-tags
      *ngIf="!minimized"
      class="tags dkm-chips"
      [tags]="item?.tags"
    ></ekon-tags>

  </div>

  <div
    *ngIf="!viewOnly"
    class="actions"
  >
    <button
      mat-icon-button
      [matMenuTriggerFor]="moreMenu"
      aria-label="More actions"
      class="menu-btn"
      (click)="$event.stopPropagation()"
    >
      <ekon-icon icon="ellipsis-v"></ekon-icon>
    </button>

    <mat-menu #moreMenu="matMenu">
      <ng-template matMenuContent>
        <button
          mat-menu-item
          aria-label="toggle done"
          (click)="updateTaskProperty(item, 'status', itemAsTask()?.status === TaskStatus.Completed ? TaskStatus.Unspecified : TaskStatus.Completed)"
        >
          <!--itemAsTask().status !== TaskStatus.Completed ? 'check-square' : 'square'-->
          <ekon-icon
            [icon]="itemAsTask()?.status !== TaskStatus.Completed ? 'check-square' : 'square'"
            class="pr-8"
          ></ekon-icon>
          <span>{{itemAsTask()?.status === TaskStatus.Completed ? 'Mark as undone' : 'Mark as done'}}</span>
        </button>
        <button
          mat-menu-item
          (click)="updateTaskProperty(item, 'status', TaskStatus.InProcess)"
        >
          <!--itemAsTask().status !== TaskStatus.InProcess ? 'check-square' : 'square'-->
          <ekon-icon
            [icon]="itemAsTask()?.status !== TaskStatus.InProcess ? 'check-square' : 'square'"
            class="pr-8"
          ></ekon-icon>
          <span>{{itemAsTask()?.status !== TaskStatus.InProcess ? 'Mark as in process' : 'In process'}}</span>
        </button>
        <button mat-menu-item aria-label="make private"
                (click)="updateTaskProperty(item, 'isPrivate', !item.isPrivate)">
          <!--item.isPrivate ? 'lock-open' : 'lock'-->
          <ekon-icon
            [icon]="item.isPrivate ? 'lock' : 'unlock'"
            class="pr-8"
          ></ekon-icon>
          <span>{{item.isPrivate ? 'Make public' : 'Make private'}}</span>
        </button>
        <button mat-menu-item aria-label="edit" (click)="editTask()">
          <ekon-icon icon="edit" class="pr-8"></ekon-icon>
          <span>Edit</span>
        </button>
        <button
          mat-menu-item
          aria-label="delete"
          (click)="deleteTask()"
          class="warn-fg"
        >
          <ekon-icon icon="trash-alt" class="pr-8"></ekon-icon>
          <span>Delete</span>
        </button>
      </ng-template>
    </mat-menu>

  </div>

</div>
