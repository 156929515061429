import { Injector, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { EkonAuthRedirectGuard /*, EkonLandingGuard, EkonRootScreenGuard */ } from '@ekon-client/auth';
import {
  RouteWithBreadcrumb,
  translateBreadcrumb,
} from '@ekon-client/dkm-events';
import {
  EkonConfigGuardService,
  GlobalConfigurationService,
} from '@ekon-client/shared/cloud-config';
import { EKON_TRANSLATE_LOADER_PATH } from '@ekon-client/shared/common/ekon-translation';
import { PermissionsGuardService } from '@ekon-client/shared/features/dkm-permissions';
// import { EkonAuthGuardService } from '@ekon-client/shared/features/ekon-auth';
import { TranslateModule } from '@ngx-translate/core';

import { FakeRootComponent } from './fake-root.component';

export const commonRoutes: Routes = [
  // {
  //   path: '**',
  //   pathMatch: 'full',
  //   redirectTo: '/error/not-found'
  // },

  RouteWithBreadcrumb(
    {
      path: '',
      redirectTo: '/landing',
      // component: FakeRootComponent,
      pathMatch: 'full',
      // canActivate: [EkonRootScreenGuard],
      // canActivate: [EkonAuthRedirectGuard],
    },
    ['Home']
  ),

  {
    path: 'landing',
    loadChildren: () =>
      import('@ekon-client/features/dkm-landing').then(
        (mod) => mod.DkmLandingModule
      ),
    canActivate: [EkonAuthRedirectGuard],
    // todo: handle this case
    // canLoad: [EkonLandingGuard]
  },

  RouteWithBreadcrumb(
    {
      path: 'terms-of-use',
      loadChildren: () =>
        import('@ekon-client/features/dkm-terms-of-use').then(
          (mod) => mod.DkmTermsOfUseModule
        ),
    },
    ['Terms of use']
  ),

  RouteWithBreadcrumb(
    {
      path: 'privacy-policy',
      loadChildren: () =>
        import('@ekon-client/features/dkm-privacy-policy').then(
          (mod) => mod.DkmPrivacyPolicyModule
        ),
    },
    ['Privacy policy']
  ),

  RouteWithBreadcrumb(
    {
      path: 'code-of-conduct',
      loadChildren: () =>
        import('@ekon-client/features/dkm-code-of-conduct').then(
          (mod) => mod.DkmCodeOfConductModule
        ),
    },
    ['Code of conduct']
  ),

  {
    path: 'subscription',
    loadChildren: () =>
      import('@ekon-client/features/dkm-plan-claims').then(
        (mod) => mod.DkmPlanClaimsModule
      ),
    // canLoad: [EkonAuthGuardService],
    canActivate: [EkonAuthRedirectGuard],
  },

  RouteWithBreadcrumb(
    {
      path: 'faq',
      loadChildren: () =>
        import('@ekon-client/features/dkm-faq').then((mod) => mod.DkmFaqModule),
      // canLoad: [
      //   EkonAuthRedirectGuard,
      //   // PermissionsGuardService
      // ],
      canActivate: [EkonAuthRedirectGuard],
      data: {
        // permissionFeature: 'FAQ',
        // permissionAction: 'read'
      },
    },
    ['FAQ']
  ),

  RouteWithBreadcrumb(
    {
      path: 'rss',
      loadChildren: () =>
        import('@ekon-client/features/dkm-rss').then((mod) => mod.DkmRssModule),
      // canLoad: [
      //   EkonAuthRedirectGuard,
      //   PermissionsGuardService,
      //   EkonConfigGuardService,
      // ],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService, EkonConfigGuardService],
      data: {
        permissionFeature: 'RSS',
        permissionAction: 'read',
        envProp: 'enableRSS',
      },
    },
    ['RSS']
  ),

  RouteWithBreadcrumb(
    {
      path: 'domains',
      loadChildren: () =>
        import('@ekon-client/features/dkm-domains').then(
          (mod) => mod.DkmDomainsModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'DOMAINS',
        permissionAction: 'read',
      },
    },
    [
      (route: ActivatedRouteSnapshot, inj: Injector) =>
        inj.get(GlobalConfigurationService).domainTitlePlural,
    ]
  ),

  RouteWithBreadcrumb(
    {
      path: 'pages',
      loadChildren: () =>
        import('@ekon-client/features/dkm-pages').then(
          (mod) => mod.DkmPagesModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'PAGES',
        permissionAction: 'read'
      },
    },
    [translateBreadcrumb(marker('KNOWLEDGE_PAGES'))]
  ),

  RouteWithBreadcrumb(
    {
      path: 'templates',
      redirectTo: '/pages?isTemplate=true',
    },
    ['Templates']
  ),

  RouteWithBreadcrumb(
    {
      path: 'my-space',
      loadChildren: () =>
        import('@ekon-client/features/dkm-my-space').then(
          (mod) => mod.DkmMySpaceModule
        ),
      // canLoad: [
      //   EkonAuthRedirectGuard,
      //   // PermissionsGuardService
      // ],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        isOwnerMode: true,
        // permissionFeature: 'MY_SPACE',
        // permissionAction: 'read'
      },
    },
    [translateBreadcrumb(marker('MY_SPACE'))]
  ),

  RouteWithBreadcrumb(
    {
      path: 'calendar',
      loadChildren: () =>
        import('@ekon-client/features/dkm-calendar').then(
          (mod) => mod.DkmCalendarModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'CALENDAR',
        permissionAction: 'read',
      },
    },
    ['Calendar']
  ),

  RouteWithBreadcrumb(
    {
      path: 'blogs',
      loadChildren: () =>
        import('@ekon-client/features/dkm-blogs').then(
          (mod) => mod.DkmBlogsModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        isOwnerMode: false,
        permissionFeature: 'BLOGS',
        permissionAction: 'read',
      },
    },
    ['Blogs']
  ),

  RouteWithBreadcrumb(
    {
      path: 'files',
      loadChildren: () =>
        import('@ekon-client/features/dkm-files').then(
          (mod) => mod.DkmFilesModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'FILES',
        permissionAction: 'read',
      },
    },
    ['files']
  ),

  RouteWithBreadcrumb(
    {
      path: 'notes',
      loadChildren: () =>
        import('@ekon-client/features/dkm-notes').then(
          (mod) => mod.DkmNotesModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'NOTES',
        permissionAction: 'read',
      },
    },
    ['Notes']
  ),

  RouteWithBreadcrumb(
    {
      path: 'todo',
      loadChildren: () =>
        import('@ekon-client/features/dkm-tasks').then(
          (mod) => mod.DkmTasksModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'TASKS',
        permissionAction: 'read',
      },
    },
    ['Tasks']
  ),

  RouteWithBreadcrumb(
    {
      path: 'feedback',
      loadChildren: () =>
        import('@ekon-client/features/dkm-feedback').then(
          (mod) => mod.DkmFeedbackModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'FEEDBACK',
        permissionAction: 'read',
      },
    },
    ['Feedback']
  ),

  RouteWithBreadcrumb(
    {
      path: 'legal-entities',
      loadChildren: () =>
        import('@ekon-client/features/dkm-legal-entity').then(
          (mod) => mod.DkmLegalEntityModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'LEGAL_ENTITIES',
        permissionAction: 'read',
      },
    },
    ['Legal entities']
  ),

  RouteWithBreadcrumb(
    {
      path: 'map',
      loadChildren: () =>
        import('@ekon-client/features/dkm-map').then((mod) => mod.DkmMapModule),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'MAPS',
        permissionAction: 'read',
      },
    },
    ['Map']
  ),

  {
    path: 'error',
    loadChildren: () =>
      import('@ekon-client/features/dkm-errors').then(
        (mod) => mod.DkmErrorsModule
      ),
  },

  RouteWithBreadcrumb(
    {
      path: 'admin',
      loadChildren: () =>
        import('@ekon-client/features/dkm-admin-panel').then(
          (mod) => mod.DkmAdminPanelModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'SYSTEM',
        permissionAction: 'config',
      },
    },
    ['Admin Dashboard']
  ),

  RouteWithBreadcrumb(
    {
      path: 'admin/subscriptions',
      loadChildren: () =>
        import('@ekon-client/features/dkm-subscriptions-config').then(
          (mod) => mod.DkmSubscriptionsConfigModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'SUBSCRIPTIONS',
        permissionAction: 'config',
      },
    },
    ['Admin Dashboard', 'Subscription Plans']
  ),

  RouteWithBreadcrumb(
    {
      path: 'admin/tou',
      loadChildren: () =>
        import('@ekon-client/features/dkm-tou-config').then(
          (mod) => mod.DkmTouConfigModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'TERMS_OF_USE',
        permissionAction: 'config',
      },
    },
    ['Admin Dashboard', 'Terms Of Use']
  ),

  RouteWithBreadcrumb(
    {
      path: 'admin/pp',
      loadChildren: () =>
        import('@ekon-client/features/dkm-privacy-policy-config').then(
          (mod) => mod.DkmPrivacyPolicyConfigModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'PRIVACY_POLICY',
        permissionAction: 'config',
      },
    },
    ['Admin Dashboard', 'Privacy Policy']
  ),

  RouteWithBreadcrumb(
    {
      path: 'admin/code-of-conduct',
      loadChildren: () =>
        import('@ekon-client/features/dkm-code-of-conduct-config').then(
          (mod) => mod.DkmCodeOfConductConfigModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'CONDUCT_POLICY',
        permissionAction: 'config',
      },
    },
    ['Admin Dashboard', 'Code of conduct']
  ),

  RouteWithBreadcrumb(
    {
      path: 'admin/files',
      loadChildren: () =>
        import('@ekon-client/features/dkm-files').then(
          (mod) => mod.DkmFilesModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'FILES',
        permissionAction: 'config',
      },
    },
    ['Admin Dashboard', 'Files']
  ),

  RouteWithBreadcrumb(
    {
      path: 'admin/faq',
      loadChildren: () =>
        import('@ekon-client/features/dkm-faq').then((mod) => mod.DkmFaqModule),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'FAQ',
        permissionAction: 'read',
      },
    },
    ['Admin Dashboard', 'FAQ']
  ),

  RouteWithBreadcrumb(
    {
      path: 'admin/element-templates',
      loadChildren: () =>
        import('@ekon-client/features/dkm-elements').then(
          (mod) => mod.DkmElementsModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'ELEMENT_TEMPLATES',
        permissionAction: 'read',
      },
    },
    ['Admin Dashboard', 'Element templates']
  ),

  RouteWithBreadcrumb(
    {
      path: 'admin/categories',
      loadChildren: () =>
        import('@ekon-client/features/dkm-categories').then(
          (mod) => mod.DkmCategoriesModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'CATEGORIES',
        permissionAction: 'read',
      },
    },
    ['Admin Dashboard', 'Categories']
  ),

  RouteWithBreadcrumb(
    {
      path: 'users',
      loadChildren: () =>
        import('@ekon-client/features/dkm-user-profile').then(
          (mod) => mod.DkmUserProfileModule
        ),
      // canLoad: [EkonAuthRedirectGuard, PermissionsGuardService],
      canActivate: [EkonAuthRedirectGuard, PermissionsGuardService],
      data: {
        permissionFeature: 'PROFILE',
        permissionAction: 'read',
      },
    },
    [translateBreadcrumb(marker('USERS'))]
  ),
];

@NgModule({
  imports: [
    RouterModule.forChild(
      commonRoutes
      // {
      //   initialNavigation: 'enabled'
      // }
    ),
    TranslateModule,
  ],
  providers: [
    {
      provide: EKON_TRANSLATE_LOADER_PATH,
      useValue: 'libs/shared/features/dkm-common-routes/',
      multi: true,
    },
  ],
  declarations: [FakeRootComponent],
})
export class DkmCommonRoutesModule { }
