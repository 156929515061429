import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  Element,
  ElementTemplatesService,
  ElementTemplatesServiceInterface,
  ElementType,
} from '@ekon-client/dkm-api';
import { EkonProgressBarService } from '@ekon-client/shared/common/ekon-progress-bar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ElementTemplatesEventsServiceInterface } from './ElementTemplatesEventsServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class ElementTemplatesEventsService
  implements
  ElementTemplatesServiceInterface,
  ElementTemplatesEventsServiceInterface {
  get configuration(): Configuration {
    return this.elementTemplatesService.configuration;
  }

  set configuration(val: Configuration) {
    this.elementTemplatesService.configuration = val;
  }

  get defaultHeaders(): HttpHeaders {
    return this.elementTemplatesService.defaultHeaders;
  }

  set defaultHeaders(val: HttpHeaders) {
    this.elementTemplatesService.defaultHeaders = val;
  }

  constructor(
    private elementTemplatesService: ElementTemplatesService,
    private progressBar: EkonProgressBarService
  ) { }

  /**
   * The mechanism for monitoring Create action
   */
  private elementTemplateCreatedSubject: Subject<Element> = new Subject();
  get elementTemplateCreated(): Observable<Element> {
    return this.elementTemplateCreatedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Delete action
   */
  private elementTemplateDeletedSubject: Subject<string> = new Subject();
  get elementTemplateDeleted(): Observable<string> {
    return this.elementTemplateDeletedSubject.asObservable();
  }

  /**
   * The mechanism for monitoring Update action
   */
  private elementTemplateUpdatedSubject: Subject<string> = new Subject();
  get elementTemplateUpdated(): Observable<string> {
    return this.elementTemplateUpdatedSubject.asObservable();
  }

  /**
   * Creating new Element Template item
   *
   * @param elementType
   */
  createElementTemplate(elementType?: ElementType): Observable<unknown> {
    return this.progressBar
      .apply(this.elementTemplatesService.createElementTemplate(elementType))
      .pipe(
        tap((res: Element) => this.elementTemplateCreatedSubject.next(res))
      );
  }

  /**
   * Deleting Element Template item
   *
   * @param id
   */
  deleteElementTemplate(id: string): Observable<unknown> {
    return this.progressBar
      .apply(this.elementTemplatesService.deleteElementTemplate(id))
      .pipe(tap((res) => this.elementTemplateDeletedSubject.next(res)));
  }

  /**
   * Getting Element Template by ID
   *
   * @param id
   */
  findElementTemplateById(id: string): Observable<Element> {
    return this.progressBar.apply(
      this.elementTemplatesService.findElementTemplateById(id)
    );
  }

  /**
   * Getting a list of Element Templates
   *
   */
  listElementTemplates(): Observable<Array<Element>> {
    return this.progressBar.apply(
      this.elementTemplatesService.listElementTemplates()
    );
  }

  /**
   * Getting list of Element Types
   *
   */
  listElementTypes(): Observable<Array<ElementType>> {
    return this.progressBar.apply(
      this.elementTemplatesService.listElementTypes()
    );
  }

  /**
   * Updating Element Template item
   *
   * @param id
   * @param element
   */
  updateElementTemplate(id: string, element: Element): Observable<unknown> {
    return this.progressBar
      .apply(this.elementTemplatesService.updateElementTemplate(id, element))
      .pipe(tap((res) => this.elementTemplateUpdatedSubject.next(res)));
  }
}
