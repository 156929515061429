import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';

import { EkonAuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EkonLandingGuard implements CanActivate, CanLoad {
  constructor(private authService: EkonAuthService, private router: Router) { }
  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.process();
  }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.process();
  }

  private process(){
    return this.authService.isLoggedIn$.pipe(
      take(1),
      map(isLoggedIn => {
        console.log('EkonLandingGuard isLoggedIn: {0}', isLoggedIn)
        if (isLoggedIn) {
          return this.router.parseUrl('/dashboard');
        }
        return true;
      })
    );
  }

}
