/**
 * DKM4U API - v1
 * DKM4U Core Web API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: espen.kon@ekonm.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *   NoGraph  Traditional  VariableWidth  Notched  ViolinPlot  VasePlot  BeanPlot
 */
export type BoxplotGraphType = 'NoGraph' | 'Traditional' | 'VariableWidth' | 'Notched' | 'ViolinPlot' | 'VasePlot' | 'BeanPlot';

export const BoxplotGraphType = {
    NoGraph: 'NoGraph' as BoxplotGraphType,
    Traditional: 'Traditional' as BoxplotGraphType,
    VariableWidth: 'VariableWidth' as BoxplotGraphType,
    Notched: 'Notched' as BoxplotGraphType,
    ViolinPlot: 'ViolinPlot' as BoxplotGraphType,
    VasePlot: 'VasePlot' as BoxplotGraphType,
    BeanPlot: 'BeanPlot' as BoxplotGraphType
};

